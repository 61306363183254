import React, { useEffect, useState } from "react";
import { Navigator } from "../Navigator";
import API from "../../../Api/Api";
import swal from "sweetalert";
import { useNavigate, useSearchParams } from "react-router-dom";

const AddCourse = () => {
  const [data, setData] = useState({
    name: "",
    description: "",
    ispaid: "free",
    amount: "",
  });
  const [thumbnail, setThumbnail] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [chapterName, setChapterName] = useState("");
  const [allChapters, setAllChapters] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let value in data) {
      if (value == "ispaid") {
        formData.append(value, data[value] == "free" ? false : true);
      } else {
        formData.append(value, data[value]);
      }
    }
    formData.append("createdby", localStorage.getItem("_id"));
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    try {
      if (searchParams.get("id")) {
        await API(
          "put",
          `/lms/admin/update-course/${searchParams.get("id")}`,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );
      } else {
        await API("post", "/lms/admin/create-course", formData, {
          "Content-Type": "multipart/form-data",
        });
      }

      swal({
        title: "Success!",
        text: "Course modified Successfully",
        icon: "success",
      });
      navigate("/lms-dashboard-course");
    } catch (error) {}
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getSingleCourses = async (id) => {
    try {
      const response = await API("get", `/lms/admin/course/${id}`);
      setData({
        name: response?.data?.data?.name,
        description: response?.data?.data?.description,
        ispaid: response?.data?.data?.ispaid ? "paid" : "free",
        amount: response?.data?.data?.amount,
        // thumbnail: response?.data?.data?.thumbnail,
      });
    } catch (error) {
    } finally {
    }
  };

  const getChapters = async () => {
    try {
      const response = await API(
        "get",
        `/lms/admin/all-chapters/${searchParams.get("id")}`
      );
      setAllChapters(response?.data?.data);
      setChapterName("");
    } catch (error) {}
  };

  const saveChapter = async () => {
    try {
      await API("post", "/lms/admin/create-chapter", {
        name: chapterName,
        course: searchParams.get("id"),
      });
      getChapters();
      swal({
        title: "Success!",
        text: "Chapter created Successfully",
        icon: "success",
      });
    } catch (error) {}
  };

  const deleteChapter = async (id) => {
    try {
      await API("delete", `/lms/admin/delete-chapter/${id}`);
      getChapters();
      swal({
        title: "Success!",
        text: "Chapter deleted Successfully",
        icon: "success",
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      getSingleCourses(searchParams.get("id"));
      getChapters();
    }
  }, []);

  return (
    <>
      <div className="container">
        <Navigator />
        <div className="container">
          <div className="main-heading mt-3 mb-3">Add/Edit Course</div>
          <main className="d-flex flex-row course-form-container">
            {/* <img width="700" src={data?.thumbnail} alt="course-img" />
            <br />
            <br /> */}
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-column course-form"
            >
              <div>
                <label>
                  Course Thumbnail<span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="course image"
                  type="file"
                  accept="image/*"
                  name="thumbnail"
                  onChange={(e) => {
                    let ext = e.target.value.split(".").pop();
                    if (["jpg", "jpeg", "bmp", "gif", "png"].includes(ext)) {
                      setThumbnail(e.target.files[0]);
                    } else {
                      swal({
                        title: "Error!",
                        text: "Please upload valid image: jpg, jpeg, bmp, gif, png.",
                        icon: "error",
                      });
                    }
                  }}
                />
              </div>

              <div>
                <label>
                  Course Name<span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Course Name"
                  value={data.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>

              <div>
                <label>
                  Course Description<span className="required">*</span>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Course Description"
                  value={data.description}
                  onChange={handleChange}
                  name="description"
                />
              </div>

              <div>
                <label>
                  Is Course Paid?<span className="required">*</span>
                </label>
                <select
                  className="form-select"
                  value={data.ispaid}
                  onChange={handleChange}
                  name="ispaid"
                >
                  <option value={"free"}>Free</option>
                  <option value={"paid"}>Paid</option>
                </select>
              </div>

              {data?.ispaid == "paid" && (
                <input
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Price in USD"
                  type="number"
                  value={data.amount}
                  name="amount"
                />
              )}
              <div className="d-flex">
                <button type="submit" className="btn btn-primary-regular">
                  Submit
                </button>
              </div>
            </form>
            {searchParams.get("id") && (
              <div className="chapters-list">
                <div className="sub-heading mt-0 mb-3">Add/Edit Chapters</div>
                <div>
                  <label>
                    Chapter Name<span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Chapter Name"
                    type="text"
                    name="thumbnail"
                    value={chapterName}
                    onChange={(e) => {
                      setChapterName(e.target.value);
                    }}
                  />
                  <br />
                  <button className="btn btn-secondary" onClick={saveChapter}>
                    Save
                  </button>
                  <br />
                  <br />
                  {allChapters.map((item, index) => {
                    return (
                      <>
                        <div
                          key={item?._id}
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            background: "#fff",
                            padding: "10px 10px 10px 10px",
                          }}
                        >
                          <h2>{item?.name}</h2>
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover this chapter!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((res) => {
                                if (res) {
                                  deleteChapter(item?._id);
                                }
                              })
                            }
                          >
                            Delete
                          </button>
                        </div>
                        <hr style={{ marginTop: "0" }} />
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default AddCourse;
