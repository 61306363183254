import React from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'

const ButtonCustom = ({ onClick, text }) => {
  return (
    <button
      onClick={() => onClick()}
      className="widget-cta-page-nopos responsive-visible float-end mb-3"
    >
      {text}
    </button>
  )
}

const CourserMain = ({ modals, setModals, setShow, setCountry }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="modal-body">
      <p className="modal-subheading">Discover your Ideal Academic fit - Explore courses where ambitions thrive</p>

        <div
          className="d-flex justify-content-center  align-items-center w-100"
          style={{ gap: '1rem' }}
        >
          <ButtonCustom
            text={
              <>
                Course Matchmaker{' '}
              </>
            }
            onClick={() => {
              setModals({
                countryMain: false,
                needHelpCountry: false,
                courseMain: false,
                needHelpCourrse: true,
              })
              setShow(false)
            }}
          />
          <ButtonCustom
            text={
              <>
                Pre-Chosen Course
              </>
            }
            onClick={() => {
              setModals({
                countryMain: false,
                needHelpCountry: false,
                courseMain: false,
                needHelpCourrse: false,
              })
              setShow(true)
              setCountry(false)
            }}
          />
        </div>
      </div>
      <FooterModal navigate={navigate} setModals={setModals}/>
    </>
  )
}

export default CourserMain
