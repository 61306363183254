import React, { useEffect, useState } from "react";
import { Navigator } from "./Navigator";
import API from "../../Api/Api";
import { Link } from "react-router-dom";
import { BsX } from "react-icons/bs";
import swal from "sweetalert";

export const TestAdminDasboard = () => {
  const [allTest, setAllTest] = useState([]);
  const [userTest, setUserTest] = useState([]);

  const [loading, setLoading] = useState({
    getAllTest: false,
    getUserTest: false,
  });

  const getAllTests = async () => {
    setLoading({ ...loading, getAllTest: true });
    try {
      const response = await API("get", `/test/all`);
      setAllTest(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading({ ...loading, getAllTest: false });
    }
  };

  useEffect(() => {
    getAllTests();
  }, []);

  const handleDeleteTest = async (id) => {
    try {
      await API("delete", `/test/admin/delete/${id}`);
      swal({
        text: "Deleted !",
      });
      getAllTests();
      // const res = await API("delete", "");
    } catch (error) {}
  };

  return (
    <div className="container">
      <Navigator />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">All Tests</h1>

          <div>
            <button className="btn btn-primary">
              <Link className="test-text-link" to={`/test/add`}>
                Add New Test
              </Link>
            </button>
          </div>
        </div>
        {!loading?.getAllTest ? (
          <div
            className="d-flex justify-center items-center flex-wrap"
          >
            {allTest?.length > 0 ? (
              allTest?.map((item, i) => (
                <div className="test-card" key={i}>
                  <div>
                    {" "}
                    <div className="d-flex justify-content-between">
                      <h2>{item?.name}</h2>
                      <BsX
                        color="red"
                        size={"28"}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          swal({
                            title: "Delete Course",
                            text: "Are you sure?",
                            buttons: true,
                          }).then((res) => {
                            if (res) {
                              handleDeleteTest(item?._id);
                            }
                          });
                        }}
                      />
                    </div>
                    <span className="test-chip">{item?.type}</span>
                    <div
                      className="test-desc"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    ></div>
                    <br />
                  </div>
                  <div className="d-flex">
                    <button className="btn btn-primary-regular">
                      <Link
                        className="test-text-link"
                        to={`/test/add?id=${item?._id}`}
                      >
                        Edit Test
                      </Link>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No test found.</p>
            )}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};
