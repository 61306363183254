import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BsImageFill } from "react-icons/bs";
import { url } from "../Constants";
import API from "../../Api/Api";
function AddTestimonial() {
  const [item, setItem] = useState({
    user_name: "",
    user_rating: "5",
    user_image: "",
    course_name: "",
    university_name: "",
    user_testimonial: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setItem((newData) => {
      return {
        ...newData,
        [name]: value,
      };
    });
  };

  const postData = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      for (let value in item) {
        formData.append(value, item[value]);
      }
      const res = await API("post", `/addTestimonial`, formData, {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      });
      const data = res?.data;
      //console.log(data)
      if (data) {
        // Handle successful response
        swal({
          title: "Success",
          text: "Add Testimonial Successfully!",
          icon: "success",
          button: "Ok",
        });
        navigation("/dashboard-testimonials");

        // Clear input fields and image preview
        setItem({
          user_name: "",
          user_rating: "",
          course_name: "",
          university_name: "",
          user_testimonial: "",
          user_image: "",
        });
      } else {
        // Handle error response
        swal({
          title: "Error",
          text: "Failed to add testimonial",
          icon: "error",
          button: "Ok",
        });
      }
    } catch (error) {
      //   console.error("Error while adding testimonial:", error);
      //   swal({
      //     title: "Error",
      //     text: "An error occurred. Please try again later.",
      //     icon: "error",
      //     button: "Ok",
      //   });
    }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  return (
    <div className="container admin-createpage">
      <div>
        <div className="main-heading">Add Testimonial</div>
      </div>
      <div className="sep" />
      <div className="page-form">
        <div className="row">
          <div className=" col-3">
            <label className="form-label">User Name<span className="required">*</span></label>
            <input
              value={item.user_name}
              name="user_name"
              onChange={setData}
              type="text"
              className="form-control"
            />
          </div>
          <div className=" col-3">
            <label className="form-label">Course Name<span className="required">*</span></label>
            <input
              value={item.course_name}
              name="course_name"
              onChange={setData}
              type="text"
              className="form-control"
            />
          </div>
          <div className=" col-3">
            <label className="form-label">University Name<span className="required">*</span></label>
            <input
              value={item.university_name}
              name="university_name"
              onChange={setData}
              type="text"
              className="form-control"
            />
          </div>
          <div className=" col-3">
            <label className="form-label">Rating<span className="required">*</span></label>
            <select
              value={item.user_rating}
              name="user_rating"
              onChange={setData}
              type="text"
              className="form-select"
            >
              {[5, 4, 3, 2, 1].map((e) => (
                <option>{e}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className=" col-9">
            <label className="form-label">Testimonial<span className="required">*</span></label>
            <textarea
              value={item.user_testimonial}
              name="user_testimonial"
              onChange={setData}
              type="text"
              rows="10"
              cols="10"
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <label className="form-label">Profile Image<span className="required">*</span></label> &ensp;
            <BsImageFill className="BsImageFill" />
            <input
              type="file"
              className="form-control"
              placeholder=""
              defaultValue=""
              onChange={(e) =>
                setItem({ ...item, user_image: e.target.files[0] })
              }
              name="profilePhoto"
              aria-label=""
            />
            {/* <input
              value={item.user_image}
              name="user_image"
              onChange={setData}
              type="text"
              alt="image"
              className="form-control"
            /> */}
          </div>
        </div>
        <br />
        <button
          type="submit"
          onClick={postData}
          className="btn btn-primary-regular me-3"
        >
          Submit
        </button>
        <button
          type="submit"
          onClick={backButton}
          className="btn btn-secondary-regular"
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default AddTestimonial;
