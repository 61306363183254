import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { updateToken } from "../utils/CalendlyTokens";
// let WEBHOOK_URL = "https://smee.io/FQdKtwmUZY0BsQt";
let WEBHOOK_URL = "https://rheapis.azurewebsites.net/v1/mentor/webhook";

const Calendly = ({ profile }) => {
  const navigate = useNavigate();
  let URL = "https://auth.calendly.com";
  let URL_SANDBOX = "https://stoplight.io/mocks/calendly/api-docs/591407";

  const [params] = useSearchParams();
  const paramsLocal = { id: localStorage.getItem("mentor_id") };
  const [calendlyData, setCalendlyData] = useState({
    // authCode: null,
    token: null,
    calendly_username: "",
    loading: false,
  });

  const saveCalendlyData = async (data) => {
    setCalendlyData({
      ...calendlyData,
      loading: true,
    });
    try {
      const cal = await API(
        "put",
        `/mentor/update/calendly-name/${paramsLocal.id}`,
        {
          calendly_username: calendlyData?.calendly_username.split(".com/")[1],
        }
      );
      swal({
        title: "Success",
        text: "Calendly Account Connected Successfully",
        icon: "success",
      });
      navigate("/mentor-dashboard#calendly");
      window.location.reload();
    } catch (error) {
    } finally {
      setCalendlyData({
        ...calendlyData,
        loading: false,
      });
    }
  };

  const getAccessToken = async () => {
    try {
      // Get Token
      const res = await fetch(`${URL}/oauth/token`, {
        method: "POST",
        body: JSON.stringify({
          grant_type: "authorization_code",
          redirect_uri: "https://risehigher.education/mentor-dashboard",
          code: params.get("code"),
        }),
        headers: {
          "Content-Type": "application/json",
          // Prefer: "code=200, dynamic=true",
          Authorization: `Basic ${btoa(
            process.env.REACT_APP_CALENDLY_CLIENT_ID +
              ":" +
              process.env.REACT_APP_CALENDLY_CLIENT_SECRET
          )}`,
        },
      });
      const response = await res.json();

      // Get USer Data
      const user = await fetch(`https://api.calendly.com/users/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${response.access_token}`,
        },
      });
      const userData = await user.json();

      await updateToken(
        paramsLocal.id,
        userData.resource.uri,
        response.access_token,
        response.refresh_token
      );

      // Create Webhook subscription
      await (
        await fetch(`https://api.calendly.com/webhook_subscriptions`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.access_token}`,
          },
          body: JSON.stringify({
            url: WEBHOOK_URL,
            organization: userData.resource.current_organization,
            events: ["invitee.canceled"],
            user: userData.resource.uri,
            scope: "user",
            signing_key: process.env.REACT_APP_CALENDLY_Webhhok,
          }),
        })
      ).json();

      // console.log(createWebhookSubscription);

      // Create Event Type
      // const eventTypeCreate = await (
      //   await fetch(`https://api.calendly.com/one_off_event_types`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${response.access_token}`,
      //     },
      //     body: JSON.stringify({
      //       name: `30 Min Session with ${userData.resource.name}`,
      //       duration: 30,
      //       host: userData.resource.uri,
      //       date_setting: {
      //         type: "date_range",
      //         start_date: moment(new Date()).format("YYYY-MM-DD"),
      //         end_date: moment(new Date())
      //           .add(354, "days")
      //           .format("YYYY-MM-DD"),
      //       },
      //       location: {
      //         kind: "custom",
      //         location: "google_conference",
      //       },
      //     }),
      //   })
      // ).json();

      // const eventCreate = await fetch(`https://api.calendly.com/shares`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${response.access_token}`,
      //   },
      //   body: JSON.stringify({
      //     event_type: eventTypeCreate.resource.uri,
      //     name: `30 Min Session with ${userData.resource.name}`,
      //     period_type: "fixed",
      //     duration: 30,
      //     start_date: moment(new Date()).format("YYYY-MM-DD"),
      //     end_date: moment(new Date()).add(10000, "days").format("YYYY-MM-DD"),
      //     location_configurations: [
      //       {
      //         kind: "google_conference",
      //       },
      //     ],
      //   }),
      // });
      // const eventReponse = await eventCreate.json();

      setCalendlyData({ ...calendlyData, token: response.access_token });
    } catch (error) {
      //console.log(error);
    }
  };

  const getAuthCode = async () => {
    try {
      window.location.href = `${URL}/oauth/authorize?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=https://risehigher.education/mentor-dashboard`;
      const res = await fetch(
        `${URL_SANDBOX}/oauth/authorize?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=https://risehigher.education/mentor-dashboard`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Prefer: "code=200, dynamic=true",
            Authorization: `Basic ${btoa(
              process.env.REACT_APP_CALENDLY_CLIENT_ID +
                ":" +
                process.env.REACT_APP_CALENDLY_CLIENT_SECRET
            )}`,
          },
        }
      );
      const response = await res.json();
      //console.log(response.data);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (params.get("code")) {
      getAccessToken();
    }
  }, []);

  useEffect(() => {
    if (profile.calendly_username) {
      setCalendlyData({
        ...calendlyData,
        calendly_username: "https://calendly.com/" + profile?.calendly_username,
      });
    }
  }, [profile.calendly_username]);

  if (profile?.calendly_username && profile?.calendly_username !== "null") {
    return (
      <div
        className="card d-flex flex-column justify-content-center align-items-center"
        style={{ padding: "40px 20px" }}
      >
        <MdOutlineCheckCircleOutline
          style={{ color: "green", fontSize: "180px" }}
        />
        <h1 style={{ fontSize: "21px" }}>
          Congratulations, you have configured your Calendly.
        </h1>
        <hr />
        <div className="accordion" style={{ textAlign: "left", width: "100%" }}>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h2 style={{ textAlign: "left", width: "100%" }}>
                  Want to update your Calendly link?{" "}
                </h2>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <img
                  src="cal.png"
                  height={"500"}
                  style={{
                    borderRadius: "20px",
                    objectFit: "cover",
                    border: "1px solid #DC7215",
                  }}
                  alt="calendly-instruciton"
                />
                <br />
                <br />
                <label>Copy Link and paste it below </label>
                <input
                  className="form-control"
                  value={calendlyData.calendly_username}
                  onChange={(e) =>
                    setCalendlyData({
                      ...calendlyData,
                      calendly_username: e.target.value,
                    })
                  }
                  // placeholder="https://calendly.com/john_doe/30min"
                />
                <br />
                {!calendlyData.loading ? (
                  <button
                    className="btn btn-primary"
                    onClick={saveCalendlyData}
                  >
                    Save
                  </button>
                ) : (
                  <button className="btn btn-primary" disabled>
                    Please wait...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" style={{ padding: "10px 20px" }}>
      <h2>
        {" "}
        <b>Calendly Config</b>
      </h2>
      {/* <hr /> */}
      {calendlyData.token ? (
        <>
          <img
            src="cal.png"
            height={"500"}
            style={{
              borderRadius: "20px",
              objectFit: "cover",
              border: "1px solid #DC7215",
            }}
            alt="calendly-instruciton"
          />
          <br />
          <br />
          <label>Copy Link and paste it below </label>
          <input
            className="form-control"
            value={calendlyData.calendly_username}
            onChange={(e) =>
              setCalendlyData({
                ...calendlyData,
                calendly_username: e.target.value,
              })
            }
            // placeholder="https://calendly.com/john_doe/30min"
          />
          <br />
          {!calendlyData.loading ? (
            <button className="btn btn-primary-regular" onClick={saveCalendlyData}>
              Save
            </button>
          ) : (
            <button className="btn btn-primary" disabled>
              Please wait...
            </button>
          )}
        </>
      ) : (
        <div className="d-flex">
        <button className="btn btn-primary-regular" onClick={getAuthCode}>
          Connect Calendly
        </button>
        </div>
      )}
    </div>
  );
};

export default Calendly;
