import { useEffect, useRef } from "react";

export const InactivityHandler = ({ onInactive }) => {
  const timer = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        onInactive();
      }, 3600000);
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetTimer();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, [onInactive]);

  return null;
};
