import React from "react";
import { useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  const gotohome = () => {
    navigate("/");
  };
  return (
    <>
    <div style={{width: "100%"}}>
      <div
        style={{
          float: "left",
          margin: "0 auto",
          background: "#fff",
          textAlign: "left",
          padding: "10% 0 10% 10%",
          width: "50%",
          height: "650px"
        }}
      >
        <img
          src={require("../Assets/Media/Images/logo.png")}
          alt="Rise Higher Education"
          title="Rise Higher Education"
          width={150}
        />
        <h1
          style={{
            color: "#101828",
            fontSize: 30,
            fontWeight: 600,
            padding: "25px 0 0 0",
            fontFamily: "Inter",
          }}
        >
          Page not found
        </h1>
        <div
          style={{
            color: "#101828",
            fontSize: 18,
            padding: "25px 0",
            fontFamily: "Inter",
          }}
        >
          Sorry, the page you are looking for doesn't exist.
        </div>
        <button
          onClick={gotohome}
          style={{
            margin: "25px 0",
            background: "#FBA33C",
            border: "1px solid #FBA33C",
            color: "#fff",
            fontSize: 15,
            fontFamily: "Inter",
            width: 280,
            height: 40,
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            borderRadius: 8,
          }}
        >Go To Homepage</button>
      </div>
      <div style={{ float: "left", width: "50%", background: "#fff" }}>
        <img
          src={require("../Assets/Media/Images/404.gif")}
          alt="Rise Higher Education"
          style={{ width: "650px", height: "650px" }}
          title="Rise Higher Education"
        />
      </div>
      </div>
    </>
  );
}

export default Error;
