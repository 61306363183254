import React, { useState } from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'
import courseData from '../../Assets/Data/coursecounseling.json'
import Autosuggest from 'react-autosuggest'
import { useEffect } from 'react'
import swal from 'sweetalert'

const CourseTab = ({ idx, item, thumbData, addCourse, delCourse }) => {
  return (
    <div className="course-info" key={idx} style={{ padding: '10px 20px' }}>
      <div className="d-flex justify-content-between">
        <p className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={thumbData.includes(item)}
            onClick={(e) => {
              if (thumbData?.length >= 5 && e.target.checked) {
                swal({
                  title: 'Error!',
                  text: 'You can only select 5 courses.',
                  icon: 'error',
                  button: 'Ok',
                })
                return
              }
              let isChecked = e.target.checked
              if (isChecked) {
                addCourse(item)
              } else {
                delCourse(item)
              }
            }}
          />{' '}
          &nbsp; &nbsp;
          <strong>{item.name}</strong>
        </p>
        <p>{item.tagline}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p>
          <strong>STEM: </strong>
          {item.stem} | <strong>Average Starting Salary: </strong>$
          {item.avgstartSalary}
        </p>
      </div>
    </div>
  )
}

const CourseHelp = ({
  addCourse,
  delCourse,
  thumbData,
  setModals,
  setShow,
  setCountry,
  setScript,
}) => {
  const navigate = useNavigate()

  const [searchTerms, setSearchTerms] = useState(['', '', ''])
  const [suggestions, setSuggestions] = useState([])
  const [searchResults, setSearchResults] = useState({})

  const inputProps = (index) => ({
    placeholder: `Job Role ${index + 1}`,
    value: searchTerms[index],
    onChange: (e, { newValue }) => {
      const updatedTerms = [...searchTerms]
      updatedTerms[index] = newValue
      setSearchTerms(updatedTerms)
    },
  })

  const getSuggestions = (value) => {
    if (value !== undefined && value.trim() !== '') {
      const inputValue = value.trim().toLowerCase()
      const allJobs = Object.values(courseData).flatMap((category) =>
        category.flatMap((subCategory) => subCategory.jobRoles),
      )

      // Check for exact matches first
      const exactMatches = allJobs.filter(
        (job) => job.toLowerCase() === inputValue,
      )

      // If no exact matches, look for partial matches (case-insensitive)
      const partialMatches = allJobs.filter((job) =>
        job.toLowerCase().includes(inputValue),
      )

      return exactMatches.length > 0 ? exactMatches : partialMatches
    }
    return []
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionSelected = (index, event, { suggestionValue }) => {
    const updatedTerms = [...searchTerms]
    updatedTerms[index] = suggestionValue
    setSearchTerms(updatedTerms)
  }

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>

  const handleSearch = () => {
    // Remove empty search terms
    const nonEmptySearchTerms = searchTerms.filter(
      (term) => term !== undefined && term.trim() !== '',
    )

    if (nonEmptySearchTerms.length > 0) {
      // Remove duplicates
      const uniqueSearchTerms = [...new Set(nonEmptySearchTerms)]

      // Your search logic
      const filteredData = Object?.keys(courseData).reduce((acc, category) => {
        const filteredSubCategories = courseData[category].filter(
          (subCategory) => {
            return uniqueSearchTerms.some((term) =>
              subCategory.jobRoles.some((role) =>
                role.toLowerCase().includes(term.toLowerCase()),
              ),
            )
          },
        )

        if (filteredSubCategories.length > 0) {
          acc[category] = filteredSubCategories
        }

        return acc
      }, {})
      setSearchResults(filteredData)
    } else {
      // Handle empty search
      // alert('Please enter at least one search term')
    }
  }

  useEffect(() => {
    // console.log('search terms', searchTerms)
    handleSearch()
    // console.log(searchResults)
  }, [searchTerms])

  return (
    <>
      <div className="modal-body">
        <div>
          <div
            className="d-flex align-items-center justify-content-center job-roles-search"
            style={{ gap: '10px' }}
          >
            {searchTerms.map((term, index) => (
              <Autosuggest
                key={index}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionSelected={(event, { suggestionValue }) =>
                  onSuggestionSelected(index, event, { suggestionValue })
                }
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps(index)}
              />
            ))}
          </div>
          <br />
          <div className="px-3">
            {thumbData?.length > 0 && (
              <h2 style={{ fontWeight: '600' }}>Selected Course:</h2>
            )}
            <div className="d-flex" style={{ gap: '8px' }}>
              {thumbData?.map((itm) => (
                <p className="m-0 course-selected">{itm?.name}</p>
              ))}
            </div>
          </div>
          <br />
          {Object.keys(searchResults).map((category, index) => (
            <div key={index}>
              <h4
                style={{
                  borderBottom: '1px dotted #DC7215',
                  paddingBottom: '10px',
                  borderRadius: '0px',
                  margin: '1rem 1rem',
                }}
              >
                {category}
              </h4>
              <div className="d-flex flex-column" style={{ gap: '1rem' }}>
                {searchResults[category].map((subCategory, subIndex) => (
                  <CourseTab
                    idx={subIndex}
                    item={subCategory}
                    thumbData={thumbData}
                    addCourse={addCourse}
                    delCourse={delCourse}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <FooterModal
        navigate={navigate}
        setModals={setModals}
        nextElement={
          <>
            <div>
              <p
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setModals({
                    countryMain: false,
                    needHelpCountry: false,
                    courseMain: false,
                    needHelpCourrse: false,
                  })
                  setShow(true)
                  setCountry(false)
                }}
              >
                + Add more course
              </p>
            </div>
            <button
              type="button"
              className="btn btn-primary-regular"
              disabled={thumbData.length < 1}
              onClick={() => {
                if (localStorage.getItem('seconds') > 0) {
                  setScript(thumbData[0])
                  setModals({
                    countryMain: false,
                    needHelpCountry: false,
                    courseMain: false,
                    needHelpCourrse: false,
                  })
                  setShow(false)
                  setCountry(false)
                } else {
                  setModals({ login: true })
                }
              }}
            >
              Start Counseling
            </button>
          </>
        }
      />
    </>
  )
}

export default CourseHelp
