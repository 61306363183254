import React, { useState } from "react";
import swal from "sweetalert";

const WorkExpCard = (props) => {
  const del = async () => {
    const newdata = [...props.work];
    const index = newdata.findIndex(
      (e) =>
        e.company === props.exp.company &&
        e.designation === props.exp.designation
    );
    newdata.splice(index, 1);
    props.setWork(newdata);
  };

  const markIsRecent = () => {
    props.setWork((prevWork) => {
      return prevWork.map((item, i) => {
        if (i === props.idx) {
          return { ...item, isRecent: item?.isRecent ? false : true };
        } else {
          return { ...item };
        }
      });
    });
    setNewWorkExp({
      ...newWorkExp,
      isRecent: newWorkExp?.isRecent ? false : true,
    });
  };

  const [edtWorkExp, setEdtWorkExp] = useState(false);
  const [newWorkExp, setNewWorkExp] = useState(props.exp);

  const onChange = (event) => {
    if (
      (event.target.name === "start" || event.target.name === "end") &&
      newWorkExp[event.target.name].length === 1 &&
      event.target.value.length === 2
    ) {
      setNewWorkExp({
        ...newWorkExp,
        [event.target.name]: String(event.target.value + "/"),
      });
      return;
    }
    setNewWorkExp({ ...newWorkExp, [event.target.name]: event.target.value });
  };

  const editWorkExp = async () => {
    setEdtWorkExp(false);
    const newdata = [...props.work];
    const index = newdata.findIndex(
      (e) =>
        e.company === props.exp.company &&
        e.designation === props.exp.designation
    );
    newdata[index] = newWorkExp;
    props.setWork(newdata);
  };

  return (
    <>
      <ul
        className={
          props.exp.isRecent ? "profile-block recent" : "profile-block"
        }
      >
        <li>
          <div className={"d-flex flex-row justify-content-between"}>
            <div>
              <svg
                width={23}
                height={21}
                viewBox="0 0 23 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.1289 19.1028V3.10278C15.1289 2.57235 14.9182 2.06364 14.5431 1.68857C14.168 1.3135 13.6593 1.10278 13.1289 1.10278H9.12891C8.59847 1.10278 8.08977 1.3135 7.71469 1.68857C7.33962 2.06364 7.12891 2.57235 7.12891 3.10278V19.1028M3.12891 5.10278H19.1289C20.2335 5.10278 21.1289 5.99821 21.1289 7.10278V17.1028C21.1289 18.2074 20.2335 19.1028 19.1289 19.1028H3.12891C2.02434 19.1028 1.12891 18.2074 1.12891 17.1028V7.10278C1.12891 5.99821 2.02434 5.10278 3.12891 5.10278Z"
                  stroke="#101828"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {props.exp.company}
            </div>
            <div className="d-flex flex-row edit-sectionIcons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square edit-btn  me-2"
                viewBox="0 0 16 16"
                onClick={() => {
                  setEdtWorkExp(true);
                }}
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash edit-btn"
                viewBox="0 0 16 16"
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this data",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      del();
                      swal("Your data has been deleted", {
                        icon: "success",
                      });
                    }
                  });
                }}
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
              </svg>
            </div>
          </div>
        </li>
        <li>{props.exp.designation}</li>
        <li>
          <svg
            width={20}
            height={22}
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1V5M6 1V5M1 9H19M3 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3Z"
              stroke="#98A2B3"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{props.exp.start}</span> - <span>{props.exp.end}</span>
        </li>
      </ul>
      {edtWorkExp ? (
        <div className="form-group edit-form">
          <div className="row mb-3 responsive-col">
            <div className="col">
              <label htmlFor="">
                Job Type<span className="required">*</span>
              </label>
              <br />
              <input
                type="radio"
                className="btn-check"
                name="options61"
                id="options61"
                autoComplete="off"
                defaultChecked=""
                checked={newWorkExp.type === "Full Time"}
                onClick={() => {
                  setNewWorkExp({ ...newWorkExp, type: "Full Time" });
                }}
              />
              <label className="btn btn-secondary" htmlFor="options61">
                <i className="fa-solid fa-briefcase" /> Full Time
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options62"
                id="options62"
                autoComplete="off"
                checked={newWorkExp.type === "Part Time"}
                onClick={() => {
                  setNewWorkExp({ ...newWorkExp, type: "Part Time" });
                }}
              />
              <label className="btn btn-secondary" htmlFor="options62">
                <i className="fa-solid fa-briefcase" /> Part Time
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options63"
                id="options63"
                autoComplete="off"
                checked={newWorkExp.type === "Internship"}
                onClick={() => {
                  setNewWorkExp({ ...newWorkExp, type: "Internship" });
                }}
              />
              <label className="btn btn-secondary" htmlFor="options63">
                <i className="fa-solid fa-briefcase" />
                Internship
              </label>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Highest Education"
                  defaultValue=""
                  aria-label="Highest Education"
                  value={newWorkExp.company}
                  onChange={onChange}
                  name="company"
                />
                <label htmlFor="">Last/Current Company</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Field of Study"
                  aria-label="Field of Study"
                  value={newWorkExp.designation}
                  onChange={onChange}
                  name="designation"
                />
                <label htmlFor="">Designation</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="month"
                  className="form-control"
                  placeholder="Start Month/Year"
                  aria-label="Start Month/Year"
                  value={newWorkExp.start}
                  onChange={onChange}
                  name="start"
                />
                <label htmlFor="">Start Month/Year</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="month"
                  className="form-control"
                  placeholder="End Month/Year"
                  aria-label="End Month/Year"
                  value={newWorkExp.end}
                  onChange={onChange}
                  name="end"
                />
                <label htmlFor="">End Month/Year</label>
              </div>
            </div>
          </div>
          <div className="row responsive-col">
            <div className="col">
              <button
                type="submit"
                className="btn btn-primary-regular"
                onClick={() => {
                  markIsRecent();
                }}
              >
                {!newWorkExp?.isRecent ? "Mark as" : "Remove from"} most recent
              </button>
            </div>
            <div className="col text-end action-btn-group">
              <button
                type="submit"
                className="btn btn-secondary"
                onClick={() => {
                  setEdtWorkExp(false);
                  setNewWorkExp(props.exp);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary-regular"
                onClick={editWorkExp}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default WorkExpCard;