import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  Nav,
  OverlayTrigger,
  Pagination,
  Popover,
} from "react-bootstrap";
import { InactivityHandler } from "./InActivityHandler.js";
import Signup from "./Components/Auth/Signup";
import Login from "./Components/Auth/Login";
import ForgotPass from "./Components/Auth/ForgotPass";
import Layout from "./Components/Layout/Layout";
import LayoutNoLeftNav from "./Components/Layout/LayoutNoLeftNav";
import LayoutNoLeftNavLogin from "./Components/Layout/LayoutNoLeftNavLogin";
import LayoutWithoutLogin from "./Components/Layout/LayoutWithoutLogin.js";
import LayoutWithoutLoginWithHeader from "./Components/Layout/LayoutWithoutLoginWithoutHeader.js";
import Home from "./Components/Scholarships/Home";
import ScholarshipPublic from "./Components/ScholarshipsPublic/Home";
import FileUploadPublic from "./Components/FilesPublic/FileUpload.js";
import Details from "./Components/Scholarships/Details";
import ComingSoon from "./Components/Layout/ComingSoon";
import Error from "./Components/Layout/Error";

//import { Analytics } from '@vercel/analytics/react';

import "./Components/Assets/Styles/main.css";
import User from "./Components/Auth/User";
import Accomodation from "./Components/Accomodation/Accomodation";
import Loan from "./Components/Loan/Loan";
import FileUpload from "./Components/Files/FileUpload";
import Flights from "./Components/Flight/Flights";
import Dashboard from "./Components/Dashboard/dashboard";
import Edit from "./Components/Dashboard/editUser";
import EditTestimonial from "./Components/Dashboard/editTestimonial";
import Admin from "./Components/Admin/admin";
import Employee from "./Components/Dashboard/Employee/employee";
import AddUser from "./Components/Dashboard/addUser";
import AddTestimonial from "./Components/Dashboard/addTestimonial";
import AddScholarship from "./Components/Dashboard/addScholarship";
import EditScholarship from "./Components/Dashboard/editScholarship";
import ForgetPasswoard from "./Components/Admin/ForgotPasswoard";
import Jobs from "./Components/jobs/Jobs";
import CurrencyExchange from "./Components/CurrencyExchange/CurrencyExchange";
import UserDashboard from "./Components/UserDashboard/userdashboard";
import ApplicationDashboard from "./Components/UserDashboard/applicationdashboard";
import ApplicationProfile from "./Components/ApplicationProfile/ApplicationProfile";
import SimCard from "./Components/SIMCard/simcard";

import { AssessmentPublic } from "./Components/AssessmentPublic/Assessment.js";

import ProfileComp from "./Components/ProfileCompletion/profileCompletion";
import Recruitment from "./Components/recruitment/recruitment";

import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Logincontext } from "./Components/Admin/Contextprovider";
import Housing from "./Components/Housing/Accomodation";

import HousingPublic from "./Components/HousingPublic/Accomodation.js";

import AddEmployee from "./Components/Dashboard/Employee/addEmployee";
import EditEmployee from "./Components/Dashboard/Employee/editEmployee";
import UserFiles from "./Components/Dashboard/UserFiles";
import Programs from "./Components/Course Filters/programs";
import Shortlisting from "./Components/Course Filters/Shortlisting";
import Counseling from "./Components/Counseling/Counseling";
import CounselingPublic from "./Components/CounselingPublic/Counseling";
import UserApplicaton from "./Components/Dashboard/UserApplication";
import AdminApplicationProfile from "./Components/Dashboard/User Application Profile/AdminApplicationProfile";
import LoanPublic from "./Components/LoanPublic/Loan";
import CourseSelection from "./Components/CourseSelection/CourseSelection";
import CreateApplication from "./Components/Dashboard/createApplication";
import { AudioProvider } from "./Context/AudioContext";
import DashboardScholarShip from "./Components/Dashboard/dashboardScholarship";
import DashboardTestimonials from "./Components/Dashboard/dashboardTestimonials";
import { Toaster, toast } from "sonner";
import { url } from "./Components/Constants";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { LinkedinCallback } from "./Components/Auth/LinkedinCallback";
import { AffiliateDashboard } from "./Components/Affiliate/AffiliateDashboard";
import DashboardAffiliate from "./Components/Dashboard/DashboardAffiliate";
import DasboardReferred from "./Components/Dashboard/DasboardReferred";
import Courses from "./Components/Courses/Courses";
import PaymentCheck from "./Components/Courses/PaymentCheck";
import { AffiliateProgram } from "./Components/Affiliate/AffiliateProgram";

import { Assessment } from "./Components/Assessment/Assessment";
import { ConductTest } from "./Components/Assessment/ConductTest";
import AffiliateHome from "./Components/Affiliate/AffiliateHome";
import { TestAdminDasboard } from "./Components/LmsAdminDashboard/Tests";
import { Questions } from "./Components/LmsAdminDashboard/Questions";
import { AddTest } from "./Components/LmsAdminDashboard/Add/AddTest";
import ResetPass from "./Components/Auth/ResetPass";
import { ViewFile } from "./Components/Dashboard/ViewFile";
import Signature from "./Components/Dashboard/Signature";
import AddQuestion from "./Components/LmsAdminDashboard/Add/AddQuestion";
import QuestionCategory from "./Components/LmsAdminDashboard/QuestionCategory";
import Visa from "./Components/Visa/Visa";
import MassEmailDashboard from "./Components/Dashboard/MassEmailDashboard";
import Calender from "./Components/Calender/Calender";
import CalenderAdmin from "./Components/Calender/CalenderAdmin";
import ShortlistingAdmin from "./Components/Shortlisting/ShortlistingAdmin";
import ShortListingUser from "./Components/Shortlisting/ShortListingUser";
import TestReport from "./Components/Assessment/TestReport";
import DashboardFromEmail from "./Components/Dashboard/DashboardFromEmail";
import Course from "./Components/LmsAdminDashboard/Course";
import Lessons from "./Components/LmsAdminDashboard/Lessons";
import AddCourse from "./Components/LmsAdminDashboard/Add/AddCourse";
import AddLesson from "./Components/LmsAdminDashboard/Add/AddLesson";
import WatchCourse from "./Components/Courses/WatchCourse";
import Mentors from "./Components/Mentors/Mentors";
import MentorProfile from "./Components/Mentors/MentorProfile";
import { TestingConductTest } from "./Components/Assessment/Testing_ConductTest";
import MentorDashboard from "./Components/Mentors/MentorDashboard";
import MentorDashboardAdmin from "./Components/Dashboard/MentorDashboard";
import AddMentor from "./Components/Mentors/AddMentor.js";
import PurchaseCredits from "./Components/Mentors/PurchaseCredits";
import PaymentCheckCredit from "./Components/Mentors/Pages/PaymentCheck";
import MentorChat from "./Components/Mentors/MentorChat";
import socketIO from "socket.io-client";
import { socketUrl } from "./Components/Constants.js";
import PrivateLoginModal from "./Components/PrivateLoginModal.js";
import { MdChat, MdClose } from "react-icons/md";
import { LeadForm } from "./Components/Leadform/LeadForm.jsx";
import { ProfileForm } from "./Components/Leadform/ProfileForm.jsx";
import { CreateLead } from "./Components/Leadform/CreateLead.jsx";
import LeadFormDashboard from "./Components/Dashboard/LeadFormDashboard.js";
import ProfileLeadFormDashboard from "./Components/Dashboard/ProfileLeadFormDashboard.js";
import GlobalGPT from "./Components/Chat/GPT.jsx";
import SubscriptionBuy from "./Components/Subscription/SubscriptionBuy.js";
import PaymentCheckSubscription from "./Components/Subscription/PaymentCheckSubscription.js";
import subsNavCheck from "./utils/subscriptionNavCheck.js";
import { SubsCheck } from "./Components/SubsCheck.js";
import TimerComponent from "./BasicPlanTimerhandler.js";
import API from "./Api/Api.js";
import subscriptionData from "./Components/Assets/Data/subscription.json";
// import GPT from "./Components/Counseling/GPT.jsx";

import WhatsApp from "./Components/Dashboard/WhatsApp.js";
import ReferralFormDashboard from "./Components/Dashboard/ReferralFormDashboard.js";

import ShortlistingWizard from "./Components/Course Filters/ShortlistingWizard.js";

const socket = socketIO.connect(socketUrl);
function App() {
  const [loggedOut, setLoggedOut] = useState(false);
  const [loggedOutWarning, setLoggedOutWarning] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [news, setNews] = useState([]);
  const [minutes, setMinutes] = useState(null);

  const getActivities = async () => {
    try {
      const res = await fetch(`${url}/notifications/activities?limit=5`);
      const result = await fetch(
        `${url}/notifications/activities?limit=5&type=news`
      );
      const data = await res.json();
      const dataNews = await result.json();
      data?.data?.map((itm) => toast.info(itm.text));
      setNews(dataNews.data.map((itm) => itm.text).join(", "));
    } catch (error) {
      console.log(error);
      toast.error(error?.response.message);
    }
  };

  const getMinutes = async () => {
    try {
      const res = await API("get", `${url}/auth/get-min`);
      let subs = subscriptionData.find(
        (itm) => itm.id === localStorage.getItem("subscription_id")
      );
      if (
        res.data?.data?.subscriptionItems?.freeAccessTime === 0 &&
        !window.location.href.includes("subscription-buy") &&
        !subs?.id &&
        localStorage.getItem("role")?.toLowerCase() == "user"
      ) {
        window.location.href = "/subscription-buy";
      } else {
        setMinutes(res.data?.data?.subscriptionItems?.freeAccessTime);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.message);
    }
  };

  const { account } = useContext(Logincontext);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isVerified, setIsVerified] = useState(false);

  const handleInactivity = () => {
    if (localStorage.getItem("token")) {
      //console.log("User has been inactive for 60 seconds, loggin out...");
      setLoggedOutWarning(true);
      setTimeout(() => {
        localStorage.clear();
      }, 10000);
    }
  };

  useEffect(() => {
    let pathname = window.location.pathname;
    if (
      !pathname.includes("/lead") &&
      !pathname.includes("/basicprofile") &&
      !pathname.includes("risehighereducation")
    ) {
      getActivities();
    }
    // setTimeout(() => {
    //   getActivities();
    // }, 10000);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setTimeout(() => {
        getMinutes();
      }, 1200);
    }
    // Check if the user is an admin only the first time component mounts
    if (
      (account && account.user && account.user.role === "Admin") ||
      (account &&
        account.user &&
        account.user.role === "Human Resource (HR)") ||
      (account && account.user && account.user.role === "Counsellor") ||
      (account && account.user && account.user.role === "Manager") ||
      (account && account.user && account.user.role === "Developer")
    ) {
      setIsAdmin(true);
    }

    // Check if the user is verified (has a token) each time component mounts
    const roleInLocalStorage = localStorage.getItem("role");
    if (
      roleInLocalStorage === "Admin" ||
      roleInLocalStorage === "Counsellor" ||
      roleInLocalStorage === "Manager" ||
      roleInLocalStorage === "Developer" ||
      roleInLocalStorage === "Human Resource (HR)"
    ) {
      // setIsVerified(true);
      setIsAdmin(true);
    }
  }, [account]);

  useEffect(() => {
    let url = window.location.pathname;
    // Add Public urls which should not have login popup.
    if (
      !url.includes("p-") &&
      !url.includes("login") &&
      !url.includes("signup") &&
      !url.includes("mentor") &&
      !url.includes("affiliate") &&
      !url.includes("reset-password") &&
      !url.includes("loans") &&
      !url.includes("lead") &&
      !url.includes("basicprofile") &&
      !url.includes("forgotpassword")
    ) {
      setInterval(() => {
        if (!localStorage.getItem("token")) {
          setLoggedOut(true);
        }
      }, 1000);
    }

    if (url.includes("p-") && localStorage.getItem("token")) {
      window.location.href = url.split("p-")[1];
    }
    if (localStorage.getItem("token") == "undefined") {
      localStorage.clear();
      window.location.href = "/login";
    }
  }, []);

  return (
    <>
    {/* <Analytics /> */}
      <Toaster
        richColors
        position="bottom-left"
        closeButton
        visibleToasts={5}
      />
      <TimerComponent minutes={minutes} />
      <InactivityHandler onInactive={handleInactivity} />
      <BrowserRouter>
        <AudioProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/p-counseling" element={<LayoutWithoutLogin />}>
              <Route index element={<CounselingPublic />} />
            </Route>
            <Route path="/p-scholarship" element={<LayoutWithoutLogin />}>
              <Route index element={<ScholarshipPublic />} />
            </Route>
            <Route path="/p-documents" element={<LayoutWithoutLogin />}>
              <Route index element={<FileUploadPublic />} />
            </Route>
            <Route path="/p-assessment" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<AssessmentPublic />} />
            </Route>
            <Route path="/p-loans" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<LoanPublic />} />
            </Route>
            <Route path="/p-accommodation" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<HousingPublic />} />
            </Route>
            <Route path="/lead" element={<LayoutWithoutLoginWithHeader />}>
              <Route index element={<LeadForm />} />
            </Route>
            <Route path="/createlead" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<CreateLead />} />
            </Route>
            <Route path="/basicprofile">
              <Route index element={<ProfileForm />} />
            </Route>
            {/* Private */}
            <Route path="/scholarship" element={<Layout news={news} />}>
              <Route index element={<Home />} />
              <Route exact path="/scholarship/details/:id" element={<Details />}/>
            </Route>
            <Route path="/subscription-buy" element={<LayoutWithoutLoginWithHeader />}>
              <Route index element={<SubscriptionBuy />} />
            </Route>
            <Route path="/subscription/payment-check" element={<Layout news={news} />}>
              <Route index element={<PaymentCheckSubscription />} />
            </Route>
            <Route path="/profile" element={<Layout news={news} />}>
              <Route index element={<User />} />
            </Route>
            <Route path="/courses-dashboard" element={<Layout news={news} />}>
              <Route index element={<Courses />} />
            </Route>
            <Route path="/course/:id" element={<Layout news={news} />}>
              <Route index element={<WatchCourse />} />
            </Route>
            <Route path="/visa" element={<Layout news={news} />}>
              <Route index element={<Visa />} />
            </Route>
            <Route path="/speak-with-counselor" element={<Layout news={news} />}>
              <Route index element={<GlobalGPT />} />
            </Route>
            {/* Mentor */}
            <Route path="/mentors" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<Mentors />} />
            </Route>
            <Route path="/mentors/:id" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<MentorProfile socket={socket} />} />
            </Route>
            <Route path="/mentor/chat/:id">
              <Route index element={<MentorChat socket={socket} />} />
            </Route>
            <Route path="/refill-credits" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<PurchaseCredits />} />
            </Route>
            <Route path="/mentor-dashboard" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<MentorDashboard socket={socket} />} />
            </Route>
            <Route path="/addmentor" element={<LayoutNoLeftNavLogin />}>
              <Route index element={<AddMentor socket={socket} />} />
            </Route>
            <Route path="/assessment" element={<Layout news={news} />}>
              <Route index element={<Assessment />} />
            </Route>
            <Route path="/assessment/:id" element={<Layout news={news} />}>
              <Route index element={<ConductTest />} />
            </Route>
            <Route path="/testing/assessment/:id" element={<Layout news={news} />}>
              <Route index element={<TestingConductTest />} />
            </Route>
            <Route path="/test-report/:id" element={<Layout news={news} />}>
              <Route index element={<TestReport />} />
            </Route>
            <Route path="/payment-check" element={<Layout news={news} />}>
              <Route index element={<PaymentCheck />} />
            </Route>
            <Route path="/credits/payment-check" element={<Layout news={news} />}>
              <Route index element={<PaymentCheckCredit />} />
            </Route>
            <Route path="/accommodation" element={<Layout news={news} />}>
              <Route index element={<Housing />} />
            </Route>
            <Route path="/shortlisting" element={<Layout news={news} />}>
              <Route index element={<Shortlisting />} />
            </Route>
            <Route path="/shortlistingWizard" element={<LayoutWithoutLogin />}>
              <Route index element={<ShortlistingWizard />} />
            </Route>
            <Route path="/loans" element={<Layout news={news} />}>
              <Route index element={<Loan />} />
            </Route>
            <Route path="/currencyexchange" element={<Layout news={news} />}>
              <Route index element={<CurrencyExchange />} />
            </Route>
            <Route path="/documents" element={<Layout news={news} />}>
              <Route index element={<FileUpload />} />
            </Route>
            <Route path="/flights" element={<Layout news={news} />}>
              <Route index element={<Flights />} />
            </Route>
            <Route path="/simcard" element={<Layout news={news} />}>
              <Route index element={<SimCard />} />
            </Route>
            <Route path="/counseling" element={<Layout news={news} />}>
              <Route index element={<Counseling />} />
            </Route>
            <Route path="/counselingFrame" element={<LayoutWithoutLoginWithHeader />}>
              <Route index element={<Counseling />} />
            </Route>
            <Route path="/dashboard/user/files/:id" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<UserFiles />} />
            </Route>
            <Route path="/view-file" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<ViewFile />} />
            </Route>
            <Route path="/dashboard/user/application/:id" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<UserApplicaton />} />
            </Route>
            <Route path="/dashboard/user/applicationprofile/:id" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<AdminApplicationProfile />} />
            </Route>
            <Route path="/dashboard/user/createapplication/:id" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<CreateApplication />} />
            </Route>
            <Route path="/userdashboard" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<UserDashboard />} />
            </Route>
            <Route path="/applicationdashboard" element={<Layout news={news} />}>
              <Route index element={<ApplicationDashboard />} />
            </Route>
            <Route path="/calendar" element={<Layout news={news} />}>
              <Route index element={<Calender />} />
            </Route>
            <Route path="/calendar/:id" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<CalenderAdmin />} />
            </Route>
            <Route path="/shortlisting/:id" element={<LayoutNoLeftNav news={news} />}>
              <Route index element={<ShortlistingAdmin />} />
            </Route>
            <Route path="/shortlisting-user/:id" element={<Layout news={news} />}>
              <Route index element={<ShortListingUser />} />
            </Route>
            <Route path="/ApplicationProfile" element={<Layout news={news} />}>
              <Route index element={<ApplicationProfile />} />
            </Route>
          
            <Route path="/Recruitment" element={<Layout news={news} />}>
              <Route index element={<Recruitment />} />
            </Route>
            <Route path="/CourseSelection" element={<Layout news={news} />}>
              <Route index element={<CourseSelection />} />
            </Route>            
            {isAdmin ? (
              <>
                <Route path="/dashboard" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<Dashboard />} />
                </Route>
                <Route path="/dashboard-scholarship" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<DashboardScholarShip />} />
                </Route>
                <Route path="/dashboard-mass-email" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<MassEmailDashboard />} />
                </Route>
                <Route path="/dashboard-lead-form" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<LeadFormDashboard />} />
                </Route>
                <Route path="/dashboard-profile-form" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<ProfileLeadFormDashboard />} />
                </Route>
                <Route path="/dashboard-referral-form" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<ReferralFormDashboard />} />
                </Route>
                <Route path="/dashboard-from-email" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<DashboardFromEmail />} />
                </Route>
                <Route path="/dashboard-signature" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<Signature />} />
                </Route>
                <Route path="/mentor-dashboard-admin" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<MentorDashboardAdmin />} />
                </Route>
                <Route path="/dashboard-affiliate-users" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<DashboardAffiliate />} />
                </Route>
                <Route path="/dashboard-referrals" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<DasboardReferred />} />
                </Route>
                <Route path="/dashboard-testimonials" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<DashboardTestimonials />} />
                </Route>
                <Route path="/lms-dashboard-tests" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<TestAdminDasboard />} />
                </Route>
                <Route path="/lms-dashboard-course" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<Course />} />
                </Route>
                <Route path="/lms-dashboard-course/add" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddCourse />} />
                </Route>
                <Route path="/lms-dashboard-lesson" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<Lessons />} />
                </Route>
                <Route path="/lms-dashboard-lesson/add" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddLesson />} />
                </Route>
                <Route path="/test/add" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddTest />} />
                </Route>
                <Route path="/question/add" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddQuestion />} />
                </Route>
                <Route path="/lms-dashboard-questions" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<Questions />} />
                </Route>
                <Route path="/lms-dashboard-questions-category" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<QuestionCategory />} />
                </Route>
                <Route path="dashboard/userEdit/:id" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<Edit />} />
                </Route>
                <Route path="dashboard/testimonialEdit/:id" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<EditTestimonial />} />
                </Route>
                <Route path="dashboard/adduser" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddUser />} />
                </Route>
                <Route path="dashboard/addtestimonial" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddTestimonial />} />
                </Route>
                <Route path="dashboard/addscholarship" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<AddScholarship />} />
                </Route>
                <Route path="dashboard/editScholarship/:id" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<EditScholarship />} />
                </Route>
                <Route path="/whatsapp" element={<LayoutNoLeftNav news={news} />}>
                  <Route index element={<WhatsApp />} />
                </Route>
              </>
            ) : (
              <Route exact path="*" element={<Error />}></Route>
            )}

            {/* Auth Routes   */}
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/signup" element={<Signup />}></Route>
            <Route exact path="/affiliate" element={<AffiliateProgram />}></Route>
            <Route exact path="/affiliate-dashboard/:id" element={<AffiliateDashboard />}></Route>
            <Route exact path="/forgotpassword" element={<ForgotPass />}></Route>
            <Route exact path="/reset-password" element={<ResetPass />}></Route>
            <Route exact path="/" element={<ComingSoon />}></Route>
            <Route exact path="*" element={<Error />}></Route>
          </Routes>
        </AudioProvider>
        <SubsCheck minutes={minutes} />
      </BrowserRouter>
      {/* {localStorage.getItem("token") &&
        !window?.location?.pathname?.includes("/lead") && !window?.location?.pathname?.includes("/basicprofile") && (
          // &&
          //   window.location.pathname !== "/speak-with-counselor"
          <div
            style={{
              position: "sticky",
              bottom: 50,
              marginLeft: "auto",
              width: "max-content",
              right: 40,
              zIndex: 999,
            }}>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={
                <Popover
                  id="popover-basic"
                  placement="right"
                  positionRight={20}
                  positionTop={50}
                  style={{ minWidth: "25%", padding: "10px" }}
                  title="Popover right">
                  <GlobalGPT />
                </Popover>
              }
            >
              <div className="chat-icon-container">
                {!chatOpen ? (
                  <MdChat
                    style={{
                      fontSize: "24px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => setChatOpen(true)}
                  />
                ) : (
                  <MdClose
                    style={{
                      fontSize: "24px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => setChatOpen(false)}
                  />
                )}
              </div>
            </OverlayTrigger>
          </div>
        )} */}
      <Modal show={loggedOut} onHide={() => setLoggedOut(false)}>
        <Modal.Header closeButton>
          <Modal.Title>You are not logged in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrivateLoginModal />
        </Modal.Body>
      </Modal>
      <Modal show={loggedOutWarning} onHide={() => setLoggedOutWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Due to inactivity you will logout in 10 seconds.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{/* <PrivateLoginModal /> */}</Modal.Body>
      </Modal>
    </>
  );
}

export default App;
