import React, { useEffect, useState } from "react";
import courseData from "../../Assets/Data/coursecounseling.json";
import countryCodes from "../../Assets/Data/countries.json";
import country_codes from "../../Assets/Data/countries_code.json";
import Select from "react-select";

import { validatePersonalInfo } from "../../../utils/validation";

import { parseISO } from 'date-fns';

const PersonalInfo = (props) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country_code: "",
    phone: "",
    dob: "",
    gender: "",
    marital: "",
    subject: "",
    course_of_interest: [],
    level: "",
    studyDest: [],
    language: "",
    citizenship: "",
    mAddress: {
      address: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
    pAddress: {
      address: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
  });
  const [course, setCourse] = useState([]);
  const [edt, setEdit] = useState(false);

  const onChange = (e) => {
    const target = e.target.name.split(".");

    if (target.length === 2) {
      setData({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const formatDateString = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = parseISO(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [errors, setErrors] = useState({});
  const handleSave = () => {
    const { isValid, errors } = validatePersonalInfo(data);
    setErrors(errors); // Set the validation errors
  
    if (isValid) {
      props?.updateData("userInfo", data);
    } else {
    }
  };

  useEffect(() => {
    if (
      props.data !== null &&
      props.data !== undefined &&
      props.data.level !== undefined
    ) {
      const formattedDob = props.data.dob ? formatDateString(props.data.dob) : "";
      setData({
        ...props.data,
        dob: formattedDob,
      });
      setEdit(true);
    } else if (props.data !== null && props.data !== undefined) {
      const formattedDob = props.data.dob ? formatDateString(props.data.dob) : "";
      setData({
        ...data,
        firstName: props.data.firstName,
        lastName: props.data.lastName,
        phone: props.data.phone,
        email: props.data.email,
        country_code: props.data.country_code,
        studyDest: props.data.studyDest,
        dob: formattedDob,
        gender: props.data.gender,
        marital: props.data.marital,
        subject: props.data.subject,
        course_of_interest: props.data.course_of_interest,
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (data.subject && data.subject.length > 0) {
      setCourse(courseData[data.subject]);
    }
  }, [data.subject]);

  return (
    <div className="row profile-section">
      <div className="col">
        <div className="section-head d-flex flex-row justify-content-between">
          Personal Information
          {edt ? (
            // Display cross icon when in edit mode (edt is true)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square edit-btn"
              viewBox="0 0 16 16"
              onClick={() => setEdit(false)} // Click to exit edit mode
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          ) : (
            // Display pencil icon when not in edit mode (edt is false)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-square-fill edit-btn"
              viewBox="0 0 16 16"
              onClick={() => setEdit(true)} // Click to enter edit mode
            >
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
            </svg>
          )}
        </div>
        <div className="form-group section-content">
          <div className="row responsive-col">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled="disabled"
                      placeholder=""
                      defaultValue=""
                      name="firstName"
                      value={data.firstName}
                      onChange={onChange}
                      aria-label=""
                    />
                    <label htmlFor="">First Name</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      // disabled="disabled"
                      placeholder=""
                      defaultValue=""
                      name="lastName"
                      disabled="disabled"
                      value={data.lastName}
                      onChange={onChange}
                      aria-label=""
                    />
                    <label htmlFor="">Last Name</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="date"
                  min="1970-01-01"
                  max="2006-12-31"
                  disabled={edt}
                  name="dob"
                  value={data.dob}
                  onChange={onChange}
                  className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                  placeholder="Birth Date"
                  aria-label="Birth Date"
                />
                {errors.dob && (
                    <span className="required">{errors.dob}</span>
                )}
                <label htmlFor="">
                  Birth Date (mm/dd/yyyy)<span className="required">*</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row responsive-col">
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  disabled="disabled"
                  name="email"
                  value={data.email}
                  onChange={onChange}
                  defaultValue=""
                  placeholder="Email"
                  aria-label="Email"
                />
                <label htmlFor="">Email</label>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col countryCode">
                  <div className="form-floating mb-3">
                    <select
                      list="countryCodeData"
                      value={data.country_code}
                      onChange={onChange}
                      placeholder="choose.."
                      className={`form-select ${errors.country_code ? 'is-invalid' : ''}`} 
                      disabled={edt}
                      name="country_code"
                    >
                      <option value="Please Select">Please Select</option>
                      {country_codes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </select>
                    {errors.country_code && <div className="required">{errors.country_code}</div>}
                    <label htmlFor="">
                      Country Code
                      <span className="required countryCode">*</span>
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      name="phone"
                      value={data.phone}
                      onChange={onChange}
                      disabled={edt}
                      maxLength={10}
                      defaultValue=""
                    />
                    <label htmlFor="">
                      Phone Number<span className="required">*</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row responsive-col">
            <div className="col">
              <label htmlFor="">
                Gender<span className="required">*</span>
              </label>
              <br />
              <input
                type="radio"
                className="btn-check"
                checked={data.gender === "male"}
                onClick={() => {
                  setData({ ...data, gender: "male" });
                }}
                id="option1"
                autoComplete="off"
                defaultChecked=""
                disabled={edt}
              />
              <label className="btn btn-secondary" htmlFor="option1">
                <i className="fa-solid fa-person" /> Male
              </label>
              <input
                type="radio"
                className="btn-check"
                checked={data.gender === "female"}
                onClick={() => {
                  setData({ ...data, gender: "female" });
                }}
                id="option2"
                autoComplete="off"
                disabled={edt}
              />
              <label className="btn btn-secondary" htmlFor="option2">
                <i className="fa-solid fa-person-dress" /> Female
              </label>
              <input
                type="radio"
                className="btn-check"
                checked={data.gender === "non-binary"}
                onClick={() => {
                  setData({ ...data, gender: "non-binary" });
                }}
                autoComplete="off"
                id="option3"
                disabled={edt}
              />
              <label className="btn btn-secondary" htmlFor="option3">
                <i className="fa-solid fa-genderless" /> Non-Binary
              </label>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={data?.studyDest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={countryCodes.map((op, i) => ({
                    value: op.name,
                    label: op.name,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setData((prevData) => ({
                      ...prevData,
                      studyDest: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                  isOptionDisabled={() => data?.studyDest?.length >= 3}
                  isDisabled={edt}
                />
                {/* <label htmlFor="">Study Destination</label> */}
              </div>
            </div>
          </div>
          <div className="row responsive-col">
            <div className="col">
              <div className="form-floating mt-3 mb-3">
                <select
                  className="form-select"
                  name="subject"
                  value={data.subject}
                  onChange={onChange}
                  placeholder="Select Subject"
                  disabled={edt}
                >
                  {Object?.keys(courseData).map((idx) => (
                    <option key={idx}>{idx}</option>
                  ))}
                </select>
                <label htmlFor="">Subject</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mt-3 mb-3">
                {/* <select className="form-select" name='course_of_interest' value={data.course_of_interest} onChange={onChange} placeholder="Select Course of Interest" disabled={edt}>
                                    {course.length === 0 ? <option value="Select Course of Interest">Select Subject to get courses</option> : ""}
                                    {course !== undefined && course.length > 0 ? course.map((item, idx) => (
                                        <option >{item.name}</option>
                                    )) : ""}
                                </select>
                                <label htmlFor="">Course of Interest</label> */}
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={data?.course_of_interest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={
                    course.length === 0
                      ? [{ label: "Select Subject to get courses", value: "" }]
                      : course.map((op, i) => ({
                        value: op.name,
                        label: op.name,
                      }))
                  }
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setData((prevData) => ({
                      ...prevData,
                      course_of_interest: newData,
                    }));
                  }}
                  isDisabled={edt}
                  placeholder="Course of Interest"
                  isOptionDisabled={() => {
                    if (course.length === 0) return 1;
                    else return data?.course_of_interest?.length >= 5;
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-floating mt-3 mb-3">
                <select
                  className="form-select"
                  name="level"
                  value={data.level}
                  onChange={onChange}
                  placeholder="Select level"
                  disabled={edt}
                >
                  <option value="Select Level">Select Level</option>
                  <option value="English Language Program">
                    English Language Program
                  </option>
                  <option value="Foundation">Foundation</option>
                  <option value="Online Programmes / Distance Learning">
                    Online Programmes / Distance Learning
                  </option>
                  <option value="Pathway Programs">Pathway Programs</option>
                  <option value="Masters">Masters</option>
                  <option value="PG Diploma/Certificate">
                    PG Diploma/Certificate
                  </option>
                  <option value="PhD">PhD</option>
                  <option value="Short Term Programs">
                    Short Term Programs
                  </option>
                  <option value="Technical School/College">
                    Technical School/College
                  </option>
                  <option value="Trade School/College">
                    Trade School/College
                  </option>
                  <option value="Twinning Programmes (PG)">
                    Twinning Programmes (PG)
                  </option>
                  <option value="Bachelors">Bachelors</option>
                  <option value="UG Diploma/ Certificate/ Associate Degree">
                    UG Diploma/ Certificate/ Associate Degree
                  </option>
                </select>
                <label htmlFor="">Level</label>
              </div>
            </div>
          </div>
          {!edt ? (
            <div className="row">
              <div className="col text-end action-btn-group">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  // disabled={data.level === ""}
                  onClick={() => {
                    setEdit(true);
                    setErrors({});
                    if (
                      props.data !== null &&
                      props.data !== undefined &&
                      props.data.level !== undefined
                    ) {
                      setData(props.data);
                    } else if (
                      props.data !== null &&
                      props.data !== undefined
                    ) {
                      setData({
                        ...data,
                        firstName: props.data.firstName,
                        lastName: props.data.lastName,
                        phone: props.data.phone,
                        email: props.data.email,
                        country_code: props.data.country_code,
                        dob: props.data.dob,
                        gender: props.data.gender,
                        marital: props.data.marital,
                        subject: props.data.subject,
                        course_of_interest: props.data.course_of_interest,
                      });
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-regular"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
