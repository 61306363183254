import React from 'react'

const FooterModal = ({ navigate, nextElement, setModals }) => {
  return (
    <div className="modal-footer d-flex justify-content-between">
      <button
        onClick={() => {
          setModals({ login: true })
        }}
        className="widget-cta-page-nopos responsive-visible float-end mb-3"
      >
        <i className="fa fa-home"></i> Back to Dashboard
      </button>
      {nextElement}
    </div>
  )
}

export default FooterModal
