import React from 'react'
import Main from './Main'


const Counseling = () => {
    return (
        <Main />
    )
}

export default Counseling
