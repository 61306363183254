import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import YourProfile from "./Pages/YourProfile";
import Calendly from "./Pages/Calendly";
import AllBookins from "./Pages/AllBookings";
import MentorChat from "./MentorChat";

const MentorDashboard = ({ socket }) => {
  const [mentorAccessToken, setMentorAccessToken] = useState(null);
  const [forgotView, setForgotView] = useState(false);
  const navigate = useNavigate("");
  const [params] = useSearchParams();
  const [mentor, setMentor] = useState({});
  const [newPassword, setPassword] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [page, setPage] = useState("general");
  const [loggingLoading, setLoggingLoading] = useState(false);
  const [notificationCount, setNotificationsCount] = useState(0);

  const getNotitificationsCount = async () => {
    try {
      const response = await API(
        "get",
        `/mentor/notifications/${mentor?._id}?type=mentor`
      );
      setNotificationsCount(response.data.data);
    } catch { }
  };

  const getMentors = async () => {
    try {
      const data = await API("get", `/mentor/${mentorAccessToken}`);
      setMentor(data?.data?.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    let url = window.location.href;
    if (!url.includes("#")) {
      setPage("general");
    } else {
      setPage(url.split("#")[1]);
    }
    if (params.get("code")) {
      setPage("calendly");
    }
  }, []);

  useEffect(() => {
    if (mentor?._id) {
      getNotitificationsCount();
    }
  }, [mentor?._id]);

  const getMentorsWithId = async () => {
    try {
      const data = await API("get", `/mentor/${params.get("id")}`);
      setMentor(data?.data?.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (mentorAccessToken) {
      getMentors();
    }
    if (params.get("id")) {
      getMentorsWithId();
    }
  }, [mentorAccessToken]);

  useEffect(() => {
    setMentorAccessToken(localStorage.getItem("mentorAccessToken"));
  }, []);

  const handlePasswordSave = async () => {
    try {
      const data = await API(
        "post",
        `/mentor/update-password/${params.get("id")}`,
        {
          password: newPassword,
        }
      );
      swal({
        title: "Success!",
        text: data?.data?.message,
        icon: "success",
      });
      window.location.reload();
    } catch (error) {
    } finally {
    }
  };

  const handleLogin = async () => {
    setLoggingLoading(true);
    try {
      const data = await API("post", `/mentor/login/`, loginData);
      localStorage.setItem("mentorAccessToken", data?.data?.token);
      localStorage.setItem("mentor_id", data.data.user._id);
      swal({
        title: "Success!",
        text: data?.data?.message,
        icon: "success",
      });
      navigate("/mentor-dashboard");
      window.location.reload();
    } catch (error) {
    } finally {
      setLoggingLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setLoggingLoading(true);

    try {
      const data = await API("post", `/mentor/forgot-password`, {
        email: loginData?.email,
      });
      // localStorage.setItem("mentorAccessToken", data?.data?.token);
      // localStorage.setItem("mentor_id", data.data.user._id);
      swal({
        title: "Success!",
        text: data?.data?.message,
        icon: "success",
      });
      navigate("/mentor-dashboard");
      window.location.reload();
    } catch (error) {
    } finally {
      setLoggingLoading(false);
    }
  };

  let NewPassword = (
      <div className="card p-4">
        <h2>
          {" "}
          <b>Create Password</b>
        </h2>
        Hey {mentor?.name}, create password and login to your account.
        <hr />
        <label>New Password</label>
        <input
          className="form-control"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={newPassword}
        />
        <div>
          <button
            onClick={() => {
              handlePasswordSave();
            }}
            className="btn btn-primary-regular mt-3"
          >
            Save
          </button>
        </div>
      </div>
  );

  if (!mentor?.password && params?.get("id")) {
    return (
      <div className="body-content width-50">
          {" "}
          {NewPassword}
      </div>
    );
  }

  if (!mentorAccessToken) {
    return (
      <div className="body-content width-50">
        <div
          className="card p-4"
        >
          <h2>
            <b>Login Mentor</b>
          </h2>
          <hr />
          <input
            className="form-control mb-3"
            placeholder="Email"
            type="email"
            onChange={(e) =>
              setLoginData({ ...loginData, email: e.target.value })
            }
            value={loginData.email}
          />
          {!forgotView && (
            <input
              className="form-control"
              placeholder="Password"
              type="password"
              onChange={(e) =>
                setLoginData({ ...loginData, password: e.target.value })
              }
              value={loginData.password}
            />
          )}
          <div>
          {!loggingLoading ? (
            <>
              {!forgotView ? (
                <button
                  className="btn btn-primary-regular mt-3"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Login
                </button>
              ) : (
                <>
                <button
                  className="btn btn-primary-regular"
                  onClick={() => {
                    handleForgotPassword();
                  }}
                >
                  Send Email
                </button>
                <button
                className="btn btn-nostyle float-end"
                onClick={() => setForgotView(false)}
              >
                Back to login
              </button>
              </>
              )}
              <br />
              <button
                className="btn btn-nostyle"
                onClick={() => setForgotView(true)}
              >
                Forgot Password?
              </button>
            </>
          ) : (
            <button className="btn btn-primary" disabled={true}>
              Please wait...
            </button>
          )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div className="body-container width-100">
          <ul className="nav nav-pills" style={{ width: "1100px" }}>
            <li className="nav-item">
              <a
                className={`nav-link ${page === "general" && "active"}`}
                href="#general"
                onClick={() => setPage("general")}
              >
                Your Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${page === "bookings" && "active"}`}
                href="#bookings"
                onClick={() => setPage("bookings")}
              >
                All bookings
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${page === "calendly" && "active"}`}
                href="#calendly"
                onClick={() => setPage("calendly")}
              >
                Calendly Config
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${page === "create-password" && "active"}`}
                href="#create-password"
                onClick={() => setPage("create-password")}
              >
                Update Password
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${page === "chat" && "active"}`}
                href="#chat"
                onClick={() => setPage("chat")}
              >
                ({notificationCount}) unread messages
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${page === "logout" && "active"}`}
                href="#logout"
                onClick={() => {
                  localStorage.removeItem("mentorAccessToken");
                  window.location.reload();
                }}
              >
                Logout
              </a>
            </li>
          </ul>
          {page === "create-password" && <>{NewPassword} </>}
          {page === "general" && <YourProfile profile={mentor} />}
          {page === "calendly" && <Calendly profile={mentor} />}
          {page === "bookings" && <AllBookins profile={mentor} />}
          {page === "chat" && <MentorChat profile={mentor} socket={socket} />}
        </div>
      </div>
    </div>
  );
};

export default MentorDashboard;
