import React from 'react'

const GraphIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
  >
    <path
      d="M7 17.1035V7.10352M13 17.1035V1.10352M1 17.1035V13.1035"
      stroke="#FBA33C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  )
}

export default GraphIcon