import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import NavBarAdmin from "./Nav/Nav";
import { OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import AllBookingAdmin from "../Mentors/Pages/AllBookingsStudentAdmin";

const MentorDashboardAdmin = () => {
  const [showBookings, setShowBookings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mentor, setMentor] = useState({
    name: "",
    email: "",
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getMentors = async () => {
    setLoading(true);
    try {
      const response = await API(
        "get",
        `/mentor/admin/all?page=${currentPage}`
      );
      // console.log(response?.data?.data);
      setData(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleAddMentor = async () => {
    try {
      const response = await API("post", `/mentor/`, mentor);
      swal({
        title: "Success!",
        text: "Mentor Added Successfully!",
        icon: "success",
        button: "Ok!",
      });
      getMentors();
      setModal(false);
      setMentor({
        name: "",
        email: "",
      });
    } catch (error) {
    } finally {
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await API("delete", `/mentor/${id}`);
      swal({
        title: "Success!",
        text: "Deleted Successfully!",
        icon: "success",
        button: "Ok!",
      });
      getMentors();
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getMentors();
  }, [currentPage]);

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
      <div className="d-flex justify-content-between">
        <h1 className="page-title">All {showBookings ? "Bookings" : "Mentors"}</h1>
        <div className="d-flex mb-2">
          <button
            className="btn btn-secondary"
            onClick={() => setShowBookings(!showBookings)}
          >
            Show All {!showBookings ? "Bookings" : "Mentors"}
          </button>
          <button className="btn btn-primary-regular ms-2" onClick={() => setModal(true)}>
            {" "}
            Add New Mentor
          </button>
        </div>
      </div>
      
      {showBookings ? (
        <AllBookingAdmin />
      ) : (
        <>
          {loading ? (
            <p>Please wait loading mentors...</p>
          ) : (
            <div className="table-responsive grid-frame">
              <table className="table admin-table">
                <thead>
                  <tr className="table-dark">
                    <th scope="col">Id</th>
                    <th scope="col">Name </th>
                    <th scope="col">Email</th>
                    <th scope="col">Area of Expertise</th>
                    <th scope="col">Description</th>
                    <th scope="col">Tags</th>
                    <th scope="col">Country</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data.map((e, id) => (
                      <tr key={id}>
                        <th scope="row">{id + 1}</th>
                        <td>{e?.name}</td>
                        <td>
                          <a href={`mailto:${e?.email}`}>{e?.email}</a>
                        </td>
                        <td>{e?.aoe}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{ __html: e?.description }}
                          ></div>
                        </td>
                        <td>{e.tags?.join(", ")}</td>
                        <td>{e.country}</td>
                        <td>
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                          >
                            <button
                              onClick={() => {
                                swal({
                                  title: "Are you sure?",
                                  text: "Once deleted, you will not be able to recover the mentor data!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((res) => {
                                  if (res) {
                                    // console.log(res)
                                    handleDelete(e?._id);
                                  }
                                });
                                // handleDelete(e?._id)
                              }}
                              className="btn deleteScholarshipBtn"
                            >
                              <MdDelete className="MdDelete" />
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p className="required p-2">No Data Found</p>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination className="d-flex justify-content-end mb-5">
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </>
      )}
      </div>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Mentor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={mentor.name}
            onChange={(e) => {
              setMentor({
                ...mentor,
                name: e.target.value,
              });
            }}
          />
          <label for="floatingInput">Mentor Name<span className="required">*</span></label>
        </div>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            placeholder=""
            type="email"
            value={mentor.email}
            onChange={(e) => {
              setMentor({
                ...mentor,
                email: e.target.value,
              });
            }}
          />
          <label for="floatingInput">Mentor Email<span className="required">*</span></label>
          </div>
          <button
            className="btn btn-primary-regular"
            onClick={() => {
              handleAddMentor();
            }}
          >
            Save
          </button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MentorDashboardAdmin;
