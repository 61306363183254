import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import swal from "sweetalert";

export const CheckoutForm = ({ itm }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const purchasePlan = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try {
      const paymentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url:
            process.env.REACT_APP_URL +
            `/credits/payment-check?planid=${itm?.id}`,
        },
      });

      if (paymentResult.error) {
        swal({
          title: "Error!",
          text: paymentResult.error.message,
          icon: "error",
          button: "Ok!",
        });
      } else {
        //redirecting..
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checkout-container" id="checkout">
      <form onSubmit={purchasePlan}>
        <h2 className="mentor-section-title">
          Purchase <strong>{itm?.name}</strong> Plan
        </h2>
        <PaymentElement />
        {/* <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              lineHeight: "40px",
              //   backgroundColor: "#fff",
              fontSize: "18px",
            },
          },
        }}
      /> */}
        {!loading ? (
          <button className="mentor-button-sm checkout-paybutton">
            {" "}
            Pay ${itm.price}
          </button>
        ) : (
          <button className="mentor-button-sm" disabled>
            Please Wait
          </button>
        )}
      </form>
    </div>
  );
};

export default CheckoutForm;
