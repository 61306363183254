import { url } from "../../Constants";
import React from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
const toolbarSettings = {
  items: [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Image",
    "|",
    "ClearFormat",
    "Print",
    "SourceCode",
    "FullScreen",
    "|",
    "Undo",
    "Redo",
  ],
};
const quickToolbarSettings = {
  image: [
    "Replace",
    "Align",
    "Caption",
    "Remove",
    "InsertLink",
    "OpenImageLink",
    "-",
    "EditImageLink",
    "RemoveImageLink",
    "Display",
    "AltText",
    "Dimension",
  ],
  link: ["Open", "Edit", "UnLink"],
};
const Editor = ({ height, data, setData, name }) => {
  return (
    <RichTextEditorComponent
      height={height ?? 450}
      toolbarSettings={toolbarSettings}
      quickToolbarSettings={quickToolbarSettings}
      value={data[name] ?? ""}
      change={(e) => setData({ ...data, [name]: e?.value })}
      insertImageSettings={{
        allowedTypes: [".jpg", ".png", ".gif"],
        saveUrl: url + "/image/new",
        path: "https://riseusersfilestorage.blob.core.windows.net/uploaded/",
      }}
    >
      <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
    </RichTextEditorComponent>
  );
};

export default Editor;
