import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import swal from "sweetalert";
import { DiscussionEmbed } from "disqus-react";
import { useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
const CourseDetails = ({ overview }) => {
  const [note, setNote] = useState("");
  const [allNotes, setAllNotes] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState({
    rating: 0,
    comment: "",
  });
  const [avgRating,setAvgRating] = useState(0)
  const params = useParams();

  const getNote = async () => {
    try {
      const data = await API("post", `/lms-activity/student/get-lesson-note`, {
        lessonid: overview?.lessonid,
        studentid: localStorage.getItem("_id"),
      });
      setAllNotes(data?.data?.data);
      setNote("");
    } catch (error) {
    } finally {
    }
  };
  const addNote = async () => {
    try {
      const data = await API(
        "post",
        `/lms-activity/student/create-lesson-note`,
        {
          note,
          lessonid: overview?.lessonid,
          studentid: localStorage.getItem("_id"),
          duration: overview?.playingSeconds,
        }
      );
      getNote();
    } catch (error) {
    } finally {
    }
  };

  const getReviews = async () => {
    try {
      const data = await API("post", `/lms-activity/student/course-rating`, {
        courseid: params?.id,
      });
      setReviews(data?.data?.data);
      let avg = 0;  
      data?.data?.data?.map((itm) => {
        avg += Number(itm?.rating)
      })
      setAvgRating(!isNaN((avg/data?.data?.data?.length).toFixed(1)) ?  (avg/data?.data?.data?.length).toFixed(1) : 0)
      setReview({
        rating: 0,
        comment: "",
      });
    } catch (error) {
    } finally {
    }
  };

  const submitReview = async () => {
    try {
      const data = await API("post", `/lms-activity/student/add-rate-course`, {
        ...review,
        courseid: params?.id,
        studentid: localStorage.getItem("_id"),
      });
      swal({
        title: "Success!",
        text: data?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      getReviews()
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (overview?.lessonid) {
      getNote();
      getReviews();
    }
  }, [overview?.lessonid]);

  return (
    <div className="course-details">
      <ul
        className="nav nav-pills mb-3"
        id="pills-tab"
        role="tablist"
        style={{ paddingLeft: "10px" }}
      >
        <li clsass="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="pills-overview-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overview"
            type="button"
            role="tab"
            aria-controls="pills-overview"
            aria-selected="true"
          >
            Overview
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-notes-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-notes"
            type="button"
            role="tab"
            aria-controls="pills-notes"
            aria-selected="false"
          >
            Notes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-transcript-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-transcript"
            type="button"
            role="tab"
            aria-controls="pills-transcript"
            aria-selected="false"
          >
            Transcript
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-qna-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-qna"
            type="button"
            role="tab"
            aria-controls="pills-qna"
            aria-selected="false"
          >
            Q&A
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-review-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-review"
            type="button"
            role="tab"
            aria-controls="pills-review"
            aria-selected="false"
          >
            Course Review
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-overview"
          role="tabpanel"
          aria-labelledby="pills-overview-tab"
          tabindex="0"
        >
          <p>
            &nbsp;<strong>About this course</strong>
          </p>
          <p style={{ marginLeft: "5px" }}>{overview?.description}</p>
        </div>
        <div
          className="tab-pane fade"
          id="pills-notes"
          role="tabpanel"
          aria-labelledby="pills-notes-tab"
          tabindex="0"
        >
          <div>
            {allNotes?.map((note) => {
              return (
                <div key={note._id}>
                  <p>{note?.note}</p>
                  <p>
                    <b>Created at:</b> {note?.duration}
                  </p>
                  <hr />
                </div>
              );
            })}
          </div>
          <div className="create-note">
            <button className="btn">
              Click here to Create a new note at{" "}
              <strong>{overview?.playingSeconds}</strong>
            </button>
          </div>
          <div className="create-note-content text-end">
            <textarea
              rows="5"
              cols="5"
              className="form-control"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
            <button className="btn btn-primary-regular" onClick={addNote}>
              Save Note
            </button>
            <button className="btn">Cancel</button>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-transcript"
          role="tabpanel"
          aria-labelledby="pills-transcript-tab"
          tabindex="0"
        >
          <p>
            &nbsp;<strong>Course Transcript</strong>
          </p>
          <div dangerouslySetInnerHTML={{ __html: overview?.transcript }}></div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-qna"
          role="tabpanel"
          aria-labelledby="pills-qna-tab"
          tabindex="0"
        >
          <DiscussionEmbed
            shortname="example"
            config={{
              url: window.location.href,
              identifier: params.id,
            }}
          />
        </div>
        <div
          className="tab-pane fade"
          id="pills-review"
          role="tabpanel"
          aria-labelledby="pills-review-tab"
          tabindex="0"
        >
          <p>
            <strong>Course Review</strong>
          </p>
          <div className="d-flex justify-content-between">
            <div className="course-rating">
             {avgRating}
              <br />
              <i className="fa-solid fa-ranking-star"></i>
            </div>
            <div className="course-review-comment">
              Write Review
              <textarea
                rows="5"
                cols="5"
                className="form-control"
                value={review.comment}
                onChange={(e) =>
                  setReview({ ...review, comment: e.target.value })
                }
              ></textarea>
              Rate&nbsp;
              <StarRatings
                rating={review.rating}
                starRatedColor="#DC7215"
                changeRating={(newRating, name) =>
                  setReview({ ...review, rating: newRating })
                }
                numberOfStars={5}
                starDimension="24px"
                starSpacing="5px"
                name="rating"
              />
              <p className="mt-3">
                <button className="btn btn-primary-regular" onClick={submitReview}>
                  Submit Review
                </button>
                <button
                  className="btn"
                  onClick={() =>
                    setReview({ ...review, comment: "", rating: 0 })
                  }
                >
                  Cancel
                </button>
              </p>
              {reviews?.map((review) => {
                return (
                  <div key={review?._id} className="review-list">
                    <span className="name-icon">
                      {review?.studentid?.firstName[0]}
                    </span>
                    <ul>
                      <li>
                        {review?.studentid?.firstName}{" "}
                        {review?.studentid?.lastName}
                      </li>
                      <li>
                        <StarRatings
                          rating={Number(review?.rating)}
                          starRatedColor="#DC7215"
                          starDimension="20px"
                          starSpacing="5px"
                        />
                      </li>
                      <li>{review?.comment}</li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
