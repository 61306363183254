import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Sidebar/Navbar";
import { useNavigate } from "react-router-dom";
import { url } from "../Constants";

const Layout = ({ news }) => {
  const navigate = useNavigate();

  const checkToken = async () => {
    const response = await fetch(`${url}/auth/verifyuser`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });

    const json = await response.json();

    // corrupted token
    if (json.success === false) {
      localStorage.removeItem("token");
      navigate("/login");
    }

    localStorage.setItem("UserFilter", json.data.filter);
    localStorage.setItem(
      "name",
      String(json.data.firstName + " " + json.data.lastName)
    );
    localStorage.setItem("subscription_id", json?.activeSubscription?.subscriptionid);
  };

  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    checkToken();
  }, []);

  return (
    <>
      <div className="container-fluid d-flex px-0">
        <div className="page-container no-leftnav">
          <>
            <Navbar news={news} />
            <Outlet />
            {/* <script src="//code.tidio.co/dy6tp59099psaaihmspn4xutfmxfsnil.js"></script> */}
          </>
        </div>
      </div>
    </>
  );
};

export default Layout;
