import React, { useEffect, useState } from "react";
import CalenderView from "./FullCalender";
import API from "../../Api/Api";

const Calender = () => {
  const [events, setEvents] = useState([
    {
      date: "2024-02-01",
      color: "red",
      title: "E1",
    },
    {
      date: "2024-02-02",
      color: "green",
      title: "E2",
    },
  ]);

  const getUserCalender = async () => {
    try {
      const response = await API(
        "get",
        `/calender/${localStorage.getItem("_id")}`
      );
      setEvents(response?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (localStorage.getItem("_id")){
      getUserCalender();
    }
  
  }, []);

  return (
    // <div className="body-content no-filterpanel">
    //   <div className="body-panel d-flex justify-content-between">
    //     <div className="body-container width-100">
    //        <h1>User Calender</h1>
    //       <br />
    //       <div style={{ background: "white", padding: "2rem 1rem" }}>
            <CalenderView events={events} />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Calender;
