import React, { useEffect, useState } from "react";
import "../Assets/Styles/coaching.css";
import CourseDetails from "./WatchCourse/CourseDetails";
import { useParams } from "react-router-dom";
import API from "../../Api/Api";
import swal from "sweetalert";
import GraphIcon from "./svg/GraphIcon";
import CourseSidebar from "./WatchCourse/CourseSidebar";
import ReactPlayer from "react-player";
import { Quiz } from "./Quiz/Quiz";

const WatchCourse = () => {
  const [course, setCourse] = useState({});
  const params = useParams();
  const [activeChapter, setActiveChapter] = useState({});
  const [playingSeconds, setPlayingSeconds] = useState("");

  const progress = JSON.parse(localStorage.getItem("progreess")) ?? [];

  function secondsToMinutesSeconds(seconds) {
    // Calculate minutes and remaining seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    // Format the result
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const getCourse = async () => {
    try {
      const data = await API(
        "get",
        `/lms/student/view/course-and-lessons/${params.id}`
      );
      setCourse(data?.data?.data);
      setActiveChapter(data?.data?.data?.lessons[0]);
      //console.log(data?.data.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  const markProgress = async () => {
    try {
      const data = await API(
        "post",
        `/lms/student/update/progress/mark-complete`,
        {
          studentId: localStorage.getItem("_id"),
          courseId: params.id,
          lessonId: activeChapter?._id,
          isCompleted: true,
        }
      );
      swal({
        title: "Success!",
        text: data?.data?.message,
      });
      let arr = [...progress, activeChapter?._id];
      localStorage.setItem("progreess", JSON.stringify(arr));
      window.location.reload();
    } catch (error) {
    } finally {
    }
  };

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div className="body-container">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-between coaching-lms">
                <div style={{ flex: "2" }}>
                  <div className="page-content mt-3">
                    <h1 className="mb-3">{course?.name}</h1>
                  </div>
                  <div className="video-container">
                    {
                      activeChapter?.total_questions ? 
                        <Quiz questions={activeChapter?.data} /> : 
                        <ReactPlayer
                        url={activeChapter?.content}
                        height={"500px"}
                        controls={true}
                        width={"100%"}
                        onProgress={(e) =>
                          setPlayingSeconds(
                            secondsToMinutesSeconds(e?.playedSeconds?.toFixed(0))
                          )
                        }
                      />
                    }
                    
                  </div>
                  <br />
                  {!progress?.includes(activeChapter?._id) && (
                    <div>
                      <button
                        style={{ width: "200px" }}
                        className="btn btn-primary"
                        onClick={() => markProgress()}
                      >
                        Mark as Completed
                      </button>
                    </div>
                  )}

                  <br />
                  <CourseDetails
                    overview={{
                      lessonid: activeChapter?._id,
                      // isvideo: activeChapter?.total_questions ? false : true,
                      // data: activeChapter?.data,
                      description: course?.description,
                      transcript: activeChapter?.transcript,
                      playingSeconds: playingSeconds,
                    }}
                  />
                </div>
                <div className="ms-4 w-100p" style={{ flex: "1" }}>
                  <div className="course-progress float-end">
                    <span className="ps-2">Course Progress</span>
                  </div>
                  <div className="course-progress float-end me-1">
                    <GraphIcon />
                    <span className="ps-2">Course Assessment</span>
                    <span className="completed-assessment">
                      {progress?.length}/{course?.lessons?.length} completed
                    </span>
                  </div>
                  <div className="clearfix"></div>
                  <CourseSidebar
                    lessons={course?.lessons}
                    activeChapter={activeChapter}
                    setActiveChapter={setActiveChapter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchCourse;
