import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import NavBarAdmin from "./Nav/Nav";
import { OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { MdDelete, MdBook, MdMail, MdPlusOne, MdHdrPlus, MdAdd, MdEdit } from "react-icons/md";
import swal from "sweetalert";
import AllBookingAdmin from "../Mentors/Pages/AllBookingsStudentAdmin";
import readXlsxFile from "read-excel-file";
import { url } from "../Constants";
import moment, { duration } from "moment/moment";

const ProfileLeadFormDashboard = () => {
  const [showBookings, setShowBookings] = useState(false);
  const [readData, setReadData] = useState([]);
  const [metaData, setMetaData] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRead = (e) => {
    setMetaData({ ...metaData, name: e[0].name });
    readXlsxFile(e[0]).then((rows) => {
      setReadData(rows);
    });
  };
  const handleSubmit = async (credentials) => {
    const studyDest = credentials.countryofinterest;
    const greSplit = credentials.gre?.split(" | ") || ["", "", "", ""];
    const gmatSplit = credentials.gmat?.split(" | ") || ["", "", "", "", ""];    
    const toeflSplit = credentials.toefl?.split(" | ") || ["", "", "", "", ""];
    const ieltsSplit = credentials.ielts?.split(" | ") || ["", "", "", "", ""];

    try {
      const response = await API("post", `/user/basicprofile-add-user`, {
        fname: credentials.firstName,
        lname: credentials.lastName,
        phone: credentials.phone,
        dest: studyDest,
        email: credentials.email,
        level: credentials.level,
        countryCode: credentials.countryCode,
        dob: credentials.dob,
        user_counsellor: localStorage.getItem("_id"),
        education: {
          hsc: {
            grade: credentials.hscgrade
          },
          ssc: {
            grade: credentials.sscgrade
          },
          grad: {
            courseSpcl: credentials.gradcourse,
            univName: credentials.graduniversity,
            cgpaBacklog: credentials.gradcgpa,
            duration: credentials.gradduration,
          },
          postGrad: {
            courseSpcl: credentials.postgradcourse,
            univName: credentials.postgraduniversity,
            cgpaBacklog: credentials.postgradcgpa,
            duration: credentials.postgradduration,
          },
        },
        testTaken: {
          gre: {
            score: greSplit[0],
            quantitative:greSplit[1],
            verbal:greSplit[2],
            analytic:greSplit[3],
          },
          gmat: {
            score:gmatSplit[0],
            quantitative:gmatSplit[1],
            verbal:gmatSplit[2],
            analytic:gmatSplit[3],
            reasoning:gmatSplit[4],
          },
          ielts: {
            score: ieltsSplit[0],
            listen: ieltsSplit[1],
            read: ieltsSplit[2],
            write: ieltsSplit[3],
            speak: ieltsSplit[4],
          },
          toefl: {
            score: toeflSplit[0],
            listen: toeflSplit[1],
            read: toeflSplit[2],
            write: toeflSplit[3],
            speak: toeflSplit[4],
          },
        },
      });
      const json = response?.data;
      swal({
        title: "User Added!",
      });
    } catch (err) {}
  };

  const getLeads = async () => {
    setLoading(true);
    try {
      const response = await API("get", `/profile?page=${currentPage}`);
      // console.log(response?.data?.data);
      setData(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await API("delete", `/profile/${id}`);
      swal({
        title: "Success!",
        text: "Deleted Successfully!",
        icon: "success",
        button: "Ok!",
      });
      getLeads();
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getLeads();
  }, [currentPage]);

  const handleUpload = async () => {
    for (let i = 1; i < readData.length; i++) {
      let data = {
        firstName: readData[i][0],
        lastName: readData[i][1],
        email: readData[i][2],
        countryCode: readData[i][3],
        phone: readData[i][4],
        message: readData[i][5],
        hearaboutus: readData[i][6],
        createdAt: readData[i][7],
      };
      try {
        await API("post", `/lead/excel`, data);
        getLeads();
      } catch (error) {}
    }
  };

  const [actionModal, setActionModal] = useState({
    notes: false,
    mail: false,
    email: "",
    user: "",
  });

  const [mail, setMail] = useState({
    subject: "",
    body: "",
  });

  const [notes, setNotes] = useState("");
  const [allNotes, setAllNotes] = useState({
    data: [],
    loading: false,
  });

  const [allMails, setAllMails] = useState({
    data: [],
    loading: false,
  });

  const getAllNotesForAUser = async (user, note) => {
    setAllNotes({ data: [], loading: true });
    try {
      const response = await fetch(`${url}/note/read`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          user: user,
          // createdBy: localStorage?.getItem("_id"),
        }),
      });
      const json = await response.json();
      setAllNotes({ data: [{ note: note }, ...json?.data], loading: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
      setAllNotes({ data: [], loading: false });
    }
  };

  const handleAddNotes = async () => {
    try {
      const response = await API("post", `${url}/note/create`, {
        note: notes,
        user: actionModal?.user,
        createdBy: localStorage?.getItem("_id"),
        type: "lead",
      });
      const json = response.data;
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setActionModal({ notes: false, mail: false });
      setNotes("");
    } catch (error) {
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">All Profile Leads</h1>
          {/* <div className="d-flex gap-2">
            <a href="/createlead" className="me-3 mt-2">Create Lead</a>
            <div className="d-flex">
            <input
              type="file"
              className="form-control file-upload"
              id="File"
              onChange={(e) => handleRead(e.target.files)}
            />
            <button className=" ms-2 btn btn-primary-regular" onClick={handleUpload}>
              Upload
            </button>
            </div>
          </div> */}
        </div>
        <br />
        {loading ? (
          <p>Please wait loading leads...</p>
        ) : (
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Country of Interest</th>
                  <th scope="col">Course of Interest</th>
                  <th scope="col">Level</th>
                  <th scope="col">Spcialization / Concentration</th>
                  <th scope="col">10th / HSC Year</th>
                  <th scope="col">10th / HSC Grade</th>
                  <th scope="col">12th / SSC Year</th>
                  <th scope="col">12th / SSC Grade</th>
                  <th scope="col">Graduation Course</th>
                  <th scope="col">Graduation University</th>
                  <th scope="col">Graduation CGPA</th>
                  <th scope="col">Graduation Duration</th>
                  <th scope="col">Post Graduation Course</th>
                  <th scope="col">Post Graduation University</th>
                  <th scope="col">Post Graduation CGPA</th>
                  <th scope="col">Post Graduation Duration</th>
                  <th scope="col">IELTS</th>
                  <th scope="col">TOEFL</th>
                  <th scope="col">GRE</th>
                  <th scope="col">GMAT</th>
                  <th scope="col">Internship Company</th>
                  <th scope="col">Internship Duration</th>
                  <th scope="col">Project / Publication</th>
                  <th scope="col">Work Experience Company</th>
                  <th scope="col">Work Experience Job Title</th>
                  <th scope="col">Work Experience Duration</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Location</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((e, id) => (
                    <tr key={id}>
                      <th scope="row">{id + 1}</th>
                      <td>{e?.firstName} {e?.lastName}</td>
                      <td>
                        <a href={`mailto:${e?.email}`}>{e?.email}</a>
                      </td>
                      <td>{e?.dob}</td>
                      <td>
                        {e?.countryCode} {e.phone}
                        <br />
                        <a href={`tel:${e?.countryCode}${e.phone}`}>
                          <i className="fa fa-phone"></i>
                        </a>{" "}
                        |{" "}
                        <a href={`https://wa.me/${e?.countryCode}${e.phone}`}>
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </td>
                      <td>{e?.countryofinterest?.join(', ')}</td>
                      <td>{e?.courseofinterest}</td>
                      <td>{e?.level}</td>
                      <td>{e?.spcialization}</td>
                      <td>{e?.hscyearpassing}</td>
                      <td>{e?.hscgrade}</td>
                      <td>{e?.sscyearofpassing}</td>
                      <td>{e?.sscgrade}</td>
                      <td>{e?.gradcourse}</td>
                      <td>{e?.graduniversity}</td>
                      <td>{e?.gradcgpa}</td>
                      <td>{e?.gradduration}</td>
                      <td>{e?.postgradcourse}</td>
                      <td>{e?.postgraduniversity}</td>
                      <td>{e?.postgradcgpa}</td>
                      <td>{e?.postgradduration}</td>
                      <td>{e?.ielts ? e.ielts : "-"}</td>
                      <td>{e?.toefl ? e.toefl : "-"}</td>
                      <td>{e?.gre ? e.gre : "-"}</td>
                      <td>{e?.gmat ? e.gmat : "-"}</td>
                      <td>{e?.internshipcompany}</td>
                      <td>{e?.internshipduration}</td>
                      <td>{e?.internshipproject}</td>
                      <td>{e?.workexpcompany}</td>
                      <td>{e?.workexpjobtitle}</td>
                      <td>{e?.workexpduration}</td>
                      <td>
                        {moment(e.createdAt).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td>{e?.location.city}, {e?.location.country_name}</td>
                      <td>
                        {" "}
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Email</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              navigate(
                                "/dashboard-mass-email?email=" + e?.email
                              );
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdMail className="" style={{ color: "#DC7215" }} />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Add / View Notes</Tooltip>
                          }
                        >
                          <button
                            onClick={() => {
                              setActionModal({
                                mail: false,
                                notes: true,
                                user: e?._id,
                              });
                              getAllNotesForAUser(e?._id, e?.note);
                              // setAllNotes({ title: }, ...notes);
                            }}
                            className="btn useDeleteBtn"
                          >
                            <MdBook style={{ color: "#DC7215" }} />{" "}
                            {/* Add / View Notes */}
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover the lead data!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((res) => {
                                if (res) {
                                  // console.log(res)
                                  handleDelete(e?._id);
                                }
                              });
                              // handleDelete(e?._id)
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdDelete className="MdDelete" />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Add as User</Tooltip>
                          }
                        >
                          <button
                            onClick={()=> handleSubmit(e)}
                            className="btn useDeleteBtn"
                          >
                            <MdAdd style={{ color: "#DC7215" }} />{" "}
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="required p-2">No Data Found</p>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Modal
          show={actionModal?.notes}
          onHide={() => setActionModal({ mail: false, notes: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add/View Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!allNotes?.loading ? (
              allNotes?.data?.map((itm, i) => (
                <div key={i}>
                  <h2 style={{ fontWeight: "600" }}>{itm?.note}</h2>
                  {itm?.createdAt && (
                    <p>
                      Created at:{" "}
                      {moment(itm?.createdAt).format("DD-MMM-YYYY HH:mm A")}
                    </p>
                  )}
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
            <div className="form-floating">
              <input
                className="form-control"
                placeholder=""
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />{" "}
              <label for="floatingTextarea">Notes</label>
            </div>
            <Button
              variant="primary-regular mt-3"
              disabled={!notes}
              onClick={() => {
                handleAddNotes();
              }}
            >
              Send
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setActionModal({ mail: false, notes: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Pagination className="d-flex justify-content-end mb-5">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default ProfileLeadFormDashboard;
