import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../Constants";
import swal from "sweetalert";
import Select from "react-select";
import countryStates from "../Assets/Data/state.json";
import countryUni from "../Assets/Data/university.json";
import countryRace from "../Assets/Data/raceethnicity.json";
const AddScholarship = () => {
  const navigate = useNavigate();
  const [item, setItem] = useState({
    Scholarship_Name: "",
    Destination_Country: "",
    Applicable_for: "",
    Amount: "",
    Awarding_Body: "",
    Program_Level: "",
    Study_Area: "",
    State: "",
    Application_Start_Date: "",
    Type_of_Scholarship: "",
    Eligible_Citizenship: "",
    Application_End_Date: "",
    University: "",
    Application_Fee: "",
    Application_Link: "",
    Description: "",
    Requirement: "",
    Academic_Eligibility: "",
    Work_Exp: "",
    Race_Ethnicity: "",
    Sex: "",
    Number_of_Awards: "",
    Category: "",
    Contact_Name: "",
    Contact_ID: "",
    Contact_Number: "",
    Image_Addres: "",
    Weblink: "",
  });

  const [stateOptions, setStateOptions] = useState([]);

  let countryOptions = countryStates?.map((getcountry) => ({
    value: getcountry.country_name,
    label: getcountry.country_name,
  }));

  useEffect(() => {
    if (item?.Destination_Country) {
      setItem({ ...item, State: "", University: "", Race_Ethnicity: "" });
      setStateOptions(
        countryStates
          .find((itm) => itm.country_name == item.Destination_Country)
          ?.states?.map((itm) => {
            return { value: itm?.state_name, label: itm?.state_name };
          })
      );
    }
  }, [item?.Destination_Country]);

  let universityOptions =
    countryUni[item?.Destination_Country] !== undefined
      ? countryUni[item?.Destination_Country].map((getstate, index) => ({
          value: getstate,
          label: getstate,
        }))
      : [];

  let raceEthOptions =
    countryRace[item?.Destination_Country] !== undefined
      ? countryRace[item?.Destination_Country].map((getstate) => ({
          label: getstate,
          value: getstate,
        }))
      : [];

  const getData = (e) => {
    const { name, value } = e.target;
    setItem((newData) => {
      return {
        ...newData,
        [name]: value,
      };
    });
  };

  const postData = async (e) => {
    e.preventDefault();
    const {
      Scholarship_Name,
      Destination_Country,
      Applicable_for,
      Amount,
      Awarding_Body,
      Program_Level,
      Study_Area,
      State,
      Application_Start_Date,
      Type_of_Scholarship,
      Eligible_Citizenship,
      Application_End_Date,
      University,
      Application_Fee,
      Application_Link,
      Description,
      Requirement,
      Academic_Eligibility,
      Work_Exp,
      Race_Ethnicity,
      Sex,
      Number_of_Awards,
      Category,
      Contact_Name,
      Contact_ID,
      Contact_Number,
      Image_Addres,
      Weblink,
    } = item;

    try {
      if (Scholarship_Name === "") {
        swal({
          title: "Error",
          text: "Please Provide Scholarship Name.",
          icon: "info",
          button: "Ok",
        });
      } else if (Destination_Country === "") {
        swal({
          title: "Error",
          text: "Please Provide Destination Country.",
          icon: "info",
          button: "Ok",
        });
      }
      // else if (Applicable_for === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Applicable for.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Amount === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Amount.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // }
      else if (Awarding_Body === "") {
        swal({
          title: "Error",
          text: "Please Provide Awarding_Body.",
          icon: "info",
          button: "Ok",
        });
      } else if (Program_Level === "") {
        swal({
          title: "Error",
          text: "Please Provide Program Level.",
          icon: "info",
          button: "Ok",
        });
      }
      // else if (Study_Area === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Study Area.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (State === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide State.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Application_Start_Date === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Application Start Date.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Type_of_Scholarship === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Type of Scholarship.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Eligible_Citizenship === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Eligible Citizenship.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Application_End_Date === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Application End Date.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (University === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide University.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Application_Fee === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Application Fee.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // }
      else if (Application_Link === "") {
        swal({
          title: "Error",
          text: "Please Provide Application Link.",
          icon: "info",
          button: "Ok",
        });
      }

      // else if (Description === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Description.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Requirement === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Destination Country.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Academic_Eligibility === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Academic Eligibility.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Work_Exp === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Work Experience.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Race_Ethnicity === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Race Ethnicity.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Number_of_Awards === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Number of Awards.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Category === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Category.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Contact_Name === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Contact Name.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // } else if (Weblink === "") {
      //   swal({
      //     title: "Error",
      //     text: "Please Provide Weblink.",
      //     icon: "info",
      //     button: "Ok",
      //   });
      // }
      else {
        const res = await fetch(`${url}/addScholarship`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            Scholarship_Name,
            Destination_Country,
            Applicable_for,
            Amount,
            Awarding_Body,
            Program_Level,
            Study_Area,
            State,
            Application_Start_Date,
            Type_of_Scholarship,
            Eligible_Citizenship,
            Application_End_Date,
            University,
            Application_Fee,
            Application_Link,
            Description,
            Requirement,
            Academic_Eligibility,
            Work_Exp,
            Race_Ethnicity,
            Sex,
            Number_of_Awards,
            Category,
            Contact_Name,
            Contact_ID,
            Contact_Number,
            Image_Addres,
            Weblink,
          }),
          mode: "cors",
          referrerPolicy: "origin-when-cross-origin",
        });
        //const finalPost = await res.json()
        //console.log(finalPost)
        swal({
          title: "Success!",
        });
        navigate("/dashboard-scholarship");
      }
    } catch (error) {
      //console.log(error)
    }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  return (
    <div className="container admin-createpage">
      <div className="main-heading">Add Scholarship</div>

      <div>
        <div className="sep" />
        <div className="page-form">
          <div className="row">
            <div className="col-3">
              <label className="form-label">Scholarship Name<span className="required">*</span></label>
              <input
                value={item.Scholarship_Name}
                onChange={getData}
                name="Scholarship_Name"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Awarding Body<span className="required">*</span></label>
              <input
                value={item.Awarding_Body}
                onChange={getData}
                name="Awarding_Body"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Type of Scholarship</label>
              <input
                value={item.Type_of_Scholarship}
                onChange={getData}
                name="Type_of_Scholarship"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">University</label>
              <Select
                value={{ value: item.University, label: item.University }}
                options={universityOptions}
                onChange={(selectedOption) => {
                  setItem({ ...item, University: selectedOption.value });
                }}
              />
              {/* <input
                value={item.University}
                onChange={getData}
                name="University"
                type="text"
                className="form-control"
              /> */}
            </div>
            <div className="col-3">
              <label className="form-label">Weblink</label>
              <input
                value={item.Weblink}
                onChange={getData}
                name="Weblink"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Destination Country<span className="required">*</span></label>
              <Select
                // value={item.Destination_Country}
                options={[
                  { value: "", label: "Please Select" },
                  ...countryOptions,
                ]}
                onChange={(selectedOption) => {
                  setItem({
                    ...item,
                    Destination_Country: selectedOption.value,
                  });
                }}
              />
              {/* <input
                value={item.Destination_Country}
                onChange={getData}
                name="Destination_Country"
                type="text"
                className="form-control"
              /> */}
            </div>
            <div className="col-3">
              <label className="form-label">Program Level<span className="required">*</span></label>
              <input
                value={item.Program_Level}
                onChange={getData}
                name="Program_Level"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Study Area</label>
              <input
                value={item.Study_Area}
                onChange={getData}
                name="Study_Area"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Eligible Citizenship</label>
              <Select
                value={{
                  value: item.Eligible_Citizenship,
                  label: item.Eligible_Citizenship,
                }}
                options={countryOptions}
                onChange={(selectedOption) => {
                  setItem({
                    ...item,
                    Eligible_Citizenship: selectedOption.value,
                  });
                }}
              />
              {/* <input
                value={item.Eligible_Citizenship}
                onChange={getData}
                name="Eligible_Citizenship"
                type="text"
                className="form-control"
              /> */}
            </div>
            <div className="col-3">
              <label className="form-label">State</label>
              <Select
                value={{ value: item.State, label: item.State }}
                options={stateOptions}
                onChange={(selectedOption) => {
                  setItem({ ...item, State: selectedOption.value });
                }}
              />
              {/* <input
                value={item.State}
                onChange={getData}
                name="State"
                type="text"
                className="form-control"
              /> */}
            </div>
            <div className="col-3">
              <label className="form-label">Amount</label>
              <input
                value={item.Amount}
                onChange={getData}
                name="Amount"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Applicable for</label>
              <input
                value={item.Applicable_for}
                onChange={getData}
                name="Applicable_for"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application Start Date</label>
              <input
                value={item.Application_Start_Date}
                onChange={getData}
                name="Application_Start_Date"
                type="date"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application End Date</label>
              <input
                value={item.Application_End_Date}
                onChange={getData}
                name="Application_End_Date"
                type="date"
                className="form-control"
                min={
                  item?.Application_Start_Date
                    ? new Date(item?.Application_Start_Date)
                        .toISOString()
                        ?.split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application Fee</label>
              <input
                value={item.Application_Fee}
                onChange={getData}
                name="Application_Fee"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application Link<span className="required">*</span></label>
              <input
                value={item.Application_Link}
                onChange={getData}
                name="Application_Link"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Description</label>
              <input
                value={item.Description}
                onChange={getData}
                name="Description"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Requirement</label>
              <input
                value={item.Requirement}
                onChange={getData}
                name="Requirement"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Academic Eligibility</label>
              <input
                value={item.Academic_Eligibility}
                onChange={getData}
                name="Academic_Eligibility"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Work Exp</label>
              <input
                value={item.Work_Exp}
                onChange={getData}
                name="Work_Exp"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Sex</label>
              <input
                value={item.Sex}
                onChange={getData}
                name="Sex"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Race Ethnicity</label>
              <Select
                value={{
                  value: item.Race_Ethnicity,
                  label: item.Race_Ethnicity,
                }}
                options={raceEthOptions}
                onChange={(selectedOption) => {
                  setItem({ ...item, Race_Ethnicity: selectedOption.value });
                }}
              />
              {/* <input
                value={item.Race_Ethnicity}
                onChange={getData}
                name="Race_Ethnicity"
                type="text"
                className="form-control"
              /> */}
            </div>
            <div className="col-3">
              <label className="form-label">Number of Awards</label>
              <input
                value={item.Number_of_Awards}
                onChange={getData}
                name="Number_of_Awards"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Category</label>
              <input
                value={item.Category}
                onChange={getData}
                name="Category"
                type="text"
                className="form-control"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Contact Name</label>
              <input
                value={item.Contact_Name}
                onChange={getData}
                name="Contact_Name"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Contact ID</label>
              <input
                value={item.Contact_ID}
                onChange={getData}
                name="Contact_ID"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Contact Number</label>
              <input
                value={item.Contact_Number}
                onChange={getData}
                name="Contact_Number"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Image Addres</label>
              <input
                value={item.Image_Addres}
                onChange={getData}
                name="Image_Addres"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <br />
          <button
            type="submit"
            onClick={postData}
            className="btn btn-primary-regular me-3"
          >
            Submit
          </button>
          <button
            type="submit"
            onClick={backButton}
            className="btn btn-secondary-regular"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddScholarship;
