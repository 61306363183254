import React, { useEffect, useState } from "react";
import NavBarAdmin from "./Nav/Nav";
import RichTextEditor from "react-rte";
import API from "../../Api/Api";
import swal from "sweetalert";

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
    "IMAGE_BUTTON",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const Signature = () => {
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [data, setData] = useState({
    html: "",
  });

  const handleSave = async () => {
    try {
      await API("post", "/signature", data);
      swal({
        title: "Success!",
        text: "Signature Updated",
        icon: "success",
        button: "Ok!",
      });
    } catch (error) {}
  };
  const getSig = async () => {
    try {
      const data = await API("get", "/signature");
      setData({
        html: data?.data?.data?.html,
      });
      let value = RichTextEditor.createValueFromString(
        data?.data?.data?.html,
        "html"
      );
      setEditorValue(value);
    } catch (error) {}
  };

  useEffect(() => {
    getSig();
  }, []);
  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">System Emails Signature</h1>
        </div>
        <div className="d-flex justify-content-between grid-frame p-4">
          <textarea
          rows={5}
          cols={80}
          value={data?.html}
          onChange={(e) => {
            let html = RichTextEditor.createValueFromString(e.target.value,'html')
            setEditorValue(html);
            setData({
              ...data,
              html: e.target.value.toString("html"),
            });
          }}
        />
        <RichTextEditor
          value={editorValue}
          onChange={(e) => {
            //console.log(e)
            setEditorValue(e);
            setData({
              ...data,
              html: e.toString("html"),
            });
          }}
          toolbarConfig={toolbarConfig}
        />
        
        </div>
        <button className="btn btn-primary-regular mt-3" onClick={handleSave}>
          {" "}
          Save
        </button>
      </div>
    </div>
  );
};

export default Signature;