import { useEffect, useLayoutEffect, useState } from "react";
import subsNavCheck from "../utils/subscriptionNavCheck.js";
import { useLocation } from "react-router-dom";

export const SubsCheck = ({minutes}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    subsNavCheck(window.location.href, minutes);
  }, [location]);

  return <></>;
};
