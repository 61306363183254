import React, { useEffect, useState } from "react";
import PlayIcon from "../svg/PlayIcon";
import swal from "sweetalert";
import API from "../../../Api/Api";

const CourseSidebar = ({ lessons, activeChapter, setActiveChapter }) => {
  const [chapters, setChapters] = useState({});
  const progress = JSON.parse(localStorage.getItem("progreess")) ?? [];

  useEffect(() => {
    let chapterArr = [];
    if (lessons) {
      let arr = lessons?.reduce((acc, obj) => {
        const chapterId = obj?.chapterid?.name;
        if (!chapterArr.find((itm) => itm.id == obj?.chapterid?._id)) {
          chapterArr.push({ name: chapterId, id: obj?.chapterid?._id });
        }
        if (!acc[chapterId]) {
          acc[chapterId] = [];
        }
        acc[chapterId].push(obj);
        return acc;
      }, {});

      chapterArr?.map((chapter) => {
        API("get", `/test/student/course/get-quiz/${chapter.id}`).then((res) => {
          if (res?.data?.data?.length > 0) {
            arr[chapter.name] = [
              ...arr[chapter.name],
              {
                name: `Chapter `+chapter.name + `-  Quiz`,
                _id: chapter.id,
                total_questions: res?.data?.data?.length,
                data: {  name:  `Chapter `+chapter.name + `-  Quiz`,questions:res?.data?.data}
              },
            ];
          }
        })
     
      });
      setChapters(arr);
    }
  }, [lessons]);

  return (
    <div className="course-content widget-border">
      <div className="d-flex justify-content-between course-content-description">
        <p>Course Content</p>
        {/* <a href="">
          <i className="fa fa-times fa-lg"></i>
        </a> */}
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        {Object?.keys(chapters)?.map((key, index) => (
          <div className="accordion-item" key={key}>
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#panelsStayOpen-${index}`}
                aria-expanded="true"
                aria-controls={`panelsStayOpen-${index}`}
              >
                <span className="section-heading">{key}</span>
                {/* <small>0/4 | 12 min</small> */}
              </button>
            </h2>
            <div
              id={`panelsStayOpen-${index}`}
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body">
                <ul>
                  {chapters[key]?.map((lesson) => {
                    return (
                      <li
                        className={`${
                          lesson?._id == activeChapter?._id
                            ? "active-chapter"
                            : ""
                        }`}
                        key={lesson?._id}
                        onClick={() => {
                          if (progress?.includes(lesson?._id)) {
                            setActiveChapter(lesson);
                          } else {
                            let less = [];
                            Object?.keys(chapters)?.map((key, index) => {
                              less.push(...chapters[key]);
                            });
                            let curr = less?.indexOf(
                              less.find((itm) => itm?._id == lesson?._id)
                            );
                            let elem = less[curr - 1];
                            if (progress.includes(elem?._id)) {
                              setActiveChapter(lesson);
                            } else {
                              swal({
                                title: "Info",
                                text: "Please complete previous chapter first",
                                icon: "info",
                              });
                            }
                          }
                        }}
                      >
                        <span className="d-flex">
                          <PlayIcon /> &nbsp;
                          <span
                            className="d-flex justify-content-between"
                            style={{ width: "100%" }}
                          >
                            <span>{lesson?.name} </span>
                            <span style={{ color: "green" }}>
                              {progress?.includes(lesson?._id)
                                ? "Completed"
                                : ""}
                            </span>
                          </span>
                        </span>
                        <br />
                        <small className="chaper-time">{lesson?.duration ?? 'Total Questions: '+lesson?.total_questions}</small>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseSidebar;
