import React, { useEffect, useState } from "react";
import NavBarAdmin from "./Nav/Nav";
import API from "../../Api/Api";
import swal from "sweetalert";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Modal,
  OverlayTrigger,
  Pagination,
  Tooltip,
} from "react-bootstrap";
import { MdBook } from "react-icons/md";

const DasboardReferred = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [editBody, setEditBody] = useState({
    id: "",
    amount: 0,
    registrationtype: "",
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getAffUsers = async (ref_id) => {
    setLoading(true);
    try {
      let id = ref_id ?? "";
      const response = await API(
        "get",
        "/affiliate/admin/get-referrals?ref_id=" + id
      );
      // console.log(response?.data?.data);
      setData(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (id, value) => {
    setLoading(true);
    // console.log(id, value);
    // return;
    try {
      const response = await API("put", "/affiliate/update-status/" + id, {
        ref_status: value,
      });
      swal({
        title: "Success",
        text: "Status Updated Successfully!",
        icon: "success",
        button: "Ok",
      });
      getAffUsers(searchParams.get("refferal_id"));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const response = await API(
        "put",
        "/affiliate/update-referral/" + editBody?.id,
        editBody
      );
      swal({
        title: "Success",
        text: "Status Updated Successfully!",
        icon: "success",
        button: "Ok",
      });
      setEditBody({
        id: "",
        amount: 0,
        registrationtype: "",
      });
      getAffUsers(searchParams.get("refferal_id"));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("refferal_id")) {
      getAffUsers(searchParams.get("refferal_id"));
    } else {
      getAffUsers();
    }
  }, []);

  const sortData = (arr, property, reverse) => {
    let sorted = arr.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    if (reverse) {
      sorted.reverse();
    }
    setLoading(true);
    setData(sorted);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <h1 className="page-title">Refferals</h1>
        {loading ? (
          <p>Please wait loading your referrals...</p>
        ) : (
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Id</th>
                  <th scope="col">
                    Name{" "}
                    <i
                      className="fa-solid fa-caret-up"
                      onClick={() => sortData(data, "fullName")}
                    ></i>{" "}
                    <i
                      className="fa-solid fa-caret-down"
                      onClick={() => sortData(data, "fullName", true)}
                    ></i>
                  </th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Referral Status</th>
                  <th scope="col">Referree Code</th>
                  <th scope="col">Referree Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Registration Type</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((e, id) => (
                  <tr key={id}>
                    <th scope="row">{id + 1}</th>
                    <td>
                      {e?.firstName} {e?.lastName}
                    </td>
                    <td>
                      <a href={`mailto:${e?.email}`}>{e?.email}</a>
                    </td>
                    <td>{e?.phone}</td>
                    <td>
                      <select
                        value={e?.ref_status}
                        onChange={(event) =>
                          updateStatus(e?._id, event.target.value)
                        }
                      >
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                      </select>
                      {/* {e?.ref_status? e?.ref_status : 'pending'} */}
                    </td>
                    <td>{e?.ref_id}</td>
                    <td>{e?.refName}</td>
                    <td>{e?.amount}</td>
                    <td>{e?.registrationtype}</td>
                    <td>
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip">Edit Amount / Type</Tooltip>
                        }
                      >
                        <button
                          disabled={false}
                          onClick={() => {
                            setShowModal(true);
                            setEditBody({ ...editBody, id: e?._id });
                          }}
                          className="btn"
                        >
                          <MdBook style={{ color: "#DC7215" }} />
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Amount / Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              className="form-control"
              placeholder="Amount"
              type="number"
              onChange={(e) =>
                setEditBody({ ...editBody, amount: e.target.value })
              }
            />
            <br />
            <select
              className="form-select"
              onChange={(e) =>
                setEditBody({ ...editBody, registrationtype: e.target.value })
              }
            >
              <option value="">Select One</option>
              <option value="Free">Free</option>
              <option value="Premium">Premium</option>
            </select>
            <br />
            <Button onClick={handleEdit}>Submit</Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant="primary">
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>

        <Pagination className="d-flex justify-content-end mb-5">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          {console.log(currentPage, totalPages)}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DasboardReferred;
