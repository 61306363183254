import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { url } from "../Constants";
import AssignStudents from "./AssignStudents";
import API from "../../Api/Api";
import countryCodes from "../Assets/Data/countries.json";
import list from "../Assets/Data/countries.json";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import country_codes from "../Assets/Data/countries_code.json";

function Edit() {
  const [scholarship, setScholarship] = useState([]);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    studyDest: [],
    sholarships: "",
    number: "",
    gender: "",
    dob: "",
    education: "",
    location: "",
    workexp: "",
    role: "",
    phone: "",
    countryCode: "",
    subscriptionItems: {
      freeAccessTime: 0,
    },
  });

  const Userdata = (e) => {
    const { name, value } = e.target;
    setData((newData) => {
      return {
        ...newData,
        [name]: value,
      };
    });
  };

  // create function for API call

  const { id } = useParams("");
  const navigate = useNavigate();

  const getUserDetails = async () => {
    try {
      const res = await fetch(`${url}/alldetails/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setData(jsonData.data);
      //console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  const getData = async () => {
    const res = await fetch(`${url}/getuser/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const newData = await res.json();
    setData(newData);
    const scholarshipData = newData.scholarships;
    setScholarship(scholarshipData);
  };

  useEffect(() => {
    getData();
    // getUserDetails();
  }, []);

  //  create function for updatation.

  const updateDetail = async (e) => {
    e.preventDefault();
    const { gender, dob, education, location, workexp } = data;
    const res2 = await fetch(`${url}/editUserDetail/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gender, dob, education, location, workexp }),
    });

    const update = await res2.json();
    //console.log(update);
  };

  const updateData = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      studyDest,
      sholarships,
      number,
      gender,
      dob,
      education,
      location,
      workexp,
      role,
      phone,
      countryCode,
      credits,
      subscriptionItems
    } = data;
    try {
      const res2 = await API("patch", `/edituser/${id}`, {
        firstName,
        lastName,
        email,
        studyDest,
        sholarships,
        number,
        gender,
        dob,
        education,
        location,
        workexp,
        role,
        phone,
        countryCode,
        credits,
        subscriptionItems
      });

      const update = res2.data;
      //console.log(update);
      swal({
        title: "Success",
        text: "Data Updated Successfully!",
        icon: "success",
        button: "Ok",
      });
    } catch (error) {}
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  const showDeleteUserModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this User!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("User has been deleted Successfuly!", {
          icon: "success",
        });
        deleteUser(id);
      }
    });
  };

  const deleteUser = async (id) => {
    try {
      const res = await fetch(`${url}/deleteuser/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();
      // console.log(deleteData);
      navigation("/dashboard");
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div className="container admin-createpage">
      <div>
        <div className="main-heading">Edit User</div>
      </div>
      <div className="sep" />
      <div className="page-form">
        <form>
          <div className="row">
            <div className="col">
              <label className="form-label">First Name</label>
              <input
                value={data.firstName}
                name="firstName"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label">Last Name</label>
              <input
                value={data.lastName}
                name="lastName"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
            {/* <div className="mb-3 col-4">
                            <label className="form-label">Gender</label>
                            <input value={data.gender} name='gender' onChange={Userdata} type="text" className="form-control" />
                        </div>
                        <div className="mb-3 col-4">
                            <label className="form-label">Date of Birth</label>
                            <input value={data.dob
                                ?
                                (new Date(data.dob).toLocaleDateString(
                                    'en-US', {
                                    day: "numeric",
                                    month: 'numeric',
                                    year: 'numeric'
                                }
                                ))
                                : ""
                            } name='dob' onChange={Userdata} type="text" className="form-control" />
                        </div>
                        <div className="mb-3 col-4">
                            <label className="form-label">Education</label>
                            <input value={data.education} name='education' onChange={Userdata} type="text" className="form-control" />
                        </div> */}
          </div>
          <div className="row">
            <div className="col-2">
              <label htmlFor="inputState" className="form-label">
                Country Code<span className="required">*</span>
              </label>
              <select
                list="countryCodeData"
                value={data.countryCode}
                onChange={Userdata}
                placeholder="choose.."
                className="form-select"
                name="countryCode"
              >
                <option>Please Select</option>
                {country_codes.map((op, i) => (
                  <option value={op.dial_code}>
                    {op.dial_code + " " + op.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <label className="form-label">Phone</label>
              <input
                value={data.phone}
                name="phone"
                onChange={Userdata}
                type="number"
                className="form-control"
              />
            </div>
            {/* <div className="mb-3 col-4">
                            <label className="form-label">Role</label>
                            <select value={data.role} name='role' onChange={Userdata} type="text" className="form-control">
                                <option>Select Designation</option>
                                <option>Admin</option>
                                <option>Human Resource (HR)</option>
                                <option>Counselor</option>
                                <option>Manager</option>
                                <option>Developer</option>
                            </select>
                        </div> */}
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="inputState" className="form-label mb-0">
                Study Destination<span className="required">*</span>
              </label>
              <Select
                className="app-multiSelect"
                isMulti
                value={data?.studyDest?.map((value) => ({
                  value,
                  label: value,
                }))}
                options={countryCodes.map((op, i) => ({
                  value: op.name,
                  label: op.name,
                }))}
                onChange={(selectedOptions) => {
                  const newData = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  setData((prevData) => ({
                    ...prevData,
                    studyDest: newData,
                  }));
                }}
                placeholder="Study Destination"
                isOptionDisabled={() => data?.studyDest?.length >= 3}
              />
            </div>

            {/* <div className="col">
              <label className="form-label">Study Destination</label>
              <input
                value={data.studyDest}
                name="studyDest"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div> */}
          </div>
          {/* <div className="mb-3 col-4">
                            <label className="form-label">Location</label>
                            <input value={data.location} name='location' onChange={Userdata} type="text" className="form-control" />
                        </div>
                        <div className="mb-3 col-4">
                            <label className="form-label">Work Experience</label>
                            <input value={data.workexp} name='workexp' onChange={Userdata} type="text" className="form-control" />
                        </div> */}
          {/* <div className="mb-3 col-4">
                            <label className="form-label">Sholarships</label>
                            <select value={data.scholarship} name='scholarship' onChange={Userdata} type="text" className="form-control">
                                <option>scholarship</option>
                                {scholarship.map((e) => {
                                    return (
                                        <>
                                            <option>{e.category}</option>
                                        </>
                                    )
                                })}
                            </select>
                        </div> */}
          <div className="row">
            <div className="col">
              <label className="form-label">Email address</label>
              <input
                value={data.email}
                name="email"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label">Free Access Time</label>
              <input
                value={data.subscriptionItems.freeAccessTime}
                name="subscriptionItems.freeAccessTime"
                onChange={(e) => {
                  setData({
                    ...data,
                    subscriptionItems: { freeAccessTime: e.target.value },
                  });
                }}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label">Role</label>
              <select
                className="form-select"
                name="role"
                value={data.role}
                onChange={Userdata}
                placeholder="Select Role"
              >
                <option value={"user"}>User</option>
                <option value={"Admin"}>Admin</option>
                <option value={"Counsellor"}>Counsellor</option>
              </select>
            </div>{" "}
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label">Credits</label>
              <input
                value={data.credits}
                name="credits"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div>
              <button
                type="submit"
                onClick={updateData}
                className="btn btn-primary-regular me-3"
              >
                Update
              </button>
              <button
                type="submit"
                onClick={backButton}
                className="btn btn-secondary-regular"
              >
                Back
              </button>
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  showDeleteUserModal(id);
                }}
                className="btn btn-danger"
              >
                Delete User
              </button>
            </div>
          </div>
        </form>
        <br />
        {data?.role === "Counsellor" && <AssignStudents userid={data._id} />}
      </div>
    </div>
  );
}

export default Edit;
