import React from "react";
import { Navigator } from "./Navigator";
import API from "../../Api/Api";

const Lessons = () => {
  return (
    <div style={{ marginTop: "8rem", marginLeft: "4rem" }}>
      <Navigator />
      <main>
        
      </main>
    </div>
  );
};

export default Lessons;
