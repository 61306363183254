import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";

import "./styles/MentorStyles.css";
import "./styles/font-awesome.min.css";

const Mentors = () => {
  const [mentors, setMentors] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const getMentors = async () => {
    setLoading(true);
    try {
      const data = await API("get", `/mentor/`);
      setMentors(data?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMentors();
  }, []);

  if (loading) {
    return (
      <div className="body-content no-filterpanel">
        <div className="body-panel d-flex justify-content-between">
          <div className="body-container width-100">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <header id="banner" className="scrollto clearfix" data-enllax-ratio=".5">
        <div id="banner-content" className="row clearfix">
          <div className="mentor-col-38">
            <div className="mentor-section-heading">
              <h1>Mentor in Academia</h1>
              <h2>Experience-driven Peer insights for academic success</h2>
            </div>
            <a href="#mentors" className="mentor-button">
              START TODAY
            </a>
          </div>
        </div>
      </header>
      <main id="content">
        <section id="about" className="introduction scrollto">
          <div className="row clearfix">
            <div className="mentor-col-3">
              <div className="mentor-section-heading">
                <h2 className="mentor-section-title">
                  How can this mentornity platform help you today?
                </h2>
                <p className="mentor-section-subtitle">
                  Connect with our student mentors aka university ambassadors
                  eager to share their insights and experiences to enrich your
                  study abroad journey. Tailor your mentor selection to your
                  needs, choosing from a diverse range of profiles, skills, and
                  offerings, and benefit from firsthand guidance from those
                  who've walked the path before you.
                </p>
              </div>
            </div>
            <div className="mentor-col-2-3">
              <div
                className="mentor-col-2 mentor-icon-block mentor-icon-top wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="mentor-icon">
                  <i className="fa fa-html5 fa-2x"></i>
                </div>
                <div className="mentor-icon-block-description">
                  <h4>Custom Essay Creation</h4>
                  <p>
                    Craft compelling Statements of Purpose and admission essay
                    with expert guidance from scratch.
                  </p>
                </div>
              </div>
              <div
                className="mentor-col-2 mentor-icon-block mentor-icon-top wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="mentor-icon">
                  <i className="fa fa-bolt fa-2x"></i>
                </div>
                <div className="mentor-icon-block-description">
                  <h4>Tailored Application Refinement & Personalization</h4>
                  <p>
                    Refine and personalize your admission essays, SOP, LOR and
                    Resumes with insider knowledge tailored to specific
                    university expectations.
                  </p>
                </div>
              </div>
              <div
                className="mentor-col-2 mentor-icon-block mentor-icon-top wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="mentor-icon">
                  <i className="fa fa-tablet fa-2x"></i>
                </div>
                <div className="mentor-icon-block-description">
                  <h4>Strategic University Decision-Making</h4>
                  <p>
                    Make University portfolio for application aligning course
                    focus and career aspirations and Finalize your university
                    choices post admit acceptance phase ensuring optimal fit for
                    successful admission and beyond.
                  </p>
                </div>
              </div>
              <div
                className="mentor-col-2 mentor-icon-block mentor-icon-top wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="mentor-icon">
                  <i className="fa fa-rocket fa-2x"></i>
                </div>
                <div className="mentor-icon-block-description">
                  <h4>Comprehensive Post-Admit Support</h4>
                  <p>
                    Expert guidance on dos and don'ts, additional courses,
                    university readiness profile refinement, and insider
                    information for a seamless transition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <aside id="mentors" className="scrollto text-center">
          <div className="row clearfix">
            <div className="mentor-section-heading">
              <h3>Mentors</h3>
              <h2 className="mentor-section-title">
                Know more about our mentors
              </h2>
            </div>
            {mentors?.filter(itm => itm.calendly_username).map((itm) => (
              <div className="mentor-col-3 testimonial classic">
                {itm?.avatar && itm?.calendly_username ? (
                  <div className="mentor-image-thumb">
                    <img src={itm?.avatar} />
                  </div>
                ) : (
                  <div className="mentor-image-thumb">
                    <i className="fa fa-user"></i>
                  </div>
                )}
                <q>
                  <span
                    dangerouslySetInnerHTML={{ __html: itm?.description }}
                  ></span>
                </q>
                {itm?.relExp && <span>Relevant Exp: {itm?.relExp}</span>}
                <footer>
                  {itm?.name}
                  <br />
                  {itm?.aoe}
                </footer>
                <div className="tags">
                  {itm?.tags?.map((itm, index) => (
                    <span className="tag" key={index}>
                      {itm}{" "}
                    </span>
                  ))}
                </div>
                <button
                  className="mentor-button-sm"
                  onClick={() => navigate(`/mentors/${itm?._id}`)}
                >
                  View Profile
                </button>
              </div>
            ))}
          </div>
        </aside>
      </main>
      <footer id="landing-footer" className="clearfix">
        <div className="row clearfix">
          <p id="copyright" className="mentor-col-2">
            Rise Higher Education
          </p>
          <ul className="mentor-col-2 social-icons">
            <li>
              <a
                target="_blank"
                title="Facebook"
                href="https://www.facebook.com/username"
              >
                <i className="fa fa-facebook fa-1x"></i>
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                title="Google+"
                href="http://google.com/+username"
              >
                <i className="fa fa-youtube fa-1x"></i>
                <span>Youtube</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                title="Twitter"
                href="http://www.twitter.com/username"
              >
                <i className="fa fa-twitter fa-1x"></i>
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                title="Instagram"
                href="http://www.instagram.com/username"
              >
                <i className="fa fa-instagram fa-1x"></i>
                <span>Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div className="body-container width-100">
          <h1>All Mentors</h1>
          <br />
          <div className="d-flex" style={{ gap: "1rem", flexWrap: "wrap" }}>
            {mentors?.map((itm) => (
              <div
                className="card flex-column justify-content-between"
                style={{ padding: "10px", width: "300px" }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {itm?.avatar ? (
                    <img
                      src={itm?.avatar}
                      alt="Avatar"
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "500px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div className="user-image-thumb">
                      <i className="fa fa-user"></i>
                    </div>
                  )}
                  <p style={{ textAlign: "center" }}>
                    <b>{itm?.name}</b> <br />
                    <i>
                      <span>{itm?.aoe}</span>
                    </i>
                  </p>
                  <p
                    className="d-flex justify-content-center"
                    style={{ gap: "0.6rem", flexWrap: "wrap" }}
                  >
                    {itm?.tags?.map((itm, index) => (
                      <span
                        className="badge bg-primary"
                        key={index}
                        style={{ fontSize: "14px", background: "#DC7215" }}
                      >
                        {itm}{" "}
                      </span>
                    ))}
                  </p>
                </div>

                <button
                  className="btn btn-primary"
                  onClick={() => navigate(`/mentors/${itm?._id}`)}
                >
                  Visit Profile
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentors;
