import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import API from "../../Api/Api";
import "../Assets/Styles/test.css";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import countryCodes from "../Assets/Data/countries.json";
import courseData from "../Assets/Data/coursecounseling.json";
import country_code from "../Assets/Data/countries_code.json";
import Select from "react-select";
import { Button, Modal, Nav, Pagination } from "react-bootstrap";
import PleaseLoginModal from "../PleaseLoginModal";

import { validateAssessment } from '../../utils/validation';

import { url } from '../Constants';
import swal from 'sweetalert';

import testEnquire from "../Assets/Data/test_enquire.json";

const groupById = (data) => {
  return data.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = [];
    }
    acc[item.id].push(item);
    return acc;
  }, {});
};

const groupByType = (data) => {
  return data.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item);
    return acc;
  }, {});
};

const testEnquireGrouped = groupById(testEnquire);

export const AssessmentPublic = () => {
  const [modal, setModal] = useState();

  const [allTest, setAllTest] = useState([]);
  const [userTest, setUserTest] = useState([]);
  const [course, setCourse] = useState([]);

  const [selectedTestType, setSelectedTestType] = useState(null);
  const [selectedTests, setSelectedTests] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({
    destination: [],
    subject: "",
    course: [],
    english_speaking: "",
    nationality: "",
    level: "",
    last_edu_country: "",
  });

  const [loading, setLoading] = useState({
    getAllTest: false,
    getUserTest: false,
  });

  const [listParams, setListParams] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    msg: "",
  });
  const [errors, setErrors] = useState({});

  const onChange = (event) => {
    setListParams({ ...listParams, [event.target.name]: event.target.value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[event.target.name];
      return newErrors;
    });
  };

  const getAllTests = async (
    destination,
    subject,
    course,
    nationality,
    level,
    last_edu_country
  ) => {
    setLoading({ ...loading, getAllTest: true });
    try {
      const response = await API(
        "get",
        `/test/all?destination=${destination.join(
          ","
        )}&subject=${subject}&course=${course.join(
          ","
        )}&nationality=${nationality}&level=${level}&last_edu_country=${last_edu_country}&is_user=${true}`
      );
      setAllTest(response?.data?.data);
    } catch (error) {
      setAllTest([]);
    } finally {
      setLoading({ ...loading, getAllTest: false });
    }
  };

  const getAllUserAssesment = async () => {
    setLoading({ ...loading, getUserTest: true });
    try {
      const response = await API(
        "get",
        `/test/student/all/${localStorage.getItem("_id")}`
      );
      setUserTest(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading({ ...loading, getUserTest: false });
    }
  };

  useEffect(() => {
    // Check for query parameters on mount
    const params = new URLSearchParams(location.search);
    const modalType = params.get('modal');

    if (modalType) {
      handleEnquireByType(modalType); // Trigger modal based on the query parameter
    }
  }, [location]);

  const handleEnquireById = (testId) => {
    const tests = groupById(testEnquire)[testId] || [];
    setSelectedTests(tests);
    setSelectedTestType(tests[0]?.type || null);
    setModal(true);
  };

  const handleEnquireByType = (testType) => {
    const tests = groupByType(testEnquire)[testType] || [];
    setSelectedTests(tests);
    setSelectedTestType(testType);
    setModal(true);
  };

  const hasEnquireData = (testId) => {
    return testEnquire.some((test) => test.id === testId);
  };

  const renderTableRows = (test) => {
    if (!test) return null;
    return Object.entries(test).map(([key, value]) => {
      if (key === "id") return null; // Exclude the 'id' field
      const formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to title case and replace underscores

      if (Array.isArray(value)) {
        return (
          <tr key={key}>
            <td><strong>{formattedKey}</strong></td>
            <td>
              <ul>
                {value.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </td>
          </tr>
        );
      }
      return (
        <tr key={key}>
          <td><strong>{formattedKey}</strong></td>
          <td>{value}</td>
        </tr>
      );
    });
  };

  useEffect(() => {
    // getAllTests();p
    // getAllUserAssesment();
    //
  }, []);

  useEffect(() => {
    if (data?.subject?.length > 0) {
      setCourse(courseData[data.subject]);
    }
  }, [data.subject]);

  useEffect(() => {
    //console.log(data);
  }, [data]);

  const selectedTest = selectedTests.find(test => test.type === selectedTestType);

  const sendMsg = async () => {
    const { isValid, errors: validationErrors } = validateAssessment(listParams);
    if (!isValid) {
      setErrors(validationErrors); // Set validation errors to state
      return; // Exit the function if form is not valid
    }

    const response = await fetch(`${url}/user/files/publicemail`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
        'token': localStorage.getItem('token'),
      },
      mode: 'cors',
      referrerPolicy: "origin-when-cross-origin",
      body: JSON.stringify({
        firstName: listParams.firstName,
                lastName: listParams.lastName,
                email: listParams.email,
                phone: listParams.phone,
                msg: listParams.msg,
        msg: `
          Test Details:<br>
          Type: ${selectedTest.type}<br>
          Cost: ${selectedTest.cost}<br>
          Discount: ${selectedTest.discount}<br>
          Demo Class: ${selectedTest.demo_class}<br>
          Classes: ${selectedTest.classes}<br>
          Duration: ${selectedTest.duration}<br>
          Teaching Hours: ${selectedTest.teaching_hours}<br>
          Mock Test: ${selectedTest.mock_test}<br>
          Batches Per Month: ${selectedTest.batches_per_month}<br>
          Specials: ${selectedTest.specials.join(", ")}`,
        from: "Public Assessment"
      })
    });

    const data = await response.json();

    if (data.success) {
      swal({
        title: "Success!",
        text: "Message Sent!",
        icon: "success",
        button: "Ok!",
      });
    } else {
      swal({
        title: "Error!",
        text: data.message,
        icon: "error",
        button: "Ok!",
      });
    }
  };

  return (
    <>
      <div className="body-content no-filterpanel public-view">
        <div className="body-panel d-flex justify-content-between">
          <div className="body-container width-100">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-between mt-3">
                  <div className="page-content">
                    <h1 className="mb-3">Coaching</h1>
                    <p>
                      RHE helps you in putting your best foot forward for all
                      preliminary and competitive examination for FREE.
                    </p>
                    {/*<a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        // navigate("/userdashboard");
                        setModal(true);
                      }}
                      className="widget-cta-page-nopos float-end mb-3"
                    >
                      <i className="fa fa-home"></i> Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="test-eligibility">
                  <div className="widget-hd widget-border">
                    Want to Check with test is required for you?
                  </div>
                  <div className="test-eligibility-form responsive-col">
                    <div className="row">
                      <div className="col">
                        <label for="">Select Nationality</label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setData((prevState) => ({
                              ...prevState,
                              nationality: e.target.value,
                            }));
                          }}
                        >
                          <option value={""}>Select Country</option>
                          {countryCodes?.map((itm) => (
                            <option id={itm?.name} value={itm?.name}>
                              {itm?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label for="">Last Study Destination<span className="required">*</span></label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setData((prevState) => ({
                              ...prevState,
                              last_edu_country: e.target.value,
                            }));
                          }}
                          value={data.last_edu_country}
                        >
                          <option value={""}>Select Country</option>
                          {country_code?.map((itm) => (
                            <option id={itm?.name} value={itm?.name}>
                              {itm?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label for="">Study Destination<span className="required">*</span></label>
                        <Select
                          className="app-multiSelect"
                          isMulti
                          value={data?.destination?.map((value) => ({
                            value,
                            label: value,
                          }))}
                          options={countryCodes.map((op, i) => ({
                            value: op.name,
                            label: op.name,
                          }))}
                          onChange={(selectedOptions) => {
                            const newData = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setData((prevData) => ({
                              ...prevData,
                              destination: newData,
                            }));
                          }}
                          placeholder="Study Destination"
                          isOptionDisabled={() =>
                            data?.destination?.length >= 3
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label for="">Level<span className="required">*</span></label>
                        <select
                          className="form-select"
                          value={data?.level}
                          onChange={(e) => {
                            setData((prevState) => ({
                              ...prevState,
                              level: e.target.value,
                            }));
                          }}
                        >
                          <option value="">Please Select</option>
                          <option value="ug">UG</option>
                          <option value="pg">PG</option>
                        </select>{" "}
                      </div>
                      <div className="col">
                        <label for="">Subject</label>
                        <select
                          className="form-select"
                          name="subject"
                          value={data.subject}
                          onChange={(e) =>
                            setData((prevState) => ({
                              ...prevState,
                              subject: e.target.value,
                            }))
                          }
                          placeholder="Select Subject"
                        >
                          <option key={""}>Please Select</option>
                          {Object?.keys(courseData).map((idx) => (
                            <option key={idx}>{idx}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label for="">Course</label>
                        <Select
                          className="app-multiSelect"
                          isMulti
                          value={data?.course?.map((value) => ({
                            value,
                            label: value,
                          }))}
                          options={
                            course?.length === 0
                              ? [
                                {
                                  label: "Select Subject to get courses",
                                  value: "",
                                },
                              ]
                              : course?.map((op, i) => ({
                                value: op.name,
                                label: op.name,
                              }))
                          }
                          onChange={(selectedOptions) => {
                            const newData = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setData((prevData) => ({
                              ...prevData,
                              course: newData,
                            }));
                          }}
                          placeholder="Course of Interest"
                          isOptionDisabled={() => {
                            if (course?.length === 0) return 1;
                            else return data?.course?.length >= 5;
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-3">
                        <button
                          className="btn btn-primary-regular"
                          onClick={() =>
                            getAllTests(
                              data?.destination,
                              data?.subject,
                              data?.course,
                              data?.nationality,
                              data?.level,
                              data?.last_edu_country
                            )
                          }
                        >
                          {" "}
                          Show Relevant Exam
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {!loading?.getAllTest ? (
                  <div className="d-flex flex-wrap mt-4">
                    {allTest?.length > 0 ? (
                      allTest?.map((item, i) => (
                        <div className="test-card" key={i}>
                          <div>
                            {" "}
                            {/* <span className="test-chip">
                              {item?.type?.toUpperCase()}
                            </span> */}
                            <button
                              className="btn btn-primary-regular float-end"
                              onClick={() => handleEnquireById(item._id)}  // Use handleEnquireById here
                              style={{ display: hasEnquireData(item._id) ? "block" : "none" }}
                            >
                              Enquire Now
                            </button>
                            <div
                              className="test-desc"
                              dangerouslySetInnerHTML={{
                                // __html:
                                //   item?.description?.length > 1700
                                //     ? item?.description.slice(0, 1700)
                                //     : item?.description,
                                __html: item?.description,
                              }}
                            ></div>
                            <br />
                          </div>

                          {/* <button className="btn btn-primary">
                            <Link
                              className="test-text-link"
                              // to={`/testing/assessment/${item?._id}`}
                              onClick={() => setModal(true)}
                            >
                              Take Assessment
                            </Link>
                          </button> */}
                        </div>
                      ))
                    ) : (
                      <p>No test found.</p>
                    )}
                  </div>
                ) : (
                  <p>Loading Tests.....</p>
                )}
                {/* <br />
                <hr />
                <br />
                {!loading?.getUserTest ? (
                  <section>
                    <h1>All Attempeted Tests</h1>
                    <div
                      className="d-flex justify-center items-center"
                      style={{
                        gap: "2rem",
                        marginTop: "1rem",
                        flexWrap: "wrap",
                      }}
                    >
                      {userTest?.length > 0 ? (
                        userTest?.map((item, i) => (
                          <div className="test-card" key={i}>
                            <div>
                              {" "}
                              <h2>{item?.testid?.name}</h2>
                              <span className="test-chip">
                                Time: {item?.timetaken}
                              </span>
                              <p className="test-desc">Score: {item?.score}</p>
                              <p
                                className="test-desc"
                                style={{ fontWeight: "700" }}
                              >
                                Attempted On:{" "}
                                {moment(item?.createdAt).format("L LTS")}
                              </p>
                            </div>
                            <Link
                              className="btn btn-primary"
                              to={`/test-report/${item?._id}`}
                            >
                              View
                            </Link>
                          </div>
                        ))
                      ) : (
                        <h2>Zero Attempeted test found.</h2>
                      )}
                    </div>
                  </section>
                ) : (
                  <h1>Loading Tests.....</h1>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <Modal show={modal} onHide={() => setModal(false)} className="modal-lg">
          <Modal.Header closeButton>
            {selectedTests.length > 1 && (
              <div className="d-flex">
                {selectedTests.map((test) => (
                  <button
                    key={test.type}
                    className="btn btn-link me-3"
                    onClick={() => setSelectedTestType(test.type)}
                  >
                    {test.type}
                  </button>
                ))}
              </div>
            )}
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <tbody>{renderTableRows(selectedTest)}</tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
          <div className="row w-100">
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                name="firstName"
                                                onChange={onChange}
                                                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={listParams.firstName}
                                                placeholder="First Name"
                                            />
                                            <label for="floatingInput">
                                                First Name<span className="required">*</span>
                                            </label>
                                            {errors.firstName && <span className="required">{errors.firstName}</span>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                name="lastName"
                                                onChange={onChange}
                                                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={listParams.lastName}
                                                placeholder="Last Name"
                                            />
                                            <label for="floatingInput">
                                                Last Name<span className="required">*</span>
                                            </label>
                                            {errors.lastName && <span className="required">{errors.lastName}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row w-100">
                                  <div className="col">
                                  <div className="form-floating mb-3">
                                    <input
                                        name="email"
                                        onChange={onChange}
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        type="email"
                                        value={listParams.email}
                                        placeholder="Email"
                                    />
                                    <label for="floatingInput">
                                        Email<span className="required">*</span>
                                    </label>
                                    {errors.email && <span className="required">{errors.email}</span>}
                                </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-floating mb-3">
                                    <input
                                        name="phone"
                                        onChange={onChange}
                                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                        type="text"
                                        maxLength={15}
                                        value={listParams.phone}
                                        placeholder="Phone Number"
                                    />
                                    <label for="floatingInput">
                                        Phone Number (add county code)<span className="required">*</span>
                                    </label>
                                    {errors.phone && <span className="required">{errors.phone}</span>}
                                </div>
                                  </div>
                                </div>
                                
            <button className="btn btn-primary-regular" onClick={sendMsg}>
              Enquire now
            </button>
          </Modal.Footer>
        </Modal>
        {/* <Modal show={modal} onHide={() => setModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>You are not logged in</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PleaseLoginModal />
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};
