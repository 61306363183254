import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import list from "../Assets/Data/countries.json";
import countryCodes from "../Assets/Data/countries.json";
import jwt_decode from "jwt-decode";
import swal from "sweetalert";
import { url } from "../Constants";
import API from "../../Api/Api";
import Select from "react-select";
import country_codes from "../Assets/Data/countries_code.json";
// import OAuth2Login from "react-simple-oauth2-login";

const Signup = (props) => {
  const [credentials, setCredentials] = useState({
    fname: "",
    lname: "",
    email: props.email || "",
    password: "",
    phone: "",
    authcode: null,
    dest: [],
    countryCode: "",
  });

  let history = useNavigate();
  const [sendOtp, setSendOtp] = useState(false);
  const [googleID, setGoogleID] = useState(0);
  const [signUpReq, setSignUpReq] = useState(false);
  const [errors, setErrors] = useState({});

  const [facebookId, setFacebookId] = useState(0);
  const [signUpFbReq, setSignUpFbReq] = useState(false);

  useEffect(() => {
    // if (localStorage.getItem("token")) {
    //   history("/scholarship");
    // }

    /* global google */
    const initGAuth = async () => {
      await google.accounts.id.initialize({
        client_id:
          "208870428930-ad67u1ss3cvahprbugg5bi0j848kl3ss.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      await google.accounts.id.renderButton(
        document.getElementById("googlebtn"),
        {
          theme: "none",
          longtitle: true,
        }
      );
    };

    initGAuth();
  }, []);

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  //for show password toggle_button

  // useEffect(() => {
  //   const ShowPasswordToggle = document.querySelector("[type='password']");
  //   const togglePasswordButton = document.getElementById("toggle-password");
  //   const passwordInput = document.querySelector("[type='password']");

  //   ShowPasswordToggle.onclick = function () {
  //     document
  //       .querySelector("[type='password']")
  //       .classList.add("input-password");
  //     document.getElementById("toggle-password").classList.remove("d-none");
  //     const passwordInput = document.querySelector("[type='password']");
  //     const togglePasswordButton = document.getElementById("toggle-password");
  //     togglePasswordButton.addEventListener("click", togglePassword);
  //   };

  //   const togglePassword = () => {
  //     if (passwordInput.type === "password") {
  //       passwordInput.type = "text";
  //       togglePasswordButton.setAttribute("aria-label", "Hide password.");
  //     } else {
  //       passwordInput.type = "password";
  //       togglePasswordButton.setAttribute(
  //         "aria-label",
  //         "Show password as plain text. " +
  //           "Warning: this will display your password on the screen."
  //       );
  //     }
  //   };
  // }, []);

  const handleCallbackResponse = async (response) => {
    // getting the jwt token and setting userObject as it response
    // console.log("JWT ID TOKEN: ", response.credential);
    var userObject = await jwt_decode(response.credential);
    setCredentials({
      ...credentials,
      email: userObject.email,
      fname: userObject.given_name,
      lname: userObject.family_name,
    });
    setGoogleID(userObject.sub);

    const res = await fetch(`${url}/oauth/google/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ googleId: userObject.sub }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await res.json();
    //console.log(json);

    if (json.requireSignup === false) {
      await localStorage.setItem("token", json.authToken);
      history("/userdashboard");
    } else {
      setSignUpReq(true);
      history("/signup");
    }
  };

  const onChange = (event) => {
    if (event.target.name === "phone") {
      const phoneValue = event.target.value.substring(0, 10);
      setCredentials({ ...credentials, [event.target.name]: phoneValue });
    } else if (event.target.name === "authcode") {
      const authcodeValue = event.target.value.substring(0, 6);
      setCredentials({ ...credentials, [event.target.name]: authcodeValue });
    } else {
      setCredentials({
        ...credentials,
        [event.target.name]: event.target.value,
      });
    }
  };

  //for manually submitting form
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await API("post", `/user/add-user`, {
        fname: credentials.fname,
        lname: credentials.lname,
        phone: credentials.phone,
        dest: credentials.dest,
        email: credentials.email,
        password: credentials.password,
        authcode: Number(credentials.authcode),
        countryCode: credentials.countryCode,
        user_counsellor: localStorage.getItem("_id"),
      });
      const json = response?.data;

      if (json.success === true) {
        swal({
          title: "Success!",
          text: "Account Created Successfully",
          icon: "success",
          button: "Ok!",
        });
        // await localStorage.setItem("token", json.authToken);
        history("/dashboard");
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) {
    }
  };

  //for Google submit
  const handleGoogleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}/oauth/google/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname: credentials.fname,
          lname: credentials.lname,
          phone: credentials.phone,
          dest: credentials.dest,
          email: credentials.email,
          googleId: googleID,
          countryCode: credentials.countryCode,
        }),
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
      });
      const json = await response.json();

      if (json.success === true) {
        swal({
          title: "Success!",
          text: "Account Created Successfully",
          icon: "success",
          button: "Ok!",
        });
        await localStorage.setItem("token", json.authToken);
        history("/userdashboard");
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) {
      swal({
        title: "Try Again!",
        text: "server routing error!",
        icon: "error",
        button: "Ok!",
      });
    }
  };

  //for Facebook submit
  const handleFacebookSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}/oauth/facebook/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname: credentials.fname,
          lname: credentials.lname,
          phone: credentials.phone,
          dest: credentials.dest,
          email: credentials.email,
          facebookId: facebookId,
          countryCode: credentials.countryCode,
        }),
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
      });
      const json = await response.json();

      if (json.success === true) {
        swal({
          title: "Success!",
          text: "Account Created Successfully",
          icon: "success",
          button: "Ok!",
        });
        await localStorage.setItem("token", json.authToken);
        history("/userdashboard");
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) {
      swal({
        title: "Try Again!",
        text: "server routing error!",
        icon: "error",
        button: "Ok!",
      });
    }
  };

  //for sending OTP
  const sendMail = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(`${url}/auth/signup/email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ email: credentials.email }),
          mode: "cors",
          referrerPolicy: "origin-when-cross-origin",
        });
        const json = await response.json();

        if (json.success === true) {
          swal({
            title: "User Created!",
            text: json.message,
            icon: "success",
            button: "Ok!",
          });
          setSendOtp(true);
        } else {
          swal({
            title: "Try Again!",
            text: json.message,
            icon: "error",
            button: "Ok!",
          });
          setSendOtp(false);
        }
      } catch (err) {
        swal({
          title: "Try Again!",
          text: "server routing error!",
          icon: "error",
          button: "Ok!",
        });
      }
    }
  };

  const validateMail = () => {
    let errors = {};
    let isValid = true;

    if (!credentials.authcode) {
      errors.authcode = "authcode is required";
      isValid = false;
    } else if (credentials.authcode?.length !== 6) {
      errors.authcode = "authcode must be of 6 characters";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!credentials.fname) {
      errors.fname = "First Name is required";
      isValid = false;
    } else if (credentials.fname?.length < 2) {
      errors.fname = "Name length atleast 2 char";
      isValid = false;
    }

    if (!credentials.lname) {
      errors.lname = "Last Name is required";
      isValid = false;
    } else if (credentials.lname?.length < 2) {
      errors.lname = "Name length atleast 2 char";
      isValid = false;
    }

    if (!credentials.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    if (!credentials.phone) {
      errors.phone = "Phone is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(credentials.phone)) {
      errors.phone = "Invalid phone number";
      isValid = false;
    }

    if (!credentials.dest) {
      errors.dest = "Destination Name is required";
      isValid = false;
    } else if (credentials.dest?.length < 2) {
      errors.fname = "Destination Name length atleast 2 char";
      isValid = false;
    }

    // if (!credentials.password) {
    //   errors.password = "Password is required";
    //   isValid = false;
    // } else if (credentials.password?.length < 8) {
    //   errors.password = "Password must be at least 8 characters";
    //   isValid = false;
    // }
    setErrors(errors);
    return isValid;
  };

  return (
    <div
      className="container admin-createpage"
    >
      <div>
        <div className="main-heading">Add User</div>
        <div className="regular-text">
          <br />
          {sendOtp === false
            ? "Please fill out the following form to create user account"
            : "Mail with verification code send to your email-ID"}
        </div>
      </div>

      <div>
        <div className="sep" />
        <div className="page-form">
          {signUpReq === true ? (
            <form onSubmit={handleGoogleSubmit}>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <select
                      list="countryCodeData"
                      value={credentials.countryCode}
                      onChange={onChange}
                      placeholder="choose.."
                      className="form-select"
                      name="countryCode"
                    >
                      <option value={""}>Please select</option>
                      {country_codes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputState" className="form-label mb-0">
                  Study Destination<span className="required">*</span>
                </label>
                <input
                  list="data"
                  value={credentials.dest}
                  onChange={onChange}
                  placeholder="choose.."
                  className="form-select"
                  name="dest"
                />
                {errors.dest && (
                  <span className="required">
                    {errors.dest}
                  </span>
                )}

                <datalist id="data">
                  {list.map((op, i) => (
                    <option>{op.name}</option>
                  ))}
                </datalist>
              </div>
              <div className="form-group">
                <div className="pt-3" />
                <div className="form-button">
                  <button
                    disabled={credentials.phone?.length < 10}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Create Account
                  </button>
                </div>
              </div>
            </form>
          ) : signUpFbReq === true ? (
            <form onSubmit={handleFacebookSubmit}>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <select
                      list="countryCodeData"
                      value={credentials.countryCode}
                      onChange={onChange}
                      placeholder="choose.."
                      className="form-select"
                      name="countryCode"
                    >
                      <option value={""}>Please select</option>
                      {country_codes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputState" className="form-label mb-0">
                  Study Destination<span className="required">*</span>
                </label>
                <input
                  list="data"
                  value={credentials.dest}
                  onChange={onChange}
                  placeholder="choose.."
                  className="form-select"
                  name="dest"
                />
                {errors.dest && (
                  <span className="required">
                    {errors.dest}
                  </span>
                )}

                <datalist id="data">
                  {list.map((op, i) => (
                    <option>{op.name}</option>
                  ))}
                </datalist>
              </div>
              <div className="form-group">
                <div className="pt-3" />
                <div className="form-button">
                  <button type="submit" className="btn btn-primary">
                    Create Account
                  </button>
                </div>
              </div>
            </form>
          ) : sendOtp === false ? (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="row">
                  <div className="col">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      First Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      aria-label="First name"
                      value={credentials.fname}
                      onChange={onChange}
                      name="fname"
                    />
                    {errors.fname && (
                      <span className="required">
                        {errors.fname}
                      </span>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Last Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                      value={credentials.lname}
                      onChange={onChange}
                      name="lname"
                    />
                    {errors.lname && (
                      <span className="required">
                        {errors.lname}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col countryCode">
                  <div className="">
                    <label htmlFor="inputState" className="form-label">
                      Country Code<span className="required">*</span>
                    </label>
                    <select
                      list="countryCodeData"
                      value={credentials.countryCode}
                      onChange={onChange}
                      placeholder="choose.."
                      className="form-select"
                      name="countryCode"
                    >
                      <option value={""}>Please select</option>
                      {country_codes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="">
                    <label htmlFor="exampleInputEmail1">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <label htmlFor="inputState" className="form-label">
                  Study Destination<span className="required">*</span>
                </label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={credentials?.dest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={countryCodes.map((op, i) => ({
                    value: op.name,
                    label: op.name,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setCredentials((prevData) => ({
                      ...prevData,
                      dest: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                  isOptionDisabled={() => credentials?.dest?.length >= 3}
                />
                {/* <input
                  list="data1"
                  value={credentials.dest}
                  onChange={onChange}
                  placeholder="choose.."
                  className="form-select"
                  name="dest"
                />
                {errors.dest && (
                  <span className="required">
                    {errors.dest}
                  </span>
                )}
                <datalist id="data1">
                  {list.map((op, i) => (
                    <option>{op.name}</option>
                  ))}
                </datalist> */}
              </div>
              <div className="">
                <div className="row">
                  <div className="col">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address<span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      value={credentials.email}
                      onChange={onChange}
                      name="email"
                    />
                    {errors.email && (
                      <span className="required">
                        {errors.email}
                      </span>
                    )}
                  </div>
                  {/* <div className="col">
                    <label htmlFor="exampleInputPassword1">
                      Password<span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={credentials.password}
                      onChange={onChange}
                      name="password"
                    />
                    {errors.password && (
                      <span className="required">
                        {errors.password}
                      </span>
                    )}
                    <button
                      id="toggle-password"
                      type="button"
                      className="d-none"
                      aria-label="Show password as plain text. Warning: this will display your password on the screen."
                    />
                  </div> */}
                </div>
                <br />
                <div className="">
                  <div className="row">
                    {/* <div className="col">
                                            <button type="submit" className="btn btn-primary" disabled={credentials.email.length < 5 || credentials.password.length < 3 || credentials.phone.length < 10}>
                                                Send Verification Code
                                            </button>
                                        </div> */}
                    <div className="col">
                      <button type="submit" className="btn btn-primary-regular me-3">
                        Add User
                      </button>
                      <button
                        type="submit"
                        onClick={backButton}
                        className="btn btn-secondary-regular"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label" htmlFor="exampleInputEmail1">
                  Verification Code<span className="required">*</span>
                </label>
                <input
                  type="Number"
                  className="form-control"
                  id="exampleInputauthcode1"
                  aria-describedby="authcodeHelp"
                  placeholder="Enter authcode"
                  value={credentials.authcode}
                  onChange={onChange}
                  name="authcode"
                />
                {errors.authcode && (
                  <span className="required">
                    {errors.authcode}
                  </span>
                )}
              </div>
              <div className="pt-3" />
              <div className="form-button">
                <button type="submit" className="btn btn-primary">
                  Verify OTP
                </button>
              </div>
              <div className="pt-3" />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
