import React, { useState } from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'
import courseData from '../../Assets/Data/coursecounseling.json'
import Autosuggest from 'react-autosuggest'
import { useEffect } from 'react'
import swal from 'sweetalert'

const CourseHelp = ({
  thumbData,
  setScript,
  setShowLevelModal,
  setThumbData,
}) => {
  const [ug, setUg] = useState(false)
  return (
    <div className="modal-body">
      <div className="form-group responsive-col">
        <div className="row">
          <div className="col">
            <label htmlFor="">
              Level<span className="required">*</span>
            </label>
            <br />
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              // defaultChecked=""
              defaultChecked={false}
              checked={ug}
              onClick={() => {
                setUg(true)
              }}
            />
            <label className="btn btn-secondary" htmlFor="option1">
              <i className="fa-solid fa-user-graduate" /> UG
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option2"
              defaultChecked={false}
              autoComplete="off"
              checked={!ug}
              onClick={() => {
                setUg(false)
              }}
            />
            <label className="btn btn-secondary" htmlFor="option2">
              <i className="fa-solid fa-user-graduate" /> PG
            </label>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary-regular"
        disabled={thumbData.length < 1}
        onClick={() => {
          setScript({ ...thumbData[0], ug })
          setThumbData((prev) => {
            return prev.map((itm) => {
              return { ...itm, ug }
            })
            
          })
          setShowLevelModal(false)
        }}
      >
        Start Counseling
      </button>
    </div>
  )
}

export default CourseHelp
