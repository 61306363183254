import React, { useEffect, useState } from "react";
import NavBarAdmin from "./Nav/Nav";
import Editor from "../LmsAdminDashboard/Editor/Editor";
import { MdDelete } from "react-icons/md";
import API from "../../Api/Api";
import swal from "sweetalert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const MassEmailDashboard = () => {
  const [user, setUsers] = useState([]);
  const [fromEmails, setFromEmails] = useState([]);
  const [temp, setTemp] = useState("");
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({
    subject: "",
    body: "",
    signature: ``,
    from: "",
    name: "",
  });
  const [saveTemplates, setSaveTemplates] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState(""); // For repeat interval
  const repeatIntervalMapping = {
    "3d": "3 days",
    "1w": "1 week",
    "2w": "2 weeks",
    "1m": "1 month",
    "": "No Repeat", // Default case for no repeat
  };

  const getUsers = async () => {
    try {
      const response = await API("get", "/user/all");
      setUsers(response.data.data);
    } catch (error) { }
  };
  const getTempaltes = async () => {
    try {
      const response = await API("get", "/mass-mail/");
      setSaveTemplates(response.data.data);
    } catch (error) { }
  };
  const getFromEmails = async () => {
    try {
      const response = await API("get", "/mass-mail/from-emails");
      setFromEmails(response.data.data);
    } catch (error) { }
  };
  useEffect(() => {
    if (searchParams.get("email")) {
      setEmails([searchParams.get("email")]);
    }
    getUsers();
    getTempaltes();
    getFromEmails();
    getScheduledEmails();
  }, []);
  const handlSendMails = async () => {
    setLoading(true);
    try {
      const payload = {
        to: emails,
        ...emailData,
        scheduledDate: scheduledDate ? scheduledDate.toISOString() : null,
        repeatInterval: emailData.repeatInterval || "",
      };

      console.log("Sending payload: ", payload);

      await API("post", "/mass-mail/send", payload);
      swal("Success!", "Email has been sent", "success");
      getTempaltes();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const saveTemplate = async () => {
    try {
      if (edit) {
        await API("put", "/mass-mail/" + editId, { to: emails, ...emailData });
      } else {
        await API("post", "/mass-mail/save", { to: emails, ...emailData });
      }

      swal("Success!", "Template has been saved", "success");
      getTempaltes();
    } catch (error) { }
  };
  const handleDelete = async (id) => {
    try {
      const response = await API("delete", `/mass-mail/${id}`);
      swal("Success!", "Template has been deleted", "success");
      getTempaltes();
    } catch (error) { }
  };
  const updateToBasedOnStatus = (e, status) => {
    if (status === "all") {
      if (e.target.checked) {
        setAllChecked(true);
        setEmails(user?.map((itm) => itm?.email));
      } else {
        setAllChecked(false);
        setEmails([]);
      }
      return;
    }
    if (e.target.checked) {
      setDisableAll(true);
      let filtered_emails = user?.filter((itm) => itm?.lead_status === status);
      setEmails([...emails, ...filtered_emails?.map((itm) => itm?.email)]);
    } else {
      setDisableAll(false);
      let filtered_emails = user?.filter((itm) => itm?.lead_status === status);
      filtered_emails = filtered_emails?.map((itm) => itm?.email);
      setEmails(
        emails?.filter((itm) => {
          if (!filtered_emails.includes(itm)) {
            return itm;
          }
        })
      );
    }
  };
  const getScheduledEmails = async () => {
    try {
      const response = await API("get", "/mass-mail/scheduled-emails"); // Assuming your API path is "/mass-mail/scheduled-emails"
      setScheduledEmails(response.data.data);
    } catch (error) {
      console.error("Error fetching scheduled emails:", error);
    }
  };
  const handleCancelScheduledEmail = async (id) => {
    try {
      await API("put", `/mass-mail/cancel/${id}`);  // Create this API on the back end
      swal("Success!", "Scheduled email has been cancelled", "success");
      getScheduledEmails();  // Refresh the list of scheduled emails
    } catch (error) {
      console.error("Error cancelling scheduled email:", error);
    }
  };
  const handleRescheduleEmail = async (emailId, newScheduledDate) => {
    try {
      const payload = { newScheduledDate };  // Pass the new scheduled date in the body

      await API("put", `/mass-mail/reschedule/${emailId}`, payload, {
        'Content-Type': 'application/json',
      });

      swal("Success!", "Email has been rescheduled", "success");
      getScheduledEmails(); // Refresh the list of scheduled emails
    } catch (error) {
      swal("Error", "Failed to reschedule the email", "error");
    }
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">Marketing Email</h1>
          <div>
            <button
              onClick={() => setShowModal(true)}  // Open the modal on click
              type="button"
              className="btn btn-link me-3"
            >
              View Scheduled E-Mails
            </button>
            <button
              onClick={() => {
                navigate("/dashboard-from-email");
              }}
              type="button"
              className="btn btn-primary-regular"
            >
              From email
            </button>
          </div>
        </div>
        <div className="d-flex marketing-email">
          <div style={{ width: "35%" }}>
            <h2>All Templates</h2>
            <div className="template-list">
              {saveTemplates?.map((itm) => (
                <div
                  key={itm?._id}
                  className="template"
                >
                  <p>
                    <strong>{itm?.name}</strong>
                  </p>
                  <p>To: {itm?.to?.length} users</p>
                  <p>Subject: {itm?.subject}</p>
                  <div className="d-flex">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setEditId(itm?._id);
                        setEdit(true);
                        setEmails(itm?.to);
                        setEmailData({
                          subject: itm?.subject,
                          body: itm?.body,
                          signature: itm?.signature ?? "",
                        });
                      }}
                    >
                      Open
                    </button>{" "}
                    &nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        swal({
                          title: "Are you sure, you want to delete?",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            handleDelete(itm?._id);
                          } else {
                          }
                        });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <br />
            <label>Temple Name (optional to save)</label>
            <input
              className="form-control"
              placeholder="Template name.."
              value={emailData.name}
              onChange={(e) =>
                setEmailData({ ...emailData, name: e.target.value })
              }
            />
            <br />
            <label>From</label>
            <select
              className="form-select"
              onChange={(e) => {
                setEmailData({
                  ...emailData,
                  from: e.target.value,
                  signature: fromEmails.find(
                    (itm) => itm.email == e.target.value
                  )?.signature,
                });
              }}
            >
              <option value="">From Email</option>
              {fromEmails?.map((itm) => (
                <option key={itm?._id} value={itm?.email}>
                  {itm?.email}
                </option>
              ))}
            </select>
            <br />
            <div className="form-group">
              <label>Scheduled Send (optional)</label>
              <DateTimePicker
                value={scheduledDate}
                onChange={(newDate) => setScheduledDate(newDate)}  // This will update the state when the user selects a date
                inputProps={{ placeholder: "Pick a date and time" }}
              />
            </div>
            <label>Repeat Email</label>
            <select
              className="form-select"
              value={emailData.repeatInterval}
              onChange={(e) => {
                setEmailData({
                  ...emailData,
                  repeatInterval: e.target.value,  // Update the repeatInterval in the emailData state
                });
              }}
            >
              <option value="">No Repeat</option>
              <option value="3d">Every 3 Days</option>
              <option value="1w">Every Week</option>
              <option value="2w">Every 2 Weeks</option>
              <option value="1m">Every Month</option>
            </select>
            <div
              className="d-flex"
              style={{ flexWrap: "wrap", gap: "0.5rem", fontSize: "13px" }}
            >
              To:
              {emails?.map((itm) => (
                <div
                  className="card "
                  style={{ flexDirection: "row", padding: "5px" }}
                  key={itm}
                >
                  {itm}
                  <MdDelete
                    className="MdDelete"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmails(emails.filter((item) => item !== itm))
                    }
                  />
                </div>
              ))}
            </div>
            <hr />
            <div className="d-flex" style={{ gap: "1rem" }}>
              <div>
                <input
                  type="checkbox"
                  disabled={disableAll}
                  onClick={(e) => {
                    updateToBasedOnStatus(e, "all");
                  }}
                />
                &nbsp;<label>All</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  disabled={allChecked}
                  onClick={(e) => {
                    updateToBasedOnStatus(e, "hot");
                  }}
                />
                &nbsp;<label>Hot</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  disabled={allChecked}
                  onClick={(e) => {
                    updateToBasedOnStatus(e, "cold");
                  }}
                />
                &nbsp;<label>Cold</label>
              </div>
              <div className="mb-3">
                <input
                  type="checkbox"
                  disabled={allChecked}
                  onClick={(e) => {
                    updateToBasedOnStatus(e, "warm");
                  }}
                />
                &nbsp;<label>Warm</label>
              </div>
            </div>
            <div className="d-flex">
              <input
                className="form-control"
                placeholder="search email.."
                list="users"
                type="email"
                value={temp}
                onChange={(e) => setTemp(e.target.value)}
              />
              <datalist id="users">
                {user?.map((itm) => (
                  <option key={itm.email} value={itm?.email}>
                    {itm.email}
                  </option>
                ))}
              </datalist>
              &nbsp;
              <button
                style={{ width: "100px" }}
                className="btn btn-primary"
                onClick={() => {
                  if (!emails.includes(temp.split(","))) {
                    setEmails([...emails, ...temp.split(",")]);
                    setTemp("");
                  }
                }}
              >
                Add
              </button>
            </div>
            <br />
            <input
              className="form-control"
              placeholder="Subject"
              value={emailData.subject}
              onChange={(e) =>
                setEmailData({ ...emailData, subject: e.target.value })
              }
            />
            <br />
            <Editor data={emailData} setData={setEmailData} name={"body"} />
            <br />
            <Editor
              data={emailData}
              setData={setEmailData}
              name={"signature"}
              height={200}
            />
            <br />
            <div className="d-flex mb-3">
              <button
                className="btn btn-primary-regular me-2 ,m"
                onClick={saveTemplate}
              >
                Save Template
              </button>
              {!loading ? (
                <button
                  className="btn btn-primary-regular"
                  onClick={handlSendMails}
                >
                  Send email
                </button>
              ) : (
                <button
                  disabled
                  className="btn btn-primary"
                  onClick={handlSendMails}
                >
                  loading...
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Scheduled Emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {scheduledEmails.length > 0 ? (
            <div className="scheduled-list">
              <table className="table admin-table">
                <thead>
                  <tr className="table-dark">
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Scheduled Time</th>
                    <th scope="col">Current Satus</th>
                    <th scope="col">Repeat</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {scheduledEmails.map((email) => (
                  <>
                    <tbody>
                      <tr key={email._id}>
                        <td>{email.from}</td>
                        <td>
                          {email.to.join(", ")}
                        </td>
                        <td>
                          {new Date(email.scheduledDate).toLocaleString()}
                        </td>
                        <td>
                          {email.status}
                        </td>
                        <td>
                          {repeatIntervalMapping[email.repeatInterval] || "No Repeat"}
                        </td> 
                        <td>
                          {email.status === "scheduled" && (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                swal({
                                  title: "Are you sure you want to cancel this scheduled email?",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    handleCancelScheduledEmail(email._id);
                                  }
                                });
                              }}
                            >
                              Cancel
                            </button>
                          )}
                          {email.status === "cancelled" && (
                            <button
                              className="btn btn-primary-regular btn-sm"
                              onClick={() => handleRescheduleEmail(email._id)}
                            >
                              Reschedule
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </>

                ))}
              </table>
            </div>
          ) : (
            <p>No scheduled or cancelled emails</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MassEmailDashboard;
