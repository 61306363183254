import React from 'react'
import { useAudio } from '../../Context/AudioContext'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import './style.css'

export default function Controls() {
  const countryChapters = [
    'Introduction',
    'Why Country',
    'Education System',
    'University Categories',
    'Language',
    'Admission Requirements',
    'Intake and Timelines',
    'Tuition Fee Structure',
    'Living Expenses',
    'Part-Time Job Opportunities',
    'Post Study Work Rights',
    'Settlement Process/ PR',
    'Scholarships',
    'Accreditation and Certification',
  ]

  function convertSecondsToMinutesAndSeconds(seconds) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    return `${minutes}:${formattedSeconds}`
  }

  const {
    audio,
    isPlaying,
    audioTime,
    togglePlay,
    handleSeek,
    script,
    country,
    lipSyncData,
    loadingAudio,
    setCaptionsEnable,
    captionsEnable
  } = useAudio()

  // Function to format time as "mm:ss"
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const getCurrentChapter = (arr, target) => {
    let lowerElement
    let upperElement

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]-1 < target) {
        lowerElement = arr[i]
      } else if (arr[i] >= target) {
        upperElement = arr[i]
        break
      }
    }
    // console.log(lowerElement)
    return lowerElement
  }

  return (
    <div className="control-div">
      {loadingAudio ? (
        <button className="btn-playpause">
          <AiOutlineLoading3Quarters
            color="#DC7215"
            style={{
              animation: 'rotate 2s linear infinite',
            }}
          />
        </button>
      ) : (
        <button
          className="btn-playpause"
          onClick={togglePlay}
          style={{ touchAction: 'manipulation' }}
        >
          {isPlaying ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}
        </button>
      )}

      <input
        className="seekbar"
        type="range"
        min="0"
        max={audio.duration || 0}
        value={audioTime}
        onChange={(e) => handleSeek(e.target.value)}
      />

      {lipSyncData.metadata && (
        <p className="video-time">
          {convertSecondsToMinutesAndSeconds(audioTime)} /{' '}
          {convertSecondsToMinutesAndSeconds(lipSyncData.metadata.duration)}
        </p>
      )}
      &nbsp;
      <button
        className="btn-playpause"
        onClick={() => setCaptionsEnable((p) => !p)}
        style={{ textDecoration:  captionsEnable ? '' :'line-through'}}
      >
        {/* {
          captionsEnable ?   <i className="fa-regular fa-closed-captioning"></i> : <i className="fa-solid fa-closed-captioning-slash"></i>
        } */}
        CC
      </button>
      <div className="chapters">
        {script &&
          script.chapters &&
          script.chapters.map((item, idx) => {
            return (
              <button
                onClick={() => {
                  handleSeek(item)
                }}
                style={
                  getCurrentChapter(
                    script.chapters,
                    Number(audioTime).toFixed(1),
                  ) === item && item <= Number(audioTime).toFixed(1)
                    ? {
                        background: '#DC7215',
                        color: '#fff',
                      }
                    : {}
                }
                key={idx}
                className="chapterBtn"
              >
                {country ? countryChapters[idx] : ''}
              </button>
            )
          })}
      </div>
    </div>
  )
}
