import React, { useState } from "react";
import Select from "react-select";
import RangeSlider from "react-range-slider-input";
import CountriesData from "../Assets/Data/countries.json";
import courseData from "../Assets/Data/coursecounseling.json";
import StatesData from "../Assets/Data/state.json";
import stepsConfig from "../Assets/Data/shortlistingwizard.json"; // Import your steps JSON
import swal from "sweetalert";
import API from "../../Api/Api"; // Ensure this is correctly set up

const ShortlistingWizard = () => {
    const [step, setStep] = useState(0);
    const [filterData, setFilterData] = useState({
        country: "",
        state: "",
        subject: "",
        course: "",
        level: "",
        tuitionFeeRange: [0, 100000],
    });
    const [courseList, setCourseList] = useState([]);
    const [collegeData, setCollegeData] = useState([]); // Store fetched results
    const [resultsLoaded, setResultsLoaded] = useState(false); // Track if results have been loaded

    const handleFilterChange = (name, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === "subject") {
            fetchCourseList(value);
            // Reset the course if the subject changes
            setFilterData((prevData) => ({
                ...prevData,
                course: "",
            }));
        }
    };

    const fetchCourseList = (subject) => {
        const courses = courseData[subject] || [];
        setCourseList(courses);
    };

    const createQueryString = (params) => {
        const queryString = Object.keys(params)
            .filter((key) => params[key]) // Filter out empty values
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
            )
            .join("&");
        return queryString ? `&${queryString}` : "";
    };

    const handleSubmit = async () => {
        try {
            const queryParams = createQueryString({
                country: filterData.country,
                state: filterData.state,
                majorName: filterData.subject,
                courseName: filterData.course,
                level: filterData.level,
                minFee: filterData.tuitionFeeRange[0],
                maxFee: filterData.tuitionFeeRange[1],
            });

            const response = await API(
                "get",
                `/college-shortlisting/all?page=1&pageSize=20${queryParams}`
            );

            if (response?.data?.data?.length) {
                setCollegeData(response.data.data);
                setResultsLoaded(true); // Indicate that results are loaded
            } else {
                swal("No Results", "No data found with the given filters", "info");
                setResultsLoaded(false);
            }
        } catch (error) {
            swal("Error", "An error occurred while fetching the data", "error");
            setResultsLoaded(false);
        }
    };

    const renderStepContent = () => {
        const stepData = stepsConfig[step];

        if (stepData.type === "radio") {
            return (
                <div>
                    <h3>{stepData.question}</h3>
                    {CountriesData.map((country, index) => (
                        <div className="radio-option" key={index}>
                            <input
                                type="radio"
                                id={country.name}
                                name={stepData.name}
                                value={country.name}
                                checked={filterData.country === country.name}
                                onChange={() => handleFilterChange("country", country.name)}
                                className="me-2"
                            />
                            <label htmlFor={country.name}>{country.name}</label>
                            <img src={country.flag} />
                        </div>
                    ))}
                </div>
            );
        }

        if (stepData.type === "combined") {
            return (
                <div>
                    <div>
                        <h3>{stepData.question}</h3>
                        <label>{stepData.fields[0].label}</label>
                        <Select
                            value={
                                filterData.subject
                                    ? { value: filterData.subject, label: filterData.subject }
                                    : null
                            }
                            options={Object.keys(courseData).map((subject) => ({
                                value: subject,
                                label: subject,
                            }))}
                            onChange={(selectedOption) =>
                                handleFilterChange("subject", selectedOption.value)
                            }
                            placeholder={stepData.fields[0].placeholder}
                        />
                    </div>
                    {filterData.subject && (
                        <div>
                            <label>{stepData.fields[1].label}</label>
                            <Select
                                value={
                                    filterData.course
                                        ? { value: filterData.course, label: filterData.course }
                                        : null
                                }
                                options={courseList.map((course) => ({
                                    value: course.name,
                                    label: course.name,
                                }))}
                                onChange={(selectedOption) =>
                                    handleFilterChange("course", selectedOption.value)
                                }
                                placeholder={stepData.fields[1].placeholder}
                            />
                        </div>
                    )}
                </div>
            );
        }

        if (stepData.type === "range") {
            return (
                <div>
                    <label>{stepData.label}</label>
                    <RangeSlider
                        min={stepData.min}
                        max={stepData.max}
                        step={stepData.step}
                        value={filterData[stepData.name]}
                        onInput={(value) => handleFilterChange(stepData.name, value)}
                        range
                    />
                    <p>
                        {stepData.label} {filterData.tuitionFeeRange[0]} - $
                        {filterData.tuitionFeeRange[1]}
                    </p>
                </div>
            );
        }

        if (stepData.type === "dropdown") {
            return (
                <select
                    className="form-select"
                    value={filterData.level}
                    onChange={(e) => handleFilterChange("level", e.target.value)}
                >
                    <option value="">Choose Level</option>
                    {stepData.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            );
        }

        return null;
    };

    return (
        <>
            <div className="body-content no-filterpanel">
                <div className="body-panel d-flex justify-content-between">
                    <div className="body-container width-100">
                        <div className="page-content">
                            <div className="row">
                                <div className="col">
                                    <div className="wizard-container">
                                        <div className="wizard-content">{renderStepContent()}</div>
                                        <div className="wizard-navigation">
                                            {step > 0 && (
                                                <button className="btn btn-secondary" onClick={() => setStep(step - 1)}>
                                                    Previous
                                                </button>
                                            )}
                                            {step < stepsConfig.length - 1 ? (
                                                <button className="btn btn-primary-regular" onClick={() => setStep(step + 1)}>
                                                    Next
                                                </button>
                                            ) : (
                                                <button className="btn btn-success" onClick={handleSubmit}>
                                                    Show Results
                                                </button>
                                            )}
                                        </div>

                                        {/* Display results once fetched */}
                                        {resultsLoaded && (
                                            <div className="results-section">
                                                <h2>Results</h2>
                                                <ul>
                                                    {collegeData.length > 0 ? (
                                                        collegeData.map((college, index) => (
                                                            <li key={index}>
                                                                <h3>{college.collegeName}</h3>
                                                                <p>Course: {college.courseName}</p>
                                                                <p>Level: {college.level}</p>
                                                                <p>Country: {college.country}</p>
                                                                <p>Tuition Fee: {college.tuitionFee}</p>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <p>No results found</p>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShortlistingWizard;