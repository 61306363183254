import React, { useEffect, useMemo, useState } from 'react'
import { OpenAI } from 'openai' // Update the import statement
import VoiceTranscript from './VoiceTranscript'
import API from '../../Api/Api'
import { socketUrl } from '../Constants'
import { translate } from 'google-translate-api-x'
const GlobalGPT = () => {
  // const navigate = useNavigate()
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_GPT,
    dangerouslyAllowBrowser: true,
  })
  const [speakOrChat, setSpeakOrChat] = useState(() =>
    window.location.pathname == '/speak-with-counselor' ? 'speak' : 'chat',
  )

  const [message, setMessage] = useState([])
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false) // State to manage loading state
  const [currResponse, setCurrResponse] = useState('')
  const [msgIndex, setMsgIndex] = useState(1)

  const speaker = new SpeechSynthesisUtterance()
  const synth = window.speechSynthesis
  const [playingAudio, setPlayingAudio] = useState(false)
  const [stateAudio, setStateAudio] = useState()
  const [lang, setLang] = useState('en')
  const [language, setLanguage] = useState('English')
  const audio = useMemo(
    () => new Audio(`${socketUrl}/v1/speech?text=${currResponse}&lang=${lang}`),
    [currResponse],
  )

  useEffect(() => {
    if (currResponse !== '') {
      const assistantMessage = {
        msg: currResponse,
        user: false,
      }
      setMessage((prev) => {
        const newArr = [...prev.slice(0, msgIndex), assistantMessage]
        return newArr
      })
      //   console.log(currResponse)
    }
  }, [currResponse])

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (!loading) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault() // Prevent the new line in the textarea
        getGPTResponse()
      }
    }
  }

  const getGPTResponse = async () => {
    setLoading(true) // Set loading state to true

    const newMsg = message
    newMsg.push({
      msg: query,
      user: true,
    })
    //console.log('mess', newMsg)
    if (message.length > 1) {
      setMsgIndex(msgIndex + 2)
    }
    setCurrResponse('')
    setQuery('')
    setMessage(newMsg)

    try {
      let customQuery = `${query}`

      if (speakOrChat == 'speak') {
        customQuery = `${query}

        
        Output the result of above query in ${language}.
        Make sure to not convert the above query in any language.
        I want you to answer the result in ${language}
        `
      }

      // console.log(customQuery)
      const completion = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'assistant', content: customQuery }],
        max_tokens: 250,
        stream: true,
      })
      //   console.log(completion)
      const asyncIterator = completion.iterator()
      for await (const value of asyncIterator) {
        if (value?.choices[0]?.delta?.content) {
          setCurrResponse(
            (prev) => prev + value?.choices[0]?.delta?.content ?? '',
          )
        }
      }
    } catch (error) {
      console.error('Error in fetching response:', error)
    } finally {
      setLoading(false) // Set loading state back to false
    }
  }

  // useEffect(() => {
  //   ;(async () => {
  //     if (speakOrChat === 'speak' && !lang.toLowerCase().includes('en')) {
  //       if (message[message?.length - 1]?.msg) {
  //         console.log(message[message?.length - 1]?.msg)
  //         let msg = message[message?.length - 1]?.msg
  //         let lang_from = 'en'
  //         let lang_to = lang
  //         // const result = await translate(msg, { to: lang_to })
  //         console.log(result)
  //       }
  //     }
  //   })()
  // }, [message])

  useEffect(() => {
    window.speechSynthesis.cancel()
  }, [])

  useEffect(() => {
    if (!loading && currResponse && speakOrChat == 'speak') {
      audio.load()
      setPlayingAudio(true)
      audio.play()
      setCurrResponse('')
      setStateAudio(audio)
      audio.addEventListener('ended', () => {
        setPlayingAudio(false)
      })
    }
    return () => {
      audio.pause()
    }
  }, [loading])

  if (speakOrChat === null) {
    return (
      <div className="bot-responsive text-center">
        <button
          className="btn btn-primary-regular"
          onClick={() => {
            // setSpeakOrChat('speak')
            // navigate('/speak-with-counselor')
            window.location.href = '/speak-with-counselor'
          }}
        >
          {' '}
          Talk to Counselor
        </button>
        <p style={{ margin: 0, textAlign: 'center' }}>
          <b>or</b>
        </p>
        <button
          className="btn btn-primary-regular"
          onClick={() => setSpeakOrChat('chat')}
        >
          Chat with Counselor
        </button>
      </div>
    )
  }

  const pauseAudio = () => {
    // audio?.currentTime = 0;
    // audio?.pause()
    stateAudio?.pause()
    // audio.src = audio.src
    setPlayingAudio(false)
    setCurrResponse('')
    // console.log('paused')
  }

  if (speakOrChat === 'speak') {
    return (
      <div className="container admin-createpage">
        <div className="bot-responses">
          {message.map((item, idx) => (
            <div
              className={
                item.user ? 'bot-response user_msg' : 'bot-response bot_msg'
              }
              key={idx}
            >
              <img
                src={require('../Assets/Media/Images/logo.png')}
                className="img-fluid bot-logo"
                alt="Rise Higher Education"
                title="Rise Higher Education"
                style={{
                  border: playingAudio ? '2px solid red' : '',
                }}
              />
              <p>{item.msg}</p>
            </div>
          ))}
        </div>
        <div className="bot-query">
          <VoiceTranscript
            hanldeChange={setQuery}
            getGPTResponse={getGPTResponse}
            setMessage={setMessage}
            pauseAudio={pauseAudio}
            setLang={setLang}
            lang={lang}
            setLanguage={setLanguage}
          />
        </div>
      </div>
    )
  }

  if (speakOrChat === 'chat') {
    return (
      <>
        <div className="bot-responses">
          {message.map((item, idx) => (
            <div
              className={
                item.user ? 'bot-response user_msg' : 'bot-response bot_msg'
              }
              key={idx}
            >
              <img
                src={require('../Assets/Media/Images/logo.png')}
                className="img-fluid bot-logo"
                alt="Rise Higher Education"
                title="Rise Higher Education"
                style={{
                  border: playingAudio ? '2px solid red' : '',
                }}
              />
              <p>{item.msg}</p>
            </div>
          ))}
        </div>
        <div className="bot-query">
          <p className="bot-heading">Type Your Question(s)</p>
          <textarea
            className="form-control"
            rows={2}
            col={3}
            value={query}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
          />
          <button
            className="btn btn-link float-end"
            disabled={query.length < 1 || loading}
            onClick={getGPTResponse}
          >
            {loading ? 'Processing...' : 'Ask Counselor'}
          </button>
        </div>
      </>
    )
  }
}

export default GlobalGPT
