import React, { useState, useEffect } from "react";
import "../Assets/Styles/sidebar-menu.css";
import ListItem from "./ListItem";
import countryStates from "../Assets/Data/state.json";
import countryUni from "../Assets/Data/university.json";
import countryRace from "../Assets/Data/raceethnicity.json";
import country from "../Assets/Data/countries.json";
import Filter from "./Filter";
import { url } from "../Constants";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { get } from "jquery";
import CountryFilter from "./CountryFilter";
import Joyride from "react-joyride";
import { steps } from "../Assets/Tutorials/Scholarship";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [view, setView] = useState("list");
  const authToken = localStorage.getItem("token");
  const [countryCount, setCountryCount] = useState([]);
  const navigate = useNavigate();

  const [sortParameter, setSortParamerter] = useState(0);
  const [universityData, setUniversityData] = useState([]);
  const [raceEthnicityData, setraceEthnicityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [initial, setInitial] = useState(true);
  const [cat, setCat] = useState("All");
  const [links, setLinks] = useState({
    v: "https://www.youtube.com/embed/k7XVWbxph1U",
    t: "",
  });

  const getCountryCount = async () => {
    const req = `${url}/scholarship/count`;
    const response = await fetch(req, {
      method: "GET",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: authToken,
      },
    });

    const responseData = await response.json();
    setCountryCount(responseData.data);
  };

  const isJsonString = async (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const [loggedUser, setLoggedUser] = useState({});
  const [filter, setFilter] = useState({
    Eligible_Citizenship: [],
    State: [],
    Destination_Country: [],
    University: [],
    Program_Level: [],
    Study_Area: [],
    Sex: [],
    Race_Ethnicity: [],
    Category: [],
  });

  const [filterData, setFilterData] = useState({
    Eligible_Citizenship: [],
    State: [],
    Destination_Country: [],
    University: [],
    Program_Level: [],
    Study_Area: [],
    Sex: [],
    Race_Ethnicity: [],
    Category: [],
  });

  const toggleGrid = () => {
    document.getElementById("list-header").style.display = "none";
    setView("grid");
  };

  const toggleList = () => {
    document.getElementById("list-header").style.display = "flex";
    setView("list");
  };

  const onChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(filter);
  };

  const getUser = async () => {
    const response = await fetch(`${url}/auth/verifyuser`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    setLoggedUser(json.data);
  };

  const handleReset = async (e) => {
    e.preventDefault();

    setFilter({
      Eligible_Citizenship: [],
      State: [],
      Destination_Country: [],
      University: [],
      Program_Level: [],
      Study_Area: [],
      Sex: [],
      Race_Ethnicity: [],
      Category: [],
    });

    setFilterData({
      Eligible_Citizenship: [],
      State: [],
      Destination_Country: [],
      University: [],
      Program_Level: [],
      Study_Area: [],
      Sex: [],
      Race_Ethnicity: [],
      Category: [],
    });

    setUniversityData([]);
    setraceEthnicityData([]);
    setStateData([]);

    await getData();
  };

  const removeFilter = async (f) => {
    setFilterData({ ...filterData, [f]: "" });
    setFilter({ ...filter, [f]: "" });
  };

  const sortStringsAlphabetically = async (arr) => {
    return await arr.sort(function (a, b) {
      return a.localeCompare(b);
    });
  };

  //for pagination
  const [data, setdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState({});
  const PAGE_SIZE_OPTIONS = [5, 10, 20];

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = async () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageSizeChange = async (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const getData = async () => {
    const req = `${url}/scholarship?page=${currentPage}&size=${pageSize}`;
    // console.log(filterData.Destination_Country)
    // set query
    localStorage.setItem("UserFilter", JSON.stringify(filterData));
    setIsLoading(true);
    const response = await fetch(req, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: authToken,
      },
      body: JSON.stringify({ query: filterData }),
    });

    const responseData = await response.json();
    //console.log(responseData)

    if (response.status === 200) {
      await handleSort(responseData.usersdata, sortParameter);
      setPageCount(responseData.Pagination.pageCount);
      setTotalCount(responseData.Pagination.count);
      //console.log(filterData?.Destination_Country,responseData?.Pagination?.count?.total);
    }
    setIsLoading(false);
  };

  const fetchQuery = async () => {
    const query = localStorage.getItem("UserFilter");
    if (!query) {
      getData();
      return;
    }

    if ((await isJsonString(query)) === false) {
      localStorage.removeItem("UserFilter");
      getData();
      return;
    }

    const q = await JSON.parse(query);

    if (query === (await JSON.stringify(filterData))) {
      getData();
      return;
    }
    setFilterData({
      Eligible_Citizenship: q.Eligible_Citizenship || [],
      State: q.State || [],
      Destination_Country: q.Destination_Country || [],
      University: q.University || [],
      Program_Level: q.Program_Level || [],
      Study_Area: q.Study_Area || [],
      Sex: q.Sex || [],
      Race_Ethnicity: q.Race_Ethnicity || [],
      Category: q.Category || [],
    });

    setFilter({
      Eligible_Citizenship: q.Eligible_Citizenship || [],
      State: q.State || [],
      Destination_Country: q.Destination_Country || [],
      University: q.University || [],
      Program_Level: q.Program_Level || [],
      Study_Area: q.Study_Area || [],
      Sex: q.Sex || [],
      Race_Ethnicity: q.Race_Ethnicity || [],
      Category: q.Category || [],
    });
  };

  const loadInitialConfig = async () => {
    setInitial(false);
    await fetchQuery();
    setIsLoading(false);
  };

  const changeCat = async (category) => {
    setIsLoading(true);

    setCat(category);
    setCurrentPage(1);
  };

  // for getting scholarship details for diffrent categories
  const getCategoryData = async (cat) => {
    const req = `${url}/scholarship/category?page=${currentPage}&size=${pageSize}`;
    const response = await fetch(req, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: authToken,
      },
      body: JSON.stringify({
        category: cat,
      }),
    });

    const json = await response.json();
    setdata(json.scholarships);
    setPageCount(json.Pagination.pageCount);
    setIsLoading(false);

    setTotalCount(...json.Pagination.count);

    // setIsLoading(false);
  };

  useEffect(() => {
    getUser();
    getCountryCount();
    if (initial) {
      loadInitialConfig();
    } else {
      if (cat === "All") {
        getData();
      } else {
        getCategoryData(cat);
      }
    }
  }, [currentPage, pageSize, cat, filterData]);

  const dataMapHandler = async () => {
    const cont = filter.Destination_Country[0];

    const Universitydata = (await countryUni[cont]) || [];
    const getUniversitydata = await sortStringsAlphabetically(Universitydata);

    const link = await country.find((countryItem) => countryItem.name === cont);

    const raceEthnicityData = (await countryRace[cont]) || [];
    const getraceEthnicityData = await sortStringsAlphabetically(
      raceEthnicityData
    );
    setraceEthnicityData(getraceEthnicityData);
    setUniversityData(getUniversitydata);
    setLinks({ t: (link && link.link) || "", v: (link && link.video) || "" });

    if (filter.Destination_Country.length > 0) {
      const getStatedata = await countryStates.find(
        (country) => country.country_name === filter.Destination_Country[0]
      );
      if (getStatedata !== undefined) {
        setStateData(getStatedata.states);
      }
    } else {
      setStateData([]);
    }
  };

  useEffect(() => {
    dataMapHandler();
  }, [filter]);

  const clrEli = async () => {
    setFilter({ ...filter, Eligible_Citizenship: [] });
    setFilterData({ ...filterData, Eligible_Citizenship: [] });
  };

  const clrDest = async () => {
    setFilter({ ...filter, Destination_Country: [] });
    setFilterData({ ...filterData, Destination_Country: [] });
  };

  const handleSort = async (arr, id) => {
    // alpha
    if (id === 0) {
      setdata(arr);
    } else if (id === 1) {
      await arr.sort((a, b) =>
        a.Scholarship_Name.localeCompare(b.Scholarship_Name)
      );
      setdata(arr);
    } else if (id === 2) {
      await arr.sort((a, b) =>
        b.Scholarship_Name.localeCompare(a.Scholarship_Name)
      );
      setdata(arr);
    }
    // // amount
    // else if (id === 3) {
    //   await arr.sort((a, b) => a.Amount > b.Amount)
    //   setdata(arr);
    // }
  };

  const [isPanelVisible, setPanelVisibility] = useState(
    window.innerWidth > 767 ? true : false
  );

  const togglePanel = () => {
    setPanelVisibility(!isPanelVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        // Adjust this breakpoint to your needs
        setView("grid");
      } else {
        setView("list");
      }
    };

    handleResize(); // Initial setup

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleJoyrideCallback = (data) => {
    if (data.action == "skip") {
      localStorage.setItem("scholarship-tut", "true");
    }
    if (data.action == 'next' && data.index == 0) {
      localStorage.setItem("scholarship-tut", "true");
    }
  };

  return (
    <div>
      {/* <Joyride
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        hideBackButton={true}
        hideCloseButton={true}
        showSkipButton={true}
        showProgress={true}
        run={localStorage.getItem("scholarship-tut") ? false : true}
        scrollDuration={300}
        continuous
        scrollOffset={{ x: 0, y: 0 }}
        callback={handleJoyrideCallback}
      /> */}
      <div
        className={`scholarship-page body-content ${
          isPanelVisible ? "" : "no-filterpanel"
        }`}
      >
        <button
          className={`${isPanelVisible ? "btn-hidefilter" : "btn-showfilter"}`}
          onClick={togglePanel}
        >
          <span>
            {isPanelVisible ? (
              <span>
                <i className="fa fa-angle-left"></i> Hide Filters
              </span>
            ) : (
              <span>
                <i className="fa fa-angle-right"></i> Show Filters
              </span>
            )}
          </span>
        </button>
        <div className="body-panel d-flex justify-content-between">
          <div
            className={`filter-container panel ${
              isPanelVisible ? "visible" : ""
            }`}
          >
            <div className="row">
              <div className="col">
                <div className="sidepanel-heading">Filter</div>
                <Filter
                  filter={filter}
                  onChange={onChange}
                  handleSubmit={handleSubmit}
                  handleReset={handleReset}
                  countrydata={countryStates}
                  stateData={stateData}
                  universityData={universityData}
                  raceEthnicityData={raceEthnicityData}
                  data={filterData}
                  setData={removeFilter}
                  setFilter={setFilter}
                  clrDest={clrDest}
                  clrEli={clrEli}
                />
              </div>
            </div>
          </div>
          <div className="body-container">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-between mt-3">
                  <div className="page-content">
                    <h1 className="mb-3">Scholarships</h1>
                    <p>
                      <strong>
                        Worried about financing your higher studies?
                      </strong>
                    </p>
                    <p>
                      <strong>ScholaRISE</strong>: Empowering students with
                      personalized scholarships, revolutionizing access to
                      education, and giving you the competitive edge to achieve
                      your dreams.
                    </p>
                    {filter.Destination_Country !== undefined &&
                    filter.Destination_Country.length > 0 ? (
                      <a
                        href={links.t}
                        target="_blank"
                        className="link-transcript"
                      >
                        Info Guide
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="video-container ms-5">
                    {/* <button className="btn btn-secondary" onClick={() => {localStorage.removeItem('scholarship-tut'); window.location.reload()}}>Need help ? </button> */}
                    &nbsp;
                    <button
                      onClick={() => {
                        navigate("/userdashboard");
                      }}
                      className="widget-cta-page-nopos float-end mb-3"
                    >
                      <i className="fa fa-home"></i> Back to Dashboard
                    </button>
                    <iframe
                      width="500"
                      height="250"
                      src={links.v}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col">
                <div className="scholarship-card">
                  <img src="https://risehigher.education/countries_flags/us.svg" />
                  <button className="btn-nostyle scholarship-card-count">1973 <i className="fa fa-filter"></i></button>
                </div>
              </div>
            </div> */}

            <div className="subsection-head">
              <p>Country Filter:</p>
              <CountryFilter
                onClick={setFilterData}
                setFilter={setFilter}
                loading={isLoading}
                countryCount={countryCount}
              />
            </div>
            {/* button for adding to categories start here */}
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-all-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-all"
                  type="button"
                  role="tab"
                  aria-controls="pills-all"
                  aria-selected="true"
                  onClick={() => changeCat("All")}
                >
                  All (
                  {totalCount["total"] !== undefined ? totalCount["total"] : 0})
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-applied-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-applied"
                  type="button"
                  role="tab"
                  aria-controls="pills-applied"
                  aria-selected="false"
                  onClick={() => changeCat("Applied")}
                >
                  Applied (
                  {totalCount["Applied"] !== undefined
                    ? totalCount["Applied"]
                    : 0}
                  )
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-approved-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-approved"
                  type="button"
                  role="tab"
                  aria-controls="pills-approved"
                  aria-selected="false"
                  onClick={() => changeCat("Approved")}
                >
                  Approved (
                  {totalCount["Approved"] !== undefined
                    ? totalCount["Approved"]
                    : 0}
                  )
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-savedforlater-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-savedforlater"
                  type="button"
                  role="tab"
                  aria-controls="pills-savedforlater"
                  aria-selected="false"
                  onClick={() => changeCat("SavedForLater")}
                >
                  Saved for Later (
                  {totalCount["SavedForLater"] !== undefined
                    ? totalCount["SavedForLater"]
                    : 0}
                  )
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-pendingdecision-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-pendingdecision"
                  type="button"
                  role="tab"
                  aria-controls="pills-pendingdecision"
                  aria-selected="false"
                  onClick={() => changeCat("PendingDecision")}
                >
                  Pending Decision (
                  {totalCount["PendingDecision"] !== undefined
                    ? totalCount["PendingDecision"]
                    : 0}
                  )
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-rejected-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-rejected"
                  type="button"
                  role="tab"
                  aria-controls="pills-rejected"
                  aria-selected="false"
                  onClick={() => changeCat("Rejected")}
                >
                  Rejected (
                  {totalCount["Rejected"] !== undefined
                    ? totalCount["Rejected"]
                    : 0}
                  )
                </button>
              </li>
            </ul>

            {/* button for adding to categories ended here */}
            {/* button for adding to categories ended here */}

            <div className="row">
              <div className="col">
                <div className="grid-container list-container-step">
                  {/* <button className="grid" onClick={() => {
                    setSortParamerter(3)
                    handleSort(data, 3);
                  }}>
                    Max Amount
                  </button>
                  <button className="grid" onClick={() => {
                    setSortParamerter(4)
                    handleSort(data, 4);
                  }}>
                    Min amount
                  </button> */}
                  <div className="buttons grid-layout">
                    <button className="grid" onClick={toggleGrid}>
                      <i className="fa-solid fa-grip" />
                    </button>
                    <button className="list" onClick={toggleList}>
                      <i className="fa-solid fa-list" />
                    </button>
                  </div>
                  <ul className="list-detail list-header" id="list-header">
                    <li className="list-width-15">
                      Name
                      <button
                        className="btn sort-icon"
                        onClick={() => {
                          setSortParamerter(1);
                          handleSort(data, 1);
                        }}
                      >
                        <i className="fa-solid fa-caret-up"></i>
                      </button>
                      <button
                        className="btn sort-icon"
                        onClick={() => {
                          setSortParamerter(2);
                          handleSort(data, 2);
                        }}
                      >
                        <i className="fa-solid fa-caret-down"></i>
                      </button>
                    </li>
                    <li className="list-width-10 eligibility-step">
                      Eligibility
                    </li>
                    {/* <li className="list-width-10">Match Score</li> */}
                    <li className="list-width-10">Level</li>
                    <li className="list-width-10">Deadline</li>
                    <li className="list-width-10">Amount</li>
                    <li className="list-width-10">Process</li>
                    {cat !== "All" ? <li className="list-width-5"></li> : ""}
                  </ul>
                  <ul
                    className={
                      view === "list" ? "list grid-list" : "grid grid-list"
                    }
                  >
                    {isLoading ? (
                      <div
                        className="circle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : data.length === 0 ? (
                      <p>No data available.</p>
                    ) : (
                      data.map((element) => {
                        return (
                          <ListItem
                            key={element._id}
                            data={element}
                            user={loggedUser}
                            mode={view}
                            id={element._id}
                            imgsrc={
                              element.Image_Address !== undefined
                                ? element.Image_Address
                                : "missing"
                            }
                            // location={element.State ? element.State : "missing"}
                            // ownership={
                            //   element.University ? element.University : "missing"
                            // }
                            destination_country={
                              element.Destination_Country
                                ? element.Destination_Country
                                : "missing"
                            }
                            eligibility_citizenship={
                              element.Eligible_Citizenship
                                ? element.Eligible_Citizenship
                                : "missing"
                            }
                            // scholarship_organization={
                            //   element.Awarding_Body
                            //     ? element.Awarding_Body
                            //     : "missing"
                            // }

                            scholarship_name={
                              element.Scholarship_Name
                                ? element.Scholarship_Name
                                : "Not Applicable"
                            }
                            scholarship_level={
                              element.Program_Level
                                ? element.Program_Level
                                : "Not Applicable"
                            }
                            scholarship_link={
                              element.Application_Link
                                ? element.Application_Link
                                : "#"
                            }
                            scholarship_amount={
                              element.Amount ? element.Amount : "Not Applicable"
                            }
                            scholarship_deadline={
                              element.Application_End_Date
                                ? element.Application_End_Date
                                : "Not Applicable"
                            }
                            // key={element._id}
                            cat={cat}
                            getCategoryData={getCategoryData}
                          />
                        );
                      })
                    )}
                  </ul>

                  {data.length > 0 ? (
                    <>
                      <div className="grid-size">
                        <label htmlFor="pageSizeSelect">Page Size: </label>
                        <select
                          id="pageSizeSelect"
                          value={pageSize}
                          onChange={handlePageSizeChange}
                        >
                          {PAGE_SIZE_OPTIONS.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="grid-contols">
                        <button
                          disabled={currentPage === 1}
                          onClick={handlePreviousPage}
                        >
                          Previous Page
                        </button>
                        <button
                          disabled={currentPage === pageCount}
                          onClick={handleNextPage}
                        >
                          Next Page
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            navigate("/assessment");
          }}
          className="widget-cta-page float-end mx-4"
        >
          Get Coaching
        </button>
        <div className="footer-links pt-5">
          <button
            type="button"
            className="footer-btn"
            data-bs-toggle="modal"
            data-bs-target="#disclaimerModal"
          >
            Disclaimer
          </button>
          <button
            type="button"
            className="footer-btn"
            data-bs-toggle="modal"
            data-bs-target="#TnCModal"
          >
            Terms &amp; Conditions
          </button>
          <div
            className="modal fade"
            id="disclaimerModal"
            tabIndex={-1}
            aria-labelledby="disclaimerModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="disclaimerModal">
                    Disclaimer
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <p>
                    The ScholaRISE Scholarship Portal ("Portal") is an online
                    platform designed to connect students with scholarship
                    opportunities. While we strive to provide accurate and
                    up-to-date information, we would like to clarify the
                    following points:
                  </p>
                  <ol>
                    <li>
                      <strong>Scholarship Outcome:</strong> The selection and
                      awarding of scholarships are at the sole discretion of the
                      scholarship providers. ScholaRISE does not have any
                      control over the outcome of scholarship applications. We
                      serve as a facilitator to help students find and apply for
                      relevant scholarships, but the final decision rests with
                      the scholarship provider.
                    </li>
                    <li>
                      <strong>No Charges for Access:</strong> ScholaRISE does
                      not charge students for accessing the Portal or using its
                      services. Our primary objective is to provide a free
                      resource to assist students in their scholarship search
                      and application process.
                    </li>
                    <li>
                      <strong>No Benefit from Scholarships:</strong> ScholaRISE
                      does not benefit financially from any scholarships awarded
                      to students through the Portal. We are committed to
                      providing unbiased and objective information to help
                      students access financial aid opportunities.
                    </li>
                    <li>
                      <strong>Scholarship Articulation:</strong> We have taken
                      utmost care to present scholarship details accurately and
                      comprehensively. However, it is important to note that
                      scholarship information, including eligibility criteria,
                      application deadlines, and award amounts, is subject to
                      change. We recommend that students verify the details
                      directly with the scholarship provider before applying.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="TnCModal"
            tabIndex={-1}
            aria-labelledby="TnCModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="TnCModal">
                    Terms &amp; Conditions
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <p>
                    By accessing and using the ScholaRISE Scholarship Portal,
                    you agree to the following terms and conditions:
                  </p>
                  <ol>
                    <li>
                      <strong>Eligibility:</strong> The Portal is available to
                      students who meet the eligibility criteria specified by
                      the scholarship providers. It is your responsibility to
                      ensure that you meet the requirements before applying for
                      any scholarships listed on the Portal.
                    </li>
                    <li>
                      <strong>Accuracy of Information:</strong> While we strive
                      to provide accurate and up-to-date information, we cannot
                      guarantee the accuracy, completeness, or reliability of
                      the scholarship details. We encourage users to verify the
                      information directly with the scholarship provider.
                    </li>
                    <li>
                      <strong>Third-Pay Websites:</strong> The Portal may
                      contain links to third-party websites or resources. These
                      links are provided for your convenience, and we do not
                      endorse or have control over the content, policies, or
                      practices of these websites. Your use of third-party
                      websites is at your own risk.
                    </li>
                    <li>
                      <strong>Privacy:</strong> We respect your privacy and
                      handle your personal information in accordance with our
                      Privacy Policy. By using the Portal, you consent to the
                      collection, use, and disclosure of your information as
                      described in the Privacy Policy.
                    </li>
                    <li>
                      <strong>Intellectual Property:</strong> All content and
                      materials on the Portal, including text, graphics, logos,
                      and images, are protected by intellectual property laws.
                      You may use the information for personal, non-commercial
                      purposes only. Any unauthorized use or reproduction of the
                      content is strictly prohibited.
                    </li>
                    <li>
                      <strong>Limitation of Liability:</strong> ScholaRISE and
                      its affiliates shall not be liable for any direct,
                      indirect, incidental, consequential, or punitive damages
                      arising out of or in connection with your use of the
                      Portal or reliance on the scholarship information
                      provided.
                    </li>
                  </ol>
                  <p>
                    These terms and conditions may be subject to change without
                    prior notice. It is your responsibility to review them
                    periodically for any updates.
                  </p>
                  <p>
                    If you have any questions or concerns regarding the Portal,
                    please contact our support team at{" "}
                    <a href="mailto:contact@risehighereducation.com">
                      contact@risehighereducation.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
