import React, { useEffect, useState } from 'react'

const BackgroundInfo = (props) => {

    const [originalData, setOriginalData] = useState({
        immigrant: "",
        immigrantCountry: "",
        seriousMedical: "",
        seriousMedicalName: "",
        visaRefusal: "",
        visaRefusalCountry: "",
        criminal: "",
    })

    const [data, setData] = useState(originalData);

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setData(props.data);
            setOriginalData(props.data); // Set the original data as well
        }
    }, [props.data]);

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleCancel = () => {
        setData(originalData); // Reset to original data
    };

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setData(props.data)
        }
    }, [props.data])

    return (
        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <div className="row profile-section">
                <div className="col">
                    <div className="section-head">Background Information</div>
                    <div className="form-group section-content">
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <label htmlFor="">
                                    Has applicant applied for any type of immigration into any
                                    country?<span className="required">*</span>
                                </label>
                                <br />
                                <input type="radio" className="btn-check" checked={data.immigrant === 'no'} onClick={() => { setData({ ...data, immigrant: "no" }) }} name="immigrant" id="option11" />
                                <label className="btn btn-secondary" htmlFor="option11">
                                    No
                                </label>
                                <input type="radio" className="btn-check" checked={data.immigrant === 'yes'} onClick={() => { setData({ ...data, immigrant: "yes" }) }} name="immigrant" id="option12" autoComplete="off" />
                                <label className="btn btn-secondary" htmlFor="option12">
                                    Yes
                                </label>
                            </div>
                            {data.immigrant === "yes" ? <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" value={data.immigrantCountry} onChange={onChange} name="immigrantCountry" className="form-control"
                                        defaultValue="" placeholder="Country Name"
                                        aria-label="Country Name" />
                                    <label htmlFor="">Country Name</label>
                                </div>
                            </div> : ""}
                        </div>
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <label htmlFor="">
                                    Does applicant suffer from a serious medical condition?
                                    <span className="required">*</span>
                                </label>
                                <br />
                                <input type="radio" className="btn-check" checked={data.seriousMedical === 'no'} onClick={() => { setData({ ...data, seriousMedical: "no" }) }} name="seriousMedical" id="option13" />
                                <label className="btn btn-secondary" htmlFor="option13">
                                    No
                                </label>
                                <input type="radio" className="btn-check" checked={data.seriousMedical === 'yes'} onClick={() => { setData({ ...data, seriousMedical: "yes" }) }} name="seriousMedical" id="option14" autoComplete="off" />
                                <label className="btn btn-secondary" htmlFor="option14">
                                    Yes
                                </label>
                            </div>
                            {data.seriousMedical === "yes" ? <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" value={data.seriousMedicalName} onChange={onChange} name="seriousMedicalName" className="form-control"
                                        defaultValue="" placeholder="Medical Condition"
                                        aria-label="Medical Condition" />
                                    <label htmlFor="">Medical Condition</label>
                                </div>
                            </div> : ""}
                        </div>
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <label htmlFor="">
                                    Has applicant Visa refusal for any country?
                                    <span className="required">*</span>
                                </label>
                                <br />
                                <input type="radio" className="btn-check" checked={data.visaRefusal === 'no'} onClick={() => { setData({ ...data, visaRefusal: "no" }) }} name="visaRefusal" id="option15"
                                />
                                <label className="btn btn-secondary" htmlFor="option15">
                                    No
                                </label>
                                <input type="radio" className="btn-check" checked={data.visaRefusal === 'yes'} onClick={() => { setData({ ...data, visaRefusal: "yes" }) }} name="visaRefusal" id="option16"
                                    autoComplete="off" />
                                <label className="btn btn-secondary" htmlFor="option16">
                                    Yes
                                </label>
                            </div>
                            {data.visaRefusal === "yes" ? <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" value={data.visaRefusalCountry} onChange={onChange} name="visaRefusalCountry" className="form-control"
                                        defaultValue="" placeholder="Country Name"
                                        aria-label="" />
                                    <label htmlFor="">Country Name</label>
                                </div>
                            </div> : ""}
                        </div>
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <label htmlFor="">
                                    Has applicant ever been convicted of a criminal offence?
                                    <span className="required">*</span>
                                </label>
                                <br />
                                <input type="radio" className="btn-check" checked={data.criminal === 'no'} onClick={() => { setData({ ...data, criminal: "no" }) }} name="criminal" id="option17"
                                />
                                <label className="btn btn-secondary" htmlFor="option17">
                                    No
                                </label>
                                <input type="radio" className="btn-check" checked={data.criminal === 'yes'} onClick={() => { setData({ ...data, criminal: "yes" }) }} name="criminal" id="option18"
                                    autoComplete="off" />
                                <label className="btn btn-secondary" htmlFor="option18">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div className="row responsive-col">
                            <div className="col text-end action-btn-group">
                                <button type="submit" className="btn btn-secondary" onClick={handleCancel}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary-regular" onClick={() => { props?.updateData("backgroundInfo", data) }}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackgroundInfo