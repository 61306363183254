import React, { useEffect } from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'

import { countriesData } from '../../Assets/Data/countriesDetails'
import countries from '../../Assets/Data/countries.json'
import { useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import swal from 'sweetalert'

import { url } from '../../Constants';

const SortTile = ({ name, value, setFormData, setTotalScores }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center sortTile_container">
        <div className="d-flex justify-content-start align-items-center sortTile_container">
          <span className="drag-icon">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <h2 className="sortTile_h2">{value}</h2>
          <h1 className="sortTile_h1">{name}</h1>
        </div>

        <MdDelete
          style={{ color: 'red', marginRight: '1rem' }}
          onClick={() =>
            setFormData((prev) => {
              setTotalScores([])
              if (prev.length > 1) {
                return prev.filter((item) => item.name !== name)
              } else {
                swal({
                  title: 'Cannot delete last attribute!',
                  icon: 'error',
                })
                return prev
              }
            })
          }
        />
      </div>
    </>
  )
}

const CountryTile = ({
  data,
  name,
  value,
  score,
  otherCountryDetail,
  formData,
  studyDest,
  handleSaveStudyDest
}) => {
  const renderText = (quality, data, country) => {
    let populatedData = ''
    switch (quality) {
      case 'Employment Rate':
        populatedData = `${data}%`
        break
      case 'University Rankings':
        populatedData = `${data} universities out Top 100 are in ${country}`
        break
      case 'Tuition Fees':
        populatedData = `Annual Average $${data} `
        break
      case 'Graduate Pay Scales':
        populatedData = `$${data} Per Annum`
        break
      case 'Cost of Living':
        populatedData = `$${data} Per Annum`
        break
      case 'PR and Settlement Opportunities':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Spouse Work Rights':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Cultural Fit':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Safety and Security':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Quality of Life':
        populatedData = `Rated ${data} out of 5`
        break
      default:
        populatedData = ''
    }
    return populatedData
  }

  const studyDestArray = Array.isArray(studyDest) ? studyDest : [studyDest];

  // console.log(otherCountryDetail)
  return (
    <>
      <div className="d-flex justify-content-start align-items-center countryTile_container">
        <span className="countryTile_tag">{value}</span>
        {studyDestArray.some(
          (dest) => dest?.trim().toLowerCase() === name?.trim().toLowerCase()
        ) ? (
          <small className="badge bg-success">Saved Study Destination</small>
        ) : (
          <button
            onClick={() => handleSaveStudyDest([...studyDest, name])}
            className='btn btn-link'
            disabled={studyDestArray.length >= 3} // Disable button if 3 destinations are already selected
          >
            {studyDestArray.length >= 3 ? 'Max Study Destinations Selected' : 'Save Study Destinations'}
          </button>
        )}
        <h1 className="countryTile_h1">
          {name}
        </h1>
        {Object.keys(data).map(
          (itm) =>
            formData.find(
              (ietm) => ietm.name == itm?.slice(5).split('_').join(' '),
            ) &&
            !itm.includes('rograms') &&
            itm.includes('_') && (
              <div className="p-2 w-100 text-left" key={itm}>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  <b>{itm?.slice(5).split('_').join(' ') + ': '}</b>{' '}
                  {renderText(
                    itm?.slice(5).split('_').join(' '),
                    data[itm],
                    name,
                  )}
                  {/* {} :{data[itm]}{' '} */}
                </p>
              </div>
            ),
        )}
        {/* <p className="countryTile_score">Score: {score}</p> */}
      </div>
    </>
  )
}

const CountryHelp = ({
  setShow,
  thumbData,
  setModals,
  setCountry,
  setThumbData,
  updateData
}) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState([
    { name: 'Employment Rate' },
    { name: 'University Rankings' },
    { name: 'Tuition Fees' },
    { name: 'PR and Settlement Opportunities' },
    { name: 'Graduate Pay Scales' },
    { name: 'Spouse Work Rights' },
    { name: 'Cost of Living' },
    { name: 'Cultural Fit' },
    { name: 'Safety and Security' },
    { name: 'Quality of Life' },
  ])
  const [totalScores, setTotalScores] = useState([])
  const [studyDest, setStudyDest] = useState('')

  const getData = async () => {
    const response = await fetch(`${url}/auth/verifyuser`, {
      method: 'POST',
      mode: 'cors',
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': localStorage.getItem('token')
      },
    })

    const res_json = await response.json();

    const fetchedStudyDest = res_json?.data?.studyDest ?? [];
    setStudyDest(fetchedStudyDest);

    // Save it to the state

    //const json = res_json.data;
    //setData({ ...data, firstName: json.firstName, lastName: json.lastName, email: json.email, phone: json.phone, countryCode: json.countryCode || "", studyDest: json.studyDest[json?.studyDest?.length-1] })
  }


  const handleSaveStudyDest = async (newStudyDestArray) => {
    try {
      const response = await fetch(`${url}/user/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'), // Ensure this token is valid
        },
        body: JSON.stringify({
          feild: 'userInfo',  // Check if this is a typo ('field' instead of 'feild'?)
          data: {
            studyDest: newStudyDestArray,  // Update studyDest
          },
        }),
      });
  
      // Log the raw response for debugging
      console.log('Raw response:', response);
  
      if (!response.ok) {
        // Log the error message if the response is not successful
        console.error('Error response:', await response.text());
        swal({
          title: 'Error!',
          text: `Failed with status: ${response.status}`,
          icon: 'error',
        });
        return;
      }
  
      const result = await response.json();
  
      if (result.success) {
        swal({
          title: 'Saved!',
          text: 'Study destination updated successfully.',
          icon: 'success',
        });
  
        // Re-fetch the updated data to reflect changes in the UI
        await getData();
      } else {
        swal({
          title: 'Error!',
          text: result.message || 'Failed to update the destination.',
          icon: 'error',
        });
      }
    } catch (error) {
      swal({
        title: 'Error!',
        text: 'Failed to update the destination. Please try again.',
        icon: 'error',
      });
      console.error('Error saving study destination:', error);
    }
  };

  const handleSubmit = () => {
    setCountry(true);
    const scores = [];
    for (let i = 0; i < countriesData.length; i++) {
      const countryData = countriesData[i];
      let totalScore = 0;
      for (const attribute in formData) {
        if (countryData.hasOwnProperty(formData[attribute].name)) {
          totalScore +=
            countryData[formData[attribute].name] * (Number(attribute) + 1);
        }
      }

      let otherCountryDetail = countries.find(
        (itm) =>
          itm.name === countryData.Country || itm?.code === countryData.Country
      );
      scores.push({
        data: countryData,
        Country: countryData.Country,
        TotalScore: totalScore.toFixed(2),
        otherCountryDetail: otherCountryDetail,
      });
    }
    scores.sort((a, b) => b.TotalScore - a.TotalScore);
    setTotalScores(scores.slice(0, 3));
    let topcountry =
      scores.slice(0, 3).map((itm) => itm?.otherCountryDetail) ?? {};
    setThumbData(topcountry);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="modal-body">
        <div className="d-flex justify-content-between">
          <p className="modal-subheading">
            Rate your preferences (1 lowest, 10 highest) to discover your
            matched destination
          </p>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setFormData([
                { name: 'Employment Rate' },
                { name: 'University Rankings' },
                { name: 'Tuition Fees' },
                { name: 'PR and Settlement Opportunities' },
                { name: 'Graduate Pay Scales' },
                { name: 'Spouse Work Rights' },
                { name: 'Cost of Living' },
                { name: 'Cultural Fit' },
                { name: 'Safety and Security' },
                { name: 'Quality of Life' },
              ])
              setTotalScores([])
            }}
          >
            Reset
          </button>
        </div>
        <p className="info-text">Drag and re-order based on your priority</p>
      </div>
      <div className="px-3">
        <ReactSortable
          className="d-flex flex-column"
          style={{ gap: '1rem' }}
          list={formData}
          setList={setFormData}
          swap
        >
          {formData.map((item, index) => (
            <SortTile
              name={item.name}
              value={index + 1}
              setFormData={setFormData}
              handleSubmit={handleSubmit}
              setTotalScores={setTotalScores}
            />
          ))}
        </ReactSortable>
        <div className="pt-3 pb-3">
          <button
            className="btn btn-primary-regular"
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {totalScores.length > 0 && (
        <div className="px-3 pb-3">
          <h1
            className="sortTile_h1 text-center"
            style={{ fontWeight: '600', color: '#000' }}
          >
            Top 3 Results{' '}
          </h1>
          <br />
          <br />
          <div
            className="d-flex flex-row justify-content-center result-container"
            style={{ gap: '1rem' }}
          >
            {totalScores?.map((item, index) => (
              <CountryTile
                formData={formData}
                data={item?.data}
                name={item.Country}
                value={index + 1}
                score={item.TotalScore}
                otherCountryDetail={item.otherCountryDetail}
                studyDest={studyDest}
                handleSaveStudyDest={handleSaveStudyDest}
                setTotalScores={setTotalScores}
              />
            ))}
          </div>
        </div>
      )}

      <FooterModal
        navigate={navigate}
        nextElement={
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn"
              onClick={() => {
                setShow(false)
                setCountry(false)
                setThumbData([])
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  needHelpCourrse: false,
                  countryScore: false,
                  courseMain: true,
                })
              }}
            >
              Skip to Course Counseling
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              disabled={totalScores.length < 1}
              onClick={() => {
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  courseMain: false,
                  needHelpCourrse: false,
                })
                setShow(true)
                setCountry(true)
              }}
            >
              Change Country
            </button>{' '}
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-primary-regular"
              disabled={totalScores.length < 1}
              onClick={() => {
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  courseMain: false,
                  needHelpCourrse: false,
                })
                setShow(false)
              }}
            >
              Start Counselling
            </button>
          </div>
        }
      />
    </>
  )
}

export default CountryHelp
