import React, { useEffect, useState } from 'react';
import '../../Assets/Styles/dashboard.css'
import { Pagination } from 'react-bootstrap';
import { BsPencilSquare } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { url } from '../../Constants';
import swal from 'sweetalert';


function Employee() {
  const [data, setData] = useState([])

  const getEmployee = async () => {
    const res = await fetch(`${url}/getAllEmployees`)
    const empData = await res.json()
    setData(empData);
    //console.log(empData);
  }

  // Show a Yes/No dialog on before Delete

  const showDeleteScholarshipModal = (id) => {
    //console.log(id)
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
        deleteEmployee(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  }

  const deleteEmployee = async (id) => {
    // console.log(id)
    try {
      const res = await fetch(`${url}/deleteEmployee/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
      });
      const deleteData = await res.json();
      // console.log(deleteData);
      swal({
        title: "Success",
        text: "Data Deleted Successfully!",
        icon: "success",
        button: "Ok",
      });
      getEmployee();
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    getEmployee();
  }, [])


  return (
    <div className='container' >
      <div className='row actionBox'>
        <div className='col-2'>
          <button type="button" className="btn btn-primary"><b>All Employees Data</b></button>
        </div>
        <div className='col-2'>
          <Link to={'/dashboard'}>
            <button type="button" className="btn btn-primary"><b>Dashboard</b></button>
          </Link>
        </div>
        <div className='col-2'>
        </div>
        <div className='col-2'>
        </div>
        <div className='col-2'>
        </div>
        <div className='col-2' style={{ textAlign: "end" }}>
          <Link to={'addemployee'}>
            <button style={{ backgroundColor: "white" }} className='btn btn-success'><b style={{ color: "black" }}> +</b><FaUserTie style={{ color: "forestgreen", fontSize: "1.5rem" }} /> </button>
          </Link>
        </div>
      </div>


      <div className='container' >
        <table className="table">
          <thead>
            <tr className='table-dark'>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Salary</th>
              <th scope="col">Date of Joining</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((item, id) => (
                <tr key={item.id}>
                  <td><b>{id + 1}</b></td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{item.salary}</td>
                  <td>{item.doj}</td>
                  <td>{item.designation}</td>
                  <td className='d-flex actionBtn'>
                    <Link to={`editemployee/${item._id}`}>
                      <button className='btn btn-primary' style={{ backgroundColor: "white" }}><BsPencilSquare style={{ color: "#0A5FD7", fontSize: "1.2rem" }} /></button>
                    </Link>
                    <button onClick={() => showDeleteScholarshipModal(item._id)} style={{ backgroundColor: "white" }} className='btn btn-danger'><MdDelete style={{ color: "red", fontSize: "1.2rem" }} /></button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        {/* <Pagination className='d-flex justify-content-end mb-5'>
          <Pagination.Prev
            disabled={currentPageTest === 1}
            onClick={() => handlePageChangeTest(currentPageTest - 1)}
          />
          {[...Array(totalPagesTest).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPageTest}
              onClick={() => handlePageChangeTest(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={currentPageTest === totalPagesTest}
            onClick={() => handlePageChangeTest(currentPageTest + 1)}
          />
        </Pagination> */}
      </div>
    </div>
  )
}

export default Employee;