import React, { useEffect, useState } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../Api/Api";
import swal from "sweetalert";
import { getNewToken } from "./utils/CalendlyTokens";
import AllBookingsStudent from "./Pages/AllBookingsStudent";
import MentorChatUserSide from "./MentorComponent/MentorChatUserSide";
import { Button, Modal, Nav, Pagination } from "react-bootstrap";

const MentorProfile = ({ socket }) => {
  const [userData, setUserData] = useState({ credits: 0 });
  const [bookClicked, setBookClicked] = useState(false);
  const [urlSearchParams] = useSearchParams();
  const [balanceModal, setBalanceModal] = useState(false);

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      try {
        //
        const id = await getNewToken(params.id);

        const event = await (
          await fetch(e.data.payload.event.uri, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${id}`,
            },
          })
        ).json();
        // console.log('event', event)
        const data = await API("post", `/mentor/create-booking`, {
          mentor: params.id,
          user: userData?._id,
          meetinglink: e.data.payload.event.uri,
          meetingTime: event?.resource.start_time,
          extLink: event?.resource.location.join_url,
        });
      } catch (error) {}
    },
  });
  const [book, setBook] = useState(false);
  const [mentor, setMentor] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const getMentors = async () => {
    try {
      const data = await API("get", `/mentor/${params.id}`);
      setMentor(data?.data?.data);
    } catch (error) {
    } finally {
    }
  };

  const getUserDetail = async () => {
    try {
      const response = await API("post", `/auth/verifyuser`);
      setUserData(response.data.data);
    } catch {}
  };

  const [panelVisible, setPanelVisible] = useState(false);
  const togglePanel = () => {
    setPanelVisible(!panelVisible);
  };

  useEffect(() => {
    getMentors();
    if (urlSearchParams.get("cal") == "ok") {
      setBook(true);
    }
    if (localStorage.getItem("token")) {
      getUserDetail();
    }
  }, []);

  return (
    <>
      <main className="mentor-insidepage" id="content">
        <section id="about" className="introduction scrollto">
          <div className="row clearfix">
            <div className="mentor-col-2-3 mt-3">
              <blockquote className="testimonial classic">
                {mentor?.avatar ? (
                  <img src={mentor?.avatar} alt="Avatar" />
                ) : (
                  <div className="user-image-thumb">
                    <i className="fa fa-user"></i>
                  </div>
                )}
                <h2 className="mentor-section-title">{mentor?.name} </h2>
                <p>
                  <a href="mailto:">({mentor?.email || "loading..."})</a>
                </p>
                <p>{mentor?.aoe}</p>
                {mentor?.relExp && <span>Relevant Exp: {mentor?.relExp}</span>}
                <q>
                  <span
                    dangerouslySetInnerHTML={{ __html: mentor?.description }}
                  ></span>
                </q>
                <div className="tags">
                  {mentor?.tags?.map((itm, index) => (
                    <span className="tag" key={index}>
                      {itm}{" "}
                    </span>
                  ))}
                </div>
                <br />
                {localStorage?.getItem("token") ? (
                  <button
                    className="mentor-button-sm"
                    onClick={() => {
                      if (userData?.credits < 1) {
                        setBalanceModal(true);
                      } else {
                        setBook(!book);
                      }
                    }}
                    // disabled={userData?.credits < 1 ? true : false}
                  >
                    Book Now
                  </button>
                ) : (
                  <>
                    {bookClicked ? (
                      <div>
                        <button
                          className="mentor-button-sm"
                          onClick={() => {
                            navigate("/signup?utm_source=mentor_profile_page");
                          }}
                        >
                          Sign Up to Book{" "}
                        </button>
                        &nbsp; &nbsp;
                        <button
                          className="mentor-button-sm"
                          onClick={() => {
                            navigate("/login?utm_source=mentor_profile_page");
                          }}
                        >
                          Login to Book{" "}
                        </button>
                      </div>
                    ) : (
                      <button
                        className="mentor-button-sm"
                        onClick={() => {
                          setBookClicked(true);
                          localStorage.setItem("mentor_nav", params.id);
                        }}
                      >
                        Book Now{" "}
                      </button>
                    )}
                  </>
                )}
              </blockquote>
            </div>
            <div className="mentor-col-1-3 calendar-container">
              {localStorage.getItem("token") && (
                <>
                  <div className="d-flex justify-content-between">
                    <p className="mt-3 mb-3">
                      <strong>Your Bookings with {mentor?.name}</strong>
                    </p>
                    <button
                      className="btn btn-primary-regular h-100 mt-2"
                      onClick={togglePanel}
                    >
                      Chat with Mentor
                    </button>
                  </div>
                  <AllBookingsStudent
                    userid={userData?._id}
                    mentorid={params.id}
                  />
                </>
              )}
            </div>
            {panelVisible && (
              <div className="mentorchat-panel">
                <div className="text-end">
                  <button onClick={togglePanel} className="btn">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                {localStorage.getItem("token") && (
                  <MentorChatUserSide
                    socket={socket}
                    mentor={mentor?._id}
                    user={userData?._id}
                  />
                )}
              </div>
            )}
          </div>
          <div className="modal" id="modal-one" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-header">
                <div className="d-flex justify-content-between">
                  <h2>Get Started</h2>
                  <a href="#" className="btn-close" aria-hidden="true">
                    <i className="fa fa-close"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div>
                  <label for="email">Email</label>
                  <input type="text" id="email" />
                </div>
                <div>
                  <label for="password">Password</label>
                  <input type="password" id="password" />
                </div>
              </div>
              <div className="modal-footer">
                <button className="button-modal-secondary">Log In</button>
                <button className="button-modal-secondary">
                  Not an account? Sign Up
                </button>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={balanceModal}
          onHide={() => setBalanceModal(false)}
          className="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Insufficient Credit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <button
              className="btn btn-primary"
              style={{width:'100px'}}
              onClick={() => navigate("/refill-credits")}
            >
              Buy Now
            </button> &nbsp;
            <button className="btn btn-danger" onClick={() => setBalanceModal(false)}>
              Cancel
            </button>
          </Modal.Body>
        </Modal>

        <Modal show={book} onHide={() => setBook(!book)} className="modal-lg">
          <Modal.Header closeButton>
            <Modal.Title>Mentor Calender</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div style={{ textAlign: "center", width:'700px' }}> */}
            {mentor?.calendly_username ? (
              <InlineWidget
                url={`https://calendly.com/${mentor?.calendly_username}`}
                // url={`https://calendly.com/`}
                pageSettings={{
                  backgroundColor: "ffffff",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "DC7215",
                }}
                prefill={{
                  email: userData?.email,
                  name: userData?.firstName + " " + userData?.lastName,
                  // smsReminderNumber: "",
                }}
              />
            ) : (
              <h1>Calendly username not found.</h1>
            )}
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default MentorProfile;
