import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import { useParams } from "react-router-dom";
import Editor from "../../LmsAdminDashboard/Editor/Editor";
import country_code from "../../Assets/Data/countries_code.json";
import { MdAdd, MdClear } from "react-icons/md";
import swal from "sweetalert";

const YourProfile = ({ profile }) => {
  const params = { id: localStorage.getItem("mentor_id") };
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    aoe: "",
    description: "",
    tags: [],
    avatar: "",
    country: "",
    calendly_username: "",
  });
  const [tempTag, setTempTag] = useState("");

  useEffect(() => {
    setData(profile);
  }, [profile]);

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }

  const predefinedTags = [
"Statement of Purpose Editing",
"SOP Creation from Scratch",
"Letter of Recommendation Review",
"Resume Review and Editing",
"Design Portfolio Review",
"Research Proposal Review",
"Personal Statement Review",
"Admission Essay Drafting",
"Admission Essay Customization",
"University Application Selection",
"Course Selection Guidance",
"Post-Admit University Finalization",
"University Focus Identification",
"Scholarship Application Assistance",
"Mock University Interview Sessions",
"Application Video Interview Preparation",
"Scholarship Essay Review",
"Pre Departure/ Cultural Adjustment Tips"
  ];

  const handleAddTag = () => {
    if (tempTag === '' || !predefinedTags.includes(tempTag)) {
      //console.log("Empty or invalid tag, not adding");
      return; // Prevent adding empty or invalid tag
    }

    setData((prev) => {
      if (prev.tags && prev.tags.length >= 3) {
        //console.log("Already have 3 tags, not adding");
        return prev; // Prevent adding new tag if there are already 3 or more tags
      }
      if (prev.tags.includes(tempTag)) {
        //console.log("Duplicate tag, not adding");
        return prev; // Prevent adding duplicate tag
      }
      const newTags = prev.tags ? [...prev.tags, tempTag] : [tempTag];
      //console.log("Adding tag:", tempTag);
      return {
        ...prev,
        tags: newTags,
      };
    });
    setTempTag('');
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    // console.log(hasDuplicates(data.tags), data.tags);
    if (data.tags.includes('') || data.tags.length === 0) {
      swal({
        title: "Error",
        text: "Empty or invalid tags not allowed",
        icon: "error",
      });
      setSubmitLoading(false);
      return;
    }
    if (hasDuplicates(data.tags)) {
      swal({
        title: "Error",
        text: "Duplicate tags not allowed",
        icon: "error",
      });
      setSubmitLoading(false);
      return;
    }

    try {
      let formData = new FormData();

      for (let value in data) {
        formData.append(value, data[value]);
      }
      const res = await API("put", `/mentor/${params.id}`, formData, {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      });
      const json = res?.data;

      swal({
        title: "Success",
        text: json?.message,
        icon: "success",
      }).then((res) => {
        //console.log(res);
        if (res) {
          window.location.reload();
        }
      });
    } catch (error) {
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="card" style={{ padding: "10px 20px" }}>
      <h2>
        <b>User Details {}</b>
      </h2>
      {data?.avatar ? (
        <img
          src={data?.avatar}
          alt="Avatar"
          style={{
            width: "140px",
            height: "140px",
            borderRadius: "500px",
            objectFit: "cover",
          }}
        />
      ) : (
        <div className="user-image-thumb">
          <i className="fa fa-user"></i>
        </div>
      )}
      <br />
      <div className="d-flex flex-column" style={{ gap: "0.7rem" }}>
        <div>
          <label>Profile Photo</label>
          <input
            className="form-control"
            type="file"
            onChange={(e) => {
              setData({ ...data, avatar: e.target.files[0] });
            }}
          />
        </div>

        <div>
          <label>Full Name</label>
          <input
            className="form-control"
            type="text"
            placeholder="Full Name"
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            value={data.name}
          />
        </div>

        <div>
          <label>Area of Expertise</label>
          <textarea
            className="form-control"
            type="text"
            placeholder="Descrive your area of expertise..."
            onChange={(e) => {
              setData({ ...data, aoe: e.target.value });
            }}
            value={data.aoe}
          />
        </div>

        <div>
          <label>Most Relevant Experience</label>
          <textarea
            className="form-control"
            type="text"
            placeholder="Descrive your relevant exp..."
            onChange={(e) => {
              setData({ ...data, relExp: e.target.value });
            }}
            value={data.relExp}
          />
        </div>

        <div>
          <label>Country</label>
          <select
            className="form-select"
            value={data.country}
            onChange={(e) => {
              setData({ ...data, country: e.target.value });
            }}
          >
            <option value="">Select Country</option>
            {country_code?.map((itm) => (
              <option id={itm?.name} value={itm?.name}>
                {itm?.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Profile Description</label>
          <Editor
            data={data}
            name={"description"}
            setData={setData}
            height={300}
          />
        </div>

        <div>
          <label>Offerings (max 3 can be added)</label>
          <div className="d-flex">
            {data?.tags?.map((itm, index) => (
              <span
                className="badge bg-primary me-2 mb-2"
                key={index}
                style={{ fontSize: "16px" }}
              >
                {itm}{" "}
                <MdClear
                  onClick={() =>
                    setData({
                      ...data,
                      tags: data.tags.filter((item) => item !== itm),
                    })
                  }
                />
              </span>
            ))}
          </div>
          <div className="d-flex">
            <select
              className="form-control"
              value={tempTag}
              onChange={(e) => setTempTag(e.target.value)}
            >
              <option value="">Select a Offering</option>
              {predefinedTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
            <MdAdd
              style={{
                fontSize: "24px",
                background: "#FBA33C",
                borderRadius: "500px",
                marginLeft: "10px"
              }}
              onClick={handleAddTag}
            />
          </div>
          {/* <br />
          <div className="d-flex align-items-center">
            <input
              className="form-control"
              type="text"
              placeholder="Enter tags"
              onChange={(e) => {
                setTempTag(e.target.value);
              }}
              value={tempTag}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setData((prev) => {
                    if (prev.tags) {
                      return {
                        ...prev,
                        tags: [...prev?.tags, tempTag],
                      };
                    }
                    return {
                      ...prev,
                      tags: [tempTag],
                    };
                  });
                  setTempTag("");
                }
              }}
            />{" "}
            &nbsp; &nbsp; &nbsp;
            <MdAdd
              style={{
                fontSize: "24px",
                background: "#FBA33C",
                borderRadius: "500px",
              }}
              onClick={() => {
                setData((prev) => {
                  if (prev.tags) {
                    return {
                      ...prev,
                      tags: [...prev?.tags, tempTag],
                    };
                  }
                  return {
                    ...prev,
                    tags: [tempTag],
                  };
                });
                setTempTag("");
              }}
            />
          </div> */}
        </div>
        <div className="d-flex">
        {!submitLoading ? (
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="btn btn-primary-regular mt-4"
          >
            Save
          </button>
        ) : (
          <button disabled className="btn btn-primary">
            Please wait...
          </button>
        )}
        </div>
      </div>
    </div>
  );
};

export default YourProfile;
