import swal from "sweetalert";
import Axios from "./axios";

const API = async (method, url, data, headers) => {
  return new Promise(function (resolve, reject) {
    Axios[method](url, data, {
      headers: headers,
    })
      .then((res) => {
        // if (!res.data.success) {
        //   swal({
        //     title: "Try Again!",
        //     text: res.data.message,
        //     icon: "error",
        //     button: "Ok!",
        //   });
        //   reject({
        //     data: res.data,
        //     other: res,
        //     success: false,
        //   });
        // }
        resolve({
          data: res?.data ?? res?.message,
          other: res,
          success: true,
        });
      })
      .catch((err) => {
        if (err.response?.data) {
          let message = err.response?.data?.message;
          let genericMsg = "Something went wrong!";
          if (typeof message != "object") {
            swal({
              title: "Try Again!",
              text: message,
              icon: "error",
              button: "Ok!",
            });
            if (err.response?.data?.redirect) {
              setTimeout(() => {
                window.location.href = err.response?.data?.redirect;
              }, 1000);
            }
          } else {
            for (let i = 0; i < err.response?.data?.message.length; i++) {
              swal({
                title: "Try Again!",
                text: err.response?.data?.message[i].msg,
                icon: "error",
                button: "Ok!",
              });

              break;
            }
          }
        }
        console.warn({
          data: err.response?.data,
          other: err,
          success: false,
        });
        reject({
          data: err.response?.data,
          other: err,
          success: false,
        });
      });
  });
};

export default API;
