import React, { useEffect, useState } from "react";
import { Navigator } from "./Navigator";
import API from "../../Api/Api";
import swal from "sweetalert";
const QuestionCategory = () => {
  const [categories, setCategories] = useState([]);
  const [edit, setEdit] = useState({});
  const [loading, setLoading] = useState({
    getAllCategory: false,
  });

  const getAllTests = async () => {
    setLoading({ ...loading, getAllCategory: true });
    try {
      const response = await API("get", `/test/admin/question-category/`);
      setCategories(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading({ ...loading, getAllCategory: false });
    }
  };

  useEffect(() => {
    getAllTests();
  }, []);

  const handleCategoryUpdate = async (id) => {
    try {
        await API('put',`/test/admin/question-category/${id}`, edit);
        swal({
            title:"Updated!"
        })
        getAllTests()
    } catch (error) {
        
    }
  };

  return (
    <>
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div className="body-container width-100">
        <Navigator />
          <div className="main-heading">Edit Questions Category</div>
      <div>
        {!loading?.getAllTest ? (
          <div
            className="d-flex justify-center items-center"
            style={{ gap: "2rem", marginTop: "1rem", flexWrap: "wrap" }}
          >
            {categories?.map((category) => (
              <div
                key={category?._id}
                style={{ background: "#fff", padding: "1rem", width: "400px" }}
              >
                <h1>{category?.name}</h1>
                <hr />
                {category?.category?.map((itm) => (
                  <div
                    key={itm?.module}
                    className="d-flex flex-column justify-content-between"
                  >
                    {" "}
                    {itm?.module}{" "}
                    <input
                      className="form-control"
                      placeholder={itm?.total}
                      onChange={(e) => {
                        if (edit?.name !== category?.name) {
                          setEdit({
                            name: category?.name,
                            category: [
                              ...category?.category.filter(
                                (item) => item?.module !== itm?.module
                              ),
                              {
                                module: itm?.module,
                                total: e.target.value,
                              },
                            ],
                          });
                        } else {
                          setEdit({
                            name: category?.name,
                            category: [
                              ...edit?.category.filter(
                                (item) => item?.module !== itm?.module
                              ),
                              {
                                module: itm?.module,
                                total: e.target.value,
                              },
                            ],
                          });
                        }
                      }}
                    />
                  </div>
                ))}
                <br />
                <button
                  disabled={edit?.name !== category?.name}
                  onClick={() => handleCategoryUpdate(category?._id)}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default QuestionCategory;
