import React from 'react'

const FooterModal = ({
    navigate,
    nextElement
}) => {
  return (
    <div className="modal-footer d-flex justify-content-between">
      <button
        onClick={() => {
          navigate('/userdashboard')
        }}
        className="widget-cta-page-nopos responsive-visible float-end mb-3"
      >
        <i className="fa fa-home"></i> Back to Dashboard
      </button>
      {nextElement}
    </div>
  )
}

export default FooterModal;
