import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import list from "../Assets/Data/countries.json";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import swal from "sweetalert";
import TestimonialSlider from "../Testimonial/TestimonialSlider";
import { url } from "../Constants";
import OAuth2Login from "react-simple-oauth2-login";
import countryCodes from "../Assets/Data/countries.json";

import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import API from "../../Api/Api";

import Select from "react-select";
import country_codes from "../Assets/Data/countries_code.json";

const Login = (props) => {
  const { linkedInLogin } = useLinkedIn({
    clientId: "863d7n1sff8fi9",
    r_emailaddress: "r_emailaddress,r_liteprofile",
    redirectUri: `${window.location.origin}/linkedin`,

    onSuccess: (code) => {
      //console.log(code);
    },
    onError: (error) => {
      //console.log(error);
    },
  });
  const [searchParams] = useSearchParams();
  const [credentials, setCredentials] = useState({
    fname: "",
    lname: "",
    email: localStorage.getItem("email") || props.email || "",
    password: localStorage.getItem("password") || "",
    phone: "",
    authcode: null,
    dest: "",
    countryCode: "",
  });

  let history = useNavigate();
  const [googleID, setGoogleID] = useState(0);
  const [signUpReq, setSignUpReq] = useState(false);
  const [errors, setErrors] = useState({});

  const [facebookId, setFacebookId] = useState(0);
  const [signUpFbReq, setSignUpFbReq] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const onChange = (event) => {
    if (event.target.name === "phone") {
      const phoneValue = event.target.value.substring(0, 10);
      setCredentials({ ...credentials, [event.target.name]: phoneValue });
    } else {
      setCredentials({
        ...credentials,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!rememberMe) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }

    if (validateForm()) {
      try {
        const response = await fetch(`${url}/auth/signin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            email: credentials.email,
            password: credentials.password,
          }),
          mode: "cors",
          referrerPolicy: "origin-when-cross-origin",
        });
        const json = await response.json();

        if (json.success == false) {
          swal({
            title: "Error",
            text: json?.message,
            icon: "error",
          });
        }

        if (json.success === true) {
          // credentials are ok, save remember me datails
          if (rememberMe) {
            await localStorage.setItem("email", credentials.email);
            await localStorage.setItem("password", credentials.password);
          }

          swal({
            title: "Welcome!",
            text: "Logged in Successfully",
            icon: "success",
            button: "Ok!",
          });
          await localStorage.setItem("token", json.authToken);
          if (
            searchParams.get("utm_source") == "mentor_profile_page" &&
            localStorage.getItem("mentor_nav")
          ) {
            history("/mentors/" + localStorage.getItem("mentor_nav"));
            window.location.reload();
            return;
          }
          if (searchParams.get("utm_source") == "public") {
            history("/" + localStorage.getItem("redirect_to").split("-")[1]);
            window.location.reload();
            return;
          }
          window.location.href = "/userdashboard";
          // window.location.reload()
        } else {
          // swal({
          //   title: "Try Again!",
          //   text: json.message,
          //   icon: "error",
          //   button: "Ok!",
          // });
        }
      } catch (err) {
        swal({
          title: "Try Again!",
          text: "server routing error!",
          icon: "error",
          button: "Ok!",
        });
      }
    }
  };

  const handleCallbackResponse = async (response) => {
    // getting the jwt token and setting userObject as it response
    // console.log("JWT ID TOKEN: ", response.credential);
    var userObject = await jwt_decode(response.credential);

    setCredentials({
      ...credentials,
      email: userObject.email,
      fname: userObject.given_name,
      lname: userObject.family_name,
    });
    setGoogleID(userObject.sub);

    const res = await fetch(`${url}/oauth/google/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        googleId: userObject.sub,
        email: userObject.email,
      }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await res.json();

    if (json.requireSignup === false && json.success === true) {
      await localStorage.setItem("token", json.authToken);
      if (
        searchParams.get("utm_source") == "mentor_profile_page" &&
        localStorage.getItem("mentor_nav")
      ) {
        history("/mentors/" + localStorage.getItem("mentor_nav"));
        window.location.reload();
        return;
      }
      if (searchParams.get("utm_source") == "public") {
        history("/" + localStorage.getItem("redirect_to").split("-")[1]);
        window.location.reload();
        return;
      }
      window.location.reload();
    } else if (json.requireSignup === false && json.success === false) {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok!",
      });
      history("/login");
    } else {
      setSignUpReq(true);
    }
  };

  const handleFacebookResponse = async (response) => {
    const accessToken = response.access_token;
    const result = await fetch(
      `https://graph.facebook.com/v12.0/me?fields=id,name,email,picture.type(large)&access_token=${accessToken}`
    );
    const profile = await result.json();

    const fullName = profile.name;
    const [firstName, lastName] = fullName.split(" ");

    setCredentials({
      ...credentials,
      fname: firstName,
      lname: lastName,
      email: profile.email,
    });
    setFacebookId(profile.id);

    const res = await fetch(`${url}/oauth/facebook/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ facebookId: profile.id, email: profile.email }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await res.json();
    // console.log(json);

    if (json.requireSignup === false && json.success === true) {
      swal({
        title: "Welcome!",
        text: "Logged in Successfully",
        icon: "success",
        button: "Ok!",
      });
      await localStorage.setItem("token", json.authToken);
      if (
        searchParams.get("utm_source") == "mentor_profile_page" &&
        localStorage.getItem("mentor_nav")
      ) {
        history("/mentors/" + localStorage.getItem("mentor_nav"));
      }
      if (searchParams.get("utm_source") == "public") {
        history("/" + localStorage.getItem("redirect_to").split("-")[1]);
        window.location.reload();
        return;
      }
      window.location.reload();
    } else if (json.requireSignup === false && json.success === false) {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok!",
      });
      history("/login");
      window.location.reload();
    } else {
      swal({
        title: "Verified!",
        text: "Continue to Register",
        icon: "success",
        button: "Continue!",
      });
      setSignUpFbReq(true);
    }
  };

  const handleGoogleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await API("post", `${url}/oauth/google/signup`, {
        fname: credentials.fname,
        lname: credentials.lname,
        phone: credentials.phone,
        dest: credentials.dest,
        email: credentials.email,
        googleId: googleID,
        countryCode: credentials.countryCode.value,
      });
      const json = response.data;
      // console.log(json)

      if (json.success === true) {
        swal({
          title: "Welcome!",
          text: "Logged in Successfully",
          icon: "success",
          button: "Ok!",
        });
        await localStorage.setItem("token", json.authToken);
        if (
          searchParams.get("utm_source") == "mentor_profile_page" &&
          localStorage.getItem("mentor_nav")
        ) {
          history("/mentors/" + localStorage.getItem("mentor_nav"));
          window.location.reload();
          return;
        }
        if (searchParams.get("utm_source") == "public") {
          history("/" + localStorage.getItem("redirect_to").split("-")[1]);
          window.location.reload();
          return;
        }
        window.location.reload();
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) {
    }
  };

  //for Facebook submit
  const handleFacebookSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}/oauth/facebook/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname: credentials.fname,
          lname: credentials.lname,
          phone: credentials.phone,
          dest: credentials.dest,
          email: credentials.email,
          facebookId: facebookId,
          countryCode: credentials.countryCode,
        }),
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
      });
      const json = await response.json();

      if (json.success === true) {
        swal({
          title: "Success!",
          text: "Account Created Successfully",
          icon: "success",
          button: "Ok!",
        });
        await localStorage.setItem("token", json.authToken);
        window.location.href = "/userdashboard"
        // history("/userdashboard");
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) {
      swal({
        title: "Try Again!",
        text: "server routing error!",
        icon: "error",
        button: "Ok!",
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.href = "/userdashboard"
      // history("/userdashboard");
    }

    /* global google */
    const initGAuth = async () => {
      await google.accounts.id.initialize({
        client_id:
          "208870428930-ad67u1ss3cvahprbugg5bi0j848kl3ss.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      await google.accounts.id.renderButton(
        document.getElementById("googlebtn"),
        {
          theme: "outline",
          size: "large",
          longtitle: true,
        }
      );
    };

    initGAuth();
  }, []);

  //for password to show

  useEffect(() => {
    const ShowPasswordToggle = document.querySelector("[type='password']");
    const togglePasswordButton = document.getElementById("toggle-password");
    const passwordInput = document.querySelector("[type='password']");

    ShowPasswordToggle.onclick = function () {
      document
        .getElementById("exampleInputPassword1")
        .classList.add("input-password");
      document.getElementById("toggle-password").classList.remove("d-none");
      const passwordInput = document.querySelector("[type='password']");
      const togglePasswordButton = document.getElementById("toggle-password");
      togglePasswordButton.addEventListener("click", togglePassword);
    };

    const togglePassword = () => {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        togglePasswordButton.setAttribute("aria-label", "Hide password.");
      } else {
        passwordInput.type = "password";
        togglePasswordButton.setAttribute(
          "aria-label",
          "Show password as plain text. " +
            "Warning: this will display your password on the screen."
        );
      }
    };
  }, []);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!credentials.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    if (!credentials.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (credentials.password?.length < 8) {
      errors.password = "Password must be at least 8 characters";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <div className="container-fluid d-flex px-0">
      <section className="left-panel">
        <TestimonialSlider />
      </section>
      <section className="right-panel">
        <div className="text-center">
          <div className="logo">
            <img
              src={require("../Assets/Media/Images/logo.png")}
              className="img-fluid logo-md"
              alt="Rise Higher Education"
              title="Rise Higher Education"
            />
          </div>
          <div className="tag-line">Empower Your Education Dreams</div>
        </div>
        <div className="main-heading">Welcome Back</div>
        <div className="regular-text">Please enter your details to login</div>
        <div className="sep" />
        <div className="page-form">
          {signUpReq === true ? (
            <form onSubmit={handleGoogleSubmit}>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <Select
                      className="app-multiSelect"
                      value={credentials.countryCode}
                      options={country_codes.map((op, i) => ({
                        value: op.dial_code,
                        label: op.dial_code + " " + op.name,
                      }))}
                      onChange={(selectedOptions) => {
                        //console.log('Select', selectedOptions)
                        setCredentials((prevData) => ({
                          ...prevData,
                          countryCode: selectedOptions,
                        }));
                      }}
                      placeholder="Country Code"
                      // isOptionDisabled={() => credentials?.dest?.length >= 3}
                    />
                    {/* <select
                      list="countryCodeData"
                      value={credentials.countryCode}
                      onChange={onChange}
                      placeholder="choose.."
                      className="form-select"
                      name="countryCode"
                    >
                      <option value={""}>Please select</option>
                      {country_codes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </select> */}
                    {errors.countryCode && (
                      <span className="required">
                        {errors.countryCode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputState" className="form-label">
                  Study Destination<span className="required">*</span>
                </label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={list.dest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={countryCodes.map((op, i) => ({
                    value: op.name,
                    label: op.name,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setCredentials((prevData) => ({
                      ...prevData,
                      dest: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                  isOptionDisabled={() => credentials?.dest?.length >= 3}
                />
                
              </div>
              <div className="form-group">
                <div className="pt-3" />
                <div className="form-button">
                  <button
                    disabled={credentials.phone?.length < 10}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Create Account
                  </button>
                </div>
              </div>
            </form>
          ) : signUpFbReq === true ? (
            <form onSubmit={handleFacebookSubmit}>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <input
                      list="countryCodeData"
                      value={credentials.countryCode}
                      onChange={onChange}
                      placeholder="choose.."
                      className="form-select"
                      name="countryCode"
                    />
                    <datalist id="countryCodeData">
                      {countryCodes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="form-group">
                <label htmlFor="">
                  Email address<span className="required">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={credentials.email}
                  onChange={onChange}
                  name="email"
                />
                {errors.email && (
                  <span className="required">
                    {errors.email}
                  </span>
                )}
              </div> */}
              <div className="form-group">
                <label htmlFor="inputState" className="form-label">
                  Study Destination<span className="required">*</span>
                </label>
                <input
                  list="data"
                  value={credentials.dest}
                  onChange={onChange}
                  placeholder="choose.."
                  className="form-select"
                  name="dest"
                />
                {errors.dest && (
                  <span className="required">
                    {errors.dest}
                  </span>
                )}

                <datalist id="data">
                  {list.map((op, i) => (
                    <option>{op.name}</option>
                  ))}
                </datalist>
              </div>
              <div className="form-group">
                <div className="pt-3" />
                <div className="form-button">
                  <button type="submit" className="btn btn-primary">
                    Create Account
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <>
              {" "}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    id=""
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={credentials.email}
                    onChange={onChange}
                    name="email"
                  />
                  {errors.email && (
                    <span className="required">
                      {errors.email}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    Password<span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={onChange}
                    name="password"
                  />
                  {errors.password && (
                    <span className="required">
                      {errors.password}
                    </span>
                  )}
                  <button
                    id="toggle-password"
                    type="button"
                    className="d-none"
                    aria-label="Show password as plain text. Warning: this will display your password on the screen."
                  />
                </div>
                <div className="form-settings d-flex justify-content-between">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      onClick={() => {
                        setRememberMe(!rememberMe);
                      }}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Remember Me
                    </label>
                  </div>
                  <div>
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>
                <div className="pt-3" />
                <div className="form-button">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      credentials.email.length < 5 ||
                      credentials.password.length < 7
                    }
                  >
                    Login
                  </button>
                </div>
              </form>
              <div className="small-text pt-3 pb-3 text-center">
                Or continue with
              </div>
              <div className="social-buttons d-flex justify-content-between pb-3">
                <a href="/" id="googlebtn" className="social-icon">
                  <i className="fa-brands fa-google fa-lg" />
                </a>
                {/* <img
                  onClick={linkedInLogin}
                  src={linkedin}
                  alt="Sign in with Linked In"
                  style={{ maxWidth: "180px", cursor: "pointer" }}
                /> */}
                {/* <OAuth2Login
                  className="social-icon"
                  authorizationUrl="https://www.facebook.com/v12.0/dialog/oauth"
                  responseType="token"
                  clientId="668676731386090"
                  redirectUri="https://risehigher.education/"
                  scope="public_profile"
                  onSuccess={handleFacebookResponse}
                >
                  <i className="fa-brands fa-facebook fa-lg" />
                </OAuth2Login> */}
              </div>
            </>
          )}
          <div className="regular-text text-center">
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
