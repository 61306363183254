import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { url } from "../../Constants";


export function WorldChart() {
  const [data, setData] = useState([["Country", "Scholarships"]]);
  const authToken = localStorage.getItem("token");

  const getCountryCount = async () => {
    const req = `${url}/scholarship/count`;
    const response = await fetch(req, {
      method: "GET",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: authToken,
      },
    });
    let arr = [];
    const responseData = await response.json();
    responseData.data.forEach((item) => {
      arr.push([item.country === 'United States of America' ? 'US' : item.country, item.count]);
    });
    setData([...data, ...arr]);
  };

  useEffect(() => {
    getCountryCount();
  }, []);

  return (
    <Chart
      chartType="GeoChart"
      width="100%"
      height="150px"
      data={data}
      mapsApiKey="AIzaSyDni1Ligw8TaotEqIWFa1BH-NgT-Eq0-w0"
      options={{
        colorAxis: {
          colors: [
            "#fdf2e8",
            "#f8d7b9",
            "#f4bc8b",
            "#efa15c",
            "#eb862e",
            "#d16d14",
          ],
        },
        legend: "none"
      }}
    />
  );
}
