import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import swal from "sweetalert";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkout";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Courses = () => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [paymentScreen, setPaymentScreen] = useState(false);
  const [currentPayableCourse, setCurrentPayableCourse] = useState({});
  const [loading, setLoading] = useState({
    enrolled: false,
    all: false,
    enrolling: false,
  });
  const [orderData, setOrderData] = useState("");
  const navigate = useNavigate();

  const getEnrolledCourses = async () => {
    setLoading({ ...loading, enrolled: true });
    try {
      const data = await API(
        "get",
        `/lms/student/view/progress/${localStorage.getItem("_id")}`
      );
      // console.log(data?.data);
      setEnrolledCourses(data?.data?.data);
    } catch (error) {
    } finally {
      setLoading({ ...loading, enrolled: false });
    }
  };

  const getAllCourses = async () => {
    setLoading({ ...loading, all: true });
    try {
      const data = await API("get", `/lms/student/view/courses`);
      setAllCourses(data?.data?.data);
    } catch (error) {
    } finally {
      setLoading({ ...loading, all: false });
    }
  };

  const enrollInCourse = async (courseid) => {
    setLoading({ ...loading, enrolling: true });
    try {
      const data = await API("post", `/lms/student/enroll-in-course`, {
        studentid: localStorage.getItem("_id"),
        courseid,
      });
      setAllCourses(data?.data?.data);
      swal({
        title: "Success!",
        text: data?.data?.message,
        icon: "success",
        button: "Ok!",
      }).then((res) => window.location.reload());
    } catch (error) {
    } finally {
      setLoading({ ...loading, enrolling: false });
    }
  };

  useEffect(() => {
    getAllCourses();
    getEnrolledCourses();
  }, []);

  const createCourseOrder = async (itm) => {
    try {
      const data = await API("post", "/lms/student/purchase-course", {
        courseid: itm?._id,
      });
      setOrderData(data?.data?.clientSecret);
      setPaymentScreen(true);
    } catch (error) {}
  };

  if (paymentScreen) {
    return (
      <div style={{ margin: "7rem 6rem", width: "50%" }}>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: orderData,
          }}
        >
          {" "}
          <CheckoutForm itm={currentPayableCourse} />
        </Elements>
      </div>
    );
  }

  return (
    <div className="container" style={{ margin: "7rem 6rem", width: "100%" }}>
      <div>
        <h1>All Enrolled Courses</h1>
        <div className="d-flex" style={{ gap: "1rem", marginTop: "1rem" }}>
          {!loading.enrolled ? (
            enrolledCourses?.length > 0 ? (
              enrolledCourses?.map((itm) => {
                return (
                  <div
                    key={itm?.course?._id}
                    style={{
                      background: "#fff",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "400px",
                    }}
                  >
                    <img
                      width={"380"}
                      style={{ borderRadius: "10px" }}
                      src={itm?.course?.thumbnail}
                      alt="course-thumbnail"
                    />
                    <br />
                    <br />
                    <h1>{itm?.course?.name}</h1>
                    <hr />
                    <p>{itm?.course?.description}</p>
                    <button
                      className="btn btn-primary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        navigate(`/course/${itm?.course._id}`);
                        localStorage.setItem(
                          "progreess",
                          JSON.stringify(
                            itm?.progress?.map((itm) => itm.lessonid)
                          )
                        );
                      }}
                    >
                      View
                    </button>
                  </div>
                );
              })
            ) : (
              <h2>Not Enrolled in Coursee</h2>
            )
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <br />
      <br />
      <div>
        <h1>All Available Courses</h1>
        <div
          className="d-flex flex-wrap"
          style={{ gap: "1rem", marginTop: "1rem" }}
        >
          {!loading.all ? (
            allCourses?.length > 0 ? (
              allCourses?.map((itm) => {
                return (
                  <div
                    key={itm?._id}
                    style={{
                      background: "#fff",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "400px",
                    }}
                  >
                    <img
                      width={"380"}
                      style={{ borderRadius: "10px" }}
                      src={itm?.thumbnail}
                      alt="course-thumbnail"
                    />
                    <br />
                    <br />
                    <h1>{itm.name}</h1>
                    <hr />
                    <p>{itm.description}</p>

                    <div className="d-flex align-items-center">
                      {itm?.ispaid ? (
                        <p style={{ margin: 0 }}>
                          <b>Price:</b> ${itm?.amount}
                        </p>
                      ) : (
                        <p style={{ margin: 0 }}>
                          <b>Price:</b> Free
                        </p>
                      )}
                    </div>
                    <br />
                    {!itm?.ispaid ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          let course = enrolledCourses.find(
                            (item) => item?._id === itm?._id
                          );
                          if (course){
                            return swal({
                              title: "Already Enrolled!",
                              text: "You have already enrolled in this course!",
                              icon: "warning",
                              button: "Ok!",
                            })
                          }
                          // if ()
                          if (!itm?.ispaid) {
                            enrollInCourse(itm?._id);
                          }
                        }}
                      >
                        Enroll for free
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          createCourseOrder(itm);
                          setCurrentPayableCourse(itm);
                        }}
                      >
                        Buy Now
                      </button>
                    )}
                  </div>
                );
              })
            ) : (
              <h2>No Courses found!</h2>
            )
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
