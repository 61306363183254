import React, { useEffect, useState } from 'react'

const EmergencyContact = (props) => {

    const [data, setData] = useState({
        name: "",
        phone: "",
        email: "",
        address: "",
        relationship: "",
        gName: "",
        gPhone: "",
        gEmail: "",
        gAddress: "",
        gRelationship: "",
    })

    const onChange = (e) => {
        const target = e.target.name.split(".");

        if (target.length === 2) {
            setData({
                ...data,
                [target[0]]: {
                    ...data[target[0]],
                    [target[1]]: e.target.value
                }
            })
        }
        else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setData(props.data)
        }
    }, [props.data])

    return (
        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel"
            aria-labelledby="v-pills-settings-tab">
            <div className="row profile-section">
                <div className="col">
                    <div className="section-head">Emergency Contact Information</div>
                    <div className="form-group section-content">
                        <div className="row mt-3 mb-3">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="name" value={data.name} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Name"
                                        aria-label="Name" />
                                    <label htmlFor="">Name</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="phone" value={data.phone} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Phone"
                                        aria-label="Phone" />
                                    <label htmlFor="">Phone</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="email" value={data.email} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Email"
                                        aria-label="Email" />
                                    <label htmlFor="">Email</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="address" value={data.address} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Address"
                                        aria-label="" />
                                    <label htmlFor="">Address</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="relationship" value={data.relationship} onChange={onChange} className="form-control"
                                        defaultValue=""
                                        placeholder="Relationship to Applicant"
                                        aria-label="" />
                                    <label htmlFor="">Relationship to Applicant</label>
                                </div>
                            </div>
                        </div>
                        <p>
                            Guardian Information (mandatory for student below 18 years)
                        </p>
                        <div className="row mt-3 mb-3">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="gName" value={data.gName} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Name"
                                        aria-label="" />
                                    <label htmlFor="">Name</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="gPhone" value={data.gPhone} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Phone"
                                        aria-label="" />
                                    <label htmlFor="">Phone</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="gEmail" value={data.gEmail} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Email"
                                        aria-label="" />
                                    <label htmlFor="">Email</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="gAddress" value={data.gAddress} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Address"
                                        aria-label="" />
                                    <label htmlFor="">Address</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="gRelationship" value={data.gRelationship} onChange={onChange} className="form-control"
                                        defaultValue=""
                                        placeholder="Relationship to Applicant"
                                        aria-label="" />
                                    <label htmlFor="">Relationship to Applicant</label>
                                </div>
                            </div>
                        </div>
                        <div className="row responsive-col">
                            <div className="col text-end action-btn-group">
                                <button type="submit" className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary-regular" onClick={() => { props.updateData("emergencyContact", data) }}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmergencyContact
