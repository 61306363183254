import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NavBarAdmin = ({
  handleShowAllData,
  getUserDetails,
  handleShowScholarship,
  fetchTestimonialsPage,
  showAllData,
  searchUser,
  setSearchUser,
  showTestimonials,
  testimonialSearch,
  setTestmonialSearch,
  showScholarship,
  scholarshipSearch,
  setScholarshipSearch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.substring(1);

  const pathStyle = (url) => {
    if (path == url) {
      return {
        background: path == url && "#534173",
        border: path == url && "#534173",
      };
    }
    return {};
  };

  return (
    <>
      <div className="row actionBox">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div>
              <button
                onClick={() => {
                  // handleShowAllData();
                  // getUserDetails();
                  // setSearchUser(true)
                  navigate("/dashboard");
                }}
                type="button"
                className="btn btn-primary-regular"
                style={pathStyle("dashboard")}
              >
                Users
              </button>
              <button
                onClick={() => {
                  navigate("/dashboard-scholarship");
                }}
                type="button"
                className="btn btn-primary-regular"
                style={pathStyle("dashboard-scholarship")}
              >
                Scholarships
              </button>
              {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-testimonials");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-testimonials")}
                >
                  Testimonials
                </button>
              )}
              {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-affiliate-users");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-affiliate-users")}
                >
                  Affiliate Users
                </button>
              )}
              {/* {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-signature");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-signature")}
                >
                  Signature
                </button>
              )} */}
              {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-mass-email");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-mass-email")}
                >
                  Marketing Email
                </button>
              )}
               {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-lead-form");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-lead-form")}
                >
                  Leads
                </button>
              )}
              {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-profile-form");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-profile-form")}
                >
                  Basic Profile Leads
                </button>
              )}
              {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-referral-form");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-referral-form")}
                >
                  Referral Leads
                </button>
              )}
              {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/mentor-dashboard-admin");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("mentor-dashboard-admin")}
                >
                  Mentors
                </button>
              )}
                {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/lms-dashboard-tests");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("lms-dashboard-tests")}
                >
                  Coaching
                </button>
              )}
              {/* {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/whatsapp");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("lms-dashboard-tests")}
                >
                  WhatsApp
                </button>
              )} */}
              {/* {localStorage?.getItem("role") === "Admin" && (
                <button
                  onClick={() => {
                    navigate("/dashboard-from-email");
                  }}
                  type="button"
                  className="btn btn-primary-regular"
                  style={pathStyle("dashboard-from-email")}
                >
                  From email
                </button>
              )} */}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <div>
              {path == "dashboard" ? (
                <input
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)}
                  className="form-control"
                  placeholder="Search Users"
                  type="text"
                />
              ) : path == "dashboard-testimonials" ? (
                <input
                  value={testimonialSearch}
                  onChange={(e) => setTestmonialSearch(e.target.value)}
                  className="form-control"
                  placeholder="Search Testimonials"
                  type="text"
                />
              ) : path == "dashboard-scholarship" ? (
                <input
                  value={scholarshipSearch}
                  onChange={(e) => setScholarshipSearch(e.target.value)}
                  className="form-control"
                  placeholder="Search Scholarships"
                  type="text"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row float-end">
        <div className="col">
          {path == "dashboard" ? (
            <Link to={"adduser"}>
              <button className="btn btn-primary-regular">Add User</button>
            </Link>
          ) : path == "dashboard-testimonials" ? (
            <Link to={"/dashboard/addtestimonial"}>
              <button className="btn btn-primary-regular">
                Add Testimonial
              </button>
            </Link>
          ) : path == "dashboard-scholarship" ? (
            <Link to={"/dashboard/addscholarship"}>
              <button className="btn btn-primary-regular">
                Add Scholarship
              </button>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default NavBarAdmin;
