import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import API from "../../../Api/Api";
import { useNavigate } from "react-router-dom";

const PaymentCheckCredit = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const refill = async () => {
    try {
      await API("post", `/mentor/user/refill-credits/success`, {
        studentid: localStorage.getItem("_id"),
        planid: searchParams.get("planid"),
        paymentid: searchParams.get("payment_intent"),
      });
      if (localStorage.getItem("mentor_nav")) {
        navigate(`/mentors/${localStorage.getItem("mentor_nav")}?cal=ok`);
        localStorage.removeItem("mentor_nav");
      } else {
        navigate("/mentors");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      searchParams.get("payment_intent") &&
      searchParams.get("redirect_status") == "succeeded"
    ) {
      refill();
    }
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100vw", marginTop: "7rem" }}
    >
      <h1>Please wait, redirecting ...</h1>
    </div>
  );
};

export default PaymentCheckCredit;
