import React, { useEffect } from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'

const ButtonCustom = ({ onClick, text }) => {
  return (
    <button
      onClick={() => onClick()}
      className="widget-cta-page-nopos responsive-visible float-end mb-3 btn-secondary"
    >
      {text}
    </button>
  )
}

const CountryMain = ({ modals, setModals, setShow, setCountry }) => {
  const navigate = useNavigate()
  useEffect(() => {
    setCountry(true);
  },[])
  return (
    <>
      <div className="modal-body">
        <p className="modal-subheading">
          Start by discovering your dream study spot - where preferences meet
          possibilities
        </p>
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ gap: '1rem' }}
        >
          <ButtonCustom
            text={<>Destination Discovery</>}
            onClick={() => {
              setModals({
                countryMain: false,
                needHelpCountry: true,
                courseMain: false,
                needHelpCourrse: false,
              })
              setShow(false)
            }}
          />
          <ButtonCustom
            text={<>Priority Ranking </>}
            onClick={() => {
              setModals({
                countryMain: false,
                needHelpCountry: false,
                courseMain: false,
                needHelpCourrse: false,
                countryScore:true
              })
              setShow(false)
            }}
          />
          <ButtonCustom
            text={<>Preset Destination </>}
            onClick={() => {
              setModals({
                countryMain: false,
                needHelpCountry: false,
                courseMain: false,
                needHelpCourrse: false,
              })
              setShow(true)
              // setCountry(true)
            }}
          />
        </div>
      </div>
      <FooterModal navigate={navigate} />
    </>
  )
}

export default CountryMain
