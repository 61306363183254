import React, { useEffect } from "react";
import country from "../Assets/Data/countries.json";
import { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { url } from "../Constants";

const TabCountry = ({
  img,
  count,
  getData,
  name,
  loading,
  selected,
  setSelected,
  setFilter,
}) => {
  return (
    <div
      className="row"
      style={{ cursor: "pointer" }}
      onClick={() => {
        getData((prevCountry) => {
          return {
            ...prevCountry,
            Destination_Country: [name],
          };
        });
        setFilter((prevCountry) => {
          return {
            ...prevCountry,
            Destination_Country: [name],
          };
        });
        setSelected(name);
      }}
    >
      <div className="col">
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={<Tooltip id="tooltip">{name}</Tooltip>}
        >
          <div className="scholarship-card">
            <img
              src={img}
              alt="country-flag"
              style={{
                height: "21px",
                width: "30px",
              }}
            />

            <button className="btn-nostyle scholarship-card-count">
              {loading && selected === name ? (
                <i className="fa fa-spinner fa-pulse"></i>
              ) : (
                <div
                  style={{
                    color: JSON.parse(
                      localStorage.getItem("UserFilter")
                    ).Destination_Country.includes(name)
                      ? "#DC7215"
                      : "#000",
                  }}
                >
                  {name} <br /> ({count}) <i className="fa fa-filter"></i>
                </div>
              )}
            </button>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

const CountryFilter = ({ onClick, loading, countryCount, setFilter }) => {
  const [selected, setSelected] = useState("");

  const getCountryCount = () => {
    let newArr = [];
    country?.map((item) => {
      countryCount.map((country) => {
        if (item?.name == country?.country) {
          newArr.push({
            name: item?.name,
            CountryFlag: item?.CountryFlag,
            newScholarshipCount: country?.count,
          });
        }
      });
    });
    return newArr;
  };

  return (
    <div className="d-flex flex-wrap">
      {getCountryCount()?.map((item, i) => {
        return (
          <TabCountry
            img={item?.CountryFlag}
            name={item?.name}
            count={item?.newScholarshipCount}
            getData={onClick}
            loading={loading}
            selected={selected}
            setSelected={setSelected}
            setFilter={setFilter}
          />
        );
      })}
    </div>
  );
};

export default CountryFilter;
