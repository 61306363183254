import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import "../../Assets/Styles/test.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ReactPlayer from "react-player";
import { ReactMic } from "react-mic";
import VoiceTranscript from "../../Assessment/Componets/VoiceTranscript";

export const Quiz = ({ questions }) => {
  const [testData, setTestData] = useState({});
  const [responseArr, setResponseArr] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const navigate = useNavigate();
  //   const [totaTimeSeconds, setTotalTimeSeconds] = useState(0);
  const [explanation, setExplanation] = useState(false);
  const getTestData = async () => {
    setTestData(questions);
  };

  useEffect(() => {
    getTestData();
  }, []);

  const handleSubmit = async () => {
    let finalResponse = [];
    responseArr.map((itm) => {
      finalResponse.push({ id: itm.id, answer: itm.answer });
    });
    testData?.questions.map((itm) => {
      if (!responseArr.find((item) => item?.id == itm?._id)) {
        finalResponse.push({ id: itm._id, answer: itm?.answer ?? "" });
      }
    });
    setCurrentQuestion(0);
    setResponseArr(finalResponse);
    setExplanation(true);
    swal({
      title: "Test Submitted!",
      text: "Congrats test submitted successfully!",
      icon: "success",
    });
    try {
      //console.log(finalResponse);
    } catch (error) {}
  };

  const hanldeChange = (type, data, checked) => {
    if (type == "mcq") {
      if (responseArr?.find((itm) => itm.id == data.id)) {
        const newData = responseArr?.filter((itm) => itm.id != data.id);
        setResponseArr([...newData, data]);
        return;
      }
      setResponseArr([...responseArr, data]);
    }
    if (type == "text") {
      if (responseArr?.find((itm) => itm.id == data.id)) {
        const newData = responseArr?.filter((itm) => itm.id != data.id);
        setResponseArr([...newData, data]);
        return;
      }
      setResponseArr([...responseArr, data]);
    }
    if (type == "mmcq") {
      let previosMMCQ = responseArr?.find((itm) => itm.id == data.id);
      if (previosMMCQ) {
        if (checked) {
          const newData = responseArr?.filter((itm) => itm.id != data.id);
          setResponseArr([
            ...newData,
            { ...data, answer: previosMMCQ.answer + "," + data?.answer },
          ]);
          return;
        } else {
          const newData = responseArr?.filter((itm) => itm.id != data.id);
          setResponseArr([
            ...newData,
            {
              ...data,
              answer: previosMMCQ.answer
                .split(",")
                ?.filter((itm) => itm != data?.answer)
                .join(","),
            },
          ]);
          return;
        }
      }
      setResponseArr([...responseArr, data]);
    }
    if (type == "subquestions") {
      let index = checked;
      if (responseArr?.find((itm) => itm.id == data.id)) {
        let str_answer = data?.answer;
        let element = responseArr?.filter((itm) => {
          if (itm.id == data.id) {
            return itm;
          }
        });
        let answer_arr = element[0]?.answer.split(",");
        answer_arr[index] = str_answer;
        // const newData = responseArr?.filter((itm) => itm.id != data.id);
        const newData = responseArr?.filter((itm) => itm.id != data.id);
        setResponseArr([
          ...newData,
          { id: data?.id, answer: answer_arr.join(",") },
        ]);
        return;
      }
      let answerArr = Array(
        testData?.questions[currentQuestion]?.options?.length
      ).fill();
      answerArr[index] = data?.answer;
      setResponseArr([
        ...responseArr,
        { id: data?.id, answer: answerArr.join(",") },
      ]);
    }
  };

  // useEffect(() => {
  //   console.log(responseArr);
  // }, [responseArr]);

  if (testData?.questions?.length > 0)
    return (
      <div
        className="main-container"
        style={{ height: "50vh", borderRadius: "20px" }}
      >
        <div className="container">
          <h1 className="text-center">{testData?.name}</h1>
          {/* <div
            className="test-description"
            dangerouslySetInnerHTML={{ __html: testData?.description }}
          ></div> */}
        </div>
        <br />
        {/* <div>
          <h2>{testData?.questions[currentQuestion]?.type}</h2>
        </div> */}
        <div>
          <h2>{testData?.questions[currentQuestion]?.category}</h2>
        </div>

        <div className="container conduct-test-container">
          <div className="d-flex justify-content-between">
            <div
              className="d-flex"
              dangerouslySetInnerHTML={{
                __html:
                  `Q${currentQuestion + 1}. ` +
                  testData?.questions[currentQuestion]?.title,
              }}
            ></div>
            {/*  */}
          </div>
          <br />
          {!explanation ? (
            <div className="d-flex justify-content-between">
              <div className="q-options">
                {testData?.questions[currentQuestion]?.type == "mcq" && (
                  <div className="form-check d-flex flex-column">
                    {testData?.questions[currentQuestion]?.options?.map(
                      (option, index) => {
                        return (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              name={testData?.questions[currentQuestion].title}
                              type="radio"
                              checked={responseArr?.find(
                                (itm) =>
                                  itm?.answer == option &&
                                  itm?.id ==
                                    testData?.questions[currentQuestion]?._id
                              )}
                              onClick={(e) => {
                                hanldeChange("mcq", {
                                  id: testData?.questions[currentQuestion]?._id,
                                  answer: option,
                                });
                              }}
                            />{" "}
                            &nbsp;
                            <p style={{ margin: 0 }}>{option}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {testData?.questions[currentQuestion]?.type == "mmcq" && (
                  <div className="d-flex flex-column">
                    {testData?.questions[currentQuestion]?.options?.map(
                      (option, index) => {
                        return (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <input
                              type="checkbox"
                              checked={responseArr
                                ?.filter(
                                  (itm) =>
                                    itm?.id ==
                                    testData?.questions[currentQuestion]?._id
                                )[0]
                                ?.answer?.split(",")
                                .find((itm) => itm == option)}
                              onChange={(e) => {
                                hanldeChange(
                                  "mmcq",
                                  {
                                    id: testData?.questions[currentQuestion]
                                      ?._id,
                                    answer: option,
                                  },
                                  e.target.checked
                                );
                                // console.log(e.target.checked, option);
                              }}
                            />{" "}
                            &nbsp;
                            <p style={{ margin: 0 }}>{option}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {testData?.questions[currentQuestion]?.type ==
                  "subquestions" && (
                  <div className="d-flex flex-column">
                    {testData?.questions[currentQuestion]?.options?.map(
                      (option, index) => {
                        return (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            {/* <input
                            type="checkbox"
                            checked={responseArr
                              ?.filter(
                                (itm) =>
                                  itm?.id ==
                                  testData?.questions[currentQuestion]?._id
                              )[0]
                              ?.answer?.split(",")
                              .find((itm) => itm == option)}
                            onChange={(e) => {
                              hanldeChange(
                                "mmcq",
                                {
                                  id: testData?.questions[currentQuestion]?._id,
                                  answer: option,
                                },
                                e.target.checked
                              );
                              // console.log(e.target.checked, option);
                            }}
                          />{" "} */}
                            &nbsp;
                            <div>
                              <p
                                style={{ margin: 0 }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    `Q${currentQuestion + 1}.${index + 1}) ` +
                                    option,
                                }}
                              ></p>
                              {testData?.questions[
                                currentQuestion
                              ]?.subquestionsoptions[index]
                                ?.split(",")
                                .map((itm) => {
                                  return (
                                    <div className="d-flex">
                                      {" "}
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        checked={
                                          responseArr
                                            ?.find(
                                              (itm) =>
                                                itm.id ==
                                                testData?.questions[
                                                  currentQuestion
                                                ]?._id
                                            )
                                            ?.answer?.includes(itm)
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          hanldeChange(
                                            "subquestions",
                                            {
                                              id: testData?.questions[
                                                currentQuestion
                                              ]?._id,
                                              answer: itm,
                                            },
                                            index
                                          );
                                        }}
                                      />
                                      &nbsp; <p style={{ margin: 0 }}>{itm}</p>
                                    </div>
                                  );
                                })}
                              <br />
                              <input
                                className="form-control"
                                placeholder="Answer"
                                value={
                                  responseArr
                                    ?.filter(
                                      (itm) =>
                                        itm?.id ==
                                        testData?.questions[currentQuestion]
                                          ?._id
                                    )[0]
                                    ?.answer?.split(",")[index]
                                }
                                onChange={(e) =>
                                  hanldeChange(
                                    "subquestions",
                                    {
                                      id: testData?.questions[currentQuestion]
                                        ?._id,
                                      answer: e.target.value,
                                    },
                                    index
                                  )
                                }
                              />
                              <br />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {testData?.questions[currentQuestion]?.type == "text" && (
                  <div>
                    <textarea
                      value={
                        responseArr?.filter(
                          (itm) =>
                            itm?.id == testData?.questions[currentQuestion]?._id
                        )[0]?.answer
                      }
                      className="ans-box"
                      placeholder="answer..."
                      onChange={(e) => {
                        hanldeChange("text", {
                          id: testData?.questions[currentQuestion]?._id,
                          answer: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
                {testData?.questions[currentQuestion]?.type == "audio" && (
                  <div>
                    <audio controls>
                      <source
                        src={testData?.questions[currentQuestion]?.url}
                        type="audio/ogg"
                      />
                      Your browser does not support the audio element.
                    </audio>
                    <br />
                    <br />
                    {/* <ReactMic
                    record={isRecording}
                    className="sound-wave"
                    onStop={onStop}
                    onData={console.log}
                    strokeColor="#000000"
                    backgroundColor="#DC7215"
                  />
                  {!isRecording ? (
                    <button
                      onClick={onStart}
                      className="btn btn-primary"
                      type="button"
                    >
                      Start Recording
                    </button>
                  ) : (
                    <button
                      onClick={() => setIsRecording(false)}
                      className="btn btn-primary"
                      type="button"
                    >
                      Stop Recording
                    </button>
                  )} */}
                    <VoiceTranscript
                      type={"text"}
                      id={testData?.questions[currentQuestion]?._id}
                      hanldeChange={hanldeChange}
                    />
                    <br />
                    <br />
                    <textarea
                      value={
                        responseArr?.filter(
                          (itm) =>
                            itm?.id == testData?.questions[currentQuestion]?._id
                        )[0]?.answer
                      }
                      className="ans-box"
                      placeholder="answer..."
                      onChange={(e) => {
                        hanldeChange("text", {
                          id: testData?.questions[currentQuestion]?._id,
                          answer: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
                {testData?.questions[currentQuestion]?.type == "video" && (
                  <div>
                    <ReactPlayer
                      url={testData?.questions[currentQuestion]?.url}
                    />
                    <br />
                    <br />
                    <textarea
                      value={
                        responseArr?.filter(
                          (itm) =>
                            itm?.id == testData?.questions[currentQuestion]?._id
                        )[0]?.answer
                      }
                      className="ans-box"
                      placeholder="answer..."
                      onChange={(e) => {
                        hanldeChange("text", {
                          id: testData?.questions[currentQuestion]?._id,
                          answer: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              &nbsp;&nbsp;
              <div
                className="q-desc"
                dangerouslySetInnerHTML={{
                  __html: testData?.questions[currentQuestion]?.description,
                }}
              ></div>
            </div>
          ) : (
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  color:
                    testData?.questions[currentQuestion]?.expectedans?.includes(
                      responseArr.find(
                        (itm) =>
                          itm.id == testData?.questions[currentQuestion]?._id
                      )?.answer
                    ) ||
                    testData?.questions[currentQuestion]?.expectedans ==
                      responseArr.find(
                        (itm) =>
                          itm.id == testData?.questions[currentQuestion]?._id
                      )?.answer
                      ? "green"
                      : "red",
                }}
              >
                <b>Your Answer:</b>{" "}
                {
                  responseArr.find(
                    (itm) => itm.id == testData?.questions[currentQuestion]?._id
                  )?.answer
                }
              </div>
              <div>
                <b>Correct Answer:</b>{" "}
                {testData?.questions[currentQuestion]?.expectedans}
              </div>
              <hr />
              <div>
                <b>Explanation:</b>{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: testData?.questions?.[currentQuestion].explanation,
                  }}
                ></div>
              </div>
            </div>
          )}

          <hr />

          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-between">
              <button
                disabled={currentQuestion === 0}
                onClick={() => setCurrentQuestion(currentQuestion - 1)}
                className="btn btn-primary"
              >
                Previous
              </button>{" "}
              &nbsp;
              <button
                disabled={testData?.questions.length - 1 === currentQuestion}
                onClick={() => setCurrentQuestion(currentQuestion + 1)}
                className="btn btn-primary"
              >
                Next
              </button>
            </div>
            <div>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return <h1>Please wait...</h1>;
};
