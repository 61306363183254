import React, { useEffect, useState } from "react";
import PersonalInfo from "./Forms/PersonalInfo";
import BackgroundInfo from "./Forms/BackgroundInfo";
import PassportInfo from "./Forms/PassportInfo";
import EmergencyContact from "./Forms/EmergencyContact";
import PastEdu from "./Forms/PastEdu";
import WorkExp from "./Forms/WorkExp";
import Test from "./Forms/Test";
import { useParams, useNavigate } from "react-router-dom";
import { url } from "../../Constants";
import API from "../../../Api/Api";

const AdminApplicationProfile = () => {
  const [data, setdata] = useState({});
  const [additionalDetails, setAdditionalDetails] = useState("");
  const { id } = useParams("");
  const [updateWorkExp, setUpdateWorkExp] = useState(false);

  const getData = async () => {
    const response = await fetch(`${url}/user/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      body: JSON.stringify({ userId: id }),
    });

    const json = await response.json();
    if (json?.data?.additionalDetails !== undefined) {
      setAdditionalDetails(json?.data?.additionalDetails);
    }

    setdata(json.data);
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  useEffect(() => {
    getData();
  }, [id]);

  const onChange = (e) => {
    const target = e.target.name.split(".");
    setAdditionalDetails(e.target.value);

    if (target.length === 2) {
      setdata({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setdata({ ...data, [e.target.name]: e.target.value });
    }
  };

  const updateData = async (feild, data, skip_work, action) => {
    const response = await API("post", `/user/update`, {
      feild,
      data,
      workDna: skip_work,
      adminUpdateAUserById: id,
    });
    setUpdateWorkExp(true);
    window.location.reload();
    // const json = response.data;
  };

  return (
    <>
      <div className="body-content">
        <div className="settings-container user-profile">
          <h1>Application Profile</h1>
          <div className="row">
            <div className="col"></div>
          </div>
          <div className="d-flex align-items-start mt-3 application-profile-block">
            <div
              className="nav flex-column nav-pills me-3 application-profile"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Personal Information
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                Background Information
              </button>
              <button
                className="nav-link"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                Passport Information
              </button>
              <button
                className="nav-link"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                Emergency Contact Information
              </button>
              <button
                className="nav-link"
                id="v-pills-education-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-education"
                type="button"
                role="tab"
                aria-controls="v-pills-education"
                aria-selected="false"
              >
                Past Education
              </button>
              <button
                className="nav-link"
                id="v-pills-test-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-test"
                type="button"
                role="tab"
                aria-controls="v-pills-test"
                aria-selected="false"
              >
                Test Taken
              </button>
              <button
                className="nav-link"
                id="v-pills-work-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-work"
                type="button"
                role="tab"
                aria-controls="v-pills-work"
                aria-selected="false"
              >
                Work Experience
              </button>
              <button
                className="nav-link"
                id="v-pills-addition-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-addition"
                type="button"
                role="tab"
                aria-controls="v-pills-addition"
                aria-selected="false"
              >
                Additional Details
              </button>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
              <PersonalInfo data={data?.userInfo} updateData={updateData} />
              <BackgroundInfo
                data={data?.backgroundInfo}
                updateData={updateData}
              />
              <PassportInfo data={data?.passport} updateData={updateData} />
              <EmergencyContact
                data={data?.emergencyContact}
                updateData={updateData}
              />
              <PastEdu data={data?.education} updateData={updateData} />
              <WorkExp data={data?.workexp} updateData={updateData} />
              <Test data={data?.testTaken} updateData={updateData} />

              <div
                className="tab-pane fade"
                id="v-pills-addition"
                role="tabpanel"
                aria-labelledby="v-pills-addition-tab"
              >
                <div className="row profile-section">
                  <div className="col">
                    <div className="section-head">Additional Details</div>
                    <div className="section-content">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3 mt-4">
                            <textarea
                              className="form-control"
                              onChange={onChange}
                              placeholder="Additional Details"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              value={additionalDetails}
                            />
                            <label htmlFor="floatingTextarea2">
                              Additional Details
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-end action-btn-group">
                          <button type="submit" className="btn btn-secondary">
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-regular"
                            onClick={() => {
                              updateData(
                                "additionalDetails",
                                additionalDetails
                              );
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={backButton}
            className="btn btn-primary updateBtn mb-5"
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminApplicationProfile;
