import React, { useEffect, useState } from "react";

const PastEdu = (props) => {
  const [originalData, setOriginalData] = useState({
    gapYear: "",
    skip: false,
    hsc: {
      name: "",
      start: "",
      end: "",
      grade: "",
      gradingSystem: "",
      schoolAddress: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
      board: "",
    },
    ssc: {
      dna: false,
      name: "",
      start: "",
      end: "",
      grade: "",
      gradingSystem: "",
      schoolAddress: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
      board: "",
    },
    grad: {
      dna: false,
      name: "",
      courseSpcl: "",
      univName: "",
      collegeName: "",
      cgpaBacklog: "",
      duration: "",
      schoolAddress: "",
      cityState: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
    },
    postGrad: {
      dna: false,
      name: "",
      courseSpcl: "",
      univName: "",
      collegeName: "",
      cgpaBacklog: "",
      duration: "",
      schoolAddress: "",
      cityState: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
    },
  });

  const [data, setData] = useState(originalData);

  useEffect(() => {
    if (props.data) {
        setData(props.data);
        setOriginalData(props.data); // Store the original data
    }
  }, [props.data]);

  // Reset data to originalData on Cancel button click
  const handleCancel = () => {
      setData(originalData); // Revert to original data
  };

  const onChange = (e) => {
    const target = e.target.name.split(".");

    if (target.length === 2) {
      setData({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <div
      className="tab-pane fade"
      id="v-pills-education"
      role="tabpanel"
      aria-labelledby="v-pills-education-tab"
    >
      <div className="row profile-section">
        <div className="col">
          <div className="section-head">Past Education</div>
          <div className="form-group edit-form section-content">
            <div className="row">
              <div className="col">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        10th / HSC{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.name"
                                value={data.hsc.name}
                                onChange={onChange}
                                placeholder="Name of the Institution"
                                aria-label=""
                              />
                              <label htmlFor="">Name of the Institution</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="hsc.start"
                                value={data.hsc.start}
                                onChange={onChange}
                                placeholder="Start Date"
                                aria-label=""
                              />
                              <label htmlFor="">Start Date</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="hsc.end"
                                value={data.hsc.end}
                                onChange={onChange}
                                placeholder="End Date"
                                aria-label=""
                                min={
                                  !isNaN(new Date(data.hsc.start))
                                    ? new Date(data.hsc.start)
                                      .toISOString()
                                      ?.split("T")[0]
                                    : new Date().toISOString().split("T")[0]
                                }
                              />
                              <label htmlFor="">End Date</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.grade"
                                value={data.hsc.grade}
                                onChange={onChange}
                                placeholder="Grades / % / Score"
                                aria-label=""
                              />
                              <label htmlFor="">Grades / % / Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.gradingSystem"
                                value={data.hsc.gradingSystem}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">Grading System</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.schoolAddress"
                                value={data.hsc.schoolAddress}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">School Address</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.city"
                                value={data.hsc.city}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">City of Study</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.state"
                                value={data.hsc.state}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">State of Study</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.zipCode"
                                value={data.hsc.zipCode}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">Zip Code</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.country"
                                value={data.hsc.country}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">Country of Study</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.board"
                                value={data.hsc.board}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">Board</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.degree"
                                value={data.hsc.degree}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">
                                Qualification/Degree Achieved
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.language"
                                value={data.hsc.language}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">
                                Primary Language of Instruction
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="hsc.status"
                                value={data.hsc.status}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">
                                Status (Studying/Graduated)
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="hsc.gradDate"
                                value={data.hsc.gradDate}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                              />
                              <label htmlFor="">Graduation Date</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        12th / SSC / Technical Diploma / IB &nbsp;&nbsp;&nbsp; &nbsp;
                        <span className="d-flex justify-content-center align-items-center">
                          {" "}
                          <input
                            type="checkbox"
                            checked={data?.ssc?.dna}
                            onChange={(e) =>
                              setData({
                                ...data,
                                ssc: { ...data?.ssc, dna: e.target.checked },
                              })
                            }
                          />{" "}
                          &nbsp; Does not Apply
                        </span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.name"
                                value={data.ssc.name}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Name of the Institution</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="ssc.start"
                                value={data.ssc.start}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Start Date</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="ssc.end"
                                value={data.ssc.end}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                                min={
                                  !isNaN(new Date(data.ssc.start))
                                    ? new Date(data.ssc.start)
                                      .toISOString()
                                      ?.split("T")[0]
                                    : new Date().toISOString().split("T")[0]
                                }
                              />
                              <label htmlFor="">End Date</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.grade"
                                value={data.ssc.grade}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Grades / % / Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.gradingSystem"
                                value={data.ssc.gradingSystem}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Grading System</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.schoolAddress"
                                value={data.ssc.schoolAddress}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">School Address</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.city"
                                value={data.ssc.city}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">City of Study</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.state"
                                value={data.ssc.state}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">State of Study</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.zipCode"
                                value={data.ssc.zipCode}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Zip Code</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.country"
                                value={data.ssc.country}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Country of Study</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.board"
                                value={data.ssc.board}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Board</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.degree"
                                value={data.ssc.degree}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">
                                Qualification/Degree Achieved
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.language"
                                value={data.ssc.language}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">
                                Primary Language of Instruction
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="ssc.status"
                                value={data.ssc.status}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">
                                Status (Studying/Graduated)
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="ssc.gradDate"
                                value={data.ssc.gradDate}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.ssc?.dna}
                              />
                              <label htmlFor="">Graduation Date</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Graduation &nbsp;&nbsp;&nbsp; &nbsp;
                        <span className="d-flex justify-content-center align-items-center">
                          {" "}
                          <input
                            type="checkbox"
                            checked={data?.grad?.dna}
                            onChange={(e) =>
                              setData({
                                ...data,
                                grad: { ...data?.grad, dna: e.target.checked },
                              })
                            }
                          />{" "}
                          &nbsp; Does not Apply
                        </span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <select
                                name="grad.name"
                                value={data.grad.name}
                                onChange={onChange}
                                className="form-select"
                                placeholder="Select Degree"
                                disabled={data?.grad?.dna}
                              >
                                <option value="Select Degree">
                                  Select Degree
                                </option>
                                <option value="Bachelor of Arts (B.A.)">
                                  Bachelor of Arts (B.A.)
                                </option>
                                <option value="Bachelor of Science (B.S.)">
                                  Bachelor of Science (B.S.)
                                </option>
                                <option
                                  value="Bachelor of Engineering
                                                            (B.Eng.)"
                                >
                                  Bachelor of Engineering (B.Eng.)
                                </option>
                                <option
                                  value="Bachelor of Technology
                                                            (B.Tech)"
                                >
                                  Bachelor of Technology (B.Tech)
                                </option>
                                <option
                                  value="Bachelor of Business
                                                            Administration (BBA)"
                                >
                                  Bachelor of Business Administration (BBA)
                                </option>
                                <option value="Bachelor of Fine Arts (BFA)">
                                  Bachelor of Fine Arts (BFA)
                                </option>
                                <option
                                  value="Bachelor of Computer
                                                            Science (B.Comp.Sc. or BCS)"
                                >
                                  Bachelor of Computer Science (B.Comp.Sc. or
                                  BCS)
                                </option>
                                <option
                                  value="Bachelor of Medicine,
                                                            Bachelor of Surgery (MBBS)"
                                >
                                  Bachelor of Medicine, Bachelor of Surgery
                                  (MBBS)
                                </option>
                                <option
                                  value="Bachelor of Education
                                                            (B.Ed.)"
                                >
                                  Bachelor of Education (B.Ed.)
                                </option>
                                <option
                                  value="Bachelor of Social Work
                                                            (BSW)"
                                >
                                  Bachelor of Social Work (BSW)
                                </option>
                                <option
                                  value="Bachelor of Commerce
                                                            (B.Com)"
                                >
                                  Bachelor of Commerce (B.Com)
                                </option>
                                <option value="Bachelor of Nursing (B.N.)">
                                  Bachelor of Nursing (B.N.)
                                </option>
                                <option
                                  value="Bachelor of Architecture
                                                            (B.Arch)"
                                >
                                  Bachelor of Architecture (B.Arch)
                                </option>
                                <option value="Bachelor of Music (B.Mus.)">
                                  Bachelor of Music (B.Mus.)
                                </option>
                                <option value="Bachelor of Laws (LL.B)">
                                  Bachelor of Laws (LL.B)
                                </option>
                                <option
                                  value="Bachelor of Pharmacy
                                                            (B.Pharm)"
                                >
                                  Bachelor of Pharmacy (B.Pharm)
                                </option>
                                <option
                                  value="Bachelor of Dental Surgery
                                                            (BDS)"
                                >
                                  Bachelor of Dental Surgery (BDS)
                                </option>
                                <option
                                  value="Bachelor of Science in
                                                            Nursing (BSN)"
                                >
                                  Bachelor of Science in Nursing (BSN)
                                </option>
                                <option value="Bachelor of Fine Arts (BFA)">
                                  Bachelor of Fine Arts (BFA)
                                </option>
                                <option
                                  value="Bachelor of Economics
                                                            (B.Econ)"
                                >
                                  Bachelor of Economics (B.Econ)
                                </option>
                                <option
                                  value="Bachelor of Communication
                                                            (B.Comm)"
                                >
                                  Bachelor of Communication (B.Comm)
                                </option>
                                <option
                                  value="Bachelor of Environmental
                                                            Science (B.Env.Sc)"
                                >
                                  Bachelor of Environmental Science (B.Env.Sc)
                                </option>
                                <option value="Bachelor of Design (B.Des)">
                                  Bachelor of Design (B.Des)
                                </option>
                              </select>
                              <label htmlFor="">Degree</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.courseSpcl"
                                value={data.grad.courseSpcl}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">
                                Course / Specialization (if any)
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.univName"
                                value={data.grad.univName}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">University Name</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.collegeName"
                                value={data.grad.collegeName}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">College Name</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.cgpaBacklog"
                                value={data.grad.cgpaBacklog}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">
                                CGPA / % / Backlogs (if any)
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.duration"
                                value={data.grad.duration}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">Duration</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.schoolAddress"
                                value={data.grad.schoolAddress}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">School Address</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.cityState"
                                value={data.grad.cityState}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">City, State of Study</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.zipCode"
                                value={data.grad.zipCode}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">Zip Code</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.country"
                                value={data.grad.country}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">Country of Study</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.degree"
                                value={data.grad.degree}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">
                                Qualification/Degree Achieved
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.language"
                                value={data.grad.language}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">
                                Primary Language of Instruction
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="grad.status"
                                value={data.grad.status}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">
                                Status (Studying/Graduated)
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="grad.gradDate"
                                value={data.grad.gradDate}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.grad?.dna}
                              />
                              <label htmlFor="">Graduation Date</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        Post Graduation &nbsp;&nbsp;&nbsp; &nbsp;
                        <span className="d-flex justify-content-center align-items-center">
                          {" "}
                          <input
                            type="checkbox"
                            checked={data?.postGrad?.dna}
                            onChange={(e) =>
                              setData({
                                ...data,
                                postGrad: { ...data?.postGrad, dna: e.target.checked },
                              })
                            }
                          />{" "}
                          &nbsp; Does not Apply
                        </span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <select
                                name="postGrad.name"
                                value={data.postGrad.name}
                                onChange={onChange}
                                className="form-select"
                                placeholder="Select Degree"
                                disabled={data?.postGrad?.dna}
                              >
                                <option value="Select Degree">
                                  Select Degree
                                </option>
                                <option value="Master of Arts (M.A.)">
                                  Master of Arts (M.A.)
                                </option>
                                <option value="Master of Science (M.S.)">
                                  Master of Science (M.S.)
                                </option>
                                <option
                                  value="Master of Business
                                                                    Administration (MBA)"
                                >
                                  Master of Business Administration (MBA)
                                </option>
                                <option
                                  value="Master of Engineering
                                                                    (M.Eng.)"
                                >
                                  Master of Engineering (M.Eng.)
                                </option>
                                <option
                                  value="Master of Technology
                                                                    (M.Tech)"
                                >
                                  Master of Technology (M.Tech)
                                </option>
                                <option value="Master of Fine Arts (MFA)">
                                  Master of Fine Arts (MFA)
                                </option>
                                <option
                                  value="Master of Computer Science
                                                                    (M.Comp.Sc. or MCS)"
                                >
                                  Master of Computer Science (M.Comp.Sc. or MCS)
                                </option>
                                <option value="Master of Education (M.Ed.)">
                                  Master of Education (M.Ed.)
                                </option>
                                <option value="Master of Social Work (MSW)">
                                  Master of Social Work (MSW)
                                </option>
                                <option value="Master of Commerce (M.Com)">
                                  Master of Commerce (M.Com)
                                </option>
                                <option value="Master of Nursing (M.N.)">
                                  Master of Nursing (M.N.)
                                </option>
                                <option
                                  value="Master of Architecture
                                                                    (M.Arch)"
                                >
                                  Master of Architecture (M.Arch)
                                </option>
                                <option value="Master of Music (M.Mus.)">
                                  Master of Music (M.Mus.)
                                </option>
                                <option value="Master of Laws (LL.M)">
                                  Master of Laws (LL.M)
                                </option>
                                <option
                                  value="Master of Pharmacy
                                                                    (M.Pharm)"
                                >
                                  Master of Pharmacy (M.Pharm)
                                </option>
                                <option
                                  value="Master of Dental Surgery
                                                                    (MDS)"
                                >
                                  Master of Dental Surgery (MDS)
                                </option>
                                <option
                                  value="Master of Science in
                                                                    Nursing (MSN)"
                                >
                                  Master of Science in Nursing (MSN)
                                </option>
                                <option value="Master of Fine Arts (MFA)">
                                  Master of Fine Arts (MFA)
                                </option>
                                <option
                                  value="Master of Economics
                                                                    (M.Econ)"
                                >
                                  Master of Economics (M.Econ)
                                </option>
                                <option
                                  value="Master of Communication
                                                                    (M.Comm)"
                                >
                                  Master of Communication (M.Comm)
                                </option>
                                <option
                                  value="Master of Environmental
                                                                    Science (M.Env.Sc)"
                                >
                                  Master of Environmental Science (M.Env.Sc)
                                </option>
                                <option value="Master of Design (M.Des)">
                                  Master of Design (M.Des)
                                </option>
                                <option
                                  value="Master of Public
                                                                    Administration (MPA)"
                                >
                                  Master of Public Administration (MPA)
                                </option>
                                <option
                                  value="Master of Public Health
                                                                    (MPH)"
                                >
                                  Master of Public Health (MPH)
                                </option>
                                <option
                                  value="Master of International
                                                                    Business (MIB)"
                                >
                                  Master of International Business (MIB)
                                </option>
                              </select>
                              <label htmlFor="">Degree</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.courseSpcl"
                                value={data.postGrad.courseSpcl}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">
                                Course / Specialization (if any)
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.univName"
                                value={data.postGrad.univName}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">University Name</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.collegeName"
                                value={data.postGrad.collegeName}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">College Name</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.cgpaBacklog"
                                value={data.postGrad.cgpaBacklog}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">
                                CGPA / % / Backlogs (if any)
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.duration"
                                value={data.postGrad.duration}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">Duration</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.schoolAddress"
                                value={data.postGrad.schoolAddress}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">School Address</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.cityState"
                                value={data.postGrad.cityState}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">City, State of Study</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.zipCode"
                                value={data.postGrad.zipCode}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">Zip Code</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.country"
                                value={data.postGrad.country}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">Country of Study</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.degree"
                                value={data.postGrad.degree}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">
                                Qualification/Degree Achieved
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.language"
                                value={data.postGrad.language}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">
                                Primary Language of Instruction
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.status"
                                value={data.postGrad.status}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">
                                Status (Studying/Graduated)
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                name="postGrad.gradDate"
                                value={data.postGrad.gradDate}
                                onChange={onChange}
                                placeholder=""
                                aria-label=""
                                disabled={data?.postGrad?.dna}
                              />
                              <label htmlFor="">Graduation Date</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    name="gapYear"
                    value={data.gapYear}
                    onChange={onChange}
                    placeholder=""
                    aria-label=""
                    disabled={data?.postGrad?.dna}
                  />
                  <label htmlFor="">Gap Year (if any)</label>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="col text-end action-btn-group">
                <button type="submit" className="btn btn-secondary" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-regular"
                  onClick={() => {
                    props.updateData("education", data);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastEdu;
