import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import API from "../../../Api/Api";
import swal from "sweetalert";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import countryCodes from "../../Assets/Data/countries.json";
import courseData from "../../Assets/Data/coursecounseling.json";
import country_code from "../../Assets/Data/countries_code.json";

import "../../../App.css";
import Editor from "../Editor/Editor";
const testList = ["gre", "gmat", "toefl", "ielts", "pte", "det", "sat", "act"];

export const AddTest = () => {
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    description: "",
    type: "",
    destination: [],
    subject: [],
    course: [],
    last_edu_country: "",
    nationality: "",
    level: "ug",
    total_time: 0,
  });
  const [course, setCourse] = useState([]);

  const [searchParams] = useSearchParams();
  const handleCreateTest = async (e) => {
    e.preventDefault();
    try {
      const response =
        typeof searchParams.get("id") == "string"
          ? await API(
            "put",
            `/test/admin/update-test/${searchParams.get("id")}`,
            data
          )
          : await API("post", `/test/admin/create-test`, data);
      swal({
        title: "Success!",
        text: response?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      navigate("/lms-dashboard-tests");
    } catch (error) { }
  };

  const getSingleTest = async (id) => {
    try {
      const response = await API("get", `/test/single/${id}`);
      setData({
        name: response?.data?.data?.name,
        description: response?.data?.data?.description?.toString("html"),
        type: response?.data?.data?.type,
        nationality: response?.data?.data?.nationality,
        destination: response?.data?.data?.destination ?? [],
        subject: response?.data?.data?.subject ?? [],
        course: response?.data?.data?.course ?? [],
        english_speaking: response?.data?.data?.english_speaking,
        total_time: response?.data?.data?.total_time,
      });
      let value = RichTextEditor.createValueFromString(
        response?.data?.data?.description,
        "html"
      );

      setEditorValue(value);
    } catch (error) { }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/lms-dashboard-tests");
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      getSingleTest(searchParams.get("id"));
    }
  }, []);

  useEffect(() => {
    if (data?.subject?.length > 0) {
      let arr = [];
      data.subject.map((itm) => {
        arr.push(...courseData[itm]);
      });
      setCourse(arr);
    }
  }, [data?.subject]);

  return (
    <>
      <div className="container admin-createpage">
        <div>
          <div className="main-heading">Add Test</div>
        </div>
        <div>
          <div className="page-form">
            <form>
              <label className="exampleInputEmail1">Test Name</label>
              <input
                value={data?.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                className="form-control"
                placeholder="Name"
              />
              <br />
              <label>Test description</label>
              {/* <RichTextEditor
            value={editorValue}
            onChange={(e) => {
              setEditorValue(e);
              setData({
                ...data,
                description: e.toString("html"),
              });
            }}
            toolbarConfig={toolbarConfig}
          /> */}
              <Editor data={data} setData={setData} name={"description"} />
              {/* <br />
          <label>Test description HTML</label>
          {data?.description && (
            <Editor
              height="20vh"
              defaultLanguage="html"
              defaultValue={data?.description}
              onChange={handleEditorChange}
              theme="vs-dark"
            />
          )} */}

              <br />
              <label>Type</label>
              <select
                value={data?.type}
                className="form-control"
                onChange={(e) => {
                  setData({
                    ...data,
                    type: e.target.value,
                  });
                }}
              >
                <option>Select One</option>
                {testList.map((test) => (
                  <option key={test} value={test}>
                    {test}
                  </option>
                ))}
              </select>
              <br />
              <label>Destination</label>
              <Select
                className="app-multiSelect"
                isMulti
                value={data?.destination?.map((value) => ({
                  value,
                  label: value,
                }))}
                options={countryCodes.map((op, i) => ({
                  value: op.name,
                  label: op.name,
                }))}
                onChange={(selectedOptions) => {
                  const newData = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  setData((prevData) => ({
                    ...prevData,
                    destination: newData,
                  }));
                }}
                placeholder="Study Destination"
                isOptionDisabled={() => data?.destination?.length >= 3}
              />
              <br />
              <label>Nationality</label>
              <select
                className="form-select"
                onClick={(e) => {
                  setData({
                    ...data,
                    nationality: e.target.value,
                  });
                }}
              >
                <option value={""}>Select Country</option>
                {country_code?.map((itm) => (
                  <option id={itm?.name} value={itm?.name}>
                    {itm?.name}
                  </option>
                ))}
              </select>
              <br />
              <label>Country of Last Education</label>
              <select
                className="form-select"
                onClick={(e) => {
                  setData({
                    ...data,
                    last_edu_country: e.target.value,
                  });
                }}
              >
                <option value={""}>Select Country</option>
                {country_code?.map((itm) => (
                  <option id={itm?.name} value={itm?.name}>
                    {itm?.name}
                  </option>
                ))}
              </select>
              <br />
              <label>Subject</label>
              <div className="d-flex">
                <select
                  style={{ width: "200px" }}
                  className="form-select"
                  value={data?.level}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, level: e.target.value };
                    });
                  }}
                >
                  <option value="ug">UG</option>
                  <option value="pg">PG</option>
                </select>{" "}
                &nbsp;
                <Select
                  style={{ width: "100%" }}
                  className="app-multiSelect"
                  isMulti
                  value={data?.subject?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={Object?.keys(courseData).map((idx) => ({
                    value: idx,
                    label: idx,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setData((prevData) => ({
                      ...prevData,
                      subject: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                // isOptionDisabled={() => data?.subject?.length >= 3}
                />
              </div>

              <br />
              <label>Course</label>
              <Select
                className="app-multiSelect"
                isMulti
                value={data?.course?.map((value) => ({
                  value,
                  label: value,
                }))}
                options={
                  course?.length === 0
                    ? [{ label: "Select Subject to get courses", value: "" }]
                    : course?.map((op, i) => ({
                      value: op.name,
                      label: op.name,
                    }))
                }
                onChange={(selectedOptions) => {
                  const newData = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  setData((prevData) => ({
                    ...prevData,
                    course: newData,
                  }));
                }}
                placeholder="Course of Interest"
                isOptionDisabled={() => {
                  if (course.length === 0) return 1;
                  else return data.course.length >= 5;
                }}
              />
              <br />
              <div className="">
                <label>Total Test Time</label>
                <input
                  className="form-control"
                  type="time"
                  value={data?.total_time}
                  onChange={(e) => {
                    //console.log(e.target.value);
                    setData({
                      ...data,
                      total_time: e.target.value,
                    });
                  }}
                />{" "}
              </div>

              {/* <div className="d-flex">
            <input
              type="checkbox"
              onClick={(e, v) => {
                setData({
                  ...data,
                  english_speaking: e.target.checked,
                });
              }}
            />{" "}
            &nbsp;
            <p style={{ margin: 0 }}>Is Candidate English Speaking?</p>
          </div> */}
              <br />
              <br />
              <button className="btn btn-primary-regular" onClick={handleCreateTest}>
                {" "}
                Submit
              </button>
              <button
                type="submit"
                onClick={backButton}
                className="btn btn-secondary-regular"
              >
                Back
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
