import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Navigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.substring(1);
  const pathStyle = (url) => {
    if (path == url) {
      return {
        background: path == url && "#534173",
        border: path == url && "#534173",
      };
    }
    return {};
  };

  return (
    <>
      <div className="row actionBox">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div>
              <button
                onClick={() => {
                  navigate("/lms-dashboard-tests");
                }}
                type="button"
                className="btn btn-primary-regular"
                style={pathStyle("lms-dashboard-tests")}
              >
                Tests
              </button>
              <button
                onClick={() => {
                  navigate("/lms-dashboard-questions");
                }}
                type="button"
                className="btn btn-primary-regular"
                style={pathStyle("lms-dashboard-questions")}
              >
                Questions
              </button>
              <button
                onClick={() => {
                  navigate("/lms-dashboard-course");
                }}
                type="button"
                className="btn btn-primary-regular"
                style={pathStyle("lms-dashboard-course")}
              >
                Courses
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate("/dashboard");
                }}
                type="button"
                className="btn btn-secondary"
                style={pathStyle("dashboard")}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
