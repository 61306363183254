import React, { useEffect, useState } from "react";
import WorkExpCard from "./WorkExpCard";

const WorkExp = (props) => {
  const [work, setWork] = useState([]);

  const [edt, setEdit] = useState(false);

  const [data, setData] = useState({ skip: false });
  const [newWorkExp, setNewWorkExp] = useState({
    company: "",
    designation: "",
    start: "",
    end: "",
    type: "",
  });
  const [edtWorkExp, setEditWorkExp] = useState(true);
  const [error, setError] = useState("");

  const addWorkExp = async () => {
    if (newWorkExp.end < newWorkExp.start) {
      setError("End date cannot be less than start date");
      return;
    }
    let newdata = work;
    newdata.push(newWorkExp);
    props.updateData("workexp", newdata, data?.skip);
    //console.log(props.updateWorkExp);
    if (props.updateWorkExp === true) {
      setWork(newdata);
      setNewWorkExp({
        company: "",
        designation: "",
        start: "",
        end: "",
        type: "",
      });
      setEditWorkExp(false);
    } else {
      setWork([]);
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (
      (event.target.name === "start" || event.target.name === "end") &&
      newWorkExp[event.target.name].length === 1 &&
      event.target.value.length === 2
    ) {
      setNewWorkExp({
        ...newWorkExp,
        [event.target.name]: String(event.target.value + "/"),
      });
      return;
    }
    if (name === "end" && value < newWorkExp.start) {
      setError("End date cannot be less than start date");
    } else {
      setError("");
    }
    setNewWorkExp({ ...newWorkExp, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (work.length === 0) {
      setEditWorkExp(true);
    }
    if (typeof props?.workDna == "boolean") {
      setData({ skip: props?.workDna });
    }
  }, [work]);

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) {
      setWork(props.data);
      if (props.data.length > 0) {
        setEditWorkExp(false);
      }
    }
  }, [props.data]);

  return (
    <div className="tab-pane fade" id="v-pills-work" role="tabpanel" aria-labelledby="v-pills-work-tab">
      <div className="row profile-section">
        <div className="col">
          <div className="section-head d-flex flex-row justify-content-between">
          <div className="d-flex">Work Experience &nbsp;&nbsp;&nbsp; &nbsp;<span className="d-flex justify-content-center align-items-center">
              {" "}
              <input
                type="checkbox"
                // disabled={data?.skip}
                checked={data?.skip}
                onChange={(e) =>
                  setData({
                    ...data,
                    skip: e.target.checked,
                  })
                }
              />{" "}
              &nbsp; Does not Apply
            </span></div>

            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square edit-btn"
              viewBox="0 0 16 16"
              onClick={() => {
                setEdit(false);
              }}
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg> */}
            {work.length > 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                onClick={() => {
                  setEditWorkExp(true);
                }}
                className="bi bi-plus-lg edit-btn"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                ></path>
              </svg>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col">
              {work.map((item, idx) => (
                <WorkExpCard
                  setWork={setWork}
                  exp={item}
                  key={idx}
                  work={work}
                  updateData={props.updateData}
                idx={idx}
                  idx={idx}
                />
              ))}
            </div>
          </div>
          {!data?.skip && (
            <div className="form-group edit-form section-content">
              {edtWorkExp ? (
                <>
                  <div className="row mb-3 responsive-col">
                    <div className="col">
                      <label htmlFor="">
                        Job Type<span className="required">*</span>
                      </label>
                      <br />
                      <input
                        disabled={data?.skip}
                        type="radio"
                        className="btn-check"
                        name="options61"
                        id="options61"
                        autoComplete="off"
                        defaultChecked=""
                        checked={newWorkExp.type === "Full Time"}
                        onClick={() => {
                          setNewWorkExp({ ...newWorkExp, type: "Full Time" });
                        }}
                      />
                      <label className="btn btn-secondary" htmlFor="options61">
                        <i className="fa-solid fa-briefcase" /> Full Time
                      </label>
                      <input
                        disabled={data?.skip}
                        type="radio"
                        className="btn-check"
                        name="options62"
                        id="options62"
                        autoComplete="off"
                        checked={newWorkExp.type === "Part Time"}
                        onClick={() => {
                          setNewWorkExp({ ...newWorkExp, type: "Part Time" });
                        }}
                      />
                      <label className="btn btn-secondary" htmlFor="options62">
                        <i className="fa-solid fa-briefcase" /> Part Time
                      </label>
                      <input
                        disabled={data?.skip}
                        type="radio"
                        className="btn-check"
                        name="options63"
                        id="options63"
                        autoComplete="off"
                        checked={newWorkExp.type === "Internship"}
                        onClick={() => {
                          setNewWorkExp({ ...newWorkExp, type: "Internship" });
                        }}
                      />
                      <label className="btn btn-secondary" htmlFor="options63">
                        <i className="fa-solid fa-briefcase" /> Internship
                      </label>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          disabled={data?.skip}
                          type="text"
                          className="form-control"
                          placeholder="Highest Education"
                          defaultValue=""
                          aria-label="Highest Education"
                          value={newWorkExp.company}
                          onChange={onChange}
                          name="company"
                        />
                        <label htmlFor="">Last/Current Company</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          disabled={data?.skip}
                          type="text"
                          className="form-control"
                          placeholder="Field of Study"
                          aria-label="Field of Study"
                          value={newWorkExp.designation}
                          onChange={onChange}
                          name="designation"
                        />
                        <label htmlFor="">Designation</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          disabled={data?.skip}
                          type="month"
                          className="form-control"
                          placeholder="Start Month/Year"
                          aria-label="Start Month/Year"
                          value={newWorkExp.start}
                          onChange={onChange}
                          name="start"
                        />
                        <label htmlFor="">Start Month/Year</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        {error && (
                          <div className="text-error text-end">{error}</div>
                        )}
                        <input
                          disabled={data?.skip}
                          type="month"
                          className={`form-control ${error && "border-error"}`}
                          placeholder="End Month/Year"
                          aria-label="End Month/Year"
                          value={newWorkExp.end}
                          onChange={onChange}
                          name="end"
                        />
                        <label htmlFor="">End Month/Year</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col">
                                    <button type="submit" className="btn btn-primary-regular" onClick={() => {
                                        setNewWorkExp({ ...newWorkExp, isRecent: true });
                                    }}>
                                        Mark as most recent
                                    </button>
                                </div>
                    <div className="col text-end action-btn-group">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={() => {
                          // setEditWorkExp(false);
                          setData({ skip: true });
                          setNewWorkExp({
                            company: "",
                            designation: "",
                            start: "",
                            end: "",
                            type: "",
                          });
                        }}
                        // disabled={work.length === 0}
                      >
                        Cancel
                      </button>
                      <button
                        // disabled={data?.skip}
                        type="submit"
                        className="btn btn-primary-regular"
                        onClick={addWorkExp}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkExp;
