import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import API from "../../Api/Api";
import { MdPerson, User } from "react-icons/md";

const MentorChat = ({ profile, socket }) => {
  // const params = { id: profile?._id };
  const [activeUser, setActiveUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  // const [searchParams] = useSearchParams();
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [details, setDetails] = useState({});
  
  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const getUniqUsers = async () => {
    try {
      const response = await API(
        "get",
        `/mentor/chat/unique-users/${profile?._id}`
      );
      setActiveUser(response.data.data[0]);
      setAllUsers(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (profile?._id) {
      getUniqUsers();
    }
  }, [profile]);

  useEffect(() => {
    if (profile?._id && activeUser?._id) {
      socket.on("connect", () => {
        // console.log("Socekt connected : ", socket.id);
      });

      socket.emit("join-chat", {
        mentor: profile?._id,
        user: activeUser?._id,
      });
      socket.on("details", (data) => {
        setDetails(data);
      });

      socket.on("messages", (data) => {
        //console.log("messages", data, profile?._id);
        setMessages(data);
      });

      socket.on("message", (data) => {
        setMessages(data);
      });
      socket.emit("get-message", {
        mentor: profile?._id,
        user: activeUser?._id,
      });

      socket.emit("get-details", {
        mentor: profile?._id,
        user: activeUser?._id,
      });
    }
  }, [socket, profile, activeUser]);

  //   let styleMe = {
  //     padding: "10px",
  //     borderRadius: "10px",
  //     background: "#fd7e14",
  //     color: "#fff",
  //   };

  let style = {
    padding: "10px",
    borderRadius: "10px",
    color: "#fff",
  };

  const markMessageAsRead = async (msg) => {
    try {
      const response = await API("post", `/mentor/read-notifications`, { msg });
    } catch {}
  };

  useEffect(() => {
    let msg = [];
    messages.map((item) => {
      if (
        item.read == false &&
        localStorage.getItem("mentorAccessToken") &&
        item.who == "user"
      ) {
        msg.push(item._id);
      } else if (
        item.read == false &&
        item.who == "mentor" &&
        localStorage.getItem("token")
      ) {
        msg.push(item._id);
      }
    });
    if (msg.length > 0) {
      markMessageAsRead(msg);
    }
  }, [messages]);

  if (!activeUser) {
    return (
      <div>
        <p>No messages found</p>
      </div>
    );
  }

  if (details?.userdata && details?.mentordata) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100%", gap: "2rem" }}
      >
        <div
          className="card d-flex flex-column"
          style={{ height: "100%", overflowY: "scroll", marginTop: "3.7rem" }}
        >
          {allUsers?.map((itm) => (
            <div
              key={itm?._id}
              className="d-flex align-items-center"
              onClick={() => setActiveUser(itm)}
              style={{
                borderBottom: "1px solid #000",
                padding: "10px 20px",
                cursor: "pointer",
                width: "200px",
                gap: "0.34rem",
              }}
            >
              {itm?.profile_picture ? (
                <img
                  src={itm?.profile_picture}
                  style={{
                    height: "28px",
                    borderRadius: "100px",
                    width: "28px",
                    objectFit: "cover",
                  }}
                  alt="mentor-profile-mg"
                />
              ) : (
                <MdPerson style={{ fontSize: "28px" }} />
              )}{" "}
              &nbsp;
              <b style={{ color: activeUser?._id == itm?._id && " #ca9900" }}>
                {itm?.firstName} {itm?.lastName}
              </b>
            </div>
          ))}
        </div>
        <div className="card" style={{ width: "700px", padding: "20px" }}>
          <h2>
            {details?.userdata?.profile_picture ? (
              <img
                src={details?.userdata?.profile_picture}
                style={{
                  height: "40px",
                  borderRadius: "100px",
                  width: "40px",
                  objectFit: "cover",
                }}
                alt="mentor-profile-mg"
              />
            ) : (
              <MdPerson style={{ fontSize: "24px" }} />
            )}
            &nbsp;
            {details?.userdata?.firstName ? (
              <b>
                {localStorage.getItem("mentorAccessToken")
                  ? details?.userdata?.firstName +
                    " " +
                    details?.userdata?.lastName
                  : details?.mentordata?.name}
              </b>
            ) : (
              "User Not Found"
            )}
          </h2>
          <hr />
          <div
            className="d-flex flex-column"
            ref={chatContainerRef}
            style={{ gap: "1rem", height: "500px", overflowY: "scroll" }}
          >
            {messages.map((itm) => (
              <div
                key={itm?._id}
                style={{
                  ...style,
                  background: itm?.who === "mentor" ? "#ff7054" : "#fff0ee",
                  color: itm?.who === "mentor" ? "#fff" : "#000",
                }}
              >
                {" "}
                <p>{itm?.text}</p>
                <span style={{ fontSize: "12px", color: "#000" }}>
                  Sent at:{" "}
                  {moment(itm?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </span>
              </div>
            ))}
          </div>
          <br />
          <div className="d-flex" style={{ gap: "1rem" }}>
            <textarea
              onChange={(e) => setText(e.target.value)}
              value={text}
              className="form-control"
            />
            <div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (text) {
                    socket.emit("send-message", {
                      mentor: profile?._id,
                      user: activeUser?._id,
                      text,
                      who: localStorage.getItem("mentorAccessToken")
                        ? "mentor"
                        : "user",
                    });
                    setText("");
                  }
                }}
              >
                {" "}
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
};

export default MentorChat;
