import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { useParams } from "react-router-dom";
import "../Assets/Styles/test.css";

const TestReport = () => {
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const params = useParams();

  const getTestData = async () => {
    setLoading(true);
    try {
      const response = await API("post", `/test/student/test-report/`, {
        testid: params.id,
      });
      setTestData(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTestData();
  }, []);

  if (loading) {
    return (
      <div className="main-container">
        <div className="container">
          {" "}
          <p>Loading...</p>
        </div>
      </div>
    );
  }
  return (
    <div className="main-container">
      <div className="container">
        <h1 className="text-center">{testData?.testid?.name}</h1>
        <div className="container conduct-test-container">
          <div className="d-flex justify-content-between">
            <div
              className="d-flex"
              dangerouslySetInnerHTML={{
                __html:
                  `Q${currentQuestion + 1}. ` +
                  testData?.response?.[currentQuestion]?.id?.title,
              }}
            ></div>
            <p>Marks: {testData?.response?.[currentQuestion]?.marks}</p>
          </div>
          <div
            style={{
              color:
                Number(testData?.response?.[currentQuestion]?.marksearned) > 0
                  ? "green"
                  : "red",
            }}
          >
            <b>Your Answer:</b> {testData?.response?.[currentQuestion]?.answer}
          </div>
          <div>
            <b>Correct Answer:</b>{" "}
            {testData?.response?.[currentQuestion]?.id.expectedans}
          </div>
          <hr />
          <div>
            <b>Explanation:</b>{" "}
            <div
              dangerouslySetInnerHTML={{
                __html: testData?.response?.[currentQuestion]?.id.explanation,
              }}
            ></div>
          </div>
        </div>
        <br/>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <button
              disabled={currentQuestion === 0}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
              className="btn btn-primary"
            >
              Previous
            </button>{" "}
            &nbsp;
            <button
              disabled={testData?.response?.length - 1 === currentQuestion}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
              className="btn btn-primary"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestReport;
