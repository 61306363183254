import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import list from "../Assets/Data/countries.json";
import countryCodes from "../Assets/Data/countries.json";
import jwt_decode from "jwt-decode";
import swal from "sweetalert";
import TestimonialSlider from "../Testimonial/TestimonialSlider";
import { url } from "../Constants";
import OAuth2Login from "react-simple-oauth2-login";
import { useLinkedIn } from "react-linkedin-login-oauth2";
// You can use provided image shipped by this package or using your own
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import API from "../../Api/Api";
import courseData from "../Assets/Data/coursecounseling.json";
import Select from "react-select";
import country_codes from "../Assets/Data/countries_code.json";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Signup = (props) => {
  const [credentials, setCredentials] = useState({
    fname: "",
    lname: "",
    email: props.email || "",
    password: "",
    phone: "",
    authcode: null,
    dest: [],
    countryCode: "",
    note: "",
    course_of_interest: [],
    skip_course: false,
    subject: "",
    level: "",
    ref_id: "",
  });
  const [searchParams] = useSearchParams();
  const [course, setCourse] = useState([]);
  let history = useNavigate();
  const [sendOtp, setSendOtp] = useState(false);
  const [googleID, setGoogleID] = useState(0);
  const [signUpReq, setSignUpReq] = useState(false);
  const [errors, setErrors] = useState({});

  const [facebookId, setFacebookId] = useState(0);
  const [signUpFbReq, setSignUpFbReq] = useState(false);
  let utm_source = searchParams.get("utm_source");
  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.href = "/userdashboard"
      // history("/userdashboard");
    }

    /* global google */
    const initGAuth = async () => {
      await google.accounts.id.initialize({
        client_id:
          "208870428930-ad67u1ss3cvahprbugg5bi0j848kl3ss.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      await google.accounts.id.renderButton(
        document.getElementById("googlebtn"),
        {
          theme: "none",
          longtitle: true,
        }
      );
    };

    initGAuth();
  }, []);

  useEffect(() => {
    setCredentials({ ...credentials, ref_id: searchParams.get("referral") });

    if (credentials.subject.length > 0) {
      setCourse(courseData[credentials.subject]);
    }
  }, [credentials.subject]);

  //for show password toggle_button

  useEffect(() => {
    const ShowPasswordToggle = document.querySelector("[type='password']");
    const togglePasswordButton = document.getElementById("toggle-password");
    const passwordInput = document.querySelector("[type='password']");

    ShowPasswordToggle.onclick = function () {
      document
        .getElementById("exampleInputPassword1")
        .classList.add("input-password");
      document.getElementById("toggle-password").classList.remove("d-none");
      const passwordInput = document.querySelector("[type='password']");
      const togglePasswordButton = document.getElementById("toggle-password");
      togglePasswordButton.addEventListener("click", togglePassword);
    };

    const togglePassword = () => {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        togglePasswordButton.setAttribute("aria-label", "Hide password.");
      } else {
        passwordInput.type = "password";
        togglePasswordButton.setAttribute(
          "aria-label",
          "Show password as plain text. " +
          "Warning: this will display your password on the screen."
        );
      }
    };
  }, []);

  const handleCallbackResponse = async (response) => {
    // getting the jwt token and setting userObject as it response
    // console.log("JWT ID TOKEN: ", response.credential);
    var userObject = await jwt_decode(response.credential);
    setCredentials({
      ...credentials,
      email: userObject.email,
      fname: userObject.given_name,
      lname: userObject.family_name,
    });
    setGoogleID(userObject.sub);

    const res = await fetch(`${url}/oauth/google/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        googleId: userObject.sub,
        email: userObject.email,
      }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await res.json();
    // console.log(json);

    if (json.requireSignup === false && json.success === true) {
      localStorage.setItem("token", json.authToken);
      if (searchParams.get("utm_source") == "public") {
        history("/" + localStorage.getItem("redirect_to").split("-")[1]);
        window.location.reload();
        return;
      }
      history(
        utm_source == "mentor_profile_page"
          ? "/refill-credits"
          : "/userdashboard"
      );
      window.location.reload();
    } else if (json.requireSignup === false && json.success === false) {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok!",
      });
      history("/login");
    } else {
      setSignUpReq(true);
    }
  };

  const handleFacebookResponse = async (response) => {
    const accessToken = response.access_token;
    const result = await fetch(
      `https://graph.facebook.com/v12.0/me?fields=id,name,email,picture.type(large)&access_token=${accessToken}`
    );
    const profile = await result.json();

    const fullName = profile.name;
    const [firstName, lastName] = fullName.split(" ");

    setCredentials({
      ...credentials,
      fname: firstName,
      lname: lastName,
      email: profile.email,
    });
    setFacebookId(profile.id);

    const res = await fetch(`${url}/oauth/facebook/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ facebookId: profile.id, email: profile.email }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await res.json();
    // console.log(json);

    if (json.requireSignup === false && json.success === true) {
      swal({
        title: "Welcome!",
        text: "Logged in Successfully",
        icon: "success",
        button: "Ok!",
      });
      await localStorage.setItem("token", json.authToken);

      if (searchParams.get("utm_source") == "public") {
        history("/" + localStorage.getItem("redirect_to").split("-")[1]);
        window.location.reload();
        return;
      }

      history(
        utm_source == "mentor_profile_page"
          ? "/refill-credits"
          : "/userdashboard"
      );
      window.location.reload();
    } else if (json.requireSignup === false && json.success === false) {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok!",
      });
      history("/login");
    } else {
      swal({
        title: "Verified!",
        text: "Continue to Register",
        icon: "success",
        button: "Continue!",
      });
      setSignUpFbReq(true);
    }
  };

  const onChange = (event) => {
    if (event.target.name === "phone") {
      const phoneValue = event.target.value.substring(0, 10);
      setCredentials({ ...credentials, [event.target.name]: phoneValue });
    } else if (event.target.name === "authcode") {
      const authcodeValue = event.target.value.substring(0, 6);
      setCredentials({ ...credentials, [event.target.name]: authcodeValue });
    } else {
      setCredentials({
        ...credentials,
        [event.target.name]: event.target.value,
      });
    }
  };

  //for manually submitting form
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateMail()) {
      try {
        const json = await API("post", `/auth/signup/email/verify`, {
          fname: credentials.fname,
          lname: credentials.lname,
          phone: credentials.phone,
          dest: credentials?.dest,
          email: credentials?.email,
          password: credentials.password,
          authcode: Number(credentials.authcode),
          countryCode: credentials.countryCode.value,
          notes: credentials.note,
          course_of_interest: credentials.course_of_interest,
          skip_course: `${credentials.skip_course}`,
          subject: credentials.subject,
          level: credentials.level,
          ref_id: credentials.ref_id,
        });
        if (json.success === true) {
          swal({
            title: "Success!",
            text: "Account Created Successfully",
            icon: "success",
            button: "Ok!",
          });
          await localStorage.setItem("token", json?.data?.authToken);
          if (searchParams.get("utm_source") == "public") {
            history("/" + localStorage.getItem("redirect_to").split("-")[1]);
            window.location.reload();
            return;
          }
          history(
            utm_source == "mentor_profile_page"
              ? "/refill-credits"
              : "/userdashboard"
          );
          window.location.reload();
        } else {
          swal({
            title: "Try Again!",
            text: json.message,
            icon: "error",
            button: "Ok!",
          });
        }
      } catch (err) { }
    }
  };

  //for Google submit
  const handleGoogleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await API("post", `${url}/oauth/google/signup`, {
        fname: credentials.fname,
        lname: credentials.lname,
        phone: credentials.phone,
        dest: credentials.dest,
        email: credentials.email,
        googleId: googleID,
        countryCode: credentials.countryCode.value,
      });
      const json = response.data;

      if (json.success === true) {
        swal({
          title: "Success!",
          text: "Account Created Successfully",
          icon: "success",
          button: "Ok!",
        });
        await localStorage.setItem("token", json.authToken);
        if (searchParams.get("utm_source") == "public") {
          history("/" + localStorage.getItem("redirect_to").split("-")[1]);
          window.location.reload();
          return;
        }
        history(
          utm_source == "mentor_profile_page"
            ? "/refill-credits"
            : "/userdashboard"
        );
        window.location.reload();
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) { }
  };

  //for Facebook submit
  const handleFacebookSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}/oauth/facebook/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname: credentials.fname,
          lname: credentials.lname,
          phone: credentials.phone,
          dest: credentials.dest,
          email: credentials.email,
          facebookId: facebookId,
          countryCode: credentials.countryCode.value,
          ref_id: credentials.ref_id,
        }),
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
      });
      const json = await response.json();

      if (json.success === true) {
        swal({
          title: "Success!",
          text: "Account Created Successfully",
          icon: "success",
          button: "Ok!",
        });
        await localStorage.setItem("token", json.authToken);
        if (searchParams.get("utm_source") == "public") {
          history("/" + localStorage.getItem("redirect_to").split("-")[1]);
          window.location.reload();
          return;
        }
        history(
          utm_source == "mentor_profile_page"
            ? "/refill-credits"
            : "/userdashboard"
        );
        window.location.reload();
      } else {
        swal({
          title: "Try Again!",
          text: json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (err) {
      swal({
        title: "Try Again!",
        text: "server routing error!",
        icon: "error",
        button: "Ok!",
      });
    }
  };

  //for sending OTP
  const sendMail = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await API("post", `${url}/auth/signup/email`, {
          email: credentials.email,
        });
        const json = await response.data;

        if (json.success === true) {
          swal({
            title: "Verification Code Sent!",
            text: json?.message,
            icon: "success",
            button: "Ok!",
          });
          setSendOtp(true);
        } else {
          swal({
            title: "Try Again!",
            text: json?.message[0].msg,
            icon: "error",
            button: "Ok!",
          });
          setSendOtp(false);
        }
      } catch (err) { }
    }
  };

  const validateMail = () => {
    let errors = {};
    let isValid = true;

    if (!credentials.authcode) {
      errors.authcode = "authcode is required";
      isValid = false;
    } else if (credentials.authcode?.length !== 6) {
      errors.authcode = "authcode must be of 6 characters";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!credentials.fname) {
      errors.fname = "First Name is required";
      isValid = false;
    } else if (credentials.fname?.length < 2) {
      errors.fname = "Name length atleast 2 char";
      isValid = false;
    }

    if (!credentials.lname) {
      errors.lname = "Last Name is required";
      isValid = false;
    } else if (credentials.lname?.length < 2) {
      errors.lname = "Name length atleast 2 char";
      isValid = false;
    }

    if (!credentials.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    if (!credentials?.countryCode) {
      errors.countryCode = "Country Code is required";
      isValid = false;
    }

    if (!credentials.phone) {
      errors.phone = "Phone is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(credentials.phone)) {
      errors.phone = "Invalid phone number";
      isValid = false;
    }
    if (!credentials.dest) {
      errors.dest = "Destination Name is required";
      isValid = false;
    } else if (credentials.dest?.length < 1) {
      errors.dest = "Destination Name length atleast 1 char";
      isValid = false;
    }

    if (!credentials.skip_course) {
      if (credentials.course_of_interest.length === 0) {
        errors.course_of_interest = "Course of Interest is required";
        isValid = false;
      }
      if (!credentials.subject) {
        errors.subject = "Subject is required when Course of Interest is selected";
        isValid = false;
      }
      if (!credentials.level) {
        errors.level = "Level is required when Course of Interest is selected";
        isValid = false;
      }
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&*!]{8,}$/;

    if (!credentials.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (!passwordRegex.test(credentials.password)) {
      errors.password = "Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, and one digit";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  // //for varification code entry by box to box tried

  // const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
  // const inputRefs = useRef([]);

  // const handleInputChange = (index, event) => {
  //   const newValues = [...inputValues];
  //   newValues[index] = event.target.value;
  //   setInputValues(newValues);

  //   // focus on the next input when a value is entered
  //   if (event.target.value && index < inputValues.length - 1) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // };

  // const handleInputKeyDown = (index, event) => {
  //   // move focus to previous input when backspace is pressed
  //   if (event.key === 'Backspace' && !inputValues[index] && index > 0) {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };

  // const renderInputs = () => {
  //   const inputs = [];
  //   for (let i = 0; i < inputValues.length; i++) {
  //     inputs.push(
  //       <input
  //         key={i}
  //         ref={el => inputRefs.current[i] = el}
  //         className="form-control verification-input"
  //         type="Number"
  //         maxLength={1}
  //         value={inputValues[i]}
  //         onChange={event => handleInputChange(i, event)}
  //         onKeyDown={event => handleInputKeyDown(i, event)}
  //       />
  //     );
  //   }
  //   return inputs;
  // };

  function calculatePasswordStrength(password) {
    let strength = 0;
    if (password.length >= 8) {
      strength += 20;
    } else if (password.length >= 6) {
      strength += 10;
    }
    if (/[A-Z]/.test(password)) {
      strength += 20;
    }
    if (/[a-z]/.test(password)) {
      strength += 20;
    }
    if (/\d/.test(password)) {
      strength += 20;
    }
    if (/[^a-zA-Z0-9]/.test(password)) {
      strength += 20;
    }

    return strength;
  }

  return (
    <div className="container-fluid d-flex px-0">
      <section className="left-panel">
        <TestimonialSlider />
      </section>
      <section className="right-panel">
        <div className="text-center">
          <div className="logo mt-4 pt-4">
            <img
              src={require("../Assets/Media/Images/logo.png")}
              className="img-fluid logo-md"
              alt="Rise Higher Education"
              title="Rise Higher Education"
            />
          </div>
          <div className="tag-line">Empower Your Education Dreams</div>
        </div>
        <div className="main-heading">Register</div>
        <div className="regular-text">
          Thank you for choosing to register with us!
          <br />
          {sendOtp === false
            ? "Please fill out the following form to create your account"
            : "Mail with verification code send to your email-ID"}
        </div>
        <div className="sep" />
        <div className="page-form">
          {signUpReq === true ? (
            <form onSubmit={handleGoogleSubmit}>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <Select
                      className="app-multiSelect"
                      value={credentials.countryCode}
                      options={country_codes.map((op, i) => ({
                        value: op.dial_code,
                        label: op.dial_code + " " + op.name,
                      }))}
                      onChange={(selectedOptions) => {
                        //console.log('Select', selectedOptions)
                        setCredentials((prevData) => ({
                          ...prevData,
                          countryCode: selectedOptions,
                        }));
                      }}
                      placeholder="Country Code"
                    // isOptionDisabled={() => credentials?.dest?.length >= 3}
                    />
                    {/* <select
                      list="countryCodeData"
                      value={credentials.countryCode}
                      onChange={onChange}
                      placeholder="choose.."
                      className="form-select"
                      name="countryCode"
                    >
                      <option value={""}>Please select</option>
                      {country_codes.map((op, i) => (
                        <option value={op.dial_code}>
                          {op.dial_code + " " + op.name}
                        </option>
                      ))}
                    </select> */}
                    {errors.countryCode && (
                      <span className="required">
                        {errors.countryCode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputState" className="form-label">
                  Study Destination<span className="required">*</span>
                </label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={credentials.dest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={countryCodes.map((op, i) => ({
                    value: op.name,
                    label: op.name,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setCredentials((prevData) => ({
                      ...prevData,
                      dest: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                  isOptionDisabled={() => credentials?.dest?.length >= 3}
                />
                {/* <select
                  list="data"
                  value={credentials.dest}
                  onChange={onChange}
                  placeholder="choose.."
                  className="form-select"
                  name="dest"
                >
                  {errors.dest && (
                    <span className="required">
                      {errors.dest}
                    </span>
                  )}
                  {list.map((op, i) => (
                    <option>{op.name}</option>
                  ))}
                </select> */}
              </div>
              <div className="form-group">
                <div className="pt-3" />
                <div className="form-button">
                  <button
                    disabled={credentials.phone?.length < 9}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Create Account
                  </button>
                </div>
              </div>
            </form>
          ) : signUpFbReq === true ? (
            <form onSubmit={handleFacebookSubmit}>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <Select
                      className="app-multiSelect"
                      value={credentials.countryCode}
                      options={country_codes.map((op, i) => ({
                        value: op.dial_code,
                        label: op.dial_code + " " + op.name,
                      }))}
                      onChange={(selectedOptions) => {
                        setCredentials((prevData) => ({
                          ...prevData,
                          countryCode: selectedOptions,
                        }));
                      }}
                      placeholder="Country Code"
                    // isOptionDisabled={() => credentials?.dest?.length >= 3}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Email address<span className="required">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={credentials.email}
                  onChange={onChange}
                  name="email"
                />
                {errors.email && (
                  <span className="required">
                    {errors.email}
                  </span>
                )}
              </div> */}
              <div className="form-group">
                <label htmlFor="inputState" className="form-label">
                  Study Destination<span className="required">*</span>
                </label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={credentials.dest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={countryCodes.map((op, i) => ({
                    value: op.name,
                    label: op.name,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setCredentials((prevData) => ({
                      ...prevData,
                      dest: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                  isOptionDisabled={() => credentials?.dest?.length >= 3}
                />
                {errors.dest && (
                  <span className="required">
                    {errors.dest}
                  </span>
                )}
              </div>
              <div className="form-group">
                <div className="pt-3" />
                <div className="form-button">
                  <button type="submit" className="btn btn-primary">
                    Create Account
                  </button>
                </div>
              </div>
            </form>
          ) : sendOtp === false ? (
            <form onSubmit={sendMail}>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="exampleInputEmail1">
                      First Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      placeholder="First name"
                      aria-label="First name"
                      value={credentials.fname}
                      onChange={onChange}
                      name="fname"
                    />
                    {errors.fname && (
                      <span className="required">
                        {errors.fname}
                      </span>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="exampleInputEmail1">
                      Last Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      placeholder="Last name"
                      aria-label="Last name"
                      value={credentials.lname}
                      onChange={onChange}
                      name="lname"
                    />
                    {errors.lname && (
                      <span className="required">
                        {errors.lname}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col countryCode">
                  <div className="form-group">
                    <label htmlFor="inputState" className="form-label mb-0">
                      Country Code<span className="required">*</span>
                    </label>
                    <Select
                      value={credentials.countryCode}
                      className="app-multiSelect"
                      options={country_codes.map((op, i) => ({
                        value: op.dial_code,
                        label: op.dial_code + " " + op.name,
                      }))}
                      onChange={(selectedOptions) => {
                        setCredentials((prevData) => ({
                          ...prevData,
                          countryCode: selectedOptions,
                        }));
                      }}
                      placeholder="Country Code"
                    // isOptionDisabled={() => credentials?.dest?.length >= 3}
                    />
                    {errors?.countryCode && (
                      <span className="required">
                        {errors?.countryCode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Phone<span className="required">*</span>
                    </label>
                    <input
                      type="Number"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      id="exampleInputPhone"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      value={credentials.phone}
                      onChange={onChange}
                      name="phone"
                    />
                    {errors.phone && (
                      <span className="required">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputState" className="form-label">
                  Study Destination<span className="required">*</span>
                </label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  value={credentials.dest?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={countryCodes.map((op, i) => ({
                    value: op.name,
                    label: op.name,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setCredentials((prevData) => ({
                      ...prevData,
                      dest: newData,
                    }));
                  }}
                  placeholder="Study Destination"
                  isOptionDisabled={() => credentials?.dest?.length >= 3}
                />
                {/* <select
                  list="data1"
                  value={credentials.dest}
                  onChange={onChange}
                  placeholder="choose.."
                  className="form-select"
                  name="dest"
                >
                 
                  {list.map((op, i) => (
                    <option>{op.name}</option>
                  ))}
                </select> */}
                {errors.dest && (
                  <span className="required">
                    {errors.dest}
                  </span>
                )}
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="exampleInputEmail1">
                      Email address<span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      value={credentials.email}
                      onChange={onChange}
                      name="email"
                    />
                    {errors.email && (
                      <span className="required">
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="exampleInputPassword1">
                      Password<span className="required">*</span>
                      <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, and one digit</Tooltip>}>
                          <i className="fa fa-md fa-info-circle"></i>
                      </OverlayTrigger>
                    </label>
                    <input
                      type="password"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={credentials.password}
                      onChange={onChange}
                      name="password"
                    />
                    {errors.password && (
                      <span className="required">
                        {errors.password}
                      </span>
                    )}
                    <button
                      id="toggle-password"
                      type="button"
                      className="d-none"
                      aria-label="Show password as plain text. Warning: this will display your password on the screen."
                    />
                    {/* Password Strength */}
                    {/* <br />
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${calculatePasswordStrength(
                            credentials.password
                          )}%`,
                        }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div> */}
                  </div>
                </div>
                <br />
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="d-flex justify-content-between"
                      >
                        <span>Subject & Course of Interest<span className="required">*</span></span>
                        <span className="d-flex justify-content-center align-items-center">
                          {" "}
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setCredentials({
                                ...credentials,
                                skip_course: e.target.checked,
                              })
                            }
                          />{" "}
                          &nbsp; I'm not sure
                        </span>
                      </label>
                      <div>
                        <select
                          disabled={credentials.skip_course}
                          className="form-select mb-3"
                          name="subject"
                          value={credentials.subject}
                          onChange={onChange}
                          placeholder="Select Subject"
                        >
                          <option value="">Select Subject</option>
                          {Object?.keys(courseData).map((idx) => (
                            <option key={idx}>{idx}</option>
                          ))}
                        </select>
                        <Select
                          isDisabled={credentials.skip_course}
                          className="app-multiSelect"
                          isMulti
                          value={credentials.course_of_interest.map((value) => ({
                            value,
                            label: value,
                          }))}
                          options={
                            course.length === 0
                              ? [
                                {
                                  label: "Select Subject to get courses",
                                  value: "",
                                },
                              ]
                              : course.map((op, i) => ({
                                value: op.name,
                                label: op.name,
                              }))
                          }
                          onChange={(selectedOptions) => {
                            const newData = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setCredentials((prevData) => ({
                              ...prevData,
                              course_of_interest: newData,
                            }));
                          }}
                          
                          placeholder="Course of Interest"
                          isOptionDisabled={() => {
                            if (course.length === 0) return 1;
                            else
                              return credentials.course_of_interest.length >= 5;
                          }}
                        />
                        
                      </div>
                      {errors.course_of_interest && (
                          <span className="required">
                            {errors.course_of_interest}
                          </span>
                        )}
                    </div>{" "}
                  </div>
                  <br />

                  <select
                    className="form-select"
                    name="level"
                    value={credentials.level}
                    onChange={onChange}
                    placeholder="Select level"
                    disabled={credentials.skip_course}
                  >
                    <option value="Select Level">Select Level</option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                  </select>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="NotesInput">Notes</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="NotesInput"
                        aria-describedby="emailHelp"
                        placeholder="Please let us know your requirements (Optional)"
                        value={credentials.note}
                        onChange={onChange}
                        name="note"
                        rows={4}
                      />
                    </div>
                  </div>{" "}
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="NotesInput">Referral Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="NotesInput"
                        aria-describedby="emailHelp"
                        placeholder="Referee's Code (Optional)"
                        value={credentials.ref_id}
                        onChange={onChange}
                        name="ref_id"
                        rows={4}
                      />
                    </div>
                  </div>{" "}
                </div>

                <div className="pt-3" />
                <div className="form-button">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      credentials.email.length < 5 ||
                      credentials.password.length < 3 ||
                      credentials.phone.length < 9
                    }
                  >
                    Send Verification Code
                  </button>
                </div>
                <div className="small-text pt-3 pb-3 text-center">
                  Or continue with
                </div>
                <div className="social-buttons d-flex justify-content-between">
                  <button className="social-icon" id="googlebtn">
                    <i className="fa-brands fa-google fa-lg" />
                  </button>
                  {/* <OAuth2Login
                    className="social-icon"
                    authorizationUrl="https://www.facebook.com/v12.0/dialog/oauth"
                    responseType="token"
                    clientId="1773525229728727"
                    redirectUri="https://risehigher.education/"
                    scope="public_profile"
                    onSuccess={handleFacebookResponse}
                  >
                    <i className="fa-brands fa-facebook fa-lg" />
                  </OAuth2Login> */}
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Verification Code<span className="required">*</span>
                </label>
                <input
                  type="Number"
                  className="form-control"
                  id="exampleInputauthcode1"
                  aria-describedby="authcodeHelp"
                  placeholder="Enter authcode"
                  value={credentials.authcode}
                  onChange={onChange}
                  name="authcode"
                />
                {errors.authcode && (
                  <span className="required">
                    {errors.authcode}
                  </span>
                )}

                {/* <div>{renderInputs()}</div> */}
              </div>
              <div className="pt-3" />
              <div className="form-button">
                <button type="submit" className="btn btn-primary">
                  Verify OTP
                </button>
              </div>
              <div className="pt-3" />
            </form>
          )}
        </div>
        <div className="regular-text text-center mb-4">
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </section>
    </div>
  );
};

export default Signup;
