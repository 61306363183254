import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { url } from "../Constants";

import Img from "../Assets/Media/Images/icon-guide.png";

const FileCard = (props) => {
  const [loading, setLoading] = useState(false);
  const maxSize = 5242880; // 5MB
  const extentions = ["pdf", "jpeg", "jpg", "doc", "docx", "png"];

  const handleFileUpload = async (documentId, event) => {
    event.preventDefault();
    setLoading(true);
    const file = event.target.files[0];

    if (!file) {
      setLoading(false);
      return;
    }

    if (file.size === 0 || file.size > maxSize) {
      swal({
        title: "Error!",
        text: "Invalid file size",
        icon: "error",
        button: "Ok!",
      });
      setLoading(false);
      event.target.value = "";
      return;
    }

    const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);

    if (
      (await extentions.find((item) => item === fileExtension)) === undefined
    ) {
      swal({
        title: "Error!",
        text: "Invalid file extention",
        icon: "error",
        button: "Ok!",
      });
      setLoading(false);
      event.target.value = "";
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentId", documentId);

    const response = await fetch(`${url}/user/files/new`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      body: formData,
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const data = await response.json();

    if (data.success) {
      swal({
        title: "Success!",
        text: "Uploaded Successfully",
        icon: "success",
        button: "Ok!",
      });

      await props?.dataHandler();
      setLoading(false);
    } else {
      swal({
        title: "Error!",
        text: data.message,
        icon: "error",
        button: "Ok!",
      });
      setLoading(false);
    }
    setLoading(false);
    // clear input
    event.target.value = "";
  };

  const handleDelete = async (fileId, documentId) => {
    const response = await fetch(`${url}/user/files/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      body: JSON.stringify({
        documentId: documentId,
        fileId: fileId,
      }),
    });

    const data = await response.json();
    // console.log(data)

    if (data.success) {
      swal({
        title: "Success!",
        text: "File Deleted Successfully",
        icon: "success",
        button: "Ok!",
      });

      await props?.dataHandler();
    } else {
      swal({
        title: "Error!",
        text: data.message,
        icon: "error",
        button: "Ok!",
      });
    }
  };

  const [count, setCount] = useState([0, 0, 0]);

  const getCount = () => {
    let arr = [0, 0, 0];

    for (let index = 0; index < props.data.files.length; index++) {
      const element = props.data.files[index];
      if (element.status === "approved") arr[1]++;
      else if (element.status === "rejected") arr[2]++;
      else arr[0]++;
    }

    setCount(arr);
  };

  useEffect(() => {
    getCount();
  }, [props.data]);

  return (
    <>
      <tr>
        <td className={props.data.required ? "file-mandatory" : ""}>
          {props.data.name}
          {props.data.premiumServiceUrl && (
            <a
              href={props.data.premiumServiceUrl}
              className="widget-cta-page-nopos btn-request"
              target="_blank"
              rel="noopener noreferrer"
            >
              Request Service
            </a>
          )}
        </td>
        <td>
          <a
            href={props.data.sampleUrl}
            className="guide-link"
            title={`${props.data.name} Guide`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Img} />
          </a>
        </td>
        {/* <td>
          {loading ? (
            <span style={{ fontSize: "21px", color: "#DC7215 !important" }}>
              Uploading...{" "}
            </span>
          ) : (
            <input
              type="file"
              onChange={(event) => handleFileUpload(props.data.id, event)}
              accept=".pdf,.jpeg,.jpg,.doc,.docx,.png"
            />
          )}

          {props.data.files.length > 0 ? (
            <button
              className="accordion accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse_${props.index}`}
              aria-expanded="true"
              aria-controls={`#collapse_${props.index}`}
            />
          ) : (
            ""
          )}
          {props.data.files.length > 0 ? (
            <>
              {count[0] > 0 && (
                <span className="file-underreviewbg">{count[0]}</span>
              )}
              {count[1] > 0 && (
                <span className="file-approvedbg">{count[1]}</span>
              )}
              {count[2] > 0 && (
                <span className="file-rejectedbg">{count[2]}</span>
              )}
            </>
          ) : (
            ""
          )}
        </td> */}
        {/* {props.data.files.length > 0 ?
                    <>
                        {count[0] > 0 && <td>
                            {count[0]} Files under review
                        </td>}
                        {count[1] > 0 && <td>
                            {count[1]} Files approved
                        </td>}
                        {count[2] > 0 && <td>
                            {count[2]} Files rejected
                        </td>}
                    </> : ""} */}
      </tr>
      {props.data.files.length > 0 ? (
        <tr>
          <td colSpan={3} className="no-padding">
            <div className="accordion file-uploaded" id="accordionExample">
              <div
                id={`collapse_${props.index}`}
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {props.data.files.map((obj) => {
                    return (
                      <div className={`file-view file-${obj.status}`}>
                        {obj.originalName}
                        <ul className="file-controls">
                          {obj.status !== "rejected" ? (
                            <li>
                              <a
                                href={obj.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-download" />
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            <button
                              className="btn-nostyle"
                              onClick={() => {
                                swal({
                                  title: "Are you sure?",
                                  text: "Once deleted, you will not be able to recover this file",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    handleDelete(obj._id, props.data.id);
                                  }
                                });
                              }}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        ""
      )}
    </>
  );
};

export default FileCard;
