import React, { useState, useEffect, useContext } from "react";
import "../Assets/Styles/dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BsPencilSquare } from "react-icons/bs";
import { BsEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import { RiUserStarFill } from "react-icons/ri";
import { FaPassport, FaUserGraduate } from "react-icons/fa";
import { FaFileUpload } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { Nav, Pagination } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import NavBarAdmin from "./Nav/Nav";
import { url } from "../Constants";
import { Logincontext } from "../Admin/Contextprovider";
import Img from "../Assets/Media/Images/nodata.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function DashboardTestimonials() {
  const [data, setData] = useState([]);
  const [view, setView] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [showAllData, setShowAllData] = useState(true);
  const [showTestimonials, setShowTestimonials] = useState(false);
  const [showScholarship, setShowScholarship] = useState(false);
  const [scholarshipData, setScholarshipData] = useState([]);
  const [scholarshipArray, setScholarshipArray] = useState([]);
  const [scholarshipView, setScholarshipView] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTestimonial, setIsLoadingTestimonial] = useState(true);
  const navigate = useNavigate();

  const { account, setAccount } = useContext(Logincontext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if the user is an admin only the first time component mounts
    if (account && account.user && account.user.role === "Admin") {
      setIsAdmin(true);
    }

    // Check if the user is verified (has a token) each time component mounts
    const roleInLocalStorage = localStorage.getItem("role");
    if (roleInLocalStorage === "Admin") {
      // setIsVerified(true);
      setIsAdmin(true);
    }
  }, [account]);

  // fetch scholarship api with pagination //
  const [scholarshipSearch, setScholarshipSearch] = useState("");
  const [scholarships, setScholarships] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalScholarships: 0,
    pageCount: 0,
  });

  const fetchDataScholarship = async (page, pageSize) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${url}/searchAndPaginateScholarships?page=${page}&pageSize=${pageSize}&search=${scholarshipSearch}`
      );
      const data = await response.json();
      //console.log(data)
      setScholarships(data.scholarships);
      setPagination(data.pagination);
      //console.log(data.pagination)
      setIsLoading(false);
    } catch (error) {
      //console.error("Failed to fetch scholarships:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataScholarship(pagination.currentPage, pagination.pageSize);
  }, [pagination.currentPage, pagination.pageSize, scholarshipSearch]);

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        currentPage: prevPagination.currentPage - 1,
      }));
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.pageCount) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        currentPage: prevPagination.currentPage + 1,
      }));
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      currentPage: 1, // Reset current page when page size changes
    }));
  };

  /////// pagination end /////////

  // /////////// all user pagination
  const [searchUser, setSearchUser] = useState("");
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage, searchUser]);

  const fetchUsers = async (page) => {
    try {
      let fetch_url = `${url}/api/users?page=${page}&search=${searchUser}`;
      if (localStorage.getItem("role") === "Counsellor") {
        fetch_url += `&user_counsellor=${localStorage.getItem("_id")}`;
      }
      const response = await fetch(fetch_url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setUsers(data.users);
      setTotalPages(data.pagination.pageCount);

      // setShowAllData(true);
      // setShowTestimonials(false);
      // setShowScholarship(false);

      setIsLoadingUser(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ///////////// end

  useEffect(() => {
    // getData();
    // getScholarshipData()
  }, []);

  // to fetch scholarship data

  const getScholarshipData = async () => {
    const response = await fetch(`${url}/getScholarship`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });

    const responseData = await response.json();

    const filterScolarshipData = responseData.slice(0, 30);
    setScholarshipData(filterScolarshipData);
    setIsLoading(false);
  };

  // Show a Yes/No dialog on before Delete

  const showDeleteScholarshipModal = (id) => {
    //console.log(id)
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
        deleteScholarship(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  const deleteScholarship = async (id) => {
    // console.log(id)
    try {
      const res = await fetch(`${url}/deleteScholarship/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();

      swal({
        title: "Success",
        text: "Data Deleted Successfully!",
        icon: "success",
        button: "Ok",
      });
      fetchDataScholarship(1, 10);
    } catch (error) {
      //console.log(error);
    }
  };

  const viewScholarship = async (id) => {
    //console.log(id)
    try {
      const res = await fetch(`${url}/view/scholarship/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();

      setScholarshipView(jsonData);
    } catch (error) {
      //console.log(error);
    }
  };

  const userhandleClick = (id) => {
    //console.log(id)
    viewData(id);
    getOneUserDetails(id);
    // handleViewButtonClick();
  };

  // fetch all users data

  const getData = async () => {
    try {
      const res = await fetch(`${url}/allusers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setData(jsonData);

      // console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch all users details

  // // //

  const [userDetailData, setUserDetailData] = useState([]);

  const combinedData = users.concat(userDetailData);

  const getUserDetails = async () => {
    try {
      const res = await fetch(`${url}/allUserDetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setUserDetailData(jsonData);
      //console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  const getOneUserDetails = async (id) => {
    try {
      const res = await fetch(`${url}/alldetails/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setUserDetails(jsonData);
      // setIsLoadingUser(false)
      //console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  // //////////// for testimonial pagination

  const [testimonialSearch, setTestmonialSearch] = useState("");
  const [testimonialsPage, setTestimonialsPage] = useState([]);
  const [currentPageTest, setCurrentPageTest] = useState(1);
  const [totalPagesTest, setTotalPagesTest] = useState(1);

  useEffect(() => {
    fetchTestimonialsPage(currentPageTest);
  }, [currentPageTest, testimonialSearch]);

  const fetchTestimonialsPage = async (page) => {
    try {
      const response = await fetch(
        `${url}/api/testimonial?page=${page}&search=${testimonialSearch}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      //console.log(data)
      // console.log(data.testimonial)
      setTestimonialsPage(data.testimonial);
      setShowTestimonials(true);
      setTotalPagesTest(data.pagination.pageCount);
      setIsLoadingTestimonial(false);
      // setShowTestimonials(true);
      // setShowAllData(false)
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  const handlePageChangeTest = (page) => {
    setCurrentPageTest(page);
  };

  // //////////// end

  const deleteTestimonial = async (id) => {
    try {
      const res = await fetch(`${url}/deletetestimonial/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();
      // console.log(deleteData);
      swal({
        title: "Success",
        text: "Data Deleted Successfully!",
        icon: "success",
        button: "Ok",
      });
      fetchTestimonialsPage();
    } catch (error) {
      //console.log(error);
    }
  };

  // Show a Yes/No dialog on before Delete

  const showDeleteTestimonialModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
        deleteTestimonial(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  // testimonial soft delete code

  // const handleSoftDelete = async (id) => {
  //     try {
  //         const response = await fetch(`${url}/softDelete/${id}`, {
  //             method: 'PATCH',
  //             headers: {
  //                 "Accept": 'application/json',
  //                 'Content-Type': 'application/json'
  //             },
  //         });

  //         const deleteData = await response.json();
  //         console.log(deleteData);

  //         if (response.ok) {
  //             // Remove the soft-deleted scholarship from the state
  //             setTestimonials((prevTestimonial) =>
  //                 prevTestimonial.filter((e) => e._id !== id)
  //             );
  //         } else {
  //             console.log('Failed to soft delete testimonial.');
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  // Show a Yes/No dialog on before Delete

  const showDeleteUserModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Your imaginary file has been deleted Successfuly!", {
          icon: "success",
        });
        deleteUser(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  const deleteUser = async (id) => {
    try {
      const res = await fetch(`${url}/deleteuser/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();
      // console.log(deleteData);
      fetchUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  const handleLeadChange = async (event, _id) => {
    try {
      const res = await fetch(`${url}/user/lead-status`, {
        method: "PUT",
        body: JSON.stringify({ lead_status: event?.target.value, _id }),
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (res?.ok) {
        swal({
          title: "Success",
          text: "Lead Status Updated Successfully!",
          icon: "success",
          button: "Ok",
        });
      }
      const data = await res.json();
      fetchUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  const viewData = async (id) => {
    try {
      const res = await fetch(`${url}/getuser/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      const scholarshipArrayData = jsonData.scholarships;
      //console.log(jsonData)
      setScholarshipArray(scholarshipArrayData);
      setView(jsonData);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleShowAllData = () => {
    setShowAllData(true);
    setShowTestimonials(false);
    setShowScholarship(false);
  };

  const handleShowScholarship = () => {
    // getScholarshipData();
    setShowAllData(false);
    setShowTestimonials(false);
    setShowScholarship(true);
  };

  const sortData = (arr, property, reverse) => {
    let sorted = arr.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    if (reverse) {
      sorted.reverse();
    }
    setIsLoadingTestimonial(true);
    setTestimonialsPage(sorted);
    setTimeout(() => {
      setIsLoadingTestimonial(false);
    }, 200);
  };

  return (
    <div className="container">
      {/* The below code for dashboard main buttons Show all Data, Scholarship, Testimonial */}
      <NavBarAdmin
        fetchTestimonialsPage={fetchTestimonialsPage}
        getUserDetails={getUserDetails}
        handleShowAllData={handleShowAllData}
        handleShowScholarship={handleShowScholarship}
        scholarshipSearch={scholarshipSearch}
        searchUser={searchUser}
        setScholarshipSearch={setScholarshipSearch}
        setTestmonialSearch={setTestmonialSearch}
        setSearchUser={setSearchUser}
        showAllData={showAllData}
        showScholarship={showScholarship}
        showTestimonials={showTestimonials}
        testimonialSearch={testimonialSearch}
        key={"nav1"}
      />
      {/* main buttons code end here */}

      {/* This code is for fetch all data from get API's user, scholarship, testimonial */}

      <div className="container">
        <h1 className="page-title">Testimonials</h1>
        <div className="table-responsive grid-frame">
          <table className="table admin-table">
            <thead>
              <tr className="table-dark">
                <th scope="col">Id</th>
                <th scope="col">
                  Name{" "}
                  <i
                    className="fa-solid fa-caret-up"
                    onClick={() => sortData(testimonialsPage, "user_name")}
                  ></i>{" "}
                  <i
                    className="fa-solid fa-caret-down"
                    onClick={() =>
                      sortData(testimonialsPage, "user_name", true)
                    }
                  ></i>
                </th>
                <th scope="col">Rating</th>
                <th scope="col">Course Name</th>
                <th scope="col">University Name</th>
                <th scope="col">Testimonial</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingTestimonial ? (
                <div className="circle">
                  <CircularProgress className="circleHight" />
                </div>
              ) : testimonialsPage.length === 0 ? (
                <>
                  <img src={Img} className="noData" alt="nodata" />
                  <p className="required p-2">No Data Found</p>
                </>
              ) : (
                testimonialsPage.map((item, id) => (
                  <tr key={item.id}>
                    <td>
                      <b>{(currentPageTest - 1) * 10 + (id + 1)}</b>
                    </td>
                    <td>{item.user_name}</td>
                    <td>{item.user_rating}</td>
                    <td>{item.course_name}</td>
                    <td>{item.university_name}</td>
                    <td>{item.user_testimonial}</td>

                    <td>
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-gear"></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={<Tooltip id="tooltip">Edit</Tooltip>}
                            >
                              <Link
                                to={`/dashboard/testimonialEdit/${item._id}`}
                              >
                                <button className="btn testimonialEditBtn">
                                  <BsPencilSquare className="BsPencilSquare" />{" "}
                                  Edit
                                </button>
                              </Link>
                            </OverlayTrigger>
                          </li>
                          {isAdmin ? (
                            <li>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                              >
                                <button
                                  onClick={() =>
                                    showDeleteTestimonialModal(item._id)
                                  }
                                  className="btn testimonialDeleteBtn"
                                >
                                  <MdDelete className="MdDelete" /> Delete
                                </button>
                              </OverlayTrigger>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/*  */}
        {testimonialsPage.length > 0 ? (
          <Pagination className="d-flex justify-content-end mb-5">
            <Pagination.Prev
              disabled={currentPageTest === 1}
              onClick={() => handlePageChangeTest(currentPageTest - 1)}
            />
            {[...Array(totalPagesTest).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPageTest}
                onClick={() => handlePageChangeTest(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPageTest === totalPagesTest}
              onClick={() => handlePageChangeTest(currentPageTest + 1)}
            />
          </Pagination>
        ) : null}
      </div>

      {/* end here */}

      {/* view Modal for user data  */}
      <div
        className="modal fade"
        id="userViewModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {view && (
              <div className="modal-body ">
                <table className="table modalView">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colspan="2">
                        Overview
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {view.firstName} {view.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>
                        {userDetails &&
                        userDetails.data &&
                        userDetails.data.gender
                          ? userDetails.data.gender
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{view.email}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{view.phone}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>
                        {userDetails && userDetails.data && userDetails.data.dob
                          ? new Date(userDetails.data.dob).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Education</td>
                      <td>
                        {userDetails &&
                        userDetails.data &&
                        userDetails.data.education
                          ? userDetails.data.education
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Study Destination</td>
                      <td>{view.studyDest}</td>
                    </tr>
                    <tr>
                      <td>Scholarship</td>
                      <td>
                        {scholarshipArray.map((e, id) => {
                          return (
                            <>
                              <p>
                                <b>{id + 1}.</b> {e.category} - <b>Id</b>.{" "}
                                {e.id}
                              </p>
                            </>
                          );
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>
                        {userDetails &&
                        userDetails.data &&
                        userDetails.data.location
                          ? userDetails.data.location
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Work Experience</td>
                      <td>
                        {userDetails &&
                        userDetails.data &&
                        userDetails.data.workexp
                          ? userDetails.data.workexp
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for scholarship view data */}

      <div
        className="modal fade"
        id="scholarshipViewModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {scholarshipView && (
              <div className="modal-body ">
                <table className="table modalView">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colspan="2">
                        Overview
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Scholarship Name</td>
                      <td>{scholarshipView.Scholarship_Name}</td>
                    </tr>
                    <tr>
                      <td>Application End Date</td>
                      <td>{scholarshipView.Application_End_Date}</td>
                    </tr>
                    <tr>
                      <td>Awarded for</td>
                      <td>{scholarshipView.Applicable_for}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>{scholarshipView.Amount}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{scholarshipView.State}</td>
                    </tr>
                    <tr>
                      <td>University</td>
                      <td>{scholarshipView.University}</td>
                    </tr>
                    <tr>
                      <td>Type of Scholarship</td>
                      <td>{scholarshipView.Type_of_Scholarship}</td>
                    </tr>
                    <tr>
                      <td>Awarding Body</td>
                      <td>{scholarshipView.Awarding_Body}</td>
                    </tr>
                    <tr>
                      <td>Weblink</td>
                      <td>
                        <a
                          href={scholarshipView.Weblink}
                          target="_blank"
                          className=""
                          rel="noreferrer"
                        >
                          Details
                        </a>
                        <i className="ms-1 fa-solid fa-xs fa-arrow-up-right-from-square"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTestimonials;
