import React from "react";
import { NavLink } from "react-router-dom";
import { url } from "../Constants";
import swal from "sweetalert";


const ListItem = (props) => {

    const isValidImageUrl = (url) => {
        const imageUrlRegex = /\.(jpeg|jpg|gif|png|bmp)$/i;
        return imageUrlRegex.test(url);
    }

    const {
        institution_name,
        institution_logo,
        type,
        co_applicant,
        roi,
        tenure,
        complete_moratorium,
        turn_around_time,
        collateral_security,
        loan_amount_limit,
        unsecured_limit_start,
        unsecured_limit_end,
        conession_of_women,
        _id
    } = props.data;


    return (
        <li className="main-list">
            <ul
                className={
                    props.mode === "list" ? "list-detail list" : "list-detail grid"
                }
            >
                <li className="list-width-15 loan-name">
                    {props.mode === "grid" ? (
                        <div className="uni-logo loan-logo">
                            <img src={institution_logo !== undefined && isValidImageUrl(institution_logo) ? institution_logo : require("../Assets/Media/Images/bank_logo.jpg")} alt="img" />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="uni-name">{institution_name}</div>
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Type
                            <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {type}
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Co-applicant
                            <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {co_applicant}
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            ROI <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {roi}
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Tenure <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {tenure}
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Complete Moratorium <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {complete_moratorium}
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Turn round Time <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {turn_around_time}
                </li>
                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Collateral Security <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {collateral_security}
                </li>
                {props.collateral === true ? <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Loan Amount Limit <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {new Intl.NumberFormat('en-IN').format(loan_amount_limit)}
                </li> : ""}

                {props.collateral === false ? (<>
                    <li className="list-width-10">
                        {props.mode === "grid" ? (
                            <span className="list-text-label">
                                Unsecured Limit Start <br />
                            </span>
                        ) : (
                            ""
                        )}
                        {new Intl.NumberFormat('en-IN').format(unsecured_limit_start)}
                    </li>
                    <li className="list-width-10">
                        {props.mode === "grid" ? (
                            <span className="list-text-label">
                                Unsecured Limit End <br />
                            </span>
                        ) : (
                            ""
                        )}
                        {new Intl.NumberFormat('en-IN').format(unsecured_limit_end)}
                    </li></>) : ""}

                <li className="list-width-10">
                    {props.mode === "grid" ? (
                        <span className="list-text-label">
                            Conession of Women <br />
                        </span>
                    ) : (
                        ""
                    )}
                    {conession_of_women}
                </li>
                <li className="list-width-10">
                    <button className="btn btn-primary" onClick={() => { props.sendMail(_id) }} target="_blank" >
                        Enquire <i className="ms-1 fa-solid fa-xs fa-arrow-up-right-from-square"></i>
                    </button>
                </li>
            </ul>
        </li>
    );
};

export default ListItem;
