import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { url } from "../Constants";
import API from "../../Api/Api";

import Select from "react-select";
import countryStates from "../Assets/Data/state.json";
import countryUni from "../Assets/Data/university.json";
import countryRace from "../Assets/Data/raceethnicity.json";

function EditScholarship() {
  // const [scholarship, setScholarship] = useState([])
  const [data, setData] = useState({
    Scholarship_Name: "",
    Destination_Country: "",
    Applicable_for: "",
    Amount: "",
    Awarding_Body: "",
    Program_Level: "",
    Study_Area: "",
    State: "",
    Application_Start_Date: "",
    Type_of_Scholarship: "",
    Eligible_Citizenship: "",
    Application_End_Date: "",
    University: "",
    Application_Fee: "",
    Application_Link: "",
    Description: "",
    Requirement: "",
    Academic_Eligibility: "",
    Work_Exp: "",
    Race_Ethnicity: "",
    Sex: "",
    Number_of_Awards: "",
    Category: "",
    Contact_Name: "",
    Contact_ID: "",
    Contact_Number: "",
    Image_Address: "",
    Weblink: "",
  });
  const [stateOptions, setStateOptions] = useState([]);

  let countryOptions = countryStates?.map((getcountry) => ({
    value: getcountry.country_name,
    label: getcountry.country_name,
  }));

  useEffect(() => {
    if (data?.Destination_Country) {
      setData({ ...data, State: "", University: "", Race_Ethnicity: "" });
      setStateOptions(
        countryStates
          .find((itm) => itm.country_name == data.Destination_Country)
          ?.states?.map((itm) => {
            return { value: itm?.state_name, label: itm?.state_name };
          })
      );
    }
  }, [data?.Destination_Country]);

  let universityOptions =
    countryUni[data?.Destination_Country] !== undefined
      ? countryUni[data?.Destination_Country].map((getstate, index) => ({
          value: getstate,
          label: getstate,
        }))
      : [];

  let raceEthOptions =
    countryRace[data?.Destination_Country] !== undefined
      ? countryRace[data?.Destination_Country].map((getstate) => ({
          label: getstate,
          value: getstate,
        }))
      : [];

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData((newData) => {
      return {
        ...newData,
        [name]: value,
      };
    });
  };

  // create function for API call

  const { id } = useParams("");

  const getData = async () => {
    const res = await fetch(`${url}/view/scholarship/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const newData = await res.json();
    setData(newData);
    //console.log(newData)
  };

  useEffect(() => {
    getData();
  }, []);

  //  create function for updatation.

  const updateData = async (e) => {
    e.preventDefault();
    const {
      Scholarship_Name,
      Destination_Country,
      Applicable_for,
      Amount,
      Awarding_Body,
      Program_Level,
      Study_Area,
      State,
      Application_Start_Date,
      Type_of_Scholarship,
      Eligible_Citizenship,
      Application_End_Date,
      University,
      Application_Fee,
      Application_Link,
      Description,
      Requirement,
      Academic_Eligibility,
      Work_Exp,
      Race_Ethnicity,
      Sex,
      Number_of_Awards,
      Category,
      Contact_Name,
      Contact_ID,
      Contact_Number,
      Image_Address,
      Weblink,
    } = data;

    try {
      const res = await API("patch", `/editscholarship/${id}`, {
        Scholarship_Name,
        Destination_Country,
        Applicable_for,
        Amount,
        Awarding_Body,
        Program_Level,
        Study_Area,
        State,
        Application_Start_Date,
        Type_of_Scholarship,
        Eligible_Citizenship,
        Application_End_Date,
        University,
        Application_Fee,
        Application_Link,
        Description,
        Requirement,
        Academic_Eligibility,
        Work_Exp,
        Race_Ethnicity,
        Sex,
        Number_of_Awards,
        Category,
        Contact_Name,
        Contact_ID,
        Contact_Number,
        Image_Address,
        Weblink,
      });

      const finalPost = res.data;
      //console.log(finalPost)
      if(res?.success){
        swal({
          title: "Success",
          text: "Data Updated Successfully!",
          icon: "success",
          button: "Ok",
        });
      }
    } catch (error) {}
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  return (
    <div className="container admin-createpage">
      <div className="main-heading"> Edit Scholarship</div>

      <div>
        <div className="sep" />
        <div className="page-form">
          <div className="row">
            <div className="col-3">
              <label className="form-label">Scholarship Name<span className="required">*</span></label>
              <input
                value={data.Scholarship_Name}
                onChange={onChangeData}
                name="Scholarship_Name"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Awarding Body<span className="required">*</span></label>
              <input
                value={data.Awarding_Body}
                onChange={onChangeData}
                name="Awarding_Body"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Type of Scholarship</label>
              <input
                value={data.Type_of_Scholarship}
                onChange={onChangeData}
                name="Type_of_Scholarship"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">University</label>
              <Select
                value={{ value: data.University, label: data.University }}
                options={universityOptions}
                onChange={(selectedOption) => {
                  setData({ ...data, University: selectedOption.value });
                }}
              />
            </div>
            <div className="col-3">
              <label className="form-label">Weblink</label>
              <input
                value={data.Weblink}
                onChange={onChangeData}
                name="Weblink"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Destination Country<span className="required">*</span></label>
              <Select
                value={{value: data.Destination_Country, label: data.Destination_Country}}
                options={countryOptions}
                onChange={(selectedOption) => {
                  setData({
                    ...data,
                    Destination_Country: selectedOption.value,
                  });
                }}
              />
              {/* <input
                value={data.Destination_Country}
                onChange={onChangeData}
                name="Destination_Country"
                type="text"
                className="form-control"
              /> */}
            </div>
            <div className="col-3">
              <label className="form-label">Program Level<span className="required">*</span></label>
              <input
                value={data.Program_Level}
                onChange={onChangeData}
                name="Program_Level"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Study Area</label>
              <input
                value={data.Study_Area}
                onChange={onChangeData}
                name="Study_Area"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Eligible Citizenship</label>
              <Select
                value={{ value: data.Eligible_Citizenship, label: data.Eligible_Citizenship }}
                options={countryOptions}
                onChange={(selectedOption) => {
                  setData({ ...data, Eligible_Citizenship: selectedOption.value });
                }}
              />
            </div>
            <div className="col-3">
              <label className="form-label">State</label>
              <Select
                value={{ value: data.State, label: data.State }}
                options={stateOptions}
                onChange={(selectedOption) => {
                  setData({ ...data, State: selectedOption.value });
                }}
              />
            </div>
            <div className="col-3">
              <label className="form-label">Amount</label>
              <input
                value={data.Amount}
                onChange={onChangeData}
                name="Amount"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Applicable for</label>
              <input
                value={data.Applicable_for}
                onChange={onChangeData}
                name="Applicable_for"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application Start Date</label>
              <input
                value={data.Application_Start_Date}
                onChange={onChangeData}
                name="Application_Start_Date"
                type="date"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application End Date</label>
              <input
                value={data.Application_End_Date}
                onChange={onChangeData}
                name="Application_End_Date"
                type="date"
                className="form-control"
                min={
                  !isNaN(new Date(data?.Application_Start_Date))
                    ? new Date(data?.Application_Start_Date)
                        .toISOString()
                        ?.split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application Fee</label>
              <input
                value={data.Application_Fee}
                onChange={onChangeData}
                name="Application_Fee"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Application Link<span className="required">*</span></label>
              <input
                value={data.Application_Link}
                onChange={onChangeData}
                name="Application_Link"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Description</label>
              <input
                value={data.Description}
                onChange={onChangeData}
                name="Description"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Requirement</label>
              <input
                value={data.Requirement}
                onChange={onChangeData}
                name="Requirement"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Academic Eligibility</label>
              <input
                value={data.Academic_Eligibility}
                onChange={onChangeData}
                name="Academic_Eligibility"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Work Exp</label>
              <input
                value={data.Work_Exp}
                onChange={onChangeData}
                name="Work_Exp"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Sex</label>
              <input
                value={data.Sex}
                onChange={onChangeData}
                name="Sex"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Race Ethnicity</label>
              <Select
                value={{
                  value: data.Race_Ethnicity,
                  label: data.Race_Ethnicity,
                }}
                options={raceEthOptions}
                onChange={(selectedOption) => {
                  setData({ ...data, Race_Ethnicity: selectedOption.value });
                }}
              />
            </div>
            <div className="col-3">
              <label className="form-label">Number of Awards</label>
              <input
                value={data.Number_of_Awards}
                onChange={onChangeData}
                name="Number_of_Awards"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Category</label>
              <input
                value={data.Category}
                onChange={onChangeData}
                name="Category"
                type="text"
                className="form-control"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Contact Name</label>
              <input
                value={data.Contact_Name}
                onChange={onChangeData}
                name="Contact_Name"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Contact ID</label>
              <input
                value={data.Contact_ID}
                onChange={onChangeData}
                name="Contact_ID"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Contact Number</label>
              <input
                value={data.Contact_Number}
                onChange={onChangeData}
                name="Contact_Number"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Image Address</label>
              <input
                value={data.Image_Address}
                onChange={onChangeData}
                name="Image_Address"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <br />
          <button
            type="submit"
            onClick={updateData}
            className="btn btn-primary-regular me-3"
          >
            Update
          </button>
          <button
            type="submit"
            onClick={backButton}
            className="btn btn-secondary-regular"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditScholarship;
