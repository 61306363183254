import React, { useEffect, useState } from 'react'
import Home from './Home'
import Modal from 'react-bootstrap/Modal'
import Thumb from './Thumb'
import Slider from 'react-slick'
import countryData from '../Assets/Data/countries.json'
import courseData from '../Assets/Data/coursecounseling.json'
import CountryCard from './CountryCard'
import { useAudio } from '../../Context/AudioContext'
import GPT from './GPT'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Autosuggest from 'react-autosuggest'
import swal from 'sweetalert'

import CourseMain from './Modals/CourserMain'
import CountryHelp from './Modals/CountryHelp'
import CourseHelp from './Modals/CourseHelp'
import CountryMain from './Modals/CountryMain'
import LevelModal from './Modals/LevelModal'
import CountryPriroty from './Modals/CountryPriroty'
import PleaseLoginModal from '../PleaseLoginModal'
import { useTrialTimer } from '../useTimer'

const Main = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const urlModal = searchParams.get('modal')
  const {
    country,
    setCountry,
    thumbData,
    setScript,
    maxLimit,
    setThumbData,
  } = useAudio()
  const [show, setShow] = useState(false)
  const [modals, setModals] = useState({
    countryMain: false,
    needHelpCountry: false,
    courseMain: false,
    needHelpCourrse: false,
    countryScore: false,
    login: false,
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [subject, setSubject] = useState('Agriculture')
  const [currCourse, setCurrCourse] = useState('')
  const [course, setCourse] = useState([])
  const [ug, setUg] = useState('null')

  const handleChange = (e) => {
    setSubject(e.target.value)
  }

  const initialModalOpen = () => {
    switch (urlModal) {
      case 'country-main':
        setModals({
          countryMain: true,
          needHelpCountry: false,
          courseMain: false,
          needHelpCourrse: false,
        })
        break
      case 'course-main':
        setModals({
          countryMain: false,
          needHelpCountry: false,
          needHelpCourrse: false,
          courseMain: true,
        })
        break
      default:
        setModals({
          countryMain: false,
          needHelpCountry: false,
          courseMain: false,
          needHelpCourrse: false,
        })
        setShow(true)
        break
    }
  }
  useEffect(() => {
    if (modals.login) {
      setShow(false)
    }
  }, [modals.login])

  useEffect(() => {
    initialModalOpen()
  }, [])

  useEffect(() => {
    setCourse(courseData[subject])
  }, [subject])

  const addCourse = (item) => {
    //console.log('Here')
    // item['ug'] = ug
    //console.log(item)
    const duplicate = thumbData.some((obj) => obj?.name === item?.name)
    item['ug'] = ug
    if (duplicate === false && thumbData?.length < 5) {
      setThumbData([...thumbData, item])
    }
  }

  const delCourse = (item) => {
    const updatedThumbData = thumbData.filter((obj) => obj.name !== item.name)
    setThumbData(updatedThumbData)
  }

  const handleCourseChange = (e) => {
    if (thumbData?.length >= 5) {
      swal({
        title: 'Error!',
        text: 'You can only select 5 courses.',
        icon: 'error',
        button: 'Ok',
      })
      return
    }
    const item = course.find((item) => item.name === e.target.value)
    addCourse(item)
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: (
      <div className="slick-prev">
        <div>
          <i className="fa fa-angle-left"></i>
        </div>
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <div>
          <i className="fa fa-angle-right"></i>
        </div>
      </div>
    ),
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const [searchTerms, setSearchTerms] = useState(['', '', ''])
  const [suggestions, setSuggestions] = useState([])
  const [searchResults, setSearchResults] = useState({})

  const getSuggestions = (value) => {
    if (value !== undefined && value.trim() !== '') {
      const inputValue = value.trim().toLowerCase()
      const allJobs = Object.values(courseData).flatMap((category) =>
        category.flatMap((subCategory) => subCategory.jobRoles),
      )

      // Check for exact matches first
      const exactMatches = allJobs.filter(
        (job) => job.toLowerCase() === inputValue,
      )

      // If no exact matches, look for partial matches (case-insensitive)
      const partialMatches = allJobs.filter((job) =>
        job.toLowerCase().includes(inputValue),
      )

      return exactMatches.length > 0 ? exactMatches : partialMatches
    }
    return []
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionSelected = (event, { suggestionValue }) => {
    const updatedTerms = [...searchTerms]
    const selectedInputIndex = searchTerms.findIndex(
      (term) => term === suggestionValue,
    )

    if (selectedInputIndex !== -1) {
      // If the suggestion is already in one of the input fields, don't populate it again
      return
    }

    const emptyIndex = updatedTerms.findIndex((term) => term === '')
    if (emptyIndex !== -1) {
      updatedTerms[emptyIndex] = suggestionValue
      setSearchTerms(updatedTerms)
    }
  }

  const inputProps = (index) => ({
    placeholder: `Search term ${index + 1}`,
    value: searchTerms[index],
    onChange: (e, { newValue }) => {
      const updatedTerms = [...searchTerms]
      updatedTerms[index] = newValue
      setSearchTerms(updatedTerms)
    },
  })

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>

  const handleSearch = () => {
    // Remove empty search terms
    const nonEmptySearchTerms = searchTerms.filter(
      (term) => term !== undefined && term.trim() !== '',
    )

    if (nonEmptySearchTerms.length > 0) {
      // Remove duplicates
      const uniqueSearchTerms = [...new Set(nonEmptySearchTerms)]

      // Your search logic
      const filteredData = Object?.keys(courseData).reduce((acc, category) => {
        const filteredSubCategories = courseData[category].filter(
          (subCategory) => {
            return uniqueSearchTerms.some((term) =>
              subCategory.jobRoles.some((role) =>
                role.toLowerCase().includes(term.toLowerCase()),
              ),
            )
          },
        )

        if (filteredSubCategories.length > 0) {
          acc[category] = filteredSubCategories
        }

        return acc
      }, {})

      setSearchResults(filteredData)
    } else {
      // Handle empty search
      alert('Please enter at least one search term')
    }
  }

  const [expanded, setExpanded] = useState(true)
  const handleToggle = () => {
    setExpanded(!expanded)
  }
  const [showLevelModal, setShowLevelModal] = useState(false)
  // useEffect(() => {
  //   console.log(thumbData)
  // }, [thumbData])

  useEffect(() => {
    if (
      searchParams.get('auto') == 'true' &&
      searchParams.get('mode') == 'course'
    ) {
      handleClose()
      handleClose()
      setCountry(false)
      let courses = JSON.parse(localStorage.getItem('courses'))
      // console.log(courses)
      if (courses?.length === 0) {
        setShow(true)
        setCountry(false)
        courses = []
        return
      }
      let searchArr = []
      Object.values(courseData).forEach((arr) => {
        arr.forEach((obj) => {
          if (courses.includes(obj.name)) {
            if (!searchArr.map((itm) => itm.name).includes(obj.name)) {
              searchArr.push({
                ...obj,
                ug:
                  localStorage.getItem('level').toLowerCase() == 'ug'
                    ? true
                    : false,
              })
            }
          }
        })
      })
      // setScript(searchArr[0])
      setShowLevelModal(true)
      // console.log(searchArr)
      setTimeout(() => {
        setThumbData(searchArr)
      }, 500)
    }

    if (
      searchParams.get('auto') == 'true' &&
      searchParams.get('mode') == 'country'
    ) {
      handleClose()
      handleClose()
      setCountry(true)
      let country = JSON.parse(localStorage.getItem('destination'))
      if (country?.length === 0) {
        setShow(true)
        setCountry(true)
        country = []
        return
      }
      let newArr = []
      countryData?.map((itm) => {
        if (country.includes(itm.name)) {
          newArr.push(itm)
        }
      })
      setTimeout(() => {
        setThumbData(newArr)
      }, 500)
    }
  }, [])

  const startCounselling = () => {
    if (country) {
      if (thumbData?.length < 1) {
        return true
      }
    }
    if (!country) {
      if (thumbData?.length < 1) {
        return true
      }
      if (ug === 'null' && typeof ug == 'string') {
        return true
      }
    }

    return false
  }

  //Timer Public
  function convertToSeconds(minutes, seconds) {
    return minutes * 60 + seconds
  }
  const [start, minutes, seconds, isRunning] = useTrialTimer(
    new Date().setSeconds(
      new Date().getSeconds() + Number(localStorage.getItem('seconds')),
    ),
    setModals,
  )
  useEffect(() => {
    if (localStorage.getItem('seconds') > 0) {
      start()
    }
    if (isRunning) {
      localStorage.setItem('seconds', convertToSeconds(minutes, seconds))
    }
  }, [seconds])

  return (
    <>
      <div className="body-content no-filterpanel public-view">
        <div className="body-panel">
          <div className="body-container">
            {/* Counseling Module */}
            <div className="row">
              <div className="col">
                <div className="page-content">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h1 className="mb-3">Counseling</h1>
                      <p>
                        Comprehensive Country and Course counseling - data
                        backed insights from industry experts and experience
                        counselors.
                      </p>
                    </div>
                  </div>
                  {/* Course Counseling */}
                  {/* <div>
                                <div>
                                {searchTerms.map((term, index) => (
                                <Autosuggest
                                key={index}
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionSelected={(event, { suggestionValue }) => onSuggestionSelected(event, { suggestionValue })}
                                getSuggestionValue={(suggestion) => suggestion}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps(index)}
                                />
                                ))}
                                </div>
                                <button onClick={handleSearch}>Search</button>

                                {Object.keys(searchResults).map((category, index) => (
                                <div key={index}>
                                <h3>{category}</h3>
                                <ul>
                                {searchResults[category].map((subCategory, subIndex) => (
                                <li key={subIndex}>{subCategory.name}</li>
                                ))}
                                </ul>
                                </div>
                                ))}
                                </div> */}
                  {/* Course Counseling */}

                  <div className="row responsive-col">
                    {/* <div className="text-end">
                      <button
                        className="btn btn-nostyle btn-chat"
                        onClick={handleToggle}
                      >
                        {expanded ? 'Expand Chat' : 'Collapse Chat'}
                      </button>
                    </div> */}
                    <div className={`col-${expanded ? 12 : 3} col`}>
                      <div className="video-frame">
                        <Home setModals={setModals} />
                      </div>
                    </div>
                    {/* <div className={`col-${expanded ? 3 : 9} col`}>
                      <div className="bot-frame">
                        <GPT />
                      </div>
                    </div> */}
                  </div>
                </div>
                <Modal
                  size="lg"
                  show={
                    modals.countryMain ||
                    modals.courseMain ||
                    modals.needHelpCountry ||
                    modals.needHelpCourrse ||
                    modals.countryScore ||
                    showLevelModal ||
                    modals.login
                  }
                  onHide={() => {
                    setModals({
                      countryMain: false,
                      needHelpCountry: false,
                      courseMain: false,
                      needHelpCourrse: false,
                    })
                  }}
                  backdrop="static"
                  keyboard={false}
                >
                  <div
                    className=""
                    id="countryCounselingModal"
                    tabIndex={-1}
                    aria-labelledby="countryCounselingModal"
                    aria-hidden="true"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                  >
                    {' '}
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            {(modals?.countryScore ||
                              modals?.needHelpCourrse ||
                              modals?.needHelpCountry) && (
                              <i
                                className="fa-solid fa-arrow-left pe-3 cursor-pointer"
                                onClick={() => {
                                  setModals({
                                    countryMain:
                                      modals.needHelpCountry ||
                                      modals.countryScore
                                        ? true
                                        : false,
                                    needHelpCountry: false,
                                    courseMain: modals.needHelpCourrse
                                      ? true
                                      : false,
                                    needHelpCourrse: false,
                                  })
                                  setShow(false)
                                  setThumbData([])
                                  // setCountry(prev =>!country)
                                }}
                              ></i>
                            )}
                            {modals?.login
                              ? 'You are not logged in'
                              : 'Let Us Assist You With Your Higher Education'}
                          </h1>
                        </div>
                        {showLevelModal && (
                          <LevelModal
                            setThumbData={setThumbData}
                            thumbData={thumbData}
                            setScript={setScript}
                            setShowLevelModal={setShowLevelModal}
                          />
                        )}
                        {modals.login && <PleaseLoginModal timer={true} />}

                        {modals.countryMain && (
                          <CountryMain
                            setModals={setModals}
                            setShow={setShow}
                            setCountry={setCountry}
                          />
                        )}
                        {modals.courseMain && (
                          <CourseMain
                            setModals={setModals}
                            setShow={setShow}
                            setCountry={setCountry}
                          />
                        )}
                        {modals.needHelpCountry && (
                          <CountryHelp
                            setModals={setModals}
                            setShow={setShow}
                            addCourse={addCourse}
                            delCourse={delCourse}
                            thumbData={thumbData}
                            setCountry={setCountry}
                            setScript={setScript}
                            setThumbData={setThumbData}
                          />
                        )}
                        {modals.needHelpCourrse && (
                          <CourseHelp
                            setModals={setModals}
                            setShow={setShow}
                            addCourse={addCourse}
                            delCourse={delCourse}
                            thumbData={thumbData}
                            setCountry={setCountry}
                            setScript={setScript}
                          />
                        )}
                        {modals.countryScore && (
                          <CountryPriroty
                            setModals={setModals}
                            setShow={setShow}
                            addCourse={addCourse}
                            delCourse={delCourse}
                            thumbData={thumbData}
                            setCountry={setCountry}
                            setScript={setScript}
                            setThumbData={setThumbData}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal
                  size="lg"
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <div
                    className=""
                    id="countryCounselingModal"
                    tabIndex={-1}
                    aria-labelledby="countryCounselingModal"
                    aria-hidden="true"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                  >
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            <i
                              className="fa-solid fa-arrow-left pe-3 cursor-pointer"
                              onClick={() => {
                                setModals({
                                  countryMain: !country ? false : true,
                                  needHelpCountry: false,
                                  courseMain: country ? false : true,
                                  needHelpCourrse: false,
                                })
                                setShow(false)
                                setThumbData([])
                                // setCountry(prev =>!country)
                              }}
                            ></i>
                            Let Us Assist You With Your Higher Education
                          </h1>
                          <p>
                            {country ? '(max 3 countries)' : '(max 5 courses)'}
                          </p>
                        </div>
                        <div className="modal-body">
                          {country ? (
                            <section className="timeline-carousel">
                              <div
                                className="timeline-carousel__item-wrapper"
                                data-js="timeline-carousel"
                              >
                                <Slider {...settings}>
                                  {countryData?.map((item, idx) => {
                                    return <CountryCard data={item} key={idx} />
                                  })}
                                </Slider>
                              </div>
                            </section>
                          ) : (
                            <>
                              <div className="form-group responsive-col">
                                <div className="row">
                                  <div className="col">
                                    <label htmlFor="">
                                      Level<span className="required">*</span>
                                    </label>
                                    <br />
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="options"
                                      id="option1"
                                      autoComplete="off"
                                      // defaultChecked=""
                                      defaultChecked={false}
                                      checked={
                                        ug !== 'null' && ug == true
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        setUg(true)
                                      }}
                                    />
                                    <label
                                      className="btn btn-secondary"
                                      htmlFor="option1"
                                    >
                                      <i className="fa-solid fa-user-graduate" />{' '}
                                      UG
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="options"
                                      id="option2"
                                      defaultChecked={false}
                                      autoComplete="off"
                                      checked={
                                        ug !== 'null' && ug !== true
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        setUg(false)
                                      }}
                                    />
                                    <label
                                      className="btn btn-secondary"
                                      htmlFor="option2"
                                    >
                                      <i className="fa-solid fa-user-graduate" />{' '}
                                      PG
                                    </label>
                                  </div>
                                  <div className="col">
                                    <label htmlFor="">Subject</label>
                                    <select
                                      id="inputState"
                                      className="form-select"
                                      value={subject}
                                      disabled={
                                        typeof ug == 'boolean' ? false : true
                                      }
                                      onChange={handleChange}
                                    >
                                      {Object?.keys(courseData).map((idx) => (
                                        <option key={idx}>{idx}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col">
                                    <label htmlFor="currCourseForm">
                                      Course
                                    </label>
                                    <select
                                      id="currCourseForm"
                                      name="currCourseForm"
                                      disabled={
                                        typeof ug == 'boolean' ? false : true
                                      }
                                      className="form-select"
                                      onChange={handleCourseChange}
                                    >
                                      <option>Choose</option>
                                      {course.map((item, idx) => {
                                        return <option>{item.name}</option>
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              {thumbData.map((item, idx) => {
                                return (
                                  <div className="course-info" key={idx}>
                                    <div className="d-flex justify-content-between">
                                      <p>
                                        <strong>{item.name}</strong>
                                      </p>
                                      <p>{item.tagline}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p>
                                        <strong>STEM: </strong>
                                        {item.stem} |{' '}
                                        <strong>
                                          Average Starting Salary:{' '}
                                        </strong>
                                        ${item.avgstartSalary}
                                      </p>
                                      <button
                                        className="btn btn-primary"
                                        style={{ maxWidth: 40 }}
                                        onClick={() => {
                                          delCourse(item)
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          )}
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                          <button
                            onClick={() => {
                              setModals({ login: true })
                            }}
                            className="widget-cta-page-nopos responsive-visible float-end mb-3"
                          >
                            <i className="fa fa-home"></i> Back to Dashboard
                          </button>
                          <button
                            type="button"
                            className="btn"
                            value={country}
                            onClick={() => {
                              if (localStorage.getItem('seconds') > 0) {
                                setCountry(!country)
                              } else {
                                setModals({ login: true })
                              }
                            }}
                          >
                            {country
                              ? 'Skip to Course Counseling'
                              : 'Back to Country Counselling'}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary-regular"
                            disabled={startCounselling()}
                            onClick={() => {
                              if (localStorage.getItem('seconds') > 0) {
                                setScript({ ...thumbData[0] })
                                handleClose()
                              } else {
                                setModals({ login: true })
                              }
                            }}
                          >
                            Start Counseling
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="row">
              <Thumb />
            </div>
            {/* Counseling Module */}
          </div>
        </div>
      </div>
      {/* <button
        onClick={() => {
          navigate('/CourseSelection')
        }}
        className="widget-cta-page cta-courseSelection"
      >
        Proceed to Shortlisting
      </button> */}
      <div className="footer-links">
        <button
          type="button"
          className="footer-btn"
          data-bs-toggle="modal"
          data-bs-target="#disclaimerModal"
        >
          Disclaimer
        </button>
        <button
          type="button"
          className="footer-btn"
          data-bs-toggle="modal"
          data-bs-target="#TnCModal"
        >
          Terms &amp; Conditions
        </button>
        <div
          className="modal fade"
          id="disclaimerModal"
          tabIndex={-1}
          aria-labelledby="disclaimerModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="disclaimerModal">
                  Disclaimer
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  The ScholaRISE Scholarship Portal ("Portal") is an online
                  platform designed to connect students with scholarship
                  opportunities. While we strive to provide accurate and
                  up-to-date information, we would like to clarify the following
                  points:
                </p>
                <ol>
                  <li>
                    <strong>Scholarship Outcome:</strong> The selection and
                    awarding of scholarships are at the sole discretion of the
                    scholarship providers. ScholaRISE does not have any control
                    over the outcome of scholarship applications. We serve as a
                    facilitator to help students find and apply for relevant
                    scholarships, but the final decision rests with the
                    scholarship provider.
                  </li>
                  <li>
                    <strong>No Charges for Access:</strong> ScholaRISE does not
                    charge students for accessing the Portal or using its
                    services. Our primary objective is to provide a free
                    resource to assist students in their scholarship search and
                    application process.
                  </li>
                  <li>
                    <strong>No Benefit from Scholarships:</strong> ScholaRISE
                    does not benefit financially from any scholarships awarded
                    to students through the Portal. We are committed to
                    providing unbiased and objective information to help
                    students access financial aid opportunities.
                  </li>
                  <li>
                    <strong>Scholarship Articulation:</strong> We have taken
                    utmost care to present scholarship details accurately and
                    comprehensively. However, it is important to note that
                    scholarship information, including eligibility criteria,
                    application deadlines, and award amounts, is subject to
                    change. We recommend that students verify the details
                    directly with the scholarship provider before applying.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="TnCModal"
          tabIndex={-1}
          aria-labelledby="TnCModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="TnCModal">
                  Terms &amp; Conditions
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  By accessing and using the ScholaRISE Scholarship Portal, you
                  agree to the following terms and conditions:
                </p>
                <ol>
                  <li>
                    <strong>Eligibility:</strong> The Portal is available to
                    students who meet the eligibility criteria specified by the
                    scholarship providers. It is your responsibility to ensure
                    that you meet the requirements before applying for any
                    scholarships listed on the Portal.
                  </li>
                  <li>
                    <strong>Accuracy of Information:</strong> While we strive to
                    provide accurate and up-to-date information, we cannot
                    guarantee the accuracy, completeness, or reliability of the
                    scholarship details. We encourage users to verify the
                    information directly with the scholarship provider.
                  </li>
                  <li>
                    <strong>Third-Pay Websites:</strong> The Portal may contain
                    links to third-party websites or resources. These links are
                    provided for your convenience, and we do not endorse or have
                    control over the content, policies, or practices of these
                    websites. Your use of third-party websites is at your own
                    risk.
                  </li>
                  <li>
                    <strong>Privacy:</strong> We respect your privacy and handle
                    your personal information in accordance with our Privacy
                    Policy. By using the Portal, you consent to the collection,
                    use, and disclosure of your information as described in the
                    Privacy Policy.
                  </li>
                  <li>
                    <strong>Intellectual Property:</strong> All content and
                    materials on the Portal, including text, graphics, logos,
                    and images, are protected by intellectual property laws. You
                    may use the information for personal, non-commercial
                    purposes only. Any unauthorized use or reproduction of the
                    content is strictly prohibited.
                  </li>
                  <li>
                    <strong>Limitation of Liability:</strong> ScholaRISE and its
                    affiliates shall not be liable for any direct, indirect,
                    incidental, consequential, or punitive damages arising out
                    of or in connection with your use of the Portal or reliance
                    on the scholarship information provided.
                  </li>
                </ol>
                <p>
                  These terms and conditions may be subject to change without
                  prior notice. It is your responsibility to review them
                  periodically for any updates.
                </p>
                <p>
                  If you have any questions or concerns regarding the Portal,
                  please contact our support team at{' '}
                  <a href="mailto:contact@risehighereducation.com">
                    contact@risehighereducation.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Main
