import React, { useEffect, useState } from "react";
import subscriptionData from "./Components/Assets/Data/subscription.json";
import API from "./Api/Api";
import { url } from "./Components/Constants"; // Import the url constant


const updateMinutes = async (min) => {
  try {
    await API("post", `/auth/update-min`, {
      min,
    });
  } catch (error) {
    console.error(error);
  }
};

const TimerComponent = ({ minutes }) => {
  const [timeLeft, setTimeLeft] = useState(minutes);
  const [isUser, setIsUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${url}/auth/verifyuser`, {
          method: "POST",
          mode: "cors",
          referrerPolicy: "origin-when-cross-origin",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
        });

        const json = await response.json();
        const role = localStorage.getItem("role");
        const subscriptionId = localStorage.getItem("subscription_id");
        const leadStatus = json?.data?.lead_status;

        //console.log("Minutes:", minutes);
        //console.log("Role:", role);
        //console.log("Subscription ID:", subscriptionId);
        //console.log("Lead Status:", leadStatus);

        const isPremiumLead =
          leadStatus === "registeredplatinum" ||
          leadStatus === "registeredelite" ||
          leadStatus === "registeredpremium" ||
          subscriptionId === "premium0plan" ||
          subscriptionId === "elite199plan" ||
          subscriptionId === "platinum399plan";

        const subscription = subscriptionData.find(
          (item) => item.id === subscriptionId
        );

        //console.log(subscription)

        const isBasicUser =
          role === "user" && !subscription && !isPremiumLead;

        //console.log("isBasicUser:", isBasicUser, "isPremiumLead:", isPremiumLead);

        setTimeLeft(minutes);
        setIsUser(isBasicUser);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [minutes]);

  useEffect(() => {
    let interval;
    if (isUser && minutes) {
      if (
        timeLeft === 0 &&
        !window.location.pathname.includes("subscription-buy")
      ) {
        finalFunction();
        return;
      }
      interval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeLeft, isUser, minutes]);

  useEffect(() => {
    if (timeLeft % 10 === 0 && timeLeft !== 0 && isUser && minutes) {
      everyThirtySecondsFunction();
    }
  }, [timeLeft, isUser, minutes]);

  const everyThirtySecondsFunction = () => {
    updateMinutes(Math.round(timeLeft));
    //console.log("Updating free access time minutes...");
  };

  const finalFunction = () => {
    updateMinutes(0);
    window.location.href = "/subscription-buy";
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  if (!isUser) {
    return null;
  }

  return (
    <div style={{ position: "absolute", zIndex: "1050" }}>
      <h2 className="d-flex basic-plan">
        You are on basic plan, your current access time remaining:&nbsp;<p className="time-limit">{formatTime(timeLeft)}</p>
      </h2>
    </div>
  );
};

export default TimerComponent;