import React, { useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const VoiceTranscript = ({ type, id, hanldeChange }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    hanldeChange(type, {
      id,
      answer: transcript,
    });
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  return (
    <div>
      <p>Microphone: {listening ? "on" : "off"}</p>
      <div className="d-flex" style={{ gap: "1rem" }}>
        {!listening ? (
          <button
            className="btn btn-primary"
            onClick={SpeechRecognition.startListening}
          >
            Start
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={SpeechRecognition.stopListening}
          >
            Stop
          </button>
        )}

        <button className="btn btn-primary" onClick={resetTranscript}>
          Reset
        </button>
      </div>

      {/* <p>{transcript}</p> */}
    </div>
  );
};
export default VoiceTranscript;
