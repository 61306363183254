import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import API from "../../../Api/Api";

const MentorChat = ({ socket, mentor, user }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [details, setDetails] = useState({});

  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (user) {
      socket.on("connect", () => {
        // console.log("Socekt connected : ", socket.id);
      });
      socket.emit("join-chat", {
        mentor: params.id,
        user: user,
      });

      socket.emit("get-message", {
        mentor: params.id,
        user: user,
      });

      socket.emit("get-details", {
        mentor: params.id,
        user: user,
      });

      socket.on("details", (data) => {
        setDetails(data);
      });

      socket.on("messages", (data) => {
        // console.log("messages", data);
        setMessages(data);
      });

      socket.on("message", (data) => {
        setMessages(data);
      });
    }
  }, [socket, user]);

  const markMessageAsRead = async (msg) => {
    try {
      const response = await API("post", `/mentor/read-notifications`, { msg });
    } catch {}
  };

  useEffect(() => {
    let msg = [];
    messages.map((item) => {
      if (
        item.read == false &&
        localStorage.getItem("mentorAccessToken") &&
        item.who == "user"
      ) {
        msg.push(item._id);
      } else if (
        item.read == false &&
        item.who == "mentor" &&
        localStorage.getItem("token")
      ) {
        msg.push(item._id);
      }
    });
    if (msg.length > 0) {
      markMessageAsRead(msg);
    }
  }, [messages]);

  //   let styleMe = {
  //     padding: "10px",
  //     borderRadius: "10px",
  //     background: "#fd7e14",
  //     color: "#fff",
  //   };

  let style = {
  };

  return (
    <div className="card p-3">
      <h2>
        <strong>
          <span>{details?.mentordata?.name}</span>{" "}and&nbsp;
          {localStorage.getItem("mentorAccessToken")
            ? details?.mentordata?.name
            : details?.userdata?.firstName + " " + details?.userdata?.lastName}
        </strong>
      </h2>
      <hr />
      <div
        className="d-flex flex-column chat-container"
        ref={chatContainerRef}
      >
        {messages.map((itm) => (
          <div
            key={itm?._id}
            className="chat-bubble"
            style={{
              ...style,
              background: itm?.who === "mentor" ? "#fff0ee" : "#ff7054",
              color: itm?.who === "mentor" ? "#000" : "#fff",
              textAlign: itm?.who === "mentor" ? "right" : "left",
            }}
          >
            {" "}
            <p>{itm?.text || 'no text'}</p>
            <span>
              Sent at:{" "}
              {moment(itm?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
        ))}
      </div>
      <div className="d-flex">
        <textarea
          onChange={(e) => setText(e.target.value)}
          value={text}
          className="form-control"
        />
          <button
            className="btn btn-primary-regular ms-2"
            onClick={() => {
              if (text) {
                socket.emit("send-message", {
                  mentor: params.id,
                  user: user,
                  text,
                  who: localStorage.getItem("mentorAccessToken")
                    ? "mentor"
                    : "user",
                });
                setText("");
              }
            }}
          >
            {" "}
            Send Message
          </button>
      </div>
    </div>
  );
};

export default MentorChat;
