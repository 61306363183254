import React, { useEffect, useState } from "react";

const Test = (props) => {
  const [originalData, setOriginalData] = useState({
    dna: false,
    gre: {
      score: "",
      date: "",
      quantitative: "",
      verbal: "",
      analytic: "",
    },
    gmat: {
      score: "",
      date: "",
      quantitative: "",
      verbal: "",
      analytic: "",
      reasoning: "",
    },
    toefl: {
      score: "",
      date: "",
      read: "",
      listen: "",
      speak: "",
      write: "",
    },
    ielts: {
      yetToRecieve: "",
      waiver: "",
      resultDate: "",
      examDate: "",
      trf: "",
      date: "",
      score: "",
      english: "",
      read: "",
      listen: "",
      speak: "",
      write: "",
    },
    pte: {
      score: "",
      date: "",
      read: "",
      listen: "",
      speak: "",
      write: "",
    },
    det: {
      score: "",
      date: "",
    },
    sat: {
      score: "",
      date: "",
      readWrite: "",
      math: "",
      essay: "",
    },
    act: {
      score: "",
      date: "",
      math: "",
      read: "",
      science: "",
      english: "",
      write: "",
    },
  });

  const [data, setData] = useState(originalData);

  useEffect(() => {
    if (props.data) {
        setData(props.data);
        setOriginalData(props.data); // Store the original data
    }
  }, [props.data]);

  // Reset data to originalData on Cancel button click
  const handleCancel = () => {
      setData(originalData); // Revert to original data
  };

  const onChange = (e) => {
    const target = e.target.name.split(".");

    if (target.length === 2) {
      setData({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <div
      className="tab-pane fade"
      id="v-pills-test"
      role="tabpanel"
      aria-labelledby="v-pills-test-tab"
    >
      <div className="row profile-section">
        <div className="col">
          <div className="section-head d-flex">
            Test Taken &nbsp;&nbsp;&nbsp; &nbsp;
            <span className="d-flex justify-content-center align-items-center">
              {" "}
              <input
                type="checkbox"
                checked={data?.dna}
                onChange={(e) =>
                  setData({
                    ...data,
                    dna: e.target.checked,
                  })
                }
              />{" "}
              &nbsp; Does not Apply
            </span>
          </div>
          <div className="form-group edit-form section-content">
            <div className="row">
              <div className="col">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample2"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        GRE
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gre.score"
                                value={data.gre?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gre.date"
                                value={data.gre?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gre.quantitative"
                                value={data.gre?.quantitative}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Quantitative</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gre.verbal"
                                value={data.gre?.verbal}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Verbal</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gre.analytic"
                                value={data.gre?.analytic}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Analytical Writing</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        GMAT
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gmat.score"
                                value={data.gmat?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gmat.date"
                                value={data.gmat?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gmat.quantitative"
                                value={data.gmat?.quantitative}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Quantitative</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gmat.verbal"
                                value={data.gmat?.verbal}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Verbal</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gmat.analytic"
                                value={data.gmat?.analytic}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Analytical Writing</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="gmat.reasoning"
                                value={data.gmat?.reasoning}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Integrated Reasoning</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseSeven"
                      >
                        TOEFL
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSeven"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="toefl.score"
                                value={data.toefl?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="toefl.date"
                                value={data.toefl?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="toefl.read"
                                value={data.toefl?.read}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Reading</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="toefl.listen"
                                value={data.toefl?.listen}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Listening</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="toefl.speak"
                                value={data.toefl?.speak}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Speaking</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="toefl.write"
                                value={data.toefl?.write}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Writing</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight"
                        aria-expanded="false"
                        aria-controls="flush-collapseEight"
                      >
                        IELTS
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingEight"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <label htmlFor="">
                              Yet to Recieve
                              <span className="required">*</span>
                            </label>
                            <br />
                            <input
                              type="radio"
                              className="btn-check"
                              name="ielts.yetToRecieve"
                              id="option51"
                              checked={data.ielts?.yetToRecieve === "yes"}
                              onClick={() => {
                                setData({
                                  ...data,
                                  ielts: { ...data.ielts, yetToRecieve: "yes" },
                                });
                              }}
                              autoComplete="off"
                              defaultChecked=""
                              disabled={data?.dna}
                            />
                            <label
                              className="btn btn-secondary"
                              htmlFor="option51"
                            >
                              Yes
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name=""
                              id="option52"
                              checked={data.ielts?.yetToRecieve === "no"}
                              onClick={() => {
                                setData({
                                  ...data,
                                  ielts: { ...data.ielts, yetToRecieve: "no" },
                                });
                              }}
                              autoComplete="off"
                              disabled={data?.dna}
                            />
                            <label
                              className="btn btn-secondary"
                              htmlFor="option52"
                            >
                              No
                            </label>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.resultDate"
                                value={data.ielts?.resultDate}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Test Result Date</label>
                            </div>
                          </div>
                          {data.ielts?.yetToRecieve === "yes" ? (
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  placeholder=""
                                  aria-label=""
                                  name="ielts.trf"
                                  value={data.ielts.trf}
                                  onChange={onChange}
                                  disabled={data?.dna}
                                />
                                <label htmlFor="">TRF No.</label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <label htmlFor="">
                              IELTS Waiver
                              <span className="required">*</span>
                            </label>
                            <br />
                            <input
                              type="radio"
                              className="btn-check"
                              name=""
                              id="option53"
                              autoComplete="off"
                              checked={data.ielts?.waiver === "yes"}
                              onClick={() => {
                                setData({
                                  ...data,
                                  ielts: { ...data.ielts, waiver: "yes" },
                                });
                              }}
                              defaultChecked=""
                              disabled={data?.dna}
                            />
                            <label
                              className="btn btn-secondary"
                              htmlFor="option53"
                            >
                              Yes
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name=""
                              id="option54"
                              checked={data.ielts?.waiver === "no"}
                              onClick={() => {
                                setData({
                                  ...data,
                                  ielts: { ...data.ielts, waiver: "no" },
                                });
                              }}
                              disabled={data?.dna}
                              autoComplete="off"
                            />
                            <label
                              className="btn btn-secondary"
                              htmlFor="option54"
                            >
                              No
                            </label>
                          </div>
                          {data.ielts?.waiver === "yes" ? (
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  placeholder=""
                                  aria-label=""
                                  name="ielts.english"
                                  value={data.ielts?.english}
                                  onChange={onChange}
                                  disabled={data?.dna}
                                />
                                <label htmlFor="">
                                  Enter English 12th Grade Marks
                                </label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data.ielts?.waiver === "yes" ? (
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="date"
                                  className="form-control"
                                  defaultValue=""
                                  placeholder=""
                                  aria-label=""
                                  name="ielts.examDate"
                                  value={data.ielts.examDate}
                                  onChange={onChange}
                                  disabled={data?.dna}
                                />
                                <label htmlFor="">Date of Examination</label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3 mt-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.score"
                                value={data.ielts?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3 mt-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.date"
                                value={data.ielts?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.listen"
                                value={data.ielts?.listen}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Listening</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.read"
                                value={data.ielts?.read}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Reading</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.write"
                                value={data.ielts?.write}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Writing</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="ielts.speak"
                                value={data.ielts?.speak}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Speaking</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine"
                        aria-expanded="false"
                        aria-controls="flush-collapseNine"
                      >
                        PTE
                      </button>
                    </h2>
                    <div
                      id="flush-collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingNine"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="pte.score"
                                value={data.pte?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="pte.date"
                                value={data.pte?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="pte.listen"
                                value={data.pte?.listen}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Listening</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="pte.read"
                                value={data.pte?.read}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Reading</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="pte.write"
                                value={data.pte?.write}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Writing</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="pte.speak"
                                value={data.pte?.speak}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Speaking</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTen"
                        aria-expanded="false"
                        aria-controls="flush-collapseTen"
                      >
                        DET
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTen"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="det.score"
                                value={data.det?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="det.date"
                                value={data.det?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEleven"
                        aria-expanded="false"
                        aria-controls="flush-collapseEleven"
                      >
                        SAT
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingEleven"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="sat.score"
                                value={data.sat?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="sat.date"
                                value={data.sat?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="sat.readWrite"
                                value={data.sat?.readWrite}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Reading &amp; Writing</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="sat.math"
                                value={data.sat?.math}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Math</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="sat.essay"
                                value={data.sat?.essay}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Essay</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwelve">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwelve"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwelve"
                      >
                        ACT
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwelve"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwelve"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.score"
                                value={data.act?.score}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Overall Score</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.date"
                                value={data.act?.date}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Date of Examination</label>
                            </div>
                          </div>
                        </div>
                        <div className="row responsive-col">
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.math"
                                value={data.act?.math}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Math</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.read"
                                value={data.act?.read}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Reading</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.science"
                                value={data.act?.science}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Science</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.english"
                                value={data.act?.english}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">English</label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder=""
                                aria-label=""
                                name="act.write"
                                value={data.act?.write}
                                onChange={onChange}
                                disabled={data?.dna}
                              />
                              <label htmlFor="">Writing</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-end action-btn-group">
                <button type="submit" className="btn btn-secondary" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-regular"
                  onClick={() => {
                    props.updateData("testTaken", data);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
