import React, { useEffect } from "react";

export const LinkedinCallback = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    localStorage.setItem("linkedinAuthCode", code);
    setTimeout(() => {
      window.close();
    });
  });
  return <div>Success!</div>;
};
