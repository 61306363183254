import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { url } from "../Constants";
import QRCode from 'react-qr-code';

const WhatsApp = () => {
  const [qrCode, setQrCode] = useState(null);
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [clientReady, setClientReady] = useState(false);
  const [pollingActive, setPollingActive] = useState(true); // Track polling state

  useEffect(() => {
    const fetchQrCode = async () => {
      try {
        const response = await axios.get(`${url}/whatsapp/qr`);
        if (response.data.success) {
          if (response.data.qrCode) {
            setQrCode(response.data.qrCode);
          } else if (response.data.message === "Client is ready. QR code is no longer needed.") {
            setClientReady(true);
            setPollingActive(false); // Stop polling when client is ready
          }
        }
      } catch (error) {
        console.error('Error fetching QR code:', error);
        setPollingActive(false); // Stop polling on error
      }
    };

    fetchQrCode();
    if (pollingActive) {
      const interval = setInterval(fetchQrCode, 5000);
      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }
  }, [pollingActive]);

  const handleSendMessage = async () => {
    setSending(true);
    const phoneNumbersArray = phoneNumbers.split(',').map(number => number.trim());

    try {
      const response = await axios.post(`${url}/whatsapp/send`, {
        phoneNumbers: phoneNumbersArray,
        message,
      });

      if (response.data.success) {
        setResponseMessage('Messages sent successfully!');
      } else {
        setResponseMessage('Failed to send messages.');
      }
    } catch (error) {
      setResponseMessage('Error sending messages.');
      console.error("Error sending messages: ", error);
    } finally {
      setSending(false);
    }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  return (
    <div className="container admin-createpage">
      <div className="main-heading">WhatsApp Messaging</div>
      <div className="sep" />
      <div className="page-form">
        <div className="row">
          <div className="col">
            <h2>Step 1:</h2>
            {!clientReady && qrCode ? (
              <QRCode value={qrCode} className="whatsapp-qr mb-3" />
            ) : clientReady ? (
              <p>Client is ready. QR code is no longer needed.</p>
            ) : (
              <p>Loading QR Code...</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2>Step 2: Send Messages</h2>
            <div className="form-group">
              <label>Phone Numbers (comma-separated with country code):</label>
              <textarea
                value={phoneNumbers}
                onChange={(e) => setPhoneNumbers(e.target.value)}
                placeholder="E.g. 12407284337, 12407284609, 919654293569"
                rows={3}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Message:</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here"
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group">
              <button onClick={handleSendMessage} className='btn btn-primary-regular' disabled={sending || !phoneNumbers || !message}>
                {sending ? 'Sending...' : 'Send Messages'}
              </button>
              <button
                type="submit"
                onClick={backButton}
                className="btn btn-secondary-regular"
              >
                Back
              </button>
              {responseMessage && <p>{responseMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsApp;