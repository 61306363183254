import React, { useEffect, useState } from "react";
import ProfilePhoto from "./Forms/ProfilePhoto";
import PersonalInfo from "./Forms/PersonalInfo";
import PastEdu from "./Forms/PastEdu";
import WorkExp from "./Forms/WorkExp";
import Test from "./Forms/Test";
import { url } from "../Constants";
import swal from "sweetalert";
import API from "../../Api/Api";
import "../Assets/Styles/user.css";
import "../Assets/Styles/responsive.css";
import { useNavigate } from 'react-router-dom';

const User = () => {
  const [data, setdata] = useState({});
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [profileComplete, setProfileComplete] = useState(0);
  const [sectionCompletion, setSectionCompletion] = useState({});
  const [updateWorkExp, setUpdateWorkExp] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => {
    setAdditionalDetails(e.target.value);
  };

  const ShowError = (text) => {
    swal({
      title: "Oops!",
      text: text,
      icon: "error",
    });
  };

  const updateData = async (feild, data, skip_work, action) => {
    try {
      const response = await API("post", `/user/update`, {
        feild,
        data,
        workDna: skip_work,
      });
      getData()
      setUpdateWorkExp(true);
      window.location.reload();
      // const json = response.data; response.data;
    } catch (error) {}
  };

  const getData = async () => {
    const response = await fetch(`${url}/user/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await response.json();
    if (json?.data?.additionalDetails !== undefined) {
      setAdditionalDetails(json?.data?.additionalDetails);
    }
    setdata(json.data);
  };

  const checkToken = async () => {
    const response = await fetch(`${url}/auth/verifyuser`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });

    const json = await response.json();
    setProfileComplete(json.profileComplete);
    setSectionCompletion(json.sectionCompletion);
  };

  useEffect(() => {
    setProfileComplete(localStorage.getItem("profileComplete"));
    getData();
    checkToken();
  }, []);

  const allSectionsComplete = Object.values(sectionCompletion).every(
    (status) => status === true
  );

  return (
    <div className="body-content">
      <div className="settings-container user-profile">
        <div className="d-flex justify-content-between align-items-top profilecomp-status">
          <h1>Profile</h1>{" "}
          <div className="dashboard-widget-frame dashboard-widget-frame-nozoom">
            <div className="">
              <p className="widget-title">Profile Completion</p>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${profileComplete}%` }}
                  aria-valuenow={profileComplete}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {profileComplete}%
                </div>
              </div>
              <br />
              <ul>
          <li>
            Personal Information:&nbsp; 
            {sectionCompletion.profile ? 'Completed ✅' : 'Incomplete ❌'}
          </li>
          <li>
            Past Education:&nbsp; 
            {sectionCompletion.education ? 'Completed ✅' : 'Incomplete ❌'}
          </li>
          <li>
            Test Taken:&nbsp; 
            {sectionCompletion.test_taken ? 'Completed ✅' : 'Incomplete ❌'}
          </li>
          <li>
            Work Experience:&nbsp; 
            {sectionCompletion.work_exp ? 'Completed ✅' : 'Incomplete ❌'}
          </li>
        </ul>
        {allSectionsComplete && (
          <button className="btn btn-primary-regular" onClick={() => navigate('/userdashboard')}>
            Proceed to next step
          </button>
        )}
            </div>
          </div>
        </div>
        <ProfilePhoto />
        <PersonalInfo updateData={updateData} data={data.userInfo} />
        {/* <BackgroundInfo updateData={updateData} data={data.backgroundInfo} /> */}
        {/* <PassportInfo updateData={updateData} data={data.passport} /> */}
        {/* <EmergencyContact updateData={updateData} data={data.emergencyContact} /> */}
        <PastEdu updateData={updateData} data={data.education} />
        <Test updateData={updateData} data={data.testTaken} />
        <WorkExp updateData={updateData} data={data.workexp} updateWorkExp={updateWorkExp} workDna={data?.workDna}/>
          {allSectionsComplete && (
            <button className="btn btn-primary-regular mt-3" onClick={() => navigate('/userdashboard')}>
              Proceed to next step
            </button>
          )}
        </div>
    </div>
  );
};

export default User;
