import React, { useEffect, useState } from "react";
import { url } from "../../Constants";
import swal from "sweetalert";

const ProfilePhoto = (props) => {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(true);

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", localStorage.getItem("_id"));

    const response = await fetch(`${url}/user/profile-picture`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      body: formData,
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const data = await response.json();

    if (data.success) {
      swal({
        title: "Success!",
        text: "Uploaded Successfully",
        icon: "success",
        button: "Ok!",
      }).then((res) => window.location.reload());
      setLoading(false);

      // await props?.dataHandler();
    } else {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      alert("Only JPG, PNG, and GIF files are allowed");
      setFile(null); // Reset the file if it's not valid
    } else {
      setFile(selectedFile); // Set the file if it's valid
    }
  };

  const handleRemove = async () => {
    setLoading(true);
   
    const response = await fetch(`${url}/user/remove-profile-picture`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      body: JSON.stringify({ id: localStorage.getItem("_id") }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const data = await response.json();

    if (data.success) {
      swal({
        title: "Success!",
        text: "Removed Successfully",
        icon: "success",
        button: "Ok!",
      }).then((res) => window.location.reload());
      setLoading(false);

      // await props?.dataHandler();
    } else {
      // swal({
      //   title: "Error!",
      //   text: data.message,
      //   icon: "error",
      //   button: "Ok!",
      // });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row profile-section">
        <div className="col">
          <div className="section-head d-flex flex-row justify-content-between">
            Profile Photo
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className={`bi ${editable ? "bi-x-square-fill" : "bi-pencil-square"} edit-btn`}
              viewBox="0 0 16 16"
              onClick={() => setEditable(!editable)}
            >
              {editable ? (
            <>
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </>
          ) : (
            <>
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
            </>
          )}
            </svg>
          </div>
          <div className="form-group section-content">
            <div className="row responsive-col">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <>
                      {loading ? (
                        <img
                          src={require("../../Assets/Media/Images/loading.gif")}
                          alt="profile-icon"
                          width={240}
                          height={240}
                          style={{ borderRadius: "50%", objectFit: "cover" }}
                        />
                      ) : (
                        <>
                          <div className="mb-2">
                            {localStorage?.getItem("profile_picture") &&
                            localStorage?.getItem("profile_picture") !==
                              "null" &&
                            localStorage?.getItem("profile_picture") !==
                              null ? (
                              <img
                                src={localStorage?.getItem("profile_picture")}
                                alt="profile-icon"
                                width={240}
                                height={240}
                                style={{
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div className="user-image-thumb">
                                <i className="fa fa-user"></i>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        placeholder=""
                        disabled={editable}
                        defaultValue=""
                        onChange={handleFileChange}
                        accept=".jpg,.png,.gif"
                        name="profilePhoto"
                        aria-label=""
                      />
                    </div>
                  </div>
                  <div className="col">
                    <button
                      type="submit"
                      disabled={editable || !file}
                      onClick={() => handleUpload()}
                      className="btn btn-primary-regular btn-sm"
                    >
                      Upload
                    </button>
                    &nbsp; &nbsp;
                    {
                      localStorage?.getItem("profile_picture") !== "null" && <button
                      type="submit"
                      disabled={editable}
                      onClick={() => handleRemove()}
                      className="btn btn-primary-regular btn-sm"
                    >
                      Remove
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
