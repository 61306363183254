/**
 * Validates lead form data.
 * @param {Object} data - The lead form data to validate.
 * @returns {Object} - Returns an object with `isValid` and `errors`.
 */

  /* Common Validations */
  const validateFirstName = (firstName) => {
  if (!firstName) {
    return "First Name is required";
  } else if (firstName.trim().length < 2) {
    return "First Name must be at least 2 characters long";
  }
  return null;
  }

  const validateLastName = (lastName) => {
    if(!lastName) {
      return "Last Name is required";
    } else if (lastName.trim().length <2) {
      return "Last Name must be at least 2 characters long";
    }
    return null;
  }

  const validateEmail = (email) => {
    if (!email) {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return "Invalid email format";
    }
    return null;
  }

  const validatePhone = (phone) => {
    if (!phone) {
      return "Phone number is required";
    } else if (!/^\d{10}$/.test(phone)) {
      return "Invalid phone number format";
    }
    return null;
  }

  const validateCountryCodes = (country_codes) => {
    if (!country_codes?.value) {
      return "Country Code is required";
    }
    return null;
  };

  const validateCountryOfInterest = (countryofinterest) => {
    if (!countryofinterest || countryofinterest.length === 0) {
      return "Please choose study destination";
    }
    return null;
  };

  /* Export Functions */
  export const validateMail = (credentials) => {
    let errors = {};
    let isValid = true;
  
    if (!credentials.authcode) {
      errors.authcode = "Auth code is required";
      isValid = false;
    } else if (credentials.authcode?.length !== 6) {
      errors.authcode = "Auth code must be 6 characters";
      isValid = false;
    }
  
    return { isValid, errors };
  };
  
  export const validateForm = (credentials) => {
    let errors = {};
    let isValid = true;
  
    if (!credentials.fname) {
      errors.fname = "First Name is required";
      isValid = false;
    } else if (credentials.fname?.length < 2) {
      errors.fname = "Name must be at least 2 characters";
      isValid = false;
    }
  
    if (!credentials.lname) {
      errors.lname = "Last Name is required";
      isValid = false;
    } else if (credentials.lname?.length < 2) {
      errors.lname = "Name must be at least 2 characters";
      isValid = false;
    }
  
    if (!credentials.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }
  
    if (!credentials?.countryCode) {
      errors.countryCode = "Country Code is required";
      isValid = false;
    }
  
    if (!credentials.phone) {
      errors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(credentials.phone)) {
      errors.phone = "Invalid phone number";
      isValid = false;
    }
  
    if (!credentials.dest) {
      errors.dest = "Destination is required";
      isValid = false;
    } else if (credentials.dest?.length < 1) {
      errors.dest = "Destination must be at least 1 character";
      isValid = false;
    }
  
    if (!credentials.skip_course) {
      if (credentials.course_of_interest.length === 0) {
        errors.course_of_interest = "Course of Interest is required";
        isValid = false;
      }
      if (!credentials.subject) {
        errors.subject = "Subject is required when Course of Interest is selected";
        isValid = false;
      }
      if (!credentials.level) {
        errors.level = "Level is required when Course of Interest is selected";
        isValid = false;
      }
    }
  
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
  
    if (!credentials.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (!passwordRegex.test(credentials.password)) {
      errors.password = "Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, and one digit";
      isValid = false;
    }
  
    return { isValid, errors };
  };

  export const validateLeadForm = (data) => {
    let errors = {};
    let isValid = true;

    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.email = validateEmail(data.email);
  
    if (!data.message) {
      errors.message = "Message is required";
      isValid = false;
    } else if (data.message.length < 10) {
      errors.message = "Message must be at least 10 characters long";
      isValid = false;
    }
  
    if (!data.hearaboutus) {
      errors.hearaboutus = "Please select how you heard about us";
      isValid = false;
    }

    return { isValid, errors };
  };

  export const validateProfileForm = (data) => {
    let errors = {};
    let isValid = true;
  
    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.email = validateEmail(data.email);
    errors.phone = validatePhone(data.phone);
    errors.country_codes = validateCountryCodes(data.country_codes);
    errors.countryofinterest = validateCountryOfInterest(data.countryofinterest);
  
    if (!data.courseofinterest) {
        errors.courseofinterest = "Course of Interest is required";
        isValid = false;
    }
    
    if (!data.level) {
        errors.level = "Please select valid level of study";
        isValid = false;
    }

    if (!data.hscgrade) {
        errors.hscgrade = "Pasisng score is required";
        isValid = false;
    }

    if (!data.hscyearpassing) {
        errors.hscyearpassing = "Pasisng year is required";
        isValid = false;
      } else if (data.hscyearpassing < 1900 || data.hscyearpassing > 2099) {
        errors.hscyearpassing = "Invalid year";
        isValid = false;
    }

    return { isValid, errors };
  };

  export const validateAssessment = (data) => {
    let errors = {};
    let isValid = true;
  
    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.email = validateEmail(data.email);
    errors.phone = validatePhone(data.phone);
  
    return { isValid, errors };
  };

  export const validateAffiliate = (data) => {
    let errors = {};
    let isValid = true;

    if (!data.fullName?.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    } else if (data.fullName.trim().length < 2) {
      errors.fullName = "Full Name must be at least 2 characters long";
      isValid = false;
    }

    errors.email = validateEmail(data.email);

    if (!data.countryCode) {
      errors.countryCode = "Country Code is required";
      isValid = false;
    }

    if (!data.mobile) {
      errors.mobile = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(data.mobile)) {
      errors.mobile = "Invalid phone number format";
      isValid = false;
    }
  
    return { isValid, errors };
  };

  export const validateAccommodation = (data) => {
    let errors = {};
    let isValid = true;
  
    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.email = validateEmail(data.email);
    errors.phone = validatePhone(data.phone);

    if (!data.msg) {
      errors.msg = "Message is required";
      isValid = false;
    } else if (data.msg.length < 10) {
      errors.msg = "Message must be at least 10 characters long";
      isValid = false;
    } else {
      delete errors.msg; 
    }
  
    return { isValid, errors };
  };

  export const validateLoan = (data) => {
    let errors = {};
    let isValid = true;

    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.email = validateEmail(data.email);
    errors.phone = validatePhone(data.phone);

    if (!data.pincode) {
      errors.pincode = "Permanent Pincode is required";
      isValid = false;
    }

    return {isValid, errors};
  };

  export const validateCredential = (data) => {
    let errors = {};
    let isValid = true;

    errors.email = validateEmail(data.email);

    if (!data.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (data.password?.length < 2) {
      errors.password = "Password must be at least 2 characters";
      isValid = false;
    }

    if (!data.url) {
      errors.url = "URL is required";
      isValid = false;
    } else if (!/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,63})([\/a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)?$/.test(data.url)) {
        errors.url = "Invalid URL format";
        isValid = false;
    } else {
        delete errors.url;
        delete errors.url;
    }

    return {isValid, errors};
  }

  export const validatePersonalInfo = (data) => {
    let errors = {};
    let isValid = true;

    errors.phone = validatePhone(data.phone);
    
    if (!data.dob || data.dob === "mm/dd/yyyy") {
      errors.dob = "Date of birth is required";
      isValid = false;
    } else {
      // Regular expression to ensure the date is in the format YYYY-MM-DD
      const regex = /^\d{4}-\d{2}-\d{2}$/;
    
      if (!regex.test(data.dob)) {
        errors.dob = "Invalid date format. Please use MM-DD-YYYY.";
        isValid = false;
      } else {
        const [year, month, day] = data.dob.split('-').map(Number);
        
        // Validate the extracted date components for correctness
        const isValidDate = (year > 1900 && month >= 1 && month <= 12 && day >= 1 && day <= 31);
    
        if (!isValidDate || isNaN(Date.parse(data.dob))) {
          errors.dob = "Invalid date. Please enter a valid date.";
          isValid = false;
        } else {
          // Check if the user is at least 15 years old
          const dob = new Date(data.dob);
          const today = new Date();
          const ageDiff = today.getFullYear() - dob.getFullYear();
          const monthDiff = today.getMonth() - dob.getMonth();
          const dayDiff = today.getDate() - dob.getDate();
    
          let age = ageDiff;
          if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
          }
    
          if (age < 15) {
            errors.dob = "You must be at least 15 years old.";
            isValid = false;
          }
        }
      }
    }

    if (!data.country_code || data.country_code === "Please Select") {
      errors.country_code = "Country Code is required";
      isValid = false;
    }

    return {isValid, errors};
  }

  export const validateReferralForm = (data) => {
    let errors = {};
    let isValid = true;
  
    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.email = validateEmail(data.email);
    errors.phone = validatePhone(data.phoneNumber);
    errors.countryCode = validateCountryCodes(data.country_codes);
  
    if (!data.referStudyDest || data.referStudyDest.length === 0) {
      errors.referStudyDest = "Please choose at least one study destination";
      isValid = false;
    }
  
    // Check if any of the errors are not null
    Object.values(errors).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
  
    return { isValid, errors };
  };
