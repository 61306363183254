import React, { useState, useEffect, useContext } from "react";
import "../Assets/Styles/dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BsPencilSquare } from "react-icons/bs";
import { BsEyeFill } from "react-icons/bs";
import {
  MdArchive,
  MdBook,
  MdDateRange,
  MdDelete,
  MdListAlt,
  MdMail,
} from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import { RiUserStarFill } from "react-icons/ri";
import { FaPassport, FaUserGraduate } from "react-icons/fa";
import { FaFileUpload } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { Button, Modal, Nav, Pagination } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import NavBarAdmin from "./Nav/Nav";
import { url } from "../Constants";
import { Logincontext } from "../Admin/Contextprovider";
import Img from "../Assets/Media/Images/nodata.png";
import API from "../../Api/Api";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "moment/moment";

function Dashboard() {
  const [editable, setEditable] = useState({
    lead: true,
    role: true,
  });
  const [data, setData] = useState([]);
  const [leadActive, setLeadActive] = useState("");
  const [view, setView] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [showAllData, setShowAllData] = useState(true);
  const [showTestimonials, setShowTestimonials] = useState(false);
  const [showScholarship, setShowScholarship] = useState(false);
  const [scholarshipData, setScholarshipData] = useState([]);
  const [scholarshipArray, setScholarshipArray] = useState([]);
  const [scholarshipView, setScholarshipView] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [activeUserLoading, setActiveUserLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTestimonial, setIsLoadingTestimonial] = useState(true);
  const [archive, setArchive] = useState("false");
  const navigate = useNavigate();
  const subscriptionId = localStorage.getItem("subscription_id");

  const [actionModal, setActionModal] = useState({
    notes: false,
    mail: false,
    email: "",
    user: "",
  });

  //console.log("Subscription ID:", subscriptionId);
  

  const [mail, setMail] = useState({
    subject: "",
    body: "",
  });

  const [notes, setNotes] = useState("");
  const [allNotes, setAllNotes] = useState({
    data: [],
    loading: false,
  });

  const [allMails, setAllMails] = useState({
    data: [],
    loading: false,
  });

  const { account, setAccount } = useContext(Logincontext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if the user is an admin only the first time component mounts
    if (account && account.user && account.user.role === "Admin") {
      setIsAdmin(true);
    }

    // Check if the user is verified (has a token) each time component mounts
    const roleInLocalStorage = localStorage.getItem("role");
    if (roleInLocalStorage === "Admin") {
      // setIsVerified(true);
      setIsAdmin(true);
    }
  }, [account]);

  // fetch scholarship api with pagination //
  const [scholarshipSearch, setScholarshipSearch] = useState("");
  const [scholarships, setScholarships] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalScholarships: 0,
    pageCount: 0,
  });

  const fetchDataScholarship = async (page, pageSize) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${url}/searchAndPaginateScholarships?page=${page}&pageSize=${pageSize}&search=${scholarshipSearch}`
      );
      const data = await response.json();
      //console.log(data)
      setScholarships(data.scholarships);
      setPagination(data.pagination);
      //console.log(data.pagination)
      setIsLoading(false);
    } catch (error) {
      //console.error("Failed to fetch scholarships:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataScholarship(pagination.currentPage, pagination.pageSize);
  }, [pagination.currentPage, pagination.pageSize, scholarshipSearch]);

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        currentPage: prevPagination.currentPage - 1,
      }));
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.pageCount) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        currentPage: prevPagination.currentPage + 1,
      }));
    }
  };


  const getLeadStatusDisplay = () => {
    if (subscriptionId === "platinum399plan") {
      return "Platinum";
    } else if (subscriptionId === "elite199plan") {
      return "Elite";
    } else if (subscriptionId === "premium0plan") {
      return "Premium";
    } else {
      return "Basic";
    }
  }

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      currentPage: 1, // Reset current page when page size changes
    }));
  };

  /////// pagination end /////////

  // /////////// all user pagination
  const [searchUser, setSearchUser] = useState("");
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);

  useEffect(() => {
    if (archive == "true") {
      setCurrentPage(1);
      fetchUsers(1);
    } else {
      fetchUsers(currentPage);
    }
  }, [currentPage, searchUser, archive, leadActive]);

  useEffect(() => {
    fetchActiveUsers();
  }, []);

  const fetchActiveUsers = async () => {
    setActiveUserLoading(true);
    try {
      let fetch_url = `${url}/api/active-users`;
      if (localStorage.getItem("role") === "Counsellor") {
        fetch_url += `&user_counsellor=${localStorage.getItem("_id")}`;
      }
      const response = await fetch(fetch_url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setActiveUsers(data?.activeUsers);
    } catch (error) {
    } finally {
      setActiveUserLoading(false);
    }
  };
  const fetchUsers = async (page) => {
    setIsLoadingUser(true);
    try {
      let fetch_url = `${url}/api/users?page=${page}&search=${searchUser}&archive=${archive}&lead_status=${leadActive}`;
      if (localStorage.getItem("role") === "Counsellor") {
        fetch_url += `&user_counsellor=${localStorage.getItem("_id")}`;
      }
      const response = await fetch(fetch_url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setUsers(data.users);
      setTotalPages(data.pagination.pageCount);
      // setActiveUserLoading(false);
      setShowAllData(true);
      setShowTestimonials(false);
      setShowScholarship(false);

      setIsLoadingUser(false);
    } catch (error) {
      // setActiveUserLoading(false);
      console.error("Error fetching users:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ///////////// end

  useEffect(() => {
    // getData();
    // getScholarshipData()
  }, []);

  // to fetch scholarship data

  const getScholarshipData = async () => {
    const response = await fetch(`${url}/getScholarship`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });

    const responseData = await response.json();

    const filterScolarshipData = responseData.slice(0, 30);
    setScholarshipData(filterScolarshipData);
    setIsLoading(false);
  };

  const getSubscriptionHistory = async () => {
    const response = await fetch(`${url}/subscription/user/subscription-history`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });

    const responseData = await response.json();
    //console.log("Response data:", responseData);
  };
  
  getSubscriptionHistory();
  

  // Show a Yes/No dialog on before Delete

  const showDeleteScholarshipModal = (id) => {
    //console.log(id)
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
        deleteScholarship(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  const deleteScholarship = async (id) => {
    // console.log(id)
    try {
      const res = await fetch(`${url}/deleteScholarship/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();

      swal({
        title: "Success",
        text: "Data Deleted Successfully!",
        icon: "success",
        button: "Ok",
      });
      fetchDataScholarship(1, 10);
    } catch (error) {
      //console.log(error);
    }
  };

  const viewScholarship = async (id) => {
    //console.log(id)
    try {
      const res = await fetch(`${url}/view/scholarship/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();

      setScholarshipView(jsonData);
    } catch (error) {
      //console.log(error);
    }
  };

  const userhandleClick = (id) => {
    //console.log(id)
    viewData(id);
    getOneUserDetails(id);
    // handleViewButtonClick();
  };

  // fetch all users data

  const getData = async () => {
    try {
      const res = await fetch(`${url}/allusers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setData(jsonData);

      // console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  // fetch all users details

  // // //

  const [userDetailData, setUserDetailData] = useState([]);

  const combinedData = users.concat(userDetailData);

  const getUserDetails = async () => {
    try {
      const res = await fetch(`${url}/allUserDetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setUserDetailData(jsonData);
      //console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  const getOneUserDetails = async (id) => {
    try {
      const res = await fetch(`${url}/alldetails/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      setUserDetails(jsonData);
      // setIsLoadingUser(false)
      //console.log(jsonData)
    } catch (error) {
      //console.log(error);
    }
  };

  // //////////// for testimonial pagination

  const [testimonialSearch, setTestmonialSearch] = useState("");
  const [testimonialsPage, setTestimonialsPage] = useState([]);
  const [currentPageTest, setCurrentPageTest] = useState(1);
  const [totalPagesTest, setTotalPagesTest] = useState(1);

  useEffect(() => {
    fetchTestimonialsPage(currentPageTest);
  }, [currentPageTest, testimonialSearch]);

  const fetchTestimonialsPage = async (page) => {
    try {
      const response = await fetch(
        `${url}/api/testimonial?page=${page}&search=${testimonialSearch}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      //console.log(data)
      // console.log(data.testimonial)
      setTestimonialsPage(data.testimonial);

      setTotalPagesTest(data.pagination.pageCount);
      setIsLoadingTestimonial(false);
      // setShowTestimonials(true);
      // setShowAllData(false)
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  const handlePageChangeTest = (page) => {
    setCurrentPageTest(page);
  };

  // //////////// end

  const deleteTestimonial = async (id) => {
    try {
      const res = await fetch(`${url}/deletetestimonial/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();
      // console.log(deleteData);
      swal({
        title: "Success",
        text: "Data Deleted Successfully!",
        icon: "success",
        button: "Ok",
      });
      fetchTestimonialsPage();
    } catch (error) {
      //console.log(error);
    }
  };

  // Show a Yes/No dialog on before Delete

  const showDeleteTestimonialModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
        deleteTestimonial(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  // testimonial soft delete code

  // const handleSoftDelete = async (id) => {
  //     try {
  //         const response = await fetch(`${url}/softDelete/${id}`, {
  //             method: 'PATCH',
  //             headers: {
  //                 "Accept": 'application/json',
  //                 'Content-Type': 'application/json'
  //             },
  //         });

  //         const deleteData = await response.json();
  //         console.log(deleteData);

  //         if (response.ok) {
  //             // Remove the soft-deleted scholarship from the state
  //             setTestimonials((prevTestimonial) =>
  //                 prevTestimonial.filter((e) => e._id !== id)
  //             );
  //         } else {
  //             console.log('Failed to soft delete testimonial.');
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  // Show a Yes/No dialog on before Delete

  const showDeleteUserModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Your imaginary file has been deleted Successfuly!", {
          icon: "success",
        });
        deleteUser(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  const deleteUser = async (id) => {
    try {
      const res = await fetch(`${url}/deleteuser/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const deleteData = await res.json();
      // console.log(deleteData);
      fetchUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  const handleLeadChange = async (event, _id) => {
    try {
      const res = await fetch(`${url}/user/lead-status`, {
        method: "PUT",
        body: JSON.stringify({ lead_status: event?.target.value, _id }),
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (res?.ok) {
        swal({
          title: "Success",
          text: "Lead Status Updated Successfully!",
          icon: "success",
          button: "Ok",
        });
        setCurrentPage(1);
      }
      const data = await res.json();
      fetchUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  const handleUserRoleUpdate = async (event, _id) => {
    try {
      const res = await fetch(`${url}/user/role-update`, {
        method: "PUT",
        body: JSON.stringify({ role: event?.target.value, _id }),
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (res?.ok) {
        swal({
          title: "Success",
          text: "Role Updated Successfully!",
          icon: "success",
          button: "Ok",
        });
      }
      const data = await res.json();
      fetchUsers();
    } catch (error) {
      //console.log(error);
    }
  };

  const viewData = async (id) => {
    try {
      const res = await fetch(`${url}/getuser/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jsonData = await res.json();
      const scholarshipArrayData = jsonData.scholarships;
      //console.log(jsonData)
      setScholarshipArray(scholarshipArrayData);
      setView(jsonData);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleShowAllData = () => {
    setShowAllData(true);
    setShowTestimonials(false);
    setShowScholarship(false);
  };

  const handleShowScholarship = () => {
    // getScholarshipData();
    setShowAllData(false);
    setShowTestimonials(false);
    setShowScholarship(true);
  };

  const getAllNotesForAUser = async (user, note) => {
    setAllNotes({ data: [], loading: true });
    try {
      const response = await fetch(`${url}/note/read`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          user: user,
          // createdBy: localStorage?.getItem("_id"),
        }),
      });
      const json = await response.json();
      setAllNotes({ data: [{ note: note }, ...json?.data], loading: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
      setAllNotes({ data: [], loading: false });
    }
  };

  const getAllMailsForAUser = async (user) => {
    setAllMails({ data: [], loading: true });
    try {
      const response = await fetch(`${url}/send-email/${user}`, {
        method: "GET",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      setAllMails({ data: json?.data, loading: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
      setAllMails({ data: [], loading: false });
    }
  };

  const handleAddNotes = async () => {
    try {
      const response = await API("post", `${url}/note/create`, {
        note: notes,
        user: actionModal?.user,
        createdBy: localStorage?.getItem("_id"),
        type: "user", 
      });
      const json = response.data;
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setActionModal({ notes: false, mail: false });
      setNotes("");
    } catch (error) {
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };

  const handleSendMail = async () => {
    try {
      const response = await fetch(`${url}/send-email`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          createdBy: localStorage?.getItem("_id"),
          user: actionModal?.user,
          to: actionModal?.email,
          subject: mail?.subject,
          body: mail?.body,
        }),
      });
      const json = await response.json();
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setMail({ subject: "", body: "" });
      setActionModal({ notes: false, mail: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };
  const handleReSendMail = async (mailid) => {
    try {
      const response = await fetch(`${url}/send-email/resend`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mailid: mailid,
        }),
      });
      const json = await response.json();
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setMail({ subject: "", body: "" });
      setActionModal({ notes: false, mail: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };

  const handleArchive = async (userid) => {
    try {
      const data = await API("put", `/api/user/archive/${userid}`, {
        archive: archive == "false" ? true : false,
      });
      swal({
        title: "Success!",
        text: data.message,
        icon: "success",
        button: "Ok!",
      });
      fetchUsers();
    } catch (error) {}
  };

  const sortData = (arr, property, reverse) => {
    let sorted = arr.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    if (reverse) {
      sorted.reverse();
    }
    setIsLoadingUser(true);
    setUsers(sorted);
    setTimeout(() => {
      setIsLoadingUser(false);
    }, 200);
  };

  return (
    <div className="container">
      {/* The below code for dashboard main buttons Show all Data, Scholarship, Testimonial */}
      <NavBarAdmin
        fetchTestimonialsPage={fetchTestimonialsPage}
        getUserDetails={getUserDetails}
        handleShowAllData={handleShowAllData}
        handleShowScholarship={handleShowScholarship}
        scholarshipSearch={scholarshipSearch}
        searchUser={searchUser}
        setScholarshipSearch={setScholarshipSearch}
        setTestmonialSearch={setTestmonialSearch}
        setSearchUser={setSearchUser}
        showAllData={showAllData}
        showScholarship={showScholarship}
        showTestimonials={showTestimonials}
        testimonialSearch={testimonialSearch}
        key={"nav1"}
      />
      {/* main buttons code end here */}

      {/* This code is for fetch all data from get API's user, scholarship, testimonial */}
      <div className="container">
        <h1 className="page-title">Active Users</h1>
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  {/* <th scope="col">Country Code</th> */}
                  <th scope="col">Phone No.</th>
                  {/* <th scope="col">Notes</th> */}
                  <th scope="col">Study Destination</th>
                  <th scope="col">
                    Lead Status
                    <BsPencilSquare
                      onClick={() =>
                        setEditable({ ...editable, lead: !editable.lead })
                      }
                      className="BsPencilSquare"
                      style={{
                        marginLeft: "8px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    />
                  </th>
                  {/* <th scope="col">
                    Role{" "}
                    <BsPencilSquare
                      onClick={() =>
                        setEditable({ ...editable, role: !editable.role })
                      }
                      style={{
                        marginLeft: "8px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      className="BsPencilSquare"
                    />
                  </th> */}
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {activeUserLoading ? (
                  <div className="circle">
                    <CircularProgress className="circleHight" />
                  </div>
                ) : activeUsers.length === 0 ? (
                  <>
                    <img src={Img} className="noData" alt="nodata" />
                    <p className="required p-2">No Data Found</p>
                  </>
                ) : (
                  activeUsers.map((e, id) => (
                    <tr key={id}>
                      <th scope="row">{id + 1}</th>
                      <td>
                        {/* Admin */}
                        {e.role.toLowerCase() == "admin" && (
                          <>
                            <i className="fa fa-user-gear"></i>
                            &nbsp;
                          </>
                        )}
                        {/* Counselor */}
                        {e.role.toLowerCase() == "counsellor" && (
                          <>
                            <i className="fa fa-user-tie"></i>
                            &nbsp;
                          </>
                        )}
                        <a
                          className="cursor-link"
                          onClick={() => {
                            navigate(
                              `/dashboard/user/applicationprofile/${e._id}`
                            );
                          }}
                        >
                          {e.firstName} {e.lastName}
                        </a>
                        <ul className="user-menu">
                          <li
                            onClick={() => {
                              navigate(`/dashboard/user/application/${e._id}`);
                            }}
                          >
                            Applications
                          </li>
                          <li
                            onClick={() => {
                              navigate(`/dashboard/user/files/${e._id}`);
                            }}
                          >
                            Files
                          </li>
                          <li
                            onClick={() => {
                              navigate("/shortlisting/" + e?._id);
                            }}
                          >
                            Shortlisting
                          </li>
                          <li
                            onClick={() => {
                              navigate(
                                "/dashboard-mass-email?email=" + e?.email
                              );
                            }}
                          >
                            Email
                          </li>
                          <li
                            onClick={() => {
                              navigate("/calendar/" + e?._id);
                            }}
                          >
                            Calendar
                          </li>
                        </ul>
                      </td>
                      <td>
                        <a href={`mailto:${e.email}`}>{e.email}</a>
                      </td>
                      {/* <td>{e?.countryCode}</td> */}
                      <td>
                        {e?.countryCode} {e.phone}
                        <br />
                        <a href={`tel:${e?.countryCode}${e.phone}`}><i className="fa fa-phone"></i></a> | <a href={`https://wa.me/${e?.countryCode}${e.phone}`}><i className="fa fa-whatsapp"></i></a>
                      </td>
                      {/* <td>{e?.note ? e?.note : '-'}</td> */}
                      <td>{e?.studyDest?.join(", ")}</td>
                      <td>
                        <select
                          className="form-select"
                          name="subject"
                          value={e?.lead_status}
                          onChange={(ev) => handleLeadChange(ev, e._id)}
                          placeholder="Select Subject"
                          disabled={editable.lead}
                        >
                          <option value={"hot"}>Hot</option>
                          <option value={"warm"}>Warm</option>
                          <option value={"cold"}>Cold</option>
                          <option value={"registeredpremium"}>Registered Premium</option>
                          <option value={"registeredelite"}>Registered Elite</option>
                          <option value={"registeredplatinum"}>Registered Platinum</option>
                        </select>
                      </td>
                      {/* <td>
                        {" "}
                        <select
                          className="form-select"
                          name="role"
                          value={e.role}
                          onChange={(ev) => handleUserRoleUpdate(ev, e._id)}
                          placeholder="Select Role"
                          disabled={editable.role}
                        >
                          <option value={"user"}>User</option>
                          <option value={"Admin"}>Admin</option>
                          <option value={"Counsellor"}>Counsellor</option>
                        </select>
                      </td> */}
                      <td>
                        <div className="dropdown">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={<Tooltip id="tooltip">Edit User</Tooltip>}
                          >
                            <button
                              className="btn userEditBtn"
                              onClick={() => navigate(`userEdit/${e._id}`)}
                            >
                              <BsPencilSquare className="BsPencilSquare" />{" "}
                              {/* Edit User */}
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">Add / View Notes</Tooltip>
                            }
                          >
                            <button
                              onClick={() => {
                                setAllNotes({ title: e?.note }, ...notes);
                                setActionModal({
                                  mail: false,
                                  notes: true,
                                  user: e?._id,
                                });
                                getAllNotesForAUser(e?._id, e?.note);
                              }}
                              className="btn useDeleteBtn"
                            >
                              <MdBook style={{ color: "#DC7215" }} />{" "}
                              {/* Add / View Notes */}
                            </button>
                          </OverlayTrigger>
                          {/* <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-gear"></i>
                          </button> */}
                          <ul className="dropdown-menu">
                            {/*<li>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">User Files</Tooltip>
                                }
                              >
                                <button
                                  className="btn fileUploadBtn"
                                  onClick={() => {
                                    navigate(`/dashboard/user/files/${e._id}`);
                                  }}
                                >
                                  <FaFileUpload className="FaFileUpload" /> User
                                  Files
                                </button>
                              </OverlayTrigger>
                            </li>
                            <li>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    Create Application
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="btn FaUserAlt"
                                  onClick={() => {
                                    navigate(
                                      `/dashboard/user/createapplication/${e._id}`
                                    );
                                  }}
                                >
                                  <FaPassport className="FaPassport" /> Create
                                  Application
                                </button>
                              </OverlayTrigger>
                            </li> */}
                            {/* <button onClick={() => userhandleClick(e._id)} style={{ backgroundColor: "white" }} className='btn btn-success' data-bs-toggle="modal" data-bs-target="#userViewModal"><BsEyeFill style={{ color: "forestgreen", fontSize: "1.2rem" }} /></button> */}
                            {isAdmin ? (
                              <>
                                {/* <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        View Applications
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/dashboard/user/application/${e._id}`
                                        );
                                      }}
                                      className="btn FaUserAlt"
                                    >
                                      <FaPassport className="FaFileUpload" />{" "}
                                      View Applications
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">Edit User</Tooltip>
                                    }
                                  >
                                    <button
                                      className="btn userEditBtn"
                                      onClick={() =>
                                        navigate(`userEdit/${e._id}`)
                                      }
                                    >
                                      <BsPencilSquare className="BsPencilSquare" />{" "}
                                      Edit User
                                    </button>
                                  </OverlayTrigger>
                                </li>
                                {/* <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Delete User
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => showDeleteUserModal(e._id)}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdDelete className="MdDelete" /> Delete
                                      User
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Add / View Notes
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        setActionModal({
                                          mail: false,
                                          notes: true,
                                          user: e?._id,
                                        });
                                        getAllNotesForAUser(e?._id, e?.note);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdBook style={{ color: "#DC7215" }} />{" "}
                                      Add / View Notes
                                    </button>
                                  </OverlayTrigger>
                                </li>
                                {/* <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">Mail</Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate(
                                          "/dashboard-mass-email?email=" +
                                            e?.email
                                        );
                                        // setActionModal({
                                        //   mail: true,
                                        //   notes: false,
                                        //   email: e?.email,
                                        //   user: e?._id,
                                        // });
                                        // getAllMailsForAUser(e?._id);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdMail style={{ color: "#DC7215" }} />{" "}
                                      View / Send Email
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Sign Up Notes
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      disabled={
                                        e?.note?.length < 0 ? true : false
                                      }
                                      onClick={() => {
                                        if (e?.note) {
                                          swal({
                                            title: e?.note,
                                          });
                                        }
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdBook style={{ color: "#DC7215" }} />{" "}
                                      Sign Up Notes
                                    </button>
                                  </OverlayTrigger>
                                </li>
                                {/*<li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">Calendar</Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate("/calendar/" + e?._id);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdDateRange
                                        style={{ color: "#DC7215" }}
                                      />{" "}
                                      Calendar
                                    </button>
                                  </OverlayTrigger>
                                </li>
                                 <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Shortlisting
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate("/shortlisting/" + e?._id);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdListAlt style={{ color: "#DC7215" }} />{" "}
                                      Shortlisting
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showAllData ? (
        <div className="container">
          <div className="d-flex justify-content-between">
            <h1 className="page-title mt-3">Users</h1>

            <select
              value={archive}
              className="form-select w-10p mb-3 mt-3"
              onChange={(e) => setArchive(e.target.value)}
            >
              <option value="false">Unarchived</option>
              <option value="true">Archived</option>
            </select>
          </div>
          <div
            className="d-flex tab"
            style={{ gap: "1rem", cursor: "pointer" }}
          >
            <h2
              onClick={() => {
                setLeadActive("");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "" && "#DC7215" }}
              className="tablinks"
            >
              All
            </h2>
            <h2
              onClick={() => {
                setLeadActive("hot");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "hot" && "#DC7215" }}
              className="tablinks"
            >
              Hot
            </h2>
            <h2
              onClick={() => {
                setLeadActive("cold");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "cold" && "#DC7215" }}
              className="tablinks"
            >
              Cold
            </h2>
            <h2
              onClick={() => {
                setLeadActive("warm");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "warm" && "#DC7215" }}
              className="tablinks"
            >
              Warm
            </h2>
            <h2
              onClick={() => {
                setLeadActive("registeredpremium");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "registeredpremium" && "#DC7215" }}
              className="tablinks"
            >
              Registered Premium
            </h2>
            <h2
              onClick={() => {
                setLeadActive("registeredelite");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "registeredelite" && "#DC7215" }}
              className="tablinks"
            >
              Registered Elite
            </h2>
            <h2
              onClick={() => {
                setLeadActive("registeredplatinum");
                setCurrentPage(1);
              }}
              style={{ color: leadActive === "registeredplatinum" && "#DC7215" }}
              className="tablinks"
            >
              Registered Platinum
            </h2>
          </div>
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table admin-table">
                <thead>
                  <tr className="table-dark">
                    <th scope="col">Id</th>
                    <th scope="col">
                      Name{" "}
                      <i
                        className="fa-solid fa-caret-up"
                        onClick={() => sortData(users, "firstName")}
                      ></i>{" "}
                      <i
                        className="fa-solid fa-caret-down"
                        onClick={() => sortData(users, "firstName", true)}
                      ></i>
                    </th>
                    <th scope="col">Email</th>
                    {/* <th scope="col">Country Code</th> */}
                    <th scope="col">Phone No.</th>
                    {/* <th scope="col">Notes</th> */}
                    <th scope="col">Study Destination</th>
                    <th scope="col">
                      Lead Status
                      <BsPencilSquare
                        onClick={() =>
                          setEditable({ ...editable, lead: !editable.lead })
                        }
                        className="BsPencilSquare"
                        style={{
                          marginLeft: "8px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </th>
                    {/* <th scope="col">
                      Role{" "}
                      <BsPencilSquare
                        onClick={() =>
                          setEditable({ ...editable, role: !editable.role })
                        }
                        style={{
                          marginLeft: "8px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        className="BsPencilSquare"
                      />
                    </th> */}
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoadingUser ? (
                    <div className="circle">
                      <CircularProgress className="circleHight" />
                    </div>
                  ) : users.length === 0 ? (
                    <>
                      <img src={Img} className="noData" alt="nodata" />
                      <p className="required p-2">No Data Found</p>
                    </>
                  ) : (
                    users.map((e, id) => (
                      <tr key={id}>
                        <th scope="row">{(currentPage - 1) * 10 + (id + 1)}</th>
                        <td>
                          {/* Admin */}
                          {e.role.toLowerCase() == "admin" && (
                            <>
                              <i className="fa fa-user-gear"></i>
                              &nbsp;
                            </>
                          )}
                          {/* Counselor */}
                          {e.role.toLowerCase() == "counsellor" && (
                            <>
                              <i className="fa fa-user-tie"></i>
                              &nbsp;
                            </>
                          )}
                          <a
                            className="cursor-link"
                            onClick={() => {
                              navigate(
                                `/dashboard/user/applicationprofile/${e._id}`
                              );
                            }}
                          >
                            {e.firstName} {e.lastName}
                          </a>
                          <ul className="user-menu">
                            <li
                              onClick={() => {
                                navigate(
                                  `/dashboard/user/application/${e._id}`
                                );
                              }}
                            >
                              Applications
                            </li>
                            <li
                              onClick={() => {
                                navigate(`/dashboard/user/files/${e._id}`);
                              }}
                            >
                              Files
                            </li>
                            <li
                              onClick={() => {
                                navigate("/shortlisting/" + e?._id);
                              }}
                            >
                              Shortlisting
                            </li>
                            <li
                              onClick={() => {
                                navigate(
                                  "/dashboard-mass-email?email=" + e?.email
                                );
                              }}
                            >
                              Email
                            </li>
                            <li
                              onClick={() => {
                                navigate("/calendar/" + e?._id);
                              }}
                            >
                              Calendar
                            </li>
                          </ul>
                        </td>
                        <td>
                          <a href={`mailto:${e.email}`}>{e.email}</a>
                        </td>
                        {/* <td>{e?.countryCode}</td> */}
                        <td>
                          {e?.countryCode} {e.phone}
                          <br />
                          <a href={`tel:${e?.countryCode}${e.phone}`}><i className="fa fa-phone"></i></a> | <a href={`https://wa.me/${e?.countryCode}${e.phone}`}><i className="fa fa-whatsapp"></i></a>
                        </td>
                        {/* <td>{e?.note ? e?.note : '-'}</td> */}
                        <td>{e?.studyDest?.join(", ")}</td>
                        <td>
                          <select
                            className="form-select"
                            name="subject"
                            value={e?.lead_status}
                            onChange={(ev) => handleLeadChange(ev, e._id)}
                            placeholder="Select Subject"
                            disabled={editable.lead}
                          >
                            <option value={"hot"}>Hot</option>
                            <option value={"warm"}>Warm</option>
                            <option value={"cold"}>Cold</option>
                            <option value={"registeredpremium"}>Registered Premium</option>
                            <option value={"registeredelite"}>Registered Elite</option>
                            <option value={"registeredplatinum"}>Registered Platinum</option>
                          </select>
                        </td>
                        {/* <td>
                        {" "}
                        <select
                          className="form-select"
                          name="role"
                          value={e.role}
                          onChange={(ev) => handleUserRoleUpdate(ev, e._id)}
                          placeholder="Select Role"
                          disabled={editable.role}
                        >
                          <option value={"user"}>User</option>
                          <option value={"Admin"}>Admin</option>
                          <option value={"Counsellor"}>Counsellor</option>
                        </select>
                      </td> */}
                        <td>
                          <div className="dropdown">
                            <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip">Edit User</Tooltip>
                              }
                            >
                              <button
                                className="btn userEditBtn"
                                onClick={() => navigate(`userEdit/${e._id}`)}
                              >
                                <BsPencilSquare className="BsPencilSquare" />{" "}
                                {/* Edit User */}
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip">Add / View Notes</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setActionModal({
                                    mail: false,
                                    notes: true,
                                    user: e?._id,
                                  });
                                  getAllNotesForAUser(e?._id, e?.note);
                                  // setAllNotes({ title: }, ...notes);
                                }}
                                className="btn useDeleteBtn"
                              >
                                <MdBook style={{ color: "#DC7215" }} />{" "}
                                {/* Add / View Notes */}
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip">
                                  {e?.archive ? "Unarchive" : "Archive"}
                                </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  handleArchive(e?._id);
                                }}
                                className="btn useDeleteBtn"
                              >
                                <MdArchive style={{ color: "#DC7215" }} />{" "}
                                {/* Add / View Notes */}
                              </button>
                            </OverlayTrigger>

                            {/* <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-gear"></i>
                          </button> */}
                            <ul className="dropdown-menu">
                              {/*<li>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">User Files</Tooltip>
                                }
                              >
                                <button
                                  className="btn fileUploadBtn"
                                  onClick={() => {
                                    navigate(`/dashboard/user/files/${e._id}`);
                                  }}
                                >
                                  <FaFileUpload className="FaFileUpload" /> User
                                  Files
                                </button>
                              </OverlayTrigger>
                            </li>
                            <li>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    Create Application
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="btn FaUserAlt"
                                  onClick={() => {
                                    navigate(
                                      `/dashboard/user/createapplication/${e._id}`
                                    );
                                  }}
                                >
                                  <FaPassport className="FaPassport" /> Create
                                  Application
                                </button>
                              </OverlayTrigger>
                            </li> */}
                              {/* <button onClick={() => userhandleClick(e._id)} style={{ backgroundColor: "white" }} className='btn btn-success' data-bs-toggle="modal" data-bs-target="#userViewModal"><BsEyeFill style={{ color: "forestgreen", fontSize: "1.2rem" }} /></button> */}
                              {isAdmin ? (
                                <>
                                  {/* <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        View Applications
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/dashboard/user/application/${e._id}`
                                        );
                                      }}
                                      className="btn FaUserAlt"
                                    >
                                      <FaPassport className="FaFileUpload" />{" "}
                                      View Applications
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                  <li>
                                    <OverlayTrigger
                                      key="bottom"
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Edit User
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        className="btn userEditBtn"
                                        onClick={() =>
                                          navigate(`userEdit/${e._id}`)
                                        }
                                      >
                                        <BsPencilSquare className="BsPencilSquare" />{" "}
                                        Edit User
                                      </button>
                                    </OverlayTrigger>
                                  </li>
                                  {/* <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Delete User
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => showDeleteUserModal(e._id)}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdDelete className="MdDelete" /> Delete
                                      User
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                  <li>
                                    <OverlayTrigger
                                      key="bottom"
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Add / View Notes
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setActionModal({
                                            mail: false,
                                            notes: true,
                                            user: e?._id,
                                          });
                                          getAllNotesForAUser(e?._id, e?.note);
                                        }}
                                        className="btn useDeleteBtn"
                                      >
                                        <MdBook style={{ color: "#DC7215" }} />{" "}
                                        Add / View Notes
                                      </button>
                                    </OverlayTrigger>
                                  </li>
                                  {/* <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">Mail</Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate(
                                          "/dashboard-mass-email?email=" +
                                            e?.email
                                        );
                                        // setActionModal({
                                        //   mail: true,
                                        //   notes: false,
                                        //   email: e?.email,
                                        //   user: e?._id,
                                        // });
                                        // getAllMailsForAUser(e?._id);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdMail style={{ color: "#DC7215" }} />{" "}
                                      View / Send Email
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                  <li>
                                    <OverlayTrigger
                                      key="bottom"
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Sign Up Notes
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        disabled={
                                          e?.note?.length < 0 ? true : false
                                        }
                                        onClick={() => {
                                          if (e?.note) {
                                            swal({
                                              title: e?.note,
                                            });
                                          }
                                        }}
                                        className="btn useDeleteBtn"
                                      >
                                        <MdBook style={{ color: "#DC7215" }} />{" "}
                                        Sign Up Notes
                                      </button>
                                    </OverlayTrigger>
                                  </li>
                                  {/*<li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">Calendar</Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate("/calendar/" + e?._id);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdDateRange
                                        style={{ color: "#DC7215" }}
                                      />{" "}
                                      Calendar
                                    </button>
                                  </OverlayTrigger>
                                </li>
                                 <li>
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Shortlisting
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        navigate("/shortlisting/" + e?._id);
                                      }}
                                      className="btn useDeleteBtn"
                                    >
                                      <MdListAlt style={{ color: "#DC7215" }} />{" "}
                                      Shortlisting
                                    </button>
                                  </OverlayTrigger>
                                </li> */}
                                </>
                              ) : null}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {users.length > 0 ? (
            <Pagination className="d-flex justify-content-end mb-5">
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              />
            </Pagination>
          ) : null}
        </div>
      ) : null}

      {/* end here */}

      {/* view Modal for user data  */}
      <div
        className="modal fade"
        id="userViewModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {view && (
              <div className="modal-body ">
                <div className="table-responsive">
                  <table className="table modalView">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" colspan="2">
                          Overview
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          {view.firstName} {view.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>
                          {userDetails &&
                          userDetails.data &&
                          userDetails.data.gender
                            ? userDetails.data.gender
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{view.email}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{view.phone}</td>
                      </tr>
                      <tr>
                        <td>Date of Birth</td>
                        <td>
                          {userDetails &&
                          userDetails.data &&
                          userDetails.data.dob
                            ? new Date(userDetails.data.dob).toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                }
                              )
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Education</td>
                        <td>
                          {userDetails &&
                          userDetails.data &&
                          userDetails.data.education
                            ? userDetails.data.education
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Study Destination</td>
                        <td>{view.studyDest}</td>
                      </tr>
                      <tr>
                        <td>Scholarship</td>
                        <td>
                          {scholarshipArray.map((e, id) => {
                            return (
                              <>
                                <p>
                                  <b>{id + 1}.</b> {e.category} - <b>Id</b>.{" "}
                                  {e.id}
                                </p>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>
                          {userDetails &&
                          userDetails.data &&
                          userDetails.data.location
                            ? userDetails.data.location
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Work Experience</td>
                        <td>
                          {userDetails &&
                          userDetails.data &&
                          userDetails.data.workexp
                            ? userDetails.data.workexp
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Notes Modal */}
      <Modal
        show={actionModal?.notes}
        onHide={() => setActionModal({ mail: false, notes: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/View Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!allNotes?.loading ? (
            allNotes?.data?.map((itm, i) => (
              <div key={i}>
                <h2 style={{ fontWeight: "600" }}>{itm?.note}</h2>
                {itm?.createdAt && (
                  <p>
                    Created at:{" "}
                    {moment(itm?.createdAt).format("DD-MMM-YYYY HH:mm A")}
                  </p>
                )}
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
          <div className="form-floating">
            <input
              className="form-control"
              placeholder=""
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />{" "}
            <label for="floatingTextarea">Notes</label>
          </div>
          <Button
            variant="primary-regular mt-3"
            disabled={!notes}
            onClick={() => {
              handleAddNotes();
            }}
          >
            Send
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setActionModal({ mail: false, notes: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Mail Modal */}
      <Modal
        show={actionModal?.mail}
        onHide={() => setActionModal({ mail: false, notes: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!allMails?.loading ? (
            allMails?.data?.map((itm, i) => (
              <div key={i}>
                {/* <h2>
                  <span style={{ fontWeight: "600" }}>To: </span>
                  {itm?.to}
                </h2> */}
                <h2>
                  <span style={{ fontWeight: "600" }}>Subject: </span>
                  {itm?.subject}
                </h2>
                <h2>
                  <span style={{ fontWeight: "600" }}>Body: </span>
                  {itm?.body}
                </h2>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0">
                    Sent at:{" "}
                    {moment(itm?.createdAt).format("DD-MMM-YYYY HH:mm A")}
                  </p>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleReSendMail(itm?._id)}
                  >
                    Resend
                  </button>
                </div>

                <hr />
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
          <h2>
            To: <span style={{ fontWeight: "600" }}>{actionModal?.email}</span>
          </h2>
          <input
            className="form-control"
            placeholder="Subject"
            value={mail.subject}
            onChange={(e) =>
              setMail({
                ...mail,
                subject: e.target.value,
              })
            }
          />{" "}
          <br />
          <textarea
            className="form-control"
            placeholder="Body"
            value={mail.body}
            onChange={(e) =>
              setMail({
                ...mail,
                body: e.target.value,
              })
            }
          />
          <br />
          <Button
            variant="primary"
            onClick={() => {
              handleSendMail();
            }}
          >
            Send
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setActionModal({ mail: false, notes: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dashboard;
