import React from "react";
import { NavLink } from "react-router-dom";
import { url } from "../Constants";
import swal from "sweetalert";
import countryCodes from "../Assets/Data/countries.json";
import "../Assets/Styles/scholarship.css";
const ListItem = (props) => {
  const isValidImageUrl = (url) => {
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|bmp)$/i;
    return imageUrlRegex.test(url);
  };

  const {
    id,
    imgsrc,
    destination_country,
    scholarship_name,
    scholarship_level,
    scholarship_deadline,
    scholarship_amount,
    scholarship_link,
  } = props;

  const authToken = localStorage.getItem("token");
  let scholarshipMatch = {
    70: { class: "scholarship-high", text: "High Match" },
    50: { class: "scholarship-mid", text: "Moderate Match" },
    30: { class: "scholarship-low", text: "Low Match" },
  };
  const getMatch = (element, user) => {
    // , level, eligible
    let sex = 0;
    let destination = 0;
    let level = 0;
    let eligible = 0;

    if (user?.studyDest?.includes(element?.Destination_Country)) {
      destination = 1;
    }
    let currentCountry = countryCodes?.find(
      (itm) => itm?.dial_code == user?.countryCode
    )?.name;

    if (currentCountry?.includes(element?.Eligible_Citizenship)) {
      eligible = 1;
    }
    if (element?.Sex == "All") {
      sex = 1;
    } else if (element?.Sex?.includes(user?.userInfo?.gender)) {
      sex = 1;
    }
    let userLevel;
    if (user?.userInfo?.level?.length > 0) {
      let userLevel = user?.userInfo?.level[0] + user?.userInfo?.level[1];
    }
    if (
      element?.Program_Level?.includes(userLevel) ||
      element?.Program_Level == "All" ||
      element?.Program_Level == userLevel
    ) {
      level = 1;
    }

    let cal = (((destination + eligible + sex + level) / 4) * 100).toFixed(0);
    return scholarshipMatch[cal >= 70 ? 70 : cal >= 50 ? 50 : 30];
    //
  };

  const del = async () => {
    const checkResponse = await fetch(`${url}/scholarship/delete`, {
      method: "POST",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        id: id,
      }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await checkResponse.json();
    if (json.success === true) {
      swal({
        title: "Deleted",
        icon: "success",
        button: "Ok",
      });
    }
    props.getCategoryData(props.cat);
    // console.log(json)
  };

  return (
    <li className="main-list">
      <ul
        className={
          props.mode === "list" ? "list-detail list" : "list-detail grid"
        }
      >
        <li className="list-width-15">
          {props.mode === "grid" ? (
            <div>
              <span className="uni-logo">
                <img
                  src={
                    imgsrc !== "missing" && isValidImageUrl(imgsrc)
                      ? imgsrc
                      : require("../Assets/Media/Images/uni-detail-logo.png")
                  }
                  alt="img"
                />
              </span>
            </div>
          ) : (
            ""
          )}
          <div>
            <span className="uni-name">
              {scholarship_name} &nbsp;
              {/* <span className={getMatch(props.data, props.user).class}>
                {getMatch(props.data, props.user).text}
              </span> */}
            </span>
            {props.mode === "grid" ? <br /> : ""}
            {props.mode === "grid" ? (
              <span className="uni-location">{destination_country}</span>
            ) : (
              ""
            )}
          </div>
        </li>
        <li className="list-width-10">
          {props.mode === "grid" ? (
            <span className="list-text-label">
              Eligibility
              <br />
            </span>
          ) : (
            ""
          )}
          <NavLink to={"#"} onClick={props.onClick}>View</NavLink>
        </li>
        {/* <li className="list-width-10">{getMatch(props.data, props.user)}</li> */}
        <li className="list-width-10">
          {props.mode === "grid" ? (
            <span className="list-text-label">
              Level <br />
            </span>
          ) : (
            ""
          )}
          {scholarship_level}
        </li>
        <li className="list-width-10">
          {props.mode === "grid" ? (
            <span className="list-text-label">
              Deadline <br />
            </span>
          ) : (
            ""
          )}
          {scholarship_deadline}
        </li>
        <li className="list-width-10">
          {props.mode === "grid" ? (
            <span className="list-text-label">
              Amount <br />
            </span>
          ) : (
            ""
          )}
          {scholarship_amount}
        </li>
        <li className="list-width-10">
          <a href={"#"} onClick={props.onClick}>
            Apply Now
            <i className="ms-1 fa-solid fa-xs fa-arrow-up-right-from-square"></i>
          </a>
        </li>
        {props.cat !== "All" ? (
          <li className="list-width-5 edit-btn" onClick={del}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
            </svg>
          </li>
        ) : (
          ""
        )}
      </ul>
    </li>
  );
};

export default ListItem;
