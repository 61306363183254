import { useTimer } from "react-timer-hook";
import { useAudio } from "../Context/AudioContext";

const useTrialTimer = (timeStamp, setModals) => {
  const { audio } = useAudio();
  const { seconds, minutes, start, isRunning } = useTimer({
    autoStart: false,
    expiryTimestamp: timeStamp,
    onExpire: () => {
      //
      // window.location.reload()
      setModals({ login: true });
      audio.pause();
    },
  });

  return [start, minutes, seconds, isRunning];
};

export { useTrialTimer };
