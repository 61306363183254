import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import swal from "sweetalert";

const AddMentor = () => {
  const [modal, setModal] = useState(false);
  const [mentor, setMentor] = useState({
    name: "",
    email: "",
  });

  const handleAddMentor = async () => {
    try {
      const response = await API("post", `/addmentor/`, mentor);
      swal({
        title: "Success!",
        text: "Mentor Added Successfully!",
        icon: "success",
        button: "Ok!",
      });
      setModal(false);
      setMentor({
        name: "",
        email: "",
      });
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <div className="body-content width-50">
        <div
          className="card p-4"
        >
          <h2>
            <b>Join as a Mentor</b>
          </h2>
          <hr />
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder=""
              value={mentor.name}
              onChange={(e) => {
                setMentor({
                  ...mentor,
                  name: e.target.value,
                });
              }}
            />
            <label for="floatingInput">Mentor Name<span className="required">*</span></label>
          </div>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              placeholder=""
              type="email"
              value={mentor.email}
              onChange={(e) => {
                setMentor({
                  ...mentor,
                  email: e.target.value,
                });
              }}
            />
            <label for="floatingInput">Mentor Email<span className="required">*</span></label>
          </div>
          <div>
          <button
            className="btn btn-primary-regular"
            onClick={() => {
              handleAddMentor();
            }}
          >
            Start Process
          </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddMentor;