import React from "react";
import { useNavigate } from 'react-router-dom'
import { url } from "../Constants";
import swal from "sweetalert";
import { Button } from "react-bootstrap";


const CourseSelection = () => {
    const navigate = useNavigate(); // Initialize navigate function
  
    const handleMarkComplete = async (step) => {
      try {
        const response = await fetch(`${url}/user/updateCompletionSteps`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({ [`step${step}Complete`]: true }),
        });
  
        const res_json = await response.json();
  
        if (res_json.success) {
          swal({
            title: "Success!",
            text: "Step marked as complete!",
            icon: "success",
            button: "Ok!",
          });
          navigate('/userdashboard');
        } else {
          swal({
            title: "Error!",
            text: res_json.message,
            icon: "error",
            button: "Ok!",
          });
        }
      } catch (error) {
        console.error("Error marking step as complete:", error);
    }
  };  

    return (
        <div>
            <div className="body-content no-filterpanel">
            <div className="body-panel d-flex justify-content-between">
                <div className="body-container width-100">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between mt-3">
                                <div className="page-content">
                                    <h1 className="mb-3">Shortlisting</h1>
                                    {/* <p>
                                    Unlock your ideal student living experience with our cutting-edge accommodation portal, offering personalized solutions, extensive options, and a seamless platform that revolutionizes the way you find and secure your perfect home away from home.
                                    </p>
                                    <a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                                </div>
                                <div>
                                <Button
                                    variant="primary-regular blinking-btn me-2 float-end"
                                    onClick={() => handleMarkComplete(4)} // Replace 5 with the appropriate step number
                                    className="ms-2"
                                    >
                                    Mark Step as Complete
                                    </Button>
                                    <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                        <i className='fa fa-home'></i> Back to Dashboard
                                    </button>
                                    <button onClick={() => { navigate('/shortlisting-user/'+ localStorage.getItem('_id')) }} className="widget-cta-page-nopos-noresponsive float-end mb-3 me-2">
                                         View Shortlistings
                                    </button>
                                </div>
                                {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <iframe src="https://risehighereducation.com/wp-content/uploads/drive/course-selection/index.html" height="450" width="100%"></iframe>
                            <div className="course-alt">
                                <div className="course-alt-or">OR</div>
                                If you <span className="text-black">don't see your ideal program</span>, let our counseling team <span className="text-black">create shortlisting</span> for you. <span className="text-black">Complete</span> our application <span className="text-black">profile form</span>, and <span className="text-black">receive</span> a personalized <span className="text-black">program list</span> within <span className="text-black">48 hours</span> via email
                                <button onClick={()=>{navigate('/applicationprofile')}} className="float-end btn-profileab">Application Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <button onClick={()=>{navigate('/documents')}} className="widget-cta-page float-end mx-4">Get Documents Checklist</button> */}
            <div className="footer-links">
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#disclaimerModal"
                >
                    Disclaimer
                </button>
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#TnCModal"
                >
                    Terms &amp; Conditions
                </button>
                <div
                    className="modal fade"
                    id="disclaimerModal"
                    tabIndex={-1}
                    aria-labelledby="disclaimerModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="disclaimerModal">
                                    Disclaimer
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>The Global Flight Search, powered by the integration of Expedia's flight search technology, is a service provided by Rise Higher Education Inc to assist users in finding flight options from various airlines. It's important to understand the following:</p>
                                <ol>
                                    <li>
                                        <strong>Third-Party Source:</strong> The flight information provided on this platform is sourced from Expedia, a third-party flight search and booking service. Rise Higher Education Inc does not independently verify the accuracy, timeliness, or completeness of the flight data.
                                    </li>
                                    <li>
                                        <strong>Search and Display:</strong> The flight search results displayed on this platform are based on the data received from Expedia's API. Rise Higher Education Inc does not guarantee the availability, prices, or details of any specific flight.
                                    </li>
                                    <li>
                                        <strong>Booking Process:</strong> Users who wish to book a flight will be redirected to Expedia's website to complete the booking process. Rise Higher Education Inc does not facilitate the booking transaction and is not responsible for any issues that may arise during the booking process on Expedia's platform.        
                                    </li>
                                    <li>
                                        <strong>External Links:</strong> By clicking on a flight option, users will be directed to the Expedia website. Users acknowledge that they are leaving Rise Higher Education Inc and entering Expedia's site, which operates under its own terms and conditions.
                                    </li>
                                    <li>
                                        <strong>Data Privacy:</strong> Users should review Expedia's privacy policy before proceeding with flight bookings. Expedia may collect and process personal information as part of the booking process.        
                                    </li>
                                    <li>
                                        <strong>Not an Endorsement:</strong> Rise Higher Education Inc does not endorse any specific airline, flight, or booking option provided through Expedia's platform. Users should exercise their own judgment when selecting and booking flights.
                                    </li>
                                    <li>
                                        <strong>Changes and Cancellations:</strong> Flight availability, prices, and terms may change without notice. Users should verify all information, including refund and cancellation policies, on Expedia's platform before booking.
                                    </li>
                                </ol>
                                <p>
                                    By using the Global Flight Search, users agree to these terms and understand that Rise Higher Education Inc is not responsible for the accuracy of flight information or any issues arising from the booking process on Expedia's platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="TnCModal"
                    tabIndex={-1}
                    aria-labelledby="TnCModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="TnCModal">
                                    Terms &amp; Conditions
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>
                                    By using the Global Flight Search, users agree to comply with these Terms & Conditions. If users do not agree with these terms, they should refrain from using the platform.
                                </p>
                                <ol>
                                    <li>
                                        <strong>Acceptance of Terms:</strong> By using the Global Flight Search, users agree to abide by these Terms & Conditions. These terms may be subject to change without notice.
                                    </li>
                                    <li>
                                        <strong>Usage Guidelines:</strong> Users shall use the Global Flight Search solely for personal and non-commercial purposes. Any unauthorized use of the platform is prohibited.
                                    </li>
                                    <li>
                                        <strong>Third-Party Content:</strong> The flight search results and booking process are provided by Expedia. Rise Higher Education Inc does not guarantee, endorse, or assume responsibility for the accuracy, quality, or availability of flights listed.
                                    </li>
                                    <li>
                                        <strong>Booking Process:</strong> Users acknowledge that Rise Higher Education Inc does not process flight bookings directly. Users will be directed to Expedia's platform to complete the booking transaction.
                                    </li>
                                    <li>
                                        <strong>Data Privacy:</strong> Users acknowledge that their personal information may be collected and processed by Expedia during the booking process. Users should review Expedia's privacy policy for details.
                                    </li>
                                    <li>
                                        <strong>Liability:</strong> Rise Higher Education Inc shall not be held liable for any direct, indirect, incidental, or consequential damages resulting from the use of the Global Flight Search, including but not limited to flight availability, booking issues, or financial loss.
                                    </li>
                                    <li>
                                        <strong>Modification and Termination:</strong> Rise Higher Education Inc reserves the right to modify, suspend, or terminate the Global Flight Search, in whole or in part, at any time without notice.
                                    </li>
                                    <li>
                                        <strong>Governing Law:</strong> These Terms & Conditions shall be governed by and construed in accordance with the laws of NC,USA without regard to its conflict of law principles.
                                    </li>
                                </ol>
                                <p>
                                    These terms and conditions may be subject to change without prior
                                    notice. It is your responsibility to review them periodically for
                                    any updates.
                                </p>
                                <p>
                                    If you have any questions or concerns regarding the Portal, please
                                    contact our support team at{" "}
                                    <a href="mailto:contact@risehighereducation.com">
                                        contact@risehighereducation.com
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
  );
};

export default CourseSelection;