import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from "recharts";

const BarChartCustom = ({ data }) => {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (Object.entries(data)?.length > 0 && !(arr.length > 1)) {
      let newArr = [];
      Object.entries(data)?.map((itm) => {
        if (itm[1]){
            newArr.push({ name: itm[0], total: itm[1] });
        }
       
      });
      setArr(newArr);
    }
  }, [data]);

  return (
    <div>
      <LineChart width={300} height={150} data={arr}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" className="text-legends" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Line type="monotone" dataKey="total" stroke="#DC7215" />
      </LineChart>
    </div>
  );
};

export default BarChartCustom;
