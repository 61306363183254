import React, { useEffect, useState } from "react";

const Test = (props) => {
  const [data, setData] = useState({
    dna: false,
    gre: {
      score: "",
      date: "",
      quantitative: "",
      verbal: "",
      analytic: "",
    },
    gmat: {
      score: "",
      date: "",
      quantitative: "",
      verbal: "",
      analytic: "",
      reasoning: "",
    },
    toefl: {
      score: "",
      date: "",
      read: "",
      listen: "",
      speak: "",
      write: "",
    },
    ielts: {
      yetToRecieve: "",
      waiver: "",
      resultDate: "",
      examDate: "",
      trf: "",
      date: "",
      score: "",
      english: "",
      read: "",
      listen: "",
      speak: "",
      write: "",
    },
    pte: {
      score: "",
      date: "",
      read: "",
      listen: "",
      speak: "",
      write: "",
    },
    det: {
      score: "",
      date: "",
    },
    sat: {
      score: "",
      date: "",
      readWrite: "",
      math: "",
      essay: "",
    },
    act: {
      score: "",
      date: "",
      math: "",
      read: "",
      science: "",
      english: "",
      write: "",
    },
  });

  const onChange = (e) => {
    const target = e.target.name.split(".");

    if (target.length === 2) {
      setData({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const [edt, setEdit] = useState(false);

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) {
      setData(props.data);
      setEdit(true);
    }
    if(props.data) {
      setEdit(true);
    }
  }, [props.data]);

  const disableFuntion = () => {
    if(edt || data?.dna) {
      return true;
    }
    return false;
  }

  return (
    <div className="row profile-section">
      <div className="col">
        <div className="section-head d-flex flex-row justify-content-between">
          <div className="d-flex">
            {" "}
            Test Taken &nbsp;&nbsp;&nbsp; &nbsp;
            <span className="d-flex justify-content-center align-items-center">
              {" "}
              <input
                type="checkbox"
                disabled={edt}
                checked={data?.dna}
                onChange={(e) =>
                  setData({
                    ...data,
                    dna: e.target.checked,
                  })
                }
              />{" "}
              &nbsp; Does not Apply
            </span>
          </div>

          {edt ? (
            // Display cross icon when in edit mode (edt is true)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square edit-btn"
              viewBox="0 0 16 16"
              onClick={() => setEdit(false)} // Click to exit edit mode
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          ) : (
            // Display pencil icon when not in edit mode (edt is false)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-square-fill edit-btn"
              viewBox="0 0 16 16"
              onClick={() => setEdit(true)} // Click to enter edit mode
            >
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
            </svg>
          )}
        </div>
        <div className="form-group edit-form section-content">
          <div className="row">
            <div className="col">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample2"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      GRE
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              // disabled={data?.dna}
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="gre.score"
                              value={data?.gre?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      GMAT
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              //  disabled={data?.dna}
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="gmat.score"
                              value={data?.gmat?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      TOEFL
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              // disabled={data?.dna}
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="toefl.score"
                              value={data?.toefl?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      IELTS
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              // disabled={data?.dna}
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="ielts.score"
                              value={data?.ielts?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingNine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      PTE
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              // disabled={data?.dna}
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="pte.score"
                              value={data?.pte?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                    >
                      DET
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTen"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              //  disabled={data?.dna}
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="det.score"
                              value={data?.det?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingEleven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEleven"
                      aria-expanded="false"
                      aria-controls="flush-collapseEleven"
                    >
                      SAT
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingEleven"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              // disabled={data?.dna}
                              name="sat.score"
                              value={data?.sat?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwelve">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwelve"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwelve"
                    >
                      ACT
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwelve"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              placeholder=""
                              aria-label=""
                              name="act.score"
                              value={data?.act?.score}
                              onChange={onChange}
                              disabled={disableFuntion()} // Added disabled attribute
                            />
                            <label htmlFor="">Overall Score</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!edt ? (
            <div className="row">
              <div className="col text-end action-btn-group">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => {
                    setEdit(true);
                    if (props.data !== null && props.data !== undefined) {
                      setData(props.data);
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-regular"
                  onClick={() => {
                    // setEdit(true);
                    props.updateData("testTaken", data);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Test;
