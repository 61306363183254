import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import swal from "sweetalert";

import { validateCredential } from '../../../utils/validation';

const CredentialModal = ({ edit, id, data }) => {
  const [credential, setCredential] = useState({
    email: "",
    password: "",
    url: "",
    edit: false,
  });

  const [errors, setErrors] = useState({});

  const [creds, setCreds] = useState([]);

  const getApplications = async () => {
    try {
      const response = await API(
        "get",
        `/application/user/creds/${id}`,
        credential
      );
      //console.log(response?.data?.data);
      setCreds(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      // getApplications();
      setCredential({
        ...credential,
        email: data?.email,
        password: data?.password,
        url: data?.url,
        edit: edit,
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredential({
      ...credential,
      [name]: value,
    });

    // Clear error for the specific field when user starts typing
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleSubmit = async () => {

    const { isValid, errors: validationErrors } = validateCredential(credential);

    if (!isValid) {
      setErrors(validationErrors); // Set validation errors
      return; // Prevent form submission if there are validation errors
    }

    try {
      const response = await API("put", `/application/user/${id}`, credential);
      swal({
        title: "Success!",
        text: response.data.message,
        icon: "success",
        button: "Ok!",
      }).then((res) => window.location.reload());
    } catch (error) {}
  };

  return (
    <div>
      {/* <br /> */}
      {/* <hr /> */}
      {/* {creds?.map((item) => {
        return (
          <div key={item?._id}>
            <p>
              <b>Email</b> : {item?.email}
            </p>
            <p>
              <b>Password</b> : {item?.password}
            </p>
            <p>
              <b>URL</b> : {item?.url}
            </p>
            <hr />
          </div>
        );
      })} */}
      <label htmlFor="" className="form-label">
        Email<span className="required">*</span>
      </label>
      <input
        value={credential.email}
        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
        placeholder="Email"
        type="Email"
        name="email"
        onChange={handleChange}
      />
      {errors.email && <div className="required">{errors.email}</div>} {/* Display error if exists */}
      <br />
      <label htmlFor="" className="form-label">
        Password<span className="required">*</span>
      </label>
      <input
        value={credential.password}
        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
        placeholder="Password"
        name="password"
        onChange={handleChange}
      />
      {errors.password && <div className="required">{errors.password}</div>} {/* Display error if exists */}
      <br />
      <label htmlFor="" className="form-label">
        URL<span className="required">*</span>
      </label>
      <input
        value={credential.url}
        className={`form-control ${errors.url ? 'is-invalid' : ''}`}
        placeholder="URL"
        name="url"
        onChange={handleChange}
      />
      {errors.url && <div className="required">{errors.url}</div>} {/* Display error if exists */}
      <br />
      <button className="btn btn-primary-regular" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default CredentialModal;
