import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import "../Assets/Styles/affiliate.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import swal from "sweetalert";

export const AffiliateDashboard = () => {
  const [user, setUser] = useState({});
  let ref_url = `https://risehigher.education/signup?referral=${user?.referral_id}`;
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  // Accessing query parameters

  const getAffiliateUserDetails = async () => {
    API("get", "/affiliate/user/" + id)
      .then((res) => {
        setUser(res?.data?.data);
      })
      .catch((err) => {});
  };

  const handleGenerateLink = () => {
    API("put", "/affiliate/generate-link/" + id)
      .then((res) => {
        getAffiliateUserDetails();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
          button: "Ok!",
        });
      })
      .catch((err) => { });
  };

  const getAffUsers = async () => {
    setLoading(true);
    try {
      const response = await API(
        "get",
        "/affiliate/get-referrals/" + user?.referral_id
      );
      //console.log(response?.data?.data);
      setData(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAffiliateUserDetails();
  }, []);

  useEffect(() => {
    if (user?.referral_id) {
      getAffUsers();
    }
  }, [user?.referral_id]);

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="header pt-3 pb-3 d-flex justify-content-between">
              <div className="logo">
                <a href="/">
                <img
                  src="https://risehigher.education/logo.png"
                  className="img-fluid logo-sm"
                  alt="Rise Higher Education"
                  title="Rise Higher Education"
                />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col">
              <section className="section main-affiliate">
                <h2>Affiliate Dashboard</h2>
                <p>Bookmark this unique URL to get the updated status of your referrals.</p>
                {user?.referral_id ? (
                  <p>
                    Hey, <strong>{user?.fullName}</strong> your affiliate code is <strong>{user?.referral_id}{" "}</strong>
                  </p>
                ) : (
                  <p>Hey, <strong>{user?.fullName}</strong> generate your affiliate link. </p>
                )}
                <div className="row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      value={user?.referral_id ? ref_url : "Generate Referral URL"}
                      disabled={true}
                    />
                    {!user?.referral_id ? (
                      <button className="btn btn-primary-regular mt-3" onClick={handleGenerateLink}>
                        Generate Link
                      </button>
                    ) : (
                      <>
                      <button
                        className="btn btn-primary-regular mt-3"
                        onClick={() => {
                          navigator.clipboard.writeText(ref_url);
                          swal({
                            title: "Success!",
                            text: "Link Copied",
                            icon: "success",
                            button: "Ok!",
                          });
                        }}
                      >
                        Copy Link
                      </button>
                      <p>You can share this link with your contacts or they can add your referral code at the time of signup.</p>
                      </>
                    )}
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12">
                    {loading ? (
                      <p>Please wait loading your referrals...</p>
                    ) : (
                      <table className="table admin-table">
                        <thead>
                          <tr className="table-dark">
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Referral Status</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Registration Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((e, id) => (
                            <tr key={id}>
                              <th scope="row">{id + 1}</th>
                              <td>
                                {e.firstName} {e.lastName}
                              </td>
                              <td>
                                <a href={`mailto:${e.email}`}>{e.email}</a>
                              </td>
                              <td>{e?.ref_status ? e?.ref_status : 'pending'}</td>
                              <td>₹{e?.amount}</td>
                              <td>{e?.registrationtype}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

