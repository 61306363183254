import React, { useState } from "react";

function Testimonial({ testimonial }) {
  const rating = parseInt(testimonial.user_rating);
  const [isExpanded, setIsExpanded] = useState(false); // State to control expansion

  const starIcons = [];
  for (let i = 0; i < rating; i++) {
    starIcons.push(<i key={i} className="fas fa-star" />);
  }

  // Function to toggle the expanded state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="user-panel" onClick={toggleExpand} style={{ cursor: "pointer" }}>
      <div className="user-avatar-lg mb-4 pb-4">
        <img
          className="user-avatar img-fluid mx-auto d-block"
          src={testimonial.user_image}
          alt={`${testimonial.user_name}'s avatar`}
        />
      </div>
      <i className="fa-solid fa-quote-right fa-flip-horizontal quote-default quote-start" />
      <div className="user-testimonials">
        <div className="user-rating-container">
          <div className="user-rating float-end">
            <div className="user-rating-icon">{starIcons}</div>
          </div>
        </div>
        <div className="user-testimonials-container">
          <p className="user-testimonials-text-title">
            {testimonial.user_name}
            <br />
            <span className="user-testimonials-small-text">
              {testimonial.course_name}
              <br />
              {testimonial.university_name}
            </span>
          </p>
          <p className={`user-testimonials-text ${isExpanded ? "expanded" : ""}`}>
            {testimonial.user_testimonial}
          </p>
        </div>
      </div>
      <i className="fa-solid fa-quote-right quote-default quote-end" />
    </div>
  );
}

export default Testimonial;