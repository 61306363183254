import React, { useEffect, useState } from "react";
import "../Assets/Styles/accommodationData.css";
import { BiHotel } from "react-icons/bi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { PiTelevision } from "react-icons/pi";
import { MdOutlineLocalLaundryService } from "react-icons/md";

import { BsPeople } from "react-icons/bs";
import { PiCheckCircle } from "react-icons/pi";
import { BiSearch } from "react-icons/bi";
import { BiWifi2 } from "react-icons/bi";
import { LuLampDesk } from "react-icons/lu";
import { CgGym } from "react-icons/cg";
import { FaArrowAltCircleLeft, FaCar, FaEye } from "react-icons/fa";
import { BiBus } from "react-icons/bi";

import { RiCustomerService2Line } from "react-icons/ri";
import { Button, Modal, Pagination } from "react-bootstrap";

import room from "../Assets/Media/Images/room1.png";
import logo from "../Assets/Media/Images/logo.png";
// import Slider from '../Housing/imageGallery';
import ImageGallery from "react-image-gallery";
import swal from "sweetalert";

function AccommodationData() {
  const [view, setView] = useState(false);
  const [amber, setAmber] = useState([]);
  const [casitaData, setCasitaData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState();
  const [amberCount, setAmberCount] = useState();
  const [casitaCount, setCasitaCount] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [action, setAction] = useState("");
  const [pages, setPages] = useState([]);
  const [showImg, setShowImg] = useState({
    state: false,
    images: [],
  });

  const [search, setSearch] = useState({
    country_id: "",
    country: "",
    city: "",
    sort: "",
    price: "",
    duration: "",
  });
  // console.clear();

  const searchData = (e) => {
    const { name, value } = e.target;
    setSearch((data) => {
      return {
        ...data,
        [name]: value,
        country_id:
          e.target.options[e.target.selectedIndex].getAttribute("data-id"),
      };
    });
  };

  function capitalizeFirstLetter(string) {
    if (string !== undefined && string !=='' && typeof(string) == 'string'){
      return string?.charAt(0).toUpperCase() + string.slice(1);
    }
    return string
}

  const onSubmit = (currentPage) => {
    if (
      !search?.country ||
      !search?.city ||
      search?.city === "Search by City" ||
      search?.country === "Search by Country"
    ) {
      swal({
        title: "Error!",
        text: "Please select both Country and City",
        icon: "error",
        button: "Ok",
      });
      return;
    }
    setView(true);
    // countriesData()
    setTotalCount(0);
    casitaFetchData(currentPage);
    fetchAmberData(currentPage);
  };

  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState([]);

  const countriesData = async () => {
    try {
      const res = await fetch("https://www.casita.com/api/countries");

      const countriesGetData = await res.json();

      setCountries(countriesGetData.data);
      // console.log(countriesGetData.data)
    } catch (error) {
      //console.log(error, "error come from countries api")
    }
  };

  const cityData = async (id) => {
    try {
      const res = await fetch(`https://www.casita.com/api/cities/${id}`);

      const cityGetData = await res.json();

      setCity(cityGetData.data);
      // console.log(cityGetData.data)
    } catch (error) {
      //console.log(error, "error come from countries api")
    }
  };

  useEffect(() => {
    fetchAmberData(currentPage);
    casitaFetchData(currentPage);
    countriesData();
  }, [currentPage]);

  const fetchAmberData = async (page) => {
    try {
      const response = await fetch(
        `https://base.amberstudent.com/api/v0/leads/partners/rise-highe-e00e18fd/inventories?location_place_name=${
          search?.country + " " + search?.city
        }&p=${page}&limit=${6}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const res = await response.json();
      setAmber(res.data.result);
      // console.log(res.data.result[0].images.map((e)=>e.path));
      //console.log(res.data)
      // console.log(res.data.meta.pages)
      setTotalPages(res.data.meta.pages);
      setAmberCount(res.data.meta.count);
      setCount(res.data.meta.count);
      setPages(res.data.meta.pages);
    } catch (error) {
      console.error("Error fetching amber:", error);
    }
  };

  const casitaFetchData = async (page) => {
    try {
      const url = `https://www.casita.com/student-accommodation/${search.country}/${search.city}?partnerCode=3419&page=${page}`;
      const res = await fetch(url);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const casitaData = await res.json();
      // console.log(casitaData)
      // console.log(casitaData.buildings);
      // console.log(casitaData.buildings.building);
      setCasitaCount(casitaData.pagination.total_count);
      setCasitaData(casitaData.buildings.building);
      return casitaData.buildings.building; // Return the building data
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return null; // Return null or handle the error in an appropriate way
    }
  };

  const combinedAccommodations = [
    // Map amber data if available, else an empty array
    ...(amber
      ? amber?.map((e) => ({
          image: e.image_featured_link,
          gallery: e.images?.map((e) => {
            return { original: e.path, thumbnail: e.path };
          }),
          name: e.name,
          currency: e.pricing.currency,
          price: e.pricing.min_price,
          duration: e.pricing.duration,
          partner: e.partner_link,
          room: e.meta.types.length,
          facility: e.features[0].values?.map((e) => e.name),
          location: e.location.secondary,
        }))
      : []),
    // Map casitaData if available, else an empty array
    ...(casitaData
      ? casitaData?.map((e) => ({
          image: e.image_path_thumb?.map((e) => e),
          name: e.name,
          currency: e.currency_sign,
          gallery: e.image_path_thumb?.map((e) => {
            return { original: e, thumbnail: e };
          }),
          price: e.min_price,
          // roomsType.map((e) => e.price),
          duration: e.price_structure,
          partner: e.end_url,
          room: e.roomsType.length,
          facility: e.facilities?.map((e) => e.title),
          location: e.address,
        }))
      : []),
  ];

  //console.log(combinedAccommodations)
  const combinedCount = amberCount + casitaCount;
  // console.log(combinedCount);

  const priceLowToHigh = [...combinedAccommodations].sort(
    (a, b) => a.price - b.price
  );
  const priceHighToLow = [...combinedAccommodations].sort(
    (a, b) => b.price - a.price
  );

  const [priceSelect, setPriceSelect] = useState();

  const priceFilter = (e) => {
    const selectFilter = e.target.value;
    // console.log(selectFilter);
    setPriceSelect(selectFilter);
  };

  // console.log(combinedAccommodations[0].facility);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scroll(0, 0);
    // casitaFetchData(currentPage)
  };

  const facilities = ["Laundry", "Wi-Fi", "Study Rooms", "TV", "Gym"];

  // Function to generate random facilities
  function generateRandomFacilities() {
    const numberOfRandomFacilities = Math.floor(
      Math.random() * (facilities.length + 1)
    ); // Random number of facilities (0 to total count)
    const randomFacilities = [];

    // Shuffle the facilities array
    const shuffledFacilities = [...facilities].sort(() => Math.random() - 0.5);

    for (let i = 0; i < numberOfRandomFacilities; i++) {
      randomFacilities.push(shuffledFacilities[i]);
    }

    return randomFacilities;
  }

  const [randFac, setRandFac] = useState([]);
  function FacilityIcons() {
    // Generate random facilities
    const randomFacilities = generateRandomFacilities();
    setRandFac(randomFacilities);
  }

  useEffect(() => {
    FacilityIcons();
  }, []);

  useEffect(() => {
    if (totalCount < combinedAccommodations?.length) {
      setTotalCount(combinedAccommodations?.length);
    } else {
      if (action == "next") {
        setTotalCount(combinedAccommodations?.length + totalCount);
      }
    }
  }, [combinedAccommodations?.length, action]);

  const cityName = search.city
    ? search.city.charAt(0).toUpperCase() + search.city.slice(1)
    : null;

  return (
    <>
      <div className="mainBox housing">
        <div className="column">
          <div className="box_1">
            <h5 className="pt-3 px-3">Search Housing</h5>
            <div className="mainBox_1">
              <div className="search-container">
                <select
                  value={search.country}
                  name="country"
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      searchData(e);
                      cityData(
                        e.target.options[e.target.selectedIndex].getAttribute(
                          "data-id"
                        )
                      );
                    }
                  }}
                  placeholder="Search by Countries"
                  className="form-select"
                >
                  <option>Search by Country</option>
                  {countries?.map((e) => {
                    return (
                      <option
                        key={e.country_name}
                        value={e.country_slug}
                        data-id={e.id}
                      >
                        {e.country_name}
                      </option>
                    );
                  })}
                </select>
                <select
                  value={search.city}
                  name="city"
                  onChange={searchData}
                  className="form-select"
                >
                  <option>Search by City</option>
                  {city?.map((e) => {
                    return (
                      <option key={e.city_name} value={e.city_slug}>
                        {e.city_name}
                      </option>
                    );
                  })}
                </select>
                <button onClick={() => onSubmit(0)} className="cardBtn">
                  Search
                </button>
                <button
                  onClick={() => {
                    setSearch({
                      country: "Search by Country",
                      city: "Search by City",
                    });
                    setAmber([]);
                    setCasitaData([])
                    setAmberCount()
                    setCasitaCount()
                    setTotalCount(0)
                    setCount()
                    setView(false)
                  }}
                  className="cardBtn"
                >
                  Reset
                </button>
              </div>
              <div className="selectBox">
                <div>
                  <select onChange={priceFilter} className="form-select">
                    <option selected>Sort</option>
                    <option>Price (Low to High)</option>
                    <option>Price (High to Low)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {view && (
            <>
              <h6 className="mx-3 text-hd2">
                Showing
                {/* {totalCount} / */} {combinedCount ? combinedCount : count}{" "}
                options in {cityName}
                {/* Page:{" "} {currentPage + 0} */}
              </h6>
              {combinedAccommodations.length === 0 ? (
                <img src={room} className="roomImg" alt="room" />
              ) : (
                <>
                  {priceSelect === "Price (Low to High)" ? (
                    <div className="box_2">
                      {priceLowToHigh?.map((e) => {
                        return (
                          <div className="cardBox col">
                            <div>
                              <img
                                src={e.image ? e.image : logo}
                                className="cardImg image-gallery"
                                alt="img"
                              />
                            </div>
                            <div>
                              <div className="cardBody accommodation-card">
                                <h2>{e.name}</h2>
                                <span className="locationName">
                                  {e.location}
                                </span>
                                <div className="allFacilitiy">
                                  {e.facility.includes("Laundry") ||
                                  e.facility.includes("Laundry facilities") ||
                                  e.facility.includes("Laundry on site") ? (
                                    <div className="facilityIcon">
                                      <MdOutlineLocalLaundryService />
                                      <span className="featureText">
                                        Laundry
                                      </span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Wi-Fi") ||
                                  e.facility.includes("High Speed Internet") ||
                                  e.facility.includes("Free Wi-Fi") ||
                                  e.facility.includes("WiFi") ||
                                  e.facility.includes("Free Wifi") ||
                                  e.facility.includes(" Superfast Broadband") ||
                                  e.facility.includes("Wifi") ||
                                  e.facility.includes(
                                    "Free Wi-Fi and broadband throughout"
                                  ) ? (
                                    <div className="facilityIcon">
                                      <BiWifi2 />
                                      <span className="featureText">Wi-Fi</span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Study Rooms") ||
                                  e.facility.includes("Study lounge") ||
                                  e.facility.includes("Study Desk") ||
                                  e.facility.includes("Desk & Chair") ? (
                                    <div className="facilityIcon">
                                      <LuLampDesk />
                                      <span className="featureText">
                                        Study Rooms
                                      </span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Lobby TV") ||
                                  e.facility.includes("Tv in the Room") ||
                                  e.facility.includes("TV") ||
                                  e.facility.includes("TV (communal)") ? (
                                    <div className="facilityIcon">
                                      <PiTelevision />
                                      <span className="featureText">TV</span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("On-Site Gym") ||
                                  e.facility.includes("Gym") ||
                                  e.facility.includes("Gym and Cinema Room") ? (
                                    <div className="facilityIcon">
                                      <CgGym />
                                      <span className="featureText">Gym</span>
                                    </div>
                                  ) : null}
                                  <div className="facilityIcon">
                                    {e.facility.includes("Parking") ? (
                                      <>
                                        <FaCar />
                                        <span className="featureText">
                                          Parking
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="facilityIcon">
                                    {e.facility.includes("Public transport") ? (
                                      <>
                                        <BiBus />
                                        <span className="featureText">
                                          Public Transport
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="facilityIcon">
                                    <BsPeople />{" "}
                                    <spand className="featureText">
                                      On-Site Security
                                    </spand>
                                  </div>
                                  <div className="facilityIcon">
                                    <PiCheckCircle />{" "}
                                    <spand className="featureText">
                                      Utilites Included
                                    </spand>
                                  </div>
                                </div>
                                <div className="roomBox">
                                  <div className="room">
                                    <BiHotel className="BiHotel" />
                                    <span className="featureText">
                                      {" "}
                                      {e.room} Room options
                                    </span>
                                  </div>
                                </div>
                                <div className="priceName_1">
                                  <div className="priceName_2">
                                    <h1 className="priceTag">
                                      {e.price == 0 ? (
                                        "Sold Out"
                                      ) : (
                                        <>
                                          From {e.currency}{" "}
                                          {e?.price ? capitalizeFirstLetter(e.price) : "200"} /{" "}
                                          {capitalizeFirstLetter(e.duration)}
                                        </>
                                      )}
                                    </h1>
                                  </div>
                                  <div>
                                    <a
                                      href={e.partner}
                                      target="_blank"
                                      className="cardBtn"
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        // ViewGallery(e.gallery);
                                        setShowImg({
                                          state: true,
                                          images: e.gallery,
                                        });
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                      className="custom-housing-btn"
                                    >
                                      <FaEye /> View Photos
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : priceSelect === "Price (High to Low)" ? (
                    <div className="box_2">
                      {priceHighToLow?.map((e) => {
                        return (
                          <div className="cardBox col">
                            <div>
                              <img
                                src={e.image ? e.image : logo}
                                className="cardImg"
                                alt="img"
                              />
                            </div>
                            <div className="container">
                              <div className="cardBody accommodation-card">
                                <h2>{e.name}</h2>
                                <span className="locationName">
                                  {e.location}
                                </span>
                                <div className="allFacilitiy">
                                  {e.facility.includes("Laundry") ||
                                  e.facility.includes("Laundry facilities") ||
                                  e.facility.includes("Laundry on site") ? (
                                    <div className="facilityIcon">
                                      <MdOutlineLocalLaundryService />
                                      <span className="featureText">
                                        Laundry
                                      </span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Wi-Fi") ||
                                  e.facility.includes("High Speed Internet") ||
                                  e.facility.includes("Free Wi-Fi") ||
                                  e.facility.includes("WiFi") ||
                                  e.facility.includes("Free Wifi") ||
                                  e.facility.includes(" Superfast Broadband") ||
                                  e.facility.includes(" Wifi") ? (
                                    <div className="facilityIcon">
                                      <BiWifi2 />
                                      <span className="featureText">Wi-Fi</span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Study Rooms") ||
                                  e.facility.includes("Study lounge") ||
                                  e.facility.includes("Study Desk") ? (
                                    <div className="facilityIcon">
                                      <LuLampDesk />
                                      <span className="featureText">
                                        Study Rooms
                                      </span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Lobby TV") ||
                                  e.facility.includes("Tv in the Room") ||
                                  e.facility.includes("TV") ||
                                  e.facility.includes("TV (communal)") ? (
                                    <div className="facilityIcon">
                                      <PiTelevision />
                                      <span className="featureText">TV</span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("On-Site Gym") ||
                                  e.facility.includes("Gym") ||
                                  e.facility.includes("Gym and Cinema Room") ? (
                                    <div className="facilityIcon">
                                      <CgGym />
                                      <span className="featureText">Gym</span>
                                    </div>
                                  ) : null}
                                  <div className="facilityIcon">
                                    {e.facility.includes("Parking") ? (
                                      <>
                                        <FaCar />
                                        <span className="featureText">
                                          Parking
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="facilityIcon">
                                    {e.facility.includes("Public transport") ? (
                                      <>
                                        <BiBus />
                                        <span className="featureText">
                                          Public Transport
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="facilityIcon">
                                    <BsPeople />{" "}
                                    <spand className="featureText">
                                      On-Site Security
                                    </spand>
                                  </div>
                                  <div className="facilityIcon">
                                    <PiCheckCircle />{" "}
                                    <spand className="featureText">
                                      Utilites Included
                                    </spand>
                                  </div>
                                </div>
                                <div className="roomBox">
                                  <div className="room">
                                    <BiHotel className="BiHotel" />
                                    <span className="featureText">
                                      {" "}
                                      {e.room} Room options
                                    </span>
                                  </div>
                                </div>
                                <div className="priceName_1">
                                  <div className="priceName_2">
                                    From{" "}
                                    <h1 className="priceTag">
                                      {e?.price ? capitalizeFirstLetter(e.price)  : "100"} {e.currency}/
                                      {capitalizeFirstLetter(e?.duration) }
                                    </h1>
                                  </div>
                                  <div>
                                    <a
                                      href={e.partner}
                                      target="_blank"
                                      className="cardBtn"
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        // ViewGallery(e.gallery);
                                        setShowImg({
                                          state: true,
                                          images: e.gallery,
                                        });
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                      className="custom-housing-btn"
                                    >
                                      <FaEye /> View Photos
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="box_2">
                      {combinedAccommodations?.map((e) => {
                        return (
                          <div className="cardBox col">
                            <div>
                              <img
                                src={e.image ? e.image : logo}
                                className="cardImg image-gallery"
                                alt="img"
                              />
                            </div>
                            <div className="container">
                              <div className="cardBody accommodation-card">
                                <h2>{e.name}</h2>
                                <span className="locationName">
                                  {e.location}
                                </span>
                                <div className="allFacilitiy">
                                  {e.facility.includes("Laundry") ||
                                  e.facility.includes("Laundry facilities") ||
                                  e.facility.includes("Laundry on site") ? (
                                    <div className="facilityIcon">
                                      <MdOutlineLocalLaundryService />
                                      <span className="featureText">
                                        Laundry
                                      </span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Wi-Fi") ||
                                  e.facility.includes("High Speed Internet") ||
                                  e.facility.includes("Free Wi-Fi") ||
                                  e.facility.includes("WiFi") ||
                                  e.facility.includes("Free Wifi") ||
                                  e.facility.includes(" Superfast Broadband") ||
                                  e.facility.includes(" Wifi") ? (
                                    <div className="facilityIcon">
                                      <BiWifi2 />
                                      <span className="featureText">Wi-Fi</span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Study Rooms") ||
                                  e.facility.includes("Study lounge") ||
                                  e.facility.includes("Study Desk") ? (
                                    <div className="facilityIcon">
                                      <LuLampDesk />
                                      <span className="featureText">
                                        Study Rooms
                                      </span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("Lobby TV") ||
                                  e.facility.includes("Tv in the Room") ||
                                  e.facility.includes("TV") ||
                                  e.facility.includes("TV (communal)") ? (
                                    <div className="facilityIcon">
                                      <PiTelevision />
                                      <span className="featureText">TV</span>
                                    </div>
                                  ) : null}
                                  {e.facility.includes("On-Site Gym") ||
                                  e.facility.includes("Gym") ||
                                  e.facility.includes("Gym and Cinema Room") ? (
                                    <div className="facilityIcon">
                                      <CgGym />
                                      <span className="featureText">Gym</span>
                                    </div>
                                  ) : null}
                                  <div className="facilityIcon">
                                    {e.facility.includes("Parking") ? (
                                      <>
                                        <FaCar />
                                        <span className="featureText">
                                          Parking
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="facilityIcon">
                                    {e.facility.includes("Public transport") ? (
                                      <>
                                        <BiBus />
                                        <span className="featureText">
                                          Public Transport
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  {randFac?.map((facility, index) => (
                                    <div key={index} className="facilityIcon">
                                      {facility.includes("Wi-Fi") ? (
                                        <BiWifi2 />
                                      ) : null}
                                      {facility.includes("TV") ? (
                                        <PiTelevision />
                                      ) : null}
                                      {facility.includes("Gym") ? (
                                        <CgGym />
                                      ) : null}
                                      {facility.includes("Laundry") ? (
                                        <MdOutlineLocalLaundryService />
                                      ) : null}
                                      {facility.includes("Study Rooms") ? (
                                        <LuLampDesk />
                                      ) : null}
                                      <span className="featureText">
                                        {facility}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                                <div className="roomBox">
                                  <div className="room">
                                    <BiHotel className="BiHotel" />
                                    <span className="featureText">
                                      {" "}
                                      {e.room} Room options
                                    </span>
                                  </div>
                                </div>
                                <div className="priceName_1">
                                  <div className="priceName_2">
                                    <span className="fromTag">
                                      {e.price ? "From" : null}
                                    </span>
                                    <span className="priceTag">
                                      {e?.price
                                        ? capitalizeFirstLetter(e.price)
                                        : "Click view for more details"}{" "}
                                      {e.price ? (
                                        <>
                                          {capitalizeFirstLetter(e.currency)}/{capitalizeFirstLetter(e.duration)}
                                        </>
                                      ) : (
                                        e.price
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <a
                                      href={e.partner}
                                      target="_blank"
                                      className="cardBtn"
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        // ViewGallery(e.gallery);
                                        setShowImg({
                                          state: true,
                                          images: e.gallery,
                                        });
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                      className="custom-housing-btn"
                                    >
                                      <FaEye /> View Photos
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              <Pagination className="d-flex justify-content-center mt-5 mb-5">
                <Pagination.Prev
                  disabled={currentPage == 1}
                  onClick={() => {
                    handlePageChange(currentPage - 1);
                    setAction("prev");
                    setTotalCount(totalCount - combinedAccommodations?.length);
                  }}
                >
                  Prev
                </Pagination.Prev>
                <Pagination.Next
                  disabled={
                    totalCount >= (combinedCount ? combinedCount : count)
                  }
                  onClick={() => {
                    handlePageChange(currentPage + 1);
                    setAction("next");
                    // setTotalCount(totalCount + combinedAccommodations?.length);
                  }}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </>
          )}
        </div>

        <Modal
          className="custom_modal_gallery modal-lg"
          show={showImg?.state}
          onHide={() => setShowImg({ ...showImg, state: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Photos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="center-box">
              <ImageGallery
                additionalClass={"gallery-housing"}
                items={showImg.images}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowImg({ ...showImg, state: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AccommodationData;
