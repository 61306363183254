import API from "../../../Api/Api";

const updateToken = async (
  mentorid,
  calendly_userid,
  access_token,
  refresh_token
) => {
  try {
    await API("put", `/mentor/calendly/update-token/${mentorid}`, {
      calendly_userid,
      access_token,
      refresh_token,
    });
  } catch (error) {}
};

const getNewToken = async (mentorid) => {
  try {
    const response = await API("get", `/mentor/calendly/${mentorid}`);

    const res = await fetch(`https://auth.calendly.com/oauth/token`, {
      method: "POST",
      body: new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: response.data.data.refresh_token,
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // Prefer: "code=200, dynamic=true",
        Authorization: `Basic ${btoa(
          process.env.REACT_APP_CALENDLY_CLIENT_ID +
            ":" +
            process.env.REACT_APP_CALENDLY_CLIENT_SECRET
        )}`,
      },
    });
    
    const newToken = await res.json();
    // Get USer Data
    const user = await fetch(`https://api.calendly.com/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken.access_token}`,
      },
    });
    const userData = await user.json();
    updateToken(
      mentorid,
      userData.resource.uri,
      newToken.access_token,
      newToken.refresh_token
    );
    return newToken.access_token;
  } catch (error) {}
};

export { updateToken, getNewToken };
