import React from 'react';
import CurrencyWidget from './CurrencyWidget';
import { useNavigate } from 'react-router-dom'

const CurrencyExchange = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className="body-content no-filterpanel">
            <div className="body-panel d-flex justify-content-between">
                <div className="body-container width-100">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between mt-3">
                                <div className="page-content">
                                    <h1 className="mb-3">Currency Exchange</h1>
                                    <p>
                                        Exchange Currency with Extra Value – Your Financial Edge Abroad
                                    </p>
                                    {/*<a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                                </div>
                                <div>
                                    <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                        <i className='fa fa-home'></i> Back to Dashboard
                                    </button>
                                </div>
                                {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col loading-bg">
                            <CurrencyWidget />
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={()=>{navigate('/simcard')}} className="widget-cta-page float-end mx-4">Get SIM Card</button>
            <div className="footer-links pt-5">
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#disclaimerModal"
                >
                    Disclaimer
                </button>
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#TnCModal"
                >
                    Terms &amp; Conditions
                </button>
                <div
                    className="modal fade"
                    id="disclaimerModal"
                    tabIndex={-1}
                    aria-labelledby="disclaimerModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="disclaimerModal">
                                    Disclaimer
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                            <p>
                                <strong>Service Provider:</strong> Rise Higher Education acts as an intermediary to facilitate currency exchange services through Wise. We do not have direct control over exchange rates, which are determined by market dynamics and government regulations.
                            </p>
                            <p>
                                <strong>Rate Fluctuations:</strong> Exchange rates can fluctuate due to various factors beyond our control, including market conditions, geopolitical events, and government policies. Rise Higher Education cannot guarantee fixed or specific rates.
                            </p>
                            <p>
                                <strong>Currency Exchange Risk:</strong> Clients using our currency exchange services are exposed to the inherent risk of currency rate fluctuations. Rise Higher Education shall not be held liable for any financial losses resulting from currency exchange rate changes.
                            </p>
                            <p>
                                <strong>Market Responsiveness:</strong> Exchange rates provided by Wise are subject to real-time market conditions. Clients should monitor rates and make exchange decisions accordingly.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="TnCModal"
                    tabIndex={-1}
                    aria-labelledby="TnCModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="TnCModal">
                                    Terms &amp; Conditions
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>
                                    <strong>Currency Exchange Services:</strong> Rise Higher Education offers currency exchange services in partnership with Wise. Clients may exchange currency for travel purposes, including cash, traveler's checks, and other instruments.
                                </p>
                                <p>
                                    <strong>Rate Transparency:</strong> Clients will receive exchange rate information from Wise at the time of currency exchange. It is the client's responsibility to review and accept the provided rates before proceeding with the exchange.
                                </p>
                                <p>
                                    <strong>Exchange Limits:</strong> Clients must adhere to any exchange limits imposed by applicable laws and regulations. Rise Higher Education reserves the right to refuse or limit currency exchange requests in compliance with legal requirements.
                                </p>
                                <p>
                                    <strong>Exchange Process:</strong> Clients will be required to provide necessary identification and documentation as per government and regulatory requirements. Failure to provide accurate and complete information may result in the rejection of the exchange request.
                                </p>
                                <p>
                                    <strong>Fees and Commissions:</strong> Clients may be subject to service fees and commissions imposed by Wise for currency exchange services. Rise Higher Education does not charge additional fees beyond those specified by Wise.
                                </p>
                                <p>
                                    <strong>Confirmation:</strong> Once a currency exchange transaction is initiated and confirmed by the client, it cannot be canceled or reversed.
                                </p>
                                <p>
                                    <strong>Client Responsibility:</strong> Clients are responsible for verifying the accuracy of exchange rate information, understanding the risks associated with currency exchange, and making informed decisions.
                                </p>
                                <p>
                                    <strong>Financial Disclaimer:</strong> Rise Higher Education is not a financial advisor, and the currency exchange service provided should not be considered financial advice. Clients should seek independent financial advice when making currency exchange decisions.
                                </p>
                                <p>
                                    <strong>Liability:</strong> Rise Higher Education shall not be liable for any financial losses, damages, or inconveniences arising from currency exchange rate fluctuations, service interruptions, or any other circumstances.
                                </p>
                                <p>
                                    By using Rise Higher Education's currency exchange services, clients acknowledge and accept the terms and conditions outlined in this disclaimer.
                                </p>
                                <p>
                                    These terms and conditions may be subject to change without prior
                                    notice. It is your responsibility to review them periodically for
                                    any updates.
                                </p>
                                <p>
                                    If you have any questions or concerns regarding the Portal, please
                                    contact our support team at{" "}
                                    <a href="mailto:contact@risehighereducation.com">
                                        contact@risehighereducation.com
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        );
    }

export default CurrencyExchange;