import React, { useEffect, useState } from "react";
import { Navigator } from "./Navigator";
import API from "../../Api/Api";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const Course = () => {
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();

  const getAllCourse = async () => {
    setLoading(true);
    try {
      const response = await API("get", `/lms/admin/courses`);
      setCourse(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCourse();
  }, []);

  const handleDelete = async (id) => {
    try {
      API("delete", `/lms/admin/remove-course-and-lessons/${id}`);
      getAllCourse();
    } catch (error) {}
  };

  if (loading) {
    return (
      <div>
        <Navigator />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container">
      <Navigator />
      <div className="container">
      <div className="d-flex justify-content-between">
        <h1 className="page-title">All Courses</h1>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => navigate(`/lms-dashboard-course/add`)}
          >
            Add Course
          </button>
        </div>
      </div>
      <div className="d-flex flex-wrap">
        {course?.map((itm) => {
          return (
            <div
              key={itm?._id}
              className="course-list-item"
            >
              <img
                src={itm?.thumbnail}
                className="course-thumbnail"
              />
              <br />
              <strong>{itm.name}</strong>
              <p>{itm.description}</p>
              <hr />
              <div className="d-flex justify-content-between">
                <div>
                {itm?.ispaid ? (
                  <p>
                    <b>Price:</b> ${itm?.amount}
                  </p>
                ) : (
                  <p>
                    <b>Price:</b> Free
                  </p>
                )}
                </div>
                <div>
                <button
                  className="btn btn-primary-regular me-2"
                  onClick={() =>
                    navigate(`/lms-dashboard-lesson/add?courseid=${itm?._id}`)
                  }
                >
                  {" "}
                  Add Lesson{" "}
                </button>
                <button
                  className="btn btn-secondary me-2"
                  onClick={() =>
                    navigate(`/lms-dashboard-course/add?id=${itm?._id}`)
                  }
                >
                  {" "}
                  Edit{" "}
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      text: "Once deleted, you will not be able to recover this course!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((res) => {
                      if (res) {
                        handleDelete(itm?._id);
                      }
                    });
                  }}
                >
                  {" "}
                  Delete{" "}
                </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default Course;
