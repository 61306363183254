import React, { useEffect, useState } from "react";
import {
  useParams,
  useSearchParams,
  Link,
  useNavigate,
} from "react-router-dom";
import API from "../../Api/Api";
import moment from "moment";
import { Button, Modal, Nav, Pagination } from "react-bootstrap";
import swal from "sweetalert";

const CalenderAdmin = () => {
  const [events, setEvents] = useState([]);
  const params = useParams();
  const [sortedEvents,setSortedEvents] = useState([])
  const [edit, setEdit] = useState({
    modal: false,
    data: {},
  });
  const [newEvent, setNewEvent] = useState({
    modal: false,
    data: {},
  });

  const getCalenerData = async () => {
    try {
      const response = await API("get", `/calender/${params.id}`);
      setEvents(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getCalenerData();
  }, []);

  const handleUpdateEvent = async () => {
    try {
      const response = await API("put", `/calender/${edit?.data?._id}`, {
        title: edit?.data?.title,
        date: edit?.data?.date,
        start: edit?.data?.start,
        end: edit?.data?.end,
        color: edit?.data?.color,
      });
      swal({
        title: "Success!",
        text: response?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      getCalenerData();
      setEdit({ ...edit, modal: false });
    } catch (error) {}
  };

  const handleAddEvent = async () => {
    try {
      const response = await API("post", `/calender/`, {
        title: newEvent?.data?.title,
        date: newEvent?.data?.date,
        start: newEvent?.data?.start,
        end: newEvent?.data?.end,
        color: newEvent?.data?.color,
        user: params.id,
      });
      swal({
        title: "Success!",
        text: response?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      getCalenerData();
      setNewEvent({ modal: false });
    } catch (error) {}
  };

  const deleteEvent = async (id) => {
    try {
      const response = await API("delete", `/calender/${id}`);
      window.location.reload();
      swal("Event Deleted!");
      getCalenerData();
    } catch (error) {
      //console.log(error);
    }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  const SortTable = (type) => {
    let arr = events;
    switch (type) {
      case "title":
        arr.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case "date":
        arr.sort((a, b) => {
          const dateA = a.date || a.start;
          const dateB = b.date || b.start;
          return new Date(dateA) - new Date(dateB);
        });
        break;
      case "end":
        arr.sort((a, b) => {
          return new Date(a.end) - new Date(b.end);
        });
        break;
      default:
        console.error("Invalid type");
    }
    setSortedEvents(arr);
  };

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div className="body-container width-100">
          <div className="d-flex justify-content-between">
            <div className="main-heading mt-0">User Calendar</div>
            <div>
              <button
                className="btn btn-primary-regular"
                onClick={() => setNewEvent({ modal: true })}
              >
                Add New Event
              </button>
              <button
                type="submit"
                onClick={backButton}
                className="btn btn-secondary-regular"
              >
                Back
              </button>
            </div>
          </div>

          <br />
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">
                    Event Title{" "}
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => SortTable("title")}
                      class="fas fa-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    Date{" "}
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => SortTable("date")}
                      class="fas fa-sort"
                    ></i>
                  </th>
                  <th scope="col">
                    End Date{" "}
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => SortTable("end")}
                      class="fas fa-sort"
                    ></i>
                  </th>
                  <th scope="col">Color</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {events?.map((item) => {
                  return (
                    <tr>
                      <td>{item?.title}</td>
                      <td>
                        {moment(item?.date ?? item?.start).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {item?.end
                          ? moment(item?.end).format("DD-MM-YYYY")
                          : "-"}
                      </td>
                      <td>
                        {
                          <span
                            style={{
                              background: item?.color,
                              padding: "7px",
                              color: "#fff",
                              borderRadius: "10px",
                            }}
                          >
                            {item?.color}
                          </span>
                        }
                      </td>
                      <td className="d-flex">
                        <button
                          className="btn btn-primary-regular me-2"
                          onClick={() => setEdit({ modal: true, data: item })}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this imaginary file!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((res) => {
                              if (res) {
                                deleteEvent(item?._id);
                              }
                            });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={newEvent?.modal}
        onHide={() => setNewEvent({ ...newEvent, modal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="floatingInput">
            Event Title<span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={newEvent?.data?.title}
            onChange={(e) => {
              setNewEvent({
                ...newEvent,
                data: {
                  ...newEvent.data,
                  title: e.target.value,
                },
              });
            }}
          />
          <div className="input-group mt-3"></div>
          <label>Date</label>
          <input
            type="date"
            className="form-control"
            disabled={newEvent?.data?.start ? true : false}
            placeholder="Enter Event Start date"
            value={newEvent?.data?.date}
            onChange={(e) => {
              setNewEvent({
                ...newEvent,
                data: {
                  ...newEvent.data,
                  date: e.target.value,
                },
              });
            }}
          />
          <br />
          <h2>OR</h2>
          <div className="input-group">
            <div className="me-3 mb-3">
              <label>Start Date</label>
              <input
                type="date"
                className="form-control"
                disabled={newEvent?.data?.date ? true : false}
                placeholder="Enter Event Start date"
                value={newEvent?.data?.start}
                onChange={(e) => {
                  setNewEvent({
                    ...newEvent,
                    data: {
                      ...newEvent.data,
                      start: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label>End Date</label>
              <input
                type="date"
                disabled={newEvent?.data?.date ? true : false}
                className="form-control"
                placeholder="Enter Event End data"
                value={newEvent?.data?.end}
                onChange={(e) => {
                  setNewEvent({
                    ...newEvent,
                    data: {
                      ...newEvent.data,
                      end: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <input
            type="color"
            className="form-control"
            placeholder="Enter Event Color"
            value={newEvent?.data?.color}
            onChange={(e) => {
              setNewEvent({
                ...newEvent,
                data: {
                  ...newEvent.data,
                  color: e.target.value,
                },
              });
            }}
          />
          <br />
          <button
            className="btn btn-primary-regular"
            onClick={() => {
              handleAddEvent();
            }}
          >
            Save
          </button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setNewEvent({ ...newEvent, modal: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit */}
      <Modal
        show={edit?.modal}
        onHide={() => setEdit({ ...edit, modal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Event Title"
            value={edit?.data?.title}
            onChange={(e) => {
              setEdit({
                ...edit,
                data: {
                  ...edit.data,
                  title: e.target.value,
                },
              });
            }}
          />
          <br />
          <input
            type="date"
            className="form-control"
            placeholder="Enter Event Start date"
            value={edit?.data?.date ?? edit.data.start}
            onChange={(e) => {
              setEdit({
                ...edit,
                data: {
                  ...edit.data,
                  [edit?.data?.date ? "date" : "start"]: e.target.value,
                },
              });
            }}
          />
          <br />
          <input
            type="date"
            disabled={edit?.data?.end ? false : true}
            className="form-control"
            placeholder="Enter Event End data"
            value={edit?.data?.end}
            onChange={(e) => {
              setEdit({
                ...edit,
                data: {
                  ...edit.data,
                  end: e.target.value,
                },
              });
            }}
          />
          <br />
          <input
            type="color"
            className="form-control"
            placeholder="Enter Event Color"
            value={edit?.data?.color}
            onChange={(e) => {
              setEdit({
                ...edit,
                data: {
                  ...edit.data,
                  color: e.target.value,
                },
              });
            }}
          />
          
        </Modal.Body>
        <Modal.Footer>
        <button
            className="btn btn-primary-regular"
            onClick={() => {
              handleUpdateEvent();
            }}
          >
            Save
          </button>
          <Button
            variant="secondary"
            onClick={() => setEdit({ ...edit, modal: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalenderAdmin;
