import React, { useEffect, useState } from "react";

const PastEdu = (props) => {
  const [data, setData] = useState({
    gapYear: "",
    hsc: {
      name: "",
      start: "",
      end: "",
      grade: "",
      gradingSystem: "",
      schoolAddress: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
    },
    ssc: {
      dna: false,
      name: "",
      start: "",
      end: "",
      grade: "",
      gradingSystem: "",
      schoolAddress: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
    },
    grad: {
      dna: false,
      name: "",
      courseSpcl: "",
      univName: "",
      collegeName: "",
      cgpaBacklog: "",
      duration: "",
      schoolAddress: "",
      cityState: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
    },
    postGrad: {
      dna: false,
      name: "",
      courseSpcl: "",
      univName: "",
      collegeName: "",
      cgpaBacklog: "",
      duration: "",
      schoolAddress: "",
      cityState: "",
      zipCode: "",
      country: "",
      degree: "",
      language: "",
      status: "",
      gradDate: "",
    },
  });

  const onChange = (e) => {
    const target = e.target.name.split(".");

    if (target.length === 2) {
      setData({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const [edt, setEdit] = useState(true);

  useEffect(() => {
    // console.log(props.data);
    if (props.data !== null && props.data !== undefined) {
      setData(props.data);
      // setEdit(true);
    }
  }, [props.data]);

  return (
    <div className="row profile-section">
      <div className="col">
        <div className="section-head d-flex flex-row justify-content-between">
          Past Education
          {edt ? (
            // Display cross icon when in edit mode (edt is true)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square edit-btn"
              viewBox="0 0 16 16"
              onClick={() => setEdit(false)} // Click to exit edit mode
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          ) : (
            // Display pencil icon when not in edit mode (edt is false)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-square-fill edit-btn"
              viewBox="0 0 16 16"
              onClick={() => setEdit(true)} // Click to enter edit mode
            >
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
            </svg>
          )}
        </div>
        {/* <div className="row">
                <div className="col">
                    <a href="" className="edit-section">
                        <i className="fa-solid fa-lg fa-pen-to-square" />
                    </a>
                    <ul className="profile-block">
                        <li>Master of Science in Information Technology</li>
                        <li>Computer Enginerring</li>
                        <li>
                            <svg width={14} height={17} viewBox="0 0 14 17" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13 7C13 11.6667 7 15.6667 7 15.6667C7 15.6667 1 11.6667 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                                    stroke="#98A2B3" strokeWidth="1.33333" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path
                                    d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z"
                                    stroke="#98A2B3" strokeWidth="1.33333" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                            India
                        </li>
                        <li>Sikkim Manipal University</li>
                        <li>
                            <svg width={20} height={22} viewBox="0 0 20 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 1V5M6 1V5M1 9H19M3 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3Z"
                                    stroke="#98A2B3" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>08/2020</span> - <span>08/2022</span>
                        </li>
                    </ul>
                </div>
            </div> */}
        <div className="form-group edit-form section-content">
          <div className="row">
            <div className="col">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      10th / HSC{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="row responsive-col">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="date"
                              className="form-control"
                              defaultValue=""
                              name="hsc.start"
                              value={data?.hsc?.start}
                              onChange={onChange}
                              placeholder="Start Date"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="hsc.start">Start Date</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="date"
                              className="form-control"
                              defaultValue=""
                              name="hsc.end"
                              value={data?.hsc?.end}
                              onChange={onChange}
                              placeholder="End Date"
                              aria-label=""
                              min={
                                !isNaN(new Date(data?.hsc?.start))
                                  ? new Date(data?.hsc?.start)
                                      .toISOString()
                                      ?.split("T")[0]
                                  : new Date().toISOString().split("T")[0]
                              }
                              disabled={edt}
                            />
                            <label htmlFor="hsc.end">End Date</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="hsc.grade"
                              value={data?.hsc?.grade}
                              onChange={onChange}
                              placeholder="Grades / % / Score"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="hsc.grade">
                              Grades / % / Score
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      12th / SSC / Technical Diploma / IB  &nbsp;&nbsp;&nbsp; &nbsp;
                      <span className="d-flex justify-content-center align-items-center">
                        {" "}
                        <input
                          type="checkbox"
                          disabled={edt}
                          checked={data?.ssc?.dna}
                          onChange={(e) =>
                            setData({
                              ...data,
                              ssc: {...data?.ssc, dna: e.target.checked},
                            })
                          }
                        />{" "}
                        &nbsp; Does not Apply
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="row responsive-col">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="date"
                              className="form-control"
                              defaultValue=""
                              name="ssc.start"
                              value={data?.ssc?.start}
                              onChange={onChange}
                              placeholder="Start Date"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="ssc.start">Start Date</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="date"
                              className="form-control"
                              defaultValue=""
                              name="ssc.end"
                              value={data?.ssc?.end}
                              onChange={onChange}
                              placeholder="End Date"
                              aria-label=""
                              disabled={edt}
                              min={
                                !isNaN(new Date(data?.ssc?.start))
                                  ? new Date(data?.ssc?.start)
                                      .toISOString()
                                      ?.split("T")[0]
                                  : new Date().toISOString().split("T")[0]
                              }
                            />
                            <label htmlFor="ssc.end">End Date</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="ssc.grade"
                              value={data?.ssc?.grade}
                              onChange={onChange}
                              placeholder="Grades / % / Score"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="ssc.grade">
                              Grades / % / Score
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Graduation &nbsp;&nbsp;&nbsp; &nbsp;
                      <span className="d-flex justify-content-center align-items-center">
                        {" "}
                        <input
                          type="checkbox"
                          disabled={edt}
                          checked={data?.grad?.dna}
                          onChange={(e) =>
                            setData({
                              ...data,
                              grad: {...data?.grad, dna: e.target.checked},
                            })
                          }
                        />{" "}
                        &nbsp; Does not Apply
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="row responsive-col">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <select
                              name="grad.name"
                              value={data?.grad?.name}
                              onChange={onChange}
                              className="form-select"
                              placeholder="Select Degree"
                              disabled={edt}
                            >
                              <option value="Select Degree">
                                Select Degree
                              </option>
                              <option value="Bachelor of Arts (B.A.)">
                                Bachelor of Arts (B.A.)
                              </option>
                              <option value="Bachelor of Science (B.S.)">
                                Bachelor of Science (B.S.)
                              </option>
                              <option
                                value="Bachelor of Engineering
                                                            (B.Eng.)"
                              >
                                Bachelor of Engineering (B.Eng.)
                              </option>
                              <option
                                value="Bachelor of Technology
                                                            (B.Tech)"
                              >
                                Bachelor of Technology (B.Tech)
                              </option>
                              <option
                                value="Bachelor of Business
                                                            Administration (BBA)"
                              >
                                Bachelor of Business Administration (BBA)
                              </option>
                              <option value="Bachelor of Fine Arts (BFA)">
                                Bachelor of Fine Arts (BFA)
                              </option>
                              <option
                                value="Bachelor of Computer
                                                            Science (B.Comp.Sc. or BCS)"
                              >
                                Bachelor of Computer Science (B.Comp.Sc. or BCS)
                              </option>
                              <option
                                value="Bachelor of Medicine,
                                                            Bachelor of Surgery (MBBS)"
                              >
                                Bachelor of Medicine, Bachelor of Surgery (MBBS)
                              </option>
                              <option
                                value="Bachelor of Education
                                                            (B.Ed.)"
                              >
                                Bachelor of Education (B.Ed.)
                              </option>
                              <option
                                value="Bachelor of Social Work
                                                            (BSW)"
                              >
                                Bachelor of Social Work (BSW)
                              </option>
                              <option
                                value="Bachelor of Commerce
                                                            (B.Com)"
                              >
                                Bachelor of Commerce (B.Com)
                              </option>
                              <option value="Bachelor of Nursing (B.N.)">
                                Bachelor of Nursing (B.N.)
                              </option>
                              <option
                                value="Bachelor of Architecture
                                                            (B.Arch)"
                              >
                                Bachelor of Architecture (B.Arch)
                              </option>
                              <option value="Bachelor of Music (B.Mus.)">
                                Bachelor of Music (B.Mus.)
                              </option>
                              <option value="Bachelor of Laws (LL.B)">
                                Bachelor of Laws (LL.B)
                              </option>
                              <option
                                value="Bachelor of Pharmacy
                                                            (B.Pharm)"
                              >
                                Bachelor of Pharmacy (B.Pharm)
                              </option>
                              <option
                                value="Bachelor of Dental Surgery
                                                            (BDS)"
                              >
                                Bachelor of Dental Surgery (BDS)
                              </option>
                              <option
                                value="Bachelor of Science in
                                                            Nursing (BSN)"
                              >
                                Bachelor of Science in Nursing (BSN)
                              </option>
                              <option value="Bachelor of Fine Arts (BFA)">
                                Bachelor of Fine Arts (BFA)
                              </option>
                              <option
                                value="Bachelor of Economics
                                                            (B.Econ)"
                              >
                                Bachelor of Economics (B.Econ)
                              </option>
                              <option
                                value="Bachelor of Communication
                                                            (B.Comm)"
                              >
                                Bachelor of Communication (B.Comm)
                              </option>
                              <option
                                value="Bachelor of Environmental
                                                            Science (B.Env.Sc)"
                              >
                                Bachelor of Environmental Science (B.Env.Sc)
                              </option>
                              <option value="Bachelor of Design (B.Des)">
                                Bachelor of Design (B.Des)
                              </option>
                              {/* Add more options here */}
                            </select>
                            <label htmlFor="grad.name">Degree</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="grad.courseSpcl"
                              value={data?.grad?.courseSpcl}
                              onChange={onChange}
                              placeholder="Course / Specialization (if any)"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="grad.courseSpcl">
                              Course / Specialization (if any)
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="grad.univName"
                              value={data?.grad?.univName}
                              onChange={onChange}
                              placeholder="University Name"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="grad.univName">
                              University Name
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row responsive-col">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="grad.collegeName"
                              value={data?.grad?.collegeName}
                              onChange={onChange}
                              placeholder="College Name"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="grad.collegeName">
                              College Name
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="grad.cgpaBacklog"
                              value={data?.grad?.cgpaBacklog}
                              onChange={onChange}
                              placeholder="CGPA / % / Backlogs (if any)"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="grad.cgpaBacklog">
                              CGPA / % / Backlogs (if any)
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="grad.duration"
                              value={data?.grad?.duration}
                              onChange={onChange}
                              placeholder="Duration"
                              aria-label=""
                              disabled={edt}
                            />
                            <label htmlFor="grad.duration">
                              Duration (yyyy - yyyy)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Post Graduation  &nbsp;&nbsp;&nbsp; &nbsp;
                      <span className="d-flex justify-content-center align-items-center">
                        {" "}
                        <input
                          type="checkbox"
                          disabled={edt}
                          checked={data?.postGrad?.dna}
                          onChange={(e) =>
                            setData({
                              ...data,
                              postGrad: {...data?.postGrad, dna: e.target.checked},
                            })
                          }
                        />{" "}
                        &nbsp; Does not Apply
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="row responsive-col">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <select
                              name="postGrad.name"
                              value={data?.postGrad?.name}
                              onChange={onChange}
                              className="form-select"
                              placeholder="Select Degree"
                              disabled={edt} // Add the disabled attribute here
                            >
                              <option value="Select Degree">
                                Select Degree
                              </option>
                              <option value="Master of Arts (M.A.)">
                                Master of Arts (M.A.)
                              </option>
                              <option value="Master of Science (M.S.)">
                                Master of Science (M.S.)
                              </option>
                              <option
                                value="Master of Business
                                                                    Administration (MBA)"
                              >
                                Master of Business Administration (MBA)
                              </option>
                              <option
                                value="Master of Engineering
                                                                    (M.Eng.)"
                              >
                                Master of Engineering (M.Eng.)
                              </option>
                              <option
                                value="Master of Technology
                                                                    (M.Tech)"
                              >
                                Master of Technology (M.Tech)
                              </option>
                              <option value="Master of Fine Arts (MFA)">
                                Master of Fine Arts (MFA)
                              </option>
                              <option
                                value="Master of Computer Science
                                                                    (M.Comp.Sc. or MCS)"
                              >
                                Master of Computer Science (M.Comp.Sc. or MCS)
                              </option>
                              <option value="Master of Education (M.Ed.)">
                                Master of Education (M.Ed.)
                              </option>
                              <option value="Master of Social Work (MSW)">
                                Master of Social Work (MSW)
                              </option>
                              <option value="Master of Commerce (M.Com)">
                                Master of Commerce (M.Com)
                              </option>
                              <option value="Master of Nursing (M.N.)">
                                Master of Nursing (M.N.)
                              </option>
                              <option
                                value="Master of Architecture
                                                                    (M.Arch)"
                              >
                                Master of Architecture (M.Arch)
                              </option>
                              <option value="Master of Music (M.Mus.)">
                                Master of Music (M.Mus.)
                              </option>
                              <option value="Master of Laws (LL.M)">
                                Master of Laws (LL.M)
                              </option>
                              <option
                                value="Master of Pharmacy
                                                                    (M.Pharm)"
                              >
                                Master of Pharmacy (M.Pharm)
                              </option>
                              <option
                                value="Master of Dental Surgery
                                                                    (MDS)"
                              >
                                Master of Dental Surgery (MDS)
                              </option>
                              <option
                                value="Master of Science in
                                                                    Nursing (MSN)"
                              >
                                Master of Science in Nursing (MSN)
                              </option>
                              <option value="Master of Fine Arts (MFA)">
                                Master of Fine Arts (MFA)
                              </option>
                              <option
                                value="Master of Economics
                                                                    (M.Econ)"
                              >
                                Master of Economics (M.Econ)
                              </option>
                              <option
                                value="Master of Communication
                                                                    (M.Comm)"
                              >
                                Master of Communication (M.Comm)
                              </option>
                              <option
                                value="Master of Environmental
                                                                    Science (M.Env.Sc)"
                              >
                                Master of Environmental Science (M.Env.Sc)
                              </option>
                              <option value="Master of Design (M.Des)">
                                Master of Design (M.Des)
                              </option>
                              <option
                                value="Master of Public
                                                                    Administration (MPA)"
                              >
                                Master of Public Administration (MPA)
                              </option>
                              <option
                                value="Master of Public Health
                                                                    (MPH)"
                              >
                                Master of Public Health (MPH)
                              </option>
                              <option
                                value="Master of International
                                                                    Business (MIB)"
                              >
                                Master of International Business (MIB)
                              </option>
                              {/* Add other options */}
                            </select>
                            <label htmlFor="">Degree</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="postGrad.courseSpcl"
                              value={data?.postGrad?.courseSpcl}
                              onChange={onChange}
                              placeholder=""
                              aria-label=""
                              disabled={edt} // Add the disabled attribute here
                            />
                            <label htmlFor="">
                              Course / Specialization (if any)
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="postGrad.univName"
                              value={data?.postGrad?.univName}
                              onChange={onChange}
                              placeholder=""
                              aria-label=""
                              disabled={edt} // Add the disabled attribute here
                            />
                            <label htmlFor="">University Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="row responsive-col">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="postGrad.collegeName"
                              value={data?.postGrad?.collegeName}
                              onChange={onChange}
                              placeholder=""
                              aria-label=""
                              disabled={edt} // Add the disabled attribute here
                            />
                            <label htmlFor="">College Name</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="postGrad.cgpaBacklog"
                              value={data?.postGrad?.cgpaBacklog}
                              onChange={onChange}
                              placeholder=""
                              aria-label=""
                              disabled={edt} // Add the disabled attribute here
                            />
                            <label htmlFor="">
                              CGPA / % / Backlogs (if any)
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              name="postGrad.duration"
                              value={data?.postGrad?.duration}
                              onChange={onChange}
                              placeholder=""
                              aria-label=""
                              disabled={edt} // Add the disabled attribute here
                            />
                            <label htmlFor="">Duration (yyyy - yyyy)</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-floating mb-3 mt-4">
                <input
                  type="text"
                  className="form-control"
                  disabled={edt}
                  defaultValue=""
                  name="gapYear"
                  value={data?.gapYear}
                  onChange={onChange}
                  placeholder=""
                  aria-label=""
                />
                <label htmlFor="">Gap Year (if any)</label>
              </div>
            </div>
          </div>
          {!edt ? (
            <div className="row">
              <div className="col text-end action-btn-group">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => {
                    setEdit(true);
                    setData(props.data);
                  }}
                  // disabled={data.hsc.name.length < 1}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-regular"
                  onClick={() => {
                    // setEdit(true);
                    props.updateData("education", data);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PastEdu;
