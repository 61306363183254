import React from "react";
import { useNavigate } from "react-router-dom";
import { MdWarning } from "react-icons/md";
const PleaseLoginModal = ({ timer }) => {
  const navigate = useNavigate();

  return (
    <div className="modal-body text-center">
      <MdWarning style={{ color: "#DC7215", fontSize: "4rem" }} />
      <h1>Please login to continue</h1>
      <br />
      <div className="modal-footer d-flex justify-content-end">
        {(!localStorage.getItem("seconds") && timer) && (
          <button
            onClick={() => {
              localStorage.setItem("seconds", 300);
              // localStorage.setItem("token", 'test0toke0public');
              // navigate("/counseling");
              window.location.reload();
            }}
            className="widget-cta-page-nopos responsive-visible float-end mb-3"
          >
            Explore for 5 minutes without login ?
          </button>
        )}

        <button
          onClick={() => {
            localStorage.setItem("redirect_to", window.location.pathname);
            navigate("/login?utm_source=public");
          }}
          className="widget-cta-page-nopos responsive-visible float-end mb-3"
        >
          Click here to login
        </button>
        <button
          onClick={() => {
            localStorage.setItem("redirect_to", window.location.pathname);
            navigate("/signup?utm_source=public");
          }}
          className="widget-cta-page-nopos responsive-visible float-end mb-3"
        >
          Or sign up
        </button>
      </div>
    </div>
  );
};

export default PleaseLoginModal;
