import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import NavBarAdmin from "./Nav/Nav";
import { Pagination, OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import { MdDelete, MdBook, MdMail, MdAdd } from "react-icons/md";
import swal from "sweetalert";
import moment from "moment/moment";

const ReferralDashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const handleSubmit = async (credentials) => {
    try {
      const response = await API("post", `/user/add-user`, {
        fname: credentials.firstName,
        lname: credentials.lastName,
        phone: credentials.phone || credentials.phoneNumber,
        dest: credentials.dest,
        email: credentials.email,
        countryCode: credentials.countryCode,
        user_counsellor: localStorage.getItem("_id"),
      });
      const json = response?.data;
      swal({
        title: "User Added!",
      });
    } catch (err) {}
  };

  // Fetch referrals from the API
  const getReferrals = async () => {
    setLoading(true);
    try {
      const response = await API("get", `/referral/referrals?page=${currentPage}`);
      setData(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
      console.error("Error fetching referrals", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle deleting a referral
  const handleDelete = async (id) => {
    try {
      // Correcting the API endpoint to match your route definition
      const response = await API("delete", `/referral/${id}`);
      swal({
        title: "Success!",
        text: "Referral Deleted Successfully!",
        icon: "success",
        button: "Ok!",
      });
      getReferrals(); // Refresh referrals after deletion
    } catch (error) {
      console.error("Error deleting referral", error);
    }
  };

  // Fetch referrals when the component loads or when the page changes
  useEffect(() => {
    getReferrals();
  }, [currentPage]);

  // Handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">All Referrals</h1>
        </div>
        <br />
        {loading ? (
          <p>Loading referrals...</p>
        ) : (
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Study Destination</th>
                  <th scope="col">Referred By</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((referral, id) => (
                    <tr key={id}>
                      <th scope="row">{id + 1}</th>
                      <td>{referral?.firstName} {referral?.lastName}</td>
                      <td>
                        <a href={`mailto:${referral?.email}`}>{referral?.email}</a>
                      </td>
                      <td>
                        {referral?.countryCode} {referral?.phoneNumber}
                        <br />
                        <a href={`tel:${referral?.countryCode}${referral?.phoneNumber}`}>
                          <i className="fa fa-phone"></i>
                        </a>{" "}
                        |{" "}
                        <a href={`https://wa.me/${referral?.countryCode}${referral?.phoneNumber}`}>
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </td>
                      <td>{referral?.dest?.join(', ')}</td>
                      <td>{referral?.referredBy?.firstName} {referral?.referredBy?.lastName}</td>
                      <td>{moment(referral.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
                      <td>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Email</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              navigate("/dashboard-mass-email?email=" + referral?.email);
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdMail className="" style={{ color: "#DC7215" }} />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover the referral data!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((res) => {
                                if (res) {
                                  handleDelete(referral?._id);
                                }
                              });
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdDelete className="MdDelete" />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Add as User</Tooltip>
                          }
                        >
                          <button
                            onClick={()=> handleSubmit(referral)}
                            className="btn useDeleteBtn"
                          >
                            <MdAdd style={{ color: "#DC7215" }} />{" "}
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="required p-2">No Data Found</p>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination className="d-flex justify-content-end mb-5">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default ReferralDashboard;