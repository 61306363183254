import React, { useEffect, useState } from "react";
import Editor from "../Editor/Editor";
import Select from "react-select";
import TestType from "../../Assets/Data/testcategories.json";
import API from "../../../Api/Api";
import swal from "sweetalert";
import { useNavigate, useSearchParams } from "react-router-dom";

const AddQuestion = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: "",
    description: "",
    questiontype: [],
    type: "mcq",
    options: [""],
    expectedans: [""],
    marks: "",
    category: [],
    explanation: "",
    subquestionsoptions: [],
    chapterid: "",
  });

  const [countOptions, setCountOptions] = useState([0]);
  const [countExpectedAns, setCountExpectedAns] = useState([0]);
  const [searchParams] = useSearchParams();

  const [allChapter, setAllChapter] = useState([]);

  function objectToFormData(obj) {
    const formData = new FormData();

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }

  const handleSubmit = async (e) => {
    e?.preventDefault();
    let formData = objectToFormData(data);
    try {
      const response =
        typeof searchParams.get("id") == "string"
          ? await API(
            "put",
            `/test/admin/update-question/${searchParams.get("id")}`,
            data
          )
          : await API("post", `/test/admin/create-question`, formData, {
            "Content-Type": "multipart/form-data",
          });
      swal({
        title: "Success!",
        text: response?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      navigate("/lms-dashboard-questions");
    } catch (error) { }
  };

  const generateQuestionsCategory = () => {
    let arr = [];
    data?.questiontype?.map((itm) => {
      TestType[itm]?.map((itm) => {
        arr.push({ value: itm, label: itm });
      });
    });
    return arr;
  };
  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getSingleQuestions = async () => {
    try {
      const response = await API(
        "get",
        `/test/admin/questions/${searchParams.get("id")}`
      );
      setCountExpectedAns(
        Array.from(
          { length: response?.data?.data?.expectedans?.length },
          (_, index) => index + 1
        )
      );
      setCountOptions(
        Array.from(
          { length: response?.data?.data?.options?.length },
          (_, index) => index + 1
        )
      );
      setData(response?.data?.data);
    } catch (error) { }
  };
  const getChapters = async () => {
    try {
      const response = await API("get", `/lms/admin/all-chapters/`);
      setAllChapter(response?.data?.data);
    } catch (error) { }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/lms-dashboard-tests");
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      getSingleQuestions();
    }
    getChapters();
  }, []);

  const handleRemove = (e, item, name) => {
    e.preventDefault();

    setData({
      ...data,
      [name]: data[name]?.filter((itm, i) => itm != item),
    });
  };

  return (
    <>
      <div className="container admin-createpage">
        <div>
          <div className="main-heading">Add/Edit Question</div>
        </div>
        <div>
          <div className="page-form">
            <form>
              <div>
                <label>Question Title</label>
                <Editor height={200} data={data} setData={setData} name="title" />
              </div>
              <br />
              <div>
                <label>Question description (Paragraph)</label>
                <Editor data={data} setData={setData} name="description" />
              </div>
              <br />
              <div>
                <label>Is this Course Question? Select Chapter</label>
                <select
                  className="form-select"
                  name="chapterid"
                  onChange={handleOnChange}
                  value={data?.chapterid}
                  placeholder="Chapter id"
                >
                  <option value="">Select Chapter</option>
                  {allChapter?.map((itm) => (
                    <option value={itm?._id} key={itm?._id}>
                      <b>{itm?.course.name}</b> - {itm?.name}
                    </option>
                  ))}
                </select>
              </div>

              <br />
              <div>
                <label>Test Category</label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  options={Object.keys(TestType)?.map((itm) => ({
                    value: itm,
                    label: itm,
                  }))}
                  value={data?.questiontype?.map((itm) => ({
                    value: itm,
                    label: itm,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setData((prevData) => ({
                      ...prevData,
                      questiontype: newData,
                      category: [],
                    }));
                  }}
                  placeholder="Question Type"
                />
              </div>
              <br />
              <div>
                <label>Question Category</label>
                <Select
                  className="app-multiSelect"
                  isMulti
                  options={generateQuestionsCategory()}
                  value={data?.category?.map((itm) => ({
                    value: itm,
                    label: itm,
                  }))}
                  onChange={(selectedOptions) => {
                    const newData = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setData((prevData) => ({
                      ...prevData,
                      category: newData,
                    }));
                  }}
                  placeholder="Question Category"
                />
              </div>
              <br />
              <div>
                <label>Type</label>
                <select
                  className="form-select"
                  name="type"
                  onChange={handleOnChange}
                  value={data?.type}
                  placeholder="Type"
                >
                  <option value="mcq">MCQ</option>
                  <option value="mmcq">Multi Choice MCQ</option>
                  <option value="text">Textual</option>
                  <option value="audio">Audio</option>
                  <option value="video">Video</option>
                  <option value="subquestions">Sub Question</option>
                </select>
              </div>
              {data?.type == "audio" && (
                <div>
                  <br />
                  <label>Choose Audio</label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".mp3,audio/*"
                    onChange={(e) => {
                      setData({ ...data, audio: e.target.files[0] });
                    }}
                  />
                </div>
              )}

              {data?.type == "video" && (
                <div>
                  <br />
                  <label>Enter Video URL</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setData({ ...data, url: e.target.value });
                    }}
                  />
                </div>
              )}

              <br />
              <div>
                <label>
                  {data?.type !== "subquestions" ? "Options" : "Sub Questions"}
                </label>
                {data?.options?.map((itm, i) => (
                  <>
                    <div className="d-flex">
                      <input
                        key={data.options[i]}
                        value={data.options[i]}
                        className="form-control"
                        autoFocus={
                          data.options[i].length > 0 ? true : false
                        }
                        onChange={(e) => {
                          let arr = [...data?.options];
                          arr[i] = e.target.value.replace(/,/g, "");
                          setData({
                            ...data,
                            options: arr,
                          });
                        }}
                        placeholder={
                          data?.type !== "subquestions"
                            ? "Enter Option"
                            : "Enter Sub Question"
                        }
                      />{" "}
                      &nbsp;
                      {data?.type == "subquestions" && (
                        <input
                          key={data.subquestionsoptions[i]}
                          placeholder="Option A,Option B"
                          className="form-control"
                          value={data.subquestionsoptions[i]}
                          autoFocus={
                            data.subquestionsoptions[i].length > 0 ? true : false
                          }
                          onChange={(e) => {
                            let arr = [...data?.subquestionsoptions];
                            //console.log(i);
                            arr[i] = e.target.value;
                            setData({
                              ...data,
                              subquestionsoptions: arr,
                            });
                          }}
                        />
                      )}
                      &nbsp;
                      <button
                        className="btn"
                        onClick={(e) => handleRemove(e, itm, "options")}
                      >
                        <span><i className="fa fa-trash fa-lg"></i></span>
                      </button>
                    </div>
                    <br />
                  </>
                ))}
                <button
                  style={{ width: "120px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setData({ ...data, options: [...data?.options, ""] });
                    // setCountOptions([
                    //   ...countOptions,
                    //   countOptions[countOptions?.length - 1] + 1,
                    // ]);
                  }}
                  className="btn btn-primary"
                >
                  Add more +{" "}
                </button>
              </div>
              <br />
              <div>
                <label>Expected Answer</label>
                {data?.expectedans?.map((itm, i) => (
                  <>
                    <div className="d-flex">
                      <input
                        key={data.expectedans[i]}
                        value={data.expectedans[i]}
                        autoFocus={
                          data.expectedans[i].length > 0 ? true : false
                        }
                        className="form-control"
                        onChange={(e) => {
                          let arr = [...data?.expectedans];
                          arr[i] = e.target.value.replace(/,/g, "");
                          setData({
                            ...data,
                            expectedans: arr,
                          });
                        }}
                        placeholder={"Enter Correct Answer"}
                      />{" "}
                      &nbsp;
                      <button
                        className="btn"
                        onClick={(e) => handleRemove(e, itm, "expectedans")}
                      >
                        <span><i className="fa fa-trash fa-lg"></i></span>
                      </button>
                    </div>

                    <br />
                  </>
                ))}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setData({ ...data, expectedans: [...data?.expectedans, ""] });
                    // setCountExpectedAns([
                    //   ...countExpectedAns,
                    //   countExpectedAns[countExpectedAns?.length - 1] + 1,
                    // ]);
                  }}
                  className="btn btn-primary-regular"
                >
                  Add more +{" "}
                </button>
              </div>
              <br />
              <div>
                <label>Explanation</label>
                <Editor
                  height={300}
                  data={data}
                  setData={setData}
                  name="explanation"
                />
              </div>
              <div>
                <br />
                <div>
                  <label>Marks</label>
                  <input
                    className="form-control"
                    placeholder=""
                    value={data?.marks}
                    type="number"
                    name="marks"
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <br />
              <button onClick={handleSubmit} className="btn btn-primary-regular">
                Save
              </button>
              <button
                type="submit"
                onClick={backButton}
                className="btn btn-secondary-regular"
              >
                Back
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuestion;
