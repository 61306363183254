import axios from "axios";
import { url } from "../Components/Constants";

const Axios = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    token: localStorage.getItem("token"),
  },
});
export default Axios;
