import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import NavBarAdmin from "./Nav/Nav";
import { OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { MdDelete, MdBook, MdMail, MdPlusOne, MdHdrPlus, MdAdd, MdEdit } from "react-icons/md";
import swal from "sweetalert";
import AllBookingAdmin from "../Mentors/Pages/AllBookingsStudentAdmin";
import readXlsxFile from "read-excel-file";
import { url } from "../Constants";
import moment from "moment/moment";

const LeadFormDashboard = () => {
  const [showBookings, setShowBookings] = useState(false);
  const [readData, setReadData] = useState([]);
  const [metaData, setMetaData] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRead = (e) => {
    setMetaData({ ...metaData, name: e[0].name });
    readXlsxFile(e[0]).then((rows) => {
      setReadData(rows);
    });
  };
  const handleSubmit = async (credentials) => {
    try {
      const response = await API("post", `/user/add-user`, {
        fname: credentials.firstName,
        lname: credentials.lastName,
        phone: credentials.phone,
        dest: credentials.studyDest,
        email: credentials.email,
        countryCode: credentials.countryCode,
        user_counsellor: localStorage.getItem("_id"),
      });
      const json = response?.data;
      swal({
        title: "User Added!",
      });
    } catch (err) {}
  };

  const getLeads = async () => {
    setLoading(true);
    try {
      const response = await API("get", `/lead?page=${currentPage}`);
      // console.log(response?.data?.data);
      setData(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await API("delete", `/lead/${id}`);
      swal({
        title: "Success!",
        text: "Deleted Successfully!",
        icon: "success",
        button: "Ok!",
      });
      getLeads();
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getLeads();
  }, [currentPage]);

  const handleUpload = async () => {
    for (let i = 1; i < readData.length; i++) {
      let data = {
        firstName: readData[i][0],
        lastName: readData[i][1],
        email: readData[i][2],
        countryCode: readData[i][3],
        phone: readData[i][4],
        message: readData[i][5],
        hearaboutus: readData[i][6],
        createdAt: readData[i][7],
      };
      try {
        await API("post", `/lead/excel`, data);
        getLeads();
      } catch (error) {}
    }
  };

  const [actionModal, setActionModal] = useState({
    notes: false,
    mail: false,
    email: "",
    user: "",
  });

  const [mail, setMail] = useState({
    subject: "",
    body: "",
  });

  const [notes, setNotes] = useState("");
  const [allNotes, setAllNotes] = useState({
    data: [],
    loading: false,
  });

  const [allMails, setAllMails] = useState({
    data: [],
    loading: false,
  });

  const getAllNotesForAUser = async (user, note) => {
    setAllNotes({ data: [], loading: true });
    try {
      const response = await fetch(`${url}/note/read`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          user: user,
          // createdBy: localStorage?.getItem("_id"),
        }),
      });
      const json = await response.json();
      setAllNotes({ data: [{ note: note }, ...json?.data], loading: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
      setAllNotes({ data: [], loading: false });
    }
  };

  const handleAddNotes = async () => {
    try {
      const response = await API("post", `${url}/note/create`, {
        note: notes,
        user: actionModal?.user,
        createdBy: localStorage?.getItem("_id"),
        type: "lead",
      });
      const json = response.data;
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setActionModal({ notes: false, mail: false });
      setNotes("");
    } catch (error) {
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">All Leads</h1>
          <div className="d-flex gap-2">
            <a href="/createlead" className="me-3 mt-2">Create Lead</a>
            <div className="d-flex">
            <input
              type="file"
              className="form-control file-upload"
              id="File"
              onChange={(e) => handleRead(e.target.files)}
            />
            <button className=" ms-2 btn btn-primary-regular" onClick={handleUpload}>
              Upload
            </button>
            </div>
          </div>
        </div>
        <br />
        {loading ? (
          <p>Please wait loading leads...</p>
        ) : (
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Id</th>
                  <th scope="col">Name </th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone number</th>
                  <th scope="col">Message</th>
                  <th scope="col">Hear About Us</th>
                  <th scope="col">Study Destination</th>
                  <th scope="col">Region</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((e, id) => (
                    <tr key={id}>
                      <th scope="row">{id + 1}</th>
                      <td>
                        {e?.firstName && e?.lastName
                          ? `${e.firstName} ${e.lastName}` // If firstName and lastName are available
                          : e?.name // Otherwise, show name
                        }
                      </td>
                      <td>
                        <a href={`mailto:${e?.email}`}>{e?.email}</a>
                      </td>
                      <td>
                        {e?.countryCode} {e.phone}
                        <br />
                        <a href={`tel:${e?.countryCode}${e.phone}`}>
                          <i className="fa fa-phone"></i>
                        </a>{" "}
                        |{" "}
                        <a href={`https://wa.me/${e?.countryCode}${e.phone}`}>
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </td>
                      <td>{e.message}</td>
                      <td>{e.hearaboutus}</td>
                      <td>{e.studyDest}</td>
                      <td>
                        {e?.location?.country_name}, {e?.location?.region},{" "}
                        {e?.location?.city}{" "}
                      </td>
                      <td>
                        {moment(e.createdAt).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td style={{width: '20%'}}>
                        {" "}
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Edit</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              navigate(
                                `/createlead?id=${e._id}`
                              );
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdEdit className="" style={{ color: "#DC7215" }} />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Email</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              navigate(
                                "/dashboard-mass-email?email=" + e?.email
                              );
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdMail className="" style={{ color: "#DC7215" }} />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Add / View Notes</Tooltip>
                          }
                        >
                          <button
                            onClick={() => {
                              setActionModal({
                                mail: false,
                                notes: true,
                                user: e?._id,
                              });
                              getAllNotesForAUser(e?._id, e?.note);
                              // setAllNotes({ title: }, ...notes);
                            }}
                            className="btn useDeleteBtn"
                          >
                            <MdBook style={{ color: "#DC7215" }} />{" "}
                            {/* Add / View Notes */}
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover the lead data!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((res) => {
                                if (res) {
                                  // console.log(res)
                                  handleDelete(e?._id);
                                }
                              });
                              // handleDelete(e?._id)
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdDelete className="MdDelete" />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Add as User</Tooltip>
                          }
                        >
                          <button
                            onClick={()=> handleSubmit(e)}
                            className="btn useDeleteBtn"
                          >
                            <MdAdd style={{ color: "#DC7215" }} />{" "}
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="required p-2">No Data Found</p>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Modal
          show={actionModal?.notes}
          onHide={() => setActionModal({ mail: false, notes: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add/View Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!allNotes?.loading ? (
              allNotes?.data?.map((itm, i) => (
                <div key={i}>
                  <h2 style={{ fontWeight: "600" }}>{itm?.note}</h2>
                  {itm?.createdAt && (
                    <p>
                      Created at:{" "}
                      {moment(itm?.createdAt).format("DD-MMM-YYYY HH:mm A")}
                    </p>
                  )}
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
            <div className="form-floating">
              <input
                className="form-control"
                placeholder=""
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />{" "}
              <label for="floatingTextarea">Notes</label>
            </div>
            <Button
              variant="primary-regular mt-3"
              disabled={!notes}
              onClick={() => {
                handleAddNotes();
              }}
            >
              Send
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setActionModal({ mail: false, notes: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Pagination className="d-flex justify-content-end mb-5">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default LeadFormDashboard;
