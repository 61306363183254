import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import { getNewToken } from "../utils/CalendlyTokens";
import { OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

const AllBookingAdmin = ({}) => {
  const [bookings, setBookings] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getBookings = async () => {
    try {
      const data = await API(
        "get",
        `/mentor/all/bookings/admin?page=${currentPage}`
      );
      setBookings(data?.data?.data);
      setTotalPages(data?.data?.total);
    } catch (error) {
    } finally {
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getBookings();
  }, [currentPage]);

  const deleteBooking = async (id) => {
    try {
      await API("post", `/mentor/delete-booking`, { id });
      swal({
        title: "Deleted!",
        text: "Booking Deleted successfully!",
        icon: "success",
      });
      getBookings()
    } catch (error) {}
  };

  return (
    <>
      <div className="table-responsive grid-frame">
        <table className="table admin-table">
          <thead>
            <tr className="table-dark">
              {" "}
              <th scope="col">Sr</th>
              <th scope="col">Student Name </th>
              <th scope="col">Mentor Name </th>
              <th scope="col">Meeting Time </th>
              <th scope="col">Mentor Status</th>
              <th scope="col">Student Status</th>
              <th scope="col">Action</th>
              {/* <th>Update Status</th> */}
            </tr>
          </thead>
          <tbody>
            {bookings ? (
              bookings?.map((itm, i) => (
                <tr>
                  <td>
                    <p>{i + 1}</p>
                  </td>
                  <td>
                    <p>
                      {itm?.user?.firstName} {itm?.user?.lastName}
                    </p>
                  </td>
                  <td>
                    <p>{itm?.mentor?.name}</p>
                  </td>
                  <td>
                    <p>
                      {moment(itm?.meetingTime).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </p>
                  </td>
                  {/* <td>
                  <p>{itm?.meetinglink}</p>
                </td> */}
                  <td>
                    <p>{itm?.status}</p>
                  </td>
                  <td>
                    <p>{itm?.studentStatus}</p>
                  </td>
                  <td>
                    <MdDelete
                      className="MdDelete"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          text: "Are you sure, you want to delete this booking?",
                          icon: "info",
                          buttons: true,
                          dangerMode: true,
                        }).then((res) => {
                          if (res) {
                            deleteBooking(itm?._id);
                          }
                        });
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </tbody>
        </table>
      </div>
      <Pagination className="d-flex justify-content-end mb-5">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {[...Array(totalPages).keys()].map((page) => (
          <Pagination.Item
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </Pagination>
    </>
  );
};

export default AllBookingAdmin;
