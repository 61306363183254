import React, { useEffect } from 'react';

const CurrencyWidget = () => {
  useEffect(() => {
    // Change the variables below
    var sourceCurrencyCode = "INR";
    var targetCurrencyCode = "USD";
    var sourceAmount = 5000;
    var callToActionLabel = "Get started";
    var hideCta = false;
    var hideDetails = false;
    var affiliateLink = "https://wise.prf.hn/click/camref:1101lHjNI";
    var lang = "en";
    var width = "100%";
    var height = "480px";
    
    // Do not modify the code below
    var redirectUrlParams = encodeURIComponent("https://wise.com?sourceCurrency=" + sourceCurrencyCode + "&targetCurrency=" + targetCurrencyCode + "&fromCalcWidget=true&sourceAmount=" + sourceAmount);
    var redirectUrl = encodeURIComponent(affiliateLink + "?u=" + redirectUrlParams);
    var wiseIframeUrl =
      "https://wise.com/widget/calculator?sourceCurrencyCode=" + sourceCurrencyCode + "&targetCurrencyCode=" + targetCurrencyCode + "&amount=" + sourceAmount + "&cta=" + callToActionLabel + "&hideCta=" + hideCta + "&hideDetails=" + hideDetails + "&redirectUrl=" + redirectUrl + "&lang=" + lang + "&theme=light";
    
    var wiseIframe = document.createElement('iframe');
    wiseIframe.frameBorder = 0;
    wiseIframe.width = width;
    wiseIframe.height = height;
    wiseIframe.id = "wise-calc";
    wiseIframe.scrolling = "no";
    wiseIframe.setAttribute("allowtransparency", "true");
    wiseIframe.setAttribute("src", wiseIframeUrl);
    
    document.getElementById("wise-calc-widget").appendChild(wiseIframe);
  }, []);

  return (
    <div id="wise-calc-widget"></div>
  );
}

export default CurrencyWidget;
