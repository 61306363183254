import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import { getNewToken } from "../utils/CalendlyTokens";

const AllBookingsStudent = ({ userid, mentorid }) => {
  const params = { id: mentorid };
  // const [status, setStatus] = useState("");
  const [bookings, setBookings] = useState(null);
  const navigate = useNavigate();

  const getBookings = async () => {
    try {
      const data = await API(
        "get",
        `/mentor/all-bookings/${params.id}?userid=${userid}`
      );
      setBookings(data?.data?.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (userid) {
      getBookings();
    }
  }, [userid]);

  return (
    <div>
      <table className="table table-responsive">
        <thead>
          <tr className="table-dark">
            {" "}
            <th>Sr</th>
            <th>Meeting Time </th>
            <th>Mentor Status</th>
            <th>Student Status</th>
            <th>Update Status</th>
          </tr>
        </thead>
        <tbody>
          {bookings ? (
            bookings?.map((itm, i) => (
              <tr>
                <td>
                  <p>{i + 1}</p>
                </td>
                {/* <td>
                <p>
                  {itm?.user?.firstName} {itm?.user?.lastName}
                </p>
              </td>
              <td>
                <p>{itm?.user?.email}</p>
              </td> */}
                <td>
                  <p>
                    {moment(itm?.meetingTime).format("MMMM Do YYYY, h:mm:ss a")}
                  </p>
                </td>
                {/* <td>
                <p>{itm?.meetinglink}</p>
              </td> */}
                <td>
                  <p>{itm?.status}</p>
                </td>
                <td>
                  <p>{itm?.studentStatus}</p>
                </td>

                <td>
                  <div>
                    <select
                      className="form-select"
                      value={itm?.studentStatus}
                      onChange={async (e) => {
                        let url = itm?.meetinglink + "/cancellation";
                        try {
                          if (e.target.value == "Cancelled") {
                            const token = await getNewToken(params.id);
                            await (
                              await fetch(url, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                  reason: "",
                                }),
                              })
                            ).json();
                          }
                          await API("put", `mentor/booking/${itm?._id}`, {
                            studentStatus: e.target.value,
                          });
                          swal({
                            title: "Success",
                            text: "Status Updated Successfully!",
                            icon: "success",
                            button: "Ok",
                          });
                          getBookings();
                        } catch (error) {}
                      }}
                    >
                      <option value="Created">Created</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="No-Show">No-Show</option>
                    </select>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllBookingsStudent;
