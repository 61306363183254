import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import "../Assets/Styles/test.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ReactPlayer from "react-player";
import { ReactMic } from "react-mic";
import VoiceTranscript from "./Componets/VoiceTranscript";

export const ConductTest = () => {
  const [testData, setTestData] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseArr, setResponseArr] = useState([]);
  const params = useParams();
  const [timer, setTimer] = useState(3600);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const navigate = useNavigate();
  const [totaTimeSeconds, setTotalTimeSeconds] = useState(0);
  const getTestData = async () => {
    setLoading(true);
    try {
      const response = await API("get", `/test/single/${params.id}`);
      setTestData(response?.data?.data);
      setTimer(timeToSeconds(response?.data?.data?.total_time));
      setTotalTimeSeconds(timeToSeconds(response?.data?.data?.total_time));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTestData();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      handleSubmit();
    }
    const intervalId = setInterval(() => {
      setTimer((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the interval when the component unmounts or when the dependency array changes
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function timeToSeconds(time) {
    const [hours, minutes] = time.split(":").map(Number);
    //console.log(hours.minutes, time);
    return hours * 3600 + minutes * 60;
  }

  function convertSecondsToHrMinSec(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const handleSubmit = async () => {
    let finalResponse = [];
    responseArr.map((itm) => {
      finalResponse.push({ id: itm.id, answer: itm.answer });
    });
    testData?.questions.map((itm) => {
      if (!responseArr.find((item) => item?.id == itm?._id)) {
        finalResponse.push({ id: itm._id, answer: itm?.answer ?? "" });
      }
    });
    try {
      const response = await API("post", `/test/student/submit-response`, {
        studentid: localStorage.getItem("_id"),
        testid: params.id,
        timetaken: convertSecondsToHrMinSec(totaTimeSeconds - timer),
        response: finalResponse,
      });
      if (response?.data?.success) {
        navigate("/assessment");
        swal({
          title: "Success!",
          text: response?.data?.message,
          icon: "success",
          button: "Ok!",
        });
      } else {
        swal({
          title: "Error!",
          text: response?.data?.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (error) {}
  };

  const hanldeChange = (type, data, checked) => {
    if (type == "mcq") {
      if (responseArr?.find((itm) => itm.id == data.id)) {
        const newData = responseArr?.filter((itm) => itm.id != data.id);
        setResponseArr([...newData, data]);
        return;
      }
      setResponseArr([...responseArr, data]);
    }
    if (type == "text") {
      if (responseArr?.find((itm) => itm.id == data.id)) {
        const newData = responseArr?.filter((itm) => itm.id != data.id);
        setResponseArr([...newData, data]);
        return;
      }
      setResponseArr([...responseArr, data]);
    }
    if (type == "mmcq") {
      let previosMMCQ = responseArr?.find((itm) => itm.id == data.id);
      if (previosMMCQ) {
        if (checked) {
          const newData = responseArr?.filter((itm) => itm.id != data.id);
          setResponseArr([
            ...newData,
            { ...data, answer: previosMMCQ.answer + "," + data?.answer },
          ]);
          return;
        } else {
          const newData = responseArr?.filter((itm) => itm.id != data.id);
          setResponseArr([
            ...newData,
            {
              ...data,
              answer: previosMMCQ.answer
                .split(",")
                ?.filter((itm) => itm != data?.answer)
                .join(","),
            },
          ]);
          return;
        }
      }
      setResponseArr([...responseArr, data]);
    }
    if (type == "subquestions") {
      let index = checked;
      if (responseArr?.find((itm) => itm.id == data.id)) {
        let str_answer = data?.answer;
        let element = responseArr?.filter((itm) => {
          if (itm.id == data.id) {
            return itm;
          }
        });
        let answer_arr = element[0]?.answer.split(",");
        answer_arr[index] = str_answer;
        // const newData = responseArr?.filter((itm) => itm.id != data.id);
        const newData = responseArr?.filter((itm) => itm.id != data.id);
        setResponseArr([
          ...newData,
          { id: data?.id, answer: answer_arr.join(",") },
        ]);
        return;
      }
      let answerArr = Array(
        testData?.questions[currentQuestion]?.options?.length
      ).fill();
      answerArr[index] = data?.answer;
      setResponseArr([
        ...responseArr,
        { id: data?.id, answer: answerArr.join(",") },
      ]);
    }
  };

  // useEffect(() => {
  //   console.log(responseArr);
  // }, [responseArr]);

  if (testData?.questions?.length > 0)
    return (
      <div className="main-container">
        <div className="container">
          <h1 className="text-center">{testData?.name}</h1>
          {/* <div
            className="test-description"
            dangerouslySetInnerHTML={{ __html: testData?.description }}
          ></div> */}
        </div>
        <br />
        {/* <div>
          <h2>{testData?.questions[currentQuestion]?.type}</h2>
        </div> */}
        <div>
          <h2>{testData?.questions[currentQuestion]?.category}</h2>
        </div>

        <div className="container conduct-test-container">
          <div className="d-flex justify-content-between">
            <div
              className="d-flex"
              dangerouslySetInnerHTML={{
                __html:
                  `Q${currentQuestion + 1}. ` +
                  testData?.questions[currentQuestion]?.title,
              }}
            ></div>
            <p className="test-conduct-timer">
              {convertSecondsToHrMinSec(timer)}
            </p>
          </div>
          <br />
          <div className="d-flex justify-content-between">
            <div className="q-options">
              {testData?.questions[currentQuestion]?.type == "mcq" && (
                <div className="form-check d-flex flex-column">
                  {testData?.questions[currentQuestion]?.options?.map(
                    (option, index) => {
                      return (
                        <div className="d-flex align-items-center" key={index}>
                          <input
                            className="form-check-input"
                            name={testData?.questions[currentQuestion].title}
                            type="radio"
                            checked={responseArr?.find(
                              (itm) =>
                                itm?.answer == option &&
                                itm?.id ==
                                  testData?.questions[currentQuestion]?._id
                            )}
                            onClick={(e) => {
                              hanldeChange("mcq", {
                                id: testData?.questions[currentQuestion]?._id,
                                answer: option,
                              });
                            }}
                          />{" "}
                          &nbsp;
                          <p style={{ margin: 0 }}>{option}</p>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {testData?.questions[currentQuestion]?.type == "mmcq" && (
                <div className="d-flex flex-column">
                  {testData?.questions[currentQuestion]?.options?.map(
                    (option, index) => {
                      return (
                        <div className="d-flex align-items-center" key={index}>
                          <input
                            type="checkbox"
                            checked={responseArr
                              ?.filter(
                                (itm) =>
                                  itm?.id ==
                                  testData?.questions[currentQuestion]?._id
                              )[0]
                              ?.answer?.split(",")
                              .find((itm) => itm == option)}
                            onChange={(e) => {
                              hanldeChange(
                                "mmcq",
                                {
                                  id: testData?.questions[currentQuestion]?._id,
                                  answer: option,
                                },
                                e.target.checked
                              );
                              // console.log(e.target.checked, option);
                            }}
                          />{" "}
                          &nbsp;
                          <p style={{ margin: 0 }}>{option}</p>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {testData?.questions[currentQuestion]?.type == "subquestions" && (
                <div className="d-flex flex-column">
                  {testData?.questions[currentQuestion]?.options?.map(
                    (option, index) => {
                      return (
                        <div className="d-flex align-items-center" key={index}>
                          {/* <input
                            type="checkbox"
                            checked={responseArr
                              ?.filter(
                                (itm) =>
                                  itm?.id ==
                                  testData?.questions[currentQuestion]?._id
                              )[0]
                              ?.answer?.split(",")
                              .find((itm) => itm == option)}
                            onChange={(e) => {
                              hanldeChange(
                                "mmcq",
                                {
                                  id: testData?.questions[currentQuestion]?._id,
                                  answer: option,
                                },
                                e.target.checked
                              );
                              // console.log(e.target.checked, option);
                            }}
                          />{" "} */}
                          &nbsp;
                          <div>
                            <p
                              style={{ margin: 0 }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  `Q${currentQuestion + 1}.${index + 1}) ` +
                                  option,
                              }}
                            ></p>
                            {testData?.questions[
                              currentQuestion
                            ]?.subquestionsoptions[index]
                              ?.split(",")
                              .map((itm) => {
                                return (
                                  <div className="d-flex">
                                    {" "}
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      checked={
                                        responseArr
                                          ?.find(
                                            (itm) =>
                                              itm.id ==
                                              testData?.questions[
                                                currentQuestion
                                              ]?._id
                                          )
                                          ?.answer?.includes(itm)
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        hanldeChange(
                                          "subquestions",
                                          {
                                            id: testData?.questions[
                                              currentQuestion
                                            ]?._id,
                                            answer: itm,
                                          },
                                          index
                                        );
                                      }}
                                    />
                                    &nbsp; <p style={{ margin: 0 }}>{itm}</p>
                                  </div>
                                );
                              })}
                            <br />
                            <input
                              className="form-control"
                              placeholder="Answer"
                              value={
                                responseArr
                                  ?.filter(
                                    (itm) =>
                                      itm?.id ==
                                      testData?.questions[currentQuestion]?._id
                                  )[0]
                                  ?.answer?.split(",")[index]
                              }
                              onChange={(e) =>
                                hanldeChange(
                                  "subquestions",
                                  {
                                    id: testData?.questions[currentQuestion]
                                      ?._id,
                                    answer: e.target.value,
                                  },
                                  index
                                )
                              }
                            />
                            <br />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {testData?.questions[currentQuestion]?.type == "text" && (
                <div>
                  <textarea
                    value={
                      responseArr?.filter(
                        (itm) =>
                          itm?.id == testData?.questions[currentQuestion]?._id
                      )[0]?.answer
                    }
                    className="ans-box"
                    placeholder="answer..."
                    onChange={(e) => {
                      hanldeChange("text", {
                        id: testData?.questions[currentQuestion]?._id,
                        answer: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
              {testData?.questions[currentQuestion]?.type == "audio" && (
                <div>
                  <audio controls>
                    <source
                      src={testData?.questions[currentQuestion]?.url}
                      type="audio/ogg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  <br />
                  <br />
                  {/* <ReactMic
                    record={isRecording}
                    className="sound-wave"
                    onStop={onStop}
                    onData={console.log}
                    strokeColor="#000000"
                    backgroundColor="#DC7215"
                  />
                  {!isRecording ? (
                    <button
                      onClick={onStart}
                      className="btn btn-primary"
                      type="button"
                    >
                      Start Recording
                    </button>
                  ) : (
                    <button
                      onClick={() => setIsRecording(false)}
                      className="btn btn-primary"
                      type="button"
                    >
                      Stop Recording
                    </button>
                  )} */}
                  <VoiceTranscript
                    type={"text"}
                    id={testData?.questions[currentQuestion]?._id}
                    hanldeChange={hanldeChange}
                  />
                  <br />
                  <br />
                  <textarea
                    value={
                      responseArr?.filter(
                        (itm) =>
                          itm?.id == testData?.questions[currentQuestion]?._id
                      )[0]?.answer
                    }
                    className="ans-box"
                    placeholder="answer..."
                    onChange={(e) => {
                      hanldeChange("text", {
                        id: testData?.questions[currentQuestion]?._id,
                        answer: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
              {testData?.questions[currentQuestion]?.type == "video" && (
                <div>
                  <ReactPlayer
                    url={testData?.questions[currentQuestion]?.url}
                  />
                  <br />
                  <br />
                  <textarea
                    value={
                      responseArr?.filter(
                        (itm) =>
                          itm?.id == testData?.questions[currentQuestion]?._id
                      )[0]?.answer
                    }
                    className="ans-box"
                    placeholder="answer..."
                    onChange={(e) => {
                      hanldeChange("text", {
                        id: testData?.questions[currentQuestion]?._id,
                        answer: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            &nbsp;&nbsp;
            <div
              className="q-desc"
              dangerouslySetInnerHTML={{
                __html: testData?.questions[currentQuestion]?.description,
              }}
            ></div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-between">
              <button
                disabled={currentQuestion === 0}
                onClick={() => setCurrentQuestion(currentQuestion - 1)}
                className="btn btn-primary"
              >
                Previous
              </button>{" "}
              &nbsp;
              <button
                disabled={testData?.questions.length - 1 === currentQuestion}
                onClick={() => setCurrentQuestion(currentQuestion + 1)}
                className="btn btn-primary"
              >
                Next
              </button>
            </div>
            <div>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return <h1>Please wait...</h1>;
};
