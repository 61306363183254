import React, { useEffect, useState } from 'react';

const EmergencyContact = (props) => {
    const [originalData, setOriginalData] = useState({
        name: "",
        phone: "",
        email: "",
        address: "",
        relationship: "",
        gName: "",
        gPhone: "",
        gEmail: "",
        gAddress: "",
        gRelationship: "",
    });

    const [data, setData] = useState(originalData);

    // Effect to set both originalData and data when props.data changes
    useEffect(() => {
        if (props.data) {
            setData(props.data);
            setOriginalData(props.data); // Store the original data
        }
    }, [props.data]);

    // Reset data to originalData on Cancel button click
    const handleCancel = () => {
        setData(originalData); // Revert to original data
    };

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    return (
        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel"
            aria-labelledby="v-pills-settings-tab">
            <div className="row profile-section">
                <div className="col">
                    <div className="section-head">Emergency Contact Information</div>
                    <div className="form-group section-content">
                        {/* Form Fields */}
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        value={data.name}
                                        onChange={onChange}
                                        className="form-control"
                                        placeholder="Name"
                                    />
                                    <label htmlFor="">Name</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="phone"
                                        value={data.phone}
                                        onChange={onChange}
                                        className="form-control"
                                        placeholder="Phone"
                                    />
                                    <label htmlFor="">Phone</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="email"
                                        value={data.email}
                                        onChange={onChange}
                                        className="form-control"
                                        placeholder="Email"
                                    />
                                    <label htmlFor="">Email</label>
                                </div>
                            </div>
                        </div>
                        {/* Similar blocks for other fields */}
                        <div className="row responsive-col">
                            <div className="col text-end action-btn-group">
                                <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary-regular" onClick={() => { props?.updateData("emergencyContact", data) }}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmergencyContact;