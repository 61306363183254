import React, { useState } from "react";
import credits from "../Assets/Data/CreditPlans.json";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./utils/Checkout";
import API from "../../Api/Api";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PurchaseCredits = () => {
  const [orderData, setOrderData] = useState("");
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState({});
  const [paymentScreen, setPaymentScreen] = useState(false);

  const purchasePlan = async (id) => {
    try {
      const data = await API("post", "/mentor/user/refill-credits", {
        id: id,
      });
      setOrderData(data?.data?.clientSecret);
      setPaymentScreen(true);
    } catch (error) {}
  };

  if (paymentScreen) {
    return (
      <div>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: orderData,
          }}
        >
          {" "}
          <CheckoutForm itm={currentPlan} />
        </Elements>
      </div>
    );
  }

  return (
    <>
      <main id="content" className="mentor-insidepage">
        <section
          id="pricing"
          className="secondary-color text-center scrollto clearfix"
        >
          <div className="row clearfix">
            <div className="mentor-section-heading">
              <h3>YOUR CHOICE</h3>
              <h2 className="mentor-section-title">
                We have the right package for you
              </h2>
            </div>
            {credits?.map((itm) => (
              <div
                className="pricing-block mentor-col-2 wow fadeInUp"
                data-wow-delay="0.4s"
                key={itm.id}
              >
                <div className="pricing-block-content">
                  <h3>{itm.name}</h3>
                  <p className="pricing-sub">{itm?.tag}</p>
                  <div className="pricing">
                    <div className="price">
                      <span>$</span>
                      {itm?.price}
                    </div>
                    <p>{itm?.description}</p>
                  </div>
                  <ul>
                    {itm?.features.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>
                  <button
                    className="mentor-button-sm"
                    onClick={() => {
                      purchasePlan(itm?.id);
                      setCurrentPlan(itm);
                    }}
                  >
                    BUY TODAY
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default PurchaseCredits;
