import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import swal from "sweetalert";

export const CheckoutForm = ({ itm }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const purchaseCourse = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try {
      const paymentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url:
            process.env.REACT_APP_URL + `/payment-check?courseid=${itm?._id}`,
        },
      });

      if (paymentResult.error) {
        swal({
          title: "Error!",
          text: paymentResult.error.message,
          icon: "error",
          button: "Ok!",
        });
      } else {
       //redirecting..
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100vw" }}
    >
      <form onSubmit={purchaseCourse}>
        <br />
        <h1>Purchase course {itm?.name}</h1>
        <br />
        <PaymentElement />
        {/* <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              lineHeight: "40px",
              //   backgroundColor: "#fff",
              fontSize: "18px",
            },
          },
        }}
      /> */}
        <br />
        {!loading ? (
          <button className="btn btn-primary"> Pay ${itm.amount}</button>
        ) : (
          <button className="btn btn-primary" disabled>
            Please Wait
          </button>
        )}
      </form>
    </div>
  );
};

export default CheckoutForm;
