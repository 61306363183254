import React, { useEffect, useState } from "react";
import NavBarAdmin from "./Nav/Nav";
import API from "../../Api/Api";
import swal from "sweetalert";
import { MdDelete } from "react-icons/md";
import Editor from "../LmsAdminDashboard/Editor/Editor";

const DashboardFromEmail = () => {
  const [user, setUsers] = useState([]);
  const [data, setData] = useState({
    email: "",
    signature: "",
    password: "",
    edit: false,
    id: "",
  });

  const getUsers = async () => {
    try {
      const response = await API("get", "/mass-mail/from-emails");
      setUsers(response.data.data);
      setData({
        email: "",
        signature: "",
        password: "",
        edit: false,
        id: "",
      });
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSave = async () => {
    try {
      if (data.edit) {
        await API("put", `/mass-mail/update-from-email/${data.id}`, data);
      } else {
        await API("post", "/mass-mail/create-from-email", data);
      }
      swal({
        title: "Success!",
      });
      getUsers();
    } catch {}
  };

  const handleDelete = async (id) => {
    try {
      await API("delete", `/mass-mail/from-email/${id}`);
      swal({
        title: "Success!",
        icon: "success",
        button: "Ok!",
        text: "Deleted Successfully!",
      });
      getUsers();
    } catch {}
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <h1 className="page-title">From Email</h1>
        <div className="d-flex marketing-email">
          <div>
          <div className="template-list">
            {user?.map((itm, id) => {
              return (
                <div
                  key={id}
                >
                  <p>
                    <b>Email:</b> {itm?.email}
                  </p>
                  {/* <p>
                    <b>pasword:</b> {itm?.password}
                  </p> */}
                  <div dangerouslySetInnerHTML={{ __html: itm?.signature }}></div>
                  <div className="d-flex">
                    <button
                      className="btn btn-secondary me-2"
                      onClick={() => {
                        setData({
                          email: itm?.email,
                          signature: itm?.signature ?? "",
                          edit: true,
                          id: itm?._id,
                        });
                      }}
                    >
                      Edit
                    </button>{" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        swal({
                          title: "Are you sure?",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            handleDelete(itm?._id);
                          }
                        });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
          </div>
          <div className="marketing-email-content">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={data.email}
              onChange={(e) => {
                setData({
                  ...data,
                  email: e.target.value,
                });
              }}
            />
            <br />
            <label>Password</label>
            <input
              type="text"
              className="form-control"
              placeholder="Password"
              value={data.password}
              onChange={(e) => {
                setData({
                  ...data,
                  password: e.target.value,
                });
              }}
            />
            <br />
            <label>Signature</label>
            <div style={{width: "100%"}}>
            <Editor data={data} setData={setData} name="signature" height={250} />
            </div>
            <br />
            <button className="btn btn-primary-regular" onClick={handleSave}>
              {" "}
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFromEmail;
