import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import moment from "moment";
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";

const ShortListingUser = () => {
  const [filesAll, setFilesAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("");
  const [renderData, setRenderData] = useState({
    headers: [],
    data: [],
  });
  const params = useParams();
  const navigate = useNavigate();

  const getAllFiles = async () => {
    setLoading(true);
    try {
      const response = await API("get", `/shortlisting/${params.id}`);
      setFilesAll(response?.data?.data);
      setActive(response?.data?.data[0]?._id);
      let obj = response?.data?.data.find(
        (itm) => itm._id == response?.data?.data[0]?._id
      );
      setRenderData({
        headers: obj?.data[0],
        data: obj?.data.slice(1),
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllFiles();
  }, []);

  useEffect(() => {
    //console.log(renderData);
  }, [render]);

  const getBg = (data) => {
    if (data.includes("High Ambitious")) {
      return "#F1B9B9";
    }
    if (data.includes("High Ambtious")) {
      return "#EC7063";
    }
    if (data.includes("Ambitious") || data.includes("ambitious")) {
      return "#F1C7B9";
    }
    if (data.includes("Moderate") || data.includes("moderate")) {
      return "#DCE2C8";
    }
    if (data.includes("Safe") || data.includes("safe")) {
      return "#F1F0B9";
    }
    if (data.includes("Reach/Safe") || data.includes("Reach")) {
      return "#F1F0B9";
    }
  };

  if (loading) {
    return (
      <div className="body-content no-filterpanel">
        <div className="body-panel d-flex justify-content-between">
          &nbsp; &nbsp; &nbsp;
          <div className="body-container width-100">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  if (filesAll.length < 1) {
    return (
      <div className="body-content no-filterpanel">
        <div className="body-panel d-flex justify-content-between">
          &nbsp; &nbsp; &nbsp;
          <div className="body-container width-100">
            <p className="alert alert-warning">No Shortlistings Found</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div className="body-container">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-between mt-3">
                <div className="page-content">
                  <h1 className="mb-3">View Shortlisting</h1>
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigate("/userdashboard");
                    }}
                    className="widget-cta-page-nopos float-end mb-3"
                  >
                    <i className="fa fa-home"></i> Back to Dashboard
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex tab">
              {filesAll.map((item) => {
                return (
                  <h2
                    key={item?._id}
                    onClick={() => {
                      setActive(item?._id);
                      setRenderData({
                        headers: item?.data[0],
                        data: item?.data.slice(1),
                      });
                    }}
                    className="tablinks"
                  >
                    {item?.name}
                  </h2>
                );
              })}
            </div>
            <div className="table-responsive shortlisting-data-table-frame grid-frame">
              <table className="table shortlisting-data-table">
                <thead>
                  <tr className="table-dark">
                    {renderData?.headers?.map((item, i) => {
                      return (
                        <th scope="col" key={i}>
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {renderData.data.map((item, i) => {
                    return (
                      <tr key={i} style={{ background: getBg(item) }}>
                        {item?.map((data, i) => {
                          // console.log(data)
                          return (
                            <td data={i}>
                              {" "}
                              {data ? (
                                String(data)?.startsWith("http") ? (
                                  <a target="_blank" href={data}>
                                    <i className="fa fa-globe"></i>
                                  </a>
                                ) : (
                                  data
                                )
                              ) : (
                                ""
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortListingUser;
