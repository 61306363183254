import React, {useState} from "react";
import "../Assets/Styles/affiliate.css";
import AffiliateHome from "./AffiliateHome";
export const AffiliateProgram = () => {
  const [showDiv, setShowDiv] = useState(false);
  const handleButtonClick = () => {
    if (!showDiv) {
      setShowDiv(true);
    }
    window.scrollTo({
      top: 500,
      behavior: 'smooth',
    });
  };
  return (
    <div className="container-fluid p-0">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="header pt-3 pb-3 d-flex justify-content-between">
              <div className="logo">
              <a href="/">
                <img
                  src="logo.png"
                  className="img-fluid logo-sm"
                  alt="Rise Higher Education"
                  title="Rise Higher Education"
                />
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col">
              <section className="section main-affiliate">
                <div className="d-flex justify-content-between">
                  <div>
                    <h1>Join Rise Higher Education's Affiliate Program</h1>
                    <p className="my-5">
                      Refer your friends, and earn commission on all registered
                      students. There is no limit to how much you can earn.
                    </p>
                    <button
                      type="submit"
                      className="btn btn-primary-regular"
                      onClick={handleButtonClick}
                    >
                      Start Now
                    </button>
                  </div>
                  <div className="text-right hero-section-container">
                    <img
                      src="affiliate.svg"
                      alt="Rise Higher Education Affiliate Program"
                      width="750"
                    />
                  </div>
                </div>
                <div className="mb-4 affiliate-form">
                {showDiv && <AffiliateHome></AffiliateHome>}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="section how-it-works my-5">
          <div>
            <h1>How it works</h1>
            <ul>
              <li>
                <p className="section-title-regular">Join the program</p>
                <p>
                  Fill a simple form to join the program and generate your
                  unique link
                </p>
              </li>
              <li>
                <p className="section-title-regular">
                  Promote to your audience
                </p>
                <p>
                  Share link with your family, friends, colleagues, follow
                  students, higher education aspriants.
                </p>
              </li>
              <li>
                <p className="section-title-regular">Earn Rewards</p>
                <p>
                  Get commission on every successful registration. It's a
                  win-win situation for everyone.
                </p>
              </li>
            </ul>
          </div>
        </section>
        <div className="clearfix"></div>
        <hr />
        <section className="section affiliate-faq faqs">
          <div>
            <h3 className="text-left">Frequently Asked Questions</h3>
          </div>
          <div className="accordion mt-4" id="">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                How quickly will my referral be approved?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Instantly! Our automated system allows you to join the program immediately upon clicking the generate link.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Is there a limit to the number of referrals I can make?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Nope! There's no cap on the number of referrals you can make, unlocking unlimited earning potential. Referrals through your unique link qualify for the program.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                How can I track my referrals?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Upon joining, you'll receive an email containing your referral link for sharing and a dashboard link for real-time tracking of your referral status.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                When will I receive payment?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Once your referral registers for a higher education program, our system updates the status to approved. You'll receive an email notification prompting you to provide your account details, and your referral earnings will be directly deposited.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                What should I do if my referral doesn't appear?
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Contact us at <a href="mailto:contact@risehighereducation.com">contact@risehighereducation.com</a> for any technical issues. Our dedicated team will assist in resolving the problem to ensure your referral is accounted for.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                What’s my earning Potential?
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>Unlimited !</p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Registration Type</th>
                        <th>1-5 Referrals</th>
                        <th>6-10 Referrals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>Premium</strong></td>
                        <td>₹1000/$15 per registration</td>
                        <td>₹2000/ $30 per registration</td>
                      </tr>
                      <tr>
                        <td><strong>Elite</strong></td>
                        <td>₹2000/ $30 per registration</td>
                        <td>₹3000/ $45 per registration</td>
                      </tr>
                      <tr>
                        <td><strong>Platinum</strong></td>
                        <td>₹3000/ $30 per registration</td>
                        <td>₹5000/ $45 per registration</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                Is there a time limit for referrals to be eligible?
                </button>
              </h2>
              <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                No, there's no time limit. Referrals qualify as long as they register within the program's duration.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                Can I refer someone outside my country?
                </button>
              </h2>
              <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Absolutely! Our referral program is open to international participants.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                Is there a minimum payout threshold?
                </button>
              </h2>
              <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                No minimum threshold. Once your referral is approved, you receive your earnings, regardless of the amount.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                Can I track my referral's progress in real-time?
                </button>
              </h2>
              <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Yes, your dashboard provides a live update on your referral's journey from registration to approval.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                Are there promotional materials available to help with referrals?
                </button>
              </h2>
              <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Yes, promotional materials such as banners and flyers can be provided upon request to boost your referral success.
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix"></div>
        <section className="section text-center ready-affiliate">
          <h2>Ready to become an Affiliate?</h2>
          <button
                      type="submit"
                      className="btn btn-primary-regular"
                      onClick={handleButtonClick}
                    >
                      Start Now
                    </button>
        </section>
      </div>
    </div>
  );
};

