export const steps = [
  // {
  //   target: ".counseling-tile",
  //   content: " Counseling Start from here",
  //   // disableBeacon: true,
  // },
  // {
  //   target: ".btn-counseling",
  //   content: "Click to start your consultation",
  //   // disableBeacon: true,
  // },
];
