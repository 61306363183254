import React, { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { url } from "../Constants";
import swal from "sweetalert";
import CircularProgress from "@mui/material/CircularProgress";

const Details = () => {
  const { id } = useParams("");
  const [indata, setIndata] = useState("");
  const [cat, setCat] = useState("");
  const authToken = localStorage.getItem("token");
  const [params] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const isValidImageUrl = (url) => {
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|bmp)$/i;
    return imageUrlRegex.test(url);
  };

  const onChange = (e) => {
    setCat(e.target.value);
    let temp = "";
    if (e.target.value === "SavedForLater") temp = "Saved For Later";
    else if (e.target.value === "PendingDecision") temp = "Pending Decision";
    else temp = e.target.value;

    swal({
      title: "Success!",
      text: `Scholarship successfully added to ${temp}`,
      icon: "success",
      button: "Done!",
    });
    // history("/signup");
  };

  const getData = async (id) => {
    const res = await fetch(`${url}/scholarship/id/${id}`, {
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    const json = await res.json();

    if (res.status === 200) {
      setIndata(json.data);
      setCat(json.category);
    } else {
      alert("no data available");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  const categorize = async (cat) => {
    const checkResponse = await fetch(`${url}/scholarship/update`, {
      method: "POST",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        id: indata._id,
        category: cat,
      }),
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await checkResponse.json();
  };

  useEffect(() => {
    categorize(cat);
  }, [cat]);

  return (
    <div className="body-content scholarship-details">
      <div className="scholarship-details-banner">
        <img
          src={require("../Assets/Media/Images/scholarship-detail-banner.jpg")}
          alt="img"
        />
      </div>
      <div className="scholarship-details-container d-flex justify-content-center">
        {isLoading ? (
          <div
            className="circle"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : indata.length === 0 ? (
          <p>No data available.</p>
        ) : (
          <div className="scholarship-details-content">
            <div className="d-flex">
              <div className="scholarship-logo me-2">
                <img
                  src={
                    indata.Image_Address !== undefined &&
                    isValidImageUrl(indata.Image_Address)
                      ? indata.Image_Address
                      : require("../Assets/Media/Images/uni-detail-logo.png")
                  }
                  alt="img"
                />
              </div>
              <div className="scholarship-name">{indata.Scholarship_Name}</div>
            </div>
            <div className="scholarship-details-list">
              <ul className="d-flex justify-content-between">
                {indata.Awarding_Body && (
                  <li>
                    <span className="scholarship-details-list-heading">
                      Organization
                    </span>
                    <br />
                    <span>{indata.Awarding_Body}</span>
                  </li>
                )}
                {indata.Destination_Country && (
                  <li>
                    <span className="scholarship-details-list-heading">
                      Destination Country
                    </span>
                    <br />
                    <span>{indata.Destination_Country}</span>
                  </li>
                )}
                {indata.Program_Level && (
                  <li>
                    <span className="scholarship-details-list-heading">
                      Level
                    </span>
                    <br />
                    <span>{indata.Program_Level}</span>
                  </li>
                )}
                {indata.Application_End_Date && (
                  <li>
                    <span className="scholarship-details-list-heading">
                      Deadline
                    </span>
                    <br />
                    <span>{indata.Application_End_Date}</span>
                  </li>
                )}
                {params.get("m") && (
                  <li>
                    <span className="scholarship-details-list-heading" >
                      Match
                    </span>
                    <br />
                    <span className={params.get("c")}>{params.get("m")}</span>
                  </li>
                )}
              </ul>
            </div>
            <div className="form-button mb-5 d-flex justify-content-between">
              <div>
                <a
                  href={indata.Application_Link}
                  target="_blank"
                  className="btn btn-primary-regular"
                  rel="noreferrer"
                >
                  Apply Scholarship{" "}
                  <i className="ms-1 fa-solid fa-xs fa-arrow-up-right-from-square"></i>
                </a>
                <p className="mt-2">
                  <Link to="/scholarship">
                    <i className="fa fa-chevron-left" /> Back to listing
                  </Link>
                </p>
              </div>
              {/* for categorise */}
              <div className="form-group mb-0">
                <label for="inputState" className="form-label">
                  <strong>Update Status</strong>
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  value={cat}
                  onChange={onChange}
                >
                  {cat === "" ? (
                    <option selected value="">
                      Choose...
                    </option>
                  ) : (
                    ""
                  )}
                  <option value="Applied">Applied</option>
                  <option value="Approved">Approved</option>
                  <option value="SavedForLater">Saved for Later</option>
                  <option value="PendingDecision">Pending Decision</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
            </div>

            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Scholarship Overview
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <p>{indata.Description}</p>
                    <table className="table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" colSpan={2}>
                            Overview
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {indata.Scholarship_Name && (
                          <tr>
                            <td>Scholarship Name</td>
                            <td>{indata.Scholarship_Name}</td>
                          </tr>
                        )}
                        {indata.Application_Start_Date && (
                          <tr>
                            <td>Application Start Date</td>
                            <td>{indata.Application_Start_Date}</td>
                          </tr>
                        )}
                        {indata.Application_End_Date && (
                          <tr>
                            <td>Application End Date</td>
                            <td>{indata.Application_End_Date}</td>
                          </tr>
                        )}
                        {indata.Applicable_for && (
                          <tr>
                            <td>Awarded for</td>
                            <td>{indata.Applicable_for}</td>
                          </tr>
                        )}
                        {indata.Amount && (
                          <tr>
                            <td>Amount</td>
                            <td>{indata.Amount}</td>
                          </tr>
                        )}
                        {indata.State && (
                          <tr>
                            <td>State</td>
                            <td>{indata.State}</td>
                          </tr>
                        )}
                        {indata.University && (
                          <tr>
                            <td>University</td>
                            <td>{indata.University}</td>
                          </tr>
                        )}
                        {indata.Type_of_Scholarship && (
                          <tr>
                            <td>Type of Scholarship</td>
                            <td>{indata.Type_of_Scholarship}</td>
                          </tr>
                        )}
                        {indata.Awarding_Body && (
                          <tr>
                            <td>Awarding Body</td>
                            <td>{indata.Awarding_Body}</td>
                          </tr>
                        )}
                        {indata.Weblink && (
                          <tr>
                            <td>Weblink</td>
                            <td>
                              <a
                                href={indata.Weblink}
                                target="_blank"
                                className=""
                                rel="noreferrer"
                              >
                                Details
                              </a>
                              <i className="ms-1 fa-solid fa-xs fa-arrow-up-right-from-square"></i>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    Eligibility
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <table className="table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" colSpan={2}>
                            Overview
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {indata.Academic_Eligibility && (
                          <tr>
                            <td>Academic Eligibility</td>
                            <td>{indata.Academic_Eligibility}</td>
                          </tr>
                        )}
                        {indata.Race_Ethnicity && (
                          <tr>
                            <td>Race/Ethnicity</td>
                            <td>{indata.Race_Ethnicity}</td>
                          </tr>
                        )}

                        {indata.Sex && (
                          <tr>
                            <td>Sex</td>
                            <td>{indata.Sex}</td>
                          </tr>
                        )}
                        {indata.Category && (
                          <tr>
                            <td>Special Category</td>
                            <td>{indata.Category}</td>
                          </tr>
                        )}
                        {indata.Study_Area && (
                          <tr>
                            <td>Study Area</td>
                            <td>{indata.Study_Area}</td>
                          </tr>
                        )}
                        {indata.Program_Level && (
                          <tr>
                            <td>Program Level</td>
                            <td>{indata.Program_Level}</td>
                          </tr>
                        )}
                        {indata.Eligible_Citizenship && (
                          <tr>
                            <td>Citizenship</td>
                            <td>{indata.Eligible_Citizenship}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    Application Process
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body">
                    {
                      <table className="table">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" colSpan={2}>
                              Overview
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {indata.Contact_Name && (
                            <tr>
                              <td>Contact Name</td>
                              <td>{indata.Contact_Name}</td>
                            </tr>
                          )}
                          {indata.Contact_ID && (
                            <tr>
                              <td>Contact ID</td>
                              <td>
                                <a href={"mailto:" + indata.Contact_ID}>
                                  {indata.Contact_ID}
                                </a>
                              </td>
                            </tr>
                          )}
                          {indata.Contact_Number && (
                            <tr>
                              <td>Contact Number</td>
                              <td>
                                <a href={"tel:" + indata.Contact_Number}>
                                  {indata.Contact_Number}
                                </a>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between footer-link-container">
              <Link to="/scholarship">
                <i className="fa fa-chevron-left" /> Back to listing
              </Link>
              <div className="form-button">
                <a
                  href={indata.Application_Link}
                  target="_blank"
                  className="btn btn-primary-regular"
                  rel="noreferrer"
                >
                  Apply Scholarship{" "}
                  <i className="ms-1 fa-solid fa-xs fa-arrow-up-right-from-square"></i>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="footer-links">
        <button
          type="button"
          className="footer-btn"
          data-bs-toggle="modal"
          data-bs-target="#disclaimerModal"
        >
          Disclaimer
        </button>
        <button
          type="button"
          className="footer-btn"
          data-bs-toggle="modal"
          data-bs-target="#TnCModal"
        >
          Terms &amp; Conditions
        </button>
        <div
          className="modal fade"
          id="disclaimerModal"
          tabIndex={-1}
          aria-labelledby="disclaimerModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="disclaimerModal">
                  Disclaimer
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  The ScholaRISE Scholarship Portal ("Portal") is an online
                  platform designed to connect students with scholarship
                  opportunities. While we strive to provide accurate and
                  up-to-date information, we would like to clarify the following
                  points:
                </p>
                <ol>
                  <li>
                    <strong>Scholarship Outcome:</strong> The selection and
                    awarding of scholarships are at the sole discretion of the
                    scholarship providers. ScholaRISE does not have any control
                    over the outcome of scholarship applications. We serve as a
                    facilitator to help students find and apply for relevant
                    scholarships, but the final decision rests with the
                    scholarship provider.
                  </li>
                  <li>
                    <strong>No Charges for Access:</strong> ScholaRISE does not
                    charge students for accessing the Portal or using its
                    services. Our primary objective is to provide a free
                    resource to assist students in their scholarship search and
                    application process.
                  </li>
                  <li>
                    <strong>No Benefit from Scholarships:</strong> ScholaRISE
                    does not benefit financially from any scholarships awarded
                    to students through the Portal. We are committed to
                    providing unbiased and objective information to help
                    students access financial aid opportunities.
                  </li>
                  <li>
                    <strong>Scholarship Articulation:</strong> We have taken
                    utmost care to present scholarship details accurately and
                    comprehensively. However, it is important to note that
                    scholarship information, including eligibility criteria,
                    application deadlines, and award amounts, is subject to
                    change. We recommend that students verify the details
                    directly with the scholarship provider before applying.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="TnCModal"
          tabIndex={-1}
          aria-labelledby="TnCModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="TnCModal">
                  Terms &amp; Conditions
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  By accessing and using the ScholaRISE Scholarship Portal, you
                  agree to the following terms and conditions:
                </p>
                <ol>
                  <li>
                    <strong>Eligibility:</strong> The Portal is available to
                    students who meet the eligibility criteria specified by the
                    scholarship providers. It is your responsibility to ensure
                    that you meet the requirements before applying for any
                    scholarships listed on the Portal.
                  </li>
                  <li>
                    <strong>Accuracy of Information:</strong> While we strive to
                    provide accurate and up-to-date information, we cannot
                    guarantee the accuracy, completeness, or reliability of the
                    scholarship details. We encourage users to verify the
                    information directly with the scholarship provider.
                  </li>
                  <li>
                    <strong>Third-Pay Websites:</strong> The Portal may contain
                    links to third-party websites or resources. These links are
                    provided for your convenience, and we do not endorse or have
                    control over the content, policies, or practices of these
                    websites. Your use of third-party websites is at your own
                    risk.
                  </li>
                  <li>
                    <strong>Privacy:</strong> We respect your privacy and handle
                    your personal information in accordance with our Privacy
                    Policy. By using the Portal, you consent to the collection,
                    use, and disclosure of your information as described in the
                    Privacy Policy.
                  </li>
                  <li>
                    <strong>Intellectual Property:</strong> All content and
                    materials on the Portal, including text, graphics, logos,
                    and images, are protected by intellectual property laws. You
                    may use the information for personal, non-commercial
                    purposes only. Any unauthorized use or reproduction of the
                    content is strictly prohibited.
                  </li>
                  <li>
                    <strong>Limitation of Liability:</strong> ScholaRISE and its
                    affiliates shall not be liable for any direct, indirect,
                    incidental, consequential, or punitive damages arising out
                    of or in connection with your use of the Portal or reliance
                    on the scholarship information provided.
                  </li>
                </ol>
                <p>
                  These terms and conditions may be subject to change without
                  prior notice. It is your responsibility to review them
                  periodically for any updates.
                </p>
                <p>
                  If you have any questions or concerns regarding the Portal,
                  please contact our support team at{" "}
                  <a href="mailto:contact@risehighereducation.com">
                    contact@risehighereducation.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
