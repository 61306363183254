import React, { useEffect, useState } from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'
import { countriesData } from '../../Assets/Data/countriesDetails'
import countries from '../../Assets/Data/countries.json'
import { url } from '../../Constants'

import swal from 'sweetalert'

const CountryTile = ({ data, name, value, quality, studyDest, handleSaveStudyDest }) => {
  // Render text for each quality
  const renderText = (quality, data, country) => {
    let populatedData = ''
    switch (quality) {
      case 'Employment Rate':
        populatedData = `${data}%`
        break
      case 'University Rankings':
        populatedData = `${data} universities out Top 100 are in ${country}`
        break
      case 'Tuition Fees':
        populatedData = `Annual Average $${data}`
        break
      case 'Graduate Pay Scales':
        populatedData = `$${data} Per Annum`
        break
      case 'Cost of Living':
        populatedData = `$${data} Per Annum`
        break
      case 'PR and Settlement Opportunities':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Spouse Work Rights':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Cultural Fit':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Safety and Security':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Quality of Life':
        populatedData = `Rated ${data} out of 5`
        break
      default:
        populatedData = 'Invalid quality specified'
    }
    return populatedData
  }

  return (
    <div className="d-flex justify-content-start align-items-center countryTile_container">
      <span className="countryTile_tag">{value}</span>
      {studyDest.includes(name) ? (
        <small className="badge bg-success">Saved Study Destination</small>
      ) : (
        <button
          onClick={() => handleSaveStudyDest([...studyDest, name])}
          className='btn btn-link'
          disabled={studyDest.length >= 3} // Disable button if 3 destinations are already selected
        >
          {studyDest.length >= 3 ? 'Max Study Destinations Selected' : 'Save Study Destinations'}
        </button>
      )}
      <h1 className="countryTile_h1">{name}</h1>
      <p className="countryTile_score">
        {renderText(quality, data[quality], name)}
      </p>
    </div>
  )
}

const CountryPriroty = ({
  setShow,
  thumbData,
  setModals,
  setCountry,
  setThumbData,
  setScript,
}) => {
  const navigate = useNavigate()
  const [showResult, setShowResult] = useState(false)
  const [formData] = useState([
    { name: 'Employment Rate' },
    { name: 'University Rankings' },
    { name: 'Tuition Fees' },
    { name: 'PR and Settlement Opportunities' },
    { name: 'Graduate Pay Scales' },
    { name: 'Spouse Work Rights' },
    { name: 'Cost of Living' },
    { name: 'Cultural Fit' },
    { name: 'Safety and Security' },
    { name: 'Quality of Life' },
  ])
  const [quality, setQuality] = useState('')
  const [studyDest, setStudyDest] = useState([])

  // Fetch study destination
  const getData = async () => {
    try {
      const response = await fetch(`${url}/auth/verifyuser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'token': localStorage.getItem('token'),
        },
      })

      const res_json = await response.json()
      const fetchedStudyDest = res_json?.data?.studyDest ?? []
      setStudyDest(fetchedStudyDest)
    } catch (error) {
      console.error('Error fetching study destination:', error)
    }
  }

  const handleSaveStudyDest = async (newStudyDestArray) => {
    try {
      const response = await fetch(`${url}/user/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        },
        body: JSON.stringify({
          feild: 'userInfo',
          data: { studyDest: newStudyDestArray },
        }),
      })

      if (!response.ok) {
        const errorMessage = await response.text()
        swal({
          title: 'Error!',
          text: `Failed with status: ${response.status}`,
          icon: 'error',
        })
        return
      }

      const result = await response.json()

      if (result.success) {
        swal({
          title: 'Saved!',
          text: 'Study destination updated successfully.',
          icon: 'success',
        })

        // Refresh study destinations
        await getData()
      } else {
        swal({
          title: 'Error!',
          text: result.message || 'Failed to update the destination.',
          icon: 'error',
        })
      }
    } catch (error) {
      swal({
        title: 'Error!',
        text: 'Failed to update the destination. Please try again.',
        icon: 'error',
      })
      console.error('Error saving study destination:', error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (showResult && quality) {
      let sortedCountries = countriesData
        .sort((a, b) => (quality === 'Tuition Fees' || quality === 'Cost of Living' ? a[quality] - b[quality] : b[quality] - a[quality]))
        .slice(0, 3)

      sortedCountries = sortedCountries.map((itm) => itm.Country)

      const countryArr = countries.filter((item) => {
        if (sortedCountries.includes(item.name)) {
          item[quality] = countriesData.find((cd) => cd.Country === item.name)[
            'Data_' + quality.split(' ').join('_')
          ]
          item['value'] = countriesData.find((cd) => cd.Country === item.name)[
            quality
          ]
          return item
        }
      })

      setThumbData(countryArr)
      setScript(countryArr[0])
    }
  }, [quality, showResult])

  return (
    <>
      <div className="modal-body">
        <div className="d-flex justify-content-between">
          <p className="modal-subheading">Select your priority</p>
        </div>
        <div
          className="d-flex justify-content-center  align-items-center w-100"
          style={{ gap: '1rem' }}
        >
          <select
            className="form-select"
            onChange={(e) => {
              setShowResult(true)
              setQuality(e.target.value)
              if (!e.target.value) {
                setShowResult(false)
                setThumbData([])
              }
            }}
          >
            <option value="">Please Select</option>
            {formData?.map((itm) => (
              <option key={itm?.name} value={itm?.name}>
                {itm?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {showResult && (
        <div className="px-3 pb-3">
          <h1
            className="sortTile_h1 text-center"
            style={{ fontWeight: '600', color: '#000' }}
          >
            Top 3 Results{' '}
          </h1>
          <br />
          <br />
          <div
            className="d-flex flex-row justify-content-center result-container"
            style={{ gap: '1rem' }}
          >
            {thumbData?.map((item, index) => (
              <CountryTile
                key={item.name}
                data={item}
                name={item.name}
                value={index + 1}
                quality={quality}
                studyDest={studyDest}
                handleSaveStudyDest={handleSaveStudyDest}
              />
            ))}
          </div>
        </div>
      )}

      <FooterModal
        navigate={navigate}
        nextElement={
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn"
              onClick={() => {
                setShow(false)
                setCountry(false)
                setThumbData([])
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  needHelpCourse: false,
                  countryScore: false,
                  courseMain: true,
                })
              }}
            >
              Skip to Course Counseling
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-primary-regular"
              disabled={!quality}
              onClick={() => {
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  courseMain: false,
                  needHelpCourse: false,
                })
                setShow(false)
              }}
            >
              Start Counseling
            </button>
          </div>
        }
      />
    </>
  )
}

export default CountryPriroty;
