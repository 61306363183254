import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import { getNewToken } from "../utils/CalendlyTokens";
import { MdChat } from "react-icons/md";

const AllBookings = () => {
  const params = { id: localStorage.getItem("mentor_id") };
  // const [status, setStatus] = useState("");
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();

  const getBookings = async () => {
    try {
      const data = await API("get", `/mentor/all-bookings/${params.id}`);
      setBookings(data?.data?.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getBookings();
  }, []);

  return (
    <div className="container">
      <div className="table-responsive grid-frame">
        <table className="table admin-table">
          <thead>
            <tr className="table-dark">
              {" "}
              <th scope="col">Sr</th>
              <th scope="col">Student Name</th>
              <th scope="col">Student Email </th>
              <th scope="col">Meeting Time </th>
              <th scope="col">Status</th>
              <th scope="col">Student Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {bookings?.map((itm, i) => (
              <tr>
                <td>
                  <p>{i + 1}</p>
                </td>
                <td>
                  <p>
                    {itm?.user?.firstName} {itm?.user?.lastName}
                  </p>
                </td>
                <td>
                  <p>{itm?.user?.email}</p>
                </td>
                <td>
                  <p>
                    {moment(itm?.meetingTime).format("MMMM Do YYYY, h:mm:ss a")}
                  </p>
                </td>
                {/* <td>
                  <p>{itm?.meetinglink}</p>
                </td> */}
                <td>
                  <p>{itm?.status}</p>
                </td>
                <td>
                  <p>{itm?.studentStatus}</p>
                </td>

                <td>
                  <div className="d-flex">
                    <select
                      className="form-select"
                      value={itm?.status}
                      onChange={async (e) => {
                        let url = itm?.meetinglink + "/cancellation";
                        try {
                          if (e.target.value == "Cancelled") {
                            const token = await getNewToken(params.id);
                            await (
                              await fetch(url, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                  reason: "",
                                }),
                              })
                            ).json();
                          }
                          // if (e.target.value == "No-Show") {
                          //   // const token = await getNewToken(params.id);
                          // }
                          await API("put", `mentor/booking/${itm?._id}`, {
                            status: e.target.value,
                          });
                          swal({
                            title: "Success",
                            text: "Status Updated Successfully!",
                            icon: "success",
                            button: "Ok",
                          });
                          getBookings();
                        } catch (error) {}
                      }}
                    >
                      <option value="Created">Created</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="No-Show">No-Show</option>
                      <option value="Completed">Completed</option>
                    </select> &nbsp;&nbsp;
                    <Link to={`/mentor/chat/${params.id}?u=${itm?.user?._id}`}>
                      <MdChat style={{ fontSize: "24px", color: "#DC7215" }} />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllBookings;
