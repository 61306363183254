import React, { useEffect, useState } from "react";
import courseData from "../../Assets/Data/coursecounseling.json";
import countryCodes from "../../Assets/Data/countries.json";
import country_codes from "../../Assets/Data/countries_code.json";
import Select from "react-select";
import zIndex from "@mui/material/styles/zIndex";

import { validatePersonalInfo } from "../../../utils/validation";

import { parseISO } from "date-fns";

const PersonalInfo = (props) => {
    
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country_code: "",
    phone: "",
    dob: "",
    gender: "",
    marital: "",
    subject: "",
    course_of_interest: [],
    level: "",
    studyDest: [],
    language: "",
    citizenship: "",
    mAddress: {
      address: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
    pAddress: {
      address: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
  });

  const [course, setCourse] = useState([]);
  const [sameAsMail, setSameAsMail] = useState(false);

  const onChange = (e) => {
    const target = e.target.name.split(".");

    if (target.length === 2) {
      setData({
        ...data,
        [target[0]]: {
          ...data[target[0]],
          [target[1]]: e.target.value,
        },
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const [errors, setErrors] = useState({});
  const handleSave = () => {
    const { isValid, errors } = validatePersonalInfo(data);
    setErrors(errors);
  
    if (isValid) {
      props?.updateData("userInfo", data);
    } else {
    }
  };

  const formatDateString = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = parseISO(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (
      props.data !== null &&
      props.data !== undefined &&
      props.data.level !== undefined
    ) {
      const formattedDob = props.data.dob
        ? formatDateString(props.data.dob)
        : "";
      setData({
        ...props.data,
        dob: formattedDob,
      });
    } else if (props.data !== null && props.data !== undefined) {
      const formattedDob = props.data.dob
        ? formatDateString(props.data.dob)
        : "";
      setData({
        ...data,
        firstName: props.data.firstName,
        lastName: props.data.lastName,
        phone: props.data.phone,
        email: props.data.email,
        country_code: props.data.country_code,
        studyDest: props.data.studyDest,
        dob: formattedDob,
        gender: props.data.gender,
        marital: props.data.marital,
        language: props.data.language,
        subject: props.data.subject,
        course_of_interest: props.data.course_of_interest,
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (data.subject && data.subject.length > 0) {
      setCourse(courseData[data.subject]);
    }
  }, [data.subject]);

  return (
    <div
      className="tab-pane fade show active"
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
    >
      <div className="row profile-section">
        <div className="col">
          <div className="section-head">Personal Information</div>
          <div className="form-group section-content">
            <div className="row responsive-col">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        disabled="disabled"
                        placeholder=""
                        defaultValue=""
                        name="firstName"
                        value={data.firstName}
                        onChange={onChange}
                        aria-label=""
                      />
                      <label htmlFor="">First Name</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        disabled="disabled"
                        placeholder=""
                        defaultValue=""
                        name="lastName"
                        value={data.lastName}
                        onChange={onChange}
                        aria-label=""
                      />
                      <label htmlFor="">Last Name</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    min="1970-01-01"
                    max="2006-12-31"
                    name="dob"
                    value={data.dob}
                    onChange={onChange}
                    className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                    placeholder="Birth Date"
                    aria-label="Birth Date"
                  />
                  <label htmlFor="">
                    Birth Date (mm/dd/yyyy)<span className="required">*</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    disabled="disabled"
                    name="email"
                    value={data.email}
                    onChange={onChange}
                    defaultValue=""
                    placeholder="Email"
                    aria-label="Email"
                  />
                  <label htmlFor="">Email</label>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col countryCode">
                    <div className="form-floating mb-3">
                      <input
                        list="countryCodeData"
                        className="form-control"
                        placeholder="Country Code"
                        name="country_code"
                        value={data.country_code}
                        onChange={onChange}
                        aria-label=""
                        maxLength={10}
                        defaultValue={+1}
                      />
                      <datalist id="countryCodeData">
                        {country_codes.map((op, i) => (
                          <option value={op.dial_code}>
                            {op.dial_code + " " + op.name}
                          </option>
                        ))}
                      </datalist>
                      <label htmlFor="">
                        Country Code
                        <span className="required countryCode">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        aria-label="Phone Number"
                        name="phone"
                        value={data.phone}
                        onChange={onChange}
                        disabled=""
                        maxLength={10}
                        defaultValue=""
                      />
                      <label htmlFor="">
                        Phone Number<span className="required">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="col">
                <label htmlFor="">
                  Gender<span className="required">*</span>
                </label>
                <br />
                <input
                  type="radio"
                  className="btn-check"
                  checked={data.gender === "male"}
                  onClick={() => {
                    setData({ ...data, gender: "male" });
                  }}
                  id="option1"
                  autoComplete="off"
                  defaultChecked=""
                />
                <label className="btn btn-secondary" htmlFor="option1">
                  <i className="fa-solid fa-person" /> Male
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  checked={data.gender === "female"}
                  onClick={() => {
                    setData({ ...data, gender: "female" });
                  }}
                  id="option2"
                  autoComplete="off"
                />
                <label className="btn btn-secondary" htmlFor="option2">
                  <i className="fa-solid fa-person-dress" /> Female
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  checked={data.gender === "non-binary"}
                  onClick={() => {
                    setData({ ...data, gender: "non-binary" });
                  }}
                  autoComplete="off"
                  id="option3"
                />
                <label className="btn btn-secondary" htmlFor="option3">
                  <i className="fa-solid fa-genderless" /> Non-Binary
                </label>
              </div>
              <div className="col">
                <label htmlFor="">
                  Marital Status<span className="required">*</span>
                </label>
                <br />
                <input
                  type="radio"
                  className="btn-check"
                  checked={data.marital === "married"}
                  onClick={() => {
                    setData({ ...data, marital: "married" });
                  }}
                  id="option4"
                  autoComplete="off"
                  defaultChecked=""
                />
                <label className="btn btn-secondary" htmlFor="option4">
                  Married
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  checked={data.marital === "unmarried"}
                  onClick={() => {
                    setData({ ...data, marital: "unmarried" });
                  }}
                  id="option5"
                  autoComplete="off"
                />
                <label className="btn btn-secondary" htmlFor="option5">
                  Unmarried
                </label>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="col">
                <div className="form-floating mt-3 mb-3">
                  <select
                    className="form-select"
                    name="subject"
                    value={data.subject}
                    onChange={onChange}
                    placeholder="Select Subject"
                  >
                    <option value={""}>Select Subject</option>
                    {Object?.keys(courseData).map((idx) => (
                      <option key={idx}>{idx}</option>
                    ))}
                  </select>
                  <label htmlFor="">Subject</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mt-3 mb-3">
                  {/* <select className="form-select" name='course_of_interest' value={data.course_of_interest} onChange={onChange} placeholder="Select Course of Interest">
                                        {course.length === 0 ? <option value="Select Course of Interest">Select Subject to get courses</option> : ""}
                                        {course.map((item, idx) => (
                                            <option >{item.name}</option>
                                        ))}
                                    </select> */}
                  <Select
                    className="app-multiSelect"
                    isMulti
                    value={data?.course_of_interest?.map((value) => ({
                      value,
                      label: value,
                    }))}
                    options={
                      course.length === 0
                        ? [
                            {
                              label: "Select Subject to get courses",
                              value: "",
                            },
                          ]
                        : course.map((op, i) => ({
                            value: op.name,
                            label: op.name,
                          }))
                    }
                    onChange={(selectedOptions) => {
                      const newData = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      setData((prevData) => ({
                        ...prevData,
                        course_of_interest: newData,
                      }));
                    }}
                    placeholder="Course of Interest"
                    isOptionDisabled={() => {
                      if (course.length === 0) return 1;
                      else return data?.course_of_interest?.length >= 5;
                    }}
                  />
                  {/* <label htmlFor="">Course of Interest</label> */}
                </div>
              </div>
              <div className="col">
                <div className="form-floating mt-3 mb-3">
                  <select
                    className="form-select"
                    name="level"
                    value={data.level}
                    onChange={onChange}
                    placeholder="Select level"
                  >
                    <option value="Select Level">Select Level</option>
                    <option value="English Language Program">
                      English Language Program
                    </option>
                    <option value="Foundation">Foundation</option>
                    <option value="Online Programmes / Distance Learning">
                      Online Programmes / Distance Learning
                    </option>
                    <option value="Pathway Programs">Pathway Programs</option>
                    <option value="Masters">Masters</option>
                    <option value="PG Diploma/Certificate">
                      PG Diploma/Certificate
                    </option>
                    <option value="PhD">PhD</option>
                    <option value="Short Term Programs">
                      Short Term Programs
                    </option>
                    <option value="Technical School/College">
                      Technical School/College
                    </option>
                    <option value="Trade School/College">
                      Trade School/College
                    </option>
                    <option value="Twinning Programmes (PG)">
                      Twinning Programmes (PG)
                    </option>
                    <option value="Bachelors">Bachelors</option>
                    <option value="UG Diploma/ Certificate/ Associate Degree">
                      UG Diploma/ Certificate/ Associate Degree
                    </option>
                  </select>
                  <label htmlFor="">Level</label>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="col">
                <div className="form-floating mb-3">
                  <label htmlFor="">Study Destination</label>
                  {/* <select name="studyDest" value={data.studyDest} onChange={onChange} className="form-control"
                                        defaultValue=""
                                        placeholder="" aria-label="" >
                                        <option value={""}>Select Country</option>
                                        
                                    </select> */}
                  <Select
                    className="app-multiSelect"
                    isMulti
                    value={data?.studyDest?.map((value) => ({
                      value,
                      label: value,
                    }))}
                    options={countryCodes.map((op, i) => ({
                      value: op.name,
                      label: op.name,
                    }))}
                    onChange={(selectedOptions) => {
                      const newData = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      setData((prevData) => ({
                        ...prevData,
                        studyDest: newData,
                      }));
                    }}
                    placeholder="Study Destination"
                    isOptionDisabled={() => data.studyDest.length >= 3}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="language"
                    value={data.language}
                    onChange={onChange}
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                  />
                  <label htmlFor="">First Language</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    name="citizenship"
                    value={data.citizenship}
                    onChange={onChange}
                    placeholder=""
                  >
                    <option value={""}>Please select</option>
                    {countryCodes.map((op, i) => (
                      <option value={op.name}>{op.name}</option>
                    ))}
                  </select>
                  <label htmlFor="">Country of Citizenship</label>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <p className="mt-4">Mailing Address</p>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="mAddress.address"
                    value={data?.mAddress?.address}
                    onChange={onChange}
                  />
                  <label htmlFor="">Address</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="mAddress.city"
                    value={data?.mAddress?.city}
                    onChange={onChange}
                  />
                  <label htmlFor="">City/Town</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="mAddress.state"
                    value={data?.mAddress?.state}
                    onChange={onChange}
                  />
                  <label htmlFor="">Province/State</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="mAddress.pincode"
                    value={data?.mAddress?.pincode}
                    onChange={onChange}
                  />
                  <label htmlFor="">Pincode</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="mAddress.country"
                    value={data?.mAddress?.country}
                    onChange={onChange}
                  />
                  <label htmlFor="">Country</label>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="mt-4 d-flex justify-content-between">
                <p>Permanent Address</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault"
                    onChange={() => {
                      if (!sameAsMail) {
                        setData({ ...data, pAddress: data.mAddress });
                      } else {
                        setData({
                          ...data,
                          pAddress: {
                            address: "",
                            city: "",
                            state: "",
                            pincode: "",
                            country: "",
                          },
                        });
                      }
                      setSameAsMail(!sameAsMail);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Same as Mailing Address
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="pAddress.address"
                    value={data?.pAddress?.address}
                    onChange={onChange}
                  />
                  <label htmlFor="">Address</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="pAddress.city"
                    value={data?.pAddress?.city}
                    onChange={onChange}
                  />
                  <label htmlFor="">City/Town</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="pAddress.state"
                    value={data?.pAddress?.state}
                    onChange={onChange}
                  />
                  <label htmlFor="">Province/State</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="pAddress.pincode"
                    value={data?.pAddress?.pincode}
                    onChange={onChange}
                  />
                  <label htmlFor="">Pincode</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    placeholder=""
                    aria-label=""
                    name="pAddress.country"
                    value={data?.pAddress?.country}
                    onChange={onChange}
                  />
                  <label htmlFor="">Country</label>
                </div>
              </div>
            </div>
            <div className="row responsive-col">
              <div className="col text-end action-btn-group">
                <button type="submit" className="btn btn-secondary">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-regular"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
