import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Sidebar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../Constants";
import API from "../../Api/Api";

import { useTrialTimer } from "../useTimer";
import { useTimer } from "react-timer-hook";

const Layout = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ credits: 0 });
  const [notificationCount, setNotificationsCount] = useState(0);
  const { seconds, minutes, start, isRunning } = useTimer({
    autoStart: true,
    expiryTimestamp: new Date().setSeconds(
      new Date().getSeconds() + Number(localStorage.getItem("seconds"))
    ),
    onExpire: () => {
      //
    },
  });
  const [time, setTime] = useState({ min: 0, sec: 0 });

  useEffect(() => {
    setTime({ min: minutes, sec: seconds });
  }, [seconds]);

  const getUserDetail = async () => {
    try {
      const response = await API("post", `/auth/verifyuser`);
      localStorage.setItem("_id", response.data.data._id);
      setUserData(response.data.data);
    } catch {}
  };

  const getNotitificationsCount = async () => {
    try {
      const response = await API(
        "get",
        `/mentor/notifications/${userData?._id}?type=user`
      );
      setNotificationsCount(response.data.data);
    } catch {}
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUserDetail();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") && userData?._id) {
      getNotitificationsCount();
    }
  }, [userData?._id]);

  return (
    <>
      <div className="container-fluid d-flex bg-white">
        <div className="page-container no-leftnav">
          <>
            <header className="fixed-top fixed-top d-flex justify-content-between align-items-center">
              <Link to={"/login"}>
                <img
                  src={require("../Assets/Media/Images/logo.png")}
                  alt="logo-rise"
                  className="header-logo"
                  title="Rise Higher Education"
                />
              </Link>
              {window.location.href.includes("p-counseling") &&
                localStorage.getItem("seconds") && (
                  <p
                    className={time.min == 0 && time.sec < 10 ? "timeOver" : ""}
                  >
                    {" "}
                    Remaining Time: {time.min} : {time.sec}{" "}
                  </p>
                )}
            </header>
            <Outlet />
            {/* <script src="//code.tidio.co/dy6tp59099psaaihmspn4xutfmxfsnil.js"></script> */}
          </>
        </div>
      </div>
    </>
  );
};

export default Layout;
