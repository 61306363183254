import React from "react";
import { MdWarning } from "react-icons/md";
const PrivateLoginModal = () => {

  return (
    <div className="modal-body text-center">
      <MdWarning style={{ color: "#DC7215", fontSize: "4rem" }} />
      <h1>Please login to continue</h1>
      <br />
      <div className="modal-footer d-flex justify-content-end">
        <button
          onClick={() => {
            window.location.href = "/login"
          }}
          className="widget-cta-page-nopos responsive-visible float-end"
        >
          Click here to login
        </button>
      </div>
    </div>
  );
};

export default PrivateLoginModal;
