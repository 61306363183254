import { useFaceLandmarker } from '@react-three/drei'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'
import Crunker from 'crunker'
const AudioContext = createContext()

export function useAudio() {
  return useContext(AudioContext)
}

export function AudioProvider({ children }) {
  const [captionsEnable, setCaptionsEnable] = useState(true)
  const audio = useMemo(() => new Audio(), [])
  const [isPlaying, setIsPlaying] = useState(false)
  const [audioTime, setAudioTime] = useState(0)
  const [script, setScript] = useState({})
  const [country, setCountry] = useState(true)
  const [maxLimit, setMaxLimit] = useState(3)
  const [thumbData, setThumbData] = useState([])
  const [activeIdx, setActiveIdx] = useState(0)
  const [lipSyncData, setLipSyncData] = useState([])
  const [loadingAudio, setLoadingAudio] = useState(true)
  const location = useLocation()
  let crunker = new Crunker()

  useEffect(() => {
    audio.addEventListener('timeupdate', () => {
      setAudioTime(audio.currentTime)
    })
    return () => {
      audio.removeEventListener('timeupdate', () => {
        setAudioTime(audio.currentTime)
      })
    }
  }, [audio])

  const togglePlay = () => {
    // if (audio.paused || audio.ended) {
    if (!isPlaying) {
      audio.load()
      audio.play()
      setIsPlaying(true)
    } else {
      audio.pause()
      setIsPlaying(false)
    }
  }

  const handleSeek = (seekTime) => {
    audio.currentTime = seekTime
    setAudioTime(seekTime)
  }

  const fetchLipSyncData = async () => {
    const res = await fetch(
      country
        ? script.lipsync
        : script.ug
        ? script.UGLipSync
        : script.PGLipSync,
    )
    const data = await res.json()
    setLipSyncData(data)
  }

  const loadAudioCustom = (audiourl) => {
    // fetch(audiourl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Set the audio source to the downloaded blob
    //     let bloburl = URL.createObjectURL(blob)
    //     audio.src = bloburl
    //     audio.load()
    //     setLoadingAudio(false)
    //   })
    //   .catch((error) => {
    //     console.error('Error downloading audio:', error)
    //     setLoadingAudio(false)
    //   })
    crunker
      .fetchAudio(audiourl)
      .then((buffers) => {
        return crunker.mergeAudio(buffers)
      })
      .then((merged) => {
        return crunker.export(merged, 'audio/mp3')
      })
      .then((output) => {
        audio.src = URL.createObjectURL(output.blob)
        setLoadingAudio(false)
      })
      .catch((error) => {
        console.error('Error downloading audio:', error)
        setLoadingAudio(false)
      })
  }

  useEffect(() => {
    setIsPlaying(false)
    setLoadingAudio(true)
    setAudioTime(0)

    if (country && script.audio !== undefined) {
      setLoadingAudio(true)
      fetchLipSyncData()
      loadAudioCustom(script.audio)
    } else if (!country && script.UGAudio !== undefined) {
      fetchLipSyncData()
      loadAudioCustom(script.ug ? script.UGAudio : script.PGAudio)
      //   audio.src = script.ug ? script.UGAudio : script.PGAudio
      //   audio.load()
    }
  }, [script])

  useEffect(() => {
    if (thumbData?.length > 0) {
      localStorage.setItem('scriptData', JSON.stringify(thumbData))
    }
  }, [thumbData])

  useEffect(() => {
    if (country === true) {
      setMaxLimit(3)
    } else {
      setMaxLimit(5)
    }
    setThumbData([])
  }, [country])

  useEffect(() => {
    if (thumbData?.length > 0) {
      setScript(thumbData[0])
    }
  }, [thumbData])

  useEffect(() => {
    // console.log(location)
    // if (location.pathname !== '/counseling') {
    if (!location.pathname.includes('/counseling')) {
      setIsPlaying(false)
      setThumbData([])
      setAudioTime(0)
      audio.pause()
    }
  }, [location])

  return (
    <AudioContext.Provider
      value={{
        audio,
        isPlaying,
        audioTime,
        script,
        lipSyncData,
        country,
        thumbData,
        maxLimit,
        activeIdx,
        togglePlay,
        handleSeek,
        setScript,
        setCountry,
        setThumbData,
        setActiveIdx,
        loadingAudio,
        captionsEnable,
        setCaptionsEnable,
      }}
    >
      {children}
    </AudioContext.Provider>
  )
}
