import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const PaymentCheckCredit = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const refill = async () => {
    try {
      await API("post", `/subscription/user/purchase-subscription/success`, {
        studentid: localStorage.getItem("_id"),
        subscriptionid: searchParams.get("planid"),
        paymentid: searchParams.get("payment_intent"),
      });
      swal({
        title: "Transaction successfully!",
        text: "Subscription successfull, you will be redirected to dashboard and your subscription will be active.",
        icon: "success",
      }).then((res) => {
        if (res) {
          navigate("/userdashboard");
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (
      searchParams.get("payment_intent") &&
      searchParams.get("redirect_status") == "succeeded"
    ) {
      refill();
    }
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100vw", marginTop: "7rem" }}
    >
      <h1>Please wait, redirecting ...</h1>
    </div>
  );
};

export default PaymentCheckCredit;
