import { Canvas } from '@react-three/fiber'
import { Experience } from './Experience'
import Controls from './Controls'
import './style.css'
import { useAudio } from '../../Context/AudioContext'
import axios from 'axios'
import { useEffect, useState } from 'react'

export default function Home() {
  const { audioTime, thumbData, activeIdx, script, country, captionsEnable } = useAudio()
  const [captions, setCaptions] = useState([])
  function checkNumberInRange(number, array) {
    for (let i = 0; i < array.length; i++) {
      if (number >= array[i].start && number <= array[i].end) {
        return array[i].content
      }
    }
    return null
  }

  const getCaptionsJSON = async (url) => {
    try {
      const data = await axios.get(url)
      setCaptions(data?.data)
    } catch (error) {}
  }

  useEffect(() => {
    if(country){
      getCaptionsJSON(script?.subtitles)
    } else {
      script?.ug ? getCaptionsJSON(script?.UGSubtitles) : getCaptionsJSON(script?.PGSubtitles)
    }
    
  }, [script])

  return (
    <div className="frame">
      <div className="overlay"></div>
      <Canvas
        shadows
        camera={{ position: [0, 0, 8], fov: 25 }}
        className="canvas"
      >
        <color attach="background" args={['#ececec']} />
        <Experience />
      </Canvas>
      {
        captionsEnable && <p
        className='close-captions'
      >
        {checkNumberInRange(audioTime, captions)}
      </p>
      }
      
      <div className="controls">
        <Controls />
      </div>
    </div>
  )
}
