import React, { useEffect, useState } from 'react';
import '../Assets/Styles/programs.css';
import { SchoolsData, countryStateCity, countryWithSchools } from './programsData';
import { ProgramsData } from './coursesData';
import { BiSearch } from 'react-icons/bi'
import { Pagination } from 'react-bootstrap';
import { url } from "../Constants";

function Programs() {
    const [search, setSearch] = useState({
        country_id: '',
        country: '',
        city: '',
        sort: '',
        price: '',
        duration: ''
    });

    const searchData = (e) => {
        const { name, value } = e.target;
        setSearch((data) => {
            return {
                ...data, [name]: value,
                country_id: e.target.options[e.target.selectedIndex].getAttribute('data-id'),
            }
        })
    }

    // console.clear()

    const [eliFilterPro_1, setSeliFilterPro1] = useState('')
    const [eliFilterPro_2, setSeliFilterPro2] = useState('')


    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [searchUser, setSearchUser] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [programsData, getProgramsData] = useState([])


    const fetchProgramsData = async (page) => {
        const res = await fetch(`${url}/api/programs?page=${page}&search=${searchUser}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&school=${selectedSchool}&education_level=${eliFilterPro_1}`)
        const resData = await res.json();
        getProgramsData(resData.programs)
        setTotalPages(resData.pagination.totalPrograms)

        //console.log(resData)
        //console.log(resData.programs)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        fetchProgramsData(currentPage);
    }, [currentPage, searchUser]);



    // Schools/University and Progrmas Data

    const [schoolPrograms, setSchoolPrograms] = useState([]);

    // console.log(schoolPrograms);

    useEffect(() => {
        // Create a mapping of school IDs to school data from SchoolsData
        const schoolDataMap = {};
        SchoolsData.forEach((schoolItem) => {
            const schoolHashMap = schoolItem.schools.schoolHashMap;
            for (const schoolId in schoolHashMap) {
                if (schoolHashMap.hasOwnProperty(schoolId)) {
                    schoolDataMap[schoolId] = schoolHashMap[schoolId];
                }
            }
        });

        // Now you can retrieve school data for each program using the school_id
        const programsWithSchoolData = programsData.map((program) => {
            const schoolId = program.school_id;
            if (schoolDataMap.hasOwnProperty(schoolId)) {
                return {
                    ...program,
                    school_data: schoolDataMap[schoolId],
                };
            }
            return program;
        });

        setSchoolPrograms(programsWithSchoolData);
    }, [programsData, SchoolsData]);


    // end


    const [countries, setCountries] = useState([])
    const [city, setCity] = useState([])


    const countriesAPIData = async () => {
        try {
            const res = await fetch('https://www.casita.com/api/countries')

            const countriesGetData = await res.json();

            setCountries(countriesGetData.data);
            // console.log(countriesGetData.data)
        } catch (error) {
            //console.log(error, "error come from countries api")
        }
    }


    const cityData = async (id) => {

        try {
            const res = await fetch(`https://www.casita.com/api/cities/${id}`)

            const cityGetData = await res.json();

            setCity(cityGetData.data);
            // console.log(cityGetData.data)
        } catch (error) {
            //console.log(error, "error come from countries api")
        }
    }


    useEffect(() => {
        countriesAPIData()
    }, []);


    const [showElg, setShowElg] = useState(true);
    const [showSchool, setShowSchool] = useState(true);
    const [showProgram, setShowProgram] = useState(true);

    // const [countryFilterData, setCountryFilterData] = useState({
    //     country: '',
    //     state: '',
    //     city: ''
    // })



    // const searchCountryData = (e) => {
    //     const { name, value } = e.target;
    //     setCountryFilterData((nData) => {
    //         return {
    //             ...nData, [name]: value
    //         }
    //     })
    // }

    // sort by fee for first render

    const [sort, setSort] = useState('')
    const [tutionLowToHigh, setTutionLowToHigh] = useState([])
    const [tutionHighToLow, setTutionHighToLow] = useState([])
    const [feeLowToHigh, setFeeLowToHigh] = useState([])
    const [feeHighToLow, setFeeHighToLow] = useState([])


    const allPrograms = schoolPrograms;

    useEffect(() => {
        if (sort === "Tuition - Lowest First") {
            const sortedPrograms = [...allPrograms].sort((a, b) => {
                const tuitionA = parseFloat(a.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionB = parseFloat(b.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionA - tuitionB;
            });

            setTutionLowToHigh(sortedPrograms)
        } else if (sort === "Tuition - Highest First" || sort === "School Rank") {
            const sortedPrograms = [...allPrograms].sort((a, b) => {
                const tuitionB = parseFloat(b.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionA = parseFloat(a.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionB - tuitionA;
            });
            setTutionHighToLow(sortedPrograms)

        } else if (sort === "Application Fee - Lowest First" || sort === "Relevance") {
            const sortedPrograms = [...allPrograms].sort((a, b) => {
                const tuitionA = parseFloat(a.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionB = parseFloat(b.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionA - tuitionB;
            });
            setFeeLowToHigh(sortedPrograms)

        } else if (sort === "Application Fee - Highest First") {
            const sortedPrograms = [...allPrograms].sort((a, b) => {
                const tuitionB = parseFloat(b.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionA = parseFloat(a.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionB - tuitionA;
            });
            setFeeHighToLow(sortedPrograms)
        }

    }, [sort])





    const educationLevel = SchoolsData.flatMap((e) => e.filterOptions.studied_level)


    const intakes = SchoolsData.flatMap((e) => e.filterOptions.intakes)

    // get data for Course Branch



    const postScecondry = SchoolsData.flatMap((e) => e.filterOptions.categories)

    const [courseBranch, setCourseBranch] = useState('');
    const [courseSubBranch, setCourseSubBranch] = useState([]);




    // console.log(courseSubBranch);

    useEffect(() => {
        if (courseBranch) {
            const filterData = SchoolsData.flatMap((e) => e.filterOptions.sub_categories)

            if (filterData) {
                const subFilterData = filterData.filter((e) => e.label === courseBranch)
                const courseSubBranchData = subFilterData[0].options.map(e => e.label)
                setCourseSubBranch(courseSubBranchData)
            }

        }

    }, [courseBranch])

    const countriesData = SchoolsData.map((e) => e.filterOptions.countries)

    const innerArray = countriesData.flatMap((e) => e)

    const visaVermit = SchoolsData.map((e) => e.countryVisaPermitReferenceTypes)
    const visaFilter = visaVermit[0].map((e) => e)

    const availableCountries = SchoolsData.flatMap((e) => e.availableCountries)

    const sortOptions = SchoolsData.flatMap((e) => e.filterOptions.sort_options)

    var allcountryData = countryStateCity.flatMap((e) => e.countries)


    // filter-2


    const [campusData, setCampusData] = useState([]);

    const [filterbyCourse, setFIlterByCourse] = useState([])

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);


    // const countryWiseFilter = () => {
    //     if (selectedCountry && selectedState && selectedCity && selectedSchool) {
    //         const filteredPrograms = schoolPrograms.filter((program) => {
    //             return program.school_data.country === selectedCountry &&
    //                 program.school_data.province === selectedState &&
    //                 program.school_data.city === selectedCity &&
    //                 program.school_data.name === selectedSchool
    //         });
    //         console.log(filteredPrograms)
    //         setFIlterByCourse(filteredPrograms)

    //     } else if (selectedCountry && selectedState && selectedCity) {
    //         const filteredPrograms = schoolPrograms.filter((program) => {
    //             return program.school_data.country === selectedCountry &&
    //                 program.school_data.province === selectedState &&
    //                 program.school_data.city === selectedCity;
    //         });
    //         console.log(filteredPrograms)
    //         setFIlterByCourse(filteredPrograms)

    //     } else if (selectedCountry && selectedState) {
    //         const filteredPrograms = schoolPrograms.filter((program) => {
    //             return program.school_data.country === selectedCountry &&
    //                 program.school_data.province === selectedState;
    //         });
    //         console.log(filteredPrograms)
    //         setFIlterByCourse(filteredPrograms)

    //     } else if (selectedCountry) {
    //         const filteredPrograms = schoolPrograms.filter((program) => {
    //             return program.school_data.country === selectedCountry;
    //         });
    //         console.log(filteredPrograms)
    //         setFIlterByCourse(filteredPrograms)

    //     }
    // }


    //  end


    useEffect(() => {
        if (selectedCountry) {
            const selectedCountryData = allcountryData.find(country => country.name === selectedCountry);

            if (selectedCountryData) {
                setStates(selectedCountryData.provinces.map(province => province.name));
            }
        }
        setSelectedState('')
        setSelectedCity('')
        setSelectedSchool('')
        setSort('')
        setSort1('')
    }, [selectedCountry]);


    useEffect(() => {
        setSelectedCity('')
        setSelectedSchool('')
        setSort('')
        setSort1('')
    }, [selectedState])

    useEffect(() => {
        setSort('')
        setSort1('')
    }, [selectedCity])

    useEffect(() => {
        if (selectedCountry && selectedState) {
            const selectedCountryData = allcountryData.find(country => country.name === selectedCountry);


            if (selectedCountryData) {
                var selectedProvinceData = selectedCountryData.provinces.find(province => province.name === selectedState);
                if (selectedProvinceData) {
                    setCities(selectedProvinceData.cities);
                }
            }

            if (selectedState) {
                const selectedCampusData = SchoolsData.map((e) => e.schools.schoolHashMap)
                const selectedSchools = Object.values(selectedCampusData[0]).filter((e) => e.province === selectedState)
                setCampusData(selectedSchools.map((e) => e.name))
            }
        }



    }, [selectedCountry, selectedState, selectedCity]);


    // filter 1 and 3 Education Level working





    const [sort1, setSort1] = useState('')

    const eligibilityBtn = async (page) => {

        const filterByCourse = [...allPrograms].filter((e) => e.program_level_caption === eliFilterPro_1);
        setFIlterByCourse(filterByCourse)
        setSort('')
        setSort1('')
        setSeliFilterPro2('')

    }

    const eligibilityBtn1 = async (page) => {


        const res = await fetch(`${url}/api/programs?page=${page}&search=${searchUser}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&school=${selectedSchool}&education_level=${eliFilterPro_2}`)
        const resData = await res.json();
        getProgramsData(resData.programs)
        setTotalPages(resData.pagination.totalPrograms)

       
        //console.log(resData)
        //console.log(resData.programs)


        const filterByCourse = [...allPrograms].filter((e) => e.program_level_caption === eliFilterPro_2);
        setFIlterByCourse(filterByCourse)
        setSort('')
        setSort1('')
        setSeliFilterPro1('')
    }

    useEffect(() => {
        if (sort1 === "Tuition - Lowest First") {
            const sortedPrograms = [...filterbyCourse].sort((a, b) => {
                const tuitionA = parseFloat(a.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionB = parseFloat(b.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionA - tuitionB;
            });

            setTutionLowToHigh(sortedPrograms)
        } else if (sort1 === "Tuition - Highest First" || sort1 === "School Rank") {
            const sortedPrograms = [...filterbyCourse].sort((a, b) => {
                const tuitionB = parseFloat(b.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionA = parseFloat(a.tuition.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionB - tuitionA;
            });
            setTutionHighToLow(sortedPrograms)

        } else if (sort1 === "Application Fee - Lowest First" || sort1 === "Relevance") {
            const sortedPrograms = [...filterbyCourse].sort((a, b) => {
                const tuitionA = parseFloat(a.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionB = parseFloat(b.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionA - tuitionB;
            });
            setFeeLowToHigh(sortedPrograms)

        } else if (sort1 === "Application Fee - Highest First") {
            const sortedPrograms = [...filterbyCourse].sort((a, b) => {
                const tuitionB = parseFloat(b.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());
                const tuitionA = parseFloat(a.fee.replace("£", "").replace("$", "").replace("GBP", "").replace("USD", "").replace("CAD", "").trim());

                return tuitionB - tuitionA;
            });
            setFeeHighToLow(sortedPrograms)
        }

    }, [sort1])


    return (
        <div >
            <div className='programSearchBox'>
                <input onChange={(e) => setSearchUser(e.target.value)} className='form-control programSearch' placeholder='Search by course, school, location' type='text' />
                <button className=' programSearchBtn'> <BiSearch className='BiSearch' />Search</button>
            </div>

            <div className='programMainBox_1 container'>

                <div className='allFilters'>

                    {/* filter-1  */}

                    <div>
                        {
                            showElg ?
                                <>
                                    <div className='filterBox col '>
                                        <div className='eligibilityShowBox'>
                                            <div>Eligibility</div>
                                            <div><button onClick={() => setShowElg(!showElg)} className='eligibleBtn'>{showElg ? "▲" : "▼"}</button></div>
                                        </div>

                                        <div>
                                            Do you have a Visa?
                                            <select className='form-select filterSelectBox'>
                                                <option>Select...</option>
                                                {
                                                    visaFilter.map((e) => {
                                                        return (
                                                            <option>{e.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            Countries
                                            <select value={search.country} name='country' onChange={(e) => { searchData(e); cityData((e.target.options[e.target.selectedIndex].getAttribute('data-id'))) }} className='form-select filterSelectBox'>
                                                <option>search by Countries</option>
                                                {countries.map((e) => {
                                                    return (
                                                        <option key={e.country_name} value={e.country_slug} data-id={e.id}>
                                                            {e.country_name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            States
                                            <select value={search.city} name='city' onChange={searchData} className='form-select filterSelectBox'>
                                                <option>search by City</option>
                                                {city.map((e) => {
                                                    return (
                                                        <option key={e.city_name} value={e.city_slug}>
                                                            {e.city_name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            Education Country
                                            <select className='form-select filterSelectBox'>
                                                <option>Select...</option>
                                                {
                                                    innerArray.map((e) => {
                                                        return (
                                                            <option>{e.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            Education Level
                                            <select
                                                value={eliFilterPro_1}
                                                onChange={(e) => setSeliFilterPro1(e.target.value)}
                                                className='form-select filterSelectBox'
                                            >
                                                <option value="">Select...</option>
                                                {educationLevel.map((item) => (
                                                    <optgroup key={item.label} label={item.label}>
                                                        {item.options.map((subItem) => (
                                                            <option key={subItem.key} value={subItem.label}>
                                                                {subItem.label}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </select>
                                        </div>

                                        <button onClick={() => fetchProgramsData(currentPage)} className='btn btn-primary'>Apply Filters</button>
                                    </div>

                                </>
                                :
                                <div className='filterBox_1 col'>
                                    <div className='eligibilityShowBox'>
                                        <div>Eligibility</div>
                                        <div><button onClick={() => setShowElg(!showElg)} className='eligibleBtn'>{showElg ? "▲" : "▼"}</button></div>
                                    </div>

                                </div>
                        }
                    </div>

                    {/* filter-1 end  */}

                    {/* filter-2 */}

                    <div>
                        {
                            showSchool ?
                                <>
                                    <div className='filterBox col '>
                                        <div className='schoolShowBox'>
                                            <div>Univeristy Filters</div>
                                            <div><button onClick={() => setShowSchool(!showSchool)} className='eligibleBtn'>{showSchool ? "▲" : "▼"}</button></div>
                                        </div>
                                        <div>
                                            Countries
                                            <select value={selectedCountry} onChange={e => setSelectedCountry(e.target.value)} className='form-select filterSelectBox'>

                                                <option value="">Select a country</option>
                                                {allcountryData.map(country => (
                                                    <option key={country.name} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            States
                                            <select value={selectedState} onChange={e => setSelectedState(e.target.value)} className='form-select filterSelectBox'>
                                                <option value="">Select a state</option>
                                                {states.map(state => (
                                                    <option key={state} value={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            Campus Cities
                                            <select value={selectedCity} onChange={e => setSelectedCity(e.target.value)} className='form-select filterSelectBox'>
                                                <option value="">Select a city</option>
                                                {cities.map(city => (
                                                    <option key={city} value={city}>
                                                        {city}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            Univeristy / Schools
                                            <select value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)} className='form-select filterSelectBox'>
                                                <option value="">Select a University/School</option>
                                                {campusData.map(school => (
                                                    <option key={school.name} value={school.name}>
                                                        {school}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <button onClick={() => fetchProgramsData(currentPage)} className='btn btn-primary'>Apply Filters</button>
                                    </div>
                                </>
                                :
                                <div className='filterBox_1 col'>
                                    <div className='schoolShowBox'>
                                        <div>Univeristy Filters</div>
                                        <div><button onClick={() => setShowSchool(!showSchool)} className='eligibleBtn'>{showSchool ? "▲" : "▼"}</button></div>
                                    </div>

                                </div>
                        }
                    </div>

                    {/* filter-2 end */}

                    {/* filter-3 */}

                    <div>
                        {
                            showProgram ?
                                <>
                                    <div className='filterBox col '>
                                        <div className='courseShowBox'>
                                            <div>Course Filters</div>
                                            <div><button onClick={() => setShowProgram(!showProgram)} className='eligibleBtn'>{showProgram ? "▲" : "▼"}</button></div>
                                        </div>
                                        <div>
                                            Education Level
                                            <select value={eliFilterPro_2} onChange={(e) => setSeliFilterPro2(e.target.value)} className='form-select filterSelectBox'>
                                                <option value="">Select...</option>
                                                {educationLevel.map((item) => (
                                                    <optgroup key={item.label} label={item.label}>
                                                        {item.options.map((subItem) => (
                                                            <option key={subItem.key} value={subItem.label}>
                                                                {subItem.label}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            Months/Years (Intakes)
                                            <select className='form-select filterSelectBox'>
                                                <option>Select...</option>
                                                {
                                                    intakes.map((e) => {
                                                        return (
                                                            <option>{e.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            Programs Status
                                            <select className='form-select filterSelectBox'>
                                                <option>Select...</option>
                                                {
                                                    ["Open", "Likely Open", "Will Open", "Waitlist"].map((e) => {
                                                        return (
                                                            <option>{e}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div>
                                            Post-Secondary Discipline
                                            <select value={courseBranch} onChange={(e) => setCourseBranch(e.target.value)} className='form-select filterSelectBox'>
                                                <option>Select...</option>
                                                {postScecondry.map((option) => (
                                                    <option key={option.value} value={option.label}>
                                                        {option[0]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            Post-Secondary Sub-Categories
                                            <select className='form-select filterSelectBox'>
                                                <option>Select...</option>
                                                {courseSubBranch.map((item) => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <button onClick={() => eligibilityBtn1(currentPage)} className='btn btn-primary'>Apply Filters</button>
                                    </div>
                                </>
                                :
                                <div className='filterBox_1 col'>
                                    <div className='courseShowBox'>
                                        <div>Course Filters</div>
                                        <div><button onClick={() => setShowProgram(!showProgram)} className='eligibleBtn'>{showProgram ? "▲" : "▼"}</button></div>
                                    </div>

                                </div>
                        }
                    </div>

                    {/* filter-3 end */}

                </div>

                {/* sort by Fee */}

                <div className='programBox col-10'>
                    {
                        filterbyCourse.length > 0 ?
                            <div className='programSortBy'>
                                <div>Programs</div>
                                <div>
                                    <select value={sort1} onChange={e => setSort1(e.target.value)} className='form-select filterSelectBox'>
                                        <option>Sort by Fee</option>
                                        <option>All Programs</option>
                                        {sortOptions.map((e) => {
                                            return (
                                                <option>{e.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            :
                            <div className='programSortBy'>
                                <div>Programs</div>
                                <div>
                                    <select value={sort} onChange={e => setSort(e.target.value)} className='form-select filterSelectBox'>
                                        <option>Sort by Fee</option>
                                        <option>All Programs</option>
                                        {sortOptions.map((e) => {
                                            return (
                                                <option>{e.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                    }

                    {/* sort by Fee end */}

                    {/* All Programs */}

                    {
                        sort ?
                            <>
                                {
                                    sort === "Sort by Fee" || sort === "All Programs" ?
                                        allPrograms.map((e) => {
                                            return (
                                                <div className='programBox_1'>
                                                    <span>{e.program_level_caption}</span>
                                                    <h2>{e.name}</h2>
                                                    <span>{e.school_name}</span>
                                                    <div className='programBox_2'>
                                                        <div className='programHeading'>
                                                            <div>{e.school_name}</div>
                                                            <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                        </div>
                                                        <div className='feeData'>
                                                            <div>
                                                                <h6>Gross tuition</h6>
                                                                {e.tuition}
                                                            </div>
                                                            <div>
                                                                <h6>Application fee</h6>
                                                                {e.fee}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='programDetails'>
                                                        <button className=' programDetailBtn'>Programs Details</button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        sort === "Tuition - Lowest First" ?
                                            tutionLowToHigh.map((e) => {
                                                return (
                                                    <div className='programBox_1'>
                                                        <span>{e.program_level_caption}</span>
                                                        <h2>{e.name}</h2>
                                                        <span>{e.school_name}</span>
                                                        <div className='programBox_2'>
                                                            <div className='programHeading'>
                                                                <div>{e.school_name}</div>
                                                                <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                            </div>
                                                            <div className='feeData'>
                                                                <div>
                                                                    <h6>Gross tuition</h6>
                                                                    {e.tuition}
                                                                </div>
                                                                <div>
                                                                    <h6>Application fee</h6>
                                                                    {e.fee}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='programDetails'>
                                                            <button className=' programDetailBtn'>Programs Details</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            sort === "Tuition - Highest First" || sort === "School Rank" ?
                                                tutionHighToLow.map((e) => {
                                                    return (
                                                        <div className='programBox_1'>
                                                            <span>{e.program_level_caption}</span>
                                                            <h2>{e.name}</h2>
                                                            <span>{e.school_name}</span>
                                                            <div className='programBox_2'>
                                                                <div className='programHeading'>
                                                                    <div>{e.school_name}</div>
                                                                    <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                </div>
                                                                <div className='feeData'>
                                                                    <div>
                                                                        <h6>Gross tuition</h6>
                                                                        {e.tuition}
                                                                    </div>
                                                                    <div>
                                                                        <h6>Application fee</h6>
                                                                        {e.fee}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='programDetails'>
                                                                <button className=' programDetailBtn'>Programs Details</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                sort === "Application Fee - Lowest First" || sort === "Relevance" ?
                                                    feeLowToHigh.map((e) => {
                                                        return (
                                                            <div className='programBox_1'>
                                                                <span>{e.program_level_caption}</span>
                                                                <h2>{e.name}</h2>
                                                                <span>{e.school_name}</span>
                                                                <div className='programBox_2'>
                                                                    <div className='programHeading'>
                                                                        <div>{e.school_name}</div>
                                                                        <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                    </div>
                                                                    <div className='feeData'>
                                                                        <div>
                                                                            <h6>Gross tuition</h6>
                                                                            {e.tuition}
                                                                        </div>
                                                                        <div>
                                                                            <h6>Application fee</h6>
                                                                            {e.fee}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='programDetails'>
                                                                    <button className=' programDetailBtn'>Programs Details</button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    sort === "Application Fee - Highest First" ?
                                                        feeHighToLow.map((e) => {
                                                            return (
                                                                <div className='programBox_1'>
                                                                    <span>{e.program_level_caption}</span>
                                                                    <h2>{e.name}</h2>
                                                                    <span>{e.school_name}</span>
                                                                    <div className='programBox_2'>
                                                                        <div className='programHeading'>
                                                                            <div>{e.school_name}</div>
                                                                            <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                        </div>
                                                                        <div className='feeData'>
                                                                            <div>
                                                                                <h6>Gross tuition</h6>
                                                                                {e.tuition}
                                                                            </div>
                                                                            <div>
                                                                                <h6>Application fee</h6>
                                                                                {e.fee}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='programDetails'>
                                                                        <button className=' programDetailBtn'>Programs Details</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                }
                            </>
                            :
                            <>
                                {filterbyCourse.length > 0 ?
                                    <>
                                        {
                                            sort1 ?
                                                <>
                                                    {
                                                        sort1 === "Sort by Fee" || sort1 === "All Programs" ?
                                                            allPrograms.map((e) => {
                                                                return (
                                                                    <div className='programBox_1'>
                                                                        <span>{e.program_level_caption}</span>
                                                                        <h2>{e.name}</h2>
                                                                        <span>{e.school_name}</span>
                                                                        <div className='programBox_2'>
                                                                            <div className='programHeading'>
                                                                                <div>{e.school_name}</div>
                                                                                <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                            </div>
                                                                            <div className='feeData'>
                                                                                <div>
                                                                                    <h6>Gross tuition</h6>
                                                                                    {e.tuition}
                                                                                </div>
                                                                                <div>
                                                                                    <h6>Application fee</h6>
                                                                                    {e.fee}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='programDetails'>
                                                                            <button className=' programDetailBtn'>Programs Details</button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            sort1 === "Tuition - Lowest First" ?
                                                                tutionLowToHigh.map((e) => {
                                                                    return (
                                                                        <div className='programBox_1'>
                                                                            <span>{e.program_level_caption}</span>
                                                                            <h2>{e.name}</h2>
                                                                            <span>{e.school_name}</span>
                                                                            <div className='programBox_2'>
                                                                                <div className='programHeading'>
                                                                                    <div>{e.school_name}</div>
                                                                                    <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                                </div>
                                                                                <div className='feeData'>
                                                                                    <div>
                                                                                        <h6>Gross tuition</h6>
                                                                                        {e.tuition}
                                                                                    </div>
                                                                                    <div>
                                                                                        <h6>Application fee</h6>
                                                                                        {e.fee}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='programDetails'>
                                                                                <button className=' programDetailBtn'>Programs Details</button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                sort1 === "Tuition - Highest First" || sort1 === "School Rank" ?
                                                                    tutionHighToLow.map((e) => {
                                                                        return (
                                                                            <div className='programBox_1'>
                                                                                <span>{e.program_level_caption}</span>
                                                                                <h2>{e.name}</h2>
                                                                                <span>{e.school_name}</span>
                                                                                <div className='programBox_2'>
                                                                                    <div className='programHeading'>
                                                                                        <div>{e.school_name}</div>
                                                                                        <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                                    </div>
                                                                                    <div className='feeData'>
                                                                                        <div>
                                                                                            <h6>Gross tuition</h6>
                                                                                            {e.tuition}
                                                                                        </div>
                                                                                        <div>
                                                                                            <h6>Application fee</h6>
                                                                                            {e.fee}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='programDetails'>
                                                                                    <button className=' programDetailBtn'>Programs Details</button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    sort1 === "Application Fee - Lowest First" || sort1 === "Relevance" ?
                                                                        feeLowToHigh.map((e) => {
                                                                            return (
                                                                                <div className='programBox_1'>
                                                                                    <span>{e.program_level_caption}</span>
                                                                                    <h2>{e.name}</h2>
                                                                                    <span>{e.school_name}</span>
                                                                                    <div className='programBox_2'>
                                                                                        <div className='programHeading'>
                                                                                            <div>{e.school_name}</div>
                                                                                            <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                                        </div>
                                                                                        <div className='feeData'>
                                                                                            <div>
                                                                                                <h6>Gross tuition</h6>
                                                                                                {e.tuition}
                                                                                            </div>
                                                                                            <div>
                                                                                                <h6>Application fee</h6>
                                                                                                {e.fee}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='programDetails'>
                                                                                        <button className=' programDetailBtn'>Programs Details</button>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        sort1 === "Application Fee - Highest First" ?
                                                                            feeHighToLow.map((e) => {
                                                                                return (
                                                                                    <div className='programBox_1'>
                                                                                        <span>{e.program_level_caption}</span>
                                                                                        <h2>{e.name}</h2>
                                                                                        <span>{e.school_name}</span>
                                                                                        <div className='programBox_2'>
                                                                                            <div className='programHeading'>
                                                                                                <div>{e.school_name}</div>
                                                                                                <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                                            </div>
                                                                                            <div className='feeData'>
                                                                                                <div>
                                                                                                    <h6>Gross tuition</h6>
                                                                                                    {e.tuition}
                                                                                                </div>
                                                                                                <div>
                                                                                                    <h6>Application fee</h6>
                                                                                                    {e.fee}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='programDetails'>
                                                                                            <button className=' programDetailBtn'>Programs Details</button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                    }
                                                </>
                                                :
                                                filterbyCourse.map((e) => {
                                                    return (
                                                        <div className='programBox_1'>
                                                            <span>{e.program_level_caption}</span>
                                                            <h2>{e.name}</h2>
                                                            <span>{e.school_name}</span>
                                                            <div className='programBox_2'>
                                                                <div className='programHeading'>
                                                                    <div>{e.school_name}</div>
                                                                    <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                                </div>
                                                                <div className='feeData'>
                                                                    <div>
                                                                        <h6>Gross tuition</h6>
                                                                        {e.tuition}
                                                                    </div>
                                                                    <div>
                                                                        <h6>Application fee</h6>
                                                                        {e.fee}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='programDetails'>
                                                                <button className=' programDetailBtn'>Programs Details</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </>
                                    :
                                    allPrograms.map((e) => {
                                        return (
                                            <div className='programBox_1'>
                                                <span>{e.program_level_caption}</span>
                                                <h2>{e.name}</h2>
                                                <span>{e.school_name}</span>
                                                <div className='programBox_2'>
                                                    <div className='programHeading'>
                                                        <div>{e.school_name}</div>
                                                        <div>{e.school_data.city}, {e.school_data.province}, {e.school_data.country}</div>
                                                    </div>
                                                    <div className='feeData'>
                                                        <div>
                                                            <h6>Gross tuition</h6>
                                                            {e.tuition}
                                                        </div>
                                                        <div>
                                                            <h6>Application fee</h6>
                                                            {e.fee}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='programDetails'>
                                                    <button className=' programDetailBtn'>Programs Details</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                    }


                    <Pagination className='d-flex justify-content-center mt-5 mb-5'>
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Prev
                        </Pagination.Prev>

                        <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </Pagination.Next>
                    </Pagination>
                </div>
            </div>
        </div>
    )
}

export default Programs

