import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../Constants";
import { MdAdminPanelSettings } from "react-icons/md";
import { MdNotifications } from "react-icons/md";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import Notification from "./components/Notification";
import "./Navbar.css";
import Ticker from "react-ticker";
import API from "../../Api/Api";

const Navbar = ({ news }) => {
  const [newN, setNewN] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const history = useNavigate();

  const ref = useRef(null);

  const getUserCalender = async () => {
    try {
      const response = await API(
        "get",
        `/calender/${localStorage.getItem("_id")}`
      );
      // console.log(response.data.data)
      let ticketstring = [];
      response.data.data?.map(itm => {
        if (itm?.title.includes('ceptance deadline due on ')){
          ticketstring.push(itm?.title)
        }
      })
      setNewN(ticketstring);
    } catch (error) {}
  };

  useEffect(() => {
    if (localStorage.getItem("_id")){
      getUserCalender();
    }
  }, []);


  const logout = async () => {
    // if (
    //   localStorage.getItem("role") === "Admin" ||
    //   localStorage.getItem("role") === "Counsellor"
    // ) {
    //   history("/admin");
    // } else {
    //   history("/login");
    // }
    history("/login");
    localStorage.removeItem("token");
    localStorage.clear();
  };

  const [name, setname] = useState("");

  const checkToken = async () => {
    const response = await fetch(`${url}/auth/verifyuser`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });

    const json = await response.json();
    setNotificationCount(json?.notificationCount);
    localStorage.setItem("role", json?.data?.role);
    localStorage.setItem("_id", json.data._id);
    localStorage.setItem("profile_picture", json.data.profile_picture);
    localStorage.setItem("profileComplete", json.profileComplete);
    setname(String(json.data.firstName + " " + json.data.lastName));
    localStorage.setItem("email", String(json.data.email));
    localStorage.setItem("phone", String(json.data.phone));
    localStorage.setItem("countryCode", String(json.data.countryCode));
    localStorage.setItem("isAffiliateUser", json?.AffiliateUser?.isAffiliateUser);
    localStorage.setItem("affiliate_id", json?.AffiliateUser?.id);
    localStorage.setItem("subscription_id", json?.activeSubscription?.subscriptionid);
  };

  React.useEffect(() => {
    checkToken();
  }, [trigger]);

  const closeNav = () => {
    // ref.current.click();
  };
  let role = localStorage.getItem("role");
  return (
    <header className="fixed-top fixed-top d-flex justify-content-between">
      <Link to={"/userdashboard"}>
        <img
          src={require("../Assets/Media/Images/logo.png")}
          alt="logo-rise"
          className="header-logo"
          title="Rise Higher Education"
        />
      </Link>
      {/* <Ticker>
        {({ index }) => (
          <>
            <h1 style={{ paddingRight: "0.5em" }}>
              This is the Headline of element #{index}!
            </h1>
          </>
        )}
      </Ticker> */}
      {/* Ticket String */}
      {/* <ul>{newN.map(itm => <li>{itm}</li>)}</ul> */}
      <ul className="header-navigation d-flex flex-row align-items-center">
        <li className="welcome-username">Welcome {name}</li>

        {(role == "Admin" || role == "Counsellor") && (
          <li>
            <MdAdminPanelSettings
              size={30}
              color="#DC7215"
              role="button"
              onClick={() => {
                window.location.href = "/dashboard";
              }}
            />
          </li>
        )}

        {role == "Admin" && (
          <li>
            <MdNotifications
              size={30}
              color="#DC7215"
              role="button"
              onClick={() => {
                handleShow();
              }}
            />
            {notificationCount > 0 && (
              <p className="notifications">{notificationCount}</p>
            )}
          </li>
        )}
        {/*
        <li>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </li>
            */}

        <li>
          <Link to="/profile" onClick={closeNav}>
            {localStorage?.getItem("profile_picture") &&
            localStorage?.getItem("profile_picture") !== "null" &&
            localStorage?.getItem("profile_picture") !== null ? (
              <img
                style={{ borderRadius: "50%" }}
                src={localStorage?.getItem("profile_picture")}
                alt="profile-icon"
                width={24}
                height={24}
              />
            ) : (
              <img
                src={require("../Assets/Media/Images/avatar.png")}
                alt="profile-icon"
                width={24}
              />
            )}
          </Link>
        </li>

        <li>
          <a href="" className="logout" onClick={logout}>
            <img
              src={require("../Assets/Media/Images/icon-logout.png")}
              title="Logout"
            />
          </a>
        </li>
      </ul>
      <Modal show={showModal} onHide={handleClose} className="modal-md">
        <Modal.Header closeButton>
          <Modal.Title>All Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Notification trigger={setTrigger} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Navbar;
