import React, { useState, useEffect } from 'react';

function ProfileComp() {
  const sectionCount = 2;

  // Define the fields and their initial completion status in each section
  const initialSectionFields = {
    personalInfo: {
      name: '',
      email: '',
    },
    address: {
      street: '',
      city: '',
      state: '',
    },
    // Add more sections and fields as needed
  };

  const [sectionFields, setSectionFields] = useState(initialSectionFields);

  // Function to calculate the overall completion percentage
  const calculateCompletionPercentage = () => {
    const completedSections = Object.values(sectionFields).filter((fields) =>
      Object.values(fields).every((value) => value !== '')
    ).length;
    return (completedSections / sectionCount) * 100;
  };

  const [completionPercentage, setCompletionPercentage] = useState(0);

  // Update the completion percentage whenever section fields change
  useEffect(() => {
    const percentage = calculateCompletionPercentage();
    setCompletionPercentage(percentage);
  }, [sectionFields]);

  // Event handler for field completion within a section
  const handleFieldChange = (sectionName, fieldName, value) => {
    setSectionFields((prevSectionFields) => ({
      ...prevSectionFields,
      [sectionName]: {
        ...prevSectionFields[sectionName],
        [fieldName]: value,
      },
    }));
  };

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel d-flex justify-content-between">
        <div>
          {/* Personal Info section */}
          <div>
            <input
              type="text"
              placeholder="Name"
              value={sectionFields.personalInfo.name}
              onChange={(e) => handleFieldChange('personalInfo', 'name', e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              value={sectionFields.personalInfo.email}
              onChange={(e) => handleFieldChange('personalInfo', 'email', e.target.value)}
            />
          </div>

          {/* Address section */}
          <div>
            <input
              type="text"
              placeholder="Street"
              value={sectionFields.address.street}
              onChange={(e) => handleFieldChange('address', 'street', e.target.value)}
            />
            <input
              type="text"
              placeholder="City"
              value={sectionFields.address.city}
              onChange={(e) => handleFieldChange('address', 'city', e.target.value)}
            />
            <input
              type="text"
              placeholder="State"
              value={sectionFields.address.state}
              onChange={(e) => handleFieldChange('address', 'state', e.target.value)}
            />
          </div>

          {/* Display the overall completion percentage */}
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${completionPercentage}%` }}
              aria-valuenow={completionPercentage}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {completionPercentage}% Complete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileComp;
