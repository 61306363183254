import React, { useEffect, useState } from "react";
import API from "../../Api/Api";

const Visa = () => {
  const [allVisaDocuments, setAllVisaDocuments] = useState([]);
  const [country, setCountry] = useState("");
  const [docs, setDocs] = useState([]);

  const getAllVisaList = async () => {
    try {
      const response = await API("get", "/visa");
      setAllVisaDocuments(response.data.data);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getAllVisaList();
  }, []);

  useEffect(() => {
    if (country) {
      const filtered = allVisaDocuments.filter(
        (visa) => visa.country === country
      );
      let arr = [];
      filtered[0].steps.map((item) => {
        if (item.step.includes(".")) {
          arr.find((st) => st.step == item.step[0]).moreData =
            arr.find((st) => st.step == item.step[0])?.moreData?.length > 0
              ? [
                  ...arr
                    .find((st) => st.step == item.step[0])
                    ?.moreData?.filter((fd) => fd != item),
                  item,
                ]
              : [item];
        } else {
          arr.push(item);
        }
      });
      //console.log(arr);
      setDocs(arr);
    } else {
      setDocs([]);
    }
  }, [country]);

  return (
    <div className="container" style={{ margin: "7rem 6rem", width: "100%" }}>
      <div>
        <label>Country </label>
        <select
          className="form-select"
          onChange={(e) => setCountry(e?.target?.value)}
        >
          <option value="">Select Country</option>
          {allVisaDocuments?.map((visa) => (
            <option value={visa.country}> {visa.country}</option>
          ))}
        </select>
      </div>
      <br />
      <div className="accordion mt-4" id="">
        {docs?.map((itm, index) => (
          <div key={index} className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#collapseOne" + index}
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                {itm?.step}. {itm?.name}
              </button>
            </h2>
            <div
              id={"collapseOne" + index}
              className="accordion-collapse collapse"
              // data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                {itm?.description}
                <br />
                {itm?.moreData && (
                  <div className="accordion mt-4" id="">
                    {itm?.moreData?.map((data, index) => {
                      return (
                        <div key={index} className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseOne" + index + 100}
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              {data?.step}. {data?.name}
                            </button>
                          </h2>{" "}
                          <div
                            id={"collapseOne" + index + 100}
                            className="accordion-collapse collapse"
                            // data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {data?.description}
                            </div>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div>
        <h1>Document list</h1>
        <br />
        <ol></ol>
      </div> */}
    </div>
  );
};

export default Visa;
