import React, { useEffect, useState } from 'react'
import API from '../../Api/Api'
import swal from 'sweetalert'
import Select from 'react-select'
import country_codes from '../Assets/Data/countries_code.json'
import { validateLeadForm } from '../../utils/validation';

export const LeadForm = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    countryCode: '',
    phone: '',
    message: '',
    hearaboutus: '',
    firstName: '',
    lastName: '',
  })
  const [loading, setLoading] = useState(false)
  
  const onChangeInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[e.target.name]; // Use 'e' instead of 'event'
      return newErrors;
    });
  };

  const [errors, setErrors] = useState({});

  const handleFormSubmit = async (e) => {
    e.preventDefault(); 
    const { isValid, errors } = validateLeadForm(data);

    //console.log('Validation Result:', { isValid, errors });
    
    if (!isValid) {
      setErrors(errors); // Set validation errors to state
      setLoading(false);
      return; // Exit the function if form is not valid
    }

    setLoading(true)
    try {
      await API('post', `/lead`, data)
      swal({
        title: 'Success!',
        text: `Thank you for reaching out. We will get back to you shortly!`,
        icon: 'success',
        button: 'Ok!',
      })
      setData({
        name: '',
        email: '',
        countryCode: '',
        phone: '',
        message: '',
        hearaboutus: '',
        firstName: '',
        lastName: '',
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      name: `${prevData.firstName} ${prevData.lastName}`.trim(),
    }));
    
  }, [data.firstName, data.lastName]);

  return (
    <div className="body-container">
      <div className="logo">
                <a href="/">
                <img
                  src="https://risehigher.education/logo.png"
                  className="img-fluid logo-sm m-4 ms-0"
                  alt="Rise Higher Education"
                  title="Rise Higher Education"
                />
                </a>
      </div>
      <div className="page-title">
        Please provide your details, and
        someone from team will reach out to you within 24 hours.
      </div>
      <div className="">
        <form onSubmit={handleFormSubmit}>
          <div className="form-floating mb-3">
            <input
              name="firstName"
              onChange={onChangeInput}
              className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
              type="text"
              value={data.firstName}
              placeholder="First Name"
            />
            <label for="floatingInput">
              First Name<span className="required">*</span>
            </label>
            {errors.firstName && (
              <span className="required">{errors.firstName}</span>
            )}
          </div>
          <div className="form-floating mb-3">
            <input
              name="lastName"
              onChange={onChangeInput}
              className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
              type="text"
              value={data.lastName}
              placeholder="Last Name"
            />
            <label for="floatingInput">
              Last Name<span className="required">*</span>
            </label>
            {errors.lastName && (
              <span className="required">{errors.lastName}</span>
            )}
          </div>
          <div className="form-floating mb-3">
            <input
              name="email"
              onChange={onChangeInput}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              type="email"
              value={data.email}
              placeholder="Email"
            />
            <label for="floatingInput">
              Email<span className="required">*</span>
            </label>
            {errors.email && (
              <span className="required">{errors.email}</span>
            )}
          </div>
          <div className="row">
            <div className="col-3 custom-formfloating mb-3">
              <label for="floatingInput" className="ms-2">
                Country Code<span className="required">*</span>
              </label>
              <Select
                className="app-multiSelect"
                value={data.country_codes}
                options={country_codes.map((op, i) => ({
                  value: op.dial_code,
                  label: op.dial_code + ' ' + op.name,
                }))}
                onChange={(selectedOptions) => {
                  //console.log('Select', selectedOptions)
                  setData((prevData) => ({
                    ...prevData,
                    country_codes: selectedOptions,
                  }))
                }}
                placeholder="Country Code"
                // isOptionDisabled={() => credentials?.dest?.length >= 3}
              />
              {errors.country_codes && (
                <span className="required">{errors.country_codes}</span>
              )}
            </div>
            <div className="col">
              <label for="floatingInput" className="ms-2">
                Phone Number<span className="required">*</span>
              </label>
              <input
                name="phone"
                onChange={(e) => {
                  if (String(e.target.value).length < 11) {
                    onChangeInput(e)
                  }
                }}
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                type="number"
                maxLength={10}
                value={data.phone}
                placeholder="Phone Number"
              />
              {errors.phone && (
                <span className="required">{errors.phone}</span>
              )}
            </div>
          </div>
          <div className="form-floating mb-3">
            <textarea
              value={data.message}
              name="message"
              onChange={onChangeInput}
              className={`form-control custom-textarea ${errors.message ? 'is-invalid' : ''}`}
              type="text"
              placeholder="Message"
            />
            <label for="floatingInput">
              Please let us know your requirements
              <span className="required">*</span>
            </label>
            {errors.message && (
                <span className="required">{errors.message}</span>
            )}
          </div>
          <div className="form-floating mb-3">
            <select
              className={`form-select ${errors.hearaboutus ? 'is-invalid' : ''}`}
              id="floatingSelect"
              name="hearaboutus"
              value={data.onChangeInput}
              onChange={onChangeInput}
            >
              <option selected>Please Select</option>
              <option value="Website">Website</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
              <option value="Alumuni Reference">Alumuni Reference</option>
              <option value="Youtube">Youtube</option>
              <option value="Other">Other</option>
            </select>
            <label for="floatingSelect">
              How did you hear about us?<span className="required">*</span>
            </label>
            {errors.hearaboutus && (
                <span className="required">{errors.hearaboutus}</span>
            )}
          </div>
          <div className="d-flex mb-4">
            {loading ? (
              <button
                className="btn btn-primary-regular"
                type="submit"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                value="Submit"
                className="btn btn-primary-regular"
              />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
