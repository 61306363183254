import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
// import
export const ViewFile = () => {
  const [searchParams] = useSearchParams();
  const [url, setURL] = useState("");

  const getPDF = async () => {
    try {
      const response = await fetch(searchParams.get("url"));
      const blob = await response.blob();
      var pdf = new Blob([blob], { type: "application/pdf" });
      const objectURL = URL.createObjectURL(pdf);
      setURL(objectURL);
    } catch (error) {}
  };

  useEffect(() => {
    if (searchParams.get("url")) {
      getPDF();
    }
  }, []);

  if (
    searchParams.get("url").endsWith("png") ||
    searchParams.get("url").endsWith("jpg") ||
    searchParams.get("url").endsWith("jpeg")
  ) {
    return (
      <div style={{ marginTop: "7rem", textAlign: "center" }}>
        <img src={searchParams.get("url")} alt="file-user" />{" "}
      </div>
    );
  }

  if (searchParams.get("url") || url) {
    return (
      <div style={{ marginTop: "7rem", textAlign: "center" }}>
        <iframe
          title="doc-viewer"
          id="pdfviewer"
          width="100%"
          height="900"
          style={{ visibility: "visible" }}
          src={url}
        ></iframe>
      </div>
    );
  }
  return (
    <h1 style={{ marginTop: "7rem", textAlign: "center" }}>
      Loading File, Please wait....
    </h1>
  );
};
