import React, { useEffect, useState } from "react";
import PersonalInfo from "./Forms/PersonalInfo";
import BackgroundInfo from "./Forms/BackgroundInfo";
import PassportInfo from "./Forms/PassportInfo";
import EmergencyContact from "./Forms/EmergencyContact";
import PastEdu from "./Forms/PastEdu";
import WorkExp from "./Forms/WorkExp";
import Test from "./Forms/Test";
import { url } from "../Constants";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import API from "../../Api/Api";

const ApplicationProfile = () => {
  const [data, setdata] = useState({});
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [application, setApplication] = useState(
    sessionStorage.getItem("applicationId")
  );
  const navigate = useNavigate();
  const [updateWorkExp, setUpdateWorkExp] = useState(false);

  const onChange = (e) => {
    setAdditionalDetails(e.target.value);
  };

  // const updateData = async (feild, data) => {
  //     const response = await fetch(`${url}/user/update`, {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Access-Control-Allow-Origin": "*",
  //             token: localStorage.getItem('token'),
  //         },

  //         body: JSON.stringify({
  //             feild, data
  //         }),
  //         mode: "cors",
  //         referrerPolicy: "origin-when-cross-origin",
  //     });

  //     const json = await response.json();

  //     if (json.success) {
  //         swal({
  //             title: "Success!",
  //             text: "Details Saved",
  //             icon: "success",
  //             button: "Ok",
  //         });
  //     }
  // }
  const updateData = async (feild, data, skip_work, action) => {
    const response = await API("post", `/user/update`, {
      feild,
      data,
      workDna: skip_work,
    });
    setUpdateWorkExp(true);
    window.location.reload();
    const json = response.data;
  };

  const getData = async () => {
    const response = await fetch(`${url}/user/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const json = await response.json();
    if (json?.data?.additionalDetails !== undefined) {
      setAdditionalDetails(json?.data?.additionalDetails);
    }

    setdata(json.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="body-content">
        <div className="settings-container user-profile">
          <h1>Application Profile</h1>
          {application !== undefined && application !== null
            ? `Application id : ${application}`
            : ""}
          <p className="mt-3">
            Complete Your Profile for Accurate Evaluation; Incomplete Profiles
            May Lead to Inaccurate Results Based on Assumptions.
          </p>
          <div className="row">
            <div className="col"></div>
          </div>
          <div className="d-flex align-items-start mt-3 application-profile">
            <div
              className="nav flex-column nav-pills me-3 application-profile"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Personal Information
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                Background Information
              </button>
              <button
                className="nav-link"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                Passport Information
              </button>
              <button
                className="nav-link"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                Emergency Contact Information
              </button>
              <button
                className="nav-link"
                id="v-pills-education-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-education"
                type="button"
                role="tab"
                aria-controls="v-pills-education"
                aria-selected="false"
              >
                Past Education
              </button>
              <button
                className="nav-link"
                id="v-pills-test-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-test"
                type="button"
                role="tab"
                aria-controls="v-pills-test"
                aria-selected="false"
              >
                Test Taken
              </button>
              <button
                className="nav-link"
                id="v-pills-work-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-work"
                type="button"
                role="tab"
                aria-controls="v-pills-work"
                aria-selected="false"
              >
                Work Experience
              </button>
              <button
                className="nav-link"
                id="v-pills-addition-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-addition"
                type="button"
                role="tab"
                aria-controls="v-pills-addition"
                aria-selected="false"
              >
                Additional Details
              </button>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
              <PersonalInfo updateData={updateData} data={data.userInfo} />
              <BackgroundInfo
                updateData={updateData}
                data={data.backgroundInfo}
              />
              <PassportInfo updateData={updateData} data={data.passport} />
              <EmergencyContact
                updateData={updateData}
                data={data.emergencyContact}
              />
              <PastEdu updateData={updateData} data={data.education} />
              <WorkExp
                updateData={updateData}
                data={data.workexp}
                updateWorkExp={updateWorkExp}
                workDna={data?.workDna}
              />
              <Test updateData={updateData} data={data.testTaken} />

              <div
                className="tab-pane fade"
                id="v-pills-addition"
                role="tabpanel"
                aria-labelledby="v-pills-addition-tab"
              >
                <div className="row profile-section">
                  <div className="col">
                    <div className="section-head">Additional Details</div>
                    <div className="section-content">
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3 mt-4">
                            <textarea
                              className="form-control"
                              placeholder="Additional Details"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              value={additionalDetails}
                              onChange={onChange}
                            />
                            <label htmlFor="floatingTextarea2">
                              Additional Details
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-end action-btn-group">
                          <button type="submit" className="btn btn-secondary">
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-regular"
                            onClick={() => {
                              updateData(
                                "additionalDetails",
                                additionalDetails
                              );
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {application !== undefined && application !== null ?  : ""} */}
              <button
                className="btn btn-primary-regular"
                onClick={() => {
                  navigate("/documents");
                }}
              >
                Upload Documents
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationProfile;
