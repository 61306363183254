import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import countryList from "../Assets/Data/countries.json";
import { url } from '../Constants';
import countryUni from "../Assets/Data/university.json";
import Item from './Item';
import swal from 'sweetalert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useNavigate } from 'react-router-dom'

const Loan = () => {

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        phone: "",
        studyDest: "",
        degree: "",
        pincode: "",
        intake: "",
        amount: 0,
        university: "",
        collateral: false,
        program:""
    })

    // console.log(countryList);

    const navigate = useNavigate();

    const [universityData, setUniversityData] = useState([]);
    const [loanData, setLoanData] = useState([]);

    const [showData, setShowData] = useState(false)
    const [view, setView] = useState("list");
    const [collateral, setCollateral] = useState(false);

    const onChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };


    const getData = async () => {
        const response = await fetch(`${url}/auth/verifyuser`, {
            method: 'POST',
            mode: 'cors',
            referrerPolicy: "origin-when-cross-origin",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
        })

        const res_json = await response.json();
        // console.log(res_json)
        const json = res_json.data;
        setData({ ...data, firstName: json.firstName, lastName: json.lastName, email: json.email, phone: json.phone, countryCode: json.countryCode || "", studyDest: json.studyDest[json?.studyDest?.length-1] })
    }

    const sendMail = async (id) => {

        swal({
            title: "Success",
            text: "Query Submitted",
            icon: "success",
            button: "Ok",
        });

        const response = await fetch(`${url}/mail/loan`, {
            method: 'POST',
            referrerPolicy: "origin-when-cross-origin",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            mode: 'cors',
            body: JSON.stringify({ data: data, id: id })
        })

        const json = await response.json();
    }

    const getLoanData = async () => {

        setCollateral(data.collateral);
        const collat = data.collateral === true ? "Yes" : 'No';

        const req = `${url}/loan?collateral_security=${collat}&amount=${data.amount}`;

        const response = await fetch(req, {
            method: 'GET',
            mode: 'cors',
            referrerPolicy: "origin-when-cross-origin",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
        })

        const json = await response.json();
        if (data.studyDest === "United States of America") {
            // const internationalData = json.data.filter((e) => e.type === 'International Lender')
            // console.log(json.data);

            setLoanData(json.data);
        } else if (data.studyDest === "India") {
            const indiaData = json.data.filter((e) => e.type !== 'International Lender')
            // console.log(indiaData)

            setLoanData(indiaData);
        }
        setLoanData(json.data);

    }

    const toggleGrid = () => {
        document.getElementById("list-header").style.display = "none";
        setView("grid");
    };

    const toggleList = () => {
        document.getElementById("list-header").style.display = "flex";
        setView("list");
    };


    useEffect(() => {

        $('button').on('click', function (e) {
            if ($(this).hasClass('grid')) {
                $('.grid-container ul').removeClass('list').addClass('grid');
                $('.list-header').hide();
                $(".list-text-label").show();
                $(".uni-logo").show();
                $(".uni-location").show();
            }
            else if ($(this).hasClass('list')) {
                $('.grid-container ul').removeClass('grid').addClass('list');
                $('.list-header').show();
                $(".list-text-label").hide();
                $(".uni-logo").hide();
                $(".uni-location").hide();
            }
        });

        getData();
    }, []);

    useEffect(() => {

        const setUniData = async () => {
            const getUniversitydata = await countryUni[data.studyDest] || []
            setUniversityData(getUniversitydata);
        }
        setUniData();

    }, [data.studyDest])

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 768) { // Adjust this breakpoint to your needs
            setView('grid');
          } else {
            setView('list');
          }
        };
    
        handleResize(); // Initial setup
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div className="body-content no-filterpanel">
            <div className="body-panel">
                <div className="body-container">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between mt-3">
                                <div className="page-content">
                                    <h1 className="mb-3">Student Loans</h1>
                                    <p>
                                        Find your ideal student loan effortlessly with our innovative portal, matching your profile to subsidized options and providing personalized support, making your educational dreams within reach.
                                    </p>
                                    <p>
                                        <a href="https://drive.google.com/file/d/12zGGSqsXIZvA-ptthpHlRDoLxf_vZhxw/view?usp=sharing" target="_blank" className="link-transcript">
                                            Knowledge Base
                                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Right now, our loan listings are Indian, but rest assured, our team is working tirelessly to negotiate with banks worldwide, bringing you the best rates on your educational journey!</Tooltip>}>
                                                <i className="fa fa-info-circle"></i>
                                            </OverlayTrigger>
                                        </a>
                                    </p>
                                    <p className="sub-section">
                                        <button type="button" className="link-btn" data-bs-toggle="modal" data-bs-target="#emiCalculatorModal">
                                            Free calculator
                                        </button>
                                        &nbsp;to evaluate student loans by estimating the interest cost, helping to understand the balance, and evaluating pay-off options.
                                        <div className="modal fade" id="emiCalculatorModal" tabIndex={-1} aria-labelledby="emiCalculatorModal" aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h1 className="modal-title fs-5" id="emiCalculatorModal">
                                                            EMI Calculator
                                                        </h1>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                    </div>
                                                    <div className="modal-body">
                                                        <iframe src="https://risehighereducation.com/wp-content/uploads/drive/loan-calculator/" name="myIframe" className="w-100 h-100 emiloanframe"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div className="video-container ms-5">
                                    <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                        <i className='fa fa-home'></i> Back to Dashboard
                                    </button>
                                    <iframe
                                        src="https://www.youtube.com/embed/Gp1BcIn97UY"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div>
                            </div>
                            <form>
                                <div className="row mb-3 responsive-col">
                                    <div className="col">
                                        <label htmlFor="">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="First name"
                                            aria-label="First name"
                                            disabled={true}
                                            value={data.firstName}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Last name"
                                            aria-label="Last name"
                                            disabled={true}
                                            value={data.lastName}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            aria-describedby=""
                                            placeholder="Email"
                                            disabled={true}
                                            value={data.email}
                                        />
                                    </div>
                                </div>
                                <div className="row responsive-col">
                                    <div className="col">
                                        <label htmlFor="phone">Phone Number</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="phone"
                                            aria-describedby=""
                                            placeholder="Phone"
                                            disabled={true}
                                            value={data.phone}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="email">Permanent Pincode:<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="pincode"
                                                aria-describedby=""
                                                placeholder="Pincode"
                                                minLength={5}
                                                maxLength={6}
                                                name='pincode'
                                                value={data.pincode}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row responsive-col">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="" className="form-label">
                                                Target Country:<span className="required">*</span>
                                            </label>
                                            <select
                                                list="data12"
                                                placeholder="choose.."
                                                className="form-select"
                                                name="studyDest"
                                                value={data?.studyDest}
                                                // defaultValue={data?.studyDest[0]}
                                                onChange={onChange}
                                            >
                                                <option value="">Please Select</option>
                                                {countryList.map((op, i) => (
                                                    <option>{op.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="inputState" className="form-label">
                                                Target Degree:<span className="required">*</span>
                                            </label>
                                            <select
                                                name="degree"
                                                value={data.degree}
                                                onChange={onChange}
                                                className="form-select"
                                                placeholder='Select Degree'
                                            >
                                                <option value="">Select Degree</option>
                                                <option value="UG Diploma/ Certificate/ Associate Degree">UG Diploma/ Certificate/ Associate Degree</option>
                                                <option value="UG">UG</option>
                                                <option value="PG Diploma/Certificate">PG Diploma/Certificate</option>
                                                <option value="PG">PG</option>
                                                <option value="UG+PG (Accelerated) Degree">UG+PG (Accelerated) Degree</option>
                                                <option value="PhD">PhD</option>
                                                <option value="Foundation">Foundation</option>
                                                <option value="Short Term Programs">Short Term Programs</option>
                                                <option value="Pathway Programs">Pathway Programs</option>
                                                <option value="Twinning Programmes (PG)">Twinning Programmes (PG)</option>
                                                <option value="English Language Program">English Language Program</option>
                                                <option value="Technical School/College">Technical School/College</option>
                                                <option value="Trade School/College">Trade School/College</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="email">Target Intake:<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="intake"
                                                aria-describedby=""
                                                placeholder="Intake"
                                                name='intake'
                                                value={data.intake}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="inputState" className="form-label">
                                                University:<span className="required">*</span>
                                            </label>
                                            <select
                                                name="university"
                                                list='universitylist'
                                                className="form-select"
                                                value={data.university}
                                                onChange={onChange}
                                                placeholder="Select University"
                                            >
                                                 <option value="">Please Select</option>
                                                {universityData !== undefined && universityData.map((getstate, index) => (
                                                    <option value={getstate} key={index}>
                                                        {getstate}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="inputState" className="form-label">
                                                Program:
                                            </label>
                                            <input
                                                name="program"
                                                className="form-control"
                                                value={data.program}
                                                onChange={onChange}
                                                placeholder="Program"
                                            >
                                            </input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 responsive-col">
                                    <div className="col">
                                        <div className="form-group rounded">
                                            <label for="customRange" className="form-label">Required Loan Amount:<span className="required me-2">*</span></label>
                                            <span className="regular-text text-20px">
                                                ₹{new Intl.NumberFormat('en-IN').format(data.amount)}
                                            </span>
                                            <input type="range" className="form-range mt-2" min="0" step="10000" max="10000000" name='amount' id="customRange2" value={data.amount} onChange={onChange} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="" className="form-label">
                                            Collateral:<span className="required">*</span>
                                        </label>
                                        <br />
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="options2"
                                            id="option3"
                                            autoComplete="off"
                                            checked={data.collateral === true}
                                            onClick={() => { setData({ ...data, collateral: true }) }}
                                        />
                                        <label className="btn btn-secondary me-3" htmlFor="option3">
                                            Secured
                                        </label>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="options2"
                                            id="option4"
                                            autoComplete="off"
                                            checked={data.collateral === false}
                                            onClick={() => { setData({ ...data, collateral: false }) }}
                                        />
                                        <label className="btn btn-secondary" htmlFor="option4">
                                            Unsecured
                                        </label>
                                    </div>
                                </div>
                                <div className="form-button">
                                    <button type="submit" className="btn btn-primary-regular" disabled={
                                        data.amount < 100 || data.pincode.length < 5 || data.intake.length < 5 || data.university.length < 1 || data.studyDest.length < 1 || data.degree.length < 1
                                    } onClick={async (e) => {
                                        e.preventDefault();
                                        await getLoanData();
                                        setShowData(true);
                                    }}>
                                        View Loan Options
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {showData && <div className="row">
                        <div className="col">
                            <div className="grid-container">
                                <div className="buttons grid-layout">
                                    <button className="grid" onClick={toggleGrid}>
                                        <i className="fa-solid fa-grip" />
                                    </button>
                                    <button className="list" onClick={toggleList}>
                                        <i className="fa-solid fa-list" />
                                    </button>
                                </div>
                                <ul className="list-detail list-header" id="list-header">
                                    <li className="list-width-15">Institution Name</li>
                                    <li className="list-width-10">Type</li>
                                    <li className="list-width-10">Co-applicant</li>
                                    <li className="list-width-10">ROI</li>
                                    <li className="list-width-10">Tenure</li>
                                    <li className="list-width-10">Complete Moratorium</li>
                                    <li className="list-width-15">Turn round Time</li>
                                    <li className="list-width-10">Collateral Security</li>
                                    {collateral && <li className="list-width-10">Loan Amount Limit</li>}
                                    {!collateral && <li className="list-width-10">Unsecured Limit Start</li>}
                                    {!collateral && <li className="list-width-10">Unsecured Limit End</li>}
                                    <li className="list-width-10">Conession of Women</li>
                                    <li className="list-width-10"></li>
                                </ul>
                                <ul className={view === 'list' ? 'list grid-list' : 'grid grid-list'}>
                                    {loanData.length < 1 ? "No items found" : ""}
                                    {loanData.map((element) => {
                                        return <Item sendMail={sendMail} mode={view} data={element} collateral={collateral} />
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <button onClick={()=>{navigate('/accommodation')}} className="widget-cta-page float-end mx-4">View Accomodation Options</button>
            <div className="footer-links pt-5">
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#disclaimerModal"
                >
                    Disclaimer
                </button>
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#TnCModal"
                >
                    Terms &amp; Conditions
                </button>
                <div
                    className="modal fade"
                    id="disclaimerModal"
                    tabIndex={-1}
                    aria-labelledby="disclaimerModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="disclaimerModal">
                                    Disclaimer
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>
                                    By accessing and using this loan portal, you acknowledge that you have read, understood, and agreed to the following terms and conditions:
                                </p>
                                <ol>
                                    <li>
                                        <strong>Information Accuracy:</strong> The loan portal and its partners strive to provide accurate and up-to-date information regarding loan products and services. However, the loan portal does not guarantee the accuracy, completeness, or reliability of the information provided. Users are advised to verify the details with the respective banks or financial institutions.
                                    </li>
                                    <li>
                                        <strong>Independent Decision:</strong> The decision to apply for a loan and the selection of a specific loan product or lender is solely the responsibility of the user. The loan portal and its partners do not endorse or recommend any particular loan product or lender. Users are advised to conduct their own research and seek professional advice before making any financial decisions.
                                    </li>
                                    <li>
                                        <strong>No Favoritism:</strong> The loan portal and its partners do not have any special influence or preferential treatment from banks or financial institutions mentioned on the portal. The loan portal is an independent platform aimed at providing information and facilitating connections between users and lenders. We cannot guarantee preferential treatment or obtain any special favors from lenders on behalf of users.
                                    </li>
                                    <li>
                                        <strong>User Responsibility:</strong> Users are responsible for providing accurate and complete information when applying for loans through the loan portal. It is the user's responsibility to ensure the authenticity and legality of the provided documents. The loan portal and its partners do not assume any liability for inaccuracies or omissions in user-submitted information.
                                    </li>
                                    <li>
                                        <strong>Third-Party Websites:</strong> The loan portal may contain links to third-party websites or resources. These links are provided for convenience, and the loan portal and its partners are not responsible for the content, policies, or practices of these external websites. Users should review the terms and conditions of such websites before engaging in any transactions.
                                    </li>
                                    <li>
                                        <strong>Disclaimer of Liability:</strong> The loan portal and its partners shall not be liable for any direct, indirect, consequential, or incidental damages arising out of or in connection with the use of the loan portal, including but not limited to, loss of data, income, or profits. Users assume all risks associated with their use of the loan portal.
                                    </li>
                                    <li>
                                        <strong>Modification of Terms:</strong> The loan portal reserves the right to modify or update these terms and conditions at any time without prior notice. Users are encouraged to review the terms periodically for any changes.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="TnCModal"
                    tabIndex={-1}
                    aria-labelledby="TnCModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="TnCModal">
                                    Terms &amp; Conditions
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>
                                    By accessing and using this loan portal, you acknowledge that you have read, understood, and agreed to the following terms and conditions:
                                </p>
                                <ol>
                                    <li>
                                        <strong>Eligibility:</strong> The loan portal is available to individuals who are of legal age and have the legal capacity to enter into a binding agreement. By accessing and using the loan portal, you represent that you meet these eligibility criteria.
                                    </li>
                                    <li>
                                        <strong>Information Accuracy:</strong> While we strive to provide accurate and up-to-date information on the loan portal, we cannot guarantee the accuracy, completeness, or reliability of the information provided. Users are advised to verify the details with the respective banks or financial institutions.
                                    </li>
                                    <li>
                                        <strong>Loan Offerings:</strong> The loan portal provides information about various loan products and services offered by banks and financial institutions. The loan portal does not endorse or recommend any particular loan product or lender. Users are responsible for evaluating the terms, conditions, and suitability of the loan offerings before making any financial decisions.
                                    </li>
                                    <li>
                                        <strong>Application Process:</strong> The loan portal facilitates the submission of loan applications to the respective banks or financial institutions. However, the loan portal does not guarantee loan approval or any specific loan terms. The decision to approve a loan application rests solely with the lending institution.
                                    </li>
                                    <li>
                                        <strong>Document Requirements:</strong> The loan portal provides general information about the documents typically required by banks or financial institutions for loan applications. However, specific document requirements may vary depending on the lending institution. Users are advised to contact the respective bank or financial institution for the most accurate and updated document requirements.
                                    </li>
                                    <li>
                                        <strong>Lender Discretion:</strong> The loan portal and its partners do not have control over the loan approval process, loan terms, or interest rates set by the banks or financial institutions. The final decision regarding loan approval, loan amount, interest rates, and other terms rests with the lending institution.
                                    </li>
                                    <li>
                                        <strong>Financial Advice:</strong> The loan portal and its partners do not provide financial advice or recommendations. The information provided on the loan portal is for informational purposes only and should not be construed as financial or investment advice. Users are advised to consult with a qualified financial advisor before making any financial decisions.
                                    </li>
                                    <li>
                                        <strong>Third-Party Services:</strong> The loan portal may include links or references to third-party services, products, or websites. These third-party services are independent of the loan portal, and the loan portal and its partners do not endorse or assume any responsibility for the content, accuracy, or privacy practices of these third-party services.
                                    </li>
                                    <li>
                                        <strong>Privacy and Data Security:</strong> The loan portal and its partners are committed to protecting the privacy and security of user information. However, the loan portal cannot guarantee the security of information transmitted over the internet. Users are advised to review the loan portal's Privacy Policy for more information on data collection, storage, and usage practices.
                                    </li>
                                    <li>
                                        <strong>Modification of Terms:</strong> The loan portal reserves the right to modify or update these terms and conditions at any time without prior notice. Users are encouraged to review the terms periodically for any changes.
                                    </li>
                                </ol>
                                <p>
                                    These terms and conditions may be subject to change without prior
                                    notice. It is your responsibility to review them periodically for
                                    any updates.
                                </p>
                                <p>
                                    If you have any questions or concerns regarding the Portal, please
                                    contact our support team at{" "}
                                    <a href="mailto:contact@risehighereducation.com">
                                        contact@risehighereducation.com
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loan
