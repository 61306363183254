import React, { useEffect, useState } from 'react'
import API from '../../Api/Api'
import swal from 'sweetalert'
import Select from 'react-select'
import countryCodes from '../Assets/Data/countries.json'
import country_codes from '../Assets/Data/countries_code.json'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const CreateLead = () => {
  const [data, setData] = useState({
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phone: '',
    message: '',
    hearaboutus: '',
  })
  const [params] = useSearchParams()
  const [loading, setLoading] = useState(false)

  const onChangeInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const navigation = useNavigate()
  const backButton = () => {
    navigation('/dashboard')
  }

  useEffect(() => {
    setData({
      ...data,
      name: `${data?.firstName} ${data?.lastName}`.replace(/\s+/g, ' '),
    })
  }, [data?.firstName, data?.lastName])

  const handleFormSubmit = async (e) => {
    setLoading(true)
    try {
      e.preventDefault()
      await API('post', `/lead-admin/`, data)
      swal({
        title: 'Success!',
        text: `Thank you for reaching out. We will get back to you shortly!`,
        icon: 'success',
        button: 'Ok!',
      })
      setData({
        name: '',
        email: '',
        countryCode: '',
        phone: '',
        message: '',
        hearaboutus: '',
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleEditFormSubmit = async (e) => {
    setLoading(true)
    try {
      e.preventDefault()
      await API('put', `/lead-admin/${params.get('id')}`, data)
      swal({
        title: 'Success!',
        text: `Lead Updated`,
        icon: 'success',
        button: 'Ok!',
      })
      getLeadData()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getLeadData = async () => {
    try {
      const response = await API(
        'get',
        `/lead-admin/single/${params.get('id')}`,
      )
      setData({
        ...response?.data?.data,
        country_codes: {
          value: response.data.data.countryCode,
          label: response.data.data?.countryCode,
        },
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (params.get('id')) {
      getLeadData()
    }
  }, [])

  return (
    <div className="container admin-createpage">
      <div className="main-heading">Create Lead</div>
      <div className="sep" />
      <div className="">
        <form
          onSubmit={params.get('id') ? handleEditFormSubmit : handleFormSubmit}
        >
          <div className="form-floating mb-3">
            <input
              name="firstName"
              onChange={onChangeInput}
              className="form-control mb-3"
              type="text"
              value={data.firstName}
              placeholder="First Name"
            />
            <label for="floatingInput">
              First Name<span className="required">*</span>
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              name="lastName"
              onChange={onChangeInput}
              className="form-control mb-3"
              type="text"
              value={data.lastName}
              placeholder="Last Name"
            />
            <label for="floatingInput">
              Last Name<span className="required">*</span>
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              name="email"
              onChange={onChangeInput}
              className="form-control mb-3"
              type="email"
              value={data.email}
              placeholder="Email"
            />
            <label for="floatingInput">Email</label>
          </div>
          <div className="row">
            <div className="col-3 custom-formfloating">
              <label for="floatingInput" className="ms-2">
                Country Code<span className="required">*</span>
              </label>
              <Select
                className="app-multiSelect"
                value={data.country_codes}
                options={country_codes.map((op, i) => ({
                  value: op.dial_code,
                  label: op.dial_code + ' ' + op.name,
                }))}
                onChange={(selectedOptions) => {
                  setData((prevData) => ({
                    ...prevData,
                    country_codes: selectedOptions,
                  }))
                }}
                placeholder="Country Code"
                // isOptionDisabled={() => credentials?.dest?.length >= 3}
              />
            </div>
            <div className="col">
              <label for="floatingInput" className="ms-2">
                Phone Number<span className="required">*</span>
              </label>
              <input
                name="phone"
                onChange={(e) => {
                  if (String(e.target.value).length < 11) {
                    onChangeInput(e)
                  }
                }}
                className="form-control mb-3"
                type="number"
                maxLength={10}
                value={data.phone}
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div className="form-floating mb-3">
            <textarea
              value={data.message}
              name="message"
              onChange={onChangeInput}
              className="form-control mb-3 custom-textarea"
              type="text"
              placeholder="Message"
            />
            <label for="floatingInput">
              Please let us know your requirements
            </label>
          </div>
          <div className="form-floating mb-3">
            <select
              className="form-select"
              id="floatingSelect"
              name="hearaboutus"
              value={data.hearaboutus}
              onChange={onChangeInput}
            >
              <option selected>Please Select</option>
              <option value="Website">Website</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
              <option value="Alumuni Reference">Alumuni Reference</option>
              <option value="Youtube">Youtube</option>
              <option value="Other">Other</option>
            </select>
            <label for="floatingSelect">How did you hear about us?</label>
          </div>
          <div className="d-flex">
            {loading ? (
              <button
                className="btn btn-primary-regular"
                type="submit"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                value="Submit"
                className="btn btn-primary-regular"
              />
            )}
            <button
              type="submit"
              onClick={backButton}
              className="btn btn-secondary-regular"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
