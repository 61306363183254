import React, { useEffect, useState } from "react";
import { url } from "../../Constants";
import swal from "sweetalert";
import { MdDelete } from "react-icons/md";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import API from "../../../Api/Api";
const TabNoti = ({ data, handleUpdate, read, handleDelete }) => {
  // const navigate = useNavigate()
  return (
    <>
    <p className="notification-text"
        onClick={() => {
          if (
            data?.title.includes("User Added") ||
            data?.title.includes("User Registered")
          ) {
            window.location.href = "/dashboard";
          }
          if (data?.title.includes("Added New File")) {
            window.location.href = `/dashboard/user/files/${data?.userid}`;
          }
        }}
      >
        {data?.desc}</p>
    <div className="d-flex align-items-center">
      <button
        className="btn"
        onClick={() => handleUpdate(data?._id, read)}
      >
        {read == "true" ? <i className="fa-regular fa-envelope"></i> : <i className="fa-solid fa-envelope-open"></i>}
      </button>
      <MdDelete
        size={21}
        color="#DC7215"
        role="button"
        onClick={() => handleDelete(data?._id)}
      />
    </div>
    </>
  );
};

const Notification = ({ trigger }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState(false);

  const handleUpdate = async (id, read) => {
    setLoading(true);
    try {
      const response = await fetch(`${url}/notifications`, {
        method: "PUT",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          id,
          read: read,
        }),
      });
      const json = await response.json();
      swal({
        title: "Success!",
        text: json?.data,
        icon: "success",
        button: "Ok!",
      });
      trigger((prev) => prev + 1);
      getAllNotification();
      // setQuery(!query);
    } catch (error) {
      //console.log(error);
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${url}/notifications/${id}`, {
        method: "DELETE",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      swal({
        title: "Success!",
        text: json?.data,
        icon: "success",
        button: "Ok!",
      });
      trigger((prev) => prev + 1);
      getAllNotification();
    } catch (error) {
      //console.log(error);
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
    } finally {
      setLoading(false);
    }
  };

  const getAllNotification = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${url}/notifications?read=${query}`, {
        method: "GET",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      setNotifications(json?.data);
    } catch (error) {
      //console.log(error);
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllNotification();
  }, [query]);

  const deleteSelected = async () => {
    try {
      selected?.map(async (itm) => {
        await API("delete", `notifications/${itm}`);
      });
      getAllNotification();
      trigger((prev) => prev + 1);
    } catch (error) {}
  };

  const deleteAll = async () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover notifications.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        try {
          notifications?.map(async (itm) => {
            await API("delete", `notifications/${itm?._id}`);
          });
          getAllNotification();
          trigger((prev) => prev + 1);
        } catch (error) {}
      }
    });
  };

  if (!loading)
    return (
      <>
        <div className="d-flex notification-btngroup">
          <button
            onClick={deleteSelected}
            disabled={selected?.length === 0}
            className="btn btn-primary-regular me-3"
          >
            Delete Selected
          </button>
          <button
            onClick={deleteAll}
            disabled={notifications?.length === 0}
            className="btn btn-primary-regular"
          >
            Delete All
          </button>
        </div>
        <div className="notification-grid-container text-start">
        <Tabs
          defaultActiveKey={!query ? "Latest" : "Old"}
          id="uncontrolled-tab-example"
          className=""
          fill
          onSelect={(e) => {
            setQuery(e == "Latest" ? false : true);
          }}
        >
          <Tab eventKey="Latest" title="Latest">
              {notifications?.map((itm) => (
                <div className="d-flex notification-item">
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      if (e.target.checked) {
                        setSelected([...selected, itm?._id]);
                      } else {
                        setSelected(
                          selected.filter((item) => item != itm?._id)
                        );
                      }
                    }}
                  />{" "}
                  <TabNoti
                    key={itm?._id}
                    data={itm}
                    handleUpdate={handleUpdate}
                    read={"true"}
                    handleDelete={handleDelete}
                  />
                </div>
              ))}
          </Tab>
          <Tab eventKey="Old" title="Previous">
              {notifications?.map((itm) => (
                <div className="d-flex notification-item">
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      if (e.target.checked) {
                        setSelected([...selected, itm?._id]);
                      } else {
                        setSelected(
                          selected.filter((item) => item != itm?._id)
                        );
                      }
                    }}
                  />{" "}
                  <TabNoti
                    key={itm?._id}
                    data={itm}
                    handleUpdate={handleUpdate}
                    read={"false"}
                    handleDelete={handleDelete}
                  />
                </div>
              ))}
            </Tab>
        </Tabs>
        </div>
      </>
    );
  return <div>Loading...</div>;
};

export default Notification;
