import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import API from "../../Api/Api";
import { useNavigate } from 'react-router-dom'

const PaymentCheck = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const enroll = async () => {
    try {
      await API("post", `/lms/student/enroll-in-course`, {
        studentid: localStorage.getItem("_id"),
        courseid: searchParams.get("courseid"),
        paymentid: searchParams.get("payment_intent"),
      });
      navigate('/courses-dashboard')
    } catch (error) {}
  };

  useEffect(() => {
    if (
      searchParams.get("payment_intent") &&
      searchParams.get("redirect_status") == "succeeded"
    ) {
      enroll();
    }
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100vw", marginTop: "7rem" }}
    >
      <h1>Please wait, redirecting ...</h1>
    </div>
  );
};

export default PaymentCheck;
