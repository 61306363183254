import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import "../Assets/Styles/test.css";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import countryCodes from "../Assets/Data/countries.json";
import courseData from "../Assets/Data/coursecounseling.json";
import country_code from "../Assets/Data/countries_code.json";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Nav, Pagination } from "react-bootstrap";

import testEnquire from "../Assets/Data/test_enquire.json";

import { url } from '../Constants';
import swal from 'sweetalert';

const groupById = (data) => {
  return data.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = [];
    }
    acc[item.id].push(item);
    return acc;
  }, {});
};


const testEnquireGrouped = groupById(testEnquire);

export const Assessment = () => {
  const [allTest, setAllTest] = useState([]);
  const [userTest, setUserTest] = useState([]);
  const [course, setCourse] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const [modal, setModal] = useState(false);
  const [selectedTestType, setSelectedTestType] = useState(null);
  const [selectedTests, setSelectedTests] = useState([]);

  const toggleExpand = (index) => {
    setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const [listParams, setListParams] = useState({
    msg: "",
  });

  const [data, setData] = useState({
    destination: [],
    subject: "",
    course: [],
    english_speaking: "",
    nationality: "",
    level: "",
    last_edu_country: "",
  });

  const [loading, setLoading] = useState({
    getAllTest: false,
    getUserTest: false,
  });

  const getAllTests = async (
    destination,
    subject,
    course,
    nationality,
    level,
    last_edu_country
  ) => {
    setLoading({ ...loading, getAllTest: true });
    try {
      const response = await API(
        "get",
        `/test/all?destination=${destination.join(
          ","
        )}&subject=${subject}&course=${course.join(
          ","
        )}&nationality=${nationality}&level=${level}&last_edu_country=${last_edu_country}&is_user=${true}`
      );
      setAllTest(response?.data?.data);
    } catch (error) {
      setAllTest([]);
    } finally {
      setLoading({ ...loading, getAllTest: false });
    }
  };

  const getAllUserAssesment = async () => {
    setLoading({ ...loading, getUserTest: true });
    try {
      const response = await API(
        "get",
        `/test/student/all/${localStorage.getItem("_id")}`
      );
      setUserTest(response?.data?.data);
    } catch (error) {
    } finally {
      setLoading({ ...loading, getUserTest: false });
    }
  };

  useEffect(() => {
    // getAllTests();p
    getAllUserAssesment();
  }, []);

  useEffect(() => {
    if (data?.subject?.length > 0) {
      setCourse(courseData[data.subject]);
    }
  }, [data.subject]);

  const handleEnquireClick = (testId) => {
    const tests = testEnquireGrouped[testId] || [];
    setSelectedTests(tests);
    setSelectedTestType(tests[0]?.type || null);
    setModal(true);
  };

  const hasEnquireData = (testId) => {
    return testEnquire.some((test) => test.id === testId);
  };  

  const renderTableRows = (test) => {
    if (!test) return null;
    return Object.entries(test).map(([key, value]) => {
      if (key === "id") return null; // Exclude the 'id' field
      const formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to title case and replace underscores

      if (Array.isArray(value)) {
        return (
          <tr key={key}>
            <td><strong>{formattedKey}</strong></td>
            <td>
              <ul>
                {value.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </td>
          </tr>
        );
      }
      return (
        <tr key={key}>
          <td><strong>{formattedKey}</strong></td>
          <td>{value}</td>
        </tr>
      );
    });
  };

  const createMarkup = (html) => {
    // Create a temporary DOM element to manipulate the HTML
    const div = document.createElement('div');
    div.innerHTML = html;

    // Select all anchor tags and set their target to _blank
    const links = div.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute('target', '_blank');
    }

    // Return the modified HTML
    return { __html: div.innerHTML };
  };

  const navigate = useNavigate();

  useEffect(() => {
    //console.log(data);
  }, [data]);

  const selectedTest = selectedTests.find(test => test.type === selectedTestType);

  const onChange = (event) => {
    setListParams({ ...listParams, [event.target.name]: event.target.value, });
};

const sendMsg = async () => {
  const response = await fetch(`${url}/user/files/msg`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      'token': localStorage.getItem('token'),
    },
    mode: 'cors',
    referrerPolicy: "origin-when-cross-origin",
    body: JSON.stringify({
      msg: `Test Details:<br>
        Type: ${selectedTest.type}<br>
        Cost: ${selectedTest.cost}<br>
        Discount: ${selectedTest.discount}<br>
        Demo Class: ${selectedTest.demo_class}<br>
        Classes: ${selectedTest.classes}<br>
        Duration: ${selectedTest.duration}<br>
        Teaching Hours: ${selectedTest.teaching_hours}<br>
        Mock Test: ${selectedTest.mock_test}<br>
        Batches Per Month: ${selectedTest.batches_per_month}<br>
        Specials: ${selectedTest.specials.join(", ")}`,
      from: "Assessment"
    })
  });

  const data = await response.json();

  if (data.success) {
    swal({
      title: "Success!",
      text: "Message Sent!",
      icon: "success",
      button: "Ok!",
    });
  } else {
    swal({
      title: "Error!",
      text: data.message,
      icon: "error",
      button: "Ok!",
    });
  }
};

  return (
    <>
      <div className="body-content no-filterpanel">
        <div className="body-panel d-flex justify-content-between">
          <div className="body-container width-100">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-between mt-3">
                  <div className="page-content">
                    <h1 className="mb-3">Coaching</h1>
                    <p>
                      RHE helps you in putting your best foot forward for all
                      preliminary and competitive examination for FREE.
                    </p>
                    {/*<a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        navigate("/userdashboard");
                      }}
                      className="widget-cta-page-nopos float-end mb-3"
                    >
                      <i className="fa fa-home"></i> Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="test-eligibility">
                  <div className="widget-hd widget-border">
                    Want to Check with test is required for you?
                  </div>
                  <div className="test-eligibility-form responsive-col">
                    <div className="row">
                      <div className="col">
                        <label for="">Select Nationality</label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setData((prevState) => ({
                              ...prevState,
                              nationality: e.target.value,
                            }));
                          }}
                        >
                          <option value={""}>Select Country</option>
                          {countryCodes?.map((itm) => (
                            <option id={itm?.name} value={itm?.name}>
                              {itm?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label for="">Last Study Destination<span className="required">*</span></label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setData((prevState) => ({
                              ...prevState,
                              last_edu_country: e.target.value,
                            }));
                          }}
                          value={data.last_edu_country}
                        >
                          <option value={""}>Select Country</option>
                          {country_code?.map((itm) => (
                            <option id={itm?.name} value={itm?.name}>
                              {itm?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label for="">Study Destination<span className="required">*</span></label>
                        <Select
                          className="app-multiSelect"
                          isMulti
                          value={data?.destination?.map((value) => ({
                            value,
                            label: value,
                          }))}
                          options={countryCodes.map((op, i) => ({
                            value: op.name,
                            label: op.name,
                          }))}
                          onChange={(selectedOptions) => {
                            const newData = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setData((prevData) => ({
                              ...prevData,
                              destination: newData,
                            }));
                          }}
                          placeholder="Study Destination"
                          isOptionDisabled={() =>
                            data?.destination?.length >= 3
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label for="">
                          Level<span className="required">*</span>
                        </label>
                        <select
                          className="form-select"
                          value={data?.level}
                          onChange={(e) => {
                            setData((prevState) => ({
                              ...prevState,
                              level: e.target.value,
                            }));
                          }}
                        >
                          <option value="">Please Select</option>
                          <option value="ug">UG</option>
                          <option value="pg">PG</option>
                        </select>{" "}
                      </div>
                      <div className="col">
                        <label for="">Subject</label>
                        <select
                          className="form-select"
                          name="subject"
                          value={data.subject}
                          onChange={(e) =>
                            setData((prevState) => ({
                              ...prevState,
                              subject: e.target.value,
                            }))
                          }
                          placeholder="Select Subject"
                        >
                          <option key={""}>Please Select</option>
                          {Object?.keys(courseData).map((idx) => (
                            <option key={idx}>{idx}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label for="">Course</label>
                        <Select
                          className="app-multiSelect"
                          isMulti
                          value={data?.course?.map((value) => ({
                            value,
                            label: value,
                          }))}
                          options={
                            course?.length === 0
                              ? [
                                {
                                  label: "Select Subject to get courses",
                                  value: "",
                                },
                              ]
                              : course?.map((op, i) => ({
                                value: op.name,
                                label: op.name,
                              }))
                          }
                          onChange={(selectedOptions) => {
                            const newData = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setData((prevData) => ({
                              ...prevData,
                              course: newData,
                            }));
                          }}
                          placeholder="Course of Interest"
                          isOptionDisabled={() => {
                            if (course?.length === 0) return 1;
                            else return data?.course?.length >= 5;
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-3">
                        <button
                          className="btn btn-primary-regular"
                          onClick={() =>
                            getAllTests(
                              data?.destination,
                              data?.subject,
                              data?.course,
                              data?.nationality,
                              data?.level,
                              data?.last_edu_country
                            )
                          }
                        >
                          {" "}
                          Show Relevant Exam
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {!loading?.getAllTest ? (
                  <div className="d-flex flex-wrap mt-4">
                    {allTest?.length > 0 ? (
                      allTest?.map((item, i) => (
                        <div className="test-card" key={i}>
                          <div>
                            {" "}
                            {/* <span className="test-chip">
                              {item?.type?.toUpperCase()}
                            </span> */}
                            <button
            className="btn btn-primary-regular float-end"
            onClick={() => handleEnquireClick(item._id)}
            style={{ display: hasEnquireData(item._id) ? "block" : "none" }}
          >
            Enquire Now
          </button>
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                isExpanded[i] ? item.description : item.description.slice(0, 2000) + '...'
                              )}
                            ></div>
                            {item?.description?.length > 1700 && (
                              <button className="btn-link float-end" onClick={() => toggleExpand(i)}>
                                {isExpanded[i] ? 'Read Less' : 'Read More'}
                              </button>
                            )}
                          </div>
                          {(localStorage.getItem("role") == "Admin" || localStorage.getItem("role") == "Counsellor") && (
                            <div className="d-flex">
                              <button className="btn btn-primary-regular">
                                <Link
                                  className="test-text-link"
                                  to={`/testing/assessment/${item?._id}`}
                                >
                                  Take Assessment
                                </Link>
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No test found.</p>
                    )}
                  </div>
                ) : (
                  <p>Loading Tests.....</p>
                )}
                {/* <br />
                <hr />
                <br />
                {!loading?.getUserTest ? (
                  <section>
                    <h1>All Attempeted Tests</h1>
                    <div
                      className="d-flex justify-center items-center"
                      style={{
                        gap: "2rem",
                        marginTop: "1rem",
                        flexWrap: "wrap",
                      }}
                    >
                      {userTest?.length > 0 ? (
                        userTest?.map((item, i) => (
                          <div className="test-card" key={i}>
                            <div>
                              {" "}
                              <h2>{item?.testid?.name}</h2>
                              <span className="test-chip">
                                Time: {item?.timetaken}
                              </span>
                              <p className="test-desc">Score: {item?.score}</p>
                              <p
                                className="test-desc"
                                style={{ fontWeight: "700" }}
                              >
                                Attempted On:{" "}
                                {moment(item?.createdAt).format("L LTS")}
                              </p>
                            </div>
                            <Link
                              className="btn btn-primary"
                              to={`/test-report/${item?._id}`}
                            >
                              View
                            </Link>
                          </div>
                        ))
                      ) : (
                        <h2>Zero Attempeted test found.</h2>
                      )}
                    </div>
                  </section>
                ) : (
                  <h1>Loading Tests.....</h1>
                )} */}
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/loans");
              }}
              className="widget-cta-page float-end mx-4"
            >
              Explore Loan Options
            </button>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          {selectedTests.length > 1 && (
            <div className="d-flex">
              {selectedTests.map((test) => (
                <button 
                  key={test.type} 
                  className="btn btn-link me-3" 
                  onClick={() => setSelectedTestType(test.type)}
                >
                  {test.type}
                </button>
              ))}
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>{renderTableRows(selectedTest)}</tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={sendMsg}>
            Enquire now
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
