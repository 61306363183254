import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const CalenderView = ({ events }) => {
  const [show, setShow] = useState({
    data: "",
    show: false,
  });
  const handleClick = (info) => {
    setShow({
      data: info.event.title,
      show: true,
    });
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={renderEventContent}
        eventClick={handleClick}
      />
      {show.show && (
        <>
          <hr className="hr-sep" />
          <div dangerouslySetInnerHTML={{ __html: show.data }}></div>
        </>
      )}
    </>
  );
};

function renderEventContent(eventInfo) {
  return (
    <div style={{ background: eventInfo?.color }}>
      <p style={{ whiteSpace: "pre-wrap", color: "#fff" }}>{}</p>
    </div>
  );
}

export default CalenderView;

// import React from "react";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import { Tooltip } from "bootstrap";

// let tooltipInstance = null;

// const CalenderView = ({ events }) => {
//   const handleMouseEnter = (info) => {
//     if (info.event.title) {
//       tooltipInstance = new Tooltip(info.el, {
//         title: info.event.title,
//         html: true,
//         placement: "top",
//         trigger: "hover",
//         container: "body"
//       });

//       tooltipInstance.show();
//     }
//   };

//   const handleMouseLeave = (info) => {
//     if (tooltipInstance) {
//       tooltipInstance.dispose();
//       tooltipInstance = null;
//     }
//   };
//   return (
//     <FullCalendar
//       plugins={[dayGridPlugin, interactionPlugin]}
//       initialView="dayGridMonth"
//       events={events}
//       eventContent={renderEventContent}
//       eventMouseEnter={handleMouseEnter}
//       eventMouseLeave={handleMouseLeave}
//     />
//   );
// };

// function renderEventContent(eventInfo) {
//   return (
//     <div style={{ background: eventInfo?.color }}>
//       {/* <OverlayTrigger
//         key="bottom"
//         placement="bottom"
//         overlay={<Tooltip id="tooltip">{eventInfo.event._def.title}</Tooltip>}
//       > */}
//         <p style={{ whiteSpace: "pre-wrap", color: "#fff" }}>{}</p>
//       {/* </OverlayTrigger> */}
//     </div>
//   );
// }

// export default CalenderView;
