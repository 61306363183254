import React, { useEffect, useState } from "react";
import { Navigator } from "./Navigator";
import API from "../../Api/Api";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Pagination } from "react-bootstrap";

export const Questions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [renderPage, setRenderPage] = useState([0, 1, 2, 3, 4]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [loading, setLoading] = useState({
    getAllQuestions: false,
  });
  const getAllQuestions = async () => {
    setLoading({ ...loading, getAllQuestions: true });
    try {
      const response = await API(
        "get",
        `/test/admin/questions?page=${currentPage}`
      );
      setAllQuestions(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
    } finally {
      setLoading({ ...loading, getAllQuestions: false });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllQuestions();
  }, [currentPage]);

  const DeleteQuestions = async (id) => {
    try {
      const response = await API("delete", `/test/admin/delete-question/${id}`);
      swal({
        title: "Deleted Successfully!",
        text: response?.data?.message,
        icon: "success",
      });
      getAllQuestions();
    } catch (error) {}
  };

  return (
    <div className="container">
      <Navigator />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">All Questions</h1>

          <div>
            <button className="btn btn-primary">
              <Link className="test-text-link" to={`/question/add`}>
                Add New Question
              </Link>
            </button>
          </div>
        </div>
        {
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">
                    Question
                  </th>
                  <th scope="col">Test</th>
                  <th scope="col">Type</th>
                  <th scope="col">Category</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allQuestions.map((item) => {
                  return (
                    <tr>
                      <td>
                        {" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: item?.title }}
                        ></span>
                      </td>
                      <td>{item?.questiontype?.join(", ")}</td>
                      <td>{item?.type}</td>
                      <td>{item?.category?.join(", ")}</td>
                      <td>{item?.marks}</td>
                      <td>
                        <Link
                          to={`/question/add?id=${item?._id}`}
                          className="btn btn-nostyle"
                        >
                          <i className="fa fa-pencil fa-lg"></i>
                        </Link>{" "}
                        <button
                          className="btn btn-nostyle"
                          onClick={() => {
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this imaginary file!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                DeleteQuestions(item?._id);
                              }
                            })
                          }}
                        >
                        <i className="fa fa-trash fa-lg"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
      <Pagination className="d-flex justify-content-end mb-5">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => {
            if (currentPage <= renderPage[0] + 2) {
              let arr = renderPage;
              if (arr.length >4){
                arr.pop();
              }
              
              if (arr[0] !== 0) {
                arr.unshift(arr[0] - 1);
              }
              setRenderPage(arr);
            }
            handlePageChange(currentPage - 1);
          }}
        />
        {renderPage.map((page) => (
          <Pagination.Item
            disabled={(page*10) >= totalPages}
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}

        <Pagination.Next
          disabled={(currentPage*10) >= totalPages}
          onClick={() => {
            if (currentPage + 1 >= renderPage[renderPage.length - 1]) {
              let arr = renderPage;
              arr.shift();

              setRenderPage([...arr, arr[arr.length - 1] + 1]);
            }
            handlePageChange(currentPage + 1);
          }}
        />
      </Pagination>
      </div>
    </div>
  );
};
