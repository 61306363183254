import { useAnimations, useFBX, useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import { useAudio } from "../../Context/AudioContext";
import * as THREE from "three";

const corresponding = {
    A: "viseme_PP",
    B: "viseme_kk",
    C: "viseme_I",
    D: "viseme_AA",
    E: "viseme_O",
    F: "viseme_U",
    G: "viseme_FF",
    H: "viseme_TH",
    X: "viseme_PP",
};

export function Avatar(props) {
    // audio and lip sync
    
    const { audio, isPlaying, script, lipSyncData } = useAudio();

    const [smoothMorphTarget, setSmoothMorphTarget] = useState(true);
    const [morphTargetSmoothing, setMorphTargetSmoothing] = useState(0.5);

    useFrame(() => {
        const currentAudioTime = audio.currentTime;
        if (audio.paused || audio.ended) {
            setAnimation("Idle");
            return;
        }

        Object.values(corresponding).forEach((value) => {
            if (!smoothMorphTarget) {
                nodes.Wolf3D_Head.morphTargetInfluences[
                    nodes.Wolf3D_Head.morphTargetDictionary[value]
                ] = 0;
                nodes.Wolf3D_Teeth.morphTargetInfluences[
                    nodes.Wolf3D_Teeth.morphTargetDictionary[value]
                ] = 0;
            } else {
                nodes.Wolf3D_Head.morphTargetInfluences[
                    nodes.Wolf3D_Head.morphTargetDictionary[value]
                ] = THREE.MathUtils.lerp(
                    nodes.Wolf3D_Head.morphTargetInfluences[
                    nodes.Wolf3D_Head.morphTargetDictionary[value]
                    ],
                    0,
                    morphTargetSmoothing
                );

                nodes.Wolf3D_Teeth.morphTargetInfluences[
                    nodes.Wolf3D_Teeth.morphTargetDictionary[value]
                ] = THREE.MathUtils.lerp(
                    nodes.Wolf3D_Teeth.morphTargetInfluences[
                    nodes.Wolf3D_Teeth.morphTargetDictionary[value]
                    ],
                    0,
                    morphTargetSmoothing
                );
            }
        });

        for (let i = 0; i < lipSyncData?.mouthCues?.length; i++) {
            const mouthCue = lipSyncData.mouthCues[i];
            if (
                currentAudioTime >= mouthCue.start &&
                currentAudioTime <= mouthCue.end
            ) {
                if (!smoothMorphTarget) {
                    nodes.Wolf3D_Head.morphTargetInfluences[
                        nodes.Wolf3D_Head.morphTargetDictionary[
                        corresponding[mouthCue.value]
                        ]
                    ] = 1;
                    nodes.Wolf3D_Teeth.morphTargetInfluences[
                        nodes.Wolf3D_Teeth.morphTargetDictionary[
                        corresponding[mouthCue.value]
                        ]
                    ] = 1;
                } else {
                    nodes.Wolf3D_Head.morphTargetInfluences[
                        nodes.Wolf3D_Head.morphTargetDictionary[
                        corresponding[mouthCue.value]
                        ]
                    ] = THREE.MathUtils.lerp(
                        nodes.Wolf3D_Head.morphTargetInfluences[
                        nodes.Wolf3D_Head.morphTargetDictionary[
                        corresponding[mouthCue.value]
                        ]
                        ],
                        1,
                        morphTargetSmoothing
                    );
                    nodes.Wolf3D_Teeth.morphTargetInfluences[
                        nodes.Wolf3D_Teeth.morphTargetDictionary[
                        corresponding[mouthCue.value]
                        ]
                    ] = THREE.MathUtils.lerp(
                        nodes.Wolf3D_Teeth.morphTargetInfluences[
                        nodes.Wolf3D_Teeth.morphTargetDictionary[
                        corresponding[mouthCue.value]
                        ]
                        ],
                        1,
                        morphTargetSmoothing
                    );
                }

                break;
            }
        }
    });

    useEffect(() => {
        nodes.Wolf3D_Head.morphTargetInfluences[
            nodes.Wolf3D_Head.morphTargetDictionary["viseme_pp"]
        ] = 1;
        nodes.Wolf3D_Teeth.morphTargetInfluences[
            nodes.Wolf3D_Teeth.morphTargetDictionary["viseme_pp"]
        ] = 1;
        if (isPlaying) {
            audio.play();
        } else {
            setAnimation("Greeting");
            audio.pause();
        }

    }, [isPlaying, script]);

    const { nodes, materials } = useGLTF("/models/final.glb");

    // animations
    const { animations: idleAnimation } = useFBX("/animations/Idle.fbx");

    idleAnimation[0].name = "Idle";

    const [animation, setAnimation] = useState("Idle");

    const group = useRef();
    const { actions } = useAnimations(
        [idleAnimation[0]],
        group
    );

    // useEffect(() => {

    //     if (actions !== undefined && actions[animation] !== undefined) {
    //         actions[animation].reset().fadeIn(0.5).play();
    //         return () => actions[animation].fadeOut(0.5);
    //     }
    // }, [animation]);


    return (
        <>
            <group {...props} dispose={null} ref={group}>
                <primitive object={nodes.Hips} />
                <skinnedMesh
                    geometry={nodes.Wolf3D_Body.geometry}
                    material={materials.Wolf3D_Body}
                    skeleton={nodes.Wolf3D_Body.skeleton}
                />
                <skinnedMesh
                    geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
                    material={materials.Wolf3D_Outfit_Bottom}
                    skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
                />
                <skinnedMesh
                    geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
                    material={materials.Wolf3D_Outfit_Footwear}
                    skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
                />
                <skinnedMesh
                    geometry={nodes.Wolf3D_Outfit_Top.geometry}
                    material={materials.Wolf3D_Outfit_Top}
                    skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
                />
                <skinnedMesh
                    geometry={nodes.Wolf3D_Hair.geometry}
                    material={materials.Wolf3D_Hair}
                    skeleton={nodes.Wolf3D_Hair.skeleton}
                />
                <skinnedMesh
                    name="EyeLeft"
                    geometry={nodes.EyeLeft.geometry}
                    material={materials.Wolf3D_Eye}
                    skeleton={nodes.EyeLeft.skeleton}
                    morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
                    morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
                />
                <skinnedMesh
                    name="EyeRight"
                    geometry={nodes.EyeRight.geometry}
                    material={materials.Wolf3D_Eye}
                    skeleton={nodes.EyeRight.skeleton}
                    morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
                    morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
                />
                <skinnedMesh
                    name="Wolf3D_Head"
                    geometry={nodes.Wolf3D_Head.geometry}
                    material={materials.Wolf3D_Skin}
                    skeleton={nodes.Wolf3D_Head.skeleton}
                    morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
                    morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
                />
                <skinnedMesh
                    name="Wolf3D_Teeth"
                    geometry={nodes.Wolf3D_Teeth.geometry}
                    material={materials.Wolf3D_Teeth}
                    skeleton={nodes.Wolf3D_Teeth.skeleton}
                    morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
                    morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
                />
            </group>
        </>
    );
}

useGLTF.preload("/models/final.glb");