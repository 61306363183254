import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Languages from "../Assets/Data/gttsLanguages.json";

const VoiceTranscript = ({
  hanldeChange,
  getGPTResponse,
  setMessage,
  pauseAudio,
  setLang,
  lang,
  setLanguage,
}) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [holding, setHolding] = useState(false);
  useEffect(() => {
    hanldeChange(transcript);
  }, [transcript]);

  useEffect(() => {
    if (!listening && transcript) {
      getGPTResponse();
    }
  }, [listening]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  return (
    <div>
      <div className="d-flex gap-2">
        {Languages?.map((item) => (
          <button
            className="btn btn-secondary"
            style={{ fontWeight: item.code === lang ? "bold" : "" }}
            key={item.code}
            value={item.code}
            onClick={(e) => {
              setLang(item.code);
              setLanguage(item.name);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <br />
      <p>Microphone: {listening ? "on" : "off"}</p>
      <div className="d-flex" style={{ gap: "1rem" }}>
        <button
          className="btn btn-primary"
          // onClick={}
          onMouseDown={() => {
            setHolding(true);
            SpeechRecognition.startListening();
          }}
          onMouseUp={() => {
            setHolding(false);
            SpeechRecognition.stopListening();
            // hanldeChange(transcript);
            // getGPTResponse();
          }}
        >
          {!listening && !holding ? "Speak" : "listening"}
        </button>
        {/* <select
          className="form-select"
          onChange={(e) => setLang(e.target.value) }
          value={lang}
        >
          {Languages?.map((item) => (
            <option key={item.code} value={item.code}>
              {item.name}
            </option>
          ))}
        </select> */}
        {/* <button
          className="btn btn-primary"
          onClick={() => {
            resetTranscript();
            setMessage([]);
            pauseAudio();
          }}
        >
          Restart
        </button> */}
      </div>

      {/* <p>{transcript}</p> */}
    </div>
  );
};
export default VoiceTranscript;
