import React, { useState, useEffect } from "react";
import Testimonial from "./Testimonial";
import { url } from "../Constants";

const TestimonialSlider = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paused, setPaused] = useState(false);  // Pause state for mouseover
  const [progress, setProgress] = useState(0);  // Progress state for the loading bar

  // Function to get a random index
  const getRandomIndex = (max, excludeIndex) => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * max);
    } while (randomIndex === excludeIndex); // Ensure it's not the same as the current index
    return randomIndex;
  };

  // Fetch testimonials from the backend
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${url}/testimonial/`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
          },
          mode: 'cors',
          referrerPolicy: "origin-when-cross-origin",
        });
        const data = await response.json();
        
        setTestimonials(data);
        
        if (data.length > 0) {
          setCurrentIndex(getRandomIndex(data.length, -1)); // Start with a random index
        }
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  // Timer effect to change the testimonial and reset progress
  useEffect(() => {
    if (paused || testimonials.length === 0) return; // Stop interval when paused or no testimonials

    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setProgress(0); // Reset progress bar
          setCurrentIndex((currentIndex) =>
            getRandomIndex(testimonials.length, currentIndex)
          );
          return 0;
        }
        return prevProgress + 1; // Increment progress (adjust this value to control speed)
      });
    }, 50); // The interval duration (adjust this value to control how fast the progress bar fills)

    return () => clearInterval(intervalId);
  }, [paused, testimonials, currentIndex]);

  // Mouse event handlers
  const handleMouseEnter = () => setPaused(true);
  const handleMouseLeave = () => setPaused(false);

  // Validate testimonials array and set default value
  const testimonial =
    Array.isArray(testimonials) && testimonials[currentIndex]
      ? testimonials[currentIndex]
      : {};

  return (
    <>
    
    <div
      className="testimonial-slider"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="loading-bar-container">
          <div className="loading-bar">
            <div
              className="loading-progress"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      <Testimonial testimonial={testimonial} />
      <div className="testimonial-controls">
        <button onClick={() => setCurrentIndex(getRandomIndex(testimonials.length, currentIndex))} className="testimonial-previous">
          <img src={require("../Assets/Media/Images/leftarrow_icon.png")} alt="Previous" />
        </button>
        <button onClick={() => setCurrentIndex(getRandomIndex(testimonials.length, currentIndex))} className="testimonial-next">
          <img src={require("../Assets/Media/Images/rightarrow_icon.png")} alt="Next" />
        </button>
      </div>
    </div>
    </>
  );
};

export default TestimonialSlider;