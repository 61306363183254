import React, { useEffect, useState } from 'react'

const Test = (props) => {

    const [data, setData] = useState({
        gre: {
            score: "",
            date: "",
            quantitative: "",
            verbal: "",
            analytic: "",
        },
        gmat: {
            score: "",
            date: "",
            quantitative: "",
            verbal: "",
            analytic: "",
            reasoning: "",
        },
        toefl: {
            score: "",
            date: "",
            read: "",
            listen: "",
            speak: "",
            write: "",
        },
        ielts: {
            yetToRecieve: "",
            waiver: "",
            resultDate: "",
            examDate: "",
            trf: "",
            date: "",
            score: "",
            english: "",
            read: "",
            listen: "",
            speak: "",
            write: "",
        },
        pte: {
            score: "",
            date: "",
            read: "",
            listen: "",
            speak: "",
            write: "",
        },
        det: {
            score: "",
            date: "",
        },
        sat: {
            score: "",
            date: "",
            readWrite: "",
            math: "",
            essay: "",
        },
        act: {
            score: "",
            date: "",
            math: "",
            read: "",
            science: "",
            english: "",
            write: "",
        },


    })

    const onChange = (e) => {
        const target = e.target.name.split(".");

        if (target.length === 2) {
            setData({
                ...data,
                [target[0]]: {
                    ...data[target[0]],
                    [target[1]]: e.target.value
                }
            })
        }
        else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }


    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setData(props.data)
        }
    }, [props.data])

    return (
        <div className="tab-pane fade" id="v-pills-test" role="tabpanel" aria-labelledby="v-pills-test-tab">
            <div className="row profile-section">
                <div className="col">
                <div className="section-head d-flex">
                    Test Taken &nbsp;&nbsp;&nbsp; &nbsp;
                    <span className="d-flex justify-content-center align-items-center">
                    {" "}
                    <input
                        type="checkbox"
                        checked={data?.dna}
                        onChange={(e) =>
                        setData({
                            ...data,
                            dna: e.target.checked,
                        })
                        }
                    />{" "}
                    &nbsp; Does not Apply
                    </span>
                </div>
                    {/* <div className="row">
                        <div className="col">
                            <a href="" className="edit-section edit-section-inline">
                                <i className="fa-solid fa-lg fa-pen-to-square" />
                            </a>
                            <ul className="profile-block profile-block-inline">
                                <li>ILETS</li>
                                <li>
                                    <svg width={14} height={20} viewBox="0 0 14 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.62636 11.5464L2.63636 19L6.72727 16.5455L10.8182 19L9.82818 11.5382M12.4545 6.72727C12.4545 9.89036 9.89036 12.4545 6.72727 12.4545C3.56419 12.4545 1 9.89036 1 6.72727C1 3.56419 3.56419 1 6.72727 1C9.89036 1 12.4545 3.56419 12.4545 6.72727Z"
                                            stroke="#98A2B3" strokeWidth="1.63636" strokeLinecap="round"
                                            strokeLinejoin="round" />
                                    </svg>
                                    8.0
                                </li>
                            </ul>
                            <a href="" className="edit-section edit-section-inline">
                                <i className="fa-solid fa-lg fa-pen-to-square" />
                            </a>
                            <ul className="profile-block profile-block-inline">
                                <li>GRE</li>
                                <li>
                                    <svg width={14} height={20} viewBox="0 0 14 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.62636 11.5464L2.63636 19L6.72727 16.5455L10.8182 19L9.82818 11.5382M12.4545 6.72727C12.4545 9.89036 9.89036 12.4545 6.72727 12.4545C3.56419 12.4545 1 9.89036 1 6.72727C1 3.56419 3.56419 1 6.72727 1C9.89036 1 12.4545 3.56419 12.4545 6.72727Z"
                                            stroke="#98A2B3" strokeWidth="1.63636" strokeLinecap="round"
                                            strokeLinejoin="round" />
                                    </svg>
                                    354
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="form-group edit-form section-content">
                        <div className="row">
                            <div className="col">
                                <div className="accordion accordion-flush" id="accordionFlushExample2">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingFive">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFive" aria-expanded="false"
                                                aria-controls="flush-collapseFive">
                                                GRE
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFive" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingFive"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gre.score" value={data.gre?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gre.date" value={data.gre?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gre.quantitative" value={data.gre?.quantitative}  />
                                                            <label htmlFor="">Quantitative</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gre.verbal" value={data.gre?.verbal}  />
                                                            <label htmlFor="">Verbal</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gre.analytic" value={data.gre?.analytic}  />
                                                            <label htmlFor="">Analytical Writing</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingSix">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseSix" aria-expanded="false"
                                                aria-controls="flush-collapseSix">
                                                GMAT
                                            </button>
                                        </h2>
                                        <div id="flush-collapseSix" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingSix"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gmat.score" value={data.gmat?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gmat.date" value={data.gmat?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gmat.quantitative" value={data.gmat?.quantitative}  />
                                                            <label htmlFor="">Quantitative</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gmat.verbal" value={data.gmat?.verbal}  />
                                                            <label htmlFor="">Verbal</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gmat.analytic" value={data.gmat?.analytic}  />
                                                            <label htmlFor="">Analytical Writing</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="gmat.reasoning" value={data.gmat?.reasoning}  />
                                                            <label htmlFor="">Integrated Reasoning</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingSeven">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseSeven" aria-expanded="false"
                                                aria-controls="flush-collapseSeven">
                                                TOEFL
                                            </button>
                                        </h2>
                                        <div id="flush-collapseSeven"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingSeven"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="toefl.score" value={data.toefl?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="toefl.date" value={data.toefl?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="toefl.read" value={data.toefl?.read}  />
                                                            <label htmlFor="">Reading</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="toefl.listen" value={data.toefl?.listen}  />
                                                            <label htmlFor="">Listening</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="toefl.speak" value={data.toefl?.speak}  />
                                                            <label htmlFor="">Speaking</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="toefl.write" value={data.toefl?.write}  />
                                                            <label htmlFor="">Writing</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingEight">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseEight" aria-expanded="false"
                                                aria-controls="flush-collapseEight">
                                                IELTS
                                            </button>
                                        </h2>
                                        <div id="flush-collapseEight"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingEight"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <label htmlFor="">
                                                            Yet to Recieve
                                                            <span className="required">*</span>
                                                        </label>
                                                        <br />
                                                        <input onChange={onChange} type="radio" className="btn-check"
                                                            name="ielts.yetToRecieve" id="option51" checked={data.ielts?.yetToRecieve === 'yes'} onClick={() => { setData({ ...data, ielts: { ...data.ielts, yetToRecieve: "yes" } }) }} autoComplete="off"
                                                            defaultChecked="" />
                                                        <label className="btn btn-secondary"
                                                            htmlFor="option51">
                                                            Yes
                                                        </label>
                                                        <input onChange={onChange} type="radio" className="btn-check"
                                                            name="" id="option52" checked={data.ielts?.yetToRecieve === 'no'} onClick={() => { setData({ ...data, ielts: { ...data.ielts, yetToRecieve: "no" } }) }}
                                                            autoComplete="off" />
                                                        <label className="btn btn-secondary"
                                                            htmlFor="option52">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.resultDate" value={data.ielts?.resultDate}  />
                                                            <label htmlFor="">Test Result Date</label>
                                                        </div>
                                                    </div>
                                                    {data.ielts?.yetToRecieve === "yes" ? <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.trf" value={data.ielts.trf}  />
                                                            <label htmlFor="">TRF No.</label>
                                                        </div>
                                                    </div> : ""}
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <label htmlFor="">
                                                            IELTS Waiver
                                                            <span className="required">*</span>
                                                        </label>
                                                        <br />
                                                        <input onChange={onChange} type="radio" className="btn-check"
                                                            name="" id="option53" autoComplete="off" checked={data.ielts?.waiver === 'yes'} onClick={() => { setData({ ...data, ielts: { ...data.ielts, waiver: "yes" } }) }}
                                                            defaultChecked="" />
                                                        <label className="btn btn-secondary"
                                                            htmlFor="option53">
                                                            Yes
                                                        </label>
                                                        <input onChange={onChange} type="radio" className="btn-check"
                                                            name="" id="option54" checked={data.ielts?.waiver === 'no'} onClick={() => { setData({ ...data, ielts: { ...data.ielts, waiver: "no" } }) }}
                                                            autoComplete="off" />
                                                        <label className="btn btn-secondary"
                                                            htmlFor="option54">
                                                            No
                                                        </label>
                                                    </div>
                                                    {data.ielts?.waiver === "yes" ? <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.english" value={data.ielts?.english}  />
                                                            <label htmlFor="">
                                                                Enter English 12th Grade Marks
                                                            </label>
                                                        </div>
                                                    </div> : ""}
                                                    {data.ielts?.waiver === "yes" ? <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.examDate" value={data.ielts?.examDate}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div> : ""}
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.score" value={data.ielts?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.date" value={data.ielts?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.listen" value={data.ielts?.listen}  />
                                                            <label htmlFor="">Listening</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.read" value={data.ielts?.read}  />
                                                            <label htmlFor="">Reading</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.write" value={data.ielts?.write}  />
                                                            <label htmlFor="">Writing</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="ielts.speak" value={data.ielts?.speak}  />
                                                            <label htmlFor="">Speaking</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingNine">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseNine" aria-expanded="false"
                                                aria-controls="flush-collapseNine">
                                                PTE
                                            </button>
                                        </h2>
                                        <div id="flush-collapseNine" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingNine"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="pte.score" value={data.pte?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="pte.date" value={data.pte?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="pte.listen" value={data.pte?.listen}  />
                                                            <label htmlFor="">Listening</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="pte.read" value={data.pte?.read}  />
                                                            <label htmlFor="">Reading</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="pte.write" value={data.pte?.write}  />
                                                            <label htmlFor="">Writing</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="pte.speak" value={data.pte?.speak}  />
                                                            <label htmlFor="">Speaking</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTen">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTen" aria-expanded="false"
                                                aria-controls="flush-collapseTen">
                                                DET
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTen" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTen"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="det.score" value={data.det?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="det.date" value={data.det?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingEleven">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseEleven" aria-expanded="false"
                                                aria-controls="flush-collapseEleven">
                                                SAT
                                            </button>
                                        </h2>
                                        <div id="flush-collapseEleven"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingEleven"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="sat.score" value={data.sat?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="sat.date" value={data.sat?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="sat.readWrite" value={data.sat?.readWrite}  />
                                                            <label htmlFor="">
                                                                Reading &amp; Writing
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="sat.math" value={data.sat?.math}  />
                                                            <label htmlFor="">Math</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="sat.essay" value={data.sat?.essay}  />
                                                            <label htmlFor="">Essay</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwelve">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTwelve" aria-expanded="false"
                                                aria-controls="flush-collapseTwelve">
                                                ACT
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwelve"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwelve"
                                            data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.score" value={data.act?.score}  />
                                                            <label htmlFor="">Overall Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="date" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.date" value={data.act?.date}  />
                                                            <label htmlFor="">Date of Examination</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.math" value={data.act?.math}  />
                                                            <label htmlFor="">Math</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.read" value={data.act?.read}  />
                                                            <label htmlFor="">Reading</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.science" value={data.act?.science}  />
                                                            <label htmlFor="">Science</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.english" value={data.act?.english}  />
                                                            <label htmlFor="">English</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input onChange={onChange} type="text" className="form-control"
                                                                defaultValue=""
                                                                placeholder=""
                                                                aria-label="" name="act.write" value={data.act?.write}  />
                                                            <label htmlFor="">Writing</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row responsive-col">
                            <div className="col text-end action-btn-group">
                                <button type="submit" className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary-regular" onClick={() => { props.updateData("testTaken", data) }}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Test