import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import NavBarAdmin from "./Nav/Nav";
import { OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";

const DashboardAffiliate = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.substring(1);

  const pathStyle = (url) => {
    if (path == url) {
      return {
        background: path == url && "#534173",
        border: path == url && "#534173",
      };
    }
    return {};
  };

  const getAffUsers = async () => {
    setLoading(true);
    try {
      const response = await API(
        "get",
        `/affiliate/admin/user/all?page=${currentPage}`
      );
      // console.log(response?.data?.data);
      setData(response?.data?.data);
      setTotalPages(response?.data?.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAffUsers();
  }, [currentPage]);

  const sortData = (arr, property, reverse) => {
    let sorted = arr.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    if (reverse) {
      sorted.reverse();
    }
    setLoading(true);
    setData(sorted);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const handleDelete = async (id) => {
    try {
      const response = await API("delete", `/affiliate/admin/delete/${id}`);
      swal({
        title: "Success!",
        text: "Deleted Successfully!",
        icon: "success",
        button: "Ok!",
      });
      getAffUsers();
    } catch (error) {
    } finally {
    }
  };

  return (
    <div className="container">
      <NavBarAdmin key={"nav1"} />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">Affiliate Users</h1>
          {localStorage?.getItem("role") === "Admin" && (
            <button
              onClick={() => {
                navigate("/dashboard-referrals");
              }}
              type="button"
              className="btn btn-primary-regular mb-3"
              style={pathStyle("dashboard-referrals")}
            >
              View Referals
            </button>
          )}
        </div>

        {loading ? (
          <p>Please wait loading your referrals...</p>
        ) : (
          <div className="table-responsive grid-frame">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Id</th>
                  <th scope="col">
                    Name{" "}
                    <i
                      className="fa-solid fa-caret-up"
                      onClick={() => sortData(data, "fullName")}
                    ></i>{" "}
                    <i
                      className="fa-solid fa-caret-down"
                      onClick={() => sortData(data, "fullName", true)}
                    ></i>
                  </th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Message</th>
                  <th scope="col">Referral Code</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((e, id) => (
                    <tr key={id}>
                      <th scope="row">{id + 1}</th>
                      <td>{e?.fullName}</td>
                      <td>
                        <a href={`mailto:${e?.email}`}>{e?.email}</a>
                      </td>
                      <td>
                      {e?.countryCode} {e.mobile}
                      <br />
                      <a href={`tel:${e?.countryCode}${e.mobile}`}><i className="fa fa-phone"></i></a> | <a href={`https://wa.me/${e?.countryCode}${e.mobile}`}><i className="fa fa-whatsapp"></i></a>
                      </td>
                      <td>{e?.message}</td>
                      <td>
                        {e?.referral_id ? (
                          <a
                            href={`/dashboard-referrals?refferal_id=${e?.referral_id}`}
                          >
                            {e?.referral_id}
                          </a>
                        ) : (
                          "Not Generated"
                        )}
                      </td>
                      <td>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover this data!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((res) => {
                                if (res) {
                                  // console.log(res)
                                  handleDelete(e?._id);
                                }
                              });
                              // handleDelete(e?._id)
                            }}
                            className="btn deleteScholarshipBtn"
                          >
                            <MdDelete className="MdDelete" /> Delete
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="required p-2">No Data Found</p>
                )}
              </tbody>
            </table>
          </div>
        )}

        <Pagination className="d-flex justify-content-end mb-5">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DashboardAffiliate;
