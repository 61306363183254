import React, { useState, useEffect } from "react";
import { Navigator } from "../Navigator";
import API from "../../../Api/Api";
import swal from "sweetalert";
import { useNavigate, useSearchParams } from "react-router-dom";
import Editor from "../Editor/Editor";
import { MdDelete, MdEdit } from "react-icons/md";
import ReactPlayer from "react-player";

const AddLesson = () => {
  const [data, setData] = useState({
    name: "",
    type: "video",
    content: "",
    chapterid: "",
    duration: "",
    transcript: "",
  });

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [allChapters, setAllChapters] = useState([]);
  const [allLessons, setAllLessons] = useState([]);

  function secondsToMinutesSeconds(seconds) {
    // Calculate minutes and remaining seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format the result
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    try {
      if (data.edit) {
        const response = await API(
          "put",
          `lms/admin/update-lesson/${data?._id}`,
          {
            ...data,
            courseid: searchParams.get("courseid"),
          }
        );
      } else {
        const response = await API("post", `/lms/admin/create-lesson/`, {
          ...data,
          courseid: searchParams.get("courseid"),
        });
      }

      getLessons();
      swal({
        title: "Success!",
        text: "Lesson modified Successfully",
        icon: "success",
      });
      setData({
        name: "",
        type: "video",
        content: "",
        transcript: "",
        duration: "",
      });
    } catch (error) {}
  };

  const getChapters = async () => {
    try {
      const response = await API(
        "get",
        `/lms/admin/all-chapters/${searchParams.get("courseid")}`
      );
      setData({
        name: "",
        type: "video",
        content: "",
        transcript: "",
        duration: "",
      });
      setAllChapters(response?.data?.data);
    } catch (error) {}
  };

  const getLessons = async () => {
    try {
      const response = await API(
        "get",
        `/lms/admin/all-lessons/${searchParams.get("courseid")}`
      );
      setAllLessons(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getChapters();
    getLessons();
  }, []);

  const deleteLesson = async (id) => {
    try {
      const response = await API("delete", `/lms/admin/delete-lesson/${id}`);
      getLessons();
    } catch (error) {}
  };

  return (
    <>
    <div className="container">
      <Navigator />
      <div className="container">
      <ReactPlayer
        url={data?.content}
        onDuration={(e) =>
          setData({ ...data, duration: secondsToMinutesSeconds(e) })
        }
        height={"0px"}
        width={"0px"}
      />
      <h1 className="page-title">{data?.edit ? "Edit" : "Add"} Lesson</h1>
        <div className="d-flex lesson-container">
          <div className="width-50 me-4">
            <form
              onSubmit={handleSubmit}
            >
                <div className="flex-column">
                  <div>
                    <label>Lesson Title</label>
                    <input
                      className="form-control"
                      placeholder="Lesson Name"
                      value={data.name}
                      onChange={handleChange}
                      name="name"
                    />
                  </div>
                  <div>
                    <label>Chapter</label>
                    <select
                      className="form-select"
                      value={data.chapterid}
                      onChange={handleChange}
                      name="chapterid"
                    >
                      <option value={""}>Select Chapter</option>
                      {allChapters?.map((itm) => (
                        <option key={itm?._id} value={itm?._id}>
                          {itm?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Lesson type</label>
                    <select
                      className="form-select"
                      value={data.ispaid}
                      onChange={handleChange}
                      name="type"
                    >
                      <option value={"video"}>Video</option>
                      {/* <option value={"text"}>Text</option>
                      <option value={"audio"}>Audio</option> */}
                    </select>
                  </div>
                  {data.type == "text" && (
                    <div>
                      <label>Lesson Content</label>
                      <Editor data={data} setData={setData} name="content" />
                    </div>
                  )}
                  {data.type == "video" && (
                    <div>
                      <label>Lesson Video URL</label>
                      <input
                        className="form-control"
                        placeholder="Video Link"
                        value={data.content}
                        onChange={handleChange}
                        name="content"
                      />
                    </div>
                  )}
                  {data.type == "audio" && (
                    <div>
                      <label>Lesson Audio URL</label>
                      <input
                        className="form-control"
                        placeholder="Audio Link"
                        value={data.content}
                        onChange={handleChange}
                        name="content"
                      />
                    </div>
                  )}
                  <div>
                    <label>Duration</label>
                    <input
                      className="form-control"
                      placeholder="00:00"
                      value={data.duration}
                      onChange={handleChange}
                      name="content"
                    />
                  </div>
                  <div>
                    <label>Lesson Transcript</label>
                    <Editor data={data} setData={setData} name="transcript" />
                  </div>
                  <button type="submit" className="btn btn-primary-regular mt-3">
                    Submit
                  </button>
              </div>
            </form>
          </div>
          <div className="table-responsive grid-frame width-50 ms-4 mt-4">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  {" "}
                  <th scope="col">Name</th> <th scope="col">Chapter Name</th>{" "}
                  <th scope="col">Chapter Duration</th>{" "}
                  <th scope="col">Type</th> <th scope="col">Action</th>{" "}
                </tr>
              </thead>{" "}
              <tbody>
                {allLessons?.map((itm) => {
                  return (
                    <tr key={itm?._id}>
                      <td>{itm?.name}</td>
                      <td>{itm?.chapterid?.name}</td>
                      <td>{itm?.duration}</td>
                      <td>{itm?.type}</td>
                      <td>
                        <MdEdit
                          onClick={() => {
                            setData({
                              name: itm?.name,
                              type: "video",
                              content: itm?.content,
                              chapterid: itm?.chapterid?._id,
                              transcript: itm?.transcript,
                              duration: itm?.duration,
                              edit: true,
                              _id: itm?._id,
                            });
                          }}
                        />{" "}
                        &nbsp;
                        <MdDelete
                          onClick={() => {
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this lesson!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((res) => {
                              if (res) {
                                deleteLesson(itm?._id);
                              }
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default AddLesson;
