var countriesData = [
  {
    Country: "United Kingdom",
    "Employment Rate": 5.2,
    "University Rankings": 12.22,
    "Tuition Fees": 8.63,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 5.42,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 6.72,
    "Cultural Fit": 5.41,
    "Safety and Security": 4.65,
    "Quality of Life": 4.65,
    "Data_Employment_Rate": "84.3",
    "Data_University_Rankings": "11",
    "Data_Tuition_Fees": "35,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "45,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "15,000",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Business and Management Studies",
      "Law",
      "Medicine and Healthcare"
    ]
  },
  {
    Country: "United States of America",
    "Employment Rate": 5.1,
    "University Rankings": 37.78,
    "Tuition Fees": 9.3,
    "PR and Settlement Opportunities": 6.06,
    "Graduate Pay Scales": 6.63,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 7.58,
    "Cultural Fit": 5.41,
    "Safety and Security": 3.49,
    "Quality of Life": 4.65,
    "Data_Employment_Rate": "82.7",
    "Data_University_Rankings": "34",
    "Data_Tuition_Fees": "37,750",
    "Data_PR_and_Settlement_Opportunities": "4",
    "Data_Graduate_Pay_Scales": "55,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "17,500",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "3",
    "Data_Quality_of_Life": "3",
    "Top_Programs": [
      "Computer Science and Information Technology",
      "Business Administration (MBA)",
      "Engineering"
    ]
  },
  {
    Country: "Canada",
    "Employment Rate": 4.43,
    "University Rankings": 4.44,
    "Tuition Fees": 6.65,
    "PR and Settlement Opportunities": 7.58,
    "Graduate Pay Scales": 6.02,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 4.55,
    "Cultural Fit": 6.76,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "71.9",
    "Data_University_Rankings": "4",
    "Data_Tuition_Fees": "27,000",
    "Data_PR_and_Settlement_Opportunities": "5",
    "Data_Graduate_Pay_Scales": "50,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "10,500",
    "Data_Cultural_Fit": "5",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Computer Science and Information Technology",
      "Engineering",
      "Nursing and Healthcare"
    ]
  },
  {
    Country: "Australia",
    "Employment Rate": 5.45,
    "University Rankings": 7.78,
    "Tuition Fees": 7.89,
    "PR and Settlement Opportunities": 7.58,
    "Graduate Pay Scales": 6.27,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 6.72,
    "Cultural Fit": 6.76,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "88.3",
    "Data_University_Rankings": "7",
    "Data_Tuition_Fees": "32,000",
    "Data_PR_and_Settlement_Opportunities": "5",
    "Data_Graduate_Pay_Scales": "52,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "15,500",
    "Data_Cultural_Fit": "5",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Engineering",
      "Business and Management",
      "Health Sciences"
    ]
  },
  {
    Country: "New Zealand",
    "Employment Rate": 5.11,
    "University Rankings": 1.11,
    "Tuition Fees": 7.39,
    "PR and Settlement Opportunities": 7.58,
    "Graduate Pay Scales": 5.78,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 6.72,
    "Cultural Fit": 6.76,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "82,8",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "30,000",
    "Data_PR_and_Settlement_Opportunities": "5",
    "Data_Graduate_Pay_Scales": "48,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "15,500",
    "Data_Cultural_Fit": "5",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Agriculture and Environmental Science",
      "Hospitality and Tourism Management",
      "Information Technology"
    ]
  },
  {
    Country: "Ireland",
    "Employment Rate": 5.05,
    "University Rankings": 1.11,
    "Tuition Fees": 6.16,
    "PR and Settlement Opportunities": 7.58,
    "Graduate Pay Scales": 4.82,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 5.2,
    "Cultural Fit": 6.76,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "81.9",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "25,000",
    "Data_PR_and_Settlement_Opportunities": "5",
    "Data_Graduate_Pay_Scales": "40,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "12,000",
    "Data_Cultural_Fit": "5",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Computer Science and Information Technology",
      "Business and Finance",
      "Medicine and Health Sciences"
    ]
  },
  {
    Country: "France",
    "Employment Rate": 4.85,
    "University Rankings": 4.44,
    "Tuition Fees": 2.96,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 4.22,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 4.77,
    "Cultural Fit": 4.05,
    "Safety and Security": 3.49,
    "Quality of Life": 3.49,
    "Data_Employment_Rate": "78.6",
    "Data_University_Rankings": "4",
    "Data_Tuition_Fees": "12,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "35,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "11,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "3",
    "Data_Quality_of_Life": "3",
    "Top_Programs": [
      "Culinary Arts and Hospitality Management",
      "Fashion and Luxury Management",
      "Aeronautics and Aerospace Engineering"
    ]
  },
  {
    Country: "Germany",
    "Employment Rate": 5.48,
    "University Rankings": 6.67,
    "Tuition Fees": 2.71,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 3.61,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 3.47,
    "Cultural Fit": 4.05,
    "Safety and Security": 4.65,
    "Quality of Life": 4.65,
    "Data_Employment_Rate": "88.8",
    "Data_University_Rankings": "6",
    "Data_Tuition_Fees": "11,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "30,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "8,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Engineering and Technology",
      "Business Administration (MBA)",
      "Medicine and Healthcare"
    ]
  },
  {
    Country: "United Arab Emirates",
    "Employment Rate": 4.99,
    "University Rankings": 1.11,
    "Tuition Fees": 7.39,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 6.63,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 6.5,
    "Cultural Fit": 4.05,
    "Safety and Security": 5.81,
    "Quality of Life": 4.65,
    "Data_Employment_Rate": "80.9",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "30,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "55,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "15,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Business and Management",
      "Aviation and Aerospace Engineering",
      "Hospitality and Tourism Management"
    ]
  },
  {
    Country: "Singapore",
    "Employment Rate": 5.79,
    "University Rankings": 2.22,
    "Tuition Fees": 8.63,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 7.23,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 5.42,
    "Cultural Fit": 4.05,
    "Safety and Security": 5.81,
    "Quality of Life": 4.65,
    "Data_Employment_Rate": "93.8",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "35,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "60,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "12,500",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Engineering and Technology",
      "Business and Finance",
      "Biomedical Sciences"
    ]
  },
  {
    Country: "Finland",
    "Employment Rate": 5.18,
    "University Rankings": 2.22,
    "Tuition Fees": 2.71,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 4.82,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 4.55,
    "Cultural Fit": 5.41,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "83.9",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "11,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "40,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "10,500",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Environmental Sciences and Sustainability",
      "Education",
      "Design and Creative Arts"
    ]
  },
  {
    Country: "Sweden",
    "Employment Rate": 5.43,
    "University Rankings": 2.22,
    "Tuition Fees": 2.96,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 5.42,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 4.77,
    "Cultural Fit": 5.41,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "88.1",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "12,,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "45,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "11,000",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Computer Science and Software Engineering",
      "Social Sciences and Sustainability",
      "Medicine and Public Health"
    ]
  },
  {
    Country: "Denmark",
    "Employment Rate": 5.25,
    "University Rankings": 2.22,
    "Tuition Fees": 3.2,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 6.02,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 3.9,
    "Cultural Fit": 5.41,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "85.1",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "13,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "50,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "9,000",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Design and Architecture",
      "Renewable Energy and Environmental Engineering",
      "Social Sciences and Economics"
    ]
  },
  {
    Country: "Italy",
    "Employment Rate": 4.02,
    "University Rankings": 3.33,
    "Tuition Fees": 2.71,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 3.61,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 4.33,
    "Cultural Fit": 4.05,
    "Safety and Security": 3.49,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "65.2",
    "Data_University_Rankings": "3",
    "Data_Tuition_Fees": "11,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "30,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "10,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "3",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Art and Design",
      "Culinary Arts and Gastronomy",
      "Architecture and Urban Planning"
    ]
  },
  {
    Country: "India",
    "Employment Rate": 3,
    "University Rankings": 0,
    "Tuition Fees": 2,
    "PR and Settlement Opportunities": 3,
    "Graduate Pay Scales": 2,
    "Spouse Work Rights": 4,
    "Cost of Living": 3,
    "Cultural Fit": 4,
    "Safety and Security": 3,
    "Quality of Life": 3,
    "Data_Employment_Rate": "45",
    "Data_University_Rankings": "0",
    "Data_Tuition_Fees": "10,000",
    "Data_PR_and_Settlement_Opportunities": "2",
    "Data_Graduate_Pay_Scales": "15,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "6,750",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "3",
    "Data_Quality_of_Life": "3",
    "Top_Programs": [
      "Information Technology and Computer Science",
      "Business and Management",
      "Medicine and Healthcare"
    ]
  },
  {
    Country: "Spain",
    "Employment Rate": 4.88,
    "University Rankings": 2.22,
    "Tuition Fees": 2.71,
    "PR and Settlement Opportunities": 3.03,
    "Graduate Pay Scales": 3.61,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 4.33,
    "Cultural Fit": 4.05,
    "Safety and Security": 3.49,
    "Quality of Life": 3.49,
    "Data_Employment_Rate": "79.1",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "11,000",
    "Data_PR_and_Settlement_Opportunities": "2",
    "Data_Graduate_Pay_Scales": "30,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "10,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "3",
    "Data_Quality_of_Life": "3",
    "Top_Programs": [
      "Tourism and Hospitality Management",
      "Language and Culture Studies",
      "Renewable Energy and Sustainability"
    ]
  },
  {
    Country: "Hungary",
    "Employment Rate": 4.51,
    "University Rankings": 1.11,
    "Tuition Fees": 2.46,
    "PR and Settlement Opportunities": 3.03,
    "Graduate Pay Scales": 3.01,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 2.17,
    "Cultural Fit": 4.05,
    "Safety and Security": 4.65,
    "Quality of Life": 3.49,
    "Data_Employment_Rate": "73.1",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "10,000",
    "Data_PR_and_Settlement_Opportunities": "2",
    "Data_Graduate_Pay_Scales": "25,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "5,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "3",
    "Top_Programs": [
      "Medical and Health Sciences",
      "Engineering and Technology",
      "Business Administration and Economics"
    ]
  },
  {
    Country: "Netherlands",
    "Employment Rate": 5.73,
    "University Rankings": 3.33,
    "Tuition Fees": 2.96,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 4.82,
    "Spouse Work Rights": 6.41,
    "Cost of Living": 4.77,
    "Cultural Fit": 5.41,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "92.9",
    "Data_University_Rankings": "3",
    "Data_Tuition_Fees": "12,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "40,000",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "11,000",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Engineering and Technology",
      "International Business and Economics",
      "Social Sciences and Political Science"
    ]
  },
  {
    Country: "Switzerland",
    "Employment Rate": 5.76,
    "University Rankings": 3.33,
    "Tuition Fees": 7.39,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 6.63,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 6.72,
    "Cultural Fit": 4.05,
    "Safety and Security": 5.81,
    "Quality of Life": 5.81,
    "Data_Employment_Rate": "93.4",
    "Data_University_Rankings": "3",
    "Data_Tuition_Fees": "30,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "55,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "15,500",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Hospitality and Hotel Management",
      "Finance and Banking",
      "International Relations and Diplomacy"
    ]
  },
  {
    Country: "Cyprus",
    "Employment Rate": 5.02,
    "University Rankings": 1.11,
    "Tuition Fees": 2.71,
    "PR and Settlement Opportunities": 4.55,
    "Graduate Pay Scales": 3.61,
    "Spouse Work Rights": 3.85,
    "Cost of Living": 3.9,
    "Cultural Fit": 4.05,
    "Safety and Security": 4.65,
    "Quality of Life": 4.65,
    "Data_Employment_Rate": "81.4",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "11,000",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "30,000",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "9,000",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Marine Sciences and Oceanography",
      "Hospitality and Tourism Management",
      "Digital Marketing and E-commerce"
    ]
  },
];

export { countriesData };
