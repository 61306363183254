import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Contextprovider from "./Components/Admin/Contextprovider";
import "./Components/Assets/Styles/responsive.css";
import {SpeedInsights} from "@vercel/speed-insights/react";

import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  "ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdEBiWXxYdHVRQWdV"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Contextprovider>
    <SpeedInsights />
    <App />
  </Contextprovider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
