import swal from "sweetalert";
import subscriptions from "../Components/Assets/Data/subscription.json";

let basicRoutes = [
  "/p-",
  "/login",
  "/signup",
  "/mentor",
  "/affiliate",
  "/reset-password",
  "/loans",
  "/lead",
  "/userdashboard",
  "/refill-credits",
  "/subscription-buy",
  "/subscription/payment-check",
];

function extractMongoIDs(input) {
  const mongoIDRegex = /\b[0-9a-fA-F]{24}\b/g;
  const matches = input.match(mongoIDRegex);
  return matches || [];
}

const subsNavCheck = (url, minutes) => {
  if (!localStorage.getItem("token")) {
    return true;
  }

  if (url.includes("http://localhost")) {
    url = url.split("http://localhost:3000")[1];
  } else {
    url = url.split("https://risehigher.education/")[1];
  }

  if (extractMongoIDs(url).length > 0) {
    url = url.split(extractMongoIDs(url)[0])[0];
  }


  if (
    localStorage.getItem("role")?.toLowerCase() == "admin" ||
    localStorage.getItem("role")?.toLowerCase() == "counsellor"
  ) {
    return true;
  }

  if (basicRoutes?.join(" ").includes(url)) {
    return true;
  }

  let sub = subscriptions.find(
    (sub) => sub.id == localStorage.getItem("subscription_id")
  );
  if (!sub) {
    if (minutes < 0) {
      swal({
        title: "Subscription not found!",
        text: "You don't have access to this page, please subscribe.",
        icon: "warning",
        button: "Ok!",
      }).then((res) => {
        if (res) {
          setTimeout(() => {
            window.location.href = "/userdashboard";
          }, 1000);
          return false;
        }
      });
    } else {
      sub = subscriptions.find((itm) => itm.id == "premium0plan");
    }
  }

  let routesAccessible = sub?.access?.frontendRoutes;
  //console.log("Subscription: ", url, routesAccessible);

  if (routesAccessible?.join(" ").includes(url) || routesAccessible?.indexOf(url)) {
    return true;
  } else {
    swal({
      title: "Error!",
      text: "You don't have access to this page, please subscribe.",
      icon: "warning",
      button: "Ok!",
    }).then((res) => {
      if (res) {
        setTimeout(() => {
          window.location.href = "/userdashboard";
        }, 1000);
        return false;
      }
    });
    return false;
  }
};

export default subsNavCheck;
