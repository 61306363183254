import React, { useState } from "react";
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const Filter = (props) => {
    // console.log(props.filter.Destination_Country)
    const dataItems = Object.entries(props.data);

    let countryOptions = props.countrydata?.map((getcountry) => ({
        value: getcountry.country_name,
        label: getcountry.country_name
    }));

    let stateOptions = props.stateData !== undefined ? props.stateData?.map((getstate, index) => ({
        value: getstate.state_name,
        label: getstate.state_name
    })) : []

    let universityOptions = props.universityData !== undefined ? props.universityData?.map((getstate, index) => ({
        value: getstate,
        label: getstate
    }
    )) : []


    let raceEthOptions = props.raceEthnicityData !== undefined ? props.raceEthnicityData?.map((getstate) => ({
        label: getstate,
        value: getstate
    })) : [];

    return (
        <>
            <p className="filter-info">
                <span>
                    For best results use (<strong>Destination Country* </strong>+ one filter) at a time.
                </span>
                <a href="#" onClick={props.handleReset} className="link-filter mt-3">
                    Reset All
                </a>
            </p>

            <form onSubmit={props.handleSubmit}>
                <div className="filter-content">

                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            Destination Country
                        </label>
                        {props.filter.Destination_Country !== undefined && props.filter.Destination_Country.length > 0 ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x filter-close-btn" viewBox="0 0 16 16" onClick={props.clrDest}>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        ) : ""}
                        <Select
                            value={props.filter.Destination_Country !== undefined && props.filter.Destination_Country.length > 0 ? { value: props.filter.Destination_Country[0], label: props.filter.Destination_Country[0] } : null}
                            options={countryOptions}
                            onChange={(selectedOption) => {
                                let newData = [];
                                if (selectedOption) {
                                    newData = [selectedOption.value];
                                }
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Destination_Country: newData,
                                    University: [],
                                    Race_Ethnicity: []
                                }));
                            }}
                        />
                        <small>Select the country where you plan to pursue your studies.</small>
                    </div>

                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            State
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Use <strong>ONLY</strong> if you are looking only for state specific scholarships.</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>
                        <Select
                            isMulti
                            value={props?.filter?.State?.map(value => ({ value, label: value }))}
                            options={stateOptions}
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    State: newData
                                }));
                            }}
                        />
                        <small>Choose the state(s) relevant to desired study destination.</small>
                    </div>


                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            University
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Select all that applies e.g., for ASU select: 'Arizona State University' + 'All' + For State University.</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>
                        <Select
                            isMulti
                            value={props.filter.University?.map(value => ({ value, label: value }))}
                            options={universityOptions}
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    University: newData
                                }));
                            }}
                        />
                        <small>Choose the university (if applicable) to filter scholarships offered by and for specific institutions.</small>
                    </div>

                    <div className="filter-container-item form-group mt-3">
                        <label htmlFor="inputState" className="form-label d-flex flex-row justify-content-between w-100">
                            Eligible Citizenship
                            {props.filter.Eligible_Citizenship.length > 0 ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x edit-btn" viewBox="0 0 16 16" onClick={props.clrEli}>
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            ) : ""}
                        </label>
                        <Select
                            value={props.filter.Eligible_Citizenship.length > 0 ? { value: props.filter.Eligible_Citizenship[0], label: props.filter.Eligible_Citizenship[0] } : null}
                            options={countryOptions}
                            onChange={(selectedOption) => {
                                let newData = [];
                                if (selectedOption) {
                                    newData = [selectedOption.value];
                                }
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Eligible_Citizenship: newData,
                                    State: []
                                }));
                            }}
                        />
                    </div>


                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            Sex
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Select all that applies e.g., for Female select 'Female' + 'All'.</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>
                        <Select
                            isMulti
                            value={props.filter.Sex?.map(value => ({ value, label: value }))}
                            options={[
                                { label: 'All', value: 'All' },
                                { label: 'Female', value: 'Female' },
                                { label: 'Male', value: 'Male' },
                                { label: 'Non-Binary', value: 'Non-Binary' },
                            ]}
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Sex: newData
                                }));
                            }}
                        />
                    </div>

                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            Level
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Select all that applies e.g., for Bachelors degree select 'UG' + 'All' + UG and PG + 'Degree Programs Only'.</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>
                        <Select
                            isMulti
                            value={props.filter.Program_Level?.map(value => ({ value, label: value }))}
                            options={[
                                { label: 'All', value: 'All' },
                                { label: 'Degree Programs Only', value: 'Degree Programs Only' },
                                { label: 'English Language Program', value: 'English Language Program' },
                                { label: 'Foundation', value: 'Foundation' },
                                { label: 'High School (11th - 12th)', value: 'High School (11th - 12th)' },
                                { label: 'Online Programmes / Distance Learning', value: 'Online Programmes / Distance Learning' },
                                { label: 'Pathway Programs', value: 'Pathway Programs' },
                                { label: 'PG', value: 'PG' },
                                { label: 'PG Diploma/Certificate', value: 'PG Diploma/Certificate' },
                                { label: 'PhD', value: 'PhD' },
                                { label: 'Short Term Programs', value: 'Short Term Programs' },
                                { label: 'Technical School/College', value: 'Technical School/College' },
                                { label: 'Trade School/College', value: 'Trade School/College' },
                                { label: 'Twinning Programmes (PG)', value: 'Twinning Programmes (PG)' },
                                { label: 'UG', value: 'UG' },
                                { label: 'UG+PG (Accelerated) Degree', value: 'UG+PG (Accelerated) Degree' },
                                { label: 'UG and PG', value: 'UG and PG' },
                                { label: 'UG Diploma/ Certificate/ Associate Degree', value: 'UG Diploma/ Certificate/ Associate Degree' },
                            ]}
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Program_Level: newData
                                }));
                            }}
                        />
                        <small>Select the intended academic study level.</small>
                    </div>

                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            Race/Ethnicity
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Multiselect e.g., If you are White select 'White' + 'Open for All'.</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>
                        {/* <input
                            list="data_race"
                            value={props.filter.Race_Ethnicity}
                            onChange={props.onChange}
                            placeholder="Select Race/Ethnicity"
                            className="form-select"
                            name="Race_Ethnicity"
                        />
                        <datalist id="data_race">
                            {props.raceEthnicityData !== undefined &&
                                props.raceEthnicityData?.map((getstate, index) => (
                                    <option value={getstate.name} key={index}>
                                        {getstate.name}
                                    </option>
                                ))}
                        </datalist> */}
                        <Select
                            isMulti
                            value={props.filter.Race_Ethnicity?.map(value => ({ value, label: value }))}
                            options={
                                raceEthOptions
                            }
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Race_Ethnicity: newData
                                }));
                            }}
                        />
                        <small>Choose your racial or ethnic background (if applicable)</small>
                    </div>

                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            Program
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Use <strong>ONLY</strong> if you are looking only for state specific scholarships or Multiselect 'Stream' + 'All'</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>

                        <Select
                            isMulti
                            value={props.filter.Study_Area?.map(value => ({ value, label: value }))}
                            options={[
                                { label: 'Any', value: 'Any' },
                                { label: 'Architecture and Building', value: 'Architecture and Building' },
                                { label: 'Arts', value: 'Arts' },
                                { label: 'Business and Administration', value: 'Business and Administration' },
                                { label: 'Commerce', value: 'Commerce' },
                                { label: 'Computer Science and Information Technology', value: 'Computer Science and Information Technology' },
                                { label: 'Education', value: 'Education' },
                                { label: 'Engineering and Engineering Trades', value: 'Engineering and Engineering Trades' },
                                { label: 'Environmental Science/Protection', value: 'Environmental Science/Protection' },
                                { label: 'Forestry and Fishery', value: 'Forestry and Fishery' },
                                { label: 'Health, Humanities', value: 'Health, Humanities' },
                                { label: 'Journalism and Information', value: 'Journalism and Information' },
                                { label: 'Law', value: 'Law' },
                                { label: 'Life Sciences', value: 'Life Sciences' },
                                { label: 'Manufacturing and Processing', value: 'Manufacturing and Processing' },
                                { label: 'Mathematics and Statistics', value: 'Mathematics and Statistics' },
                                { label: 'Personal Services', value: 'Personal Services' },
                                { label: 'Physical Sciences', value: 'Physical Sciences' },
                                { label: 'Sciences', value: 'Sciences' },
                                { label: 'Security Services', value: 'Security Services' },
                                { label: 'Social and Behavioural Science', value: 'Social and Behavioural Science' },
                                { label: 'Social Services', value: 'Social Services' },
                                { label: 'Transport Services', value: 'Transport Services' },
                                { label: 'Veterinary', value: 'Veterinary' }
                            ]}
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Study_Area: newData
                                }));
                            }}
                        />
                        <small>Select your academic program Stream.</small>
                    </div>

                    <div className="filter-container-item form-group">
                        <label htmlFor="inputState" className="form-label">
                            Special Category
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>Use <strong>ONLY</strong> if you are looking only for state specific Category or Multiselect</Tooltip>}>
                                <i className="fa fa-info-circle"></i>
                            </OverlayTrigger>
                        </label>
                        <Select
                            isMulti
                            value={props.filter.Category?.map(value => ({ value, label: value }))}
                            options={[
                                { label: 'Flexible Learning', value: 'Flexible Learning' },
                                { label: 'International Students', value: 'International Students' },
                                { label: 'LGBTQ+', value: 'LGBTQ+' },
                                { label: 'Minority', value: 'Minority' },
                                { label: 'Physically Challenged', value: 'Physically Challenged' },
                                { label: 'Select Special Category', value: '' },
                                { label: 'Special Category', value: 'Special Category' },
                                { label: 'Sports', value: 'Sports' },
                                { label: 'Veteran', value: 'Veteran' },
                                { label: 'Women', value: 'Women' }
                            ]}
                            onChange={(selectedOptions) => {
                                const newData = selectedOptions ? selectedOptions?.map(option => option.value) : [];
                                props.setFilter(prevData => ({
                                    ...prevData,
                                    Category: newData
                                }));
                            }}
                        />
                        <small>Select any special categories that apply to you.</small>
                    </div>

                    <div className="form-button">
                        <button name="submit" className="btn btn-primary">
                            Filter
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Filter;
