import React, { useEffect, useState } from "react";
import { url } from "../Constants";
import { CircularProgress } from "@mui/material";
import Img from "../Assets/Media/Images/nodata.png";
import { Pagination } from "react-bootstrap";
import swal from "sweetalert";

const AssignStudents = ({ userid }) => {
  const [allStudents, setAllStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [unAssignedStudent, setUnassignedStudents] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalScholarships: 0,
    pageCount: 0,
  });

  const getAllStudents = async (page) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${url}/api/users?page=${page}&role=user&user_counsellor=${userid}&fetch_counsellor_unassigned=true&populate=user_counsellor`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setAllStudents(data.users);
      setTotalPages(data.pagination.pageCount);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStudents(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLoadingAndNoData = () => {
    if (loading) {
      return (
        <div className="circle">
          <CircularProgress className="circleHight" />
        </div>
      );
    }
    if (allStudents.length === 0) {
      return (
        <>
          <img src={Img} className="noData" alt="nodata" />
          <p className="required p-2">No Data Found</p>
        </>
      );
    }
    return "1";
  };

  const handleAssign = async () => {
    try {
      const response = await fetch(`${url}/api/assign-students`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          students: assignedStudents,
          counsellor: userid,
          unassignedstudents: unAssignedStudent,
        }),
      });
      if (response.ok) {
        swal({
          title: "Success",
          text: `Data Updated Successfully!`,
          icon: "success",
          button: "Ok",
        });
        getAllStudents(currentPage);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <>
      <br />
      <div className="container">
        <h1 className="page-title">Assign Students to Counsellor</h1>
        <table className="table admin-table">
          <thead>
            <tr className="table-dark">
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone No.</th>
              <th scope="col">Study Destination</th>
              <th scope="col">Role</th>
              <th scope="col">Current Counsellor</th>
            </tr>
          </thead>
          <tbody>
            {handleLoadingAndNoData() === "1"
              ? allStudents?.map((e, id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <input
                          type="checkbox"
                          onClick={(event) => {
                            if (event.target.checked) {
                              setAssignedStudents([
                                ...assignedStudents,
                                e?._id,
                              ]);
                              if (e?.user_counsellor) {
                                setUnassignedStudents(
                                  unAssignedStudent.filter(
                                    (item) => item != e?._id
                                  )
                                );
                              }
                            } else {
                              let newArr = assignedStudents.filter(
                                (item) => item != e?._id
                              );
                              if (e?.user_counsellor) {
                                setUnassignedStudents([
                                  ...unAssignedStudent,
                                  e?._id,
                                ]);
                              }
                              setAssignedStudents(newArr);
                            }
                          }}
                          defaultChecked={e?.user_counsellor ? true : false}
                        />
                      </td>
                      <td>
                        {e.firstName} {e.lastName}
                      </td>
                      <td>
                        <a href={`mailto:${e.email}`}>{e.email}</a>
                      </td>
                      <td>{e.phone}</td>
                      <td>{e.studyDest}</td>
                      <td>{e.role}</td>
                      <td>
                        {e?.user_counsellor?.firstName
                          ? `${e?.user_counsellor?.firstName} ${e?.user_counsellor?.lastName}`
                          : "Not Assigned"}
                      </td>
                    </tr>
                  );
                })
              : handleLoadingAndNoData()}
          </tbody>
        </table>
        {allStudents.length > 0 ? (
          <Pagination className="d-flex justify-content-between mb-5">
            <button
              type="submit"
              onClick={() => handleAssign()}
              className="btn btn-primary updateBtn"
            >
              Assign Students
            </button>
            <div className="d-flex justify-content-end">
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              />
            </div>
          </Pagination>
        ) : null}
      </div>
    </>
  );
};

export default AssignStudents;
